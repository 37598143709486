
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { PartialRegistrationOfInterestState } from "@/store/modules/registrationofinterest/registrationOfInterestTypes";
import { createNamespacedHelpers } from "vuex";
import { SuperName, SuperPath } from "./registrationOfInterestTypes";

const { mapMutations } = createNamespacedHelpers("registrationOfInterest");

@Component({
	methods: mapMutations(["updateField"]),
})
export default class BaseRegistrationOfInterestStep extends Vue {
	@Prop(String)
	readonly superPath!: SuperPath;

	@Prop(String)
	readonly superName!: SuperName;

	updateField!: (payload: PartialRegistrationOfInterestState) => void;

	validate() {
		return (this.$refs.validationObserver as any).validate();
	}
}
