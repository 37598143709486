
















































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import Button from "@/form/Button.vue";
import axios from "@/utils/ApiUtils";
import {
	MaintenanceEntity,
	PaymentProfile,
	RCStrategy,
	RCStrategyTypes,
	ReportingCentreDetail,
	PaymentProfileRC,
} from "@/pages/maintenanceTypes";
import EmployerSelector from "@/components/EmployerSelector.vue";
import { toastErrorMessage, toastSuccessMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import {
	retrieveEmployerPaymentProfileOptions,
	retrievePaymentProfileByMemberId,
	saveReportingCentre,
} from "@/constants/apiconstants";
import { VForm } from "@/@typings/type-vee-validate";
import PageHeader from "@/components/PageHeader.vue";
import ModalWithSaveAndCancel from "@/components/ModalWithSaveAndCancel.vue";
import { getEmployerHierarchy } from "@/utils/PermissionUtils";
import { AxiosResponse } from "axios";
import ReportingCentreDetails from "@/components/ReportingCentreDetails.vue";
import { createNamespacedHelpers } from "vuex";
import PaymentProfileSection from "@/components/PaymentProfileSection.vue";
import { SelectOption } from "@/form/FieldOptions";
import PaymentProfileDetails from "@/components/PaymentProfileDetails.vue";
import PaymentProfileMaintenance from "@/components/PaymentProfileMaintenance.vue";
import { Logger } from "@/utils/logger";

const { mapActions, mapMutations } = createNamespacedHelpers("persistent");

type ErrorArray = string[];

interface ErrorMap {
	[fieldName: string]: ErrorArray;
}

@Component({
	components: {
		PageHeader,
		ReportingCentreDetails,
		ModalWithSaveAndCancel,
		Layout,
		EmployerSelector,
		Button,
		PaymentProfileSection,
		PaymentProfileDetails,
		PaymentProfileMaintenance,
	},
	methods: {
		...mapActions(["fetchEmployerHierarchy"]),
		...mapMutations(["setSelectedEntities"]),
	},
})
export default class AddReportingCentre extends Vue {
	private rcDetail: ReportingCentreDetail | null = null;
	private hierarchy: MaintenanceEntity[] = [];
	private showCancelModal = false;

	@Prop({ type: Number, required: true })
	private employerId!: number;
	@Prop({ type: String, required: true })
	private rcStrategy!: RCStrategy;
	@Prop({ type: String })
	private abn!: string;
	private paymentProfileOptions: SelectOption[] | null = null;
	private memberId = "";
	private paymentProfileRC: PaymentProfileRC | null = null;
	private reportingCentreNames: { name: string }[] = [];
	private memberIdForPaymentProfile: string | undefined = undefined;
	private showPaymentProfileModal = false;

	/**
	 * Type the mapped persistent.setSelectedEntities mutattion.
	 */
	setSelectedEntities!: (selectedEntities: string[]) => void;
	/**
	 * Type the mapped persistent.fetchEmployerHierarchy action.
	 */
	fetchEmployerHierarchy!: () => Promise<void>;

	async saveReportingCentre() {
		if (this.employerId && this.rcDetail) {
			if (await (this.$refs.reportingCentreForm as VForm).validate()) {
				axios
					.post(saveReportingCentre(), this.rcDetail)
					.then((response: AxiosResponse) => {
						this.fetchEmployerHierarchy();
						toastSuccessMessage(
							"Reporting centre added successfully"
						);
						this.$router.push({ name: "Company Maintenance" });
					})
					.catch((error) => {
						toastErrorMessage(parseErrorMessage(error));
					});
			}
		}
	}

	get allFormFieldsEmpty(): boolean {
		const isStrategyFieldsEmpty = (): boolean => {
			switch (this.rcStrategy) {
				case RCStrategyTypes.ABN_LC:
					return this.isEmptyVal(this.rcDetail?.locationId);
				case RCStrategyTypes.ABN_BK:
					return (
						this.isEmptyVal(this.rcDetail?.bsb) &&
						this.isEmptyVal(this.rcDetail?.accountNumber)
					);
				default:
					return true;
			}
		};

		return (
			this.rcDetail !== null &&
			this.isEmptyVal(this.rcDetail.name) &&
			this.isEmptyVal(this.rcDetail.addressLine1) &&
			this.isEmptyVal(this.rcDetail.suburb) &&
			this.isEmptyVal(this.rcDetail.postcode) &&
			this.isEmptyVal(this.rcDetail.state) &&
			isStrategyFieldsEmpty()
		);
	}

	private onChangeEntity(entity: MaintenanceEntity): void {
		this.setSelectedEntities([entity.id]);
		this.$router.push({
			name: "Company Maintenance",
		});
	}

	private discardChanges() {
		this.$router.push({ name: "Company Maintenance" });
	}

	private closeModal() {
		this.showCancelModal = false;
	}

	private cancel() {
		if (this.allFormFieldsEmpty) {
			this.$router.push({
				name: "Company Maintenance",
			});
		} else {
			this.showCancelModal = true;
		}
	}

	updateReportingCentreDetail(rcDetail: ReportingCentreDetail) {
		if (rcDetail) {
			this.rcDetail = rcDetail;
		}
	}

	isEmptyVal(val: string | undefined): boolean {
		return val === undefined || val === null || val === "";
	}

	updatePaymentProfile(memberId: string) {
		this.memberId = memberId;
		if (this.rcDetail && this.rcDetail.employerId) {
			this.rcDetail.paymentProfileMemberId = memberId;
			this.fetchPaymentProfileByMemberId(
				this.rcDetail.employerId,
				this.memberId
			);
		}
	}

	addPaymentProfile() {
		if (this.rcDetail && this.rcDetail.employerId) {
			this.memberIdForPaymentProfile = undefined;
			this.showPaymentProfileModal = true;
		}
	}

	editPaymentProfile(param: { memberId: string }) {
		if (this.rcDetail && this.rcDetail.employerId) {
			this.memberIdForPaymentProfile = param.memberId;
			this.showPaymentProfileModal = true;
		}
	}

	onClosePaymentProfileModal() {
		this.memberIdForPaymentProfile = undefined;
		this.showPaymentProfileModal = false;
	}

	onSavePaymentProfile() {
		if (!this.rcDetail || !this.rcDetail.employerId) {
			Logger.warn(
				"updatePaymentProfile",
				"Missing employer id or member id."
			);
			return;
		}
		this.fetchPaymentProfileOptions();
	}

	fetchPaymentProfileByMemberId(employerId: number, memberId: string) {
		axios
			.get<PaymentProfileRC>(
				retrievePaymentProfileByMemberId(employerId, memberId, 0)
			)
			.then((response) => {
				this.paymentProfileRC = response.data;
				if (this.paymentProfileRC.paymentProfile) {
					this.memberId = this.paymentProfileRC.paymentProfile.memberId;
					this.reportingCentreNames = this.paymentProfileRC?.reportingCentres.map(
						(name) => ({ name })
					);
				}
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
	}

	mounted() {
		if (this.employerId && this.rcStrategy) {
			this.rcDetail = {
				employerId: this.employerId,
				name: "",
				addressLine1: "",
				addressLine2: "",
				suburb: "",
				postcode: "",
				state: "",
				rcIdentificationStrategy: this.rcStrategy,
				abn: this.abn ? this.abn : "",
				locationId: "",
				paymentProfileMemberId: "",
			} as ReportingCentreDetail;
			this.fetchPaymentProfileOptions();

			this.hierarchy = getEmployerHierarchy("E_" + this.employerId);
			this.hierarchy.push({
				id: "",
				label: "Add Reporting Centre",
			});
		} else {
			this.$router.push({
				name: "Company Maintenance",
			});
		}
	}

	fetchPaymentProfileOptions() {
		axios
			.get<PaymentProfile[]>(
				retrieveEmployerPaymentProfileOptions(this.employerId)
			)
			.then((response) => {
				this.paymentProfileOptions = response.data.map(
					(paymentProfile: PaymentProfile) => ({
						value: paymentProfile.memberId,
						label: paymentProfile.nickname,
					})
				);
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
	}
}
