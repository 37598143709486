import { render, staticRenderFns } from "./CreateBatchPage.vue?vue&type=template&id=b2d05e90&scoped=true&"
import script from "./CreateBatchPage.vue?vue&type=script&lang=ts&"
export * from "./CreateBatchPage.vue?vue&type=script&lang=ts&"
import style0 from "./CreateBatchPage.vue?vue&type=style&index=0&id=b2d05e90&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2d05e90",
  null
  
)

export default component.exports