






















import { createNamespacedHelpers } from "vuex";

interface BrandOption {
	value: string;
	label: string;
	code: string;
}
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import SelectField from "@/form/SelectField.vue";
import axios from "@/utils/ApiUtils";
import { currentBrandsListURL } from "@/constants/apiconstants";
import { toastErrorMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import { IRESS_BRAND_CODE } from "@/constants/constants";
const { mapState } = createNamespacedHelpers("persistent");

@Component({
	components: {
		SelectField,
	},
	computed: mapState(["brandId"]),
})
export default class BrandList extends Vue {
	private brandList: BrandOption[] | null = null;
	private loading = false;
	private iressBrandId: number | null = null;
	/**
	 * Type the mapped persistent.selectedEntities getter.
	 * It is a computed property. Do not mutate it.
	 * hide brandList component if its
	 */
	brandId!: number | null;

	@Prop(Number) selectedBrandId!: string | number | null;
	@Prop(Boolean) readonly readonly!: boolean;

	update(field: string, value: string) {
		this.$emit("change", { field: field, value: Number(value) });
	}

	beforeMount() {
		this.loading = true;
		axios
			.get<{ brands: BrandOption[] }>(currentBrandsListURL())
			.then((response) => {
				this.brandList = response.data.brands.map(
					(brand: BrandOption) => ({
						...brand,
						value: brand.value.toString(),
					})
				);

				const iressBrand = this.brandList.filter(
					(brand: BrandOption) => {
						return brand.code === IRESS_BRAND_CODE;
					}
				);
				this.iressBrandId =
					iressBrand && iressBrand.length > 0
						? Number(iressBrand[0].value)
						: null;
				this.loading = false;
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
				this.loading = false;
			});
	}
}
