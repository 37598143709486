




















































import Vue from "vue";
import { throttle } from "lodash";
import { Component, Prop } from "vue-property-decorator";

import Container from "@/components/Container.vue";

export interface PopupProps {
	title: string;
	size: "md" | "sm" | "fill" | "lg";
}

@Component({
	components: {
		Container,
	},
})
export default class Popup extends Vue implements PopupProps {
	/**
	 * MouseDistanceOffset is how far the mouse has to of moved since its last
	 * position before we updated it. This is to avoid thrashing the DOM.
	 *
	 * The number chosen was arbitrarily and can be tweaked.
	 */
	private readonly MouseDistanceOffset = 8;

	@Prop(String) title!: string;

	@Prop({ type: String, default: "sm" }) size!: "md" | "sm" | "fill" | "lg";

	x = -99999;

	y = -99999;

	$refs!: {
		popupEl: Container;
		slotEl: HTMLElement;
	};

	mounted(): void {
		document.body.addEventListener("mousemove", this.onMouseMove);
	}

	beforeDestroy(): void {
		document.body.removeEventListener("mousemove", this.onMouseMove);
	}

	/**
	 * onMouseMove callback is wrapped in a throttle function to avoid firing too often
	 * and thrashing the page with DOM updates.
	 */
	private readonly onMouseMove: (e: MouseEvent) => void = throttle(
		(e: MouseEvent) => {
			this._onMouseMove(e);
		},
		16
	);

	private _onMouseMove(e: MouseEvent): void {
		if (
			Math.abs(this.x - e.clientX) < this.MouseDistanceOffset &&
			Math.abs(this.y - e.clientY) < this.MouseDistanceOffset
		) {
			return;
		}
		this.x = e.clientX - 8;
		this.y = e.clientY - 8;
		const popupEl = this.$refs.popupEl ? this.$refs.popupEl.$el : undefined;
		if (!(popupEl instanceof HTMLElement)) {
			// Ignore updating DOM next frame if element is bound
			return;
		}
		this.$nextTick(this.updateAtStartOfFrame);
	}

	private hasSlotChildren(): boolean {
		return this.$refs.slotEl && this.$refs.slotEl.children.length > 0;
	}

	private updateAtStartOfFrame = (): void => {
		const popupEl = this.$refs.popupEl ? this.$refs.popupEl.$el : undefined;
		if (!(popupEl instanceof HTMLElement)) {
			// Ignore trying to update element if its no-longer bound.
			// ie. Got unmounted between last frame and now.
			return;
		}
		// NOTE(Jae): 2020-06-12
		// We need to toggle the class here as for some reason the :class
		// binding in Vue isn't reacting to X/Y changes.
		if (
			!this.hasSlotChildren() ||
			(this.x === -99999 && this.y === -99999) ||
			(this.x === 0 && this.y === 0)
		) {
			popupEl.classList.add("Popup__root--hidden");
		} else {
			popupEl.classList.remove("Popup__root--hidden");
		}
		popupEl.style.left = this.x + "px";
		popupEl.style.top = this.y + "px";
	};
}
