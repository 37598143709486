














































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import axios from "@/utils/ApiUtils";
import {
	getAlertDetailsURL,
	updateNotificationDone,
	updateNotificationPending,
} from "@/constants/apiconstants";
import { toastErrorMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import Overlay from "@/components/Overlay.vue";
import {
	ActionedBy,
	Alert,
	AlertDetailsTemplate,
	NotificationCategory,
	NotificationCategoryMapper,
	Status,
} from "@/models/AlertRow";
import Form from "@/form/Form.vue";
import Button from "@/form/Button.vue";
import FieldGroup from "@/form/FieldGroup.vue";
import GridLoadingOverlay from "@/grid/GridLoadingOverlay.vue";
import TextField from "@/form/TextField.vue";
import AlertInfo from "@/components/AlertInfo.vue";

@Component({
	components: {
		Overlay,
		Form,
		Button,
		FieldGroup,
		TextField,
		GridLoadingOverlay,
		AlertInfo,
	},
})
export default class AlertDetails extends Vue {
	@Prop({ type: String, default: "" })
	readonly target!: string;

	@Prop({ type: String, default: "topright" })
	readonly placement!: string;

	@Prop()
	readonly rowData!: Alert;

	private templateData: AlertDetailsTemplate | null = null;
	private loading = false;

	mounted() {
		this.loading = true;
		axios
			.get<AlertDetailsTemplate>(getAlertDetailsURL(this.rowData.id))
			.then((response) => {
				this.templateData = response.data;
				this.loading = false;
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
				this.loading = false;
			});
	}

	private onMarkAsDone(): void {
		const notificationId = (this.rowData as Alert).id;
		const notificationType = (this.rowData as Alert).type;
		if (notificationId) {
			axios
				.put(updateNotificationDone(), [
					{ id: notificationId, type: notificationType },
				])
				.then(() => {
					this.$emit("done");
				})
				.catch((rejected) => {
					toastErrorMessage(parseErrorMessage(rejected));
				});
		}
	}

	private onMarkAsPending(): void {
		const notificationId = (this.rowData as Alert).id;
		const notificationType = (this.rowData as Alert).type;
		if (notificationId) {
			axios
				.put(updateNotificationPending(), [
					{ id: notificationId, type: notificationType },
				])
				.then(() => {
					this.$emit("pending");
				})
				.catch((rejected) => {
					toastErrorMessage(parseErrorMessage(rejected));
				});
		}
	}

	private onClosePopover(): void {
		this.$emit("close");
	}

	private get title(): string {
		return `${NotificationCategoryMapper(this.rowData.category)} - ${
			this.rowData.id
		}`;
	}

	private get icon(): [string, string] {
		switch (this.rowData.category) {
			case NotificationCategory.F:
				return ["fal", "hand-holding-usd"];
			case NotificationCategory.C:
				return ["fal", "files-medical"];
			case NotificationCategory.P:
				return ["fal", "sack-dollar"];
			case NotificationCategory.U:
				return ["fal", "user-cog"];
			case NotificationCategory.S:
				return ["fal", "repeat-alt"];
			case NotificationCategory.ER:
				return ["fal", "list"];
			default:
				return ["fal", "bell"];
		}
	}

	private get isActionedByUser(): boolean {
		return this.rowData.actionedBy === ActionedBy.U;
	}

	private get isStatusDone(): boolean {
		return this.rowData.status === Status.D;
	}
}
