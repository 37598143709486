




import Vue from "vue";
import Component from "vue-class-component";
import axios from "@/utils/ApiUtils";
import { upscopePublicKeyURL } from "@/constants/apiconstants";
import { init } from "@/upscope/upscope";

@Component({})
export default class Upscope extends Vue {
	mounted() {
		if (process.env.VUE_APP_BRAND) {
			axios
				.get<string>(upscopePublicKeyURL(process.env.VUE_APP_BRAND))
				.then((resp) => {
					let publicKey = "";
					if (resp.data) {
						publicKey = resp.data;
					}
					if (publicKey !== "") {
						init(publicKey);
					}
				});
		}
	}
}
