import { Component } from "vue-property-decorator";

// NOTE(Jae): 2020-05-18
// This file must be imported in main before the Vue app instance is initialized.
// Interestingly enough, this logic *needs* to be in its own file like this, just putting
// this directly in main.ts caused it to not work.
// Sources:
// - https://github.com/vuejs/vue-class-component/issues/264#issuecomment-426618715
// - https://class-component.vuejs.org/guide/additional-hooks.html
Component.registerHooks([
	"beforeRouteEnter",
	"beforeRouteLeave",
	"beforeRouteUpdate",
]);
