




























































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { FormFieldMap, FormStage } from "@/models/EmployeeRow";
import ModalWithSaveAndCancel from "@/components/ModalWithSaveAndCancel.vue";
import Form from "@/form/Form.vue";
import Button from "@/form/Button.vue";
import FieldGroup from "@/form/FieldGroup.vue";
import AutoField from "@/form/AutoField.vue";
import GridLoadingOverlay from "@/grid/GridLoadingOverlay.vue";
import {
	SUPERSTREAM_STATUS_DONE,
	SUPERSTREAM_STATUS_PENDING,
	SuperStreamContributionResponse,
	SuperStreamSelectedRow,
} from "@/models/SuperStreamContributionResponse";
import { hasPermission } from "@/utils/PermissionUtils";
import axios from "@/utils/ApiUtils";
import {
	superStreamContributionResponseDetailsURL,
	updateSuperStreamContributionResponseURL,
} from "@/constants/apiconstants";
import { toastErrorMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";

@Component({
	components: {
		Form,
		Button,
		ModalWithSaveAndCancel,
		FieldGroup,
		AutoField,
		GridLoadingOverlay,
	},
})
export default class SuperStreamDetail extends Vue {
	@Prop({ type: String, default: "" })
	readonly target!: string;

	@Prop({ type: String, default: "topright" })
	readonly placement!: string;

	@Prop({ type: String, default: "" })
	readonly title!: string;

	@Prop(Number)
	readonly responseTypeId!: number;

	@Prop()
	readonly rowData!: SuperStreamSelectedRow;

	private formData: SuperStreamContributionResponse | null = null;
	private showPopover = true;
	private isStatusModalShown = false;
	private loading = false;

	private formSchema: FormStage[] = [
		{
			header: "SuperStream Response Message",
			subSections: [
				{
					name: "Response Details",
					value: [
						["errorCode", "severity"],
						["shortDescription"],
						["detailedDescription"],
						["contextId"],
						["conversationId"],
					],
					icon: ["fal", "repeat-alt"],
				},
				{
					name: "Fund Details",
					value: [
						["superFundAbn", "superFundUsi"],
						["superFundName"],
					],
					icon: ["fal", "hand-holding-usd"],
				},
				{
					name: "Member Details",
					value: [["givenNames", "surname"], ["memberClientId"]],
					icon: ["fal", "user-alt"],
				},
				{
					name: "Contribution Refund Details",
					value: [
						["refund"],
						["refundAmount", "refundPaymentTotal"],
						["refundPaymentRefNumber"],
						["employerAbn"],
					],
					icon: ["fal", "hand-holding-usd"],
				},
			],
		},
	];

	private editFieldMap: FormFieldMap = {
		errorCode: {
			name: "errorCode",
			label: "Response code",
			type: "TextField",
			readonly: true,
		},
		conversationId: {
			name: "conversationId",
			type: "TextField",
			readonly: true,
		},
		severity: {
			name: "severity",
			label: "Severity code",
			type: "TextField",
			readonly: true,
		},
		shortDescription: {
			name: "shortDescription",
			type: "TextField",
			readonly: true,
		},
		detailedDescription: {
			name: "detailedDescription",
			type: "TextField",
			readonly: true,
		},
		contextId: {
			name: "contextId",
			type: "TextField",
			readonly: true,
		},

		superFundAbn: {
			name: "superFundAbn",
			type: "TextField",
			label: "ABN",
			readonly: true,
		},
		superFundName: {
			name: "superFundName",
			type: "TextField",
			label: "Name",
			readonly: true,
		},
		superFundUsi: {
			name: "superFundUsi",
			type: "TextField",
			label: "USI",
			readonly: true,
		},

		givenNames: {
			name: "givenNames",
			label: "Given name",
			type: "TextField",
			readonly: true,
		},
		surname: {
			name: "surname",
			type: "TextField",
			readonly: true,
		},
		memberClientId: {
			name: "memberClientId",
			type: "TextField",
			label: "Member client ID",
			readonly: true,
		},
		refundAmount: {
			name: "refundAmount",
			type: "TextField",
			label: "Amount",
			readonly: true,
		},
		refundPaymentRefNumber: {
			name: "refundPaymentRefNumber",
			type: "TextField",
			label: "Payment reference number",
			readonly: true,
		},
		refundPaymentTotal: {
			name: "refundPaymentTotal",
			type: "TextField",
			label: "Payment total",
			readonly: true,
		},
		employerAbn: {
			name: "employerAbn",
			label: "Employer ABN",
			type: "TextField",
			readonly: true,
		},
		refund: {
			name: "refund",
			type: "TextField",
			label: "Refund",
			readonly: true,
		},
	};

	mounted() {
		this.loading = true;
		axios
			.get<SuperStreamContributionResponse>(
				superStreamContributionResponseDetailsURL(
					this.rowData.batchId,
					this.rowData.responseType,
					this.rowData.responseTypeId
				)
			)
			.then((response) => {
				this.formData = response.data;
				this.loading = false;
			})
			.catch((error) => {
				this.loading = true;
				toastErrorMessage(parseErrorMessage(error));
			});
	}
	private showConfirmDoneModal(): void {
		this.isStatusModalShown = true;
	}

	private cancelStatusUpdate(): void {
		this.isStatusModalShown = false;
	}

	private setStatus(): void {
		if (!this.rowData) {
			return;
		}

		axios
			.put(
				updateSuperStreamContributionResponseURL(
					this.rowData.batchId,
					this.rowData.responseType,
					this.rowData.responseTypeId
				),
				{
					status:
						this.formData?.status === SUPERSTREAM_STATUS_DONE
							? SUPERSTREAM_STATUS_PENDING
							: SUPERSTREAM_STATUS_DONE,
				},
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			)
			.then((response) => {
				//close pop over
				this.showPopover = false;
				this.isStatusModalShown = false;
				this.$emit("done");
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
	}

	private get isStatusPending(): boolean {
		return this.formData?.status !== SUPERSTREAM_STATUS_DONE;
	}

	private get buttonLabel(): string {
		return this.formData?.status !== SUPERSTREAM_STATUS_DONE
			? "done"
			: "pending";
	}

	private onClosePopover() {
		this.showPopover = false;
		this.$emit("close");
	}

	private hasPermission(permission: string) {
		return hasPermission(permission);
	}
}
