










































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
@Component({})
export default class Overlay extends Vue {
	@Prop({ type: Boolean, default: true })
	readonly isVariantDark!: boolean;

	private disableHandler(e: any): void {
		// NOTE(Jae): 2020-07-21
		// Block inputs so that TAB'ing through fields won't work while the overlay is open.
		e.stopImmediatePropagation();
		e.preventDefault();
	}

	mounted(): void {
		document.addEventListener("keydown", this.disableHandler);
		document.body.addEventListener("keydown", this.disableHandler);
	}

	destroyed(): void {
		document.removeEventListener("keydown", this.disableHandler);
		document.body.removeEventListener("keydown", this.disableHandler);
	}
}
