

















































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import Form from "@/form/Form.vue";
import TextField from "@/form/TextField.vue";
import FieldGroup from "@/form/FieldGroup.vue";
import axios from "@/utils/ApiUtils";
import Multiselect from "vue-multiselect";
import Button from "@/form/Button.vue";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import { toastErrorMessage } from "@/plugins/toasts";
import ModalWithSaveAndCancel from "@/components/ModalWithSaveAndCancel.vue";
import SelectField from "@/form/SelectField.vue";
import {
	searchEmployeesInABatchURL,
	searchEmployeesURL,
} from "@/constants/apiconstants";
import { debounce } from "lodash";
import MultiSelect from "@/form/MultiSelect.vue";
import { trimStringPropsForObject } from "@/utils/CommonUtils";
import { SearchEmployeeModel } from "@/models/ContributionRow";
import { hasPermission } from "@/utils/PermissionUtils";

@Component({
	components: {
		MultiSelect,
		ModalWithSaveAndCancel,
		FieldGroup,
		TextField,
		Form,
		Layout,
		Multiselect,
		Button,
		SelectField,
	},
})
//Searches Employees within reporting centres allowed for a batch if parentBatchId is specified
// else searches employees within reporting centres for which the user has permission to
export default class SearchEmployee extends Vue {
	private isLoading = false;
	private noResults = false;
	private employeeList: SearchEmployeeModel[] = [];
	private selectedEmployee: SearchEmployeeModel | null = null;
	private queryStr: string | null = null;
	@Prop() parentBatchId!: number;
	private readonly maximumResults = 10;

	private searchEmployees(query: string) {
		if (!query) {
			return;
		}
		query = query.trim();
		if (query.length <= 2) {
			return;
		}
		this.queryStr = query;
		this.debounceGetData();
	}

	private debounceGetData = debounce(this.getData, 300);
	private getData() {
		this.isLoading = true;
		this.selectedEmployee = null;
		this.employeeList = [];
		const url = this.parentBatchId
			? searchEmployeesInABatchURL(this.parentBatchId)
			: searchEmployeesURL;
		axios
			.get<SearchEmployeeModel[]>(
				`${url}?query=${this.queryStr}&limit=${this.maximumResults + 1}`
			) // detect over limit by fetching an extra row
			.then((response) => {
				this.employeeList = response.data;
				if (!response.data || response.data.length === 0) {
					this.noResults = true;
					this.selectedEmployee = null;
				} else {
					this.noResults = false;
				}
			})
			.catch((error) => {
				this.noResults = true;
				toastErrorMessage(parseErrorMessage(error));
			})
			.finally(() => {
				this.isLoading = false;
			});
	}

	/**
	 * See customLabel prop of multiselect component
	 */
	private employeeLabel(option: SearchEmployeeModel, label: string): string {
		const optionVal: any = option;
		return `${optionVal.surname} - ${optionVal.givenNames} - ${optionVal.payrollNo} - ${optionVal.reportingCentreName}`;
	}

	get showAddEmployee() {
		if (
			this.selectedEmployee &&
			this.selectedEmployee.givenNames &&
			this.selectedEmployee.givenNames !== ""
		) {
			// Remove all leading and trialing white spaces
			this.selectedEmployee = trimStringPropsForObject(
				this.selectedEmployee
			);

			this.$emit("set-employee", this.selectedEmployee);
			return true;
		}

		return false;
	}

	updateSelectedEmployee(employee: SearchEmployeeModel) {
		this.selectedEmployee = employee;
	}

	hasPermission(permission: string) {
		return hasPermission(permission);
	}
}
