





























































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class ErrorList extends Vue {
	@Prop([Array, String]) errors!: string | string[];

	@Prop({ type: Boolean, default: false }) horizontal!: boolean;

	@Prop({ type: Boolean, default: true }) displayUnexpectedError!: boolean;

	@Prop({ type: Boolean, default: true }) displayBullet!: boolean;

	private seperator = ",";

	/**
	 * computedErrors will remove any duplicate strings given when rendering the
	 * errors.
	 *
	 * This was done to remove duplicates like "Network Error" from printing two or more times
	 * when we collect errors per request we made naively and the server is down.
	 */
	get computedErrors(): string[] {
		if (typeof this.errors === "string") {
			return [this.errors];
		}
		const foundMap: { [key: string]: boolean } = {};
		const newErrors: string[] = [];
		for (const error of this.errors) {
			if (foundMap[error] !== undefined) {
				// Skip if already found this error.
				continue;
			}
			newErrors.push(error);
			foundMap[error] = true;
		}
		return newErrors;
	}
}
