





































import Vue from "vue";
import { Component } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import Button from "@/form/Button.vue";
import axios from "@/utils/ApiUtils";
import { getAPIRoot } from "@/constants/apiconstants";
import { toastErrorMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";

interface FileDetails {
	link: string;
	title: string;
	filename: string;
}

@Component({
	components: {
		Layout,
		Button,
	},
})
export default class DocumentationPage extends Vue {
	private readonly documentsList: FileDetails[] = [
		{
			title: "Iress SuperConnector training guide",
			link: `${getAPIRoot()}/help/docs/Iress_SuperConnector_training_guide_for_employers.pdf`,
			filename: "Iress SuperConnector training guide for employers.pdf",
		},
		{
			title: "Detailed SAFF usage guide",
			link: `${getAPIRoot()}/help/docs/Detailed_SAFF_usage_guide.pdf`,
			filename: "Detailed SAFF usage guide.pdf",
		},
		{
			title: "Supplement to Detailed SAFF usage guide",
			link: `${getAPIRoot()}/help/docs/Supplement_to_Detailed_SAFF_usage_guide.pdf`,
			filename: "Supplement to Detailed SAFF usage guide.pdf",
		},
	];

	private viewDocumentLink = "";

	beforeMount() {
		this.loadHelpDocument();
	}

	private get isChrome(): boolean {
		return window.navigator.userAgent.indexOf("Chrome") != -1;
	}

	private loadHelpDocument() {
		const fileUrl = `${getAPIRoot()}/help/docs/Iress_SuperConnector_training_guide_for_employers.pdf`;
		const filename =
			"Iress SuperConnector training guide for employers.pdf";
		axios
			.get(fileUrl, {
				responseType: "blob",
			})
			.then((response) => {
				const blob = new Blob([response.data], {
					type: "application/pdf",
				});
				if (window.navigator && window.navigator.msSaveOrOpenBlob) {
					window.navigator.msSaveOrOpenBlob(blob, filename);
				} else {
					this.viewDocumentLink = `${window.URL.createObjectURL(
						blob
					)}${
						this.isChrome ? "#toolbar=0&navpanes=1&view=FitH" : ""
					}`;
				}
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
	}

	private onDownloadDocument(fileUrl: string, filename: string) {
		axios
			.get(fileUrl, {
				responseType: "blob",
			})
			.then((response) => {
				const blob = new Blob([response.data]);
				if (window.navigator && window.navigator.msSaveBlob) {
					window.navigator.msSaveBlob(blob, filename);
				} else {
					const url = window.URL.createObjectURL(blob);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", filename);
					link.click();
				}
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
	}
}
