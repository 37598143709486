export interface SecretCodeState {
	secretCode: string;
}

export interface AuthorizedContactState {
	authorizedContactFirstName: string;
	authorizedContactLastName: string;
	authorizedContactPosition: string;
	authorizedContactEmail: string;
	authorizedContactMobile: string;
}

export interface BusinessDetailsState {
	abn: string;
	registeredName: string;
	tradingName: string;
	addressLine1: string;
	addressLine2: string;
	suburb: string;
	postcode: string;
	state: string;
}

export interface RefundAccountState {
	refundAccountBsb: string;
	refundAccountNumber: string;
	refundAccountName: string;
}

export interface OtherState {
	contributionSubmissionMethod: ContributionSubmissionMethod;
	dateFormat: DateFormat;
	defaultFund: string;
	defaultFundOther: string;
	numberOfEmployees: number | "";
}

export interface RegistrationOfInterestState
	extends SecretCodeState,
		AuthorizedContactState,
		BusinessDetailsState,
		RefundAccountState,
		OtherState {}

export enum PaymentType {
	DIRECT_DEBIT = "DIRECT_DEBIT",
	ELECTRONIC_FUNDS_TRANSFER = "ELECTRONIC_FUNDS_TRANSFER",
	BPAY = "BPAY",
}
export enum ContributionSubmissionMethod {
	FILE_UPLOAD = "FILE_UPLOAD",
	MANUAL_GRID_ENTRY = "MANUAL_GRID_ENTRY",
	BOTH = "BOTH",
}

export enum DateFormat {
	"YYYY-MM-DD" = "yyyy-M-d",
	"YY-MMM-DD" = "yy-MMM-d",
	"YY-MM-DD" = "yy-M-d",
	"YYYY-MMM-DD" = "yyyy-MMM-dd",
	"YYYY MMM DD" = "yyyy MMM dd",
	"YYYY/MM/DD" = "yyyy/M/d",
	"YY/MM/DD" = "yy/M/d",
	"MMM-DD-YY" = "MMM-d-y",
	"MM-DD-YYYY" = "M-d-yyyy",
	"MM-DD-YY" = "M-d-yy",
	"MMM-DD-YYYY" = "MMM-d-yyyy",
	"MMM DD YYYY" = "MMM d yyyy",
	"MM/DD/YYYY" = "M/d/yyyy",
	"MM/DD/YY" = "M/d/yy",
	"DD-MMM-YY" = "d-MMM-yy",
	"DD-MM-YYYY" = "d-M-yyyy",
	"DD-MM-YY" = "d-M-yy",
	"DD-MMM-YYYY" = "d-MMM-yyyy",
	"DD MMM YYYY" = "d MMM yyyy",
	"DD/MM/YYYY" = "d/M/yyyy",
	"DD/MM/YY" = "d/M/yy",
}

export enum DefaultFund {
	GUILD_SUPER = "GUILD_SUPER",
	CHILDCARE_SUPER = "CHILDCARE_SUPER",
	OTHER = "OTHER",
}

export type PartialRegistrationOfInterestState = Partial<
	RegistrationOfInterestState
>;
