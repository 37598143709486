import { Module } from "vuex";

import { RootState } from "@/store/root";
import { getters } from "./loginGetters";
import { actions } from "./loginActions";
import { mutations } from "./loginMutations";
import { LoginPhase, LoginState } from "./loginTypes";

export const getDefaultState: () => Readonly<LoginState> = () => ({
	errorMessage: null,
	phase: LoginPhase.CRED_DEFAULT,
});

export const login: Module<LoginState, RootState> = {
	namespaced: true,
	state: getDefaultState,
	getters,
	actions,
	mutations,
};
