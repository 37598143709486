

















































































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import TextField from "@/form/TextField.vue";
import SelectField from "@/form/SelectField.vue";
import FieldGroup from "@/form/FieldGroup.vue";
import {
	RCStrategyTypes,
	ReportingCentreDetail,
} from "@/pages/maintenanceTypes";
import SubHeading from "@/components/SubHeading.vue";
import { stateList } from "@/constants/constants";
import { SelectOption } from "@/form/FieldOptions";
import AlertInfo from "@/components/AlertInfo.vue";

@Component({
	components: {
		Layout,
		TextField,
		FieldGroup,
		SelectField,
		SubHeading,
		AlertInfo,
	},
})
export default class ReportingCentreDetails extends Vue {
	@Prop({ required: true })
	readonly rcDetail!: ReportingCentreDetail;
	@Prop(Boolean) readonly readonly!: boolean;

	private readonly states: SelectOption[] = stateList;

	onFieldUpdate(field: string, value: string) {
		this.$emit("change", { ...this.rcDetail, [field]: value });
	}

	private get isValidIdentificationStrategy() {
		return (
			this.rcDetail.rcIdentificationStrategy &&
			(this.isStrategyABN ||
				this.isStrategyLocationId ||
				this.isStrategyBankAccount)
		);
	}

	private get isStrategyABN() {
		return this.rcDetail.rcIdentificationStrategy === RCStrategyTypes.ABN;
	}

	private get isStrategyLocationId() {
		return (
			this.rcDetail.rcIdentificationStrategy === RCStrategyTypes.ABN_LC
		);
	}

	private get isStrategyBankAccount() {
		return (
			this.rcDetail.rcIdentificationStrategy === RCStrategyTypes.ABN_BK
		);
	}
}
