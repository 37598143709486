var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',{staticClass:"ContributionPage",attrs:{"no-container":""}},[_c('Container',[_c('ProgressArrow',{attrs:{"steps":_vm.steps}}),(_vm.errors && _vm.errors.length > 0)?_c('ErrorList',{attrs:{"errors":_vm.errors}}):_vm._e(),_c('PageHeader',{attrs:{"title":_vm.title,"icon":['fal', 'tasks']}})],1),_c('Container',[_c('ContributionSummaryComponent',{attrs:{"summary":_vm.contributionSummary,"gridReady":_vm.gridReady}}),_c('ContributionEmployeeGridMaintenance',{key:'empgridkey_' + _vm.gridRefreshKey,attrs:{"is-form-readonly":!_vm.contributionSummary || !_vm.contributionSummary.canModifyBatch,"batch-id":_vm.parentBatchId,"grid-ready-changed":_vm.onGridReady,"refresh-summary":_vm.refreshSummary,"fund-field-mappings":_vm.fundFieldMappings,"contribution-file-type":_vm.contributionSummary &&
				_vm.contributionSummary.contributionFileType}})],1),_c('Container',[_c('LeftRightFooter',{staticClass:"mt-1",scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('Button',{attrs:{"disabled":!_vm.canClickBack},on:{"click":_vm.onClickBack}},[_vm._v(" Back ")]),(!_vm.emptyRowData)?_c('Button',{attrs:{"variant":"primary","disabled":_vm.isDownloadingRows},on:{"click":_vm.downloadAllRows}},[_vm._v(" Print contribution batch ")]):_vm._e(),(
						_vm.contributionSummary &&
						_vm.contributionSummary.showSplitBatchOption
					)?_c('Button',{attrs:{"variant":"primary","disabled":!_vm.contributionSummary.canSplitBatch,"data-cy":"splitButton"},on:{"click":function($event){_vm.splitBatchWarning = true}}},[_vm._v(" Move employees with errors into a new batch ")]):_vm._e()]},proxy:true}])},[[_c('Button',{attrs:{"disabled":!_vm.canClickCancelBatch},on:{"click":_vm.onClickCancelBatch}},[_vm._v(" Cancel contribution batch ")]),(
						_vm.contributionSummary && !_vm.contributionSummary.refresh
					)?_c('Button',{attrs:{"variant":"primary","disabled":!_vm.canClickNext,"data-cy":"validateContentNextButton"},on:{"click":_vm.onClickSubmitOrNext}},[_vm._v(" Next ")]):_vm._e(),_c('RefreshButton',{directives:[{name:"show",rawName:"v-show",value:(
						_vm.contributionSummary && _vm.contributionSummary.refresh
					),expression:"\n\t\t\t\t\t\tcontributionSummary && contributionSummary.refresh\n\t\t\t\t\t"}],attrs:{"interval-id":"validateContent","can-refresh":_vm.canSendToFund,"trigger-refresh":_vm.refreshButtonClicked,"auto-refresh":_vm.contributionSummary && _vm.contributionSummary.refresh,"refresh-interval":_vm.contributionSummary &&
						_vm.contributionSummary.refreshInterval,"button-name":"Next","data-cy":"validateContentRefreshButton"}})]],2)],1),(_vm.isCancelModalShown)?_c('ModalWithSaveAndCancel',{attrs:{"title":"Cancel Contribution batch"},on:{"cancel":function($event){return _vm.closeCancelBatch()},"confirm":function($event){return _vm.triggerBatchCancel()}}},[_c('strong',[_vm._v("Cancelling the contribution batch will delete records of the contribution batch from the portal")])]):_vm._e(),(_vm.splitBatchWarning)?_c('ModalWithSaveAndCancel',{attrs:{"title":"Update batch confirmation"},on:{"cancel":function($event){_vm.splitBatchWarning = false},"confirm":_vm.splitBatch}},[_c('strong',[_vm._v("You are about to transfer employee(s) that have error(s) from this batch to a new batch. Please confirm.")])]):_vm._e(),(_vm.isSplittingBatch)?_c('Modal',{attrs:{"title":"Updating batch","close-button-name":'Refresh',"close-button-disabled":_vm.splitRefreshCtr > 0},on:{"close":_vm.splitBatchRefreshPage}},[_c('br'),_vm._v(" Please click the 'Refresh' button to view the progress of the batch. The button will be enabled after "+_vm._s(_vm.splitRefreshCtr)+" seconds. "),_c('br')]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }