















import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";

@Component
export default class PageHeader extends Vue {
	@Prop({ type: String, default: "" }) readonly title!: string;
	@Prop(Array) readonly icon!: string[];
}
