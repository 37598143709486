











































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class Container extends Vue {
	@Prop({ type: String, default: "lg" }) size!: "md" | "sm" | "fill" | "lg";

	@Prop({ type: Boolean, default: false }) noPadding!: boolean;
}
