














































import Vue from "vue";
import Component from "vue-class-component";
import axios from "@/utils/ApiUtils";
import { insight, insightOneLiners } from "@/constants/apiconstants";
import PieChart from "@/components/charts/PieChart.vue";
import ColumnChart from "@/components/charts/ColumnChart.vue";
import InsightOneLiner from "@/components/InsightOneLiner.vue";

import {
	commitToModule,
	INSIGHTS_STORE,
	registerModule,
} from "@/store/modules/insight";
import { InsightState } from "@/store/modules/insight/insightStoreTypes";

@Component({
	components: {
		InsightOneLiner,
		ColumnChart,
		PieChart,
	},
})
export default class InsightCarousel extends Vue {
	private insightStore: InsightState = {
		fundTotalData: [],
		batchTotalData: [],
		oneLiners: [],
	};

	moreThanOneSlide() {
		return (
			(this.insightStore.fundTotalData.length > 0 &&
				this.insightStore.batchTotalData.length > 0) ||
			(this.insightStore.fundTotalData.length > 0 &&
				this.insightStore.oneLiners.length > 0) ||
			(this.insightStore.batchTotalData.length > 0 &&
				this.insightStore.oneLiners.length > 0)
		);
	}

	getFromStoreOrFetch(apiEndpoint: string, dataType: string) {
		if (this.$store.getters[`${INSIGHTS_STORE}/${dataType}`].length === 0) {
			axios.get(apiEndpoint).then((resp) => {
				commitToModule(this.$store, {
					name: dataType,
					data: resp.data,
				});
				this.insightStore =
					this.$store.getters[`${INSIGHTS_STORE}/getAll`];
			});
		} else {
			this.insightStore = this.$store.getters[`${INSIGHTS_STORE}/getAll`];
		}
	}

	mounted() {
		registerModule(this.$store);
		this.insightStore = this.$store.getters[`${INSIGHTS_STORE}/getAll`];

		this.getFromStoreOrFetch(insightOneLiners(), "oneLiners");
		this.getFromStoreOrFetch(insight("batch-totals"), "batchTotalData");
		this.getFromStoreOrFetch(insight("fund-breakdown"), "fundTotalData");
	}
}
