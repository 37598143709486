




























import Vue from "vue";
import Layout from "@/components/Layout.vue";
import PageHeader from "@/components/PageHeader.vue";
import { Component, Prop } from "vue-property-decorator";
import { capitalize } from "lodash-es";
import WizardForm, {
	FormSubmissionConfig,
	SchemaForm,
} from "@/components/WizardForm.vue";
import axios from "@/utils/ApiUtils";
import { definedBenefitEmployee } from "@/constants/apiconstants";
import { RoutePath } from "@/router/routePath";
import { ToastMessageType, toastErrorMessage } from "@/plugins/toasts";

type Mode = "add" | "edit" | "view";
type ModeText = "Add" | "Edit" | "View";

@Component({
	components: {
		WizardForm,
		Layout,
		PageHeader,
	},
})
export default class AddDbEmployee extends Vue {
	/**
	 * route params prop
	 */
	@Prop(String) mode!: Mode;
	@Prop(Number) reportingCentreId!: number;

	private readonly vuexStore = "addDbEmployee";

	private readonly resourceEndpoint = `${definedBenefitEmployee()}/add/${
		this.reportingCentreId
	}`;
	private readonly submitSettings: FormSubmissionConfig = {
		endpoint: this.resourceEndpoint,
		successRedirect: RoutePath.EmployeeList,
		toastMessages: [
			{
				code: 200,
				message: {
					message: "New DB employee added",
					type: ToastMessageType.SUCCESS,
				},
			},
			{
				code: "anyError",
				message: {
					message: "Something went wrong",
					type: ToastMessageType.ERROR,
				},
			},
		],
	};
	private formLoaded = false;
	private formLoadFailed = false;

	private get modeText(): ModeText {
		return capitalize(this.mode.replace("db-", "")) as ModeText;
	}

	private schema: SchemaForm = {
		data: {},
		fieldMap: {},
		steps: {},
		requestStructure: [],
	};

	private cancel() {
		this.$router.push(RoutePath.EmployeeList);
	}

	created() {
		axios
			.get(this.resourceEndpoint)
			.then((res) => {
				this.schema = res.data;
				this.formLoaded = true;
			})
			.catch((e) => {
				toastErrorMessage(e.response.data.message);
				this.formLoadFailed = true;
			});
	}
}
