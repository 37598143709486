

























import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import axios from "@/utils/ApiUtils";
import { addBulkFile } from "@/constants/apiconstants";
import { parseErrorMessageList } from "@/utils/ErrorUtils";
import FileUpload from "@/form/FileUpload.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import Button from "@/form/Button.vue";
import SelectField from "@/form/SelectField.vue";
import { toastInfoMessage } from "@/plugins/toasts";

@Component({
	components: {
		FileUpload,
		ProgressBar,
		Button,
		SelectField,
	},
})
export default class BulkFileUploadForm extends Vue {
	@Prop(String) entity!: string;
	@Prop(String) fileFormatId!: string;
	@Prop(String) payeeRelationshipId!: string;

	private readonly acceptBulkFileExt = ".csv, .txt";
	private files: any[] = [];
	errors: string[] = [];
	isSubmitting = false;
	progress: number | null = null;

	get hasError(): boolean {
		return this.errors && this.errors.length > 0;
	}

	@Watch("files")
	onFilesChanged() {
		this.errors = [];
		this.progress = null;

		if (this.files && this.files.length > 0) {
			this.triggerSubmit();
		}
	}

	triggerSubmit(): void {
		if (this.isSubmitting) {
			return;
		}

		this.isSubmitting = true;
		this.progress = 0;

		// Submit
		const fd = new FormData();
		fd.append("bulkFile", this.files[0]);
		axios
			.post<string>(
				addBulkFile(
					this.entity,
					this.fileFormatId,
					this.payeeRelationshipId
				),
				fd,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
					onUploadProgress: this.onUploadProgress,
				}
			)
			.then((response) => {
				this.onSubmitSuccess();
			})
			.catch((error) => {
				this.errors = parseErrorMessageList(error);
			})
			.finally(() => {
				this.progress = 1;
				this.isSubmitting = false;
			});
	}

	onUploadProgress = (progressEvent: ProgressEvent): void => {
		this.progress = progressEvent.loaded / progressEvent.total;
	};

	onSubmitSuccess() {
		toastInfoMessage("File is uploaded and is being processed.");
		this.$emit("uploaded");
	}
}
