import axios from "axios";

import { getSiteRoot } from "@/constants/apiconstants";
import store from "@/store";
import { applySpringBootInterceptor } from "@/utils/AxiosSpringBootInterceptor";

axios.defaults.baseURL = getSiteRoot();

const instance = axios.create();

interface TokenError {
	response?: {
		status: number;
		data?: {
			error: string;
		};
	};
}

/**
 * Queue the http calls till the expired access token is not refreshed. Once expired access token
 * is refreshed, all the queued up calls will fire asynchronously using the new access token.
 * This solution of using queue to fix access token expiration issue was referred from
 * https://gist.github.com/mkjiau/650013a99c341c9f23ca00ccb213db1c#gistcomment-2597141
 *
 * isAlreadyFetchingAccessToken: Flag used to allowing that call to refresh access token is fired
 * only once
 *
 * failedRequestQueue: An array to queue up failed request due to access token expiry
 */
let isAlreadyFetchingAccessToken = false;
let failedRequestQueue: Array<any> = [];

/**
 * Fires the queued up request calls and then clears the array
 * @param accessToken
 */
function onAccessTokenFetched(accessToken: string) {
	failedRequestQueue = failedRequestQueue.filter((callback) =>
		callback(accessToken)
	);
}

/**
 * Registers the failed request calls into the array
 * @param callback
 */
function addSubscriber(callback: any) {
	failedRequestQueue.push(callback);
}

/**
 * Request interceptor so that objects are serialized to support Spring Boot
 *
 * Issue is defined here:
 * - https://stackoverflow.com/questions/5900840/post-nested-object-to-spring-mvc-controller-using-json/7203353#7203353
 */
applySpringBootInterceptor(instance);

/**
 * If api response with 401 and a message starts with __client_id__, it is
 * a access token expired response and need a retry
 */
export function isAccessTokenExpiredError(error: TokenError): boolean {
	return Boolean(
		typeof error === "object" &&
			typeof error.response === "object" &&
			error.response.status === 401
	);
}

instance.interceptors.request.use(
	function (config) {
		const isLoggedIn = Boolean(store.getters["persistent/isLoggedIn"]);
		const accessToken = store.getters["persistent/accessToken"];

		if (!isLoggedIn || !accessToken || config.url === "/recaptchaSiteKey") {
			return config;
		}
		config.headers.Authorization = "Bearer " + accessToken;
		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	(response) => {
		return response;
	},
	function (error): Promise<any> {
		const originalRequest = error.config;

		if (isAccessTokenExpiredError(error)) {
			if (!isAlreadyFetchingAccessToken) {
				isAlreadyFetchingAccessToken = true;
				store
					.dispatch("persistent/refreshTokenFetch", store.getters)
					.then(
						(response) => {
							isAlreadyFetchingAccessToken = false;
							const accessToken: string =
								store.getters["persistent/accessToken"];
							onAccessTokenFetched(accessToken);
						},
						(error: any) => {
							isAlreadyFetchingAccessToken = false;
							store.dispatch("resetState");
							return Promise.reject(error);
						}
					);
			}

			const retryOriginalRequest = new Promise((resolve) => {
				addSubscriber((accessToken: string) => {
					if (!accessToken) {
						store.dispatch("resetState");
						return Promise.reject(
							"Token has expired. Must re-login"
						);
					}
					originalRequest.headers.Authorization =
						"Bearer " + accessToken;
					resolve(axios(originalRequest));
				});
			});
			return retryOriginalRequest;
		}
		return Promise.reject(error);
	}
);

export default instance;
export { axios as axiosStatic };
export { CancelTokenSource, CancelToken } from "axios";
