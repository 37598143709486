












































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import ReportTable from "@/components/ReportTable.vue";
import Layout from "@/components/Layout.vue";
import PageHeader from "@/components/PageHeader.vue";
import EmployerSelector from "@/components/EmployerSelector.vue";
import { EmployerHierarchy } from "@/store/modules/persistent/persistentTypes";
import Grid from "@/grid/Grid.vue";
import { createNamespacedHelpers } from "vuex";
import DatepickerField from "@/form/DatepickerField.vue";
import Button from "@/form/Button.vue";
import { reportsURL } from "@/constants/apiconstants";
import axios from "@/utils/ApiUtils";
import { toastErrorMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import { ColDef, ColGroupDef } from "ag-grid-community";
import {
	columnCurrencyFormatter,
	columnDateFormatter,
	getDateForPastNMonths,
} from "@/utils/CommonUtils";
import { FilterModel } from "@/grid/gridTypes";

const { mapState, mapMutations } = createNamespacedHelpers("persistent");

interface EmployeeRefundV2Dto {
	batchId: number;
	payPeriodStart: string;
	payPeriodEnd: string;
	abn: string;
	employer: string;
	locationId: string;
	reportingCentre: string;
	employeeSurname: string;
	employeeGivenName: string;
	dateOfBirth: string;
	contribution: number;
	refund: number;
	contType: string;
	transactionDate: string;
	contReference: string;
	refundReference: string;
	usi: string;
	fundName: string;
	status: string;
}

@Component({
	components: {
		PageHeader,
		Layout,
		ReportTable,
		EmployerSelector,
		DatepickerField,
		Button,
		Grid,
	},
	computed: mapState(["selectedEntities", "employerHierarchy"]),
	methods: {
		...mapMutations(["setSelectedEntities"]),
	},
})
export default class RefundReportPageV2 extends Vue {
	private errorMessage: string | null = null;
	private showSearch = false;
	selectedEntities!: string[];
	employerHierarchy!: EmployerHierarchy[];
	private rowData: EmployeeRefundV2Dto[] = [];
	private showResult = false;

	private readonly columnDefs: (ColGroupDef | ColDef)[] = [
		{
			headerName: "Batch ID",
			field: "batchId",
		},
		{
			headerName: "Pay period start",
			field: "payPeriodStart",
		},
		{
			headerName: "Pay period end",
			field: "payPeriodEnd",
		},
		{
			headerName: "ABN",
			field: "abn",
		},
		{
			headerName: "Employer",
			field: "employer",
		},
		{
			headerName: "Location ID",
			field: "locationId",
		},
		{
			headerName: "Reporting centre",
			field: "reportingCentre",
		},
		{
			headerName: "Employee surname",
			field: "employeeSurname",
		},
		{
			headerName: "Employee given name",
			field: "employeeGivenName",
		},
		{
			headerName: "Date of birth",
			field: "dateOfBirth",
			valueFormatter: columnDateFormatter,
		},
		{
			headerName: "Contribution",
			field: "contribution",
			valueFormatter: columnCurrencyFormatter,
		},
		{
			headerName: "Refund",
			field: "refund",
			valueFormatter: columnCurrencyFormatter,
		},
		{
			headerName: "Cont type",
			field: "contType",
		},
		{
			headerName: "Transaction date",
			field: "transactionDate",
			valueFormatter: columnDateFormatter,
		},
		{
			headerName: "Cont reference",
			field: "contReference",
		},
		{
			headerName: "Refund reference",
			field: "refundReference",
		},
		{
			headerName: "USI",
			field: "usi",
		},
		{
			headerName: "Fund name",
			field: "fundName",
		},
		{
			headerName: "Status",
			field: "status",
		},
	];

	private readonly filterModel: FilterModel = {
		startDate: {
			value: getDateForPastNMonths(6),
			column: "startDate",
		},
		endDate: {
			value: "",
			column: "endDate",
		},
	};

	public $refs!: {
		gridEl: Grid;
	};
	private gridRequest = {
		filters: Object.keys(this.filterModel).map((key) => {
			return this.filterModel[key];
		}),
		orders: null,
	};

	updateGridRequest() {
		this.gridRequest = {
			filters: Object.keys(this.filterModel).map((key) => {
				return this.filterModel[key];
			}),
			orders: null,
		};
	}

	/**
	 * Type the mapped persistent.setSelectedEntities mutation.
	 */
	setSelectedEntities!: (selectedEntities: string[]) => void;

	get selectedReportingCenter() {
		return this.$store.state.persistent.selectedEntities;
	}

	@Watch("selectedReportingCenter")
	onSelectedReportingCenterChanged() {
		this.showSearch = this.selectedReportingCenter.length === 1;
		this.showResult = false;
	}

	private clearErrorMessage(): void {
		this.errorMessage = null;
	}

	private generateReport() {
		axios
			.post(
				reportsURL() + "/employee-refund/v2",
				{
					entities: this.selectedReportingCenter,
					request: JSON.parse(JSON.stringify(this.gridRequest)),
				},
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			)
			.then((response) => {
				this.rowData = response.data;
				this.showResult = true;
				if (
					this.rowData &&
					this.rowData.length === 0 &&
					this.$refs.gridEl
				) {
					this.$refs.gridEl.showNoRowsOverlay();
				}
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
	}
}
