







import Vue, { CreateElement } from "vue";
import { Component, Prop } from "vue-property-decorator";
import Button from "@/form/Button.vue";
import { BSidebar } from "bootstrap-vue";
import { VNode } from "node_modules/vue/types/umd";

@Component({
	components: {
		Button,
	},
})
export default class GridFilter extends Vue {
	@Prop(Boolean)
	readonly disabled!: boolean;

	selectedFilterCount = 0;

	render(createElement: CreateElement) {
		this.selectedFilterCount = this.computedSelectedFilterCount(
			this.$slots.default
		);
		return createElement("div", [
			createElement(
				Button,
				{
					directives: [
						{
							name: "b-toggle",
							modifiers: { "sidebar-filter": true },
						},
					],
					attrs: {
						variant: "primary",
						disabled: this.disabled,
						dataCy: "filterButton",
					},
					scopedSlots: {
						default: () => [
							createElement("font-awesome-icon", {
								staticClass: "mr-1",
								attrs: { icon: ["fal", "filter"] },
							}),
							"Filter",
							createElement(
								"span",
								{
									staticClass:
										"ml-1 p-1 selected-filter-count",
								},
								[String(this.selectedFilterCount)]
							),
						],
					},
				},
				[]
			),
			createElement(
				BSidebar,
				{
					attrs: {
						id: "sidebar-filter",
						shadow: true,
						backdrop: true,
						"no-header": true,
					},
				},
				[
					createElement("div", { staticClass: "p-3" }, [
						createElement(
							"div",
							{
								staticClass: "d-flex justify-content-between",
							},
							[
								createElement("h3", [
									createElement("font-awesome-icon", {
										staticClass: "mr-2",
										attrs: {
											icon: ["fal", "filter"],
										},
									}),
									"Filter",
								]),
								createElement(
									Button,
									{
										directives: [
											{
												name: "b-toggle",
												modifiers: {
													"sidebar-filter": true,
												},
											},
										],
										attrs: { variant: "link" },
										on: { click: this.resetFilters },
									},
									["Reset"]
								),
							]
						),
						[this.$slots.default],
					]),
				]
			),
		]);
	}

	computedSelectedFilterCount(vNodes?: VNode[]) {
		if (!vNodes) {
			return 0;
		}

		let count = 0;
		for (const vNode of vNodes) {
			const propsData:
				| { [propName: string]: any | undefined }
				| undefined = vNode.componentOptions?.propsData;
			if (!propsData) {
				continue;
			}

			if (propsData.value && propsData.label) {
				// Handle multiselect
				if (
					Array.isArray(propsData.value) &&
					propsData.value.length === 0
				) {
					continue;
				}
				count++;
			}
		}
		return count;
	}

	private resetFilters() {
		this.$emit("reset-filters");
	}
}
