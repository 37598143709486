



import Vue from "vue";
import { Component } from "vue-property-decorator";
import { TranslationMapping } from "@/grid/gridTypes";

@Component({})
export default class ValueTranslatedCellRenderer extends Vue {
	private params: any;
	private style = "";

	get displayValue() {
		if (this.params.translationMappings) {
			const translationMappings: TranslationMapping[] = this.params
				.translationMappings;
			const foundMapping = translationMappings.find(
				(element: TranslationMapping) => {
					return this.params.value === element.value;
				}
			);
			if (foundMapping && foundMapping.style) {
				this.style = foundMapping.style;
			}
			return foundMapping
				? foundMapping.translatedValue
				: this.params.value;
		}
		return null;
	}
	refresh(params: any) {
		if (params.value !== this.params.value) {
			this.params = params;
		}
		return true;
	}
	constructor() {
		super();
	}
}
