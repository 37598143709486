var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ProgressArrow__root pr-3 pl-2 mb-1"},[_c('ol',{staticClass:"ProgressArrow__list"},_vm._l((this.steps),function(item,index){return _c('li',{key:'ProgressArrow' + index},[_c('div',{class:{
					ProgressArrow__pointer: true,
					'ProgressArrow__pointer--done': item.done,
					'ProgressArrow__pointer--active': item.active,
					'ProgressArrow__pointer--disabled': item.disabled,
				},attrs:{"title":item.disabled ? item.disabledTooltip : ''}},[_c('span',{staticClass:"ProgressArrow__pointer__start"}),_c('p',{staticClass:"ProgressArrow__text"},[_vm._v(" "+_vm._s(index + 1 + " " + item.label)+" ")]),_c('span',{class:{
						ProgressArrow__pointer__end: true,
						'ProgressArrow__pointer__end--done': item.done,
						'ProgressArrow__pointer__end--active': item.active,
						'ProgressArrow__pointer__end--disabled':
							item.disabled,
					}})])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }