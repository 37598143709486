



















































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { BvTableFieldArray } from "bootstrap-vue/src/components/table";

import Button from "@/form/Button.vue";
import ButtonBehaviour from "@/form/ButtonBehaviour.vue";
import ModalWithSaveAndCancel from "@/components/ModalWithSaveAndCancel.vue";
import TextField from "@/form/TextField.vue";

enum FocusType {
	ADD = "ADD",
	EDIT = "EDIT",
	REMOVE = "REMOVE",
}

interface ItemInFocus {
	[key: string]: any;
}

@Component({
	model: {
		prop: "items",
		event: "update",
	},
	components: {
		Button,
		ButtonBehaviour,
		ModalWithSaveAndCancel,
		TextField,
	},
})
export default class TableForm extends Vue {
	@Prop({ type: Array, required: true }) items!: object[];

	@Prop({ type: Array, required: true }) fields!: BvTableFieldArray;

	@Prop({ type: Boolean, default: true }) canAdd!: boolean;

	@Prop({ type: Boolean, default: true }) canEdit!: boolean;

	@Prop({ type: Boolean, default: true }) canRemove!: boolean;

	@Prop({ type: Array, default: () => [] }) validationErrors!: string[];

	itemInFocus: ItemInFocus | null = null;

	indexInFocus: number | null = null;

	focusType: FocusType | null = null;

	get defaultItem(): object {
		const defaultItem = {};
		this.fields.forEach((field) => {
			let key;
			if (typeof field === "string") {
				key = field;
			} else {
				key = field.key;
			}
			(defaultItem as any)[key] = "";
		});
		return defaultItem;
	}

	get isIndexInFocusWithinRange(): boolean {
		return this.checkIndexWithinRange(this.indexInFocus);
	}

	get tableFields(): Readonly<BvTableFieldArray> {
		return Object.freeze([
			{ key: "number", label: "No." },
			...this.fields,
			{ key: "actions", label: "Actions", width: 65 },
		]);
	}

	get shouldShowForm() {
		return (
			this.itemInFocus &&
			(this.focusType === FocusType.ADD ||
				this.focusType === FocusType.EDIT)
		);
	}

	get shouldShowRemovalModal() {
		return this.itemInFocus && this.focusType === FocusType.REMOVE;
	}

	checkIndexWithinRange(index: number | null): index is number {
		if (typeof index === "number") {
			return index >= 0 && index < this.items.length;
		}
		return false;
	}

	showAddItemForm() {
		this.focusType = FocusType.ADD;
		this.itemInFocus = { ...this.defaultItem };
	}

	showEditItemForm(index: number | null) {
		if (this.checkIndexWithinRange(index)) {
			this.focusType = FocusType.EDIT;
			this.indexInFocus = index;
		}
	}

	closeFocusedElement() {
		if (this.isIndexInFocusWithinRange) {
			this.indexInFocus = null;
		} else {
			this.itemInFocus = null;
		}
	}

	async submitItemForm() {
		if (!(await (this.$refs.validationObserver as any).validate())) {
			return;
		}

		if (this.focusType === FocusType.ADD) {
			this.submitAddItemForm();
		} else if (this.focusType === FocusType.EDIT) {
			this.submitEditItemForm();
		}
	}

	submitAddItemForm() {
		if (this.itemInFocus) {
			const itemsCopy = [...this.items, this.itemInFocus];
			this.$emit("update", itemsCopy);
			this.itemInFocus = null;
		}
	}

	submitEditItemForm() {
		if (
			this.isIndexInFocusWithinRange &&
			this.itemInFocus &&
			// Type guard only. indexInFocus has been thoroughly checked above.
			typeof this.indexInFocus === "number"
		) {
			const itemsCopy = [
				...this.items.slice(0, this.indexInFocus),
				this.itemInFocus,
				...this.items.slice(this.indexInFocus + 1),
			];
			this.$emit("update", itemsCopy);
			this.indexInFocus = null;
		}
	}

	showRemovalModal(index: number | null) {
		if (this.checkIndexWithinRange(index)) {
			this.focusType = FocusType.REMOVE;
			this.indexInFocus = index;
		}
	}

	removeItem() {
		if (
			this.isIndexInFocusWithinRange &&
			// Type guard only. indexInFocus has been thoroughly checked above.
			typeof this.indexInFocus === "number"
		) {
			const itemsCopy = [
				...this.items.slice(0, this.indexInFocus),
				...this.items.slice(this.indexInFocus + 1),
			];
			this.$emit("update", itemsCopy);
			this.indexInFocus = null;
		}
	}

	@Watch("indexInFocus")
	handleIndexInFocusChange() {
		if (
			this.isIndexInFocusWithinRange &&
			// Type guard only. indexInFocus has been thoroughly checked above.
			typeof this.indexInFocus === "number"
		) {
			this.itemInFocus = { ...this.items[this.indexInFocus] };
		} else {
			this.itemInFocus = null;
		}
	}

	@Watch("itemInFocus")
	handleItemInFocusChange(value: ItemInFocus | null) {
		if (!value) {
			this.focusType = null;
		}
	}
}
