import {
	AuthorizedContactState,
	BusinessDetailsState,
	RefundAccountState,
	OtherState,
} from "@/store/modules/registrationofinterest/registrationOfInterestTypes";

export enum EmployerRegistrationStatus {
	PENDING = "PENDING",
	APPROVED = "APPROVED",
	DECLINED = "DECLINED",
	CREATED = "CREATED",
}

export interface EmployerRegistration
	extends AuthorizedContactState,
		BusinessDetailsState,
		RefundAccountState,
		OtherState {
	invitationCode: string;
	id: number;
	sponsorId: number;
	brandId: number;
	status: EmployerRegistrationStatus;
}
