

















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class AlertInfo extends Vue {
	@Prop(String) alertMessage!: string;
}
