






























import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

@Component({})
export default class ProgressBar extends Vue {
    /**
     * value between 0 and 1.
     * Opted to make the API similar to the semi-supported <progress-bar> HTML5 element.
     */
    @Prop([String, Number]) readonly value!: string | number;
}
