
























































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class LeftRightFooter extends Vue {
	@Prop({ type: Boolean, default: false }) readonly hasPaddingTop!: boolean;

	@Prop({ type: Boolean, default: false })
	readonly hasPaddingBottom!: boolean;
}
