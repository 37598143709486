














import Vue from "vue";
import { Component } from "vue-property-decorator";
import axios from "@/utils/ApiUtils";
import { accountBalanceTotalURL } from "@/constants/apiconstants";
import { toastErrorMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";

/**
 * This should correspond to the Java class au.com.iress.clearinghouse.portal.accountbalance.AccountBalanceResource.AccountBalanceTotalDto
 */
interface AccountBalanceTotalDto {
	display: boolean;
	accountBalanceTotal: string;
	unfundedBatchesTotal: string;
}
@Component({
	components: {},
})
export default class AccountBalanceTotal extends Vue {
	accountBalanceTotalDto: AccountBalanceTotalDto | null = null;

	mounted() {
		axios
			.get<AccountBalanceTotalDto>(accountBalanceTotalURL())
			.then((resp) => {
				this.accountBalanceTotalDto = resp.data;
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
	}
}
