import { MutationTree } from "vuex";

import { LoginPhase, LoginState } from "./loginTypes";

export const mutations: MutationTree<LoginState> = {
	loginCredSuccess(state) {
		state.errorMessage = null;
		state.phase = LoginPhase.TWOFA_DEFAULT;
	},
	loginCredError(state, payload) {
		state.errorMessage = payload;
		state.phase = LoginPhase.CRED_ERROR;
	},
	forgotPasswordSuccess(state) {
		state.errorMessage = null;
		state.phase = LoginPhase.RESET_PASSWORD_DEFAULT;
	},
	forgotPasswordError(state, payload) {
		state.phase = LoginPhase.FORGOT_PASSWORD_ERROR;
		state.errorMessage = payload;
	},
	resetPasswordSuccess(state) {
		state.errorMessage = null;
		state.phase = LoginPhase.RESET_PASSWORD_SUCCESS;
	},
	resetPasswordInvalidBrand(state) {
		state.errorMessage = null;
		state.phase = LoginPhase.RESET_PASSWORD_INVALID_BRAND;
	},
	resetPasswordError(state, payload) {
		state.errorMessage = payload;
		state.phase = LoginPhase.RESET_PASSWORD_ERROR;
	},
	loginTwoFAError(state, payload) {
		state.errorMessage = payload;
		state.phase = LoginPhase.TWOFA_ERROR;
	},
	loginTwoFASuccess(state) {
		state.errorMessage = null;
		state.phase = LoginPhase.SUCCESS;
	},
	clearErrorMessage(state) {
		state.errorMessage = null;
	},
	setPhase(state, payload: LoginPhase) {
		state.phase = payload;
	},
	setErrorMessage(state, payload: string) {
		state.errorMessage = payload;
	},
};
