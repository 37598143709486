export const computeLabel = (label: any, name: string) => {
	let newLabel = "";
	if (typeof label === "string") {
		newLabel = label;
	} else if (name) {
		// If name is all uppercase, assume it's a special case like "TFN"
		// where we don't want to give it "Sentence Case Text"
		// ie. "T F N"
		if (name === name.toUpperCase()) {
			newLabel = name;
		} else {
			// If no label defined, convert "camelCase" to "Sentence case text"
			// based on the name.
			const result = name.replace(/([A-Z]|[0-9])/g, " $1").toLowerCase();
			newLabel = result.charAt(0).toUpperCase() + result.slice(1);

			// Replace all lower case id to uppercase
			newLabel = newLabel.replace(/(id$)/gi, "ID");
		}
	}
	return newLabel;
};
