





























































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import SelectField from "@/form/SelectField.vue";
import ModalWithSaveAndCancel from "@/components/ModalWithSaveAndCancel.vue";
import Button from "@/form/Button.vue";
import TextField from "@/form/TextField.vue";
import {
	PaymentProfile,
	BasePaymentProfile,
	PaymentProfileRC,
} from "@/pages/maintenanceTypes";
import DirectDebitInfoText from "@/components/DirectDebitInfoText.vue";
import axios from "@/utils/ApiUtils";
import { toastErrorMessage, toastSuccessMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import {
	savePaymentProfile,
	updatePaymentProfile,
	retrievePaymentProfileByMemberId,
} from "@/constants/apiconstants";
import RadioGroup from "@/form/RadioGroup.vue";
import { RadioOption } from "@/form/FieldOptions";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Modal from "@/components/Modal.vue";
import LeftRightFooter from "@/components/LeftRightFooter.vue";
import { VForm } from "@/@typings/type-vee-validate";
import { Logger } from "@/utils/logger";

@Component({
	components: {
		Modal,
		TextField,
		SelectField,
		ModalWithSaveAndCancel,
		Button,
		DirectDebitInfoText,
		RadioGroup,
		ValidationObserver,
		ValidationProvider,
		LeftRightFooter,
	},
})
export default class PaymentProfileMaintenance extends Vue {
	private formPaymentProfile: BasePaymentProfile | null = null;
	private paymentProfile: PaymentProfile | null = null;
	private enableDirectDebit = false;

	@Prop({ type: Number, default: null })
	private employerId!: number | null;
	@Prop(String)
	private memberId!: string | undefined;
	// reportingCentreId is 0 if RC is not created
	@Prop({ type: Number, default: 0 })
	private reportingCentreId!: number;

	private showCancelModal = false;
	private isFormDirty = false;

	private paymentTypes: RadioOption[] = [
		{
			value: "O",
			text: "EFT or BPAY",
		},
		{
			value: "D",
			text: "Direct debit",
		},
	];

	private get addMode(): boolean {
		return this.memberId === undefined;
	}

	private exit() {
		this.isFormDirty = false;
		this.$emit("close");
	}

	private closeModal() {
		this.showCancelModal = false;
	}

	onClickCancel() {
		if (this.isFormDirty) {
			this.showCancelModal = true;
		} else {
			this.exit();
		}
	}

	async onClickSave() {
		if (!this.employerId) {
			return;
		}

		if (await (this.$refs.paymentDetailsForm as VForm).validate()) {
			if (this.addMode) {
				axios
					.post<string>(
						savePaymentProfile(
							this.employerId.toString(),
							this.reportingCentreId
						),
						this.formPaymentProfile
					)
					.then((response) => {
						toastSuccessMessage(
							"Payment profile saved successfully"
						);
						this.$emit("save");
						this.exit();
					})
					.catch((error) => {
						toastErrorMessage(parseErrorMessage(error));
					});
			} else {
				if (this.memberId) {
					axios
						.put(
							updatePaymentProfile(
								this.employerId,
								this.memberId,
								this.reportingCentreId
							),
							this.formPaymentProfile
						)
						.then((response) => {
							toastSuccessMessage(
								"Payment profile updated successfully"
							);
							this.$emit("save");
							this.exit();
						})
						.catch((error) => {
							toastErrorMessage(parseErrorMessage(error));
						});
				}
			}
		}
	}

	async beforeMount() {
		if (this.employerId !== null) {
			if (this.addMode) {
				this.enableDirectDebit = false;
				this.formPaymentProfile = {};
			} else {
				if (!this.employerId || !this.memberId) {
					Logger.warn("Missing member id.");
					return;
				}
				await axios
					.get<PaymentProfileRC>(
						retrievePaymentProfileByMemberId(
							this.employerId,
							this.memberId,
							this.reportingCentreId
						)
					)
					.then((response) => {
						const paymentProfileRC: PaymentProfileRC =
							response.data;
						if (paymentProfileRC?.paymentProfile) {
							this.paymentProfile =
								paymentProfileRC?.paymentProfile;
							this.formPaymentProfile = this.paymentProfile;
							this.enableDirectDebit =
								this.paymentProfile.debitBsb !== "" ||
								this.paymentProfile.debitBankAccountNo !== "";
						}
					})
					.catch((error) => {
						toastErrorMessage(parseErrorMessage(error));
					});
			}
		}
	}
}
