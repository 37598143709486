import { sum, multiply, zipWith } from "lodash-es";

const BSB_PATTERN = /^[0-9]{3}-?[0-9]{3}$/;
const URL_PATTERN = /^(http|https):\/\/[^ "]+$/;
const SAFE_TEXT_PATTERN = /^([a-zA-Z0-9 \n.,!?$%&*'"/()-]+)$/;
const TFN_PATTERN = /^[0-9]{3}[ -]?[0-9]{3}[ -]?[0-9]{3}$/;
const VALIDITY_CHECK_WEIGHTING_FACTORS = [1, 4, 3, 7, 5, 8, 6, 9, 10];
const VALIDITY_CHECK_8_DIGIT_WEIGHTING_FACTORS = [1, 4, 3, 7, 5, 8, 6, 10];
const NUMBER_PATTERN = /^[0-9]+$/;
const ABN_LENGTH = 11;
const USER_EMAIL_PATTERN =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const USER_MOBILE_NO_PATTERN =
	/^04(\s?[0-9]{2}\s?)([0-9]{3}\s?[0-9]{3}|[0-9]{2}\s?[0-9]{2}\s?[0-9]{2})$/;
const ALLOWED_ASCII = /^[ -~]+$/;
const STANDARD_TEXT_PATTERN = /^([0-9a-zA-Z .,?!(){}:;'|\-_=\\/@#$%*&"])*$/;

export function url(value: string) {
	return URL_PATTERN.test(value);
}

export function safeText(value: string) {
	return SAFE_TEXT_PATTERN.test(value);
}

export function isValidBsb(value: string) {
	return BSB_PATTERN.test(value);
}

export function isValidTfn(value: string) {
	if (!TFN_PATTERN.test(value)) {
		return false;
	}
	value = value.replace(/[- ]/gi, "");
	if (value === "000000000" || value === "999999999") {
		return false;
	}
	const tfnDigits = [...value].map((d) => parseInt(d));
	const computeIfValid = (weightFactors: number[]): boolean =>
		sum(zipWith(tfnDigits, weightFactors, multiply)) % 11 === 0;

	const valid =
		tfnDigits.length === 8
			? computeIfValid(VALIDITY_CHECK_8_DIGIT_WEIGHTING_FACTORS)
			: computeIfValid(VALIDITY_CHECK_WEIGHTING_FACTORS);

	return valid;
}

export function isValidAbn(value: string) {
	const strippedValue = value.replace(/\s/g, "").replace(/-/g, "");
	return (
		NUMBER_PATTERN.test(strippedValue) &&
		strippedValue.length === ABN_LENGTH
	);
}
export function isValidUserMobileNo(value: string) {
	return USER_MOBILE_NO_PATTERN.test(value);
}
export function isValidUserEmail(value: string) {
	return USER_EMAIL_PATTERN.test(value);
}

export function isValidCharacter(value: string) {
	return ALLOWED_ASCII.test(value);
}

export const isValidStandardText = (value: string): boolean => {
	return STANDARD_TEXT_PATTERN.test(value);
};
