var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"DefinedBenefitReportPage"},[_c('EmployerSelector',{attrs:{"select-level":"Batch","multiple":_vm.multiSelect,"error-message":_vm.errorMessage},on:{"clear-error-message":_vm.clearErrorMessage}}),_c('div',{staticClass:"d-flex justify-content-between button-container"},[_c('grid-filter',{on:{"reset-filters":_vm.onResetFilter}},[_c('SelectField',{attrs:{"options":_vm.reportTypes.length > 0 ? _vm.reportTypes : [''],"label":"Type","name":"type","disabled":_vm.reportTypes.length === 0,"placeholder":_vm.reportTypes.length === 0
						? 'Select an Employer first'
						: 'Select option'},on:{"input":_vm.onApplyFilter},model:{value:(_vm.filterModel.type.value),callback:function ($$v) {_vm.$set(_vm.filterModel.type, "value", $$v)},expression:"filterModel.type.value"}}),_c('SelectField',{attrs:{"options":_vm.reportStatuses,"label":"Status","name":"status"},on:{"input":_vm.onApplyFilter},model:{value:(_vm.filterModel.status.value),callback:function ($$v) {_vm.$set(_vm.filterModel.status, "value", $$v)},expression:"filterModel.status.value"}}),_c('SelectField',{attrs:{"options":_vm.employeeLevels,"label":"Employee level","name":"employeeLevel"},on:{"input":_vm.onApplyFilter},model:{value:(_vm.filterModel.employeeLevel.value),callback:function ($$v) {_vm.$set(_vm.filterModel.employeeLevel, "value", $$v)},expression:"filterModel.employeeLevel.value"}}),_c('DatepickerField',{attrs:{"label":"Created date","name":"createdDate","range":""},on:{"input":_vm.onApplyFilter},model:{value:(_vm.filterModel.createdDate.value),callback:function ($$v) {_vm.$set(_vm.filterModel.createdDate, "value", $$v)},expression:"filterModel.createdDate.value"}})],1),_c('DropdownMenu',{attrs:{"right":"","is-disabled":!_vm.canCreateReport,"hidden":!_vm.hasPermission('EDIT_DB_REPORT')},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" Create report")]},proxy:true}])},[_vm._l((this.reportTypes),function(item){return [_c('DropdownMenuItem',{key:item.value,on:{"click":function($event){return _vm.openReportModal(item.value)}}},[_vm._v(" "+_vm._s(item.label)+" ")])]})],2)],1),_c('div',{staticClass:"defined-benefit-table"},[_c('Grid',{ref:"gridEl",attrs:{"column-defs":_vm.columnDefs,"row-selection":"single","server-side-datasource":this},on:{"grid-ready":_vm.onGridReady}})],1),_c('div',{staticClass:"d-flex justify-content-between button-container"},[(_vm.canRefresh)?_c('Button',{attrs:{"disabled":!_vm.canRefresh ||
				_vm.intervalData.remaining !== 0 ||
				_vm.intervalData.isRefreshing},on:{"click":_vm.onClickRefresh}},[_vm._v(" Refresh "+_vm._s(_vm.refreshCount)+" "),(
					!_vm.refreshCount &&
					(!_vm.canRefresh ||
						_vm.intervalData.remaining !== 0 ||
						_vm.intervalData.isRefreshing)
				)?_c('font-awesome-icon',{attrs:{"icon":['fal', 'spinner'],"spin":""}}):_vm._e()],1):_vm._e()],1),_c('ValidationObserver',{ref:"dbReportForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
				var invalid = ref.invalid;
return [(_vm.showReportModal)?_c('Modal',{attrs:{"title":_vm.reportModalTitle,"allow-body-overflow":""},on:{"close":_vm.closeReportModal},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('LeftRightFooter',[[_c('Button',{on:{"click":_vm.closeReportModal}},[_vm._v(" Cancel")]),_c('Button',{attrs:{"disabled":!_vm.canCreateReport || invalid,"variant":"primary"},on:{"click":_vm.onSubmitReport}},[_vm._v(" Create report ")])]],2)]},proxy:true}],null,true)},[_c('Form',{attrs:{"height":"500"},model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}},[_c('FieldGroup',[_c('b-row',[_c('b-col',[_c('SelectField',{attrs:{"label":"Report type","options":_vm.reportTypes,"value":_vm.selectedReportType,"disabled":""}})],1),_c('b-col',[_c('SelectField',{attrs:{"label":"Employee level","options":_vm.employeeLevels},model:{value:(_vm.selectedEmployeeLevel),callback:function ($$v) {_vm.selectedEmployeeLevel=$$v},expression:"selectedEmployeeLevel"}})],1)],1),_c('b-row',[(_vm.loadingParams)?_c('b-col',{staticClass:"d-flex justify-content-center align-items-center mb-3"},[_c('BSpinner')],1):_vm._e(),_vm._l((_vm.reportParamsMap.keys()),function(param){return [_c('b-col',{key:param,attrs:{"cols":"6"}},[_c('AutoField',{key:param,attrs:{"label":_vm.reportParamsMap.get(param).label,"type":_vm.reportParamsMap.get(param).type,"field-map":_vm.reportParamsMap.get(param),"rules":_vm.reportParamsMap.get(param).rules},model:{value:(_vm.formData[param]),callback:function ($$v) {_vm.$set(_vm.formData, param, $$v)},expression:"formData[param]"}})],1)]})],2)],1)],1)],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }