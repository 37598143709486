
































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import axios, { axiosStatic } from "@/utils/ApiUtils";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import Container from "@/components/Container.vue";
import Button from "@/form/Button.vue";
import {
	superStreamContributionResponseURL,
	superStreamContributionResponseDownloadURL,
	getFundsByBatch,
} from "@/constants/apiconstants";
import { toastErrorMessage, toastInfoMessage } from "@/plugins/toasts";
import Grid from "@/grid/Grid.vue";
import {
	ColDef,
	ColGroupDef,
	ICellRendererParamsTyped,
	IServerSideDatasourceTyped,
	IServerSideGetRowsParamsTyped,
	ValueGetterParams,
	ICellRendererParams,
} from "ag-grid-community";
import {
	columnCurrencyFormatter,
	columnDateFormatter,
} from "@/utils/CommonUtils";
import { getFullname } from "@/utils/ContributionUtils";
import {
	SUPERSTREAM_STATUS_DONE,
	SUPERSTREAM_STATUS_PENDING,
	SUPERSTREAM_TYPE_MROR,
	SuperStreamContributionResponse,
	SuperStreamContributionResponsePageResult,
	SuperStreamSelectedRow,
} from "@/models/SuperStreamContributionResponse";
import GridErrorWarningRenderer from "@/grid/GridErrorWarningRenderer.vue";
import AlertInfo from "@/components/AlertInfo.vue";
import GridActionsRenderer from "@/grid/GridActionsRenderer.vue";
import { MessageType, FilterModel } from "@/grid/gridTypes";
import GridFilter from "@/components/GridFilter.vue";
import { messageType } from "@/constants/pageConstants";
import SelectField from "@/form/SelectField.vue";
import SuperStreamDetail from "@/components/SuperStreamDetail.vue";
import MultiSelect from "@/form/MultiSelect.vue";
import TextField from "@/form/TextField.vue";
import { FundModel, FundSelectModel } from "@/models/ContributionRow";
import { commitToModule, registerModule } from "@/store/modules/filters";
import SuperStreamForm from "@/components/SuperStreamForm.vue";
import { hasPermission } from "@/utils/PermissionUtils";

export interface SSResponseFilterOptions {
	messageType: any;
	filterOutDone: any;
}

@Component({
	components: {
		SuperStreamForm,
		Container,
		Button,
		Grid,
		AlertInfo,
		GridFilter,
		SelectField,
		SuperStreamDetail,
		MultiSelect,
		TextField,
	},
})
export default class SuperStreamResponses
	extends Vue
	implements IServerSideDatasourceTyped
{
	private rowData: SuperStreamContributionResponse[] = [];
	private gridReady = false;
	private selectedRow: SuperStreamSelectedRow | null = null;
	readonly messageTypeOptions = messageType;
	private isDownloading = false;
	private fundOptions: FundSelectModel[] = [];
	private selectedFund: FundModel | null = null;
	private vuexStore = `batchSuperStream/${this.$route.params.id}`;
	private showSuperStreamForm = false;

	private readonly columnDefs: (ColGroupDef | ColDef)[] = [
		{
			headerName: "Date",
			field: "responseDate",
			minWidth: 110,
			resizable: true,
			valueFormatter: columnDateFormatter,
		},
		{
			headerName: "Type",
			field: "responseType",
			minWidth: 80,
			resizable: true,
		},
		{
			headerName: "Amount",
			field: "requestAmount",
			minWidth: 100,
			resizable: true,
			valueFormatter: columnCurrencyFormatter,
		},
		{
			headerName: "Employee",
			field: "ComputedFullName",
			valueGetter: getFullname,
			resizable: true,
			minWidth: 165,
		},
		{
			headerName: "Fund",
			field: "fund",
			minWidth: 155,
			resizable: true,
		},
		{
			headerName: "Response description",
			field: "shortDescription",
			minWidth: 200,
			resizable: true,
			valueGetter: this.descriptionGetter,
		},
		{
			headerName: "Refund",
			field: "refund",
			minWidth: 100,
			resizable: true,
		},
		{
			headerName: "Type",
			field: "type",
			minWidth: 115,
			resizable: true,
		},
		{
			headerName: "Status",
			field: "status",
			minWidth: 100,
			resizable: true,
			pinned: "right",
			cellRendererParams: {
				translationMappings: [
					{
						value: SUPERSTREAM_STATUS_DONE,
						translatedValue: "Done",
						style: "font-weight: bold;color:#228b22;", //Forest green
					},
					{
						value: SUPERSTREAM_STATUS_PENDING,
						translatedValue: "Pending",
						style: "font-weight: bold;color:#8b0000;", //Red
					},
				],
			},
			cellRenderer: "valueTranslatedCellRenderer",
		},
		{
			headerName: "View",
			cellRenderer: this.actionsRender,
			minWidth: 20,
			resizable: true,
			pinned: "right",
		},
	];

	private filterModel: FilterModel = {
		messageType: {
			value: MessageType.ALL,
			column: "messageType",
		},
		showAll: {
			value: false,
			column: "showAll",
		},
		familyName: {
			value: "",
			column: "familyName",
		},
		fundAbn: {
			value: "",
			column: "fundAbn",
		},
		fundUsi: {
			value: "",
			column: "fundUsi",
		},
	};

	private totalCount = 0;

	actionsRender(params: ICellRendererParams): HTMLElement | string {
		const responseTypeId = this.responseTypeId(params.data); // MROR - id; CTER - responseTiId
		if (responseTypeId < 0) {
			return "";
		}
		const rowId = "row" + params.data.responseType + responseTypeId; //add responseType (MROR or CTER) for uniqueness
		const vm = new Vue({
			el: document.createElement("div"),
			render: (createElement) => {
				return createElement(GridActionsRenderer, {
					props: {
						rowIndex: params.rowIndex,
						isView: true,
						isEdit: false,
						rowId: rowId,
						row: params.data,
					},
					on: {
						clickView: this.onClickView,
					},
				});
			},
		});
		this.gridVMList.push(vm);
		return vm.$el as HTMLElement;
	}

	public $refs!: {
		gridEl: Grid;
	};

	private gridVMList: Vue[] = [];
	private loading = false;

	hasPermission(permission: string) {
		return hasPermission(permission);
	}

	onResetFilter() {
		this.filterModel.messageType.value = MessageType.ALL;
		this.filterModel.showAll.value = false;
		this.filterModel.familyName.value = "";
		this.filterModel.fundAbn.value = "";
		this.filterModel.fundUsi.value = "";
		this.onApplyFilter();
	}

	private onApplyFilter() {
		commitToModule(this.$store, this.vuexStore, this.filterModel);
		this.$refs.gridEl.reload();
	}

	private onGridReady() {
		this.gridReady = true;
	}

	private reloadGrid(): void {
		this.gridReady = false;
		if (!this.$refs.gridEl) {
			return;
		}
		this.$refs.gridEl.reload();
		this.gridReady = true;
	}

	get superStreamContributionResponsesFound(): boolean {
		return this.loading || (this.rowData && this.rowData.length > 0);
	}

	private descriptionGetter(params: ValueGetterParams): string {
		return [
			params.data.errorCode?.trim(),
			params.data.shortDescription?.trim(),
		]
			.filter((s) => s)
			.join(" - ")
			.trim();
	}

	getRows(
		params: IServerSideGetRowsParamsTyped<SuperStreamContributionResponse>
	): void {
		this.loading = true;

		params.request.filterModel = Object.keys(this.filterModel).map(
			(key) => {
				return this.filterModel[key];
			}
		);

		axios
			.get<SuperStreamContributionResponsePageResult>(
				superStreamContributionResponseURL(
					Number(this.$route.params.id)
				),
				{
					params: {
						// Pass in <Grid> parameters
						grid: params.request,
					},
					cancelToken: params.cancelToken,
				}
			)
			.then((response) => {
				const pagedResultData = response.data.pagedResult;
				const recordList = pagedResultData.elements;
				this.totalCount = pagedResultData.totalElements;
				params.successCallback(pagedResultData);

				this.rowData = recordList;
				this.loading = false;
			})
			.catch((error) => {
				if (axiosStatic.isCancel(error)) {
					return;
				}
				toastErrorMessage(parseErrorMessage(error));
			});
	}

	warningErrorRender(
		params: ICellRendererParamsTyped<SuperStreamContributionResponse>
	): HTMLElement {
		const vm = new Vue({
			el: document.createElement("div"),
			render: (createElement) => {
				return createElement(GridErrorWarningRenderer, {
					props: {
						rowIndex: params.rowIndex,
						withError: params.data.severity === "Error",
						withWarning: params.data.severity === "Warning",
						withInfo: true,
					},
				});
			},
		});
		this.gridVMList.push(vm);
		return vm.$el as HTMLElement;
	}

	onClickView({ row }: { row: SuperStreamContributionResponse }) {
		if (!this.rowData) {
			return;
		}
		this.selectionChanged(row);
	}

	private selectionChanged(response: SuperStreamContributionResponse): void {
		const responseTypeId = this.responseTypeId(response);
		if (
			responseTypeId < 0 ||
			(this.selectedRow?.responseTypeId === responseTypeId &&
				this.selectedRow?.responseType === response.responseType)
		) {
			return;
		}
		this.selectedRow = {
			batchId: Number(this.$route.params.id),
			responseType: response.responseType,
			responseTypeId: responseTypeId,
		};
	}

	private downloadAllResponse() {
		const batchId = Number(this.$route.params.id);
		this.isDownloading = true;
		axios
			.get(superStreamContributionResponseDownloadURL(batchId))
			.then((response) => {
				const fileName = "SuperStream-" + batchId + ".csv";
				const blob = new Blob([response.data]);
				if (window.navigator && window.navigator.msSaveBlob) {
					window.navigator.msSaveBlob(blob, fileName);
				} else {
					const url = window.URL.createObjectURL(blob);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", fileName);
					document.body.appendChild(link);
					link.click();
				}
				this.isDownloading = false;
				toastInfoMessage("Download completed.");
			})
			.catch((error) => {
				if (error.response?.status === 400) {
					toastInfoMessage(parseErrorMessage(error));
				} else {
					toastErrorMessage(parseErrorMessage(error));
				}
				this.isDownloading = false;
			});
	}

	// MROR - mrorId; CTER - cterId
	private responseTypeId(
		response: SuperStreamContributionResponse | null
	): number {
		if (!response) {
			return -1;
		}
		return Number(
			SUPERSTREAM_TYPE_MROR === response.responseType
				? response.mrorId
				: response.cterId
		);
	}

	onDone() {
		this.selectedRow = null;
		this.reloadGrid();
	}

	onClose() {
		this.selectedRow = null;
	}

	onClickCreateCter() {
		this.showSuperStreamForm = true;
	}

	created() {
		registerModule(this.$store, this.vuexStore, this.filterModel);
		this.filterModel = this.$store.getters[`${this.vuexStore}/filters`];
	}

	mounted() {
		axios
			.get(getFundsByBatch(Number(this.$route.params.id)))
			.then((resp) => (this.fundOptions = resp.data))
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
	}

	private filterFormFundLabel(option: FundModel): string {
		if (option.usi != null && option.usi.trim() !== "") {
			return option.name + " - " + option.usi + " - " + option.abn;
		} else {
			return option.name + " - " + option.abn;
		}
	}

	updateFilterSelectedFund(value: FundModel) {
		this.selectedFund = value;
		this.filterModel.fundUsi.value = value.usi != null ? value.usi : "";
		this.filterModel.fundAbn.value = value.abn != null ? value.abn : "";
		this.onApplyFilter();
	}
}
