






























































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Login from "../components/Login.vue";
import axios from "@/utils/ApiUtils";
import { toastErrorMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";

interface TermNode {
	text: string;
	link?: string;
	filename?: string;
}

@Component({
	components: { Login },
})
export default class TermsAndConditions extends Vue {
	@Prop({ required: true }) termsAndConditions!: string[];

	private getTermsNodes(termsSentence: string): TermNode[] {
		const termsTokens = termsSentence.split(/(\{.*?\})/g);
		return termsTokens.map((token: string) => {
			const node: TermNode = { text: "" };
			if (token.startsWith("{")) {
				[node.text, node.link, node.filename] = [
					...token.substring(1, token.length - 1).split("|"),
				];
			} else {
				node.text = token;
			}
			return node;
		});
	}

	get currentYear(): string {
		return String(new Date().getFullYear());
	}

	private onLoadDocument(filename: string) {
		axios
			.get(`files/${filename}`, {
				responseType: "blob",
			})
			.then((response) => {
				const blob = new Blob([response.data], {
					type: "application/pdf",
				});
				if (window.navigator && window.navigator.msSaveOrOpenBlob) {
					window.navigator.msSaveOrOpenBlob(blob, filename);
				} else {
					const url = window.URL.createObjectURL(blob);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", filename);
					link.click();
				}
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
	}
}
