






























































































































import ProgressArrow from "@/components/ProgressArrow.vue";
import { Component, Watch } from "vue-property-decorator";
import Vue from "vue";
import { AppRoute, AppRouteNextFunction } from "@/router";
import ContributionResource, {
	ContributionSummary,
} from "@/rest/ContributionResource";
import Layout from "@/components/Layout.vue";
import ContributionSummaryComponent from "@/pages/contribution/ContributionSummaryComponent.vue";
import LeftRightFooter from "@/components/LeftRightFooter.vue";
import { RoutePath } from "@/router/routePath";
import Container from "@/components/Container.vue";
import Button from "@/form/Button.vue";
import { ContributionRow } from "@/models/ContributionRow";
import Grid from "@/grid/Grid.vue";
import {
	ColDef,
	ColGroupDef,
	ICellRendererParams,
	IServerSideDatasourceTyped,
	IServerSideGetRowsParamsTyped,
	RowNode,
	ValueFormatterParams,
	ValueGetterParams,
} from "ag-grid-community";
import axios, { axiosStatic } from "@/utils/ApiUtils";
import GridActionsRenderer from "@/grid/GridActionsRenderer.vue";
import ModalOrPopup from "@/components/ModalOrPopup.vue";
import Form from "@/form/Form.vue";
import FieldGroup from "@/form/FieldGroup.vue";
import DatepickerField from "@/form/DatepickerField.vue";
import Overlay from "@/components/Overlay.vue";
import { toastErrorMessage, toastSuccessMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import { ValidationObserver } from "vee-validate";
import { formatDate } from "@/utils/CommonUtils";
import PageHeader from "@/components/PageHeader.vue";
import {
	getNextStep,
	getPreviousStep,
	getSteps,
} from "@/constants/pageConstants";
import { PagedResult } from "@/grid/gridTypes";
import { VForm } from "@/@typings/type-vee-validate";
import { batchCancelURL } from "@/constants/apiconstants";
import ModalWithSaveAndCancel from "@/components/ModalWithSaveAndCancel.vue";

interface FileErrorResponseBundle {
	batchId: number;
	summary: ContributionSummary | undefined;
}

interface GetDetailRowDataParams {
	// details for the request,
	node: RowNode;
	data: any;

	// success callback, pass the rows back the grid asked for
	successCallback(rowData: ContributionRow[]): void;
}

interface PayPeriodContribution {
	message: string;
	periodStart: string;
	periodEnd: string;
	contributions: PagedResult<ContributionRow>;
	enableEdit: boolean;
	enableDetail: boolean;
}

class FileErrorUpdateRequest {
	periodStart = "";
	periodEnd = "";
	updatedPeriodStart = "";
	updatedPeriodEnd = "";
}

@Component({
	components: {
		PageHeader,
		ProgressArrow,
		Layout,
		ContributionSummaryComponent,
		LeftRightFooter,
		Container,
		Button,
		Grid,
		ModalOrPopup,
		Form,
		FieldGroup,
		DatepickerField,
		Overlay,
		ValidationObserver,
		ModalWithSaveAndCancel,
	},
})
export default class FileErrorPage
	extends Vue
	implements IServerSideDatasourceTyped
{
	private readonly title = "File errors and warnings";

	private steps = getSteps(this.title);

	private parentBatchId = 0;
	private contributionSummary: ContributionSummary | null = null;
	private gridVMList: Vue[] = [];
	private editPeriodMode = false;
	private payPeriod: FileErrorUpdateRequest | null = null;
	private updating = false;
	private containsFileLevelErrors = false;
	private canClickCancelBatch = false;

	readonly columnDefs: (ColGroupDef | ColDef)[] = [
		{
			headerName: "Errors and warnings",
			field: "message",
			rowGroup: true,
			hide: true,
		},
		{
			headerName: "Pay period start date",
			field: "periodStart",
			cellRenderer: "agGroupCellRenderer",
			valueFormatter: this.dateFormatter,
			width: 55,
		},
		{
			headerName: "Pay period end date",
			field: "periodEnd",
			cellRenderer: "agGroupCellRenderer",
			valueFormatter: this.dateFormatter,
			width: 55,
		},
		{
			headerName: "Field name",
			field: "fieldName",
			cellRenderer: "agGroupCellRenderer",
			width: 120,
		},
		{
			headerName: "Bulk edit",
			field: "__Actions",
			cellRenderer: this.actionsRender,
			pinned: "right",
			minWidth: 100,
			maxWidth: 100,
		},
	];
	private isCancelModalShown = false;

	private dateFormatter(param: ValueFormatterParams): string {
		if (param.value && typeof param.value === "string") {
			const date = formatDate(param.value);
			// Display Default acurity as blank
			if (date === formatDate("0001-01-01")) {
				return "(blank)";
			}
			return date;
		}
		return "";
	}

	public $refs!: {
		gridEl: Grid;
		payPeriodForm: VForm;
	};

	actionsRender(params: ICellRendererParams): HTMLElement {
		const allowEdit =
			params.node.allChildrenCount === null &&
			params.data &&
			params.data.enableEdit;
		const vm = new Vue({
			el: document.createElement("div"),

			render: (createElement) => {
				return createElement(GridActionsRenderer, {
					props: {
						rowIndex: params.rowIndex,
						row: params.data,
						isEdit: allowEdit,
					},
					on: {
						clickEdit: this.onClickUpdate,
					},
				});
			},
		});
		this.gridVMList.push(vm);
		return vm.$el as HTMLElement;
	}

	readonly detailColumnDefs: (ColGroupDef | ColDef)[] = [
		{
			headerName: "Employee",
			field: "ComputedFullName",
			valueGetter: this.fullName,
			width: 250,
			sortable: true,
			initialSort: "desc",
		},
		{
			headerName: "Payroll ID",
			field: "payrollNo",
			width: 250,
			sortable: true,
		},
		{
			headerName: "Employer ABN",
			field: "abn",
			width: 250,
		},
		{
			headerName: "Employer name",
			field: "employerPayrollName",
			width: 250,
		},
		{
			headerName: "Reporting centre",
			field: "reportingCentreName",
			width: 353,
		},
	];
	private autoGroupColumnDef = {
		cellRendererParams: {
			suppressCount: true,
		},
	};

	readonly detailCellRendererParams = {
		detailGridOptions: {
			columnDefs: this.detailColumnDefs,
		},
		getDetailRowData: (params: GetDetailRowDataParams) => {
			params.successCallback(params.data.contributions.elements);
		},
	};

	readonly isRowMaster = (row: PayPeriodContribution) => {
		return row.enableDetail;
	};

	private reloadGrid(): void {
		this.contributionSummary = null;
		if (!this.$refs.gridEl) {
			return;
		}
		// See "getRows()" method on this class to see what is triggered by our
		// <Grid> component.

		FileErrorPage.getResponseBundle(this.parentBatchId).then((r) => {
			this.setResponse(r);
			// Wait for response (contributionSummary) to check if validation is in progress or finished before reloading the grid
			this.$refs.gridEl.reload();
		});
	}

	private onClickUpdate({
		row: { periodStart, periodEnd },
	}: {
		row: PayPeriodContribution;
	}): void {
		this.editPeriodMode = true;
		this.payPeriod = {
			periodStart,
			periodEnd,
			updatedPeriodStart: periodStart,
			updatedPeriodEnd: periodEnd,
		};
	}

	private onClickCancel(): void {
		this.editPeriodMode = false;
		this.payPeriod = null;
	}

	private async onClickSave() {
		if (await (this.$refs.payPeriodForm as VForm).validate()) {
			this.updating = true;
			axios
				.put(
					"api/contribution/file-error/" + this.parentBatchId,
					this.payPeriod
				)
				.then((resp) => {
					toastSuccessMessage(
						"Pay period updated - validation in progress"
					);
					this.editPeriodMode = false;
					this.reloadGrid();
					this.updating = false;
					this.payPeriod = null;
				})
				.catch((e) => {
					toastErrorMessage(parseErrorMessage(e));
					this.editPeriodMode = false;
					this.updating = false;
				});
		}
	}

	private onClickBack(): void {
		const routePath = getPreviousStep(this.steps, this.title).routePath;
		this.$router.push(routePath.replace("/:id", "/" + this.parentBatchId));
	}

	private onClickNext(): void {
		const routePath = getNextStep(this.steps, this.title).routePath;
		this.$router.push(routePath.replace("/:id", "/" + this.parentBatchId));
	}

	private isBatchValidating(): boolean {
		return this.contributionSummary?.batchStatus === "Validating";
	}

	private overlayNoRowsTemplate(): string {
		let message;

		if (this.isBatchValidating()) {
			message =
				"File validation is in progress. Please click the 'Refresh' button to view the progress. The 'Next' button will be enabled when this process has been completed.";
		} else {
			if (this.notFileLevelErrors()) {
				message =
					"The contribution batch contains errors or warnings. Please proceed to the next page to view them.";
			} else {
				message = "No contribution batch errors or warnings found.";
			}
		}
		return '<span style="font-size: 1rem">' + message + "</span>";
	}

	private noErrors(): boolean {
		return (
			this.contributionSummary != null &&
			this.contributionSummary.warnings === 0 &&
			this.contributionSummary.errors === 0
		);
	}

	private notFileLevelErrors(): boolean {
		return !this.containsFileLevelErrors && !this.noErrors();
	}

	fullName(params: ValueGetterParams): string {
		return [params.data.givenNames, params.data.surname].join(" ");
	}

	async beforeRouteEnter(
		to: AppRoute,
		from: AppRoute,
		next: AppRouteNextFunction<FileErrorPage>
	) {
		const r = await FileErrorPage.beforeRouteEnterOrUpdate(to, from, next);
		if (r === undefined) {
			return;
		}
		next((vm) => {
			vm.setResponse(r);
		});
	}

	setResponse(resp: FileErrorResponseBundle) {
		const { batchId, summary } = resp;

		this.parentBatchId = batchId;
		if (summary !== undefined) {
			this.contributionSummary = summary;
			this.canClickCancelBatch = summary.canCancelBatch;
		}
	}

	private onClickClose(): void {
		this.editPeriodMode = false;
		this.payPeriod = null;
	}

	getRows(params: IServerSideGetRowsParamsTyped): void {
		// Used example from: https://github.com/ag-grid/ag-grid-server-side-nodejs-example/blob/master/client/index.js
		const batchId = this.parentBatchId;
		if (batchId === 0) {
			throw new Error("Contribution batch ID cannot be 0.");
		}

		axios
			.get<{ data: PagedResult<PayPeriodContribution> }>(
				"api/contribution/file-error/" + batchId,
				{
					headers: {
						"Content-Type": "application/json",
					},
					params: {
						// Pass in <Grid> parameters
						grid: params.request,
					},
					cancelToken: params.cancelToken,
				}
			)
			.then((resp) => {
				const pagedResultData = resp.data.data;
				params.successCallback(pagedResultData);
				this.containsFileLevelErrors =
					pagedResultData.elements.length > 0;
			})
			.catch((e) => {
				if (axiosStatic.isCancel(e)) {
					return;
				}
				params.failCallback();
			})
			.finally(() => {
				this.$refs.gridEl.api?.forEachNode((node) => {
					if (
						node.key ===
							"Period start and end dates are mandatory. Please enter the dates for this new contribution batch." ||
						node.key ===
							"This contribution is for a previous contribution quarter and may no longer be accepted by superannuation funds." ||
						node.key ===
							"This contribution is for a future financial year. Please check if this is correct." ||
						node.key?.startsWith(
							"The field is not configured for this USI"
						)
					) {
						node.setExpanded(true);
					}
				});
			});
	}

	static async beforeRouteEnterOrUpdate(
		to: AppRoute,
		from: AppRoute,
		next: AppRouteNextFunction<FileErrorPage>
	): Promise<FileErrorResponseBundle | undefined> {
		const id = Number(to.params.id);
		if (!id || isNaN(id)) {
			// Redirect to 404 if ID is zero or NaN
			next({
				name: "Not Found",
			});
			return;
		}
		return this.getResponseBundle(id);
	}

	static async getResponseBundle(batchId: number) {
		// Fire requests simultaneously
		let summaryData: ContributionSummary | undefined;
		await Promise.all([
			ContributionResource.getContributionSummary(batchId)
				.then((resp) => {
					summaryData = resp.data;
				})
				.catch((e: any) => {
					toastErrorMessage(e);
				}),
		]);

		return {
			batchId: batchId,
			summary: summaryData,
		};
	}
	private onClickCancelBatch(): void {
		this.isCancelModalShown = true;
	}
	private triggerBatchCancel() {
		axios
			.delete(batchCancelURL(this.parentBatchId))
			.then((response) => {
				toastSuccessMessage("Contribution batch marked for deletion");
				// Batch no longer exists, so return to the batch list
				this.$router.push(RoutePath.BatchList);
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			})
			.finally(() => {
				this.isCancelModalShown = false;
			});
	}
	private closeCancelBatch(): void {
		this.isCancelModalShown = false;
	}

	/**
	 * Currently ValidationProvider immediate in FieldHolder is not set so field is not validated
	 * when rendered. ValidationObserver invalid slot prop turns to false only when all
	 * ValidationProvider are validated and each invalid turns to false. Consequently, use has to
	 * click in and out of pay period start or end fields to make them validated.
	 *
	 * Here is a workaround. If either start or end date fields are updated, manually validate the
	 * form.
	 */
	@Watch("payPeriod", { deep: true })
	onPayPeriodFormFieldsChanged() {
		if (!this.$refs.payPeriodForm) {
			return;
		}
		// Either pay period start or end fields change, validate the form.
		this.$refs.payPeriodForm.validate();
	}
}
