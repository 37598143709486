












































































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import TextField from "@/form/TextField.vue";
import FieldGroup from "@/form/FieldGroup.vue";
import Button from "@/form/Button.vue";
import axios from "@/utils/ApiUtils";
import { searchFundsForPayeeCodeAndRC } from "@/constants/apiconstants";
import { toastErrorMessage, toastWarningMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import { EditFundResponse, EditSmsfResponse } from "@/pages/fundTypes";
import SelectField from "@/form/SelectField.vue";
import { AppRoute, AppRouteNextFunction } from "@/router";
import { formatDateTime } from "@/utils/CommonUtils";

type FundResponse = EditFundResponse | EditSmsfResponse;

class Option {
	value: string | undefined;
	label: string | undefined;
}

@Component({
	components: {
		FieldGroup,
		Layout,
		TextField,
		SelectField,
		Button,
	},
})
export default class ViewFundPage extends Vue {
	private responseData: FundResponse | null = null;
	private reportingCentreId = "";
	private fundName = "";
	private fundAbn = "";
	private fundUsi = "";
	private abnStatus = "";
	private complyingStatus = "";
	private payeeCode = "";
	private lastSuperFundLookup = "";
	private fen = "";
	private fundType = "";
	private defaultFundFlag = "No";
	private accountName = "";
	private bsb = "";
	private accountNumber = "";
	private esa = "";
	private esaOptions: Array<Option> = [];
	private id: number | null = null;

	private cancel() {
		this.$router.push({ name: "Funds" });
	}

	get shouldShowAPRAForm() {
		return this.fundType === "APRA";
	}

	get shouldShowSMSFForm() {
		return this.fundType === "SMSF";
	}

	async beforeRouteEnter(
		to: AppRoute,
		from: AppRoute,
		next: AppRouteNextFunction<ViewFundPage>
	) {
		const fundType = to.params.fundType;
		const reportingCentreId = to.params.reportingCentreId;
		const response = await ViewFundPage.beforeRouteEnterOrUpdate(
			to,
			from,
			next
		);
		if (response === undefined) {
			return;
		}
		next((vm) => {
			vm.setResponse(response, fundType, reportingCentreId);
		});
	}

	static async beforeRouteEnterOrUpdate(
		to: AppRoute,
		from: AppRoute,
		next: AppRouteNextFunction<ViewFundPage>
	): Promise<FundResponse | undefined> {
		const payeeCode: string = to.params.payeeCode;
		const reportingCentreId: string = to.params.reportingCentreId;
		const type: string = to.params.fundType;
		if (!payeeCode || payeeCode === "0") {
			// Redirect to 404 if ID is blank or 0.
			next({
				name: "Not Found",
			});
			return;
		}
		if (!reportingCentreId || reportingCentreId === "0") {
			// Redirect to 404 if ID is blank or 0.
			next({
				name: "Not Found",
			});
			return;
		}
		if (!type || type === "") {
			next({
				name: "Not Found",
			});
			return;
		}
		return await ViewFundPage.fetchFundDetails(
			payeeCode,
			type,
			reportingCentreId
		);
	}

	private static async fetchFundDetails(
		payeeCode: string,
		type: string,
		reportingCentreId: string
	): Promise<FundResponse | undefined> {
		let fundDetailResponse;
		await axios
			.get(
				searchFundsForPayeeCodeAndRC(
					payeeCode,
					type,
					parseInt(reportingCentreId)
				)
			)
			.then((response) => {
				fundDetailResponse = response.data;
			})
			.catch((error) => {
				const errorMessage = parseErrorMessage(error);
				toastErrorMessage(errorMessage);
			});
		return fundDetailResponse;
	}

	setResponse(
		response: FundResponse,
		fundType: string,
		reportingCentreId: string
	) {
		if (response) {
			this.reportingCentreId = reportingCentreId;
			this.responseData = response;
			if (fundType == "APRA") {
				response = response as EditFundResponse;
				this.fundName = response.fundName;
				this.fundAbn = response.fundAbn;
				this.fundUsi = response.fundUsi;
				this.payeeCode = response.payeeCode;
				this.defaultFundFlag = response.defaultFundFlag ? "Yes" : "No";
				this.abnStatus = response.status;
				this.fen = response.fen;
				this.fundType = "APRA";
				this.id = response.id;
			} else if (fundType == "SMSF") {
				response = response as EditSmsfResponse;
				this.fundName = response.name;
				this.fundAbn = response.abn;
				this.payeeCode = response.payeeCode;
				this.accountName = response.accountName;
				this.esa = response.esa;
				this.bsb = response.bsb;
				this.accountNumber = response.accountNumber;
				this.lastSuperFundLookup = formatDateTime(
					response.lastLookupDate
				);
				this.abnStatus = response.status;
				this.complyingStatus = response.complyingStatus;
				this.fundType = "SMSF";
				this.esaOptions = Object.keys(response.smsfAliasMap).map(
					(alias) => ({
						value: alias,
						label: alias,
					})
				);
				if (
					response.status ===
						"Service unavailable. Please try again later." ||
					response.complyingStatus ===
						"Service unavailable. Please try again later."
				) {
					toastWarningMessage(
						"Unable to verify details with the government's Super Fund lookup service. Please try later."
					);
				}
			}
		}
	}
	private navigate() {
		this.$router.push({ name: "Funds" });
	}
}
