





















































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import FieldHolder from "@/form/FieldHolder.vue";
import type { CommonField } from "@/form/CommonField";

@Component({
	components: {
		FieldHolder,
	},
})
export default class TextField extends Vue implements CommonField {
	private static uniqueId = 0;

	/**
	 * id must be globally unique in a HTML document, we guarantee this by making each render
	 * of this component have unique ID number appended to the end to avoid clashes.
	 *
	 * We need ids mostly to connect <label> and <input> elements together for accessibility.
	 */
	readonly id = "TextField_" + ++TextField.uniqueId;

	@Prop([String]) readonly name!: string;

	@Prop([String]) readonly label!: string;

	@Prop([String]) value!: string;

	@Prop([String, Object]) readonly rules!: string | object;

	@Prop(Boolean) readonly readonly!: boolean;

	@Prop(String) readonly placeholder!: string;

	@Prop(String) readonly dataCy!: string;

	@Prop(Boolean) readonly numericCardinalIdsOnly!: boolean;

	/**
	 * Add additional custom error messages to this field.
	 */
	@Prop([Array]) readonly errors!: string[];

	@Prop(Boolean) readonly disabled!: boolean;

	/**
	 * Whether errors are shown externally.
	 */
	@Prop(Boolean) readonly errorsShownExternally!: boolean;

	@Prop({ type: Boolean, default: false }) readonly isLabelLeft!: boolean;

	public $refs!: {
		inputEl: HTMLInputElement;
	};

	onInputChange(e: any) {
		const textField = document.querySelector("#" + e.srcElement.id);

		let textFieldValue = null;
		let tfHtmlElem = null;

		if (textField) {
			textFieldValue = e.target.value;

			tfHtmlElem = textField as HTMLInputElement;
		}

		if (this.numericCardinalIdsOnly) {
			const maskedCardinal = textFieldValue.replace(/\D/g, "");

			if (tfHtmlElem) {
				tfHtmlElem.value = maskedCardinal;
			}

			this.$emit("input", maskedCardinal);
		} else {
			this.$emit("input", e.target.value);
		}
	}
}
