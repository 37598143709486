







































































































































import Vue, { VueConstructor } from "vue";
import Component from "vue-class-component";
import { FormWizard, TabContent } from "vue-form-wizard";
import WizardFormStep from "@/components/WizardFormStep.vue";
import { Prop } from "vue-property-decorator";
import { registerModule } from "@/store/modules/wizardform";
import axios from "@/utils/ApiUtils";
import {
	displayToast,
	TToastMessage,
	toastInfoMessage,
	toastErrorMessage,
} from "@/plugins/toasts";
import { RoutePath } from "@/router/routePath";
import { parseErrorMessage } from "@/utils/ErrorUtils";

interface Field {
	name: string;
	type: string;
	value?: any;
	allowEmptyValue?: boolean;
	rules?: string;
	label?: string;
	options?: any;
}

export interface SchemaForm {
	data: any;
	fieldMap: { [k: string]: Field };
	steps: { [k: string]: string[] };
	requestStructure: {
		name: string;
		structType: "object" | "objectArray";
		fields?: string[];
		groups?: string[];
	}[];
}
interface StepList {
	[k: string]: Field[];
}

export interface FormSubmissionConfig {
	endpoint: string;
	successRedirect: RoutePath;
	toastMessages: {
		code: number | "anyError" | "anySuccess";
		message: TToastMessage;
	}[];
}

@Component({
	components: {
		WizardFormStep,
		FormWizard: FormWizard as VueConstructor<Vue>,
		TabContent: TabContent as VueConstructor<Vue>,
	},
})
export default class WizardForm extends Vue {
	@Prop({ type: String }) readonly vuexStore!: string;

	@Prop() readonly schema!: SchemaForm;
	@Prop() readonly submissionSettings!: FormSubmissionConfig;
	private steps: Record<string, any> = [];
	formCompleted = false;

	validateStep(title: string) {
		return () => {
			const instance = (this.$refs[title] as any)[0];
			if (instance.validate) {
				return instance.validate().then((res: any) => {
					if (!res) {
						toastInfoMessage(
							"Please correct remaining errors before proceeding"
						);
					}
					return res;
				});
			}
			return true;
		};
	}

	submitForm() {
		this.formCompleted = true;
		const data = JSON.parse(
			JSON.stringify(this.$store.getters[`${this.vuexStore}/steps`])
		) as StepList;

		const completedForm = new Map<string, any>();

		// Flatten the data object so all the fields are no longer seperated into their steps
		Object.values(data)
			.flat()
			.forEach((field) => completedForm.set(field.name, field.value));

		this.transformToExpectedStructure(completedForm);

		axios
			.post(
				this.submissionSettings.endpoint,
				Object.fromEntries(completedForm)
			)
			.then((res) => {
				let customToast = this.submissionSettings.toastMessages.find(
					(toast) => toast.code === res.status
				);
				if (!customToast) {
					customToast = this.submissionSettings.toastMessages.find(
						(toast) => toast.code === "anySuccess"
					);
				}
				if (customToast) {
					displayToast(customToast.message);
				}
				this.$router.push(this.submissionSettings.successRedirect);
			})
			.catch((e) => {
				toastErrorMessage(parseErrorMessage(e));
			});
	}

	private transformToExpectedStructure(completedForm: Map<string, any>) {
		// Transforms the form data to match what is the expected requestBody.
		this.schema.requestStructure.forEach((struct) => {
			if (struct.fields) {
				const obj: { [k: string]: any } = {};
				struct.fields?.forEach((field) => {
					obj[field] = completedForm.get(field);
					completedForm.delete(field);
				});
				if (struct.structType === "objectArray") {
					completedForm.set(struct.name, [obj]);
				} else if (struct.structType === "object") {
					completedForm.set(struct.name, obj);
				}
			}

			if (struct.groups) {
				const obj: { [k: string]: any } = {};
				struct.groups?.forEach((group) => {
					obj[group] = completedForm.get(group);
					completedForm.delete(group);
				});
				completedForm.set(struct.name, obj);
			}
		});
	}

	created() {
		registerModule(this.$store, this.vuexStore);
		this.$store.commit(`${this.vuexStore}/setSteps`, this.createSteps());
		this.steps = this.$store.getters[`${this.vuexStore}/steps`];
	}

	createSteps(): Record<string, any> {
		const fieldMap = this.schema.fieldMap;
		let initialData = new Map();
		if (this.schema.data) {
			initialData = new Map(Object.entries(this.schema.data));
		}
		const stepList: StepList = {};
		Object.entries(this.schema.steps).forEach((step) => {
			const stepName = step[0];
			const fields = step[1];
			stepList[stepName] = fields
				.filter((field) => fieldMap[field] !== undefined)
				.map((field) => {
					const fMap = fieldMap[field];
					if (fMap.type === "DateField") {
						fMap.type = "DatepickerField";
					}
					if (initialData.has(field)) {
						fMap.value = initialData.get(field);
					}
					return fMap;
				});
		});
		return stepList;
	}
	handleWizardTabChange() {
		this.scrollToTop();
	}

	scrollToTop() {
		const layout = document.getElementsByClassName("Layout__root")[0];
		layout.scrollTo(0, 0);
	}
}
