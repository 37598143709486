import { ActionTree } from "vuex";
import store from "@/store";
import axios, { AxiosResponse } from "axios";
import { LoginPhase, LoginState, PreLoginResponse } from "./loginTypes";
import {
	loginOAuthTokenURL,
	loginPreloginURL,
	requestResetPasswordURL,
	resetPaswordURL,
	revokeTokenURL,
} from "@/constants/apiconstants";
import { RootState } from "@/store/root";
import { parseErrorMessage } from "@/utils/ErrorUtils";

export const actions: ActionTree<LoginState, RootState> = {
	loginCredRequest({ commit }, payload): Promise<Partial<PreLoginResponse>> {
		const formData = new FormData();
		formData.append("username", payload.username);
		formData.append("password", payload.password);
		formData.append("brand", payload.brand);
		return axios({
			url: loginPreloginURL(),
			method: "POST",
			data: formData,
		}).then(
			(response: AxiosResponse<PreLoginResponse>) => {
				if (response.data.isBrandCorrect) {
					commit("loginCredSuccess");
					localStorage.removeItem("showInsightsMenu");
				} else {
					const host = window.location.origin;
					window.location.href = host + "/" + response.data.brand;
				}
				return {
					financialDocsRead: response.data.financialDocsRead,
				};
			},
			(error) => {
				commit("loginCredError", parseErrorMessage(error));
				return { financialDocsRead: false };
			}
		);
	},

	async twoFARequest({ commit }, payload): Promise<void> {
		const formData = new FormData();
		formData.append("code", payload.code);
		formData.append("username", payload.username);
		formData.append("password", payload.password);
		formData.append("financialDocsRead", payload.financialDocsRead);
		let response;
		try {
			response = await axios({
				url: loginOAuthTokenURL(),
				method: "POST",
				data: formData,
			});
		} catch (error) {
			commit("loginTwoFAError", parseErrorMessage(error));
			if (error.response?.data?.errorCode === "PASSWORD_EXPIRED") {
				setTimeout(() => {
					commit("loginTwoFAError", null);
					commit("setPhase", LoginPhase.CRED_DEFAULT);
				}, 8000);
			}
			return;
		}
		try {
			await store.dispatch("persistent/setAuthToken", response);
		} catch (error) {
			commit("loginTwoFAError", parseErrorMessage(error));
		}
		await store.dispatch("persistent/fetchCurrentUser");
		await store.dispatch("persistent/fetchDefinedBenefitFeaturesEnabled");

		commit("loginTwoFASuccess");
	},
	logout(): Promise<void> {
		const accessToken = store.getters["persistent/accessToken"];
		if (!accessToken) {
			// If token isn't set, just logout
			store.dispatch("resetState", { root: true });
			return Promise.resolve();
		}
		return axios({
			url: revokeTokenURL(accessToken),
			method: "POST",
		})
			.then(() => {
				store.dispatch("resetState", { root: true });
			})
			.catch((error) => {
				if (
					error.response?.status === 400 &&
					error.response?.data?.msg === "token revoke failed"
				) {
					// The main reason for token revoke failure is, it has been already revoked.
					// Usually by logging in the same account on another session.
					store.dispatch("resetState", { root: true });
				}
			});
	},
	forgotPasswordRequest({ commit }, payload): any {
		const formData = new FormData();
		formData.append("email", payload.email);
		formData.append("phonePart", payload.phone);
		formData.append("captchaResponse", payload.captchaResponse);
		axios({
			url: requestResetPasswordURL(),
			method: "POST",
			data: formData,
		}).then(
			(response: AxiosResponse<PreLoginResponse>) => {
				commit("forgotPasswordSuccess", response);
			},
			(error) => {
				commit("forgotPasswordError", parseErrorMessage(error));
			}
		);
	},

	loginResetPassword({ commit }, payload): any {
		const formData = new FormData();
		formData.append("email", payload.email);
		formData.append("newPassword", payload.newPassword);
		formData.append("twoFactorCode", payload.twoFactorCode);
		formData.append("brand", payload.brand);
		axios({
			url: resetPaswordURL(),
			method: "POST",
			data: formData,
		}).then(
			(response) => {
				if (response.data.isBrandCorrect) {
					commit("resetPasswordSuccess");
					setTimeout(
						() => commit("setPhase", LoginPhase.CRED_DEFAULT),
						5000
					);
				} else {
					const host = window.location.origin;
					commit("resetPasswordInvalidBrand");
					setTimeout(
						() =>
							(window.location.href =
								host + "/" + response.data.brand),
						6000
					);
				}
			},
			(error) => {
				commit("resetPasswordError", parseErrorMessage(error));
			}
		);
	},
};
