// Router and IE11 workaround.
// This fixes :href links that aren't using a <router-link>
//
// See: https://github.com/vuejs/vue-router/issues/1911
const IE11RouterFix = {
	methods: {
		hashChangeHandler: function () {
			(this as any).$router.push(
				window.location.hash.substring(1, window.location.hash.length)
			);
		},
		isIE11: function () {
			return (
				!!window.MSInputMethodContext &&
				!!(document as any).documentMode
			);
		},
	},
	mounted: function () {
		if ((this as any).isIE11()) {
			window.addEventListener(
				"hashchange",
				(this as any).hashChangeHandler
			);
		}
	},
	destroyed: function () {
		if ((this as any).isIE11()) {
			window.removeEventListener(
				"hashchange",
				(this as any).hashChangeHandler
			);
		}
	},
};

export default IE11RouterFix;
