



























































































































































import { Component, Watch } from "vue-property-decorator";
import { extend } from "vee-validate";
import FieldHolder from "@/form/FieldHolder.vue";
import TextField from "@/form/TextField.vue";
import Button from "@/form/Button.vue";
import axios from "@/utils/ApiUtils";
import {
	confirmEmailApi,
	confirmMobileApi,
	verifyEmailApi,
	verifyMobileApi,
} from "@/constants/apiconstants";
import BaseRegistrationOfInterestStep from "./BaseRegistrationOfInterestStep.vue";
import { createNamespacedHelpers } from "vuex";
import { Logger } from "@/utils/logger";

const { mapState } = createNamespacedHelpers("registrationOfInterest");

extend("validated", {
	validate: (value) => value === true,
	message: "Please complete email and mobile validations before continuing.",
});

enum ValidationStatus {
	NOT_STARTED,
	REQUESTED_TO_SEND_CODE,
	CODE_SENT,
	VERIFYING_CODE,
	COMPLETED,
}

@Component({
	components: {
		FieldHolder,
		TextField,
		Button,
	},
	computed: mapState([
		"authorizedContactFirstName",
		"authorizedContactLastName",
		"authorizedContactPosition",
		"authorizedContactEmail",
		"authorizedContactMobile",
		"secretCode",
	]),
})
export default class AuthorisedContactStep extends BaseRegistrationOfInterestStep {
	authorizedContactFirstName!: string;
	authorizedContactLastName!: string;
	authorizedContactPosition!: string;
	authorizedContactEmail!: string;
	authorizedContactMobile!: string;

	secretCode!: string;

	emailValidationStatus = ValidationStatus.NOT_STARTED;
	emailValidationCode = "";
	authorizedContactEmailErrors: string[] = [];
	authorizedContactEmailValidationErrors: string[] = [];

	mobileValidationStatus = ValidationStatus.NOT_STARTED;
	mobileValidationCode = "";
	authorizedContactMobileErrors: string[] = [];
	authorizedContactMobileValidationErrors: string[] = [];

	authorizedContactConfirmation = false;

	get ValidationStatus(): typeof ValidationStatus {
		return ValidationStatus;
	}

	async validate(): Promise<boolean> {
		if (!(await (this.$refs.validationObserver as any).validate())) {
			return false;
		}

		const verificationsNeeded: Promise<void>[] = [];

		if (this.emailValidationStatus !== ValidationStatus.COMPLETED) {
			verificationsNeeded.push(this.verifyEmailValidationCode());
		}

		if (this.mobileValidationStatus !== ValidationStatus.COMPLETED) {
			verificationsNeeded.push(this.verifyMobileValidationCode());
		}

		return Promise.all(verificationsNeeded)
			.then(() => true)
			.catch(() => false);
	}

	handleAuthorizedContactEmailInput(value: string) {
		this.updateField({ authorizedContactEmail: value });
		if (this.emailValidationStatus !== ValidationStatus.NOT_STARTED) {
			this.emailValidationStatus = ValidationStatus.NOT_STARTED;
		}
		if (this.authorizedContactEmailErrors.length) {
			this.authorizedContactEmailErrors = [];
		}
	}

	handleAuthorizedContactMobileInput(value: string) {
		this.updateField({ authorizedContactMobile: value });
		if (this.mobileValidationStatus !== ValidationStatus.NOT_STARTED) {
			this.mobileValidationStatus = ValidationStatus.NOT_STARTED;
		}
		if (this.authorizedContactMobileErrors.length) {
			this.authorizedContactMobileErrors = [];
		}
	}

	@Watch("emailValidationCode")
	clearAuthorizedContactEmailValidationErrors() {
		if (this.authorizedContactEmailValidationErrors.length) {
			this.authorizedContactEmailValidationErrors = [];
		}
	}

	@Watch("mobileValidationCode")
	clearAuthorizedContactMobileValidationErrors() {
		if (this.authorizedContactMobileValidationErrors.length) {
			this.authorizedContactMobileValidationErrors = [];
		}
	}

	requestToSendEmailValidationCode() {
		this.emailValidationStatus = ValidationStatus.REQUESTED_TO_SEND_CODE;

		axios
			.post(verifyEmailApi, {
				invitationCode: this.secretCode,
				email: this.authorizedContactEmail,
			})
			.then(() => {
				this.emailValidationStatus = ValidationStatus.CODE_SENT;
			})
			.catch((error) => {
				Logger.log(error.response);

				this.emailValidationStatus = ValidationStatus.NOT_STARTED;
				this.authorizedContactEmailErrors = [
					error.response.data.message,
				];
			});
	}

	requestToSendMobileValidationCode() {
		this.mobileValidationStatus = ValidationStatus.REQUESTED_TO_SEND_CODE;

		axios
			.post(verifyMobileApi, {
				invitationCode: this.secretCode,
				mobile: this.authorizedContactMobile,
			})
			.then(() => {
				this.mobileValidationStatus = ValidationStatus.CODE_SENT;
			})
			.catch((error) => {
				this.mobileValidationStatus = ValidationStatus.NOT_STARTED;
				this.authorizedContactMobileErrors = [
					error.response.data.message,
				];
			});
	}

	verifyEmailValidationCode(): Promise<void> {
		this.emailValidationStatus = ValidationStatus.VERIFYING_CODE;

		return axios
			.post(confirmEmailApi, {
				invitationCode: this.secretCode,
				email: this.authorizedContactEmail,
				emailCode: this.emailValidationCode,
			})
			.then(() => {
				this.emailValidationStatus = ValidationStatus.COMPLETED;
			})
			.catch((error) => {
				this.emailValidationStatus = ValidationStatus.CODE_SENT;
				this.authorizedContactEmailValidationErrors = [
					error.response.data.message,
				];
				throw error;
			});
	}

	verifyMobileValidationCode(): Promise<void> {
		this.mobileValidationStatus = ValidationStatus.VERIFYING_CODE;

		return axios
			.post(confirmMobileApi, {
				invitationCode: this.secretCode,
				mobile: this.authorizedContactMobile,
				mobileCode: this.mobileValidationCode,
			})
			.then(() => {
				this.mobileValidationStatus = ValidationStatus.COMPLETED;
			})
			.catch((error) => {
				this.mobileValidationStatus = ValidationStatus.CODE_SENT;
				this.authorizedContactMobileValidationErrors = [
					error.response.data.message,
				];
				throw error;
			});
	}
}
