




















































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import ModalWithSaveAndCancel from "@/components/ModalWithSaveAndCancel.vue";
import Button from "@/form/Button.vue";
import TextField from "@/form/TextField.vue";
import Modal from "@/components/Modal.vue";
import LeftRightFooter from "@/components/LeftRightFooter.vue";
import MultiSelect from "@/form/MultiSelect.vue";
import axios from "@/utils/ApiUtils";
import {
	createSuperStreamCter,
	employeeContributions,
	employeesWithContributions,
} from "@/constants/apiconstants";
import { toastErrorMessage, toastInfoMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import { ColDef, ColGroupDef, SelectionChangedEvent } from "ag-grid-community";
import { columnCurrencyFormatter } from "@/utils/CommonUtils";
import Grid from "@/grid/Grid.vue";
import { RowNode } from "ag-grid-community/dist/lib/entities/rowNode";
import CurrencyField from "@/form/CurrencyField.vue";

interface EmployeeDto {
	employerAbn: string;
	fundAbn: string;
	fundUsi: string;
	surname: string;
	givenName: string;
	payrollNumber: string;
	contributionTotal: number;
	fwIdentity: number;
}

interface EmployeeConts {
	contName: string;
	contAmount: number;
	fcIdentity: number;
}

interface Cter {
	totalRefund: number;
	prn: string;
	conversationId: string;
	errorCode: string;
	errorDesc: string;
	fcIds: number[];
}

@Component({
	components: {
		Modal,
		TextField,
		ModalWithSaveAndCancel,
		Button,
		LeftRightFooter,
		MultiSelect,
		Grid,
		CurrencyField,
	},
})
export default class SuperStreamForm extends Vue {
	private showCancelModal = false;
	private selectedEmployee: EmployeeDto | null = null;
	private employeeList: EmployeeDto[] = [];
	private employeeContributions: EmployeeConts[] = [];
	private gridReady = false;
	private cter: Cter | null = null;
	private totalRefundError: string | null = null;

	@Prop()
	private batchId!: number;

	private readonly columnDefs: (ColGroupDef | ColDef)[] = [
		{
			minWidth: 50,
			maxWidth: 50,
			checkboxSelection: true,
			headerCheckboxSelection: true,
			colId: "selectAllBox",
		},
		{
			headerName: "Contribution",
			field: "contName",
		},
		{
			minWidth: 200,
			maxWidth: 200,
			headerName: "Amount",
			field: "contAmount",
			valueFormatter: columnCurrencyFormatter,
		},
	];
	private isShowCheckbox = (params: RowNode) => true;

	private onGridReady() {
		this.gridReady = true;
	}

	onClickCancel() {
		if (this.selectedEmployee) {
			this.showCancelModal = true;
		} else {
			this.exit();
		}
	}

	onSelectionChanged(event: SelectionChangedEvent) {
		if (this.cter) {
			this.cter.totalRefund = 0;
			this.cter.fcIds = [];
			for (const node of event.api.getSelectedRows()) {
				this.cter.totalRefund =
					Number(this.cter.totalRefund) + Number(node.contAmount);
				this.cter.fcIds.push(node.fcIdentity);
			}
			if (this.cter.totalRefund === 0) {
				this.totalRefundError =
					"At least one contribution refund is required.";
			} else {
				this.totalRefundError = null;
			}
		}
	}

	onClickSave() {
		if (!this.selectedEmployee || !this.cter) {
			return;
		}

		axios
			.post<string>(
				createSuperStreamCter(
					this.batchId,
					this.selectedEmployee.fwIdentity
				),
				this.cter
			)
			.then((resp) => {
				toastInfoMessage(
					"CTER with conversation ID " +
						resp.data +
						" is created successfully. This may take a few minutes to appear in the SuperStream responses tab."
				);
				this.exit();
			})
			.catch((err) => {
				toastErrorMessage(parseErrorMessage(err));
			});
	}

	private closeModal() {
		this.showCancelModal = false;
	}

	setEmployee() {
		if (!this.selectedEmployee) {
			return;
		}
		axios
			.get<EmployeeConts[]>(
				employeeContributions(
					this.batchId,
					this.selectedEmployee.fwIdentity
				)
			)
			.then((resp) => {
				this.employeeContributions = resp.data;
				this.cter = {
					totalRefund: 0,
					prn: "",
					conversationId: "",
					errorCode: "",
					errorDesc: "",
					fcIds: [],
				};
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
	}

	private employeeLabel(employee: EmployeeDto): string {
		return (
			employee.surname +
			" " +
			employee.givenName +
			" - " +
			employee.payrollNumber +
			" ($ " +
			employee.contributionTotal +
			")"
		);
	}

	mounted() {
		axios
			.get<EmployeeDto[]>(employeesWithContributions(this.batchId))
			.then((resp) => {
				this.employeeList = resp.data;
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
	}

	private exit() {
		this.selectedEmployee = null;
		this.showCancelModal = false;
		this.$emit("close");
	}

	get isValid() {
		return (
			this.cter &&
			this.cter.totalRefund !== 0 &&
			this.cter.prn &&
			this.cter.conversationId &&
			this.cter.errorCode &&
			this.cter.errorDesc
		);
	}
}
