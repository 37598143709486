


















































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import Grid from "@/grid/Grid.vue";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import Button from "@/form/Button.vue";
import EmployerSelector from "@/components/EmployerSelector.vue";
import axios from "@/utils/ApiUtils";
import { reportsURL } from "@/constants/apiconstants";
import { toastErrorMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import { TSelectLevel } from "@/components/employerSelectorTypes";
import {
	getEndDateOfCurrentMonthForDatePicker,
	getStartDateOfCurrentMonthForDatePicker,
} from "@/utils/CommonUtils";
import DatepickerField from "@/form/DatepickerField.vue";
import { FilterModel } from "@/grid/gridTypes";
import { ColDef } from "ag-grid-community";
import { isOnlyOneChildlessEmployerSelected } from "@/utils/EmployerSelectorUtils";
import { NO_RC_ERROR_MESSAGE } from "@/constants/constants";
import { EmployerHierarchy } from "@/store/modules/persistent/persistentTypes";
import { createNamespacedHelpers } from "vuex";
import GridFilter from "@/components/GridFilter.vue";
import TextField from "@/form/TextField.vue";
import SearchFund from "@/components/SearchFund.vue";
import { fundType } from "@/constants/pageConstants";
import SelectField from "@/form/SelectField.vue";
import { commitToModule, registerModule } from "@/store/modules/filters";

const { mapState } = createNamespacedHelpers("persistent");
@Component({
	components: {
		Button,
		DatepickerField,
		EmployerSelector,
		Grid,
		GridFilter,
		TextField,
		SearchFund,
		SelectField,
	},
	computed: mapState(["selectedEntities", "employerHierarchy"]),
})
export default class ReportTable extends Vue {
	/**
	 * Type the mapped persistent.selectedEntities getter.
	 * It is a computed property. Do not mutate it.
	 */
	selectedEntities!: string[];

	/**
	 * Type the mapped persistent.employerHierarchy getter.
	 * It is a computed property. Do not mutate it.
	 */
	employerHierarchy!: EmployerHierarchy[];

	@Prop() private colDef!: ColDef[];
	@Prop(String) private api!: string;
	@Prop(String) private searchFilter!: "contributions" | "refund";
	readonly fundType = fundType;
	private autoGroupColumnDef = {
		headerName: "Organisation",
		minWidth: 250,
	};
	private pageContext: TSelectLevel = "ALL";
	private multiSelect = true;
	private errorMessage: string | null = null;
	private vuexStore = `${this.searchFilter}ReportPage`;
	private showResult = false;
	private selectedReportOption = "employee";

	private readonly reportOptions = [
		{ value: "employee", label: "Employee" },
		{ value: "fund", label: "Fund" },
		{ value: "batch", label: "Batch ID" },
	];
	public $refs!: {
		gridEl: Grid;
	};
	private filterModel: FilterModel = {
		startDate: {
			value: getStartDateOfCurrentMonthForDatePicker(),
			column: "startDate",
		},
		endDate: {
			value: getEndDateOfCurrentMonthForDatePicker(),
			column: "endDate",
		},
		payrollId: {
			value: "",
			column: "payrollId",
		},
		employeeName: {
			value: "",
			column: "employeeName",
		},
		employeeBirthday: {
			value: "",
			column: "employeeBirthday",
		},
		fundType: {
			value: "",
			column: "fundType",
		},
		fundAbn: {
			value: "",
			column: "fundAbn",
		},
		fundUsi: {
			value: "",
			column: "fundUsi",
		},
		refundAmount: {
			value: "",
			column: "refundAmount",
		},
		fundNameUsiAbn: {
			value: "",
			column: "fundNameUsiAbn",
		},
		batchId: {
			value: "",
			column: "batchId",
		},
	};
	private gridRequest = {
		filters: Object.keys(this.filterModel).map((key) => {
			return this.filterModel[key];
		}),
		orders: null,
	};

	updateGridRequest() {
		this.gridRequest = {
			filters: Object.keys(this.filterModel).map((key) => {
				return this.filterModel[key];
			}),
			orders: null,
		};
	}

	private modules = [RowGroupingModule];
	private rowData: any = [];

	get selectedReportingCenter() {
		return this.$store.state.persistent.selectedEntities;
	}

	created() {
		registerModule(this.$store, this.vuexStore, this.filterModel);
		this.filterModel = this.$store.getters[`${this.vuexStore}/filters`];
		this.updateGridRequest();
	}

	private generateReport() {
		this.fetchReport();
	}

	@Watch("selectedReportingCenter")
	onSelectedReportingCenterChanged() {
		this.showResult = false;
	}

	private onGridReady(): void {
		this.updateColGroups();
	}

	private async fetchReport(): Promise<void> {
		if (
			isOnlyOneChildlessEmployerSelected(
				this.selectedEntities,
				this.employerHierarchy
			)
		) {
			this.errorMessage = NO_RC_ERROR_MESSAGE;
			this.rowData = [];
			return Promise.resolve();
		}
		const value =
			this.$store.state.persistent.selectedEntities == null
				? []
				: this.$store.state.persistent.selectedEntities;
		try {
			const response = await axios.post(
				reportsURL() + "/" + this.api,
				{
					request: JSON.parse(JSON.stringify(this.gridRequest)),
					entities: value,
				},
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			this.rowData = response.data;
			this.showResult = true;
			if (
				this.rowData &&
				this.rowData.length === 0 &&
				this.$refs.gridEl
			) {
				this.$refs.gridEl.showNoRowsOverlay();
			}
		} catch (error) {
			toastErrorMessage(parseErrorMessage(error));
		}
	}

	private onResetFilter() {
		this.filterModel.startDate.value =
			getStartDateOfCurrentMonthForDatePicker();
		this.filterModel.endDate.value =
			getEndDateOfCurrentMonthForDatePicker();
		this.filterModel.payrollId.value = "";
		this.filterModel.employeeName.value = "";
		this.filterModel.employeeBirthday.value = "";
		this.filterModel.fundType.value = "";
		this.filterModel.fundAbn.value = "";
		this.filterModel.fundUsi.value = "";
		this.filterModel.refundAmount.value = "";
		this.filterModel.fundNameUsiAbn.value = "";
		this.filterModel.batchId.value = "";
		commitToModule(this.$store, this.vuexStore, this.filterModel);
		this.updateGridRequest();
		this.fetchReport();
	}

	private filterChanged() {
		commitToModule(this.$store, this.vuexStore, this.filterModel);
		this.updateGridRequest();
	}

	private clearErrorMessage() {
		this.errorMessage = null;
	}

	private updateColGroups() {
		if (this.searchFilter === "refund") {
			return;
		}

		this.$refs.gridEl.columnApi?.setRowGroupColumns([
			"employerName",
			"reportingCentre",
		]);

		if (this.selectedReportOption === "batch") {
			this.$refs.gridEl.columnApi?.addRowGroupColumns([
				"batchId",
				"employeeName",
			]);
			this.$refs.gridEl.columnApi?.setColumnsVisible(
				["batchId", "employeeName"],
				false
			);
			this.$refs.gridEl.columnApi?.setColumnsVisible(["fund"], true);
		} else if (this.selectedReportOption === "employee") {
			// eslint-disable-next-line
			console.log(
				this.$refs.gridEl.columnApi?.getColumnGroup("employeeName")
			);
			this.$refs.gridEl.columnApi?.addRowGroupColumns(["employeeName"]);
			this.$refs.gridEl.columnApi?.setColumnsVisible(
				["employeeName"],
				false
			);
			this.$refs.gridEl.columnApi?.setColumnsVisible(
				["fund", "batchId"],
				true
			);
		} else if (this.selectedReportOption === "fund") {
			this.$refs.gridEl.columnApi?.addRowGroupColumns([
				"fund",
				"employeeName",
			]);
			this.$refs.gridEl.columnApi?.setColumnsVisible(
				["fund", "employeeName"],
				false
			);
			this.$refs.gridEl.columnApi?.setColumnsVisible(["batchId"], true);
		}
	}
}
