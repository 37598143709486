

























































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import FieldHolder from "@/form/FieldHolder.vue";
import type { CommonField } from "@/form/CommonField";

@Component({
	components: {
		FieldHolder,
	},
})
export default class PasswordField extends Vue implements CommonField {
	private static uniqueId = 0;

	/**
	 * id must be globally unique in a HTML document, we guarantee this by making each render
	 * of this component have unique ID number appended to the end to avoid clashes.
	 *
	 * We need ids mostly to connect <label> and <input> elements together for accessibility.
	 */
	readonly id = "PasswordField_" + ++PasswordField.uniqueId;

	@Prop([String]) readonly name!: string;

	@Prop([String]) readonly label!: string;

	@Prop([String]) readonly value!: string;

	@Prop([String]) readonly rules!: string;

	@Prop(Boolean) readonly readonly!: boolean;

	@Prop(String) readonly placeholder!: string;

	/**
	 * Add additional custom error messages to this field.
	 */
	@Prop([Array]) readonly errors!: string[];

	@Prop(Boolean) readonly disabled!: boolean;

	/**
	 * Whether errors are shown externally.
	 */
	@Prop(Boolean) readonly errorsShownExternally!: boolean;

	@Prop({ type: Boolean, default: false }) readonly isLabelLeft!: boolean;
	private showPassword = false;

	public $refs!: {
		passwordEl: HTMLInputElement;
	};

	onInputChange(e: any) {
		this.$emit("input", e.target.value);
	}

	get type() {
		if (this.showPassword) {
			return "text";
		} else {
			return "password";
		}
	}
	get title() {
		if (this.showPassword) {
			return "Hide Password";
		} else {
			return "Show Password";
		}
	}
}
