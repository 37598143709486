




















































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import TextField from "@/form/TextField.vue";
import FieldGroup from "@/form/FieldGroup.vue";
import Button from "@/form/Button.vue";
import { CompanyDetail } from "@/pages/maintenanceTypes";
import SubHeading from "@/components/SubHeading.vue";

@Component({
	components: { Layout, TextField, FieldGroup, Button, SubHeading },
})
export default class AuthContact extends Vue {
	@Prop() readonly companyDetail!: CompanyDetail | null;
	@Prop(Boolean) readonly readonly!: boolean;

	emitUpdate(field: string, value: string) {
		this.$emit("change", { field: field, value: value });
	}
}
