type RegisterableElement = any;

export class ElementNotFoundError extends Error {}

export default class ElementSchemaManager {
	private static components: { [type: string]: RegisterableElement } = {};

	public static get(kind: string): RegisterableElement {
		const component = this.components[kind];
		if (component === undefined) {
			throw new ElementNotFoundError(
				"Component type has not been registered: " + kind
			);
		}
		return component;
	}

	/**
	 * register will make a component available to the ElementRenderer component which
	 * can take JSON structured data and render a hierarchal list of Vue components.
	 */
	public static register(type: string, component: RegisterableElement): void {
		if (this.components[type] !== undefined) {
			throw new Error("Cannot define same component type twice: " + type);
		}
		this.components[type] = component;
	}
}
