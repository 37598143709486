



























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import ButtonBehaviour from "@/form/ButtonBehaviour.vue";
import VueRouter from "vue-router";

@Component({
	components: {
		ButtonBehaviour,
	},
})
export default class GridDeepLinkRenderer<RowType> extends Vue {
	/**
	 * rowId of div for popover target
	 */
	@Prop(String) link!: string;
	@Prop(String) rowId!: string;
	@Prop(VueRouter) router!: VueRouter;

	private goToLink(event: MouseEvent): void {
		event.stopPropagation();
		this.router.push(this.link);
	}
}
