











































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import HeaderNav from "@/components/HeaderNav.vue";
import Footer from "@/components/Footer.vue";
import Container from "@/components/Container.vue";
import PageHeader from "@/components/PageHeader.vue";
import { getIconForRoute, getTitleForRoute } from "@/utils/RouteUtils";

@Component({
	components: {
		PageHeader,
		HeaderNav,
		Footer,
		Container,
	},
})
export default class Layout extends Vue {
	@Prop({ type: Boolean, default: true }) readonly showFooter!: boolean;

	@Prop({ type: Boolean, default: false }) noContainer!: boolean;
	@Prop({ type: Boolean, default: false }) isInsightPage!: boolean;

	/**
	 * isLoggedIn is used to hide the HeaderNav for a non-logged in user so that 404 / Forbidden
	 * pages don't show unnecessary information. By hiding the whole nav, it allows us to
	 * be able to know that the HeaderNav component is only present for logged-in users.
	 */
	get isLoggedIn(): boolean {
		return this.$store.getters["persistent/isLoggedIn"];
	}

	get definedBenefitFeaturesEnabled(): boolean | null {
		return this.$store.getters["persistent/definedBenefitFeaturesEnabled"];
	}

	get currentRouteIcon(): string[] | undefined {
		return getIconForRoute(this.$router.currentRoute.path, this.$route);
	}
	get currentRouteTitle(): string {
		return getTitleForRoute(this.$router.currentRoute.path, this.$route);
	}
}
