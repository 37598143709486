export interface Alert {
	id: number;
	type: string;
	category: NotificationCategory;
	entityType: AlertEntityType;
	entityKey: number;
	batchId: string;
	severity: AlertSeverityLevel;
	created: string;
	message: string;
	status: string;
	actionedBy: string;
	lastActionedUser: string;
	deepLink: string;
	messageAssignedTo: string;
}

export interface FileFormat {
	fileFormatName: string;
}

export interface AlertCount {
	category: string;
	count: number;
}

export interface AlertParameters {
	title: string;
	value: string;
}

export interface AlertDetailsTemplate {
	title: string;
	description: string;
	parameters: AlertParameters[] | null;
	footnote: string | null;
}

export enum AlertEntityType {
	SPONSOR = "S",
	PARENTORGANISATION = "P",
	EMPLOYER = "E",
	REPORTINGCENTRE = "R",
	PARENTCONTFILE = "B",
	PORTAL_USER = "PU",
	SUPERSTREAM_MROR = "SSM",
	SUPERSTREAM_CTER = "SSC",
	EMPLOYER_REGISTRATION = "ER",
}

export enum AlertSeverityLevel {
	AC_REQ = "AC_REQ",
	INFO = "INFO",
}

export enum NotificationCategory {
	F = "F",
	C = "C",
	P = "P",
	U = "U",
	S = "S",
	ER = "ER",
	FM = "FM",
}

export enum Status {
	P = "P",
	D = "D",
}

export enum ActionedBy {
	U = "U",
	S = "S",
	F = "F",
	E = "E",
}

export const NotificationCategoryMapper = (
	category: NotificationCategory
): string => {
	switch (category) {
		case NotificationCategory.F:
			return "Funds";
		case NotificationCategory.C:
			return "Contributions";
		case NotificationCategory.P:
			return "Payments";
		case NotificationCategory.U:
			return "Users";
		case NotificationCategory.S:
			return "Superstream";
		case NotificationCategory.ER:
			return "Employer Registrations";
		case NotificationCategory.FM:
			return "Fund Message";
	}
};

export const AlertSeverityMapper = (severity: AlertSeverityLevel): string => {
	switch (severity) {
		case AlertSeverityLevel.AC_REQ:
			return "Action Required";
		case AlertSeverityLevel.INFO:
			return "Information";
	}
};
