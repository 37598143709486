




















































import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

@Component({})
export default class ButtonBehaviour extends Vue {
    @Prop({type: String, default: ''}) tag!: string;

    @Prop({type: String, default: 'button'}) type!: string;

    @Prop({type: String, default: ''}) href!: string;

    @Prop([Boolean]) disabled!: boolean;

    /**
     * for is the "for" attribute for linking a <label> button to an <input> element.
     */
    @Prop({type: String, default: ''}) for!: string;

    onClick(e: Event) {
        if (this.disabled) {
            // If the button is disabled, halt the default behaviour/action
            // and do nothing.
            e.preventDefault();
            return;
        }
        if (this.$listeners === undefined ||
            this.$listeners.click === undefined) {
            // If no @click event is bound.
            // We want to avoid calling e.preventDefault() so that
            // <a> / <label> elements use natural browser behaviour.
            return
        }
        e.preventDefault()
        this.$emit('click', e);
    }

    /**
     * currentHref will attempt to resolve the given URL or partial URL with
     * a registered route, if we find a match, use that as the href.
     *
     * This saves us from needing to write additional <router-link> boilerplate
     * for simpler cases.
     */
    get currentHref(): string {
        if (this.$router === undefined) {
            // If no router exists (like in Jest tests), we cannot automatically
            // replace with a router route.
            return this.href;
        }
        const normalizedRoute = this.$router.resolve(this.href, this.$route);
        if (!normalizedRoute) {
            return this.href;
        }
        return normalizedRoute.href;
    }

    get currentTag(): string {
        if (this.tag !== '') {
            return this.tag;
        }
        if (this.disabled) {
            // <label> and <a> don't have a "disabled" state.
            return 'button';
        }
        if (this.href !== '') {
            return 'a';
        }
        return 'button';
    }
}
