export enum FundType {
	APRA = "APRA",
	SMSF = "SMSF",
	SCHEME = "SCHEME",
}

export interface SearchFundRequest {
	abn: string;
	fundType: FundType;
	/**
	 * Only for permission check, if null then get 403
	 * So I don't understand why allow absence,
	 */
	reportingCentreId?: number | null;
	partialMatch?: boolean;
	// Funds not associated with the reportingCentreId
	otherFunds?: boolean;
	// APRA fund specific fields.
	usi?: string;
	fundName?: string;
}

export type TFundResponse = {
	id: number;
	fundName: string;
	fundAbn: string;
	fundUsi: string;
	fen: string;
	payeeCode: string;
	active: boolean;
	defaultFund?: boolean;
};

export type EditFundResponse = {
	fundName: string;
	fundAbn: string;
	fundUsi: string;
	status: string;
	fen: string;
	defaultFundFlag: boolean;
	payeeCode: string;
	id: number;
};

export function isTFundResponse(response: any): response is TFundResponse {
	return "fundAbn" in response && "fundUsi" in response;
}

export interface SmsfAlias {
	alias: string;
	url: string;
	startDate: Date;
	endDate: Date;
	gatewayName: string;
}

export interface SmsfAliasMap {
	[alias: string]: SmsfAlias;
}

export type TSmsfResponse = {
	id: number;
	abn: string;
	name: string;
	lastLookupDate: string;
	status: string;
	complyingStatus: string;
	payeeCode: string;
	accountName: string;
	bsb: string;
	accountNumber: string;
	esa: string;
	smsfAliasMap: SmsfAliasMap;
};

export type SearchFundResponse = EditSmsfResponse &
	EditFundResponse &
	OtherSearchFundFields;

interface OtherSearchFundFields {
	fundType: string;
	active: boolean | null;
}

export type EditSmsfResponse = {
	name: string;
	abn: string;
	payeeCode: string;
	accountName: string;
	esa: string;
	bsb: string;
	accountNumber: string;
	lastLookupDate: string;
	status: string;
	complyingStatus: string;
	smsfAliasMap: SmsfAliasMap;
};

export interface Fund {
	reportingCentreId: string;
	employerName: string;
	reportingCentreName: string;
	fundName: string;
	usi: string;
	payeeCode: string;
	abn: string;
	status: string;
	type: string;
	fen: string;
}

export function isTSmsfResponse(response: any): response is TSmsfResponse {
	return "abn" in response && "smsfAliasMap" in response;
}

export type FundResponse = TFundResponse | TSmsfResponse;

export interface FundDetails {
	// Common fields.
	fundName: string;
	fundAbn: string;
	// APRA specific field.
	fundUsi: string;
	superannuationFundGeneratedEmployerIdentifier: string;
	// SMSF specific fields.
	fundAccountName: string;
	fundBsb: string;
	fundElectronicServiceAddress: string;
	fundAccountNumber: string | null;
}

export interface SearchFundDetails extends FundDetails {
	// APRA specific field.
	active: boolean | null;
	// SMSF specific fields.
	payeeCode: string;
	status: string;
	complyingStatus: string;
	smsfAliasMap: SmsfAliasMap;
}

export interface FundResponses {
	apraResponses: TFundResponse[];
	smsfResponses: TSmsfResponse[];
	schemeResponses: TFundResponse[];
}

export enum ContributionFileType {
	SUPERSTREAM = "SPSTRM",
	DB_BYPASS = "DBBYP",
	ALL = "ALL", //Only used in Add fund to display all fund options
}
