












































































































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { isEmpty, replace } from "lodash-es";
import WarningIcon from "@/components/WarningIcon.vue";
import ErrorIcon from "@/components/ErrorIcon.vue";

@Component({ components: { ErrorIcon, WarningIcon } })
class EditFieldErrorWarningList extends Vue {
	@Prop(String) readonly highlightedFieldName!: string;

	@Prop(Object) readonly errorFields!: {
		[fieldName: string]: { label: string; errors: string[] };
	};

	@Prop(Object) readonly warningFields!: {
		[fieldName: string]: { label: string; warnings: string[] };
	};

	@Prop(Object) readonly discardedErrorFields!: {
		[fieldName: string]: { label: string; disabledErrors: string[] };
	};

	@Prop(Object) readonly discardedWarningFields!: {
		[fieldName: string]: { label: string; disabledWarnings: string[] };
	};

	emitHighlightedChange(
		fieldName: string,
		type: "error" | "warning" | "disablederror"
	): void {
		this.$emit("change", fieldName, type);
	}

	get shouldShowErrorList() {
		return !isEmpty(this.errorFields);
	}

	get shouldShowWarningList() {
		return !isEmpty(this.warningFields);
	}

	get shouldShowDiscardedErrorList() {
		return !isEmpty(this.discardedErrorFields);
	}

	get shouldShowDiscardedWarningList() {
		return !isEmpty(this.discardedWarningFields);
	}

	getFieldErrorMessage(errorMessage: string, fieldLabel: string) {
		return replace(errorMessage, "{{name}}", fieldLabel);
	}
}

export default EditFieldErrorWarningList;
