




































import { Component, Watch } from "vue-property-decorator";
import VueRecaptcha from "vue-recaptcha";
import { Logger } from "@/utils/logger";
import TextField from "@/form/TextField.vue";
import FieldHolder from "@/form/FieldHolder.vue";
import axios from "@/utils/ApiUtils";
import { confirmInvitationApi } from "@/constants/apiconstants";
import BaseRegistrationOfInterestStep from "./BaseRegistrationOfInterestStep.vue";
import { createNamespacedHelpers } from "vuex";
import { ValidationObserver } from "@/@typings/type-vee-validate";

const { mapState } = createNamespacedHelpers("registrationOfInterest");

@Component({
	components: {
		FieldHolder,
		TextField,
		VueRecaptcha,
	},
	computed: mapState(["secretCode"]),
})
export default class SecretCodeStep extends BaseRegistrationOfInterestStep {
	private secretCode!: string;
	private secretCodeErrors: string[] = [];

	private recaptchaCode = "";
	private recaptchaErrors: string[] = [];
	private recaptchaSiteKey = String(
		sessionStorage.getItem("recaptchaSiteKey")
	);

	$refs!: {
		validationObserver: ValidationObserver;
		refPasswordResetInitiateCaptcha: VueRecaptcha;
	};

	async validate(): Promise<boolean> {
		if (!(await (this.$refs.validationObserver as any).validate())) {
			return false;
		}

		return axios
			.post(confirmInvitationApi, { invitationCode: this.secretCode })
			.then(() => true)
			.catch(() => {
				this.secretCodeErrors = ["Your code is not recognised."];
				return false;
			});
	}

	private handleRecaptchaVerified(code: string) {
		this.recaptchaErrors = [];
		this.recaptchaCode = code;
	}

	private handleRecaptchaError() {
		const error =
			"reCAPTCHA has encountered an error (usually network connectivity). Please retry.";
		Logger.error(error);
		this.recaptchaErrors = [error];
	}

	private handleRecaptchaExpired() {
		const expirationMessage =
			"reCAPTCHA response has expired and you need to re-verify.";
		Logger.warn(expirationMessage);
		this.recaptchaErrors = [expirationMessage];
	}

	@Watch("secretCode")
	clearSecretCodeErrors() {
		if (this.secretCodeErrors.length) {
			this.secretCodeErrors = [];
		}
	}
}
