













import { Prop, Vue, Component } from "vue-property-decorator";
import AutoField from "@/form/AutoField.vue";

@Component({
	components: { AutoField },
})
export default class ReadOnlyFields extends Vue {
	@Prop()
	data!: { [p: string]: any };
}
