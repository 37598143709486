












































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import axios from "@/utils/ApiUtils";
import Layout from "@/components/Layout.vue";
import Button from "@/form/Button.vue";
import TextField from "@/form/TextField.vue";
import SubHeading from "@/components/SubHeading.vue";
import EmployerRegistrationDetails from "@/components/EmployerRegistrationDetails.vue";
import {
	EmployerRegistration,
	EmployerRegistrationStatus,
} from "@/models/EmployerRegistration";
import {
	getEmployerRegistrationDetailsApi,
	getEmployerRegistrationApproveApi,
	getEmployerRegistrationDeclineApi,
} from "@/constants/apiconstants";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import { toastSuccessMessage, toastErrorMessage } from "@/plugins/toasts";
import { hasPermissionForRoute } from "@/utils/PermissionUtils";
import LeftRightFooter from "@/components/LeftRightFooter.vue";

@Component({
	components: {
		Layout,
		Button,
		EmployerRegistrationDetails,
		TextField,
		SubHeading,
		LeftRightFooter,
	},
})
export default class EmployerRegistrationReview extends Vue {
	@Prop()
	employerRegistrationId!: number;

	employerRegistration: EmployerRegistration | null = null;

	get canApproveEmployerRegistration() {
		return (
			hasPermissionForRoute(["APPROVE_EMPLOYER_REGISTRATION"]) &&
			this.employerRegistration &&
			![
				EmployerRegistrationStatus.APPROVED,
				EmployerRegistrationStatus.CREATED,
			].includes(this.employerRegistration.status)
		);
	}

	get canDeclineEmployerRegistration() {
		return (
			hasPermissionForRoute(["DECLINE_EMPLOYER_REGISTRATION"]) &&
			this.employerRegistration &&
			this.employerRegistration.status ===
				EmployerRegistrationStatus.PENDING
		);
	}

	navigateToEmployerRegistrationMaintenance() {
		this.$router.push({
			name: "Employer Registrations Maintenance",
		});
	}

	@Watch("employerRegistrationId", { immediate: true })
	getEmployerRegistration() {
		axios
			.get<EmployerRegistration>(
				getEmployerRegistrationDetailsApi(
					String(this.employerRegistrationId)
				),
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			)
			.then((response) => {
				this.employerRegistration = response.data;
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
	}

	approveEmployerRegistration() {
		if (this.canApproveEmployerRegistration) {
			axios
				.put(
					getEmployerRegistrationApproveApi(
						String(this.employerRegistrationId)
					)
				)
				.then(() => {
					this.navigateToEmployerRegistrationMaintenance();
					toastSuccessMessage("Employer registration approved.");
				})
				.catch((error) => {
					toastErrorMessage(parseErrorMessage(error));
				});
		}
	}

	declineEmployerRegistration() {
		if (this.canDeclineEmployerRegistration) {
			axios
				.put(
					getEmployerRegistrationDeclineApi(
						String(this.employerRegistrationId)
					)
				)
				.then(() => {
					this.navigateToEmployerRegistrationMaintenance();
					toastSuccessMessage("Employer registration declined.");
				})
				.catch((error) => {
					toastErrorMessage(parseErrorMessage(error));
				});
		}
	}
}
