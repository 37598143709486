import { MutationTree } from "vuex";

export const mutations: MutationTree<any> = {
	updateField(
		state,
		payload: { stepName: string; fieldName: string; value: any }
	) {
		state.steps[payload.stepName].find(
			(field: any) => field.name === payload.fieldName
		).value = payload.value;
	},

	setSteps(state, payload) {
		state.steps = payload;
	},
};
