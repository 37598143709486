import { MiddlewareContext } from "./middleware";
import { hasPermissionForRoute } from "@/utils/PermissionUtils";

export default function permission(context: MiddlewareContext): Promise<any> {
	const { to, next, router, store } = context;
	if (
		to.meta === undefined ||
		to.meta.permissions === undefined ||
		to.meta.permissions.length === 0
	) {
		return next();
	}

	const authorized = hasPermissionForRoute(to.meta.permissions);

	const isLoggedIn = Boolean(store.getters["persistent/isLoggedIn"]);

	if (!authorized) {
		if (!isLoggedIn) {
			// NOTE(Jae): 2020-05-08
			// Showing a forbidden message on a login page seems like a bad
			// idea, so instead redirect to home
			return router.push({ name: "Dashboard" });
		}
		if (isLoggedIn) {
			// NOTE(Jae): 2020-05-08
			// If possible I'd rather the router show the user a "Forbidden" error page
			// but kepe the URL the same. That way if/when users hit problems, the screenshot they
			// provide will give more context as to where they hit problems.
			return router.push({ name: "Forbidden" });
		}
		return router.push({ name: "Login" });
	}

	return next();
}
