










import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import axios from "@/utils/ApiUtils";
import { quicksightDashboard } from "@/constants/apiconstants";
import { embedQuicksight } from "@/utils/QuicksightUtils";
import { toastErrorMessage, toastInfoMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";

const height = "720px";
const width = "1420px";

const DASHBOARD_1 = "dashboard1";

@Component({
	components: {
		Layout,
	},
})
export default class QuicksightDashboard extends Vue {
	@Prop({ type: String }) entity!: string;

	private dashboard1Enabled = false;
	mounted() {
		axios
			.get<string>(quicksightDashboard(DASHBOARD_1), {
				params: {
					entities: [this.entity],
				},
			})
			.then((resp) => {
				this.dashboard1Enabled = resp.data !== "";
				if (this.dashboard1Enabled) {
					embedQuicksight(DASHBOARD_1, resp.data, height, width);
				}
			})
			.catch((error) => {
				if (error.response.status === 400) {
					toastInfoMessage(parseErrorMessage(error));
				} else {
					toastErrorMessage(parseErrorMessage(error));
				}
			});
	}
}
