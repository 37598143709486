import Vue from "vue";
import { NavigationGuard } from "vue-router";
import { concat } from "lodash-es";
import { RoutePath } from "@/router/routePath";
import DashboardPage from "@/pages/DashboardPage.vue";
import LoginPage from "@/pages/LoginPage.vue";
import ForbiddenPage from "@/pages/ForbiddenPage.vue";
import EmployeeListPage from "@/pages/EmployeeListPage.vue";
import ValidateContentPage from "@/pages/contribution/ValidateContentPage.vue";
import FinalBatchReviewPage from "@/pages/contribution/FinalBatchReviewPage.vue";
import BatchListPage from "@/pages/BatchListPage.vue";
import FundListPage from "@/pages/FundListPage.vue";
import MaintenancePage from "@/pages/MaintenancePage.vue";
import NotFoundPage from "@/pages/NotFoundPage.vue";
import DataInputPage from "@/pages/contribution/DataInputPage.vue";
import HelpPage from "@/pages/HelpPage.vue";
import ReportsPage from "@/pages/reports/ReportsPage.vue";
import ContributionsReportPage from "@/pages/reports/ContributionsReportPage.vue";
import { AppRouteConfig } from "./index";
import AddFundPage from "@/pages/AddFundPage.vue";
import EditFundPage from "@/pages/EditFundPage.vue";
import BatchDetailsPage from "@/pages/BatchDetailsPage.vue";
import NewsPage from "@/pages/maintenance/NewsPage.vue";
import RegistrationOfInterestPage from "@/pages/registrationofinterest/RegistrationOfInterestPage.vue";
import PaymentDetailsPage from "@/pages/contribution/PaymentDetailsPage.vue";
import CreateBatchPage from "@/pages/createbatch/CreateBatchPage.vue";
import FileErrorPage from "@/pages/contribution/FileErrorPage.vue";
import AddOrEditEmployee from "@/pages/employee/AddOrEditEmployee.vue";
import AddDbEmployee from "@/pages/employee/AddDbEmployee.vue";
import CompanyMaintenance from "@/pages/maintenance/CompanyMaintenance.vue";
import AddEmployer from "@/pages/maintenance/AddEmployer.vue";
import AddReportingCentre from "@/pages/maintenance/AddReportingCentre.vue";
import PortalUserListPage from "@/pages/maintenance/PortalUserListPage.vue";
import AddOrEditPortalUsers from "@/pages/maintenance/AddOrEditPortalUsers.vue";
import ViewFundPage from "@/pages/ViewFundPage.vue";
import RefundReportPage from "@/pages/reports/RefundReportPage.vue";
import RefundReportPageV2 from "@/pages/reports/RefundReportPageV2.vue";
import DocumentationPage from "@/pages/documentation/DocumentationPage.vue";
import DocumentationVidPage from "@/pages/documentation/VideosPage.vue";
import EmployerRegistrationMaintenance from "@/pages/maintenance/EmployerRegistrationMaintenance.vue";
import EmployerRegistrationReview from "@/pages/maintenance/EmployerRegistrationReview.vue";
import AlertListPage from "@/pages/AlertListPage.vue";
import EmployerFileFormatPage from "@/pages/EmployerFileFormatPage.vue";

import axios from "@/utils/ApiUtils";
import { requestRecaptchaSiteKeyUrl } from "@/constants/apiconstants";
import { Logger } from "@/utils/logger";
import ProfilePage from "@/pages/ProfilePage.vue";
import AccountBalancePage from "@/pages/accountbalance/AccountBalancePage.vue";
import TransactionsPage from "@/pages/accountbalance/TransactionsPage.vue";
import FileMappingPage from "@/pages/maintenance/FileMappingPage.vue";
import FileMappingDetailsPage from "@/pages/maintenance/FileMappingDetailsPage.vue";
import InsightPage from "@/pages/InsightPage.vue";
import DefinedBenefitReportPage from "@/pages/reports/DefinedBenefitReportPage.vue";
import DefinedBenefitReportDocument from "@/pages/reports/DefinedBenefitReportDocument.vue";
import BulkFilePage from "@/pages/BulkFilePage.vue";
import ReleaseNotesPage from "@/pages/documentation/ReleaseNotesPage.vue";
import AdminOps from "@/pages/AdminOps.vue";
import UsiMappingPage from "@/pages/maintenance/UsiMappingPage.vue";
import USIMappingDetailsPage from "@/pages/maintenance/UsiMappingDetailsPage.vue";
import EmployerFundFormatPage from "@/pages/EmployerFundFormatPage.vue";
import FundMessagePage from "@/pages/FundMessagePage.vue";
import FileLoadSummaryPage from "@/pages/contribution/FileLoadSummaryPage.vue";

const getRecaptchaSiteKey: NavigationGuard<Vue> = async (to, from, next) => {
	const siteKey = sessionStorage.getItem("recaptchaSiteKey");
	if (siteKey) {
		// It may be in Login page after user log out. recaptchaSiteKey is available
		// in such state and need to fetch again.
		next();
		return;
	}

	await axios
		.get<{ key: string }>(requestRecaptchaSiteKeyUrl)
		.then((response) => {
			const key = response.data.key;
			if (!key) {
				throw Error(`Invalid recaptcha siteKey: ${key}.`);
			}
			sessionStorage.setItem("recaptchaSiteKey", key);
		})
		.catch((error) =>
			Logger.error(
				`${error}.\n Forgot password and reset password do not work.`
			)
		);
	next();
};

export const menuRoutes: AppRouteConfig[] = [
	{
		path: RoutePath.Dashboard,
		name: "Dashboard",
		component: DashboardPage,
		meta: {
			permissions: [],
			title: "Dashboard",
			icon: ["fal", "home"],
		},
	},
	{
		path: RoutePath.EmployeeList,
		name: "Employees",
		component: EmployeeListPage,
		meta: {
			permissions: ["VIEW_EMPLOYEES"],
			title: "Employees",
			icon: ["fal", "users-class"],
		},
	},
	{
		path: RoutePath.FundList,
		name: "Funds",
		component: FundListPage,
		meta: {
			permissions: ["VIEW_FUNDS"],
			title: "Funds",
			icon: ["fal", "hand-holding-usd"],
		},
	},
	{
		path: RoutePath.BatchList,
		name: "Contributions",
		component: BatchListPage,
		meta: {
			permissions: ["VIEW_CONTRIBUTIONS"],
			title: "Contributions",
			icon: ["fal", "files-medical"],
		},
	},
	{
		path: RoutePath.Alerts,
		name: "Alerts",
		component: AlertListPage,
		meta: {
			permissions: ["VIEW_ALERTS"],
			title: "Alerts",
			icon: ["fal", "bell"],
		},
	},
	{
		path: RoutePath.Reports,
		name: "Reports",
		component: ReportsPage,
		meta: {
			permissions: ["VIEW_REPORT"],
			icon: ["fal", "chart-line"],
			title: "Reports",
		},
		children: [
			{
				path: RoutePath.ContributionsReport,
				name: "Contributions Report",
				component: ContributionsReportPage,
				meta: {
					permissions: ["VIEW_REPORT"],
					icon: ["fal", "users-class"],
					title: "Contributions report",
				},
			},
			{
				path: RoutePath.RefundReport,
				name: "Refund Report",
				component: RefundReportPage,
				meta: {
					permissions: ["VIEW_REPORT"],
					icon: ["fal", "hand-holding-usd"],
					title: "SuperStream refund report",
				},
			},
			{
				path: RoutePath.DefinedBenefitReport,
				name: "DB Direct Report",
				component: DefinedBenefitReportPage,
				meta: {
					permissions: ["VIEW_REPORT"],
					icon: ["fal", "hand-holding-usd"],
					title: "DB Direct report",
				},
			},
			{
				path: RoutePath.DefinedBenefitReportView,
				name: "Defined Benefit Report View",
				component: DefinedBenefitReportDocument,
				meta: {
					permissions: ["VIEW_REPORT"],
					icon: ["fal", "book"],
					title: "Defined Benefit Report View",
				},
			},
			{
				path: RoutePath.RefundReportV2,
				name: "Refund Report V2",
				component: RefundReportPageV2,
				meta: {
					permissions: ["VIEW_REPORT"],
					icon: ["fal", "hand-holding-usd"],
					title: "SuperStream refund report",
				},
			},
		],
	},
	{
		path: RoutePath.Maintenance,
		name: "Maintenance",
		component: MaintenancePage,
		meta: {
			permissions: ["VIEW_MAINTENANCEPAGE"],
			icon: ["fal", "tools"],
			title: "Maintenance",
		},
		children: [
			{
				path: RoutePath.NewsMaintenance.replace(
					RoutePath.Maintenance + "/",
					""
				),
				name: "News",
				component: NewsPage,
				meta: {
					permissions: ["VIEW_NEWS"],
					icon: ["fal", "newspaper"],
					title: "News",
				},
			},
			{
				path: RoutePath.EmployerFundFormat,
				name: "Employer fund formats",
				component: EmployerFundFormatPage,
				meta: {
					permissions: ["VIEW_FILE_FORMAT"],
					title: "Employer fund formats",
					icon: ["fal", "copy"],
				},
			},
		],
	},
	{
		path: RoutePath.AccountBalance,
		name: "Account balance",
		component: AccountBalancePage,
		meta: {
			permissions: ["VIEW_ACCOUNT_BALANCE"],
			icon: ["fal", "usd-square"],
			title: "Account balance",
		},
	},
	{
		path: RoutePath.Insight,
		name: "Insights",
		component: InsightPage,
		meta: {
			permissions: ["VIEW_QUICKSIGHT"],
			icon: ["fal", "chart-bar"],
			title: "Insights",
		},
	},
	{
		path: RoutePath.BulkFile,
		name: "Bulk file loads",
		component: BulkFilePage,
		meta: {
			permissions: ["VIEW_BULK_FILE"],
			icon: ["fal", "file-invoice"],
			title: "Bulk file loads",
		},
	},
	{
		path: RoutePath.AdminOps,
		name: "Admin operations",
		component: AdminOps,
		meta: {
			permissions: ["VIEW_ADMIN_OPS"],
			icon: ["fal", "user-cog"],
			title: "Admin operations",
		},
		children: [
			{
				path: RoutePath.EmployerFileFormat,
				name: "Employer file formats",
				component: EmployerFileFormatPage,
				meta: {
					permissions: ["VIEW_ADMIN_OPS", "VIEW_FILE_FORMAT"],
					title: "Employer file formats",
					icon: ["fal", "copy"],
				},
			},
		],
	},
];

export const rightMenuRoutes: AppRouteConfig[] = [
	{
		path: RoutePath.Help,
		name: "Help",
		component: HelpPage,
		meta: {
			permissions: ["LOGGED_IN"],
			title: "Help",
			icon: ["fal", "life-ring"],
		},
		beforeEnter: getRecaptchaSiteKey,
	},
];
const otherRoutes: AppRouteConfig[] = [
	{
		path: RoutePath.RegistrationOfInterest,
		name: "Registration of Interest",
		component: RegistrationOfInterestPage,
		beforeEnter: getRecaptchaSiteKey,
	},
	{
		path: RoutePath.Profile,
		name: "Profile",
		component: ProfilePage,
		meta: {
			permissions: [],
			title: "Profile",
			icon: ["fal", "user"],
		},
	},
	{
		path: "/",
		name: "Login",
		component: LoginPage,
		alias: [RoutePath.Login],
		beforeEnter: getRecaptchaSiteKey,
	},
	{
		path: RoutePath.Forbidden,
		name: "Forbidden",
		component: ForbiddenPage,
	},
	{
		path: RoutePath.CreateBatch,
		name: "Create batch",
		component: CreateBatchPage,
		meta: {
			permissions: ["ADD_CONTRIBUTIONS"],
			icon: ["fal", "file-plus"],
			title: "Create contributions manually",
		},
	},
	{
		path: RoutePath.FileLoadSummary,
		name: "File load summary",
		component: FileLoadSummaryPage,
		meta: {
			permissions: ["VIEW_CONTRIBUTIONS"],
		},
	},
	{
		path: RoutePath.DataInput,
		name: "Data Input",
		component: DataInputPage,
		meta: {
			permissions: ["VIEW_CONTRIBUTIONS"],
		},
	},
	{
		path: RoutePath.FileErrorPage,
		name: "File errors and warnings",
		component: FileErrorPage,
		meta: {
			permissions: ["VIEW_CONTRIBUTIONS"],
		},
	},
	{
		path: RoutePath.ContributionValidateContents,
		name: "Validate Contents",
		component: ValidateContentPage,
		meta: {
			permissions: ["VIEW_CONTRIBUTIONS"],
		},
	},
	{
		path: RoutePath.FinalBatchReview,
		name: "Final Review",
		component: FinalBatchReviewPage,
		meta: {
			permissions: ["VIEW_CONTRIBUTIONS"],
		},
	},
	{
		path: RoutePath.PaymentDetails,
		name: "Payment Details",
		component: PaymentDetailsPage,
		meta: {
			permissions: ["AUTHORISE_PAYMENTS"],
		},
	},
	{
		path: RoutePath.AddFund,
		name: "Add Fund",
		component: AddFundPage,
		meta: {
			permissions: ["ADD_FUND"],
			title: "Add fund",
			icon: ["fal", "hand-holding-usd"],
		},
	},
	{
		path: RoutePath.EditFund,
		name: "Edit Fund",
		component: EditFundPage,
		props: true,
		meta: {
			title: "Edit fund",
			icon: ["fal", "hand-holding-usd"],
			permissions: ["MAINTAIN_FUNDS"],
		},
	},
	{
		path: RoutePath.ViewFund,
		name: "View Fund",
		component: ViewFundPage,
		props: true,
		meta: {
			title: "View fund",
			icon: ["fal", "hand-holding-usd"],
			permissions: ["VIEW_FUNDS"],
		},
	},
	{
		path: RoutePath.UserMaintenance,
		name: "Users and roles",
		component: PortalUserListPage,
		meta: {
			title: "Users and roles",
			icon: ["fal", "user-cog"],
			permissions: ["VIEW_USERS"],
		},
	},
	//{
	//  path: '/about',
	//  name: 'About',
	//  component: AboutPage,
	// route level code-splitting
	// this generates a separate chunk (about.[hash].js) for this route
	// which is lazy-loaded when the route is visited.
	//component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
	//},
	{
		path: "*",
		name: "Not Found",
		component: NotFoundPage,
		alias: [RoutePath.NotFound],
	},
	{
		path: RoutePath.BatchDetails,
		name: "Batch Details",
		component: BatchDetailsPage,
		meta: {
			permissions: ["VIEW_CONTRIBUTIONS"],
			title: "Contribution details",
			icon: ["fal", "clipboard-list"],
		},
	},
	{
		path: RoutePath.AddEmployee,
		name: "Add Employee",
		component: AddOrEditEmployee,
		props: (route) => {
			return {
				mode: route.params.mode,
				reportingCentreId: Number.parseInt(
					route.params.reportingCentreId,
					10
				),
				definedBenefitMode: route.params.definedBenefitMode,
			};
		},
		meta: {
			permissions: ["ADD_EMPLOYEES"],
		},
	},
	{
		path: RoutePath.AddDbEmployee,
		name: "Add DB Employee",
		component: AddDbEmployee,
		props: (route) => {
			return {
				mode: route.params.mode,
				reportingCentreId: Number.parseInt(
					route.params.reportingCentreId,
					10
				),
			};
		},
		meta: {
			permissions: ["ADD_EMPLOYEES"],
		},
	},
	{
		path: RoutePath.EditEmployee,
		name: "Edit Employee",
		component: AddOrEditEmployee,
		props: (route) => {
			return {
				mode: route.params.mode,
				employeeId: Number.parseInt(route.params.id, 10),
				reportingCentreId: Number.parseInt(
					route.params.reportingCentreId,
					10
				),
			};
		},
		meta: {
			permissions: ["EDIT_EMPLOYEES"],
		},
	},
	{
		path: RoutePath.EditEmployee,
		name: "View Employee",
		component: AddOrEditEmployee,
		props: (route) => {
			return {
				mode: route.params.mode,
				employeeId: Number.parseInt(route.params.id, 10),
				reportingCentreId: Number.parseInt(
					route.params.reportingCentreId,
					10
				),
			};
		},
		meta: {
			permissions: ["VIEW_EMPLOYEES"],
		},
	},
	{
		path: RoutePath.CompanyMaintenance,
		name: "Company Maintenance",
		component: CompanyMaintenance,
		meta: {
			permissions: ["VIEW_EMPLOYER"],
			icon: ["fal", "sitemap"],
		},
	},
	{
		path: RoutePath.AddEmployer,
		name: "Add Employer",
		props: true,
		component: AddEmployer,
		meta: {
			permissions: ["ADD_EMPLOYER"],
			title: "Add employer",
			icon: ["fal", "landmark"],
		},
	},
	{
		path: RoutePath.AddPortalUser,
		name: "Add User",
		props: (route) => ({ pageMode: route.params.mode }),
		component: AddOrEditPortalUsers,
		meta: {
			permissions: ["ADD_USERS"],
			title: "Add user",
			icon: ["fal", "user-plus"],
		},
	},
	{
		path: RoutePath.EditPortalUser,
		name: "Edit User",
		props: (route) => ({
			pageMode: route.params.mode,
			userId: Number.parseInt(route.params.id, 10),
		}),
		component: AddOrEditPortalUsers,
		meta: {
			permissions: ["EDIT_USERS"],
			title: "Edit user",
			icon: ["fal", "user-edit"],
		},
	},
	{
		path: RoutePath.ViewPortalUser,
		name: "View User",
		props: (route) => ({
			pageMode: route.params.mode,
			userId: Number.parseInt(route.params.id, 10),
		}),
		component: AddOrEditPortalUsers,
		meta: {
			permissions: ["VIEW_USERS"],
			title: "View user",
			icon: ["fal", "search"],
		},
	},
	{
		path: RoutePath.AddReportingCentre,
		name: "Add Reporting Centre",
		component: AddReportingCentre,
		props: (route) => {
			return {
				employerId: Number.parseInt(route.params.employerId, 10),
				rcStrategy: route.params.rcStrategy,
				abn: route.params?.abn || null,
			};
		},
		meta: {
			permissions: ["ADD_EMPLOYER"],
			title: "Add reporting centre",
			icon: ["fal", "users"],
		},
	},
	{
		path: RoutePath.HelpDocumentation,
		name: "Documentation",
		component: DocumentationPage,
		meta: {
			permissions: ["LOGGED_IN"],
			title: "Documentation",
			icon: ["fal", "book"],
		},
	},
	{
		path: RoutePath.HelpVideos,
		name: "Videos",
		component: DocumentationVidPage,
		meta: {
			permissions: ["LOGGED_IN"],
			title: "Videos",
			icon: ["fal", "video"],
		},
	},
	{
		path: RoutePath.ReleaseNotes,
		name: "Release notes",
		component: ReleaseNotesPage,
		meta: {
			permissions: ["LOGGED_IN"],
			title: "Release notes",
			icon: ["fal", "list-ul"],
		},
	},
	{
		path: RoutePath.EmployerRegistrationMaintenance,
		name: "Employer Registrations Maintenance",
		component: EmployerRegistrationMaintenance,
		meta: {
			permissions: ["VIEW_EMPLOYER_REGISTRATION"],
			title: "Employer maintenance",
			icon: ["fal", "list"],
		},
	},
	{
		path: RoutePath.ViewEmployerRegistration,
		name: "Review Employer Registration",
		component: EmployerRegistrationReview,
		meta: {
			permissions: ["VIEW_EMPLOYER_REGISTRATION"],
			title: "Review employer registration",
			icon: ["fal", "list"],
		},
		props: (route) => ({
			employerRegistrationId: Number(route.params.employerRegistrationId),
		}),
	},
	{
		path: RoutePath.AccountBalanceTransactions,
		name: "Transaction details",
		component: TransactionsPage,
		props: true,
		meta: {
			permissions: ["VIEW_ACCOUNT_BALANCE"],
			title: "Transaction details",
			icon: ["fal", "list-alt"],
		},
	},
	{
		path: RoutePath.MaintainFileMapping,
		name: "Configure file mappings",
		component: FileMappingPage,
		meta: {
			permissions: ["EDIT_FILE_MAPPING", "VIEW_FILE_MAPPING"],
			title: "Configure file mappings",
			icon: ["fal", "line-columns"],
		},
	},
	{
		path: RoutePath.ViewFileMapping,
		name: "View file mappings",
		component: FileMappingPage,
		meta: {
			permissions: ["VIEW_ADMIN_OPS", "VIEW_FILE_MAPPING"],
			title: "File mappings",
			icon: ["fal", "line-columns"],
		},
	},
	{
		path: RoutePath.FundMapping,
		name: "View USI mappings",
		component: UsiMappingPage,
		meta: {
			permissions: ["VIEW_ADMIN_OPS", "VIEW_FILE_MAPPING"],
			title: "Configure fund mappings",
			icon: ["fal", "line-columns"],
			//TODO update Icon
		},
	},
	{
		path: RoutePath.UsiMappingDetails,
		name: "Configure fund mapping details",
		component: USIMappingDetailsPage,
		props: true,
		meta: {
			permissions: ["EDIT_FILE_MAPPING", "VIEW_FILE_MAPPING"],
			title: "Fund mapping details",
			icon: ["fal", "line-columns"],
		},
	},
	{
		path: RoutePath.MaintainFileMappingDetails,
		name: "Configure file mapping details",
		component: FileMappingDetailsPage,
		props: true,
		meta: {
			permissions: ["EDIT_FILE_MAPPING", "VIEW_FILE_MAPPING"],
			title: "File mapping details",
			icon: ["fal", "line-columns"],
		},
	},
	{
		path: RoutePath.ViewFileMappingDetails,
		name: "View file mapping details",
		component: FileMappingDetailsPage,
		props: true,
		meta: {
			permissions: ["VIEW_FILE_MAPPING"],
			title: "File mapping details",
			icon: ["fal", "line-columns"],
		},
	},
	{
		path: RoutePath.FundMessage,
		name: "Fund message",
		component: FundMessagePage,
		props: true,
		meta: {
			permissions: ["VIEW_FUND_MESSAGE_CATEGORY"],
			title: "Fund message",
			icon: ["fal", "envelope-square"],
		},
	},
];
const routes = concat(menuRoutes).concat(rightMenuRoutes).concat(otherRoutes);
export default routes;
