import { AxiosInstance } from "axios";

type URLParameter =
	| string
	| number
	| boolean
	| URLParameterMap
	| URLParameterMap[]
	| undefined
	| null;
type URLParameterMap = { [param: string]: any } | undefined | null;

function buildParams(
	prefix: string,
	obj: URLParameterMap | URLParameter,
	add: (key: string, value: any) => void
) {
	if (Array.isArray(obj)) {
		// Empty array is ignored and parameter is not added.
		// Assume array elements are of same type.
		const arrayElementType = typeof obj[0];
		if (["string", "number", "boolean"].includes(arrayElementType)) {
			add(prefix, obj.join(","));
			return;
		}
		// tslint:disable-next-line prefer-for-of
		for (let i = 0; i < obj.length; i++) {
			const v = obj[i];
			buildParams(`${prefix}[${i}]`, v, add);
		}
	} else if (typeof obj === "object") {
		// Serialize object item.
		for (const name in obj) {
			if (!Object.prototype.hasOwnProperty.call(obj, name)) {
				continue;
			}
			buildParams(prefix + "." + name, obj[name], add);
		}
	} else {
		// Serialize scalar item.
		add(prefix, obj);
	}
}

/**
 * Replacement for jQuery.param() function. This converts an input object to a url query parameter string.
 * eg. {a:'1', b:['2','3'],c:{d:'4',e:'5'},d:[{o:'6'},{o:'7'}]} maps to
 * parsed: 'a=1&b=2,3&c.d=4&c.3=5&d[0].o=6&d[1].o=7'
 * encoded: 'a=1&b=2%2C3&c.d=4&c.e=5&d%5B0%5D.o=6&d%5B1%5D.o=7'
 *
 * This is code was taken from jQuery 3.4.1 and modified to support Spring Boot.
 *
 * @param obj
 * @returns {string} the corresponding query parameter string.
 */
function encodeQueryParams(params: URLParameterMap): string {
	if (params === null || params === undefined) {
		return "";
	}

	const s: string[] = [];
	const add = (key: string, valueOrFunction: any) => {
		// If value is a function, invoke it and use its return value
		const value =
			typeof valueOrFunction === "function"
				? valueOrFunction()
				: valueOrFunction;
		if (value === undefined) {
			// Ignore undefined parameters.
			return;
		}
		s.push(
			encodeURIComponent(key) +
				"=" +
				encodeURIComponent(value == null ? "" : value)
		);
	};

	// If an array was passed in, assume that it is an array of form elements.
	if (Array.isArray(params)) {
		// Serialize the form elements
		for (const name in params) {
			if (!Object.prototype.hasOwnProperty.call(params, name)) {
				continue;
			}
			const value = params[name];
			add(name, value);
		}
	} else {
		// Encode params recursively.
		for (const name in params) {
			if (!Object.prototype.hasOwnProperty.call(params, name)) {
				continue;
			}
			const value = params[name];
			buildParams(name, value, add);
		}
	}
	if (!s || s.length === 0) {
		return "";
	}
	return s.join("&");
}

/**
 * applySpringBootInterceptor ensures objects are serialized to support Spring Boot
 *
 * Issue is defined here:
 * - https://stackoverflow.com/questions/5900840/post-nested-object-to-spring-mvc-controller-using-json/7203353#7203353
 */
export function applySpringBootInterceptor(instance: AxiosInstance) {
	instance.interceptors.request.use(
		function (config) {
			if (!config.params || typeof config.params !== "object") {
				return config;
			}
			const newConfig = { ...config };
			const paramString = encodeQueryParams(newConfig.params);
			newConfig.params = undefined;
			newConfig.url += "?" + paramString;
			return newConfig;
		},
		function (error) {
			return Promise.reject(error);
		}
	);
}
