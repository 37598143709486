
























import { Component, Vue, Prop } from "vue-property-decorator";
import Button from "@/form/Button.vue";
import { debounce } from "lodash-es";

@Component({
	components: {
		Button,
	},
})
export default class RefreshButton extends Vue {
	@Prop({ default: 10 })
	private readonly refreshInterval!: number;

	@Prop({ required: true })
	private readonly intervalId!: string;

	@Prop({ required: true })
	private readonly canRefresh!: boolean;

	@Prop()
	private readonly triggerRefresh!: () => void;

	@Prop({ default: false })
	private readonly autoRefresh!: boolean;

	@Prop({ default: "Refresh" })
	private readonly buttonName!: string;

	mounted() {
		this.setIntervalForNewId(this.intervalId);
	}

	beforeDestroy() {
		this.stopRefreshTimer();
	}

	private readonly intervalData: {
		id?: string;
		remaining?: number;
		intervalRef?: number;
		isRefreshing: boolean;
	} = {
		id: undefined,
		remaining: undefined,
		intervalRef: undefined,
		isRefreshing: false,
	};

	/**
	 * Summary data is refreshed periodically while the page is open
	 */
	private setIntervalForNewId(id: string) {
		if (this.intervalData.intervalRef !== undefined) {
			this.intervalData.isRefreshing = false;
			clearInterval(this.intervalData.intervalRef);
			this.intervalData.intervalRef = undefined;
		}

		this.intervalData.id = id;
		this.intervalData.remaining = this.refreshInterval;
		// Runs every second and triggers refresh every refresh interval
		this.intervalData.intervalRef = window.setInterval(() => {
			if (this.intervalData.isRefreshing) {
				return;
			}
			if (
				this.intervalData.remaining === undefined ||
				this.intervalData.remaining < 0
			) {
				throw new Error("Invalid state for refresh interval");
			}

			if (this.intervalData.remaining === 0 && this.canRefresh) {
				if (this.autoRefresh) {
					this.onClickRefresh();
				}
				return;
			}
			this.intervalData.remaining = Math.abs(
				this.intervalData.remaining - 1
			);
		}, 1000);
	}

	stopRefreshTimer(): void {
		if (this.intervalData.intervalRef !== undefined) {
			clearInterval(this.intervalData.intervalRef);
			this.intervalData.intervalRef = undefined;
		}
		this.intervalData.remaining = undefined;
	}

	get refreshCount(): string {
		if (this.intervalData.remaining === undefined) {
			return "";
		}
		return this.intervalData.remaining === 0
			? ""
			: "" + this.intervalData.remaining;
	}

	private readonly onClickRefresh = debounce(
		() => {
			this.triggerRefresh();
			this.setIntervalForNewId(this.intervalId);
		},
		500,
		{ leading: true, trailing: false }
	);
}
