





































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import TextField from "@/form/TextField.vue";
import SelectField from "@/form/SelectField.vue";
import FieldGroup from "@/form/FieldGroup.vue";
import Button from "@/form/Button.vue";
import SubHeading from "@/components/SubHeading.vue";
import { createNamespacedHelpers } from "vuex";
import { PortalUser } from "@/models/PortalUser";
const { mapState } = createNamespacedHelpers("persistent");

@Component({
	components: {
		Layout,
		TextField,
		FieldGroup,
		Button,
		SelectField,
		SubHeading,
	},
	computed: mapState(["brandId", "username"]),
})
export default class UserDetails extends Vue {
	@Prop() userDetail!: PortalUser | null;

	/**
	 * Type the mapped persistent.selectedEntities getter.
	 * It is a computed property. Do not mutate it.
	 */
	brandId!: number | null;

	/**
	 * Type the mapped persistent.selectedEntities getter.
	 * It is a computed property. Do not mutate it.
	 * hide suspend field if current user being edited has the same username in store
	 */
	username!: string;

	@Prop(Boolean) readonly readonly!: boolean;

	update(field: string, value: string) {
		this.$emit("change", { field: field, value: value });
	}
}
