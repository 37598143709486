











































































import axios from "@/utils/ApiUtils";
import { Component } from "vue-property-decorator";
import { parseErrorMessageList } from "@/utils/ErrorUtils";
import { businessDetailsApi } from "@/constants/apiconstants";
import TableForm from "@/components/TableForm.vue";
import TextField from "@/form/TextField.vue";
import SelectField from "@/form/SelectField.vue";
import NumberField from "@/form/NumberField.vue";
import { AbnLookupDetails } from "@/pages/registrationofinterest/registrationOfInterestTypes";
import { isValidAbn } from "@/plugins/validators/rule/rules";
import { stateList } from "@/constants/constants";
import { SelectOption } from "@/form/FieldOptions";
import BaseRegistrationOfInterestStep from "./BaseRegistrationOfInterestStep.vue";

import { createNamespacedHelpers } from "vuex";

const { mapState } = createNamespacedHelpers("registrationOfInterest");

@Component({
	components: {
		NumberField,
		TableForm,
		TextField,
		SelectField,
	},
	computed: {
		...mapState([
			"abn",
			"registeredName",
			"tradingName",
			"addressLine1",
			"addressLine2",
			"suburb",
			"postcode",
			"state",
			"secretCode",
		]),
	},
})
export default class BusinessDetailsStep extends BaseRegistrationOfInterestStep {
	abn!: string;
	registeredName!: string;
	tradingName!: string;
	addressLine1!: string;
	addressLine2!: string;
	suburb!: string;
	postcode!: string;
	state!: string;
	secretCode!: string;

	states!: SelectOption[];

	abnErrors: string[] = [];
	isLoadingFromRemote = false;

	handleAbnInput(value: string) {
		this.updateField({ abn: value });
		if (this.abnErrors) {
			this.abnErrors = [];
		}

		if (isValidAbn(value)) {
			this.fetchBusinessDetailsByAbn();
		}
	}

	fetchBusinessDetailsByAbn() {
		this.isLoadingFromRemote = true;

		return axios
			.get<AbnLookupDetails>(businessDetailsApi, {
				params: {
					abn: this.abn,
				},
				headers: {
					"X-Registration-Wizard-Unique-Access-Code": this.secretCode,
				},
			})
			.then((response) => {
				this.updateField({ ...response.data });
			})
			.catch((error) => {
				this.abnErrors = parseErrorMessageList(error);
			})
			.finally(() => {
				this.isLoadingFromRemote = false;
			});
	}

	created() {
		this.states = stateList;
	}
}
