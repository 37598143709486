


















































































































































import { isEmpty } from "lodash";
import { Component, Prop } from "vue-property-decorator";

import Subform from "@/form/Subform.vue";
import TextField from "@/form/TextField.vue";
import ButtonBehaviour from "@/form/ButtonBehaviour.vue";
import RadioGroup from "@/form/RadioGroup.vue";
import MultiSelect from "@/form/MultiSelect.vue";
import CheckBox from "@/form/CheckBox.vue";
import SearchFund from "@/components/SearchFund.vue";
import { SelectOption } from "@/form/FieldOptions";
import {
	ContributionFileType,
	FundDetails,
	FundType,
	SearchFundDetails,
	SmsfAliasMap,
} from "@/pages/fundTypes";
import { toastErrorMessage } from "@/plugins/toasts";
import { hasPermission } from "@/utils/PermissionUtils";

@Component({
	components: {
		ButtonBehaviour,
		CheckBox,
		MultiSelect,
		RadioGroup,
		TextField,
		SearchFund,
	},
})
export default class FundDetailsFields extends Subform {
	@Prop({ type: Object, required: true })
	readonly values!: FundDetails;

	@Prop({ type: Number, required: true })
	readonly reportingCentreId!: number;

	@Prop({ type: Number })
	readonly employeeId!: number | undefined;

	@Prop({ type: Boolean, default: false })
	readonly readOnly!: boolean;

	@Prop({ type: String, default: ContributionFileType.SUPERSTREAM })
	readonly contributionFileType!:
		| ContributionFileType.DB_BYPASS
		| ContributionFileType.SUPERSTREAM;

	private fundType = FundType.APRA;
	private editMode = false;
	private currentSmsfAliasMap: SmsfAliasMap | {} = {};

	private get isAPRAFund(): boolean {
		return this.fundType === FundType.APRA;
	}

	private get isSMSFFund(): boolean {
		return this.fundType === FundType.SMSF;
	}

	private get isSchemeFund(): boolean {
		return this.fundType === FundType.SCHEME;
	}

	get canEdit(): boolean {
		return this.hasPermission("EDIT_FUND_SENSITIVE") && !this.readOnly;
	}

	private get fundElectronicServiceAddressOptions(): SelectOption[] {
		if (
			this.fundType !== FundType.SMSF ||
			isEmpty(this.currentSmsfAliasMap)
		) {
			return [{ value: "", label: "" }];
		}

		return Object.keys(this.currentSmsfAliasMap).map((smsfAlias) => ({
			value: smsfAlias,
			label: smsfAlias,
		}));
	}

	private enableEditMode() {
		if (this.reportingCentreId === 0) {
			toastErrorMessage("Please select a reporting centre first");
			return;
		}

		this.editMode = true;
	}

	private handleSmsfAliasMapUpdate(smsfAliasMap: SmsfAliasMap) {
		this.currentSmsfAliasMap = smsfAliasMap;
	}

	private handleSearchFundUpdate(fundDetails: SearchFundDetails) {
		this.handleSmsfAliasMapUpdate(fundDetails.smsfAliasMap);
		this.$emit("update", fundDetails);
	}

	private hasPermission(permission: string): boolean {
		return hasPermission(permission);
	}

	/**
	 * Determine the fund type based on the given fund details.
	 *
	 * This method only checks if fields exclusive to any fund type have values,
	 * it does not care about the validity of those fields.
	 *
	 * CHSN-569: If fund data contains both APRA and SMSF details, or contains nothing,
	 * we assume the type to be APRA.
	 */
	private determineFundTypeFromFundDetails(value: FundDetails): FundType {
		if (this.contributionFileType === ContributionFileType.DB_BYPASS) {
			return FundType.SCHEME;
		}
		if (value.fundUsi) {
			return FundType.APRA;
		}
		if (value.fundAbn) {
			return FundType.SMSF;
		}
		return FundType.APRA;
	}

	private created() {
		this.fundType = this.determineFundTypeFromFundDetails(this.values);
	}
}
