var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ButtonBehaviour',{class:{
		Button__root: true,
		'Button__root--disabled': _vm.disabled,
		'Button__root--block': _vm.block,
		'Button__root--sm': _vm.size === 'sm',
		'Button__root--md': _vm.size === 'md',
		'Button__root--lg': _vm.size === 'lg',
		Button__default: _vm.currentVariant === '',
		Button__primary: _vm.currentVariant === 'primary',
		Button__secondary: _vm.currentVariant === 'secondary',
		Button__link: _vm.currentVariant === 'link',
		'Button__link-light': _vm.currentVariant === 'link-light',
	},attrs:{"tag":_vm.tag,"data-cy":_vm.dataCy,"type":_vm.type,"href":_vm.sanitisedHref,"disabled":_vm.disabled,"for":this.for},on:_vm._d({},[_vm.clickEvent,_vm.onClick])},[(_vm.icon && _vm.icon.length > 0)?_c('font-awesome-icon',{staticClass:"Button__icon",attrs:{"icon":_vm.icon}}):_vm._e(),_c('span',{staticClass:"Button__text"},[_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }