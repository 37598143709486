




























































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import Button from "@/form/Button.vue";
import CompanyDetails from "@/components/CompanyDetails.vue";
import axios from "@/utils/ApiUtils";
import {
	CompanyDetail,
	MaintenanceEntity,
	UpdateFieldValue,
} from "@/pages/maintenanceTypes";
import EmployerSelector from "@/components/EmployerSelector.vue";
import { toastErrorMessage, toastSuccessMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import { saveEmployer } from "@/constants/apiconstants";
import { VForm } from "@/@typings/type-vee-validate";
import PageHeader from "@/components/PageHeader.vue";
import ModalWithSaveAndCancel from "@/components/ModalWithSaveAndCancel.vue";
import { getParentOrgNameForParentOrgId } from "@/utils/PermissionUtils";
import { AxiosResponse } from "axios";
import CompanyConfigDetails from "@/components/CompanyConfigDetails.vue";
import { createNamespacedHelpers } from "vuex";

const { mapActions } = createNamespacedHelpers("persistent");

@Component({
	components: {
		CompanyConfigDetails,
		PageHeader,
		CompanyDetails,
		ModalWithSaveAndCancel,
		Layout,
		EmployerSelector,
		Button,
	},
	methods: {
		...mapActions(["fetchEmployerHierarchy"]),
	},
})
export default class AddEmployer extends Vue {
	private companyDetail: CompanyDetail = {
		abn: "",
		name: "",
		addressLine1: "",
		addressLine2: "",
		suburb: "",
		postCode: "",
		state: "",
		dateFormat: "",
	};
	private selectedParent: string | null = null;
	private selectedEntity: string | null = null;
	private showCancelModal = false;
	@Prop({ type: String }) private parentOrganisationId!: string;

	/**
	 * Type the mapped persistent.fetchEmployerHierarchy action.
	 */
	fetchEmployerHierarchy!: () => void;

	async saveEmployer() {
		const existingEmployerABNsForParentOrg: string =
			// eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
			localStorage.getItem("ParentOrgABNs")!;

		const newCompanyABN = this.replaceAll(
			this.replaceAll(this.companyDetail.abn.trim(), "-", ""),
			" ",
			""
		);

		if (existingEmployerABNsForParentOrg.includes(newCompanyABN)) {
			toastErrorMessage(
				"This Employer ABN is already attached to your business structure."
			);
			return;
		} else {
			if (this.parentOrganisationId && this.companyDetail) {
				this.companyDetail.parentOrganisationId = Number(
					this.parentOrganisationId
				);
				if (await (this.$refs.employerForm as VForm).validate()) {
					axios
						.post(saveEmployer(), this.companyDetail)
						.then((response: AxiosResponse) => {
							this.fetchEmployerHierarchy();
							toastSuccessMessage("Employer added successfully");
							this.$router.push({ name: "Company Maintenance" });
						})
						.catch((error) => {
							toastErrorMessage(parseErrorMessage(error));
						});
				}
			}
		}
	}

	replaceAll(stringIn: string, search: string, replace: string): string {
		return stringIn.split(search).join(replace);
	}

	showParentScreen() {
		this.$router.push({
			name: "Company Maintenance",
		});
	}

	private discardChanges() {
		this.$router.push({ name: "Company Maintenance" });
	}
	private closeModal() {
		this.showCancelModal = false;
	}

	cancel() {
		if (
			this.companyDetail &&
			this.isEmptyVal(this.companyDetail.abn) &&
			this.isEmptyVal(this.companyDetail.name) &&
			this.isEmptyVal(this.companyDetail.addressLine1) &&
			this.isEmptyVal(this.companyDetail.addressLine2) &&
			this.isEmptyVal(this.companyDetail.suburb) &&
			this.isEmptyVal(this.companyDetail.postCode) &&
			this.isEmptyVal(this.companyDetail.state)
		) {
			this.$router.push({
				name: "Company Maintenance",
			});
		} else {
			this.showCancelModal = true;
		}
	}

	updateCompanyDetail(e: UpdateFieldValue) {
		if (this.companyDetail) {
			Vue.set(this.companyDetail, e.field, e.value);
		}
	}

	isEmptyVal(val: string) {
		if (val === undefined || val === null || val === "") {
			return true;
		}
		return false;
	}

	mounted() {
		if (this.parentOrganisationId) {
			const parent: MaintenanceEntity | null =
				getParentOrgNameForParentOrgId(
					"P_" + this.parentOrganisationId
				);
			if (parent) {
				this.selectedParent = parent.label;
			}
		}
	}
}
