export function embedQuicksight(
	elementId: string,
	dashboard: string,
	height: string,
	width: string
) {
	const containerDiv = document.getElementById(elementId);
	if (containerDiv && "" === dashboard) {
		containerDiv.innerHTML = "";
		return;
	}

	const options = {
		// replace this dummy url with the one generated via embedding API
		url: dashboard,
		container: containerDiv,
		scrolling: "no",
		height: height,
		iframeResizeOnSheetChange: true, // use this option in combination with height: AutoFit,
		width: width,
		footerPaddingEnabled: true,
		// sheetId: "YOUR_SHEETID", // use this option to specify initial sheet id to load for the embedded dashboard
		sheetTabsDisabled: true, // use this option to enable or disable sheet tab controls
		undoRedoDisabled: true, // use this option to disable undo and redo buttons
		resetDisabled: true, // use this option to disable reset button
		parameters: {},
	};
	/* eslint-disable */
	const QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");
	// Always clear inner HTML before embedding new dashboard
	if (containerDiv) {
		containerDiv.innerHTML = "";
	}
	QuickSightEmbedding.embedDashboard(options);
}
