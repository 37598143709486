





















































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Button from "@/form/Button.vue";
import TextField from "@/form/TextField.vue";
import axios from "@/utils/ApiUtils";
import SelectField from "@/form/SelectField.vue";
import {
	listAvailableFileFormat,
	addFormatListUrl,
} from "@/constants/apiconstants";
import { FileFormat } from "@/models/FileFormatRow";
import { SelectOption } from "@/form/FieldOptions";
import { toastErrorMessage, toastSuccessMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";

class FileFormatClass {
	id = null;
}

@Component({
	components: {
		Button,
		TextField,
		SelectField,
	},
})
export default class LinkFileFormat extends Vue {
	@Prop({ type: String, default: "" })
	readonly target!: string;

	@Prop({ type: String, default: "top" })
	readonly placement!: string;

	@Prop({ type: String, default: "" })
	readonly selectedEntity!: string;

	private fileFormatOptions: SelectOption[] = [];

	private fileFormatObj: FileFormatClass = new FileFormatClass();

	addFormatLink() {
		axios
			.get(addFormatListUrl(), {
				params: {
					fileFormatId: this.fileFormatObj.id,
					selectEntity: this.selectedEntity,
				},
			})
			.then((response) => {
				if (response.data) {
					toastSuccessMessage("A file format has been associated.");
					this.$emit("done");

					// Reset the file obj
					this.fileFormatObj.id = null;
				}
			})
			.catch((rejected) => {
				toastErrorMessage(parseErrorMessage(rejected));
			});
	}

	close() {
		this.$emit("close");
	}

	mounted() {
		axios
			.get(listAvailableFileFormat(), {
				params: {
					selectedEntity: this.selectedEntity,
				},
			})
			.then((response: { data: FileFormat[] }) => {
				response.data.forEach((item) => {
					this.fileFormatOptions.push({
						value: item.id.toString(),
						label: item.fileFormatName,
					});
				});
			});
	}
}
