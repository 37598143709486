var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
		container: true,
		Container__container: true,
		'Container__container--has-padding': !_vm.noPadding,
		'Container__container--md': _vm.size === 'md',
		'Container__container--sm': _vm.size === 'sm',
		'Container__container--lg': _vm.size === 'lg',
		'Container__container--fill': _vm.size === 'fill',
	}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }