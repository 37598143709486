










import Vue from "vue";
import { Component } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import MaintenanceGrid, { GridItem } from "@/components/MaintenanceGrid.vue";
import { RoutePath } from "@/router/routePath";
import axios from "@/utils/ApiUtils";
import { reportsURL } from "@/constants/apiconstants";
import { hasPermission } from "@/utils/PermissionUtils";
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("persistent");

@Component({
	components: { Layout, MaintenanceGrid },
	computed: {
		...mapGetters({
			definedBenefitEntities: "definedBenefitEntities",
		}),
	},
})
export default class ReportsPage extends Vue {
	private readonly reportTiles: GridItem[] = [
		{
			name: "Contributions report",
			description: "Choose how to group and view your contributions",
			link: RoutePath.ContributionsReport,
			buttonName: "View report",
			cardDataCy: "contributionByEmployee",
			dataCy: "contributionByEmployeeBtn",
			show: true,
		},
		{
			name: "SuperStream refund",
			description:
				"List of response messages included in a refund deposit",
			link: RoutePath.RefundReport,
			buttonName: "View report",
			cardDataCy: "superStreamRefund",
			dataCy: "superStreamRefundBtn",
			show: true,
		},
	];

	private maintenanceActive = false;
	private definedBenefitEntities!: () => [];

	updated() {
		this.maintenanceActive = typeof this.$refs.rv === "undefined";
	}
	mounted() {
		this.maintenanceActive = typeof this.$refs.rv === "undefined";
		if (
			hasPermission("VIEW_DB_REPORT") &&
			this.definedBenefitEntities.length > 0
		) {
			this.reportTiles.push({
				name: "DB Direct",
				description: "Fund generated reports",
				link: RoutePath.DefinedBenefitReport,
				buttonName: "View report",
				dataCy: "dbReportBtn",
				cardDataCy: "dbReport",
				show: true,
			});
		}
		axios.get(reportsURL() + "/employee-refund/v2").then((resp) => {
			if (resp.data) {
				this.reportTiles.push({
					name: "SuperStream refund V2",
					description:
						"List of response messages included in a refund deposit",
					link: RoutePath.RefundReportV2,
					buttonName: "View report",
					cardDataCy: "superStreamRefundV2",
					dataCy: "superStreamRefundV2Btn",
					show: true,
				});
			}
		});
	}
}
