




























import Vue from "vue";
import { Component } from "vue-property-decorator";
import IdleTimer from "@/components/IdleTimer.vue";
import Upscope from "@/components/Upscope.vue";
import InsightPage from "@/pages/InsightPage.vue";
import { createNamespacedHelpers } from "vuex";
import PortalNotifications from "@/components/PortalNotifications.vue";
import UserLoginNotification from "@/components/UserLoginNotification.vue";

const { mapGetters } = createNamespacedHelpers("persistent");

@Component({
	components: {
		Upscope,
		IdleTimer,
		InsightPage,
		UserLoginNotification,
		PortalNotifications,
	},
	methods: {
		...mapGetters(["isLoggedIn", "allPermission"]),
	},
})
export default class App extends Vue {
	private readonly isLoggedIn!: () => boolean;
	private readonly allPermission!: () => any;
}
