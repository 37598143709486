import store from "@/store";
import {
	AllGroupedPermissions,
	EmployerHierarchy,
	PermissionModel,
} from "@/store/modules/persistent/persistentTypes";
import { MaintenanceEntity } from "@/pages/maintenanceTypes";

export const EMPLOYER = "E_";
export const RC = "R_";
export const PARENT = "P_";

function checkPermission(
	permission: string,
	userPermissions: PermissionModel[]
): boolean {
	let permissionMatched;
	if (userPermissions !== null && userPermissions.length > 0) {
		for (const userPermission of userPermissions) {
			if (userPermission.permissions) {
				permissionMatched = userPermission.permissions.find(
					(s) => s === permission
				);
			}
			if (permissionMatched) {
				return true;
			}
		}
	}
	return false;
}

export function getParentOrgNameForParentOrgId(
	parentId: string
): MaintenanceEntity | null {
	const hierarchy = store.getters["persistent/employerHierarchy"];
	if (hierarchy.length > 0) {
		for (const parent of hierarchy) {
			if (parent.id === parentId) {
				return {
					id: parent.id,
					label: parent.label,
				} as MaintenanceEntity;
			}
		}
	}
	return null;
}

// Note: (York and Dhiraj) - `parent.children` is null possible. We should revisit and refactor method later.
export function getEmployerHierarchy(employerId: string): MaintenanceEntity[] {
	const hierarchy = store.getters["persistent/employerHierarchy"];
	if (hierarchy.length > 0) {
		for (const parent of hierarchy) {
			if (parent.id.startsWith(EMPLOYER) && parent.id === employerId) {
				return [
					{
						id: parent.id,
						label: parent.label,
					},
				];
			} else {
				for (const employer of parent.children) {
					if (employer.id === employerId) {
						return [
							{
								id: parent.id,
								label: parent.label,
							},
							{
								id: employer.id,
								label: employer.label,
							},
						];
					}
				}
			}
		}
	}
	return [];
}

// Note: (York and Dhiraj) - `parent.children` is null possible. We should revisit and refactor method later.
export function getReportingCentreHierarchy(
	reportingCentreId: string
): MaintenanceEntity[] {
	const hierarchy = store.getters["persistent/employerHierarchy"];
	if (hierarchy.length > 0) {
		for (const parent of hierarchy) {
			if (parent.id.startsWith(EMPLOYER)) {
				for (const rc of parent.children) {
					if (rc.id === reportingCentreId) {
						return [
							{
								id: parent.id,
								label: parent.label,
							},
							{
								id: rc.id,
								label: rc.label,
							},
						];
					}
				}
			} else {
				for (const employer of parent.children) {
					for (const rc of employer.children) {
						if (rc.id === reportingCentreId) {
							return [
								{
									id: parent.id,
									label: parent.label,
								},
								{
									id: employer.id,
									label: employer.label,
								},
								{
									id: rc.id,
									label: rc.label,
								},
							];
						}
					}
				}
			}
		}
	}
	return [];
}

//-----------------------------------------------------------------------------
// Helper functions for hasPermission

/**
 * Find a path to entity in the employerHierarchy tree. i.e employerHierarchy
 * like P1 -> E1 -> R1, then the path array is [P1, E1, R1]. Note that param
 * path is mutated. Elements of path array are EmployerHierarchy type, not just
 * entity id.
 */
export function findEntityPath(
	entity: string,
	employerHierarchy: EmployerHierarchy,
	path: EmployerHierarchy[]
) {
	if (entity === employerHierarchy.id) {
		path.push(employerHierarchy);
		return true;
	}
	for (const node of employerHierarchy.children) {
		const found = findEntityPath(entity, node, path);
		if (found) {
			path.unshift(employerHierarchy);
			return true;
		}
	}
	return false;
}

/**
 * Return true if a permission of an entity is in all permissions, otherwise return false.
 */
export function isEntityHasPermission(
	permission: string,
	entity: string,
	allPermissions: AllGroupedPermissions
) {
	const [type, id] = entity.split("_");
	let permissions: PermissionModel[];
	switch (type) {
		case "P":
			permissions = allPermissions.parentOrganisationPermissions ?? [];
			break;
		case "E":
			permissions = allPermissions.employerPermissions ?? [];
			break;
		case "R":
			permissions = allPermissions.reportingCentrePermissions ?? [];
			break;
		default:
			throw Error(`Unknown entity type ${type} of entity ${entity}.`);
	}
	return (
		permissions.find(
			(p) => p.id === id && p.permissions.includes(permission)
		) !== undefined
	);
}

/**
 * Return true if the employerHierarchy tree and subtree recursively have permissions, otherwise return false.
 */
export function isAllChildrenHavePermission(
	permission: string,
	employerHierarchy: EmployerHierarchy,
	allPermissions: AllGroupedPermissions
) {
	if (
		isEntityHasPermission(permission, employerHierarchy.id, allPermissions)
	) {
		return true;
	}
	// is leaf
	if (employerHierarchy.children.length === 0) {
		return false;
	}
	for (const child of employerHierarchy.children) {
		if (!isAllChildrenHavePermission(permission, child, allPermissions)) {
			return false;
		}
	}
	// all children have permission.
	return true;
}

/**
 * Return true if an entity has permission, otherwise return false.
 * If the entity is not found in employerHierarchy tree, return undefined.
 */
export function hasPermissionForEntity(
	permission: string,
	entity: string,
	employerHierarchy: EmployerHierarchy,
	allPermissions: AllGroupedPermissions
): boolean | undefined {
	const path: EmployerHierarchy[] = [];
	const found = findEntityPath(entity, employerHierarchy, path);
	// Should handle the last entity specially because employer selector applies
	// default BRANCH_PRIORITY value combination strategy. A branch node is
	// selected when all its descendants are selected.
	const last = path.pop();
	if (!found || last === undefined) {
		// Whether has permission is meaningless when entity is not found.
		return undefined;
	}
	for (const node of path) {
		if (isEntityHasPermission(permission, node.id, allPermissions)) {
			return true;
		}
	}
	return isAllChildrenHavePermission(permission, last, allPermissions);
}

/**
 * Return true if all entities have permissions in employerHierarchy tree list,
 * otherwise return false. If an entity is not found in employerHierarchy tree
 * list, throw error.
 * This function does not take into account the sponsor/domain permissions
 */
export function hasPermissionForSelectedEntities(
	permission: string,
	selectedEntities: string[],
	employerHierarchyList: EmployerHierarchy[],
	allPermissions: AllGroupedPermissions
) {
	for (const entity of selectedEntities) {
		let foundEntity = false;
		for (const employerHierarchy of employerHierarchyList) {
			const hasPermissionOrNotFound = hasPermissionForEntity(
				permission,
				entity,
				employerHierarchy,
				allPermissions
			);

			if (hasPermissionOrNotFound) {
				foundEntity = true;
			}
			// ignore undefined
			if (hasPermissionOrNotFound === false) {
				return false;
			}
		}
		if (!foundEntity) {
			throw Error(`${entity} is not found in employerHierarchy list.`);
		}
	}
	return true;
}
//-----------------------------------------------------------------------------

//This method to be used to check if the user permission to view/edit/update/delete an item
// NOTE (York): As hasPermission is widely used in the code base, i am afraid to change its signature.
// Hence I keep and combine store getters here and extract the biggest block into hasPermissionForSelectedEntities.
export function hasPermission(permission: string): boolean {
	if (permission === "LOGGED_IN") {
		return store.getters["persistent/isLoggedIn"];
	}
	const selectedEntities = store.getters["persistent/selectedEntities"];

	if (selectedEntities === null || selectedEntities.length === 0) {
		const userPermissions: PermissionModel[] =
			store.getters["persistent/allPermission"];
		return checkPermission(permission, userPermissions);
	} else {
		const sponsorPermissions =
			store.getters["persistent/sponsorPermissions"];
		const domainPermissions = store.getters["persistent/domainPermissions"];
		if (sponsorPermissions !== null && sponsorPermissions.length !== 0) {
			return checkPermission(permission, sponsorPermissions);
		} else if (
			domainPermissions !== null &&
			domainPermissions.length !== 0
		) {
			return checkPermission(permission, domainPermissions);
		} else {
			const employerHierarchy =
				store.getters["persistent/employerHierarchy"];
			const allPermissions =
				store.getters["persistent/allGroupedPermissions"];
			return hasPermissionForSelectedEntities(
				permission,
				selectedEntities,
				employerHierarchy,
				allPermissions
			);
		}
	}
	return false;
}

function checkPermissionForRoute(permission: string): boolean {
	if (permission === "LOGGED_IN") {
		return store.getters["persistent/isLoggedIn"];
	}
	const userPermissions: PermissionModel[] =
		store.getters["persistent/allPermission"];
	return checkPermission(permission, userPermissions);
}

//This method should be used to check if user has permission to view a page.
export function hasPermissionForRoute(permissions: string[]): boolean {
	let permissionMatched = 0;
	for (const permission of permissions) {
		if (checkPermissionForRoute(permission)) {
			permissionMatched++;
		}
	}
	if (permissionMatched === permissions.length) {
		return true;
	} else {
		return false;
	}
}
