

















































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import Button from "@/form/Button.vue";
import Container from "@/components/Container.vue";

export interface ModalProps {
	title: string;
}

@Component({
	components: {
		Button,
		Container,
	},
})
export default class ModalWithSaveAndCancel extends Vue implements ModalProps {
	private static bodyClass = "Modal--is-body-open";

	private static emitCancel = "cancel";
	private static emitConfirm = "confirm";
	@Prop({ type: String, default: "Confirm" }) confirmButtonName!: string;
	@Prop({ type: Boolean, default: false }) disableConfirmButton!: boolean;

	/**
	 * modalsMounted tracks the modals we have open so that we can
	 * nest modals.
	 */
	private static modalsMounted = 0;

	@Prop(String) title!: string;

	mounted(): void {
		if (ModalWithSaveAndCancel.modalsMounted === 0) {
			document.body.classList.add(ModalWithSaveAndCancel.bodyClass);
		}
		ModalWithSaveAndCancel.modalsMounted++;
		document.body.addEventListener("keydown", this.onKeyDown, {
			capture: true,
			passive: false,
		});
	}

	get confirmButton() {
		return this.confirmButtonName;
	}

	beforeDestroy(): void {
		ModalWithSaveAndCancel.modalsMounted--;
		if (ModalWithSaveAndCancel.modalsMounted === 0) {
			document.body.classList.remove(ModalWithSaveAndCancel.bodyClass);
		}
		document.body.removeEventListener("keydown", this.onKeyDown);
	}

	/**
	 * errors will return developer mistakes caught at runtime.
	 * These will only appear in development mode.
	 */
	get errors(): string {
		if (process.env.NODE_ENV !== "development") {
			return "";
		}
		if (
			!this.$listeners ||
			!this.$listeners[ModalWithSaveAndCancel.emitCancel]
		) {
			return `Developer Error: You should bind a @${ModalWithSaveAndCancel.emitCancel} event to your Modal so that keyboard accessibility cases, like pressing ESCAPE works.`;
		}
		if (
			!this.$listeners ||
			!this.$listeners[ModalWithSaveAndCancel.emitConfirm]
		) {
			return `Developer Error: You should bind a @${ModalWithSaveAndCancel.emitConfirm} event to your Modal so that keyboard accessibility cases, like pressing ESCAPE works.`;
		}
		return "";
	}

	private onKeyDown(e: KeyboardEvent): void {
		switch (e.key) {
			case "Escape": {
				this.triggerCancel();
				return;
			}
		}
	}

	private triggerCancel(): void {
		this.$emit(ModalWithSaveAndCancel.emitCancel);
	}
	private triggerConfirm(): void {
		this.$emit(ModalWithSaveAndCancel.emitConfirm);
	}
}
