








import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class BellOnIcon extends Vue {
	@Prop({ type: String, default: "Alert" })
	title!: string;

	@Prop({ type: String, default: "text-danger fa-lg" })
	iconType!: string;
}
