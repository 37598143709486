
































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { ContributionSummary } from "@/rest/ContributionResource";
import WarningIcon from "@/components/WarningIcon.vue";
import ErrorIcon from "@/components/ErrorIcon.vue";

@Component({
	components: { ErrorIcon, WarningIcon },
})
export default class ContributionSummaryComponent extends Vue {
	@Prop([Boolean]) readonly gridReady!: boolean;
	@Prop({ type: Number, default: null }) batchId!: number;

	@Prop() readonly summary!: ContributionSummary;
}
