

























































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import TextField from "@/form/TextField.vue";
import NumberField from "@/form/NumberField.vue";
import SubHeading from "@/components/SubHeading.vue";
import { EmployerRegistration } from "@/models/EmployerRegistration";
import {
	DateFormat,
	DefaultFund,
} from "@/store/modules/registrationofinterest/registrationOfInterestTypes";

const contributionSubmissionMethodText = {
	FILE_UPLOAD: "File Upload",
	MANUAL_GRID_ENTRY: "Manual Grid Entry",
	BOTH: "Both",
};

const dateFormatText = {
	[DateFormat["YYYY-MM-DD"]]: "YYYY-MM-DD",
	[DateFormat["YY-MMM-DD"]]: "YY-MMM-DD",
	[DateFormat["YY-MM-DD"]]: "YY-MM-DD",
	[DateFormat["YYYY-MMM-DD"]]: "YYYY-MMM-DD",
	[DateFormat["YYYY MMM DD"]]: "YYYY MMM DD",
	[DateFormat["YYYY/MM/DD"]]: "YYYY/MM/DD",
	[DateFormat["YY/MM/DD"]]: "YY/MM/DD",
	[DateFormat["MMM-DD-YY"]]: "MMM-DD-YY",
	[DateFormat["MM-DD-YYYY"]]: "MM-DD-YYYY",
	[DateFormat["MM-DD-YY"]]: "MM-DD-YY",
	[DateFormat["MMM-DD-YYYY"]]: "MMM-DD-YYYY",
	[DateFormat["MMM DD YYYY"]]: "MMM DD YYYY",
	[DateFormat["MM/DD/YYYY"]]: "MM/DD/YYYY",
	[DateFormat["MM/DD/YY"]]: "MM/DD/YY",
	[DateFormat["DD-MMM-YY"]]: "DD-MMM-YY",
	[DateFormat["DD-MM-YYYY"]]: "DD-MM-YYYY",
	[DateFormat["DD-MM-YY"]]: "DD-MM-YY",
	[DateFormat["DD-MMM-YYYY"]]: "DD-MMM-YYYY",
	[DateFormat["DD MMM YYYY"]]: "DD MMM YYYY",
	[DateFormat["DD/MM/YYYY"]]: "DD/MM/YYYY",
	[DateFormat["DD/MM/YY"]]: "DD/MM/YY",
};

@Component({
	components: {
		TextField,
		NumberField,
		SubHeading,
	},
})
export default class EmployerRegistrationMaintenance extends Vue {
	@Prop(Object)
	employerRegistration!: EmployerRegistration;

	get contributionSubmissionMethodText(): string {
		return contributionSubmissionMethodText[
			this.employerRegistration.contributionSubmissionMethod
		];
	}

	get dateFormatText(): string {
		return dateFormatText[this.employerRegistration.dateFormat];
	}

	get shouldShowDefaultFundOther(): boolean {
		return this.employerRegistration.defaultFund === DefaultFund.OTHER;
	}
}
