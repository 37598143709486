<template>
	<div class="p-3">
		<p>
			If your account is not listed, you will need to complete the Direct
			debit authority form and email it to the following address for
			processing:
		</p>
		<div class="gpo-address">
			<p>Email: superconnector@iress.com</p>
		</div>
		<a href="#" @click.prevent="downloadForm"
			>Download a direct debit authority form</a
		>
	</div>
</template>

<script>
import Vue from "vue";
import { Component } from "vue-property-decorator";
import axios from "@/utils/ApiUtils";
import { filesFormsDownload } from "@/constants/apiconstants";
import { toastErrorMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";
@Component({
	components: {},
})
export default class DirectDebitInfoText extends Vue {
	downloadForm() {
		const filename = "direct-debit-form.pdf";
		axios
			.get(filesFormsDownload(filename), {
				responseType: "blob",
			})
			.then((response) => {
				const blob = new Blob([response.data]);
				if (window.navigator && window.navigator.msSaveBlob) {
					window.navigator.msSaveBlob(blob, filename);
				} else {
					const url = window.URL.createObjectURL(blob);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", filename);
					link.click();
				}
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
	}
}
</script>

<style lang="scss">
.gpo-address {
	margin-bottom: px(16);
	p {
		margin-bottom: 0;
	}
}
</style>
