









































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Button from "@/form/Button.vue";

@Component({
	components: {
		Button,
	},
})
export default class SummaryListItem extends Vue {
	@Prop(String) name!: string;

	@Prop([String, Number]) value!: string | number;
	@Prop({ type: String, default: null }) navLink!: string;
}
