import Vue from "vue";
// Use only icons from light brand
import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faExternalLink,
	faAtlas,
	faBuilding,
	faCommentDots,
	faEnvelope,
	faEnvelopeOpen,
	faLandmark,
	faMailbox,
	faMobile,
	faPhoneRotary,
	faUser,
	faUsers,
	faCalendar,
	faPencil,
	faExclamation,
	faFilter,
	faMinus,
	faPlus,
	faUserCircle,
	faTrash,
	faSpinner,
	faSearch,
	faExclamationCircle,
	faExclamationTriangle,
	faUsersClass,
	faSackDollar,
	faFilePlus,
	faNewspaper,
	faFilesMedical,
	faChartLine,
	faTools,
	faLifeRing,
	faHome,
	faHandHoldingUsd,
	faClipboardList,
	faListAlt,
	faEngineWarning,
	faTasks,
	faClipboardCheck,
	faUserPlus,
	faUserEdit,
	faTimesOctagon,
	faUserAlt,
	faAddressCard,
	faBriefcase,
	faHandsUsd,
	faPiggyBank,
	faUserCog,
	faSitemap,
	faFileUpload,
	faInfoSquare,
	faMoneyBill,
	faRepeatAlt,
	faTimes,
	faCheck,
	faUserTag,
	faUniversalAccess,
	faMoneyCheckAlt,
	faBook,
	faVideo,
	faFileDownload,
	faPauseCircle,
	faExternalLinkSquare,
	faList,
	faChevronLeft,
	faKey,
	faEye,
	faEyeSlash,
	faBell,
	faUsdSquare,
	faMailBulk,
	faLineColumns,
	faCopy,
	faChartBar,
	faExpandAlt,
	faBellOn,
	faUserSlash,
	faFileInvoice,
	faBan,
	faListUl,
	faEnvelopeSquare,
	faFileCheck,
} from "@fortawesome/pro-light-svg-icons";
import {
	FontAwesomeIcon,
	FontAwesomeLayers,
	FontAwesomeLayersText,
} from "@fortawesome/vue-fontawesome";
// Import only the required icon. Do not import whole family.
library.add(
	faUserCircle,
	faMobile,
	faCommentDots,
	faEnvelopeOpen,
	faAtlas,
	faExternalLink,
	faPhoneRotary,
	faMailbox,
	faEnvelope,
	faUser,
	faCalendar,
	faPlus,
	faMinus,
	faFilter,
	faPencil,
	faUsers,
	faLandmark,
	faBuilding,
	faTrash,
	faSpinner,
	faExclamation,
	faSearch,
	faExclamationCircle,
	faExclamationTriangle,
	faUsersClass,
	faSackDollar,
	faFilePlus,
	faNewspaper,
	faFilesMedical,
	faChartLine,
	faTools,
	faLifeRing,
	faHandHoldingUsd,
	faHome,
	faClipboardList,
	faListAlt,
	faEngineWarning,
	faTasks,
	faClipboardCheck,
	faUserPlus,
	faUserEdit,
	faTimesOctagon,
	faUserAlt,
	faAddressCard,
	faBriefcase,
	faHandsUsd,
	faPiggyBank,
	faUserCog,
	faSitemap,
	faFileUpload,
	faInfoSquare,
	faMoneyBill,
	faRepeatAlt,
	faTimes,
	faCheck,
	faUserTag,
	faUniversalAccess,
	faMoneyCheckAlt,
	faBook,
	faVideo,
	faFileDownload,
	faPauseCircle,
	faExternalLinkSquare,
	faList,
	faChevronLeft,
	faKey,
	faEye,
	faEyeSlash,
	faBell,
	faUsdSquare,
	faListAlt,
	faMailBulk,
	faLineColumns,
	faCopy,
	faChartBar,
	faExpandAlt,
	faBellOn,
	faUserSlash,
	faBellOn,
	faFileInvoice,
	faBan,
	faListUl,
	faEnvelopeSquare,
	faFileCheck
);
Vue.component("font-awesome-layers", FontAwesomeLayers);
Vue.component("font-awesome-layers-text", FontAwesomeLayersText);
Vue.component("font-awesome-icon", FontAwesomeIcon);
