import { toastErrorMessage } from "@/plugins/toasts";
import axios from "@/utils/ApiUtils";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import { FinalBatchReviewUpdateRecord } from "@/models/FinalBatchReviewRecord";

export function getBatchUpdates(
	endpoint: string,
	batchId: number
): Promise<FinalBatchReviewUpdateRecord[]> {
	return axios
		.get<FinalBatchReviewUpdateRecord[]>(`${endpoint}/${batchId}`)
		.then((resp) => resp.data)
		.catch((error) => {
			toastErrorMessage(parseErrorMessage(error));
			return [];
		});
}
