






































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import SubHeading from "@/components/SubHeading.vue";
import SelectField from "@/form/SelectField.vue";
import { SelectOption } from "@/form/FieldOptions";
import ModalWithSaveAndCancel from "@/components/ModalWithSaveAndCancel.vue";
import Button from "@/form/Button.vue";
import { hasPermission } from "@/utils/PermissionUtils";

@Component({
	components: {
		SubHeading,
		SelectField,
		ModalWithSaveAndCancel,
		Button,
	},
})
export default class PaymentProfileSection extends Vue {
	@Prop({ required: true })
	private readonly paymentProfileOptions!: SelectOption[];

	@Prop({ type: String })
	private memberId!: string;
	@Prop(Boolean) readonly readonly!: boolean;

	private hasPermission(permission: string): boolean {
		return hasPermission(permission);
	}

	update(value: string) {
		this.$emit("change", value);
	}

	add() {
		this.$emit("addPaymentProfile");
	}
}
