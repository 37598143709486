



































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import {
	getEmployerHierarchy,
	getParentOrgNameForParentOrgId,
	getReportingCentreHierarchy,
} from "@/utils/PermissionUtils";
import Layout from "@/components/Layout.vue";
import { TSelectLevel } from "@/components/employerSelectorTypes";
import EmployerSelector from "@/components/EmployerSelector.vue";
import EditEmployer from "@/components/EditEmployer.vue";
import EditParentOrganisation from "@/components/EditParentOrganisation.vue";
import PageHeader from "@/components/PageHeader.vue";
import EditReportingCentre from "@/components/EditReportingCentre.vue";
import { MaintenanceEntity } from "@/pages/maintenanceTypes";
import { createNamespacedHelpers } from "vuex";
import { AppRoute, AppRouteNextFunction } from "@/router";
import ModalWithSaveAndCancel from "@/components/ModalWithSaveAndCancel.vue";

const { mapState, mapActions, mapMutations } =
	createNamespacedHelpers("persistent");

@Component({
	components: {
		PageHeader,
		EditParentOrganisation,
		EditEmployer,
		Layout,
		EmployerSelector,
		EditReportingCentre,
		ModalWithSaveAndCancel,
	},
	computed: mapState(["selectedEntities"]),
	methods: {
		...mapActions(["fetchEmployerHierarchy"]),
		...mapMutations(["setSelectedEntities"]),
	},
})
export default class CompanyMaintenance extends Vue {
	private pageContext: TSelectLevel = "ALL";
	private selectedEntityType: string | null = null;
	private errorMessage: string | null = null;
	private selectedEntityID: number | null = null;
	private hierarchy: MaintenanceEntity[] = [];
	private sponsor: string | null = null;

	/**
	 * Indiate whether form is editing and contains changes
	 */
	private isFormDirty = false;

	private showCancelModal = false;

	/**
	 * Type the mapped persistent.selectedEntities getter.
	 * It is a computed property. Do not mutate it.
	 */
	selectedEntities!: string[];

	/**
	 * Type the mapped persistent.setSelectedEntities mutation.
	 */
	setSelectedEntities!: (selectedEntities: string[]) => void;
	/**
	 * Type the mapped persistent.fetchEmployerHierarchy action.
	 */
	fetchEmployerHierarchy!: () => Promise<void>;

	get selectedEntity(): string | null {
		return this.selectedEntities.length === 1
			? this.selectedEntities[0]
			: null;
	}

	private onChangeEntity(entity: MaintenanceEntity): void {
		this.setSelectedEntities([entity.id]);
	}

	private async onSave(): Promise<void> {
		await this.fetchEmployerHierarchy();
		this.updateHierarchy();
		this.isFormDirty = false;
	}

	private onCancel(): void {
		this.setSelectedEntities([]);
		this.isFormDirty = false;
	}

	private updateHierarchy(): void {
		if (this.selectedEntity) {
			switch (this.selectedEntityType) {
				case "R":
					this.hierarchy = getReportingCentreHierarchy(
						this.selectedEntity
					);
					break;
				case "E":
					this.hierarchy = getEmployerHierarchy(this.selectedEntity);
					break;
				case "P":
					{
						const selectedParent: MaintenanceEntity | null =
							getParentOrgNameForParentOrgId(this.selectedEntity);
						this.hierarchy = selectedParent ? [selectedParent] : [];
					}
					break;
			}
		}
	}

	@Watch("selectedEntity", { immediate: true })
	private getSelectedEntity(): void {
		const selectedEntity = this.selectedEntity;
		if (selectedEntity !== null) {
			this.selectedEntityType = selectedEntity.charAt(0);
			this.selectedEntityID = Number.parseInt(
				selectedEntity.substr(2),
				10
			);

			this.updateHierarchy();
		} else {
			this.selectedEntityType = null;
			this.selectedEntityID = null;
			this.errorMessage =
				"Please select a Parent organisation, an Employer or a Reporting centre";
		}
		this.sponsor = null;
	}

	mounted() {
		const selectEntityParam = this.$route.query.selectEntity as string;
		if (selectEntityParam && selectEntityParam !== "") {
			this.setSelectedEntities([selectEntityParam]);
		}

		this.getSelectedEntity();
	}

	clearErrorMessage() {
		this.errorMessage = null;
	}

	discardChanges() {
		this.showCancelModal = false;
		this.isFormDirty = false;
		this.onCancel();
	}

	beforeRouteLeave(
		to: AppRoute,
		from: AppRoute,
		next: AppRouteNextFunction<CompanyMaintenance>
	) {
		if (this.isFormDirty) {
			this.showCancelModal = true;
			next(false);
		} else {
			next();
		}
	}
}
