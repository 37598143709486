



































import Vue, { CreateElement, RenderContext, VNode } from "vue";
import { forEach } from "lodash-es";
import { Component, Prop, Watch } from "vue-property-decorator";
import { CommonField } from "@/form/CommonField";

const VNodeRenderer = {
	functional: true,
	render: (h: CreateElement, ctx: RenderContext) => {
		const { vNode, ...extraPropsForVNode } = ctx.props;
		const vNodeOptions = vNode.componentOptions;

		vNodeOptions.propsData = {
			...vNodeOptions.propsData,
			...extraPropsForVNode,
		};

		if (ctx.listeners) {
			forEach(ctx.listeners, (subformListeners, key) => {
				// NOTE(Dhiraj, York): 2021-01-12
				// Do not add vue specific listener handler functions like "invoker"
				// to the list of listeners on a particular event as it can lead to
				// infinite calls and cause stack overflow
				let existingListeners = vNodeOptions.listeners ?? {};
				existingListeners = existingListeners[key] ?? [];
				let existingListenersForKey: Function | Function[] =
					existingListeners.fns ?? existingListeners;
				if (!Array.isArray(existingListenersForKey)) {
					existingListenersForKey = [existingListenersForKey];
				}

				const listenersInArray = Array.isArray(subformListeners)
					? subformListeners
					: [subformListeners];

				vNodeOptions.listeners[key] = [
					...new Set(
						listenersInArray.concat(existingListenersForKey)
					),
				];
			});
		}
		return vNode;
	},
};

@Component({
	components: {
		VNodeRenderer,
	},
})
export default class Subform extends Vue {
	// You could, but not required to, provide a better type for the values prop.
	@Prop(Object)
	readonly values!: object | undefined;

	@Prop(String)
	readonly highlightedFieldName!: string | undefined;

	protected fieldVNodes: Record<string, VNode> = {};

	// Add all props that when updated should trigger a re-rendering of fields as watchers here.
	@Watch("values", { immediate: true, deep: true })
	@Watch("highlightedFieldName")
	private refreshChildVNodes() {
		if (this.$slots.default) {
			this.$slots.default.forEach((vNode) => {
				if (vNode.componentOptions?.propsData) {
					this.fieldVNodes[
						(vNode.componentOptions?.propsData as CommonField).name
					] = vNode;
				}
			});
		}
	}
}
