




































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import axios from "@/utils/ApiUtils";
import {
	fundMessageCategories,
	fundMessageConversation,
	fundMessageDone,
} from "@/constants/apiconstants";
import { toastErrorMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import Overlay from "@/components/Overlay.vue";
import { Alert, NotificationCategoryMapper, Status } from "@/models/AlertRow";
import Form from "@/form/Form.vue";
import Button from "@/form/Button.vue";
import FieldGroup from "@/form/FieldGroup.vue";
import GridLoadingOverlay from "@/grid/GridLoadingOverlay.vue";
import TextField from "@/form/TextField.vue";
import AlertInfo from "@/components/AlertInfo.vue";
import {
	ConversationDTO,
	FundMessageCategory,
} from "@/pages/FundMessagePage.vue";
import { SelectOption } from "@/form/FieldOptions";
import SelectField from "@/form/SelectField.vue";
import TextareaField from "@/form/TextareaField.vue";

@Component({
	components: {
		Overlay,
		Form,
		Button,
		FieldGroup,
		TextField,
		GridLoadingOverlay,
		AlertInfo,
		SelectField,
		TextareaField,
	},
})
export default class FundMessageDetail extends Vue {
	@Prop({ type: String, default: "" })
	readonly target!: string;

	@Prop({ type: String, default: "topright" })
	readonly placement!: string;

	@Prop()
	readonly rowData!: Alert;

	private conversationDTO: ConversationDTO | null = null;
	private loading = false;
	private fundMessageCategoryOptions: SelectOption[] = [];
	private assigneeOptions: SelectOption[] = [
		{
			value: "F",
			label: "Fund",
		},
		{
			value: "E",
			label: "Employer",
		},
	];

	mounted() {
		this.loading = true;

		axios
			.get<FundMessageCategory[]>(fundMessageCategories())
			.then((resp) => {
				this.fundMessageCategoryOptions = resp.data.map((fmc) => ({
					value: fmc.code,
					label: fmc.category,
				}));
			});

		axios
			.get<ConversationDTO>(
				fundMessageConversation() + "?conversationId=" + this.rowData.id
			)
			.then((resp) => {
				this.conversationDTO = resp.data;
				this.loading = false;
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
				this.loading = false;
			});
	}

	private onMarkAsDone(): void {
		axios
			.post(
				fundMessageDone() +
					"?done=true&conversationId=" +
					this.rowData.id
			)
			.then((resp) => {
				this.$emit("done");
			})
			.catch((err) => {
				toastErrorMessage(parseErrorMessage(err));
			});
	}

	private onMarkAsPending(): void {
		axios
			.post(
				fundMessageDone() +
					"?done=false&conversationId=" +
					this.rowData.id
			)
			.then((resp) => {
				this.$emit("pending");
			})
			.catch((err) => {
				toastErrorMessage(parseErrorMessage(err));
			});
	}

	private onClosePopover(): void {
		this.$emit("close");
	}

	private get title(): string {
		return `${NotificationCategoryMapper(this.rowData.category)} - ${
			this.rowData.id
		}`;
	}

	private get isStatusDone(): boolean {
		return this.rowData.status === Status.D;
	}
}
