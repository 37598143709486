







import Vue, {
	CreateElement,
	VNode,
	VNodeComponentOptions,
	VNodeData,
} from "vue";
import { Component, Prop } from "vue-property-decorator";

import { CommonField } from "@/form/CommonField";
import SelectField from "@/form/SelectField.vue";

@Component({})
export default class Form extends Vue {
	@Prop({ type: Boolean, default: false }) readonly!: boolean;

	@Prop({ type: Boolean, default: false }) disabled!: boolean;

	@Prop([Object]) value!: { [name: string]: any } | undefined | null;

	/**
	 * fieldMap maps a field name to field properties.
	 * For example, if a SelectField exists within a form, it will inherit
	 * its properties from this map, such as "rules" or "options". But only
	 * if those properties have *not* been set on the component already.
	 */
	@Prop([Object]) fieldMap!:
		| { [name: string]: CommonField }
		| undefined
		| null;

	/**
	 * fieldErrorMap maps a field name to a list of error messages.
	 *
	 * If this property is set, the "errors" property on field components like
	 * TextField will be updated (if the property is not explictly set on the component already)
	 */
	@Prop([Object]) fieldErrorMap!:
		| { [name: string]: string[] }
		| undefined
		| null;

	duplicateFieldNameCheck: { [name: string]: boolean } = {};

	/**
	 * fieldErrorsAppliedCheck will flag fields as having their errors
	 * applied. This allows us to detect if a field is missing on the form
	 * when we get an error message back from the server.
	 */
	//fieldErrorsAppliedCheck: { [name: string]: boolean } = {};

	/**
	 * inheritableFields is a list of fields that can be inherited from backend
	 * data.
	 */
	private static inheritableFields: (
		| keyof CommonField
		| keyof SelectField
	)[] = [
		// The following properties are special cases:
		// "readonly",
		// "disabled",
		// CommonField
		"label",
		"rules",
		// SelectField
		"options",
		"allowEmptyValue",
	];

	/**
	 * onInput will update the "value" of this object with the field name.
	 */
	private onInput(name: string, newValue: any): void {
		if (
			this.value !== undefined &&
			this.value !== null &&
			this.value[name] === newValue
		) {
			return;
		}

		let newData: { [propName: string]: any };
		if (this.value !== undefined && this.value !== null) {
			// NOTE(Jae): 2020-06-02
			// For now, we be conservative with allocations/perf.
			// and mutate the existing JS obj because each keypress
			// would in-theory generate a new JSObj / Hashmap. Which cant
			// be a good idea.
			//const newData = {...this.value}
			newData = this.value;
		} else {
			newData = {};
		}
		newData[name] = newValue;
	}

	/**
	 * Add extra props to the props object.
	 *
	 * Subclasses could use this method to perform props injection.
	 * Simple overriding would not work here because the Component
	 * decorator turns these classes into VueComponent and overridden
	 * super methods are lost. See: https://github.com/vuejs/vue-class-component/issues/93
	 */
	protected applyExtraPropsToField(props: {
		[propName: string]: any | undefined;
	}) {
		return;
	}

	/**
	 * Add extra listeners to the listeners object.
	 *
	 * Subclasses could use this method to perform listener injection.
	 * Simple overriding would not work here because the Component
	 * decorator turns these classes into VueComponent and overridden
	 * super methods are lost. See: https://github.com/vuejs/vue-class-component/issues/93
	 */
	protected applyExtraListenersToField(
		componentOptions: VNodeComponentOptions
	) {
		return;
	}

	/**
	 * Add extra data to the VNode data object.
	 *
	 * Subclasses could use this method to perform data injection.
	 * Simple overriding would not work here because the Component
	 * decorator turns these classes into VueComponent and overridden
	 * super methods are lost. See: https://github.com/vuejs/vue-class-component/issues/93
	 */
	protected applyExtraVNodeDataToField(
		data: VNodeData,
		props: { [propName: string]: any | undefined }
	) {
		return;
	}

	/**
	 * Apply props to the field props object.
	 */
	private applyPropsToField(
		props: {
			[propName: string]: any | undefined;
		},
		propsMeta: any
	) {
		if (props.readonly === undefined && this.readonly === true) {
			// Inherit state from this <Form> component
			props.readonly = this.readonly;
		}
		if (props.disabled === undefined && this.disabled === true) {
			// Inherit state from this <Form> component
			props.disabled = this.disabled;
		}
		if (this.fieldMap !== undefined && this.fieldMap !== null) {
			// If certain properties aren't set on the form field element
			// directly in *.vue, like "label" or "rules", use values provided by
			// the backend.
			const fieldProps: { [propName: string]: any } =
				this.fieldMap[props.name];
			if (fieldProps !== undefined) {
				if (propsMeta.fieldMap !== undefined) {
					// If component implements fieldMap directly, like "AutoField"
					// just apply to that instead.
					props.fieldMap = fieldProps;
				} else {
					// Handle case for all other field types such as TextField, NumberField, etc.
					for (const inheritableField of Form.inheritableFields) {
						if (
							!Object.prototype.hasOwnProperty.call(
								fieldProps,
								inheritableField
							)
						) {
							continue;
						}
						const newValue = fieldProps[inheritableField];
						if (newValue === undefined) {
							// If value isn't set, skip it.
							continue;
						}
						if (propsMeta[inheritableField] === undefined) {
							// Don't try to apply this prop if the Vue component
							// doesn't have that prop at all.
							throw new Error(
								'Cannot apply "' +
									inheritableField +
									'" property to form field "' +
									props.name +
									"\" as this field component doesn't implement that property."
							);
						}
						// Only apply override if our current property value is unset (undefined)
						const propValue = props[inheritableField];
						if (propValue === undefined) {
							props[inheritableField] = newValue;
						}
					}
				}
			}
		}
		if (this.fieldErrorMap !== undefined && this.fieldErrorMap !== null) {
			const fieldErrorList = this.fieldErrorMap[props.name];
			if (fieldErrorList !== undefined && fieldErrorList !== null) {
				if (props.errors === undefined) {
					props.errors = fieldErrorList;
				}
				// NOTE(Jae): 2020-08-20
				// This was here for debug purposes.
				// Even just marking this object with boolean trues
				// causes render timeout problems. Maybe Vue turns this object into an expensive
				// observer object or something under the hood?
				//this.fieldErrorsAppliedCheck[props.name] = true;
			}
		}
		if (this.value !== null && this.value !== undefined) {
			props.value = this.value[props.name];

			// Compute the required_if rule by ourselves.
			// That saves us from passing down values of target fields, as although vee-validate could utilise the rule,
			// we still need to know whether a field is required to display an required asterisk.
			if (props.rules || props.fieldMap?.rules) {
				const rules = props.rules || props.fieldMap.rules;
				const requiredIfRegex = /required_if:(\w+),(\w+)/;
				const regexExecArray = requiredIfRegex.exec(rules);
				if (regexExecArray !== null) {
					const [, targetFieldName, targetValue] = regexExecArray;
					const stringToReplaceRequiredIfRule =
						this.value[targetFieldName] === targetValue
							? "required"
							: "";

					props.rules = rules.replace(
						requiredIfRegex,
						stringToReplaceRequiredIfRule
					);
				}
			}
		}

		this.applyExtraPropsToField(props);
	}

	/**
	 * Add listeners to the listeners object.
	 */
	private applyListenersToField(componentOptions: VNodeComponentOptions) {
		const props = componentOptions.propsData as {
			[propName: string]: any | undefined;
		};
		const listeners: { [eventName: string]: any | undefined } =
			{ ...componentOptions.listeners } || {};
		if (
			props.value !== undefined &&
			props.value !== null &&
			props.readonly &&
			props.disabled
		) {
			// If a value is explicitly set on a disabled/readonly form field, then don't auto-apply
			// an input listener.
			return;
		}
		if (listeners.input === undefined) {
			// If user-code already has "v-model" set or an "input" listener
			// then do not apply our listener.
			// (FYI: "v-model" is syntactic sugar that leverages an "input" listener.)

			if (this.duplicateFieldNameCheck[props.name] !== undefined) {
				throw new Error(
					"Duplicate name detected in form: " +
						props.name +
						'\nThis is not allowed unless you explicitly want to handle the duplicate named field by explicitly setting a "value" or "v-model".'
				);
			}
			this.duplicateFieldNameCheck[props.name] = true;

			listeners.input = (newValue: any) => {
				if (
					componentOptions !== undefined &&
					componentOptions.propsData !== undefined &&
					(componentOptions.propsData as any).name
				) {
					// NOTE(Jae): 2020-06-02
					// We access propsData via "componentOptions" here as Vue may throw away
					// the entire propsData object we store in "const props" above and replace it
					// with a new one. I haven't confirmed this is the case, but I'm just going
					// to err. on the side of caution.
					this.onInput(
						(componentOptions.propsData as any).name,
						newValue
					);
				}
			};
		}

		componentOptions.listeners = listeners;
		this.applyExtraListenersToField(componentOptions);
	}

	private applyVNodeDataToField(
		data: VNodeData,
		props: { [propName: string]: any | undefined }
	) {
		this.applyExtraVNodeDataToField(data, props);
	}

	private maybeApplyValueAndInputCallbackToEachField(
		elements: VNode[]
	): void {
		for (const el of elements) {
			if (el.children && el.children.length > 0) {
				// Handle simple children rendering underneath items like regular <div>'s
				this.maybeApplyValueAndInputCallbackToEachField(el.children);
			}
			if (
				el.componentOptions === undefined ||
				el.componentOptions.Ctor === undefined
			) {
				// Skip element if its not a component
				continue;
			}
			if (
				el.componentOptions &&
				el.componentOptions.children !== undefined &&
				el.componentOptions.children.length > 0
			) {
				// Handle case for updating children underneath a component, like a <FieldGroup>
				this.maybeApplyValueAndInputCallbackToEachField(
					el.componentOptions.children
				);
			}
			const options = (el.componentOptions.Ctor as any).options;
			if (options === undefined || options.props === undefined) {
				// Skip element if its not a component
				continue;
			}
			const propsMeta = options.props;
			if (
				propsMeta.label === undefined ||
				propsMeta.name === undefined ||
				propsMeta.rules === undefined ||
				//Both value (CommonField) and fieldValue (FundFieldMappingField) are undefined
				(propsMeta.value === undefined &&
					propsMeta.fieldValue === undefined) ||
				propsMeta.errors === undefined
			) {
				// Skip this component if it doesn't satisfy the CommonField
				// interface.
				continue;
			}
			const props: { [propName: string]: any | undefined } | undefined =
				el.componentOptions.propsData;
			if (
				props === undefined ||
				props.name === undefined ||
				props.name === null ||
				props.name === ""
			) {
				// Ignore if no name on field. The field itself should handle
				// having no "name" prop set and display an error.
				continue;
			}

			// These methods can assume the params are valid for a field.
			this.applyPropsToField(props, propsMeta);
			this.applyListenersToField(el.componentOptions);
			this.applyVNodeDataToField(el.data as VNodeData, props);
		}
	}

	render(createElement: CreateElement, context: any) {
		if (!this.$slots || !this.$slots.default) {
			// If nothing is inside this <Form> element, we render nothing.
			// We do this so components like <Popup> can decide to not render themselves
			// if they have no child elements.
			return null;
		}

		this.duplicateFieldNameCheck = {};
		//this.fieldErrorsAppliedCheck = {};
		this.maybeApplyValueAndInputCallbackToEachField(this.$slots.default);

		// NOTE(Jae): 2020-08-20
		// This logic causes the <Form> component to throw a rendering error.
		// Not sure why this is causing the timeout to be exceeded as it seems like
		// low-cost logic.
		/*if (process.env.NODE_ENV === "development") {
			// NOTE(Jae): 2020-08-20
			// Store values for use later in closure and run in a setTimeout function.
			// This is to stop <Form> from taking too long and having its rendering timed
			// out during development.
			const fieldErrorMap = this.fieldErrorMap;
			const fieldErrorsAppliedCheck = this.fieldErrorsAppliedCheck;
			setTimeout(() => {
				if (
					fieldErrorMap !== null &&
					fieldErrorMap !== undefined &&
					Object.keys(fieldErrorsAppliedCheck).length !==
					Object.keys(fieldErrorMap).length
				) {
					console.error(
						"Missing fields on form and can't apply error messages.",
						"errors received:",
						fieldErrorMap,
						"fields found:",
						fieldErrorsAppliedCheck
					);
				}
			}, 100);
		}*/

		return createElement(
			"form",
			{
				class: "Form__root",
			},
			this.$slots.default
		);
	}
}
