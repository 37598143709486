














import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import BellOnIcon from "@/components/BellOnIcon.vue";
import { INSIGHT_ONE_LINER_TYPES } from "@/constants/constants";

interface InsightType {
	message: string;
	alertLevel: number;
	category: string;
}

@Component({ components: { BellOnIcon } })
export default class InsightOneLiner extends Vue {
	@Prop(Array)
	readonly insights!: InsightType[];
	@Prop()
	readonly columnSize!: number;

	readonly style = [
		"text-danger fa-lg",
		"text-warning fa-lg",
		"text-success fa-lg",
	];

	getLabel(category: string) {
		return INSIGHT_ONE_LINER_TYPES.find(
			(insight) => insight.value === category
		)?.label;
	}
}
