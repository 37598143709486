import { GetterTree } from "vuex";
import { RootState } from "@/store/root";

export const getters: GetterTree<any, RootState> = {
	oneLiners(state) {
		return state.oneLiners;
	},
	batchTotalData(state) {
		return state.batchTotalData;
	},
	fundTotalData(state) {
		return state.fundTotalData;
	},
	getAll(state) {
		return state;
	},
};
