interface FileFormat {
	id: number;
	fileFormatName: string;
	numRowSkip: number;
	rowIdentifier: number;
	numHeaders: number;
	fileFormatType: string | null;
	fileCategoryCode: string | null;
}

interface FileFormatSaffColumnMapping {
	id: number;
	columnName: string;
	fileFormatId: number;
	type: string;
	validateOnLoad: boolean;
	saffColumnId: number | null;
	saffColumnIdName: string;
	fieldNameMapping: string | null;
	startOffset: number;
	endOffset: number;
	headerName?: string;
	subHeaderName?: string;
}

interface FileFormatSaffColumnHeaderMapping {
	id: number;
	columnName: string;
	fileFormatId: number;
	type: string;
	validateOnLoad: boolean;
	saffColumnId: number | null;
	saffColumnIdName: string;
	startOffset: number;
	endOffset: number;
	fieldNameMapping: string | null;
}

interface FileFormatResponse {
	fileFormat: FileFormat;
	mappings: FileFormatSaffColumnMapping[];
	headerMappings: FileFormatSaffColumnHeaderMapping[];
}

const fileFormatConfig = [
	{
		label: "CSV",
		value: "CSV",
	},
	{
		label: "Fixed index",
		value: "FI",
	},
];

const fileCategoryConfig = [
	{
		label: "Contribution",
		value: "CONT",
	},
	{
		label: "Supplementary File",
		value: "SCF",
	},
	{
		label: "Salary Update",
		value: "SU",
	},
	{
		label: "Backdated Salary Update",
		value: "BSU",
	},
	{
		label: "Employee",
		value: "EMP",
	},
	{
		label: "LWOP",
		value: "LWOP",
	},
	{
		label: "TFN Update",
		value: "TFNU",
	},
	{
		label: "Address Update",
		value: "ADDU",
	},
	{
		label: "Payroll ID Update",
		value: "PIDU",
	},
	{
		label: "New Member Update",
		value: "NMUP",
	},
	{
		label: "Purchased Leave",
		value: "PURL",
	},
	{
		label: "Service Fraction",
		value: "SERF",
	},
	{
		label: "Contribution Rates",
		value: "CRAT",
	},
];

const toFindDuplicates = (arry: any[]) =>
	arry.filter((item, index) => arry.indexOf(item) !== index);

export {
	FileFormat,
	FileFormatSaffColumnMapping,
	FileFormatResponse,
	fileFormatConfig,
	fileCategoryConfig,
	FileFormatSaffColumnHeaderMapping,
	toFindDuplicates,
};
