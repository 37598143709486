
















































































































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { ValidationProvider } from "vee-validate";

import { computeLabel } from "./FormUtils";

@Component({
	components: {
		ValidationProvider,
	},
})
export default class FieldHolder extends Vue {
	@Prop([String]) readonly id!: string;

	@Prop([String]) readonly name!: string;

	@Prop([String]) readonly label!: string;

	@Prop([String, Object]) readonly rules!: string | object;

	@Prop([Boolean]) readonly readonly!: boolean;

	@Prop([String, Number, Array, Boolean, Object]) readonly value!:
		| string
		| number
		| boolean
		| string[]
		| number[]
		| object;

	@Prop({ type: Array, default: () => [] }) readonly errors!: string[];

	/**
	 * Whether errors are shown externally.
	 */
	@Prop(Boolean) readonly errorsShownExternally!: boolean;

	@Prop([String]) readonly placeholder!: string;

	@Prop({ type: Boolean, default: false }) readonly isLabelLeft!: boolean;

	private getAllErrors(
		errors: string[],
		validationErrors: string[]
	): string[] {
		const r: string[] = [];
		if (errors) {
			if (typeof errors === "string") {
				// NOTE(Jae): 2020-06-12
				// Handle invalid data being passed into this component and highlight
				// to the developer there is a problem. Because we get no type-checking
				// when passing properties via templates and it's possible a user can accidentally pass in a string
				// rather than the expected array of strings.
				if (process.env.NODE_ENV === "development") {
					r.push(
						"Development Error: Expected array of strings. Not string. Error value given: " +
							errors
					);
				} else {
					r.push(errors);
				}
			} else {
				r.push(...errors);
			}
		}
		if (validationErrors && validationErrors.length > 0) {
			r.push(...validationErrors);
		}
		return r;
	}

	private hasErrors(validationErrors: string[]) {
		return this.errors.length > 0 || validationErrors.length > 0;
	}

	/**
	 * isNotEmpty will check if various value types are empty or not
	 */
	private isNotEmpty(val: string | number | string[] | number[]): boolean {
		if (typeof val === "string" && val !== "") {
			return true;
		}
		if (typeof val === "number" && val !== 0) {
			return true;
		}
		if (Array.isArray(val) && val.length > 0) {
			return true;
		}
		return false;
	}

	get computedLabel(): string {
		return computeLabel(this.label, this.name);
	}

	get isRequired(): boolean {
		if (!this.rules) {
			return false;
		}
		let ruleNames = [];
		if (typeof this.rules === "string") {
			ruleNames = this.rules.split("|");
		} else {
			ruleNames = Object.keys(this.rules);
		}
		for (const rule of ruleNames) {
			if (rule === "required" || rule === "dateRequired") {
				if (
					typeof this.rules !== "object" ||
					(this.rules as any)[rule]
				) {
					// rule could be false
					return true;
				}
			}
		}
		return false;
	}

	/**
	 * quotedComputedLabel is provided to the validator. Opted to quote the
	 * pretty name so that error messages can be scanned by human eyes easier.
	 */
	get quotedComputedLabel(): string {
		const r = this.computedLabel;
		if (!r) {
			return "";
		}
		return '"' + r + '"';
	}

	private validateAndDisplayError(error: string): string {
		if (process.env.NODE_ENV === "development" && !error) {
			// If bad data gets into the error handling code, we tell the dev. but only in development mode
			return "Development Mode Error. Unexpected dataset. An empty error message was given in the list of errors. Do not add empty/blank data to the error list.";
		}
		const newError = error.replace("{{name}}", this.computedLabel);
		return newError;
	}
}
