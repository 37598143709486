














































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import FieldHolder from "@/form/FieldHolder.vue";
import ErrorList from "@/components/ErrorList.vue";
import { CommonField } from "@/form/CommonField";
import Multiselect from "vue-multiselect";

@Component({
	components: {
		FieldHolder,
		ErrorList,
		Multiselect,
	},
})

//Third Party Lib https://vue-multiselect.js.org/
export default class MultiSelect extends Vue implements CommonField {
	private static uniqueId = 0;
	/**
	 * id must be globally unique in a HTML document, we guarantee this by making each render
	 * of this component have unique ID number appended to the end to avoid clashes.
	 *
	 * We need ids mostly to connect <label> and <input> elements together for accessibility.
	 */
	readonly id = "MultiSelectField__" + ++MultiSelect.uniqueId;

	readonly $refs!: {
		multiselectRef: Multiselect;
	};

	@Prop([String]) readonly name!: string;

	@Prop([String]) readonly label!: string;
	@Prop({ type: String, default: "Start Typing to Search" })
	readonly placeholder!: string;
	@Prop({ type: String, default: "" })
	readonly deselectLabel!: string;

	@Prop({ type: [Object, Array] }) readonly value!: object | object[];

	@Prop([String]) readonly rules!: string;

	@Prop(Boolean) readonly readonly!: boolean;

	@Prop(Boolean) readonly disabled!: boolean;

	/**
	 * Add additional custom error messages to this field.
	 */
	@Prop([Array]) readonly errors!: string[];

	@Prop(Boolean) readonly showErrors!: boolean;

	//tracks the object by this property
	@Prop(String) readonly trackBy!: string;
	@Prop(Boolean) readonly isLoading!: boolean;

	//Decide whether to filter the results internally based on search query.
	// Useful for async filtering, where we search through more complex data.
	@Prop({ type: Boolean, default: false }) readonly internalSearch!: boolean;

	//Clear the search input after select(). Use only when multiple is true.
	@Prop({ type: Boolean, default: false }) readonly clearOnSelect!: boolean;

	//Allows to remove all selected values. Otherwise one must be left selected.
	@Prop({ type: Boolean, default: false }) readonly allowEmpty!: boolean;

	//Selects the first option if initial value is empty
	@Prop({ type: Boolean, default: false }) readonly preselectFirst!: boolean;

	@Prop({ type: Number, default: 300 }) readonly optionsLimit!: number;

	@Prop([Array]) readonly options!: [];

	@Prop(Function) customLabel!: Function;

	/**
	 * Whether errors are shown externally.
	 */
	@Prop(Boolean) readonly errorsShownExternally!: boolean;

	@Prop({ type: Boolean, default: false }) readonly multiple!: boolean;

	@Prop({ type: Boolean, default: true }) readonly searchable!: boolean;

	private searchQueryChanged(query: any) {
		this.$emit("search", query);
	}

	private inputChanged(value: any) {
		this.$emit("input", value);
		if (!value || value.length === 0) {
			this.$refs.multiselectRef.$emit("blur");
		}
	}

	private onSelectClose() {
		this.$refs.multiselectRef.$emit("blur");
	}
}
