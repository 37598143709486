











































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import Button from "@/form/Button.vue";
import CompanyDetails from "@/components/CompanyDetails.vue";
import axios from "@/utils/ApiUtils";
import { saveEmployer, searchEmployer } from "@/constants/apiconstants";
import {
	CompanyDetail,
	RCStrategyTypes,
	UpdateFieldValue,
} from "@/pages/maintenanceTypes";
import {
	toastErrorMessage,
	toastInfoMessage,
	toastSuccessMessage,
} from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import AuthContact from "@/components/AuthContact.vue";
import { VForm } from "@/@typings/type-vee-validate";
import CompanyConfigDetails from "@/components/CompanyConfigDetails.vue";
import { isEqual } from "lodash-es";
import ModalWithSaveAndCancel from "@/components/ModalWithSaveAndCancel.vue";
import Modal from "@/components/Modal.vue";
import { hasPermission } from "@/utils/PermissionUtils";

@Component({
	components: {
		CompanyConfigDetails,
		AuthContact,
		CompanyDetails,
		Button,
		ModalWithSaveAndCancel,
		Modal,
	},
})
export default class EditEmployer extends Vue {
	private storedCompanyDetail: CompanyDetail | null = null;
	private companyDetail: CompanyDetail | null = null;
	private displayAuthContact = false;
	private reportingCentreCount = 0;
	private showCancelModal = false;
	private showConfirmationModal = false;
	private confirmationModalMessage: string | null = null;

	/**
	 * Indiate whether form is editing and contains changes
	 */
	private isFormDirty = false;

	@Prop({ type: Number, required: true, default: null })
	readonly employerId!: number | null;

	async saveEmployer() {
		if (!this.companyDetail) {
			return;
		}

		if (
			this.storedCompanyDetail &&
			isEqual(this.storedCompanyDetail, this.companyDetail)
		) {
			toastInfoMessage(
				"No changes have been made to this record, save not required"
			);
			return;
		}

		if (
			this.companyDetail.reportingCentreIdentification ===
				RCStrategyTypes.ABN &&
			this.reportingCentreCount > 1
		) {
			this.confirmationModalMessage =
				"You have more than one Reporting Centre attached to your Employer ABN, so you cannot change your Reporting Centre identification strategy to Employer ABN only.  It must remain as either Employer ABN  + Location ID or Employer ABN + Payer BSB + Payer Number";
			this.showConfirmationModal = true;
			return;
		}

		if (await (this.$refs.employerForm as VForm).validate()) {
			axios
				.put(saveEmployer(), this.companyDetail)
				.then((response) => {
					this.showMessageForStrategyChange();
					toastSuccessMessage("Employer saved successfully");
					if (this.companyDetail) {
						this.storedCompanyDetail = { ...this.companyDetail };
					}
					this.isFormDirty = false;
					this.$emit("save");
					if (this.employerId) {
						this.fetchCompanyDetail(this.employerId);
					}
				})
				.catch((error) => {
					toastErrorMessage(parseErrorMessage(error));
				});
		}
	}

	private showMessageForStrategyChange() {
		if (
			this.storedCompanyDetail &&
			this.companyDetail &&
			this.storedCompanyDetail.reportingCentreIdentification !==
				this.companyDetail.reportingCentreIdentification
		) {
			if (
				this.companyDetail.reportingCentreIdentification ===
				RCStrategyTypes.ABN_BK
			) {
				this.confirmationModalMessage =
					"You have updated your Reporting centre identification strategy. You must now update each Reporting centre with new Payer BSB and Account number values. If you fail to update all of your Reporting centres, you will not be able to successfully load files.";
				this.showConfirmationModal = true;
			} else if (
				this.companyDetail.reportingCentreIdentification ===
				RCStrategyTypes.ABN_LC
			) {
				this.confirmationModalMessage =
					"You have updated your Reporting centre identification strategy. You must now update each Reporting centre with new Reporting centre identification values. If you fail to update all of your Reporting centres, you will not be able to successfully load files.";
				this.showConfirmationModal = true;
			}
		}
	}

	private discardChanges() {
		this.isFormDirty = false;
		this.$emit("cancel");
	}

	private closeModal() {
		this.showCancelModal = false;
	}

	private closeConfirmationModal() {
		this.confirmationModalMessage = null;
		this.showConfirmationModal = false;
	}

	get isCompanyDetailChanged() {
		return (
			this.storedCompanyDetail !== null &&
			!isEqual(this.companyDetail, this.storedCompanyDetail)
		);
	}

	private cancel() {
		if (this.isCompanyDetailChanged) {
			this.showCancelModal = true;
		} else {
			this.discardChanges();
		}
	}

	@Watch("employerId", { immediate: true })
	fetchCompanyDetailsOnChange(value: number | null) {
		if (value !== null) {
			this.fetchCompanyDetail(value);
		} else {
			this.companyDetail = null;
		}
	}

	get isCanAddReportingCentre() {
		return (
			this.companyDetail?.paymentProfileExists ||
			this.companyDetail?.canUserAddPaymentProfile
		);
	}

	addNewReportingCentre() {
		if (
			this.employerId &&
			this.storedCompanyDetail &&
			this.storedCompanyDetail.reportingCentreIdentification
		) {
			if (
				this.storedCompanyDetail.reportingCentreIdentification ===
					RCStrategyTypes.ABN &&
				this.reportingCentreCount > 0
			) {
				this.confirmationModalMessage =
					"If you wish to add more Reporting Centres, you must change your Reporting Centre identification strategy to either Employer ABN  + Location ID or Employer ABN + Payer BSB + Payer Number before adding additional Reporting Centres";
				this.showConfirmationModal = true;
				return;
			}

			this.$router.push({
				name: "Add Reporting Centre",
				params: {
					employerId: this.employerId.toString(),
					rcStrategy:
						this.storedCompanyDetail.reportingCentreIdentification,
					abn: this.storedCompanyDetail?.abn || "",
				},
			});
		}
	}

	updateCompanyDetail(e: UpdateFieldValue) {
		if (this.companyDetail) {
			Vue.set(this.companyDetail, e.field, e.value);

			if (!this.isFormDirty && this.isCompanyDetailChanged) {
				this.isFormDirty = true;
				this.$emit("form-changed");
			}
		}
	}

	private async fetchCompanyDetail(employerId: number): Promise<void> {
		await axios
			.get(searchEmployer(employerId))
			.then((response) => {
				this.companyDetail = response.data.data as CompanyDetail;
				this.storedCompanyDetail = { ...this.companyDetail };
				this.displayAuthContact = response.data.displayAuthContact;
				this.reportingCentreCount = response.data.reportingCentreCount;
			})
			.catch((error) => {
				if (error.response.status === 403) {
					toastErrorMessage(
						"Your current permissions do not allow you to view this data."
					);
				} else {
					toastErrorMessage(parseErrorMessage(error));
				}
			});
	}

	get readonly() {
		return !hasPermission("EDIT_EMPLOYER");
	}

	get readonlyAbn() {
		return !hasPermission("EDIT_EMPLOYER_ABN");
	}
}
