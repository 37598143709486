












import Vue from "vue";
import { Component } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import MaintenanceGrid from "@/components/MaintenanceGrid.vue";
import { RoutePath } from "@/router/routePath";

@Component({
	components: { Layout, MaintenanceGrid },
})
export default class AdminOps extends Vue {
	private maintenanceGridItems = [
		{
			name: "File mappings",
			description: "View and configure file mappings",
			link: RoutePath.MaintainFileMapping,
			buttonName: "Configure file mappings",
		},
		{
			name: "File mappings",
			description: "View file mappings",
			link: RoutePath.ViewFileMapping,
			dataCy: "viewFileMappings",
			buttonName: "View file mappings",
		},
		{
			name: "Employer file formats",
			description: "View file formats and associate them to employers",
			link: RoutePath.EmployerFileFormat,
			dataCy: "associateFileFormats",
			buttonName: "Associate employer file formats",
		},
		{
			name: "Fund mapping",
			description: "View and configure fund mappings",
			link: RoutePath.FundMapping,
			buttonName: "Configure fund mappings",
			dataCy: "configureUsiFundMappings",
		},
		{
			name: "Employer fund mappings",
			description: "View fund formats and associate them to employers",
			link: RoutePath.EmployerFundFormat,
			dataCy: "associateFundFormats",
			buttonName: "Associate employer fund formats",
		},
	];

	private maintenanceActive = false;

	updated() {
		this.maintenanceActive = typeof this.$refs.rv === "undefined";
	}
	mounted() {
		this.maintenanceActive = typeof this.$refs.rv === "undefined";
	}
}
