





















































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Button from "@/form/Button.vue";
import TextField from "@/form/TextField.vue";
import axios from "@/utils/ApiUtils";
import SelectField from "@/form/SelectField.vue";
import {
	addFundFormatListUrl,
	listAvailableFundFormat,
} from "@/constants/apiconstants";
import { FundFormat } from "@/models/FundFormatRow";
import { SelectOption } from "@/form/FieldOptions";
import { toastErrorMessage, toastSuccessMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";

class FundFormatClass {
	id = null;
}

@Component({
	components: {
		Button,
		TextField,
		SelectField,
	},
})
export default class LinkFundFormat extends Vue {
	@Prop({ type: String, default: "" })
	readonly target!: string;

	@Prop({ type: String, default: "top" })
	readonly placement!: string;

	@Prop({ type: String, default: "" })
	readonly selectedEntity!: string;

	private readonly fundFormatOptions: SelectOption[] = [];

	private fundFormatObj: FundFormatClass = new FundFormatClass();

	addFormatLink() {
		axios
			.get(addFundFormatListUrl(), {
				params: {
					fundFormatId: this.fundFormatObj.id,
					entities: this.$store.state.persistent.selectedEntities,
				},
			})
			.then((response) => {
				if (response.data) {
					toastSuccessMessage("A fund format has been associated.");
					this.$emit("done");

					// Reset the file obj
					this.fundFormatObj.id = null;
				}
			})
			.catch((rejected) => {
				toastErrorMessage(parseErrorMessage(rejected));
			});
	}

	close() {
		this.$emit("close");
	}

	mounted() {
		axios
			.get(listAvailableFundFormat(), {
				params: {
					entities: this.$store.state.persistent.selectedEntities,
				},
			})
			.then((response: { data: FundFormat[] }) => {
				response.data.forEach((item) => {
					this.fundFormatOptions.push({
						value: item.id.toString(),
						label: `${item.fundFormatName} - ${item.usi}`,
					});
				});
			});
	}
}
