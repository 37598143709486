





















































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class Card extends Vue {
	@Prop({ type: String, default: "" }) readonly title!: string;
	@Prop({ type: Array, default: null }) readonly icon!: string[];

	@Prop({ type: Boolean }) readonly verticallyCenterBody!: boolean;
	@Prop({ type: String }) readonly shortcutRouterName!: string | undefined;
	@Prop({ type: String, default: "" }) readonly cardDataCy!: string;
	@Prop({ type: Boolean, default: false })
	readonly enableShortcutRouterName!: boolean;

	navigateToPage() {
		if (this.shortcutRouterName) {
			this.$router.push({
				name: this.shortcutRouterName,
			});
		}
	}
}
