import { PagedResult } from "@/grid/gridTypes";

// This should correspond to the Java class au.com.iress.clearinghouse.portal.superstream.response.SuperStreamResponseDto
export interface SuperStreamContributionResponse {
	parentContributionFileId: number;
	severity: string;
	responseDate: string | null;
	responseType: string;
	requestAmount: string | null;
	givenNames: string;
	surname: string;
	fund: string | null;
	errorCode: string;
	shortDescription: string | null;
	detailedDescription: string;
	conversationId: string;
	mrorId: number;
	cterId: number;
	status: string | null;
	refund: string | null;
	type: string;
	contextId: string | null;
	superFundAbn: string | null;
	superFundUsi: string | null;
	superFundName: string | null;
	memberClientId: string | null;
	refundAmount: string | null;
	refundPaymentTotal: string | null;
	employerAbn: string | null;
	refundPaymentRefNumber: string | null;
	// CHSN-1329: additional fields for filtering, not used in the frontend.
	fwIdentity: number;
	fwPayrollName: string;
}

// This should correspond to the Java class au.com.iress.clearinghouse.portal.superstream.response.SuperStreamResponsePageResult
export interface SuperStreamContributionResponsePageResult {
	pagedResult: PagedResult<SuperStreamContributionResponse>;
}

export interface SuperStreamSelectedRow {
	batchId: number;
	responseType: string;
	responseTypeId: number;
	refId?: string;
}

export const SUPERSTREAM_TYPE_MROR = "MROR";
export const SUPERSTREAM_TYPE_CTER = "CTER";

export const SUPERSTREAM_STATUS_DONE = "D";
export const SUPERSTREAM_STATUS_PENDING = "P";
