













































































































































































































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import {
	AgGridEvent,
	CellClickedEvent,
	ColDef,
	ColGroupDef,
	ColumnApi,
	CsvExportParams,
	GridApi,
	GridOptions,
	ICellRendererParamsTyped,
	Module,
	RowClickedEvent,
	RowNode,
	GridOptionsWrapper,
	GetContextMenuItemsParams,
	MenuItemDef,
	SelectionChangedEvent,
	ColumnState,
	IServerSideDatasourceTyped,
	Beans,
} from "ag-grid-community";
import { axiosStatic, CancelTokenSource } from "@/utils/ApiUtils";
import GridLoadingOverlay from "@/grid/GridLoadingOverlay.vue";
import ValueTranslatedCellRenderer from "@/grid/cellrenderers/ValueTranslatedCellRenderer.vue";
import StyledCellRenderer from "@/grid/cellrenderers/StyledCellRenderer.vue";
import { RowNodeEvent } from "ag-grid-community/dist/lib/entities/rowNode";
import { replace } from "lodash";
import { getFormattedCurrency } from "@/utils/CommonUtils";
import { isPagedResult, PagedResult } from "@/grid/gridTypes";
import { ValueFormatterParams } from "ag-grid-community/dist/lib/entities/colDef";
import { ProcessCellForExportParams } from "ag-grid-community/dist/lib/interfaces/exportParams";
import { Logger } from "@/utils/logger";

export interface GridRowEvent<Type = { [fieldName: string]: any }> {
	rowIndex: number;
	data: Type;
}

export interface SortModel {
	colId: string;
	sort: string;
}

/**
 * Grid is a thin-wrapper around ag-grid-vue so that we can expand upon its API.
 *
 * NOTE (York): 2020-01-05
 * We bind row data in the way with `:rowData="rowData"`, instead of using
 * v-model. Ensure DO NOT bind the `data-model-changed` event. Otherwise,
 * row-data loses reactivity (CHSN-791)
 * See: https://www.ag-grid.com/vuejs-misc/#binding-row-data-with-v-model
 */
@Component({
	components: {
		AgGridVue,
		GridLoadingOverlay,
	},
})
export default class Grid<Type = { [fieldName: string]: any }> extends Vue {
	/**
	 * The default behaviour of the grid is to not use a columns valueFormatter for export or copying but this
	 * has been replaced. To disable the valueFormater this class can be added to the column definition
	 */
	public static readonly DISABLE_FORMAT_FOR_EXPORT_CLASS =
		"disable-value-formatter-on-export";

	@Prop([Array]) readonly columnDefs!: (ColGroupDef | ColDef)[];

	@Prop([Array]) readonly modules!: Module[];

	@Prop({ type: String, default: "" })
	readonly editType!: string;

	private defaultColDef: ColDef = {
		resizable: true,
		menuTabs: [],
	};

	/**
	 * rowData is used for client-side rendering.
	 * ie. render all the data at once
	 *
	 * - if row-data prop is unused or passed as null, grid displays loading overlay
	 * - if row-data is passed as [], grid displays no row data overlay
	 */
	@Prop({ type: Array }) readonly rowData: Type[] | null | undefined;
	@Prop({ default: () => [] }) readonly autoGroupColumnDef!: ColGroupDef[];

	@Prop([String]) readonly gridKey!: string;

	@Prop([Boolean]) readonly footer!: boolean;
	@Prop({ default: true }) private pagination!: boolean;

	/**
	 * serverSideDatasource is an object or class that implements:
	 *
	 * - getRows(params: IServerSideGetRowsParamsTyped): void;
	 * - destroy?(): void;
	 */
	@Prop([Object])
	readonly serverSideDatasource!: IServerSideDatasourceTyped<Type, void>;

	@Prop([Array]) readonly footerRowData!: Type[];

	@Prop({ default: 10 }) readonly paginationPageSize!: number;

	@Prop({ default: 0 }) readonly groupDefaultExpanded!: number;

	@Prop({ type: Boolean, default: false })
	readonly groupMultiAutoColumn!: boolean;

	@Prop() readonly detailCellRendererParams!: any;

	@Prop({ type: Boolean, default: false }) readonly masterDetail!: boolean;

	@Prop(String) private readonly domLayout!: string | undefined;

	/**
	 * Min height can't be set with auto height.
	 * https://www.ag-grid.com/documentation/vue/grid-size/#min-height-with-auto-height
	 */
	@Prop(String) private mainGridHeight!: string | undefined;

	@Prop() readonly isRowMaster!: any;

	private currentRowOver: number | null = null;

	/**
	 * internalRowData is used for server-side rendering.
	 * ie. render a partial amount of data
	 */
	private internalRowData: Type[] = [];

	private mainGridOptions: GridOptions = {};

	private footerGridOptions: GridOptions = {};

	private readonly themeClass = "ag-theme-alpine";

	private frameworkComponents = {
		valueTranslatedCellRenderer: ValueTranslatedCellRenderer,
		styledCellRenderer: StyledCellRenderer,
		gridLoadingOverlay: GridLoadingOverlay,
	};

	private serverQueryCancelTokenSource: CancelTokenSource | null = null;

	@Prop() private component: any;
	@Prop() private rowSelection: any;
	@Prop() private readonly stopEditingWhenGridLosesFocus!: boolean;
	private aggFuncs = {
		sumOfCurrency: this.sumOfCurrency,
	};
	private gridApi: GridApi | null = null;

	private gridColumnApi: ColumnApi | null = null;

	private paginationRowCount = 0;

	/**
	 * paginationCurrentPage starts from 0.
	 * To the end-user, page 0, is page 1, because normal human beings count from 1 onward.
	 */
	private paginationCurrentPage = 0;

	get readablePaginationCurrentPage() {
		return this.paginationTotalPages === 0
			? 0
			: this.paginationCurrentPage + 1;
	}

	/**
	 * paginationTotalPages can be 0 when paginationTotalPages is zero
	 */
	private paginationTotalPages = 0;

	/**
	 * isFetching is true if server side rows are currently being queried.
	 */
	private isFetching = false;

	private lastRow = 0;
	private loadingOverlayComponent = "gridLoadingOverlay";

	private defaultExportParams: CsvExportParams = {
		processCellCallback: this.processCellForExport,
	};

	@Prop(Function) exportAll!: () => void | undefined;

	@Prop(Function) isRowSelectable!: (rowNode: RowNode) => boolean;

	@Prop(String) overlayNoRowsTemplate: string | undefined;

	@Prop({ type: Boolean, default: false })
	readonly suppressRowClickSelection!: boolean;

	@Prop({ type: Boolean, default: false })
	readonly groupIncludeTotalFooter!: boolean;

	/**
	 * Replace other export options with `Export All` if available, otherwise return default export menu
	 */
	getExportMenu(
		gridOptionsWrapper: GridOptionsWrapper,
		exportAll?: () => void
	): string | MenuItemDef {
		if (!exportAll) {
			// use default export menu
			return "export";
		}
		// Copy from `MenuItemMapper.prototype.getStockMenuItem` in ag-grid-enterprise
		// Remove all other export options. If you want to get them back, check in the git commit.
		const exportSubMenuItems = [];

		const localeTextFunc = gridOptionsWrapper.getLocaleTextFunc();
		// Append `Export All`
		exportSubMenuItems.push({
			name: localeTextFunc("exportAll", "Export All"),
			action: exportAll,
		});

		return {
			name: localeTextFunc("export", "Export"),
			subMenu: exportSubMenuItems,
			icon: `<span class="ag-icon ag-icon-save" unselectable="on" role="presentation"></span>`,
		};
	}

	getContextMenuItems(
		params: GetContextMenuItemsParams
	): (string | MenuItemDef)[] {
		const defaultItems = params.defaultItems ?? [];
		const menuItems: (string | MenuItemDef)[] = defaultItems.filter(
			(menuItem) => menuItem !== "export"
		);
		menuItems.push(
			this.getExportMenu(
				(params.api as any).gridOptionsWrapper,
				this.exportAll
			)
		);
		return menuItems;
	}

	$refs!: {
		gridEl: AgGridVue;
	};

	/**
	 * We use the ColDef.prototype.valueFormatter for CSV export and clipboard.
	 * To disable it for a column add the Grid.DISABLE_FORMAT_FOR_EXPORT_CLASS class to the ColDef
	 */
	private processCellForExport(params: ProcessCellForExportParams) {
		const colDef = params.column.getColDef();
		const classes = Array.isArray(colDef.cellClass)
			? colDef.cellClass
			: [colDef.cellClass];
		if (
			typeof colDef.valueFormatter === "function" &&
			!classes.includes(Grid.DISABLE_FORMAT_FOR_EXPORT_CLASS)
		) {
			return colDef.valueFormatter({
				...params,
				colDef,
			} as any as ValueFormatterParams);
		}
		return params.value;
	}

	beforeMount() {
		if (this.footer) {
			this.mainGridOptions.suppressHorizontalScroll = true;
			if (!this.footerGridOptions.alignedGrids) {
				this.footerGridOptions.alignedGrids = [];
				this.footerGridOptions.alignedGrids.push(this.mainGridOptions);
			}
			if (!this.mainGridOptions.alignedGrids) {
				this.mainGridOptions.alignedGrids = [];
				this.mainGridOptions.alignedGrids.push(this.footerGridOptions);
			}
		}
		this.mainGridOptions.stopEditingWhenGridLosesFocus =
			this.stopEditingWhenGridLosesFocus ?? false;
		this.mainGridOptions.groupIncludeTotalFooter =
			this.groupIncludeTotalFooter;
	}

	get computedRowData(): Type[] {
		if (this.serverSideDatasource) {
			return this.internalRowData;
		}
		return this.rowData ?? [];
	}

	private beforeDestroy(): void {
		for (const rowEl of this.getRowElements()) {
			rowEl.removeEventListener("mouseover", this.onRowMouseOver);
			rowEl.removeEventListener("mouseleave", this.onRowMouseOut);
		}
	}

	/**
	 * Re-emits data of the clicked row.
	 */
	private onRowClicked(event: RowClickedEvent) {
		this.$emit("row-clicked", event.data);
	}

	private onRowValueChanged(event: RowNodeEvent): void {
		this.$emit("row-value-changed", event);
	}

	private onRowEditingStarted(event: RowNodeEvent): void {
		this.$emit("row-editing-started", event);
	}

	private onRowEditingStopped(event: RowNodeEvent): void {
		this.$emit("row-editing-stopped", event);
	}

	private onSelectionChanged(event: SelectionChangedEvent): void {
		this.$emit("selection-changed", event);
	}

	/**
	 * NOTE (York): mouseover/mouseleave events are never used. Consider removing this
	 * method and onRowMouseOver/onRowMouseOut in the future. This method once was used
	 * by data-model-changed event handler `onDataModelChanged` which is removed.
	 */
	private initEventListeners(): void {
		for (const rowEl of this.getRowElements()) {
			rowEl.addEventListener("mouseover", this.onRowMouseOver);
			rowEl.addEventListener("mouseleave", this.onRowMouseOut);
		}
	}

	get paginationStartRange(): number {
		if (this.paginationTotalPages === 0) {
			return 0;
		}
		return this.paginationCurrentPage * this.paginationPageSize + 1;
	}

	private onCellMouseOver(params: ICellRendererParamsTyped<Type>): void {
		this.$emit("cell-mouse-over", params);
	}

	private onCellMouseOut(params: ICellRendererParamsTyped<Type>): void {
		this.$emit("cell-mouse-out", params);
	}

	private onCellClicked(e: CellClickedEvent): void {
		this.$emit("cell-clicked", e);
	}

	get paginationEndRange(): number {
		if (this.isLastPage) {
			return this.paginationRowCount;
		}
		return (
			this.paginationCurrentPage * this.paginationPageSize +
			this.paginationPageSize
		);
	}

	get isFirstPage(): boolean {
		return this.paginationCurrentPage === 0;
	}

	get isLastPage(): boolean {
		const endRange =
			this.paginationCurrentPage * this.paginationPageSize +
			this.paginationPageSize;
		return endRange >= this.paginationRowCount;
	}

	get canClickFirstPage(): boolean {
		return (
			this.paginationTotalPages > 1 &&
			!this.isFirstPage &&
			!this.isFetching
		);
	}

	get canClickLastPage(): boolean {
		return (
			this.paginationTotalPages > 1 &&
			!this.isLastPage &&
			!this.isFetching
		);
	}

	get canClickPrevPage(): boolean {
		if (!this.gridApi || this.isFirstPage || this.isFetching) {
			return false;
		}
		return true;
	}

	get canClickNextPage(): boolean {
		if (!this.gridApi || this.isLastPage || this.isFetching) {
			return false;
		}
		return true;
	}

	get gridClasses(): string {
		let classes = this.themeClass + " Grid__grid";
		if (this.domLayout === "autoHeight") {
			classes += " Grid__grid--auto-height";
		}
		return classes;
	}

	updatePaginationState(api: GridApi): void {
		if (this.serverSideDatasource) {
			throw new Error(
				'This is not allowed to be called for "serverSideDatasource" cases.'
			);
		}
		this.paginationCurrentPage = api.paginationGetCurrentPage();
		this.paginationTotalPages = api.paginationGetTotalPages();
		this.paginationRowCount = api.paginationGetRowCount();
		// const paginationComp = api.context.componentsMappedByName['AG-PAGINATION'];
		// console.warn('updatePaginationState', api, this.mainGridOptions, paginationComp, paginationComp.nextButtonDisabled);
	}

	public showLoadingOverlay(): void {
		this.gridApi?.showLoadingOverlay();
	}

	public hideOverlay(): void {
		this.gridApi?.hideOverlay();
	}

	public sizeColumnsToFit(): void {
		if (!this.gridApi) {
			Logger.warn(
				"Grid API is not available (grid is not ready yet and no mainGridOptions provided). sizeColumnsToFit is a no-op."
			);
			return;
		}
		this.gridApi.sizeColumnsToFit();
	}

	/**
	 * reload can be called by other components to trigger calling "getRows".
	 * This allows us to reload the data after updating filters / etc.
	 * resets page to first page
	 */
	public reload(): void {
		if (!this.gridApi) {
			return;
		}
		this.gridApi.showLoadingOverlay();
		if (!this.serverSideDatasource) {
			// Do nothing if not remote
			return;
		}
		this.queryForServerData(0, this.paginationPageSize);
	}
	/**
	 * reload can be called by other components to trigger calling "getRows".
	 * This allows us to reload the data after updating a row data and retain
	 * the page selection
	 */
	public refresh(): void {
		let startRow = this.lastRow - this.paginationPageSize;
		if (startRow < 0) {
			startRow = 0;
		}
		const endRow = this.lastRow;
		this.queryForServerData(
			startRow,
			endRow,
			this.columnApi?.getColumnState()
		);
	}

	private mounted() {
		this.initEventListeners();
		if (this.mainGridOptions) {
			if (this.mainGridOptions.api) {
				this.gridApi = this.mainGridOptions.api;
			}
			if (this.mainGridOptions.columnApi) {
				this.gridColumnApi = this.mainGridOptions.columnApi;
			}
		}
	}

	private getRowElements(): HTMLElement[] {
		if (!this.$refs.gridEl || !this.$refs.gridEl.$el) {
			return [];
		}
		const resultList: HTMLElement[] = [];
		for (const rowEl of this.$refs.gridEl.$el.querySelectorAll(
			".ag-row[row-index]"
		)) {
			if (
				!rowEl.parentNode ||
				(rowEl.parentNode as HTMLElement).getAttribute("role") !==
					"rowgroup"
			) {
				continue;
			}
			resultList.push(rowEl as HTMLElement);
		}
		return resultList;
	}

	private onRowMouseOver(e: MouseEvent): void {
		if (!this.$listeners || !this.$listeners["row-mouse-over"]) {
			return;
		}
		if (!(e.currentTarget instanceof HTMLElement)) {
			return;
		}
		const rowIndexOrUndefined = e.currentTarget.getAttribute("row-index");
		if (rowIndexOrUndefined === undefined || rowIndexOrUndefined === null) {
			return;
		}
		const rowIndex = Number(rowIndexOrUndefined);
		if (this.currentRowOver === rowIndex) {
			// If already hovering over this row, avoid firing
			// the event for the user.
			return;
		}
		this.currentRowOver = rowIndex;
		const record = this.computedRowData[rowIndex];
		const r: GridRowEvent<Type> = {
			rowIndex: rowIndex,
			data: record,
		};
		this.$emit("row-mouse-over", r);
	}

	private onRowMouseOut(e: MouseEvent): void {
		if (!this.$listeners || !this.$listeners["row-mouse-out"]) {
			return;
		}
		if (!(e.currentTarget instanceof HTMLElement)) {
			return;
		}
		const rowIndexOrUndefined = e.currentTarget.getAttribute("row-index");
		if (rowIndexOrUndefined === undefined || rowIndexOrUndefined === null) {
			return;
		}
		const rowIndex = Number(rowIndexOrUndefined);
		const record = this.computedRowData[rowIndex];
		const r: GridRowEvent<Type> = {
			rowIndex: rowIndex,
			data: record,
		};
		this.$emit("row-mouse-out", r);
		this.currentRowOver = null;
	}

	private onPaginationChanged(params: AgGridEvent) {
		if (!params.api) {
			return;
		}
		if (!this.serverSideDatasource) {
			this.updatePaginationState(params.api);
			return;
		}
	}

	private onClickFirstPage(): void {
		if (!this.gridApi || !this.canClickFirstPage) {
			return;
		}
		if (!this.serverSideDatasource) {
			// Use native behaviour for local list
			this.gridApi.paginationGoToFirstPage();
			this.$emit("page-changed");
			return;
		}
		const startRow = 0;
		const endRow = startRow + this.paginationPageSize;
		this.queryForServerData(
			startRow,
			endRow,
			this.columnApi?.getColumnState()
		);
		this.$emit("page-changed");
	}

	private onClickLastPage(): void {
		if (!this.gridApi || !this.canClickLastPage) {
			return;
		}
		if (!this.serverSideDatasource) {
			// Use native behaviour for local list
			this.gridApi.paginationGoToLastPage();
			this.$emit("page-changed");
			return;
		}
		const startRow =
			this.paginationTotalPages * this.paginationPageSize -
			this.paginationPageSize;
		const endRow = startRow + this.paginationPageSize;
		this.queryForServerData(
			startRow,
			endRow,
			this.columnApi?.getColumnState()
		);
		this.$emit("page-changed");
	}

	private onClickPrevPage(): void {
		if (!this.gridApi || !this.canClickPrevPage || this.isFetching) {
			return;
		}
		if (!this.serverSideDatasource) {
			// Use native behaviour for local list
			this.gridApi.paginationGoToPreviousPage();
			this.$emit("page-changed");
			return;
		}
		let startRow = this.lastRow - 2 * this.paginationPageSize;
		if (startRow < 0) {
			startRow = 0;
		}
		const endRow = startRow + this.paginationPageSize;
		this.queryForServerData(
			startRow,
			endRow,
			this.columnApi?.getColumnState()
		);
		this.$emit("page-changed");
	}

	private onClickNextPage(): void {
		if (!this.gridApi || !this.canClickNextPage) {
			return;
		}
		if (!this.serverSideDatasource) {
			// Use native behaviour for local list
			this.gridApi.paginationGoToNextPage();
			this.$emit("page-changed");
			return;
		}
		const startRow = this.lastRow;
		const endRow = startRow + this.paginationPageSize;
		this.queryForServerData(
			startRow,
			endRow,
			this.columnApi?.getColumnState()
		);
		this.$emit("page-changed");
	}

	public get api(): GridApi | null {
		return this.gridApi;
	}

	public get columnApi(): ColumnApi | null {
		return this.gridColumnApi;
	}

	private onSortChanged(event: AgGridEvent): void {
		if (!event.api) {
			return;
		}
		if (this.serverSideDatasource) {
			// server side sort
			let startRow = this.lastRow - this.paginationPageSize;
			if (startRow < 0) {
				startRow = 0;
			}
			const endRow = this.lastRow;
			this.queryForServerData(
				startRow,
				endRow,
				this.columnApi?.getColumnState()
			);
		}
	}

	get paginationSize() {
		if (this.pagination && this.serverSideDatasource) {
			return this.paginationPageSize;
		}
		return undefined;
	}

	private queryForServerData(
		startRow: number,
		endRow: number,
		columnStates?: ColumnState[]
	): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			if (!this.gridApi) {
				throw new Error(
					"Unexpected error. Cannot call if missing 'gridApi'."
				);
			}
			if (!this.gridColumnApi) {
				throw new Error(
					"Unexpected error. Cannot call if missing 'gridColumnApi'."
				);
			}
			if (this.isFetching && this.serverQueryCancelTokenSource) {
				// If client code does not use axios and put cancel token into request config,
				// this cancel call does nothing.
				this.serverQueryCancelTokenSource.cancel();
				this.serverQueryCancelTokenSource = null;
			}
			const columnPrimaryId = "id";

			const rowNode = new RowNode(new Beans());
			rowNode.id = columnPrimaryId;
			rowNode.data = this.internalRowData;
			rowNode.level = -1; // documentation says -1 is top-level
			this.isFetching = true;

			const sortingCol = columnStates?.filter((obj) => {
				return obj.sort != null;
			});

			// Create cancel token for this query and pass it as parameter `cancelToken` of getRow.
			// It is the choice of client code whether use it or not by putting it into axios request config.
			this.serverQueryCancelTokenSource =
				axiosStatic.CancelToken.source();
			this.serverSideDatasource.getRows({
				request: {
					startRow: startRow,
					endRow: endRow,
					rowGroupCols: [],
					valueCols: [],
					pivotCols: [],
					pivotMode: false,
					groupKeys: [],
					filterModel: null, // any
					sortModel: sortingCol ? sortingCol[0] : null,
				},
				parentNode: rowNode,
				cancelToken: this.serverQueryCancelTokenSource.token,
				successCallback: (
					// Note (York): Do not pass in an Array since last row is not used. Pagination only
					// works when PagedResult<Type> is passed in.
					rowsThisPage: Type[] | PagedResult<Type> | null,
					// Note (York): last row is not used
					lastRow: number
				): void => {
					this.isFetching = false;
					this.serverQueryCancelTokenSource = null;

					if (
						!rowsThisPage ||
						(Array.isArray(rowsThisPage) &&
							rowsThisPage.length <= 0)
					) {
						this.internalRowData = [];
					} else {
						if (
							Array.isArray(rowsThisPage) &&
							rowsThisPage.length > 0
						) {
							this.internalRowData = rowsThisPage;
						} else if (isPagedResult<Type>(rowsThisPage)) {
							this.internalRowData = rowsThisPage.elements;
							this.paginationCurrentPage =
								rowsThisPage.offset / rowsThisPage.limit;
							if (rowsThisPage.totalElements > 0) {
								this.lastRow =
									this.paginationCurrentPage *
										this.paginationPageSize +
									rowsThisPage.limit;
							} else {
								this.lastRow = 0;
							}
							this.paginationTotalPages = Math.ceil(
								rowsThisPage.totalElements / rowsThisPage.limit
							);
							this.paginationRowCount =
								rowsThisPage.totalElements;
						}
					}

					if (this.internalRowData.length === 0) {
						this.hideOverlay();
						this.showNoRowsOverlay();
					}
					resolve();
				},
				failCallback: (): void => {
					this.isFetching = false;
					this.serverQueryCancelTokenSource = null;
					resolve();
				},
				api: this.gridApi,
				columnApi: this.gridColumnApi,
			});
		});
	}

	private onGridReady(params: AgGridEvent) {
		this.gridApi = params.api;
		params.api.sizeColumnsToFit();

		if (!this.serverSideDatasource) {
			params.api.paginationSetPageSize(this.paginationPageSize);
			this.updatePaginationState(params.api);

			// NOTE(Jae): 2020-06-09
			// We could pass "params" directly through. But I want to be conservative
			// with the API surface for now, only trickle up info if we need to.
			this.$emit("grid-ready", this.gridKey);
		} else {
			this.gridApi.showLoadingOverlay();
			this.queryForServerData(0, 0, this.columnApi?.getColumnState())
				.then(() => {
					this.$emit("grid-ready", this.gridKey);
				})
				.catch(() => {
					// Vue.toasted.global.errorToast("Error");
				});
		}
	}

	private sumOfCurrency(params: any) {
		let result = 0.0;
		params.values.forEach(function (value: string) {
			value = value.replace("$", "");
			value = replace(value, new RegExp(",", "g"), "");
			if (!isNaN(parseFloat(value))) {
				result += parseFloat(parseFloat(value).toFixed(2));
			}
		});
		return getFormattedCurrency(result.toString());
	}

	public showNoRowsOverlay() {
		this.gridApi?.showNoRowsOverlay();
	}

	public setRowData(items: any[]) {
		this.gridApi?.setRowData(items);
	}

	public paginationGoToPage(page: number) {
		this.gridApi?.paginationGoToPage(page);
	}
}
