









import { Prop, Vue, Component } from "vue-property-decorator";
import Grid from "@/grid/Grid.vue";
import LeftRightFooter from "@/components/LeftRightFooter.vue";
import Button from "@/form/Button.vue";
import { ColDef } from "ag-grid-community";

export interface GridSubsectionProps {
	addBtnLabel?: string;
	canEdit?: boolean;
	defaultObject?: any;
	identityField?: string;
	pagination?: boolean;
	rowsToShow?: number;
}
@Component({
	components: { Button, LeftRightFooter, Grid },
})
export default class GridSubsection extends Vue {
	@Prop()
	rowData!: any[];
	@Prop()
	columnDefs!: ColDef[];
	@Prop()
	gridProps!: GridSubsectionProps;
	// TODO: Make this dynamic. This is just for demo and should work for more than just variance.
	private noRowsTemplate =
		'<span style="position:absolute; bottom:15%;">No expected contributions found</span>';

	public $refs!: {
		gridEl: Grid;
	};

	/*
	 * Grid height is got from dom-layout="autoHeight" when there is 10 rows.
	 * Each row is 40px height with two 1px border. Header is 48px with 1px
	 * border bottom. The grid border top and bottom are 1px. Totally
	 * 42px * number of rows + 48px + 1px + 2px
	 */
	private dynamicProps() {
		if (!this.gridProps) {
			return {
				domLayout: "autoHeight",
			};
		}
		const settings: any = {};
		if (this.gridProps?.rowsToShow) {
			settings["mainGridHeight"] = `${
				42 * this.gridProps.rowsToShow + 51
			}px`;
		}

		settings["pagination"] = this.gridProps?.pagination ?? true;

		return settings;
	}
}
