
















































































































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import EmployerSelector from "@/components/EmployerSelector.vue";
import Form from "@/form/Form.vue";
import TextField from "@/form/TextField.vue";
import FieldGroup from "@/form/FieldGroup.vue";
import { FundType, SmsfAlias } from "@/pages/fundTypes";
import ModalWithSaveAndCancel from "@/components/ModalWithSaveAndCancel.vue";
import SelectField from "@/form/SelectField.vue";
import RadioGroup from "@/form/RadioGroup.vue";
import Button from "@/form/Button.vue";
import { SelectOption } from "@/form/FieldOptions";
import { EmployeeFundDetails } from "@/models/EmployeeRow";

@Component({
	components: {
		RadioGroup,
		ModalWithSaveAndCancel,
		FieldGroup,
		TextField,
		Form,
		Layout,
		EmployerSelector,
		Button,
		SelectField,
	},
})
export default class AddFundForm extends Vue {
	private defaultFundOptions = ["Yes", "No"];
	private selectedDefaultFund = "No";
	@Prop({ type: Boolean, default: false }) hideDefaultFund!: boolean;
	@Prop({ type: Boolean, default: false }) readonly!: boolean;

	@Prop() smsfAliasMap!: { [alias: string]: SmsfAlias } | null;
	/**
	 * Bind to multiselect component and get the selected fund
	 */
	@Prop() selectedFund!: EmployeeFundDetails;
	private canAddDefaultFund = true;

	get showFundForm(): boolean {
		return (
			this.selectedFund &&
			this.selectedFund.fundType === FundType.APRA &&
			this.selectedFund.fundAbn.length > 0
		);
	}
	get showSmsfForm(): boolean {
		return (
			this.selectedFund &&
			this.selectedFund.fundType === FundType.SMSF &&
			this.selectedFund.fundAbn.length > 0
		);
	}

	get isFundActive(): string {
		return this.selectedFund.active ? "Active" : "Inactive";
	}
	get esaOptions(): SelectOption[] {
		const smsfAlias = this.selectedFund.smsfAliasMap
			? this.selectedFund.smsfAliasMap
			: this.smsfAliasMap
			? this.smsfAliasMap
			: null;
		if (!this.selectedFund || !smsfAlias) {
			return [{ value: "", label: "" }];
		}
		return Object.keys(smsfAlias).map((alias) => ({
			value: alias,
			label: alias,
		}));
	}
	emitUpdate(fieldName: string, value: any): void {
		this.$emit("update", fieldName, value);
	}
}
