













































import Grid from "@/grid/Grid.vue";
import Vue from "vue";
import Button from "@/form/Button.vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import LeftRightFooter from "@/components/LeftRightFooter.vue";
import GridActionsRenderer from "@/grid/GridActionsRenderer.vue";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import Container from "@/components/Container.vue";
import { differenceWith, toPairs, isEqual } from "lodash-es";
import axios from "@/utils/ApiUtils";
import {
	toastInfoMessage,
	toastSuccessMessage,
	toastErrorMessage,
} from "@/plugins/toasts";
import GridLoadingOverlay from "@/grid/GridLoadingOverlay.vue";
import { capitalise } from "@/utils/CommonUtils";
import { parseErrorMessage } from "@/utils/ErrorUtils";

const EMPLOYEE_DB_ADD_BUTTON_MAPPING = {
	"Add Salary History Record": "Add salary history",
	"Add Purchased Leave Record": "Add purchased leave history",
	"Add Service Fraction": "Add service fraction",
	"Add LWOP Record": "Add LWOP history",
	"Add Employment Record": "Add employment history",
};
export interface DynamicGridTabProps {
	tabName: string;
	columnDefs: ColDef[];
	mode: string;
	gridData: any[];
	gridName: string;
	icon: string[];
	identityField: string;
	addBtnLabel?: string;
	canEdit?: boolean;
	defaultObject?: any;
}
@Component({
	components: {
		Container,
		LeftRightFooter,
		Grid,
		Button,
		GridLoadingOverlay,
	},
})
export default class DynamicGridTab extends Vue {
	@Prop() gridDetails!: DynamicGridTabProps;
	@Prop() saveChangesUrl!: string;
	private readonly gridVMList: Vue[] = [];
	private objectFieldNames: string[] = [];
	private editableFieldNames: string[] = [];
	private originalGridData: any[] = [];
	private deletedObjects: any[] = [];
	private gridLoading = false;

	public $refs!: {
		gridEl: Grid;
	};

	private reloadGrid() {
		this.$refs.gridEl.showLoadingOverlay();
		axios
			.get(`${this.saveChangesUrl}/${this.gridDetails.mode}`)
			.then((res) => {
				this.gridDetails.gridData =
					res.data[this.gridDetails.tabName].grid.gridData;
				// perform a deep copy of the original object, so we can track the change delta
				this.originalGridData = JSON.parse(
					JSON.stringify(this.gridDetails.gridData)
				);
				this.deletedObjects = [];
				this.gridLoading = false;
				this.$refs.gridEl.hideOverlay();
			})
			.catch((e) => {
				toastErrorMessage(parseErrorMessage(e));

				// reset grid back to original data. Something went wrong.
				this.gridDetails.gridData = JSON.parse(
					JSON.stringify(this.originalGridData)
				);
				this.deletedObjects = [];
				this.gridLoading = false;
				this.$refs.gridEl.hideOverlay();
			});
		return true;
	}

	created() {
		// perform a deep copy of the original object, so we can track the change delta
		this.originalGridData = JSON.parse(
			JSON.stringify(this.gridDetails.gridData)
		);
		this.objectFieldNames = this.gridDetails.columnDefs.map((col) =>
			col.field ? col.field : ""
		);
		this.editableFieldNames = this.gridDetails.columnDefs.map((col) =>
			col.field && col.editable ? col.field : ""
		);
		if (
			this.gridDetails.addBtnLabel &&
			this.gridDetails.columnDefs.filter(
				(col) => col.headerName === "Delete"
			).length === 0
		) {
			this.gridDetails.columnDefs.push({
				headerName: "Delete",
				cellRenderer: this.customGridActionsRender,
				width: 80,
				minWidth: 80,
				pinned: "right",
			});
		}
	}

	customGridActionsRender(params: ICellRendererParams): HTMLElement {
		const vm = new Vue({
			el: document.createElement("div"),
			render: (createElement) => {
				return createElement(GridActionsRenderer, {
					props: {
						rowIndex: params.rowIndex,
						isEdit: false, // we don't to use the pencil. In-grid editing is used instead
						isDelete: true,
					},
					on: {
						clickDelete: this.onClickDelete,
					},
				});
			},
		});
		this.gridVMList.push(vm);
		return vm.$el as HTMLElement;
	}

	onClickSave() {
		this.gridLoading = true;
		this.$refs.gridEl.showLoadingOverlay();
		const changes = differenceWith(
			toPairs(this.gridDetails.gridData),
			toPairs(this.originalGridData),
			isEqual
		);

		const upserts: any[] = [];
		changes.forEach((change) => {
			if (change[1][this.gridDetails.identityField] !== "") {
				this.objectFieldNames.forEach((field) => {
					if (
						!this.editableFieldNames.includes(field) &&
						field !== this.gridDetails.identityField
					) {
						delete change[1][field];
					}
				});
				upserts.push(change[1]);
			} else {
				upserts.push(change[1]);
			}
		});

		const deletions: any[] = [];
		this.deletedObjects.forEach((obj) => {
			if (obj[this.gridDetails.identityField] !== "") {
				const deletion: { [k: string]: any } = {};
				deletion[this.gridDetails.identityField] =
					obj[this.gridDetails.identityField];
				deletions.push(deletion);
			}
		});

		if (deletions.length === 0 && upserts.length === 0) {
			toastInfoMessage("No changes made to " + this.gridDetails.gridName);
			this.gridLoading = false;
			this.$refs.gridEl.hideOverlay();
		} else {
			axios
				.put(this.saveChangesUrl + "/" + this.gridDetails.mode, {
					upserts: upserts,
					deletions: deletions,
				})
				.then(
					() =>
						this.reloadGrid() &&
						toastSuccessMessage(
							this.gridDetails.gridName +
								" was updated Successfully"
						)
				)
				.catch((e) => {
					toastErrorMessage(parseErrorMessage(e));
					this.reloadGrid();
				});
		}
	}

	onClickCancel() {
		this.gridLoading = true;
		this.$refs.gridEl.showLoadingOverlay();
		const changes = differenceWith(
			toPairs(this.gridDetails.gridData),
			toPairs(this.originalGridData),
			isEqual
		);
		if (changes.length > 0 || this.deletedObjects.length > 0) {
			// perform a deep copy of the original object, so we can track the change delta
			this.gridDetails.gridData = JSON.parse(
				JSON.stringify(this.originalGridData)
			);
			this.deletedObjects = [];
			toastInfoMessage(this.gridDetails.gridName + " changes reverted");
		} else {
			toastInfoMessage(
				this.gridDetails.gridName + " no changes to revert"
			);
		}
		this.$refs.gridEl.hideOverlay();
		this.gridLoading = false;
	}
	onClickAdd() {
		this.gridDetails.gridData.push(this.generateNewRowObject());
	}

	capitaliseInDom(str: string): string {
		return capitalise(str);
	}

	addButtonTitle(str: string): string {
		const keyTyped = str as keyof typeof EMPLOYEE_DB_ADD_BUTTON_MAPPING;
		return EMPLOYEE_DB_ADD_BUTTON_MAPPING[keyTyped] || capitalise(str);
	}

	onClickDelete({ rowIndex }: { rowIndex: number }) {
		this.deletedObjects.push(this.gridDetails.gridData[rowIndex]);
		this.gridDetails.gridData.splice(rowIndex, 1);
	}
	private generateNewRowObject() {
		const newObject: any = {};
		for (const key of this.objectFieldNames) {
			if (key in this.gridDetails.defaultObject) {
				newObject[key] = this.gridDetails.defaultObject[key];
			} else {
				newObject[key] = "";
			}
		}
		return newObject;
	}
}
