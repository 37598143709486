
























































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import Layout from "../../components/Layout.vue";
import LeftRightFooter from "@/components/LeftRightFooter.vue";
import Button from "@/form/Button.vue";
import { RoutePath } from "@/router/routePath";
import FieldGroup from "@/form/FieldGroup.vue";
import axios, { axiosStatic } from "@/utils/ApiUtils";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import { toastErrorMessage } from "@/plugins/toasts";
import EmployerSelector from "@/components/EmployerSelector.vue";
import ErrorList from "@/components/ErrorList.vue";
import { AxiosResponse } from "axios";
import Container from "@/components/Container.vue";
import Grid from "@/grid/Grid.vue";
import {
	ColDef,
	ColGroupDef,
	IServerSideDatasourceTyped,
	ValueGetterParams,
} from "ag-grid-community";
import { columnDateFormatter } from "@/utils/CommonUtils";
import RadioGroup from "@/form/RadioGroup.vue";
import { RadioOption } from "@/form/FieldOptions";
import { RC } from "@/utils/PermissionUtils";

import { createNamespacedHelpers } from "vuex";
import { isOnlyOneChildlessEmployerSelected } from "@/utils/EmployerSelectorUtils";
import { NO_RC_ERROR_MESSAGE } from "@/constants/constants";
import { EmployerHierarchy } from "@/store/modules/persistent/persistentTypes";
import { PagedResult } from "@/grid/gridTypes";
import { createBatchURL } from "@/constants/apiconstants";

const { mapState } = createNamespacedHelpers("persistent");

interface CreateBatchResponse {
	batchId?: string;
}

interface BatchSummary {
	batchId: number;
	employerId: number;
	parentOrgId: number;
	originalFileName: string;
	numReportingCentres: number;
	numFunds: number;
	finalisationDate: string;
	entityName: string;
}

interface BatchOption extends RadioOption {
	db: boolean;
}

@Component({
	components: {
		RadioGroup,
		Grid,
		Button,
		Container,
		EmployerSelector,
		ErrorList,
		FieldGroup,
		Layout,
		LeftRightFooter,
	},
	computed: mapState([
		"selectedEntities",
		"employerHierarchy",
		"definedBenefitEntities",
	]),
})
export default class CreateBatchPage extends Vue {
	/**
	 * Type the mapped persistent.selectedEntities getter.
	 * It is a computed property. Do not mutate it.
	 */
	selectedEntities!: string[];

	private errors: string[] = [];

	private gridType = "ADHOC";
	private isSubmitting = false;
	private errorMessage: string | null = null;
	/**
	 * Type the mapped persistent.employerHierarchy getter.
	 * It is a computed property. Do not mutate it.
	 */
	employerHierarchy!: EmployerHierarchy[];
	definedBenefitEntities!: string[];

	private batchOptions: BatchOption[] = [
		{
			value: "ADHOC",
			text: "An ad hoc contribution batch, which is a new empty batch into which I add only the employees I need",
			db: false,
		},
		{
			value: "ADHOC_DB",
			text: "An ad hoc contribution batch for 'defined benefit' members, which is a new empty batch into which I add only the employees I need",
			db: true,
		},
		{
			value: "CLONE",
			text: "A regular contribution batch, which is a copy of a previous batch into which I will make changes",
			db: false,
		},
	];
	private selectedBatchId = "";

	private batchSummaryColumnDefs: (ColGroupDef | ColDef)[] = [
		{
			headerName: "Contribution batch ID",
			field: "batchId",
			resizable: true,
		},
		{
			headerName: "Reporting centres",
			field: "numReportingCentres",
			resizable: true,
		},
		{
			headerName: "Uploaded filename",
			field: "originalFileName",
			resizable: true,
		},
		{
			headerName: "Entity name",
			field: "entityName",
			resizable: true,
		},
		{
			headerName: "Entity type",
			valueGetter: this.entityTypeGetter,
			resizable: true,
		},
		{
			headerName: "Finalisation date",
			field: "finalisationDate",
			valueFormatter: columnDateFormatter, // not showing time
			resizable: true,
		},
	];

	entityTypeGetter(params: ValueGetterParams): string {
		if (params.data.employerId && params.data.employerId > 0) {
			return "Employer";
		}
		return "Parent Organisation";
	}

	get batchSummaryDatasource(): IServerSideDatasourceTyped<BatchSummary> {
		const entitySelection = this.entitySelection;
		return {
			getRows(params): void {
				axios
					.get<PagedResult<BatchSummary>>(createBatchURL(), {
						params: {
							selectedEntity: entitySelection,
							grid: params.request,
						},
						cancelToken: params.cancelToken,
					})
					.then((response) => {
						// NOTE (York): Pagination
						params.successCallback(response.data);
					})
					.catch((error) => {
						if (axiosStatic.isCancel(error)) {
							return;
						}
						toastErrorMessage(parseErrorMessage(error));
					});
			},
		};
	}

	public $refs!: {
		creategridform: HTMLFormElement;
		batchsummarygrid: Grid;
	};

	get entitySelection(): string | null {
		if (this.selectedEntities.length !== 1) {
			return null;
		}
		return this.selectedEntities[0];
	}

	private onBatchSelection(batchSummary: BatchSummary) {
		this.selectedBatchId = batchSummary ? "" + batchSummary.batchId : "";
	}

	private onClickCancel() {
		this.$router.push(RoutePath.Dashboard);
	}

	private get canClickNext() {
		const canClickNext =
			!this.isSubmitting &&
			this.gridType &&
			this.entitySelection &&
			!this.errorMessage;
		if (
			canClickNext &&
			this.gridType === "CLONE" &&
			this.selectedBatchId === ""
		) {
			return false;
		}
		return canClickNext;
	}

	private onClickNext() {
		if (
			!this.$refs.creategridform ||
			!this.canClickNext ||
			!this.entitySelection
		) {
			return;
		}
		this.isSubmitting = true;
		const formData = new FormData(this.$refs.creategridform);
		formData.append("create-batch-type", this.gridType);
		formData.append("create-batch-entity", this.entitySelection);

		if (this.gridType === "CLONE") {
			formData.append("create-batch-from-id", this.selectedBatchId);
		}

		axios
			.post<CreateBatchResponse>(createBatchURL(), formData)
			.then((response: AxiosResponse<CreateBatchResponse>) => {
				this.$router.push(
					RoutePath.DataInput.replace(
						"/:id",
						"/" + response.data.batchId
					)
				);
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			})
			.finally(() => {
				this.isSubmitting = false;
			});
	}

	private clearErrorMessage() {
		this.errorMessage = null;
	}

	@Watch("entitySelection", { immediate: true })
	validateSelection() {
		if (
			isOnlyOneChildlessEmployerSelected(
				this.selectedEntities,
				this.employerHierarchy
			)
		) {
			this.errorMessage = NO_RC_ERROR_MESSAGE;
			return;
		}
		if (
			this.entitySelection === null ||
			this.entitySelection.startsWith(RC)
		) {
			this.errorMessage = "Please select Parent Organisation or Employer";
		}
	}

	@Watch("definedBenefitEntities")
	onDefinedBenefitEntitiesChanged() {
		this.$forceUpdate();
	}

	get hasErrorMessage(): boolean {
		return this.errorMessage !== null;
	}

	onGridReady() {
		// Clear grid selection once grid is reloaded, i.e, by switching selected employer.
		this.selectedBatchId = "";
	}

	get canAddDbEmployee() {
		return (
			this.selectedEntities.length === 1 &&
			this.definedBenefitEntities.includes(this.selectedEntities[0])
		);
	}

	get batchOptionsValues() {
		if (this.canAddDbEmployee) {
			return this.batchOptions;
		}
		return this.batchOptions.filter((o) => !o.db);
	}
}
