/**
 * setup.ts is where any side effect imports and plugins should be registered
 * that need to be shared across the main build as well as Jest tests.
 *
 * This file is currently imported by at least two files:
 * - "main.ts", the entrypoint for the Webpack build
 * - "jest.setup.ts", a setup file that runs before each Jest test.
 */

// NOTE(Jae): 2020-05-11
// This must be imported first or else IE11 will fail
// as this contains polyfills.
import "core-js/stable";

// NOTE(Jae): 2020-05-18
// This file must be imported in main before the Vue app instance is initialized.
import "@/router/hooks";

// NOTE(Jae): 2020-05-14
// We must import this for side effects so "axios" has the baseURL
// setup correctly.
import "@/utils/ApiUtils";

import Vue from "vue";
import "@/plugins/fontawesome";
import "@/plugins/bootstrap-vue";
import "@/plugins/vee-validate";
import "@/plugins/v-idle";
import "@/plugins/register-form-schema-fields";
import "@/plugins/toasts";
import "@/plugins/grid";
import "@/router";
import "@/store";
import "@/styles/main.scss";
Vue.config.productionTip = false;
