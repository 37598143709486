import moment from "moment";
import "moment-timezone";
import {
	ValueFormatterParams,
	ValueParserParams,
} from "ag-grid-community/dist/lib/entities/colDef";

export const DATE_REGEX = new RegExp(/\d{4}-\d{2}-\d{2}/g);
export const ACURITY_EMPTY_DATE = "0001-01-01";
export const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";

export function formatDateTime(value: string): string {
	const timezone = moment.tz.guess();
	return moment(value).tz(timezone).format("DD/MM/YYYY HH:mm:ss z");
}

export function columnDateTimeFormatter(param: ValueFormatterParams): string {
	if (param.value && typeof param.value === "string") {
		return formatDateTime(param.value);
	}
	return "";
}

export function getDateRangeForPastThreeMonths() {
	return (
		moment().subtract(3, "months").format(DEFAULT_DATE_FORMAT) +
		" to " +
		moment().format(DEFAULT_DATE_FORMAT)
	);
}

export function getDateForPastNMonths(months: number) {
	return moment().subtract(months, "months").format(DEFAULT_DATE_FORMAT);
}

export function getDateRangeForPastNMonths(months: number) {
	return (
		getDateForPastNMonths(months) +
		" to " +
		moment().format(DEFAULT_DATE_FORMAT)
	);
}

export function getFormattedCurrency(value: string): string {
	const formatter = new Intl.NumberFormat("en-AU", {
		style: "currency",
		currency: "AUD",
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
	return formatter.format(parseFloat(value));
}

export function columnCurrencyFormatter(params: ValueFormatterParams): string {
	if (
		params.value === undefined ||
		params.value === null ||
		params.value === ""
	) {
		return "";
	}
	return getFormattedCurrency(params.value);
}

export function columnCurrencyParser(params: ValueParserParams): string {
	if (params.newValue === undefined || params.newValue === null) {
		return params.oldValue;
	}

	if (params.newValue === "") {
		return "";
	}

	if (!/^\d+(\.\d+)?$/.test(params.newValue)) {
		return params.oldValue;
	}

	return parseFloat(params.newValue).toFixed(2);
}

export function formatDate(value: string): string {
	return moment(value).format("DD/MM/YYYY");
}

export function acurityFormatDate(value: string): string {
	if (value === ACURITY_EMPTY_DATE) {
		return "";
	}
	return moment(value).format("DD/MM/YYYY");
}

export function columnDateFormatter(param: ValueFormatterParams): string {
	if (param.value && typeof param.value === "string") {
		return formatDate(param.value);
	}
	return "";
}

export function accountStatusFormatter(param: ValueFormatterParams): string {
	return param.value ? "Inactive" : "Active";
}

export function acurityColumnDateFormatter(
	param: ValueFormatterParams
): string {
	if (param.value && typeof param.value === "string") {
		return acurityFormatDate(param.value);
	}
	return "";
}

export function columnDescriptionDateFormatter(
	param: ValueFormatterParams
): string {
	if (param.value && typeof param.value === "string") {
		const dates = param.value.match(DATE_REGEX);
		if (dates) {
			for (const date of dates) {
				param.value = param.value.replace(date, formatDate(date));
			}
		}
		return param.value;
	}
	return "";
}
export function getCurrentDate(): string {
	const timezone = moment.tz.guess();
	return moment(new Date()).tz(timezone).format("DD/MM/YYYY");
}

export function getDateInTimestamp(dateString: string) {
	const timezone = moment.tz.guess();
	const date = moment(dateString).tz(timezone).format();
	return moment(date).format("X");
}

export function getStartDateOfCurrentMonth(): string {
	const timezone = moment.tz.guess();
	return moment().tz(timezone).startOf("month").format("DD/MM/YYYY");
}
export function getStartDateOfCurrentMonthForDatePicker(): string {
	const timezone = moment.tz.guess();
	return moment().tz(timezone).startOf("month").format(DEFAULT_DATE_FORMAT);
}
export function getEndDateOfCurrentMonthForDatePicker(): string {
	const timezone = moment.tz.guess();
	return moment().tz(timezone).endOf("month").format(DEFAULT_DATE_FORMAT);
}

export function getEndDateOfCurrentMonth(): string {
	const timezone = moment.tz.guess();
	return moment().tz(timezone).endOf("month").format("DD/MM/YYYY");
}

export function trimStringPropsForObject(obj: any): any {
	Object.keys(obj).map(
		(k) => (obj[k] = typeof obj[k] === "string" ? obj[k].trim() : obj[k])
	);
	return obj;
}

export function capitalise(str: string): string {
	return str.replace(/\b(?!LWOP)(?!DB)\b\S+/g, ($0, $1) =>
		$1 === 0
			? $0[0].toUpperCase() + $0.slice(1).toLowerCase()
			: $0.toLowerCase()
	);
}
