// Upscope initialise connection - do not alter
export const init = (publicKey) => {
	(function (w, u, d) {
		var i = function () {
			i.c(arguments);
		};
		i.q = [];
		i.c = function (args) {
			i.q.push(args);
		};
		const l = function () {
			const s = d.createElement("script");
			s.type = "text/javascript";
			s.async = true;
			s.src = "https://code.upscope.io/" + publicKey + ".js";
			const x = d.getElementsByTagName("script")[0];
			x.parentNode.insertBefore(s, x);
		};
		if (typeof u !== "function") {
			w.Upscope = i;
			l();
		}
	})(window, window.Upscope, document);

	Upscope("init");
};
