























































































































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import Card from "@/components/Card.vue";
import { RoutePath } from "@/router/routePath";
import ButtonBehaviour from "@/form/ButtonBehaviour.vue";

@Component({
	components: { Card, Layout, ButtonBehaviour },
})
export default class HelpPage extends Vue {
	private sponsorDetails: any = {};

	private get documentationLink() {
		return this.getResolvedRoute(RoutePath.HelpDocumentation);
	}

	private get videosLink() {
		return this.getResolvedRoute(RoutePath.HelpVideos);
	}

	private onReleaseNotesClick() {
		return this.$router.push(RoutePath.ReleaseNotes);
	}

	private getResolvedRoute(href: string): string {
		if (this.$router === undefined) {
			return href;
		}

		const normalizedPath = this.$router.resolve(href, this.$route);
		if (!normalizedPath) {
			return href;
		}
		return normalizedPath.href;
	}

	async mounted() {
		this.sponsorDetails = await import(
			"@/themes/" + process.env.VUE_APP_BRAND + "/constants.json"
		);
	}
}
