import { SelectOption } from "@/form/FieldOptions";

export const stateList: SelectOption[] = [
	{
		label: "Australian Capital Territory",
		value: "ACT",
	},
	{
		label: "New South Wales",
		value: "NSW",
	},
	{
		label: "Northern Territory",
		value: "NT",
	},
	{
		label: "Queensland",
		value: "QLD",
	},
	{
		label: "South Australia",
		value: "SA",
	},
	{
		label: "Tasmania",
		value: "TAS",
	},
	{
		label: "Victoria",
		value: "VIC",
	},
	{
		label: "Western Australia",
		value: "WA",
	},
];

export const IRESS_BRAND_CODE = "IRESS";

export const NO_RC_ERROR_MESSAGE =
	"There are no reporting centres related to the selected employer. Please add a reporting centre to selected employer.";

export const INSIGHT_ONE_LINER_TYPES = [
	{
		label: "Insight",
		value: "INSI",
	},
	{
		label: "Compliance",
		value: "COMPL",
	},
	{
		label: "Employee fraud",
		value: "FRAUD",
	},
];
