
































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import FieldHolder from "@/form/FieldHolder.vue";
import type { CommonField } from "@/form/CommonField";
import { setInputFilter } from "@/utils/InputUtils";

@Component({
	components: {
		FieldHolder,
	},
})
export default class NumberField extends Vue implements CommonField {
	private static uniqueId = 0;

	/**
	 * id must be globally unique in a HTML document, we guarantee this by making each render
	 * of this component have unique ID number appended to the end to avoid clashes.
	 *
	 * We need ids mostly to connect <label> and <input> elements together for accessibility.
	 */
	readonly id = "NumberField_" + ++NumberField.uniqueId;

	@Prop([String]) readonly name!: string;

	@Prop([String]) readonly label!: string;

	@Prop({ type: [String, Number] }) readonly value!: string | number;

	@Prop([String]) readonly rules!: string;

	@Prop(Boolean) readonly readonly!: boolean;

	@Prop(Boolean) readonly disabled!: boolean;

	/**
	 * Add additional custom error messages to this field.
	 */
	@Prop([Array]) readonly errors!: string[];

	/**
	 * Whether errors are shown externally.
	 */
	@Prop(Boolean) readonly errorsShownExternally!: boolean;

	public $refs!: {
		inputEl: HTMLInputElement;
	};

	mounted(): void {
		this.maybeApplyFilter();
	}

	@Watch("readonly")
	onReadonlyChanged(newValue: boolean, oldValue: boolean) {
		if (!newValue) {
			this.maybeApplyFilter();
		}
	}

	/**
	 * maybeApplyFilter is called when we want to attempt to apply our input filtering
	 * logic to the <input> box. We need to check if the inputEl ref exists because if the FieldHolder
	 * is "readonly", then the <input> won't be rendered.
	 */
	private maybeApplyFilter(): void {
		if (!this.$refs.inputEl) {
			return;
		}
		setInputFilter(this.$refs.inputEl, (value) => {
			/// Allow digits only (no "." allowed)
			return /^\d*$/.test(value);
		});
	}

	onInputChange(e: any) {
		let newValue: string | number = e.target.value;
		if (typeof this.value === "number") {
			newValue = Number(e.target.value);
			if (Number.isNaN(newValue)) {
				// Don't update if invalid.
				return;
			}
		}
		this.$emit("input", newValue);
	}
}
