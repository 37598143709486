





















































































































































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import { TSelectLevel } from "@/components/employerSelectorTypes";
import EmployerSelector from "@/components/EmployerSelector.vue";
import SelectField from "@/form/SelectField.vue";
import axios from "@/utils/ApiUtils";
import {
	fundMessageCategories,
	fundMessageConversation,
	fundMessageDone,
	fundMessageFeatureEnabled,
	fundMessageMessage,
	getExtensions,
	messageAttachment,
	postMessageAttachment,
} from "@/constants/apiconstants";
import { SelectOption } from "@/form/FieldOptions";
import TextareaField from "@/form/TextareaField.vue";
import TextField from "@/form/TextField.vue";
import { createNamespacedHelpers } from "vuex";
import { RoutePath } from "@/router/routePath";
import Button from "@/form/Button.vue";
import ModalWithSaveAndCancel from "@/components/ModalWithSaveAndCancel.vue";
import {
	toastErrorMessage,
	toastInfoMessage,
	toastSuccessMessage,
} from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import { AppRoute, AppRouteNextFunction } from "@/router";
import FundMessageThread from "@/components/FundMessageThread.vue";
import * as FileSaver from "file-saver";
import FileUpload from "@/form/FileUpload.vue";
import ProgressBar from "@/components/ProgressBar.vue";

const { mapState, mapMutations } = createNamespacedHelpers("persistent");

export interface AttachmentPreChecks {
	allowedAttachments: string;
	maxFileSize: number;
}

export interface FundMessageCategory {
	id: number;
	category: string;
	code: string;
}

export interface Attachment {
	id: number;
	originalFileName: string;
	messageId: number | null;
	mediaType: string;
	createdDate: string;
}

export interface MessageDTO {
	id: number | null;
	conversationId: number | null;
	sentFrom: string;
	createdDate: string;
	assigned: string;
	details: string;
	attachments: Attachment[];
	sentByName: string;
}

export interface ConversationDTO {
	id: number | null;
	sponsorId: number | null; //can be derived from employer/parent or rcId
	employerId: number | null;
	parentId: number | null;
	rcId: number | null;
	category: string; // 3-letter code
	message: string;
	done: boolean;
	messages: MessageDTO[];
}

interface FundMessageBundle {
	createMode: boolean;
	readOnly: boolean;
	conversationDTO: ConversationDTO;
	fundMessageCategoryOptions: SelectOption[];
}

@Component({
	components: {
		ProgressBar,
		FileUpload,
		Layout,
		EmployerSelector,
		SelectField,
		TextareaField,
		TextField,
		Button,
		ModalWithSaveAndCancel,
		FundMessageThread,
	},
	computed: mapState([
		"selectedEntities",
		"employerHierarchy",
		"iressOperations",
		"sponsorPermissions",
		"definedBenefitEntities",
	]),
	methods: {
		...mapMutations(["setSelectedEntities"]),
	},
})
export default class FundMessagePage extends Vue {
	private readonly pageContext: TSelectLevel = "ALL";
	private errorMessage: string | null = null;
	private fundMessageCategoryOptions: SelectOption[] = [];
	private readonly assigneeOptions: SelectOption[] = [
		{
			value: "F",
			label: "Fund",
		},
		{
			value: "E",
			label: "Employer",
		},
	];

	private conversationDTO: ConversationDTO = {
		id: null,
		sponsorId: null,
		employerId: null,
		parentId: null,
		rcId: null,
		category: "",
		done: false,
		message: "",
		messages: [],
	};
	private messageDTO: MessageDTO = {
		id: null,
		conversationId: null,
		sentFrom: "",
		createdDate: "",
		assigned: "",
		details: "",
		attachments: [],
		sentByName: "",
	};

	private readonly files: File[] = [];
	private progress: number | null = null;
	private readonly accept = "";

	private showSubmitConfirmation = false;
	private showBackConfirmation = false;
	private showSubmitReplyConfirmation = false;
	private showMarkAsDoneConfirmation = false;
	private showReopenConfirmation = false;

	private readOnly = false;
	private createMode = false;

	private downloading = false;

	setSelectedEntities!: (selectedEntities: string[]) => void;

	/**
	 * It is a computed property. Do not mutate it.
	 */
	iressOperations!: number | null;
	sponsorPermissions!: [] | null;

	private errors: string[] = [];

	definedBenefitEntities!: string[];

	async beforeRouteEnter(
		to: AppRoute,
		from: AppRoute,
		next: AppRouteNextFunction<FundMessagePage>
	) {
		let createMode = false;
		let readOnly = true;
		let conversationDTO: ConversationDTO | null = null;
		let fundMessageCategoryOptions: SelectOption[];

		const { createView, id } = to.params;
		if (createView === "create") {
			createMode = true;
			readOnly = false;
			conversationDTO = {
				id: 0,
				sponsorId: null,
				employerId: null,
				parentId: null,
				rcId: null,
				category: "",
				done: false,
				messages: [],
				message: "",
			};
		} else if (createView === "view") {
			createMode = false;
			readOnly = false;

			await axios
				.get<ConversationDTO>(
					`${fundMessageConversation()}WithAttachments?conversationId=${id}&includeAttachments=true`
				)
				.then((resp) => {
					conversationDTO = resp.data;
					readOnly = conversationDTO.done;
				})
				.catch((err) => {
					toastErrorMessage(parseErrorMessage(err));
				});
		}

		await axios
			.get<FundMessageCategory[]>(fundMessageCategories())
			.then((resp) => {
				fundMessageCategoryOptions = resp.data.map((fmc) => ({
					value: fmc.code,
					label: fmc.category,
				}));
			});
		next((vm) => {
			if (conversationDTO) {
				vm.setResponse({
					createMode: createMode,
					readOnly: readOnly,
					conversationDTO: conversationDTO,
					fundMessageCategoryOptions: fundMessageCategoryOptions,
				});
			}
		});
	}

	get hasError(): boolean {
		return this.errors && this.errors.length > 0;
	}

	private setResponse(data: FundMessageBundle) {
		this.createMode = data.createMode;
		this.readOnly = data.readOnly;
		this.conversationDTO = data.conversationDTO;
		this.fundMessageCategoryOptions = data.fundMessageCategoryOptions;

		//Set employer selector
		if (this.conversationDTO.rcId) {
			this.setSelectedEntities(["R_" + this.conversationDTO.rcId]);
		} else if (this.conversationDTO.employerId) {
			this.setSelectedEntities(["E_" + this.conversationDTO.employerId]);
		} else if (this.conversationDTO.parentId) {
			this.setSelectedEntities(["P_" + this.conversationDTO.parentId]);
		}

		//Initialise message
		this.messageDTO = {
			id: null,
			conversationId: this.createMode
				? null
				: Number(this.conversationDTO.id),
			sentFrom: "",
			createdDate: "",
			assigned: "",
			details: "",

			attachments: [],
			sentByName: "",
		};

		//Set default values
		let assignedTo: string;
		let sentFrom: string;
		if (this.iressOperations) {
			assignedTo = "";
			sentFrom = "F";
		} else if (this.sponsorPermissions?.length !== 0) {
			assignedTo = "E";
			sentFrom = "F";
		} else {
			assignedTo = "F";
			sentFrom = "E";
		}
		this.messageDTO.sentFrom = sentFrom;

		if (this.createMode) {
			this.messageDTO.assigned = assignedTo;
		} else {
			this.messageDTO.assigned =
				this.conversationDTO.messages[0].assigned;
		}
	}

	private clearErrorMessage(): void {
		this.errorMessage = null;
	}

	private setConversationEntityId(): void {
		if (this.conversationDTO) {
			this.conversationDTO.parentId = null;
			this.conversationDTO.employerId = null;
			this.conversationDTO.rcId = null;

			const selectedEntity = this.selectedReportingCenter[0];
			if (selectedEntity.startsWith("P_")) {
				this.conversationDTO.parentId = selectedEntity.substr(2);
			} else if (selectedEntity.startsWith("E_")) {
				this.conversationDTO.employerId = selectedEntity.substr(2);
			} else if (selectedEntity.startsWith("R_")) {
				this.conversationDTO.rcId = selectedEntity.substr(2);
			}
		}
	}

	@Watch("selectedReportingCenter", { immediate: true })
	private updateError(): void {
		if (this.selectedReportingCenter.length === 0) {
			this.errorMessage =
				"Please select a Parent organisation, an Employer or a Reporting centre";
			return;
		}

		axios
			.get<boolean>(
				fundMessageFeatureEnabled(this.selectedReportingCenter[0])
			)
			.then((resp) => {
				if (!resp.data) {
					this.errorMessage =
						"Fund message is not allowed for selected organisation";
				}
			});
	}

	get selectedReportingCenter() {
		return this.$store.state.persistent.selectedEntities;
	}

	backConfirmation(changed: boolean) {
		if (changed) {
			this.showBackConfirmation = true;
		} else {
			this.back();
		}
	}

	back() {
		this.$router.push(RoutePath.Alerts);
	}

	submit() {
		if (this.conversationDTO && this.messageDTO) {
			this.conversationDTO.messages = [];
			this.conversationDTO.messages.push(this.messageDTO);

			this.setConversationEntityId();
			axios
				.post(fundMessageConversation(), this.conversationDTO, {
					headers: {
						"Content-Type": "application/json",
					},
				})
				.then(() => {
					toastInfoMessage("Message submitted.");
					this.$router.push(RoutePath.Alerts);
				})
				.catch((err) => {
					toastErrorMessage(parseErrorMessage(err));
					this.showSubmitConfirmation = false;
				});
		}
	}

	@Watch("files")
	onFileChange() {
		//clear existing errors on adding a new file.
		this.errors = [];
		this.progress = null;
		if (this.files.length > 0) {
			axios
				.get(
					getExtensions() +
						"?entity=" +
						this.$store.state.persistent.selectedEntities[0]
				)
				.then((response) => {
					const preChecks = response.data as AttachmentPreChecks;
					if (this.files[0].size > preChecks.maxFileSize) {
						this.errors.push("File size is too large.");
						return;
					}
					if (preChecks.allowedAttachments == ".") {
						this.errors.push(
							"No allowed file extensions found this organisation."
						);
						return;
					} else {
						const allowedExtensions =
							preChecks.allowedAttachments.split(",");
						const fileExtension = this.files[0].name.substring(
							this.files[0].name.lastIndexOf(".") + 1
						);
						if (!allowedExtensions.includes(fileExtension)) {
							this.errors.push(
								"File extension not allowed. Allowed extensions are: " +
									allowedExtensions.join(", ")
							);
							return;
						}
					}
					// upload file, set response to fileId and emit fileId
					const fd = new FormData();
					fd.append("Attachment", this.files[0]);
					this.progress = 0;
					axios
						.post<number>(
							postMessageAttachment(
								this.$store.state.persistent.selectedEntities[0]
							),
							fd,
							{
								headers: {
									"Content-Type": "multipart/form-data",
								},
								onUploadProgress: this.onUploadProgress,
							}
						)
						.then((resp) => {
							// Currently we only allow one attachment. So force a singleton array of the most recent attachment
							const attachment: Attachment = {
								id: resp.data,
								originalFileName: this.files[0].name,
								messageId: null,
								mediaType: this.files[0].type,
								createdDate: new Date().toISOString(),
							};

							this.messageDTO.attachments = [attachment];
						})
						.catch((error) => {
							this.errors.push(parseErrorMessage(error));
						})
						.finally(() => {
							this.progress = 1;
							setInterval(() => {
								this.progress = null;
							}, 500);
						});
				});
		} else {
			// attachments have been cleared in the UI. Clear the DTO
			this.messageDTO.attachments = [];
		}
	}

	onUploadProgress = (progressEvent: ProgressEvent): void => {
		this.progress = progressEvent.loaded / progressEvent.total;
	};

	async onFileDownload(attachment: Attachment) {
		const attachmentId = attachment.id;
		this.downloading = true;
		await axios
			.get(messageAttachment() + "?attachmentId=" + attachmentId, {
				responseType: "blob",
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((response) => {
				const data: Blob = response.data;
				FileSaver.saveAs(data, attachment.originalFileName, {
					autoBom: false,
				});
				toastSuccessMessage("File download initiated");
				this.downloading = false;
			})
			.catch((error) => {
				this.errors = error;
			});
	}

	submitReply() {
		if (this.messageDTO) {
			axios
				.post(fundMessageMessage(), this.messageDTO, {
					headers: {
						"Content-Type": "application/json",
					},
				})
				.then(() => {
					toastInfoMessage("Reply submitted.");
					this.$router.push(RoutePath.Alerts);
				})
				.catch((err) => {
					toastErrorMessage(parseErrorMessage(err));
					this.showSubmitReplyConfirmation = false;
				});
		}
	}

	reopen() {
		if (this.conversationDTO && this.conversationDTO.id) {
			const id = this.conversationDTO.id;

			axios
				.post(`${fundMessageDone()}?done=false&conversationId=${id}`)
				.then(() => {
					toastInfoMessage("Message reopened.");
					this.showReopenConfirmation = false;
					this.refresh(id);
				})
				.catch((err) => {
					toastErrorMessage(parseErrorMessage(err));
					this.showReopenConfirmation = false;
				});
		}
	}

	refresh(id: number) {
		axios
			.get<ConversationDTO>(
				`${fundMessageConversation()}?conversationId=${id}`
			)
			.then((resp) => {
				this.setResponse({
					createMode: false,
					readOnly: resp.data.done,
					conversationDTO: resp.data,
					fundMessageCategoryOptions: this.fundMessageCategoryOptions,
				});
			})
			.catch((err) => {
				toastErrorMessage(parseErrorMessage(err));
			});
	}

	complete() {
		if (this.conversationDTO) {
			axios
				.post(
					`${fundMessageDone()}?done=true&conversationId=${
						this.conversationDTO.id
					}`
				)
				.then(() => {
					toastInfoMessage("Message completed.");
					this.$router.push(RoutePath.Alerts);
				})
				.catch((err) => {
					toastErrorMessage(parseErrorMessage(err));
					this.showMarkAsDoneConfirmation = false;
				});
		}
	}
}
