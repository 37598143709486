











































import { Component } from "vue-property-decorator";
import TextField from "@/form/TextField.vue";
import TableForm from "@/components/TableForm.vue";
import BaseRegistrationOfInterestStep from "./BaseRegistrationOfInterestStep.vue";
import { createNamespacedHelpers } from "vuex";

const { mapState } = createNamespacedHelpers("registrationOfInterest");

@Component({
	components: {
		TableForm,
		TextField,
	},
	computed: {
		...mapState([
			"refundAccountBsb",
			"refundAccountNumber",
			"refundAccountName",
			"refundAccountsForOutlets",
		]),
	},
})
export default class RefundAccountStep extends BaseRegistrationOfInterestStep {
	refundAccountBsb!: string;
	refundAccountNumber!: string;
	refundAccountName!: string;
}
