












































import Vue from "vue";
import { Component } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import axios, { axiosStatic } from "@/utils/ApiUtils";
import { toastErrorMessage, toastSuccessMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import {
	fileFormatMappingById,
	fileFormatMappingsAll,
} from "@/constants/apiconstants";
import Grid from "@/grid/Grid.vue";
import Button from "@/form/Button.vue";
import {
	ColDef,
	ColGroupDef,
	IServerSideDatasourceTyped,
	IServerSideGetRowsParamsTyped,
	ICellRendererParams,
} from "ag-grid-community";
import GridActionsRenderer from "@/grid/GridActionsRenderer.vue";
import { hasPermission } from "@/utils/PermissionUtils";
import { FileFormat } from "./FileFormatMapping";
import ModalOrPopup from "@/components/ModalOrPopup.vue";
import { PagedResult } from "@/grid/gridTypes";

@Component({
	components: { Layout, Grid, Button, ModalOrPopup },
})
export default class FileMappingPage
	extends Vue
	implements IServerSideDatasourceTyped
{
	private fileFormats: FileFormat[] = [];

	public $refs!: {
		gridEl: Grid;
	};

	private gridVMList: Vue[] = [];
	private gridReady = false;
	private deleteConfirmation = false;
	private rowForDeletion: FileFormat | null = null;

	private editAllowed = hasPermission("EDIT_FILE_MAPPING");

	private readonly columnDefs: (ColGroupDef | ColDef)[] = [
		{
			headerName: "ID",
			field: "id",
			width: 60,
			resizable: true,
			hide: true,
		},
		{
			headerName: "File format name",
			field: "fileFormatName",
			width: 500,
			resizable: true,
		},
		{
			headerName: "Number of rows to skip",
			field: "numRowSkip",
			width: 100,
			resizable: true,
			hide: true,
		},
		{
			headerName: "Row identifier",
			field: "rowIdentifier",
			width: 100,
			resizable: true,
			hide: true,
		},
		{
			headerName: "Actions",
			field: "__Actions",
			cellRenderer: this.actionsRender,
			width: 60,
		},
	];

	actionsRender(params: ICellRendererParams): HTMLElement {
		const edit = this.editAllowed;
		const vm = new Vue({
			el: document.createElement("div"),

			render: (createElement) => {
				return createElement(GridActionsRenderer, {
					props: {
						rowIndex: params.rowIndex,
						row: params.data,
						isEdit: edit,
						isView: !edit,
						isDelete: edit,
					},
					on: {
						clickEdit: this.viewDetails,
						clickView: this.viewDetails,
						clickDelete: this.onDelete,
					},
				});
			},
		});
		this.gridVMList.push(vm);
		return vm.$el as HTMLElement;
	}

	viewDetails({ row }: { row: FileFormat }) {
		this.$router.push({
			name: this.editAllowed
				? "Configure file mapping details"
				: "View file mapping details",
			params: {
				fileFormatId: String(row.id),
			},
		});
	}

	onDelete({ row }: { row: FileFormat }) {
		this.deleteConfirmation = true;
		this.rowForDeletion = row;
	}

	private onGridReady() {
		this.gridReady = true;
	}

	private onAdd() {
		this.$router.push({
			name: "Configure file mapping details",
			params: {
				fileFormatId: String(0),
			},
		});
	}

	private deleteFileFormat() {
		if (this.rowForDeletion) {
			this.deleteConfirmation = false;
			axios
				.delete(fileFormatMappingById(this.rowForDeletion?.id))
				.then((resp) => {
					this.$refs.gridEl.reload();
					toastSuccessMessage("File format deleted successfully");
				})
				.catch((error) => {
					toastErrorMessage(parseErrorMessage(error));
				});
		}
	}

	getRows(params: IServerSideGetRowsParamsTyped<FileFormat>): void {
		axios
			.get<PagedResult<FileFormat>>(fileFormatMappingsAll(), {
				params: {
					// Pass in <Grid> parameters
					grid: params.request,
				},
				cancelToken: params.cancelToken,
			})
			.then((response) => {
				params.successCallback(response.data);
			})
			.catch((error) => {
				if (axiosStatic.isCancel(error)) {
					return;
				}
				toastErrorMessage(parseErrorMessage(error));
			});
	}
}
