








































































































































































































































































































































































































































































































































































































































































































































import {
	capitalize,
	cloneDeep,
	isEmpty,
	isInteger,
	map,
	pickBy,
} from "lodash-es";
import AddFundForm from "@/pages/employee/AddFundForm.vue";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import EmployerSelector from "@/components/EmployerSelector.vue";
import {
	EmployeeeDefinedBenefitField,
	EmployeeFundDetails,
	FormFieldMap,
	FormStage,
	ReportingCentreABN,
	EmployeeAndFunds,
	DbTabDetails,
} from "@/models/EmployeeRow";
import PageHeader from "@/components/PageHeader.vue";
import Container from "@/components/Container.vue";
import AutoField from "@/form/AutoField.vue";
import TextField from "@/form/TextField.vue";
import Form from "@/form/Form.vue";
import DatepickerField from "@/form/DatepickerField.vue";
import FieldGroup from "@/form/FieldGroup.vue";
import Accordion from "@/components/Accordion.vue";
import LeftRightFooter from "@/components/LeftRightFooter.vue";
import { AgGridVue } from "ag-grid-vue";
import ContributionSummaryComponent from "@/pages/contribution/ContributionSummaryComponent.vue";
import Button from "@/form/Button.vue";
import EmployeeForm from "@/pages/contribution/EmployeeForm.vue";
import { RoutePath } from "@/router/routePath";
import SearchFund from "@/components/SearchFund.vue";
import axios from "@/utils/ApiUtils";
import { FundType, SearchFundDetails } from "@/pages/fundTypes";
import {
	definedBenefitTabDataForEmployee,
	employeeDetails,
	getFundFieldMappingUrlByRcAndUsi,
	reportingCentreDetailsURL,
} from "@/constants/apiconstants";
import GridLoadingOverlay from "@/grid/GridLoadingOverlay.vue";
import { TSelectLevel } from "@/components/employerSelectorTypes";
import {
	toastErrorMessage,
	toastInfoMessage,
	toastSuccessMessage,
} from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import ValidationObserverNotifier from "@/components/ValidationObserverNotifier.vue";
import { ValidationObserver, VForm } from "@/@typings/type-vee-validate";
import Modal from "@/components/Modal.vue";
import { capitalise } from "@/utils/CommonUtils";
import Grid from "@/grid/Grid.vue";
import { ColDef, ColGroupDef, ICellRendererParams } from "ag-grid-community";
import GridActionsRenderer from "@/grid/GridActionsRenderer.vue";
import AlertInfo from "@/components/AlertInfo.vue";

import { AddressDetails, FundFieldMapping } from "@/models/ContributionRow";
import { clone, each } from "lodash";
import { computeLabel } from "@/form/FormUtils";
import AddressForm from "@/components/AddressForm.vue";
import SelectField from "@/form/SelectField.vue";
import { AppRoute, AppRouteNextFunction } from "@/router";
import { stateList } from "@/constants/constants";
import { hasPermission } from "@/utils/PermissionUtils";
import { acurityColumnDateFormatter } from "@/utils/CommonUtils";
import moment from "moment";
import GridErrorWarningRenderer from "@/grid/GridErrorWarningRenderer.vue";
import ErrorList from "@/components/ErrorList.vue";
import FundFieldMappingField from "@/components/FundFieldMappingField.vue";
import DynamicGridTab, {
	DynamicGridTabProps,
} from "@/components/CustomGridTab.vue";
import CustomMultiTab, {
	MultiTabProps,
} from "@/components/CustomTabContainer.vue";

type ErrorArray = string[];

interface ErrorMap {
	[fieldName: string]: ErrorArray;
}

type Mode = "add" | "edit" | "view";
type ModeText = "Add" | "Edit" | "View";

// Note (Ray): I hope you see how awkward this EmployeeFundDetails type is.
// To properly provide an empty value, lots of redundant and useless attributes need to
// be listed while they do absolutely nothing.
// A good first step of refactoring is unifying APRA and SMSF fund attribute names.
const emptyEmployeeFund: EmployeeFundDetails = {
	accountName: "",
	accountNumber: "",
	active: null,
	bsb: "",
	employeeFundId: undefined,
	esa: "",
	fundAbn: "",
	fundFen: "",
	fundName: "",
	fundPayeeCode: "",
	fundRegistrationDate: null,
	fundStatus: "",
	fundTerminationDate: null,
	fundType: "",
	fundUsi: "",
	memberBenefitCategory: null,
	memberClientIdentifier: null,
	payeeCode: "",
	name: "",
	abn: "",
	lastLookupDate: "",
	status: "",
	complyingStatus: "",
	smsfAliasMap: {},
	fen: "",
	defaultFundFlag: false,
	id: -1,
	fundAccountName: "",
	fundAccountNumber: "",
	fundBsb: "",
	fundElectronicServiceAddress: "",
	employeeDefinedBenefitFields: [],
};

const fieldNamesGroupedByTab: string[][] = [
	[
		"title",
		"givenNames",
		"surname",
		"suffix",
		"gender",
		"dateOfBirth",
		"taxFileNo",
		"email",
		"landline",
		"mobile",
	],
	[
		"addressType",
		"countryCode",
		"addressLine1",
		"addressLine2",
		"addressLine3",
		"addressLine4",
		"locality",
		"postcode",
		"state",
	],
	[],
	["funds", "smsfTermination"],
	[
		"employmentStartDate",
		"occupationDescription",
		"employmentStatus",
		"atWorkIndicator",
		"weeklyHoursWorked",
		"terminationDate",
		"terminationReason",
		"annualSalaryBenefits",
		"annualSalaryContributions",
		"annualSalaryInsurance",
		"annualSalaryContributionsStartDate",
		"annualSalaryContributionsEndDate",
		"insuranceOptOut",
		"superContributionCommencementDate",
		"superContributionCeaseDate",
		"registrationAmendmentReason",
	],
];

@Component({
	methods: { definedBenefitTabDataForEmployee },
	components: {
		CustomMultiTab,
		CustomGridTab: DynamicGridTab,
		SelectField,
		AddFundForm,
		Layout,
		AgGridVue,
		PageHeader,
		ContributionSummaryComponent,
		DatepickerField,
		Accordion,
		Button,
		FieldGroup,
		TextField,
		AutoField,
		GridLoadingOverlay,
		Form,
		LeftRightFooter,
		Container,
		EmployeeForm,
		EmployerSelector,
		SearchFund,
		ValidationObserverNotifier,
		Modal,
		Grid,
		GridActionsRenderer,
		AlertInfo,
		AddressForm,
		ErrorList,
		FundFieldMappingField,
	},
})
export default class AddEmployee extends Vue {
	@Prop([String]) readonly name!: string;
	@Prop([String]) readonly label!: string;

	/**
	 * route params prop
	 */
	@Prop(String) mode!: Mode;

	/**
	 * route params prop, only used when mode is edit
	 */
	@Prop(Number) employeeId!: number;

	/**
	 * route params prop, only used when mode is edit
	 */
	@Prop(Number) reportingCentreId!: number;

	@Prop(String) definedBenefitMode!: string;

	tfnRules = {
		tfn: true,
		byFunction: [this.validateTfnOrPayrollNo, "payrollNo"],
	};
	payrollNoRules = {
		standardText: true,
		max: 15,
		required: this.mode === "edit",
		byFunction: this.validateTfnOrPayrollNo,
	};

	public static readonly SMSF_NON_COMPLYING_STATUS = "Non-complying";
	public static readonly GENERATED_PAYROLL_PREFIX = "SCCH";

	public $refs!: {
		gridEl: [Grid];
		addFundFormValidation: ValidationObserver;
		employeeForm: VForm;
		[index: number]: ValidationObserver;
	};

	private pageContext: TSelectLevel = "RC";
	private employeeFund: EmployeeFundDetails = cloneDeep(emptyEmployeeFund);
	private dbFund: string | undefined = undefined;
	private employeeDefinedBenefitFields: EmployeeeDefinedBenefitField[] = [];
	private activeTabIndex = 0;

	private hasFundError = false;

	private acurityDbFields: string[] = [];
	private customGridTabs: DynamicGridTabProps[] = [];
	private readonly customMultiTabs: any[] = [];

	private readonly columnDefs: (ColGroupDef | ColDef)[] = [
		{
			colId: "notification",
			cellClass: "grid-fund-error-column",
			cellRenderer: (params: ICellRendererParams) => {
				const vm = new Vue({
					el: document.createElement("div"),
					render: (createElement) => {
						const withError = !this.validateFundTerminationDate(
							params.data
						);
						return createElement(GridErrorWarningRenderer, {
							props: {
								rowIndex: params.rowIndex,
								row: params.data,
								withError: withError,
							},
						});
					},
				});
				this.gridVMList.push(vm);
				return vm.$el as HTMLElement;
			},
			resizable: true,
			maxWidth: 45,
			hide: true,
		},
		{
			headerName: "Start date",
			field: "fundRegistrationDate",
			resizable: true,
			width: 150,
			valueFormatter: acurityColumnDateFormatter,
		},
		{
			headerName: "End date",
			field: "fundTerminationDate",
			resizable: true,
			width: 150,
			valueFormatter: acurityColumnDateFormatter,
		},
		{
			headerName: "Fund name",
			field: "fundName",
			resizable: true,
			width: 290,
		},
		{
			headerName: "USI",
			field: "fundUsi",
			resizable: true,
			width: 200,
		},
		{
			headerName: "ABN",
			field: "fundAbn",
			resizable: true,
			width: 180,
		},
		{
			headerName: "Type",
			field: "fundType",
			resizable: false,
			flex: 2,
			width: 100,
		},
	];
	private gridVMList: Vue[] = [];
	private selectedRowIdx: number | null = null;

	private loadingRcDetails = false;
	private loadingEmployeeDetails = false;

	get editSensitivePermission() {
		return this.hasPermission("EDIT_EMPLOYEES_SENSITIVE");
	}

	get employeeNameAndPayroll(): string {
		let employeeNameAndPayroll = "";
		if (this.recordFormData?.employee?.surname != null) {
			employeeNameAndPayroll = `${
				this.recordFormData?.employee?.givenNames != null
					? this.recordFormData?.employee?.givenNames
					: ""
			} ${
				this.recordFormData?.employee?.surname != null
					? this.recordFormData?.employee?.surname
					: ""
			} ${
				this.originalPayrollNo != null
					? "- " + this.originalPayrollNo
					: ""
			}`;
		}
		return employeeNameAndPayroll;
	}

	private readonly formSchema: FormStage[] = [
		{
			header: "Personal and contact",
			subSections: [
				{
					name: "Personal details",
					value: [
						["title", "givenNames", "surname", "suffix"],
						["gender", "dateOfBirth"],
						["taxFileNo", "inactive"],
					],
					icon: ["fal", "user-alt"],
				},
				{
					name: "Contact details",
					value: [["email", "landline", "mobile"]],
					icon: ["fal", "address-card"],
				},
			],
		},
		{
			header: "Address",
			subSections: [
				{
					name: "Address details",
					icon: ["fal", "address-card"],
				},
			],
		},
		{
			header: "Employer",
			subSections: [
				{
					name: "Employer details",
					value: [],
					icon: ["fal", "landmark"],
				},
			],
		},
		{
			header: "Fund",
			subSections: [
				{
					name: "Fund details",
					value: [
						["fundRegistrationDate", "fundTerminationDate"],
						["memberClientIdentifier", "memberBenefitCategory"],
					],
					icon: ["fal", "hand-holding-usd"],
				},
			],
			displayIf: {
				fundRegistrationDate: "fundType:APRA,SMSF",
				fundTerminationDate: "fundType:APRA,SMSF",
				memberClientIdentifier: "fundType:APRA",
				memberBenefitCategory: "fundType:APRA",
			},
			checkHiddenFields: ["fundType", "fundName"],
		},
		{
			header: "Employment",
			subSections: [
				{
					name: "Employment details",
					value: [
						[
							"employmentStartDate",
							"occupationDescription",
							"weeklyHoursWorked",
						],
						["employmentStatus", "atWorkIndicator"],
						["terminationDate", "terminationReason"],
					],
					icon: ["fal", "briefcase"],
				},
				{
					name: "Annual salary details",
					value: [
						[
							"annualSalaryBenefits",
							"annualSalaryContributions",
							"annualSalaryInsurance",
						],
						[
							"annualSalaryContributionsStartDate",
							"annualSalaryContributionsEndDate",
							"insuranceOptOut",
						],
					],
					icon: ["fal", "money-bill"],
				},
				{
					name: "Super contribution details",
					value: [
						[
							"superContributionCommencementDate",
							"superContributionCeaseDate",
						],
						["registrationAmendmentReason"],
					],
					icon: ["fal", "hand-holding-usd"],
				},
			],
		},
		{
			// index 5
			header: "DB registration",
			subSections: [
				{
					name: "DB registration details",
					value: [],
					icon: ["fal", "tasks"],
				},
			],
		},
		{
			// index 6
			header: "Additional",
			subSections: [
				{
					name: "Additional details",
					value: [],
					icon: ["fal", "tasks"],
				},
			],
		},
	];

	private showCustomTab(
		idx: number,
		dbRego: boolean,
		additional: boolean
	): string {
		if (idx === 5) {
			return this.dbFund && dbRego ? "" : "d-none";
		}
		if (idx === 6) {
			return this.dbFund && additional ? "" : "d-none";
		}

		//Accum tabs
		return "";
	}

	private formValidationErrorMap: ErrorMap = {};

	/**
	 * editFieldMap maps field names to their properties. We receive this value from
	 * the backend and apply it to our <Form> component, which applies it down to each
	 * field.
	 */
	private editFieldMap: FormFieldMap = {
		employerPayrollName: {
			name: "employerPayrollName",
			type: "TextField",
			readonly: true,
		},
		employerPayrollLocation: {
			name: "employerPayrollLocation",
			type: "TextField",
			readonly: true,
		},
		spouseContributions: {
			name: "spouseContributions",
			label: "Spouse",
			type: "CurrencyField",
		},
		employerContributionsSalarySacrificed: {
			name: "employerContributionsSalarySacrificed",
			label: "Salary sacrifice",
			type: "CurrencyField",
		},
		superContributionCeaseDate: {
			name: "superContributionCeaseDate",
			label: "Super contribution cease date",
			type: "DateField",
			rules: "datesCompare:@superContributionCommencementDate,sameOrAfter,Super contribution commencement date",
		},
		occupationDescription: {
			name: "occupationDescription",
			type: "TextField",
			rules: "max:80|validateAllowedCharacters",
		},
		annualSalaryContributionsStartDate: {
			name: "annualSalaryContributionsStartDate",
			label: "Annual salary for contributions effective start date",
			type: "DateField",
			rules:
				"datesCompare:@employmentStartDate,sameOrAfter,Employment start date," +
				"@annualSalaryContributionsEndDate,sameOrBefore,Annual salary for contributions effective end date",
		},
		employerContributionsVoluntary: {
			name: "employerContributionsVoluntary",
			type: "CurrencyField",
		},
		employmentStatus: {
			name: "employmentStatus",
			options: [
				{
					label: "Casual",
					value: "Casual",
				},
				{
					label: "Contractor",
					value: "Contractor",
				},
				{
					label: "Full time",
					value: "Full time",
				},
				{
					label: "Part time",
					value: "Part time",
				},
			],
			allowEmptyValue: true,
			type: "SelectField",
		},
		fundAccountNumber: {
			name: "fundAccountNumber",
			label: "Account number",
			type: "TextField",
			rules: "required_if:fundType,SMSF",
		},
		fundFen: {
			name: "fundFen",
			label: "FEN",
			type: "TextField",
		},
		terminationDate: {
			name: "terminationDate",
			type: "DateField",
			rules: "bothOrNothing:@terminationReason|datesCompare:@employmentStartDate,sameOrAfter,Employment start date",
		},
		reportingCentreName: {
			name: "reportingCentreName",
			type: "TextField",
			readonly: true,
		},
		abn: {
			name: "abn",
			type: "TextField",
			readonly: true,
		},
		reportingCentreAbn: {
			name: "reportingCentreAbn",
			label: "ABN",
			type: "TextField",
			readonly: true,
		},
		insuranceOptOut: {
			name: "insuranceOptOut",
			label: "Insurance opt out indicator",
			options: [
				{
					label: "false",
					value: "false",
				},
				{
					label: "true",
					value: "true",
				},
			],
			allowEmptyValue: true,
			type: "SelectField",
		},
		addressLine1: {
			name: "addressLine1",
			type: "TextField",
			rules: "required|max:50|standardText",
		},
		fundUsi: {
			name: "fundUsi",
			label: "USI",
			type: "TextField",
		},
		addressLine2: {
			name: "addressLine2",
			type: "TextField",
			rules: "max:50|containsInvalidText:C/-,true|standardText",
		},
		id: {
			name: "id",
			type: "NumberField",
			rules: "numeric",
		},
		addressLine3: {
			name: "addressLine3",
			type: "TextField",
			rules: "max:50|containsInvalidText:C/-,true|standardText",
		},
		addressLine4: {
			name: "addressLine4",
			type: "TextField",
			rules: "max:50|containsInvalidText:C/-,true|standardText",
		},
		state: {
			name: "state",
			options: stateList,
			allowEmptyValue: false,
			type: "SelectField",
			rules: "required_if:countryCode,AU",
		},
		terminationReason: {
			name: "terminationReason",
			type: "TextField",
			rules: "bothOrNothing:@terminationDate|max:50|validateAllowedCharacters",
		},
		childContributions: {
			name: "childContributions",
			label: "Child",
			type: "CurrencyField",
		},
		otherThirdPartyContributions: {
			name: "otherThirdPartyContributions",
			label: "Other 3rd party",
			type: "CurrencyField",
		},
		registrationAmendmentReason: {
			name: "registrationAmendmentReason",
			label: "Member registration amendment reason",
			type: "TextareaField",
			rules: "max:500|validateAllowedCharacters",
		},
		addressType: {
			name: "addressType",
			options: [
				{
					label: "Residential",
					value: "R",
				},
				{
					label: "Postal",
					value: "P",
				},
			],
			allowEmptyValue: false,
			type: "SelectField",
			rules: "required",
		},
		givenNames: {
			name: "givenNames",
			label: "Given name",
			type: "TextField",
			rules: "required|standardText|max:40",
		},
		suffix: {
			name: "suffix",
			label: "Suffix",
			type: "TextField",
			rules: "max:5|validateAllowedCharacters",
		},
		locality: {
			name: "locality",
			label: "Suburb",
			type: "TextField",
			rules: "required_if:countryCode,AU|validateAllowedCharacters",
		},
		postcode: {
			name: "postcode",
			type: "NumberField",
			rules: "required_if:countryCode,AU|digits:4",
		},
		fundRegistrationDate: {
			name: "fundRegistrationDate",
			type: "DateField",
		},
		fundTerminationDate: {
			name: "fundTerminationDate",
			type: "DateField",
		},
		payrollNo: {
			name: "payrollNo",
			label: "Payroll number",
			type: "TextField",
			rules: this.payrollNoRules,
		},
		fileRegRef: {
			name: "fileRegRef",
			options: [
				{
					label: "1",
					value: "2332",
				},
			],
			allowEmptyValue: false,
			type: "SelectField",
		},
		employmentStartDate: {
			name: "employmentStartDate",
			type: "DateField",
			rules: "datesCompare:@annualSalaryContributionsStartDate,sameOrBefore,Annual salary for contributions effective start date,@annualSalaryContributionsEndDate,sameOrBefore,Annual salary for contributions effective end date,@terminationDate,sameOrBefore,Termination date",
		},
		fundBsb: {
			name: "fundBsb",
			label: "BSB",
			type: "TextField",
			rules: "required_if:fundType,SMSF",
		},
		atWorkIndicator: {
			name: "atWorkIndicator",
			options: [
				{
					label: "false",
					value: "false",
				},
				{
					label: "true",
					value: "true",
				},
			],
			allowEmptyValue: true,
			type: "SelectField",
		},
		annualSalaryInsurance: {
			name: "annualSalaryInsurance",
			label: "Insurance amount",
			type: "CurrencyField",
			rules: "min_value:0|max_value:99999999999.99",
		},
		payroll: {
			name: "payroll",
			type: "TextField",
		},
		fundName: {
			name: "fundName",
			type: "TextField",
			rules: "required",
		},
		fundType: {
			name: "fundType",
			type: "TextField",
			rules: "required",
		},
		superContributionCommencementDate: {
			name: "superContributionCommencementDate",
			label: "Super contribution commencement date",
			type: "DateField",
			rules: "datesCompare:@superContributionCeaseDate,sameOrBefore,Super contribution cease date",
		},
		gender: {
			name: "gender",
			options: [
				{
					label: "Not stated",
					value: "U",
				},
				{
					label: "Intersex / Indeterminate",
					value: "X",
				},
				{
					label: "Male",
					value: "M",
				},
				{
					label: "Female",
					value: "F",
				},
			],
			allowEmptyValue: false,
			type: "SelectField",
			rules: "required",
		},
		taxFileNo: {
			name: "taxFileNo",
			label: "Tax file number",
			type: "TFNField",
			rules: this.tfnRules,
			taxFileNoSupplied: null,
		},

		inactive: {
			name: "inactive",
			label: "Employee account status",
			type: "SelectField",
			options: [
				{
					label: "Active",
					value: "false",
				},
				{
					label: "Inactive",
					value: "true",
				},
			],
			allowEmptyValue: false,
		},

		personalContributions: {
			name: "personalContributions",
			label: "Personal",
			type: "CurrencyField",
		},
		title: {
			name: "title",
			type: "TextField",
			rules: "max:12|validateAllowedCharacters",
		},
		fundAccountName: {
			name: "fundAccountName",
			label: "Account name",
			type: "TextField",
		},
		memberBenefitCategory: {
			name: "memberBenefitCategory",
			label: "Benefit category text",
			type: "TextField",
			rules: "max:80|validateAllowedCharacters",
		},
		employerContributionsSuperannuationGuarantee: {
			name: "employerContributionsSuperannuationGuarantee",
			label: "Super guarantee",
			type: "CurrencyField",
		},
		fundElectronicServiceAddress: {
			name: "fundElectronicServiceAddress",
			label: "Electronic service address",
			type: "TextField",
			rules: "required_if:fundType,SMSF",
		},
		surname: {
			name: "surname",
			label: "Family name",
			type: "TextField",
			rules: "required|standardText|max:40",
		},
		countryCode: {
			name: "countryCode",
			label: "Country",
			options: [],
			searchable: true,
			allowEmptyValue: false,
			type: "SelectField",
		},
		employerContributionsAwardOrProductivity: {
			name: "employerContributionsAwardOrProductivity",
			label: "Award",
			type: "CurrencyField",
		},
		memberClientIdentifier: {
			name: "memberClientIdentifier",
			label: "Member number",
			type: "TextField",
			rules: "max:20|validateAllowedCharacters",
		},
		email: {
			name: "email",
			label: "Email address",
			type: "TextField",
			rules: "email|max:240",
		},
		annualSalaryBenefits: {
			name: "annualSalaryBenefits",
			label: "Benefits amount",
			type: "CurrencyField",
			rules: "min_value:0|max_value:99999999999.99",
		},
		annualSalaryContributionsEndDate: {
			name: "annualSalaryContributionsEndDate",
			label: "Annual salary for contributions effective end date",
			type: "DateField",
			rules:
				"datesCompare:@employmentStartDate,sameOrAfter,Employment start date," +
				"@annualSalaryContributionsStartDate,sameOrAfter,Annual salary for contributions effective start date",
		},
		mobile: {
			name: "mobile",
			type: "TextField",
			rules: "alphaNumericSpaces|max:16|min:10|mobileLeadingZero",
		},
		lineId: {
			name: "lineId",
			type: "TextField",
		},
		dateOfBirth: {
			name: "dateOfBirth",
			label: "Date of birth",
			type: "DateField",
			rules: "required|datesCompare:currentDate,before,current date",
		},
		fundAbn: {
			name: "fundAbn",
			label: "ABN",
			type: "TextField",
			readonly: true,
		},
		weeklyHoursWorked: {
			name: "weeklyHoursWorked",
			type: "TextField",
			rules: "min_value:0|max_value:168",
		},
		annualSalaryContributions: {
			name: "annualSalaryContributions",
			label: "Contributions amount",
			type: "CurrencyField",
			rules: "min_value:0|max_value:99999999999.99",
		},
		landline: {
			name: "landline",
			type: "TextField",
			rules: "alphaNumericSpaces|max:16",
		},
		funds: {
			name: "funds",
			type: "",
			label: "Funds",
		},
		smsfTerminated: {
			name: "smsfTermination",
			label: "SMSF termination date",
			type: "",
		},
	};

	/**
	 * recordFormData is the record currently being edited.
	 * initialised in created hook
	 */
	recordFormData: EmployeeAndFunds | null = null;
	originalPayrollNo: string | null = null;

	private onGridReady() {
		this.showErrorOnGridIfPresent();
	}

	private showErrorOnGridIfPresent() {
		const funds = this.recordFormData?.funds;
		if (!funds) {
			return;
		}

		for (const fund of funds) {
			if (!this.validateFundTerminationDate(fund)) {
				this.showFundErrorColumn(true);
				return;
			}
		}
		this.showFundErrorColumn(false);
	}

	private showFundErrorColumn(visible: boolean) {
		this.hasFundError = visible;
		if (!this.$refs.gridEl) {
			return;
		}
		this.$refs.gridEl[0].columnApi?.setColumnVisible(
			"notification",
			visible
		);
		this.$refs.gridEl[0].sizeColumnsToFit();
	}

	/**
	 * Initialised after `getReportingCenterDetails` call in created hook or in second stage.
	 */
	employerDetails: ReportingCentreABN = {
		reportingCentre: 0,
		reportingCentreName: null,
		abn: null,
		employerPayrollName: "",
		employerPayrollLocation: null,
		employerId: 0,
	};

	fundModalMode: Mode | null = null;

	created() {
		// Add new employee
		if (this.mode === "add") {
			/**
			 * default values
			 */
			this.recordFormData = {
				employee: {
					employeeId: 0,
					annualSalaryBenefits: null,
					annualSalaryContributions: null,
					annualSalaryContributionsEndDate: null,
					annualSalaryContributionsStartDate: null,
					annualSalaryInsurance: null,
					atWorkIndicator: null,
					dateOfBirth: null,
					email: null,
					employmentStartDate: null,
					employmentStatus: null,
					addressType: null,
					countryCode: "AU",
					addressLine1: null,
					addressLine2: null,
					addressLine3: null,
					addressLine4: null,
					locality: null,
					postcode: null,
					state: null,
					givenNames: null,
					insuranceOptOut: null,
					occupationDescription: null,
					payrollNo: null,
					registrationAmendmentReason: null,
					superContributionCeaseDate: null,
					superContributionCommencementDate: null,
					surname: null,
					taxFileNo: null,
					inactive: false,
					taxFileNoSupplied: null,
					terminationDate: null,
					terminationReason: null,
					weeklyHoursWorked: null,
					title: null,
					suffix: null,
					gender: null,
					landline: null,
					mobile: null,
					reportingCentre: null,
					dbBypass: null,
				},
				funds: [],
			};
			this.setTFNSupplied();
		} else {
			this.loadingEmployeeDetails = true;
			axios
				.get<EmployeeAndFunds>(
					employeeDetails(this.reportingCentreId, this.employeeId),
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				)
				.then((resp) => {
					if (resp.data.employee.dbBypass) {
						this.fetchStaticDbTabData();
					}
					this.recordFormData = resp.data;
					this.originalPayrollNo = resp.data.employee.payrollNo;
					this.setTFNSupplied();
					this.loadingEmployeeDetails = false;
					this.recordFormData?.funds.forEach((f) => {
						if (
							f.employeeDefinedBenefitFields &&
							f.employeeDefinedBenefitFields.length > 0
						) {
							this.dbFund = f.fundUsi;
							this.employeeDefinedBenefitFields =
								f.employeeDefinedBenefitFields;
						}
					});
				})
				.catch((e) => {
					toastErrorMessage(parseErrorMessage(e));
				});
		}
		this.getReportingCenterDetails(this.reportingCentreId);
	}

	private fetchStaticDbTabData() {
		const definedBenefitFeaturesEnabled =
			this.$store.getters["persistent/definedBenefitFeaturesEnabled"];
		if (definedBenefitFeaturesEnabled) {
			axios
				.get<DbTabDetails>(
					definedBenefitTabDataForEmployee(
						this.reportingCentreId,
						this.employeeId
					)
				)
				.then((resp) => {
					if (resp.data != null) {
						this.extractAcurityDbTabData(resp.data);
					}
				})
				.catch((e) => {
					toastErrorMessage(parseErrorMessage(e));
				});
		}
	}

	get dbRegoEmployeeDefinedBenefitFields() {
		return this.employeeDefinedBenefitFields.filter(
			(f) => f.tab === "DBREGO"
		);
	}

	get additionalEmployeeDefinedBenefitFields() {
		return this.employeeDefinedBenefitFields.filter(
			(f) => f.tab === "ADDL"
		);
	}

	get dbRegoEmployeeDefinedBenefitFieldsNumOfErrors() {
		let errors = 0;
		for (const fieldName of this.dbRegoEmployeeDefinedBenefitFields.map(
			(f) => f.fieldName
		)) {
			if (this.formValidationErrorMap[fieldName]?.length) {
				errors++;
			}
		}
		return errors;
	}

	get additionalEmployeeDefinedBenefitFieldsNumOfError() {
		let errors = 0;
		for (const fieldName of this.additionalEmployeeDefinedBenefitFields.map(
			(f) => f.fieldName
		)) {
			if (this.formValidationErrorMap[fieldName]?.length) {
				errors++;
			}
		}
		return errors;
	}

	beforeMount() {
		if (this.editSensitivePermission) {
			this.columnDefs.push({
				headerName: "Edit",
				cellRenderer: this.actionsRender,
				width: 60,
				minWidth: 70,
				pinned: "right",
			});
		} else {
			this.columnDefs.push({
				headerName: "View",
				cellRenderer: this.actionsRender,
				width: 60,
				minWidth: 60,
				pinned: "right",
			});
		}
	}

	private hasPermission(permission: string) {
		return hasPermission(permission);
	}

	get isDbMode() {
		return (
			this.definedBenefitMode != null &&
			this.definedBenefitMode.toLowerCase() === "true"
		);
	}

	beforeRouteEnter(
		to: AppRoute,
		from: AppRoute,
		next: AppRouteNextFunction<AddEmployee>
	) {
		const { mode, id, reportingCentreId } = to.params;
		// NOTE (York): The employer selector is included in Add Employee page and disabled. It used to
		// get selected reportingCentre Id by `getRcIdFromSelection` from EmployerSelectorUtils. It caused
		// many troubles to test. `getRcIdFromSelection` used to import global store and can't use store
		// set in the test. When `getRcIdFromSelection` is changed and use passed in `this.$store`, method
		// can't be used in this hook since this is not initialised. Due to so many problems, I decided to
		// pass reporting centre id in as prop no matter add or edit mode.
		if (mode === "add" && !isInteger(Number(reportingCentreId))) {
			// RC is not defined redirect to employee dashboard
			toastErrorMessage("Please select a reporting centre");
			next({ name: "Employees" });
			return;
		}

		if (
			mode === "edit" &&
			!(isInteger(Number(id)) && isInteger(Number(reportingCentreId)))
		) {
			toastErrorMessage(
				"Employee id and reporting centre id are required to edit an employee."
			);
			next({ name: "Employees" });
			return;
		}
		next();
	}

	private get modeText(): ModeText {
		return this.isDbMode
			? ((capitalize(this.mode) + " DB") as ModeText)
			: (capitalize(this.mode) as ModeText);
	}

	private get fundModalModeText(): ModeText | "" {
		if (!this.fundModalMode) {
			return "";
		}
		return capitalize(this.fundModalMode) as ModeText;
	}

	private updateFund(fieldName: string, value: any) {
		(this.employeeFund as any)[fieldName] = value;
	}

	private setTFNSupplied(): void {
		this.editFieldMap.taxFileNo.taxFileNoSupplied =
			this.recordFormData?.employee.taxFileNoSupplied;
	}

	private displayField(stage: any, fieldName: string, obj: any): boolean {
		let display = false;
		if (!obj) {
			return display;
		}
		if (stage.displayIf && stage.displayIf[fieldName]) {
			const compute = stage.displayIf[fieldName];
			const [targetFieldName, targetValues] = compute.split(":");
			const record: any = obj;
			each(targetValues.split(","), function (targetValue) {
				if (record[targetFieldName] === targetValue) {
					display = true;
				}
			});
			return display;
		} else {
			display = true;
		}

		return display;
	}

	private getReportingCenterDetails(
		reportingCentreId: number
	): Promise<void> {
		this.loadingRcDetails = true;
		return axios
			.get<ReportingCentreABN>(
				reportingCentreDetailsURL(reportingCentreId),
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			)
			.then((resp) => {
				this.employerDetails = resp.data;
				this.loadingRcDetails = false;
			})
			.catch((e) => {
				toastErrorMessage(parseErrorMessage(e));
			});
	}

	@Watch("activeTabIndex")
	private refreshFundsGrid(value: number) {
		// 4 is the index of the Funds tab. Refresh it to occupy the full width of the container.
		if (value === 4 && this.$refs.gridEl?.length) {
			this.$refs.gridEl[0].sizeColumnsToFit();
		}
	}

	private cancel() {
		this.$router.push(RoutePath.EmployeeList);
	}

	private async onClickSave() {
		if (this.recordFormData === null) {
			return;
		}

		const hasEmploymentDetails =
			this.validateMrrFieldsAgainstEmploymentStartDate() === true;

		if (!this.validateCommencementDate()) {
			toastErrorMessage(
				"Super contribution commencement date must not be earlier than any fund registration dates." +
					"Please check either Super contribution commencement date or all fund registration dates."
			);
			return;
		}
		const valid =
			(await this.$refs.employeeForm.validate()) && hasEmploymentDetails;
		if (valid) {
			const employee = { ...this.recordFormData.employee };
			delete employee.dbBypass;

			if (this.dbFund) {
				this.recordFormData.funds.forEach((f) => {
					if (f.fundUsi === this.dbFund) {
						f.employeeDefinedBenefitFields =
							this.employeeDefinedBenefitFields;
					}
				});
			}

			let method: "post" | "POST" | "put" | "PUT" = "POST";
			let url: string | null = null;
			let funds = null;
			if (this.mode === "edit") {
				method = "PUT";
				//set funds only if the user has permission to edit fund
				if (this.editSensitivePermission) {
					funds = this.recordFormData.funds;
				}
				url = `/api/employees/${this.reportingCentreId}/${this.employeeId}`;
			} else {
				method = "POST";
				funds = this.recordFormData.funds;
				url = `/api/employees/${this.reportingCentreId}`;
			}
			if (url && method) {
				this.sendSaveRequest(method, url, employee, funds);
			}
		} else {
			this.afterSave(hasEmploymentDetails);
		}
	}

	private sendSaveRequest(
		method: any,
		url: string,
		employee: any,
		funds: any
	) {
		const dbFieldsMap: { [fieldName: string]: any } = {};
		const employeeMap = new Map(Object.entries(employee));
		this.acurityDbFields.forEach((field) => {
			if (employeeMap.has(field)) {
				dbFieldsMap[field] = employeeMap.get(field);
			}
		});
		axios
			.request<void>({
				method: method,
				url: url,
				data: { employee, funds, dbTabFields: dbFieldsMap },
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				// 204 - Employee / EmployeeFund / EmployeeSMSF not updated since there was no change
				if (resp.status === 204) {
					toastInfoMessage(
						`No changes have been made to ${employee?.givenNames}  ${employee?.surname}; save not required`
					);
				} else {
					if (this.mode === "edit") {
						toastSuccessMessage(
							`Edit employee successful for ${employee?.givenNames}  ${employee?.surname}`
						);
					} else {
						toastSuccessMessage(
							`Add employee successful for ${employee?.givenNames}  ${employee?.surname}`
						);
					}
					this.$router.push({ name: "Employees" });
				}
			})
			.catch((e) => {
				toastErrorMessage(parseErrorMessage(e));
				// MRR fasttrack returns 406 (Employee record updated but failed to send MRR - navigate back to Employee list when this happens)
				if (this.mode === "add" && e.response.status === 406) {
					this.$router.push({ name: "Employees" });
				}
			});
	}

	private afterSave(hasEmploymentDetails: boolean) {
		if (!hasEmploymentDetails) {
			toastErrorMessage(
				"An Employee requires either Employment details (ie: Employment Start Date.) or a Fund with a 'Fund registration date'."
			);
		}
		if (this.hasFundError || !this.doFundsExist) {
			toastErrorMessage("Employer must have a valid fund.");
		}
	}

	private closeFundModal(): void {
		this.fundModalMode = null;
		this.selectedRowIdx = null;
		this.resetEmployeeFund();
		this.fundRegistrationDateFieldErrors = [];
		this.fundValidationErrors = [];
	}

	private async onClickSubmitFund(): Promise<void> {
		if (
			(!this.employeeFund.abn && !this.employeeFund.fundAbn) ||
			!this.recordFormData?.funds
		) {
			return;
		}

		// Validate and add to the grid
		const valid = await this.$refs.addFundFormValidation.validate();
		this.validateSMSFIsActive();

		if (
			valid &&
			this.fundRegistrationDateFieldErrors.length === 0 &&
			this.fundValidationErrors.length === 0
		) {
			const fund: any = {
				fundName: this.employeeFund.fundName
					? this.employeeFund.fundName
					: "",
				fundAbn: this.employeeFund.fundAbn
					? this.employeeFund.fundAbn
					: "",
				fundType: this.employeeFund.fundType,
				fundFen: this.employeeFund.fundFen,
				fundUsi: this.employeeFund.fundUsi,
				fundPayeeCode: this.employeeFund.payeeCode,
				fundStatus: this.employeeFund.fundStatus,
				complyingStatus: this.employeeFund.complyingStatus,
				accountName: this.employeeFund.fundAccountName,
				accountNumber: this.employeeFund.fundAccountNumber,
				bsb: this.employeeFund.fundBsb,
				esa: this.employeeFund.fundElectronicServiceAddress,
				fundRegistrationDate: this.employeeFund.fundRegistrationDate,
				fundTerminationDate: this.employeeFund.fundTerminationDate,
				memberClientIdentifier:
					this.employeeFund.memberClientIdentifier,
				memberBenefitCategory: this.employeeFund.memberBenefitCategory,
			};

			this.clearOrPushDbFields(fund);

			// Once fund is selected in SearchFund component, `this.employeeFund` stores
			// a copy of smsfAliasMap. After `AddFundForm` Add/Update button is clicked
			// and this method is called, backup `this.employeeFund.smsfAliasMap` into
			// this.recordFormData for later usage because closeFundModal reset
			// `this.employeeFund`
			if (!this.recordFormData.smsfAliasOptions) {
				this.recordFormData.smsfAliasOptions =
					this.employeeFund.smsfAliasMap;
			}

			this.showErrorOnGridIfPresent();
			this.loadCustomForm(fund.fundUsi);
			this.closeFundModal();
		}
	}

	private clearOrPushDbFields(fund: any) {
		if (!this.recordFormData?.funds) {
			return;
		}

		if (this.selectedRowIdx !== null && this.selectedRowIdx >= 0) {
			fund.employeeFundId =
				this.recordFormData.funds[this.selectedRowIdx].employeeFundId;
			this.recordFormData.funds.splice(this.selectedRowIdx, 1, fund);
			if (
				this.recordFormData.funds.filter(
					(fund) => fund.fundUsi === this.dbFund
				).length === 0
			) {
				this.employeeDefinedBenefitFields = [];
			}
		} else {
			this.recordFormData.funds.push(fund);
		}
	}

	private loadCustomForm(usi: string) {
		if (usi) {
			axios
				.get<FundFieldMapping[]>(
					getFundFieldMappingUrlByRcAndUsi(
						this.reportingCentreId,
						usi
					)
				)
				.then((resp) => {
					if (resp.data.length > 0) {
						this.dbFund = usi;
						this.employeeDefinedBenefitFields = resp.data.map(
							(d) => {
								return {
									usi,
									fieldName: d.fieldName,
									fieldLabel: d.fieldLabel,
									fieldType: d.fieldType,
									tab: d.tab,
									fundFieldMappingId: d.id.toString(),
									fieldValue: "",
									mandatory: d.mandatory,
								};
							}
						);
					}
				})
				.catch((e) => {
					toastErrorMessage(parseErrorMessage(e));
				});
		}
	}

	private setSelectedFund(selectedFund: SearchFundDetails) {
		const { status, smsfAliasMap, ...copyOfselectedFund } = selectedFund;
		this.resetEmployeeFund();

		// CHSN-1007: if the new selected fund is empty, no fund type should be changed.
		// Otherwise SearchFund will receive a wrong fund type and bug out.
		let fundType = "";
		if (selectedFund.fundUsi) {
			//TODO CHSN-2871 set funddType to sheme for DB bypass employees
			fundType = FundType.APRA;
		} else if (selectedFund.fundAbn) {
			fundType = FundType.SMSF;
		}

		this.employeeFund = {
			...this.employeeFund,
			...copyOfselectedFund,
			fundStatus: status,
			smsfAliasMap: cloneDeep(smsfAliasMap),
			fundType,
			fundFen: selectedFund.superannuationFundGeneratedEmployerIdentifier,
		};
	}

	onValidationObserverErrorsChanged(errorMap: ErrorMap) {
		this.formValidationErrorMap = errorMap;
	}

	get doFundsExist(): boolean {
		return (
			this.recordFormData !== null &&
			this.recordFormData.funds?.length > 0
		);
	}

	get addressDetailsOfEmployee(): AddressDetails | {} {
		const formData = this.recordFormData?.employee;
		if (!formData) {
			return {};
		}

		const addressDetailsKeys: (keyof AddressDetails)[] = [
			"addressType",
			"countryCode",
			"addressLine1",
			"addressLine2",
			"addressLine3",
			"addressLine4",
			"locality",
			"postcode",
			"state",
		];
		const addressDetails: Partial<AddressDetails> = {};
		addressDetailsKeys.forEach((key) => {
			addressDetails[key] = formData[key];
		});
		return addressDetails;
	}

	get numberOfErrorsForEachTab(): number[] {
		const temp = fieldNamesGroupedByTab.map((fieldsInTab) => {
			let errorsInTab = 0;

			for (const fieldName of fieldsInTab) {
				if (this.formValidationErrorMap[fieldName]?.length) {
					errorsInTab++;
				}
			}
			return errorsInTab;
		});
		temp.push(this.dbRegoEmployeeDefinedBenefitFieldsNumOfErrors);
		temp.push(this.additionalEmployeeDefinedBenefitFieldsNumOfError);
		return temp;
	}

	updateAddressDetailsOfEmployee(value: AddressDetails) {
		const formData = this.recordFormData?.employee;
		if (!formData) {
			return;
		}

		const addressDetailsKeys: (keyof AddressDetails)[] = [
			"addressType",
			"countryCode",
			"addressLine1",
			"addressLine2",
			"addressLine3",
			"addressLine4",
			"locality",
			"postcode",
			"state",
		];

		addressDetailsKeys.forEach((key) => {
			formData[key] = value[key];
		});
	}

	capitaliseInDom(str: string): string {
		return capitalise(str);
	}

	actionsRender(params: ICellRendererParams): HTMLElement {
		const vm = new Vue({
			el: document.createElement("div"),
			render: (createElement) => {
				return createElement(GridActionsRenderer, {
					props: {
						rowIndex: params.rowIndex,
						isEdit: this.editSensitivePermission,
						isView: !this.editSensitivePermission,
						// isDelete: true
					},
					on: {
						clickEdit: this.handleEditFundClick,
						clickView: this.handleViewFundClick,
						// clickDelete: this.onClickDelete
					},
				});
			},
		});
		this.gridVMList.push(vm);
		return vm.$el as HTMLElement;
	}

	private showFundModal(mode: Mode): void {
		this.fundModalMode = mode;
	}

	private handleAddFundClick(): void {
		this.resetEmployeeFund();
		this.showFundModal("add");
	}

	private handleEditFundClick({ rowIndex }: { rowIndex: number }): void {
		if (!this.recordFormData?.funds) {
			return;
		}
		this.selectedRowIdx = rowIndex;
		// edit fund - clone to revert if a user makes changes and then clicks cancel
		const clonedFundDetails = clone(this.recordFormData.funds[rowIndex]);
		if (clonedFundDetails.fundType === FundType.SMSF) {
			this.employeeFund = {
				...clonedFundDetails,
				name: clonedFundDetails.fundName,
				abn: clonedFundDetails.fundAbn,
				status: clonedFundDetails.fundStatus,
				complyingStatus: clonedFundDetails.complyingStatus,
				fundAccountName: clonedFundDetails.accountName,
				fundAccountNumber: clonedFundDetails.accountNumber,
				fundBsb: clonedFundDetails.bsb,
				fundElectronicServiceAddress: clonedFundDetails.esa,
			};
		} else {
			this.employeeFund = {
				...clonedFundDetails,
				active: clonedFundDetails.fundStatus === "Active",
			};
		}

		this.employeeFund.payeeCode = this.employeeFund.fundPayeeCode;

		// show the validation error if present
		this.validateSMSFIsActive();
		this.showFundModal("edit");
	}

	private handleViewFundClick({ rowIndex }: { rowIndex: number }): void {
		if (!this.recordFormData?.funds) {
			return;
		}
		this.selectedRowIdx = rowIndex;
		// edit fund - clone to revert if a user makes changes and then clicks cancel
		const clonedFundDetails = clone(this.recordFormData.funds[rowIndex]);
		if (clonedFundDetails.fundType === FundType.SMSF) {
			this.employeeFund = {
				...clonedFundDetails,
				name: clonedFundDetails.fundName,
				abn: clonedFundDetails.fundAbn,
				status: clonedFundDetails.fundStatus,
				complyingStatus: clonedFundDetails.complyingStatus,
				fundAccountName: clonedFundDetails.accountName,
				fundAccountNumber: clonedFundDetails.accountNumber,
				fundBsb: clonedFundDetails.bsb,
				fundFen: clonedFundDetails.fen,
				fundElectronicServiceAddress: clonedFundDetails.esa,
			};
		} else {
			this.employeeFund = {
				...clonedFundDetails,
				active: clonedFundDetails.fundStatus === "Active",
			};
		}
		this.employeeFund.payeeCode = this.employeeFund.fundPayeeCode;
		this.showFundModal("view");
	}

	onTaxFileNoSuppliedChange(checked: boolean) {
		if (!this.recordFormData) {
			return;
		}
		this.recordFormData.employee.taxFileNoSupplied = checked;
		this.setTFNSupplied();
	}

	buildErrorFromErrorMap(errorMap: ErrorMap): string | null {
		const editFieldMap = this.editFieldMap;
		const trimmedErrorMap = pickBy(errorMap, (errors) => !isEmpty(errors));

		const fields = map(
			trimmedErrorMap,
			(errors: ErrorArray, fieldName: string) => {
				return computeLabel(editFieldMap[fieldName].label, fieldName);
			}
		);
		return fields.length > 0 ? fields.join(", ") : null;
	}

	resetEmployeeFund() {
		this.employeeFund = cloneDeep(emptyEmployeeFund);
	}

	commencementDateFieldErrors: string[] = [];
	fundRegistrationDateFieldErrors: string[] = [];
	fundValidationErrors: string[] = [];

	private validateFundTerminationDate(
		employeeFund: EmployeeFundDetails
	): boolean {
		return (
			!!employeeFund.fundTerminationDate ||
			employeeFund.complyingStatus !==
				AddEmployee.SMSF_NON_COMPLYING_STATUS
		);
	}

	validateSMSFIsActive() {
		// this was created because of issues getting vee-validate to work partly because we use multiple forms
		this.fundValidationErrors = [];
		if (!this.validateFundTerminationDate(this.employeeFund)) {
			this.fundValidationErrors = ["SMSF must be compliant."];
		}
	}

	onFundRegistrationDateFieldInput(fundRegisterDateValue: string) {
		this.employeeFund.fundRegistrationDate = fundRegisterDateValue;
		this.fundRegistrationDateFieldErrors = [];

		const commencementDateValue =
			this.recordFormData?.employee.superContributionCommencementDate ??
			"";
		if (
			!AddEmployee.validateCommencementAndRegistrationDates(
				commencementDateValue,
				fundRegisterDateValue
			)
		) {
			this.fundRegistrationDateFieldErrors = [
				"Commencement date must not be earlier than fund registration date.",
			];
		}
	}

	onCommencementDateFieldInput(commencementDateValue: string) {
		if (!this.recordFormData) {
			return;
		}
		this.commencementDateFieldErrors = [];
		this.recordFormData.employee.superContributionCommencementDate =
			commencementDateValue;
		if (!this.validateCommencementDate()) {
			this.commencementDateFieldErrors = [
				"Commencement date must not be earlier than any of fund registration dates.",
			];
		}
	}

	/**
	 * Unable to validate these fields together using normal rules,
	 * possibly because they are on different forms.
	 */
	validateTfnOrPayrollNo() {
		const employee = this.recordFormData?.employee;
		if (employee) {
			const payrollNoNotSupplied =
				isEmpty(employee.payrollNo) ||
				(employee.payrollNo &&
					employee.payrollNo.startsWith(
						AddEmployee.GENERATED_PAYROLL_PREFIX
					));
			let tfnOrPayrollRequired = false;

			if (
				this.mode === "add" &&
				isEmpty(employee.taxFileNo) &&
				payrollNoNotSupplied
			) {
				tfnOrPayrollRequired = true;
			} else {
				// CHSN-1852 Invalid TFN (null) in table
				if (employee.taxFileNo === null && payrollNoNotSupplied) {
					tfnOrPayrollRequired = true;
				}
				if (
					employee.taxFileNo !== null &&
					payrollNoNotSupplied &&
					employee.payrollNo !== this.originalPayrollNo
				) {
					tfnOrPayrollRequired = true;
				}
			}
			if (tfnOrPayrollRequired) {
				return 'Either "Tax file number" or a non-SCCH "Payroll number" is required.';
			}
		}
		return true;
	}

	validateMrrFieldsAgainstEmploymentStartDate() {
		const employee = this.recordFormData?.employee;
		if (
			employee?.employmentStartDate ||
			employee?.annualSalaryBenefits ||
			employee?.annualSalaryContributions ||
			employee?.annualSalaryContributionsStartDate ||
			employee?.annualSalaryContributionsEndDate ||
			employee?.annualSalaryInsurance ||
			employee?.weeklyHoursWorked ||
			employee?.insuranceOptOut ||
			employee?.superContributionCommencementDate ||
			employee?.superContributionCeaseDate ||
			employee?.occupationDescription ||
			employee?.employmentStatus ||
			employee?.atWorkIndicator // ||
			// currently unused, turn on after CHSN-1757
			// employee?.registrationAmendmentReason
		) {
			return true;
		}
		let hasEmptyFund = false;
		if (
			this.recordFormData?.funds &&
			this.recordFormData.funds.length > 0
		) {
			for (const fund of this.recordFormData?.funds) {
				if (
					!fund?.fundRegistrationDate &&
					!fund?.memberBenefitCategory
				) {
					hasEmptyFund = true;
				}
			}
		} else {
			hasEmptyFund = true;
		}
		if (!hasEmptyFund) {
			return true;
		}

		return "Please provide at least an Employment Start Date.";
	}

	validateCommencementDate() {
		const funds = this.recordFormData?.funds ?? [];
		const commencementDate =
			this.recordFormData?.employee.superContributionCommencementDate ??
			"";
		return funds
			.map((fund) => fund.fundRegistrationDate ?? "")
			.map((registrationDate) =>
				AddEmployee.validateCommencementAndRegistrationDates(
					commencementDate,
					registrationDate
				)
			)
			.every(Boolean);
	}

	static validateCommencementAndRegistrationDates(
		commencementDateValue: string,
		fundRegisterDateValue: string
	) {
		if (!commencementDateValue || !fundRegisterDateValue) {
			return true;
		}
		const dateFormat = "YYYY-MM-DD";
		const commencementDate = moment(commencementDateValue, dateFormat);
		const fundRegisterDate = moment(fundRegisterDateValue, dateFormat);
		return fundRegisterDate.isSameOrBefore(commencementDate);
	}

	onEmployeeDbInputChange(e: { data: any; fieldName: string }) {
		if (!this.employeeDefinedBenefitFields) {
			return;
		}

		this.employeeDefinedBenefitFields.forEach((db) => {
			if (db.fieldName === e.fieldName) {
				db.fieldValue = e.data;
			}
		});
	}

	extractAcurityDbTabData(dbTabs: DbTabDetails) {
		const tabNames = Object.keys(dbTabs);
		tabNames.forEach((tab) => {
			if (dbTabs[tab].mode) {
				this.handleMultiTab(dbTabs[tab]);
			} else if (dbTabs[tab].grid) {
				this.handleCustomGridTab(
					tab,
					dbTabs[tab].grid as DynamicGridTabProps
				);
			}
		});
	}

	private handleMultiTab(props: MultiTabProps) {
		this.customMultiTabs.push({
			tabName: props.tabLabel,
			...props,
		});
		this.formSchema.push({
			header: props.tabLabel,
			subSections: [],
		});
	}

	private handleCustomGridTab(tab: string, grid: DynamicGridTabProps) {
		this.customGridTabs.push({
			tabName: tab,
			...grid,
		});
		this.formSchema.push({
			header: tab,
			subSections: [
				{
					name: grid.gridName,
					icon: grid.icon,
				},
			],
		});
	}
}
