
































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import Button from "@/form/Button.vue";
import ButtonBehaviour from "@/form/ButtonBehaviour.vue";

@Component({
	components: {
		Button,
		ButtonBehaviour,
	},
})
export default class DropdownMenuItem extends Vue {
	@Prop(String) href!: string;

	@Prop(Boolean) disabled!: boolean;

	/**
	 * clickEvent helps us only bind the @click event on the element if there is
	 * a listener.
	 *
	 * Source:
	 * - https://github.com/vuejs/vue/issues/7349#issuecomment-621699548
	 * - https://jsfiddle.net/pbastowski/v0wt5qpo/63/
	 */
	get clickEvent(): "click" | null {
		return this.$listeners && this.$listeners.click ? "click" : null;
	}

	onClick(e: Event) {
		this.$emit("click", e);
	}
}
