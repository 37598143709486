import Vue from "vue";
import Vuex from "vuex";
import { RootState } from "@/store/root";
import {
	getDefaultState as getDefaultLoginState,
	login,
} from "@/store/modules/login";
import {
	getDefaultPersistentState,
	persistent,
} from "@/store/modules/persistent";
import { createStore } from "vuex-extensions";
import router from "@/router";
import { RoutePath } from "@/router/routePath";
import { insight, getDefaultInsightState } from "@/store/modules/insight";

Vue.use(Vuex);
const initialStoreModules = {
	login,
	persistent,
	insight,
};

const initialRootState: RootState = {
	login: getDefaultLoginState(),
	persistent: getDefaultPersistentState(),
	insight: getDefaultInsightState(),
};

const store = createStore<RootState>(Vuex.Store, {
	// Do not enable strict mode when deploying for production!
	// Strict mode runs a synchronous deep watcher on the state tree for detecting inappropriate mutations,
	// and it can be quite expensive when you make large amount of mutations to the state.
	// Make sure to turn it off in production to avoid the performance cost.
	//
	// Source: https://vuex.vuejs.org/guide/strict.html
	strict: process.env.NODE_ENV === "development",
	modules: initialStoreModules,
	mutations: {
		reset(state: any) {
			Object.keys(state).forEach((key) => {
				// Removes any modules not defined in the RootState.
				if (!Object.keys(initialRootState).includes(key)) {
					store.unregisterModule(key);
				} else {
					// sets modules to their given default state.
					Object.assign(
						state[key],
						initialRootState[key as keyof RootState]
					);
				}
			});
		},
	},
	actions: {
		resetState({ commit }) {
			// Note: (York) Reset root states only when after page is successfully navigated to login page.
			// We don't want mutation of persistent state to cause any reactive actions (probably firing requests)
			// when it is still in pages other than login, which may fail. (access token is absent at this state)
			router.push(RoutePath.Login, () => {
				window.sessionStorage.removeItem("persistent");
				commit("reset");
			});
		},
	},
});
export default store;
