





























































































































































































































import Vue, { VueConstructor } from "vue";
import { Component } from "vue-property-decorator";
import { FormWizard, TabContent } from "vue-form-wizard";
import { setInteractionMode } from "vee-validate";
import { createNamespacedHelpers } from "vuex";
import axios from "@/utils/ApiUtils";
import { AxiosResponse } from "axios";
import Layout from "@/components/Layout.vue";
import SecretCodeStep from "@/pages/registrationofinterest/SecretCodeStep.vue";
import AuthorisedContactStep from "@/pages/registrationofinterest/AuthorisedContactStep.vue";
import BusinessDetailsStep from "@/pages/registrationofinterest/BusinessDetailsStep.vue";
import RefundAccountStep from "@/pages/registrationofinterest/RefundAccountStep.vue";
import OtherStep from "@/pages/registrationofinterest/OtherStep.vue";
import FinaliseStep from "@/pages/registrationofinterest/FinaliseStep.vue";
import BaseRegistrationOfInterestStep from "./BaseRegistrationOfInterestStep.vue";
import Button from "@/form/Button.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { registrationOfInterest } from "@/store/modules/registrationofinterest";
import { SuperName, SuperPath } from "./registrationOfInterestTypes";
import TermsAndConditions from "@/components/TermsAndConditions.vue";
import { toastErrorMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import { supportEmailApi } from "@/constants/apiconstants";

const { mapState } = createNamespacedHelpers("registrationOfInterest");

// Assume the interaction mode in other parts of the app is aggressive (the default).
const globalInteractionMode = "aggressive";
setInteractionMode("eager");

type RegistrationOfInterestFormStepTitle =
	| "Unique Access Code"
	| "Authorised Contact"
	| "Business Details"
	| "Refund Account"
	| "Other"
	| "Finalise";

@Component({
	components: {
		FormWizard: FormWizard as VueConstructor<Vue>,
		TabContent: TabContent as VueConstructor<Vue>,
		Layout,
		Button,
		TermsAndConditions,
	},
	computed: {
		...mapState(["secretCode"]),
	},
})
class RegistrationOfInterestPage extends Vue {
	introductionCompleted = false;
	formCompleted = false;
	private supportEmail = "";
	private termsAndConditions: string[] = [];

	secretCode!: string;

	get steps(): Record<
		RegistrationOfInterestFormStepTitle,
		typeof BaseRegistrationOfInterestStep
	> {
		return Object.freeze({
			"Unique Access Code": SecretCodeStep,
			"Authorised Contact": AuthorisedContactStep,
			"Business Details": BusinessDetailsStep,
			"Refund Account": RefundAccountStep,
			Other: OtherStep,
			Finalise: FinaliseStep,
		});
	}

	get indexOfSecretCodeStep(): number {
		return Object.keys(this.steps).findIndex(
			(stepTitle) => stepTitle === "Unique Access Code"
		);
	}

	get superPath(): SuperPath {
		return (this.$router as any).options.base.replace(/\//g, "");
	}

	get superName(): SuperName {
		const superPathNameMap: Record<SuperPath, SuperName> = {
			childcare: "Child Care Super",
			guild: "GuildSuper",
			iress: "Iress",
		};
		return superPathNameMap[this.superPath];
	}

	validateStep(title: RegistrationOfInterestFormStepTitle) {
		return () => {
			const instance = (this.$refs[title] as any)[0];
			if (instance.validate) {
				return instance.validate();
			}
			return true;
		};
	}

	completeForm() {
		this.formCompleted = true;
	}

	handleWizardTabChange(prevIndex: number, nextIndex: number) {
		this.scrollToTop();

		if (
			prevIndex === this.indexOfSecretCodeStep &&
			nextIndex === this.indexOfSecretCodeStep + 1
		) {
			this.fetchSupportEmail();
		}
	}

	scrollToTop() {
		const layout = document.getElementsByClassName("Layout__root")[0];
		layout.scrollTo(0, 0);
	}

	fetchSupportEmail() {
		axios
			.get<string>(supportEmailApi, {
				headers: {
					"X-Registration-Wizard-Unique-Access-Code": this.secretCode,
				},
			})
			.then((response: AxiosResponse<string>) => {
				this.supportEmail = response.data;
			})
			.catch((e) => {
				toastErrorMessage(parseErrorMessage(e));
			});
	}

	created() {
		document.title = this.superName;

		if (!this.$store.hasModule("registrationOfInterest")) {
			this.$store.registerModule(
				"registrationOfInterest",
				registrationOfInterest
			);
		}
	}

	async mounted() {
		const constants = await import(
			"@/themes/" + process.env.VUE_APP_BRAND + "/constants.json"
		);
		this.termsAndConditions = constants.termsAndConditions;
	}

	beforeRouteLeave() {
		setInteractionMode(globalInteractionMode);
	}
}

export default RegistrationOfInterestPage;
