interface FundFormat {
	id: number;
	fundFormatName: string;
	usi: string;
}

interface FundFormatAndMappingResponse {
	fundFormat: FundFormat;
	mappings: Fund[];
}

interface UsiFormatResponse {
	usiFormat: Fund[];
}

enum Status {
	NEW,
	UPDATED,
	UNCHANGED,
}
interface ApraFundOptions {
	id: string;
	value: string;
}

interface ApraResponse {
	fundName: string;
	fundABN: string;
	fundUsi: string;
	fen: string;
	payeeCode: string;
	active: boolean;
	defaultFund: boolean;
	DisplayString: string;
}

interface Fund {
	id: string;
	tab: string;
	fundFormatId: number;
	saffColumn: string;
	fieldLabel: string;
	fieldType: string;
	displayOrder: number;
	status: Status;
	mandatory: boolean;
}

interface SaveObject {
	id: number;
	tab: string;
	fundFormatId: number;
	saffColumn: string;
	order: number;
	mandatory: boolean;
}

export {
	Status,
	ApraResponse,
	Fund,
	SaveObject,
	FundFormat,
	FundFormatAndMappingResponse,
};
