import { FilterState } from "@/store/modules/filters/filterTypes";
import { Module, Store } from "vuex";
import { RootState } from "@/store/root";
import { getters } from "@/store/modules/filters/filterGetters";
import { mutations } from "@/store/modules/filters/mutations";
import { FilterModel } from "@/grid/gridTypes";

export const getDefaultFilterState: () => Readonly<FilterState> = () => ({
	filters: {},
});

export const filter: Module<FilterState, RootState> = {
	namespaced: true,
	state: getDefaultFilterState,
	getters,
	mutations,
};

export const registerModule = (
	store: Store<any>,
	name: string,
	model: FilterModel
) => {
	if (!store.hasModule(name)) {
		store.registerModule(name, filter);
		store.commit(`${name}/setFilters`, JSON.parse(JSON.stringify(model)));
	}
};

export const registerModuleFilterForm = (
	store: Store<any>,
	name: string,
	model: { [p: string]: string | null | undefined }
) => {
	if (!store.hasModule(name)) {
		store.registerModule(name, filter);
		store.commit(`${name}/setFilters`, JSON.parse(JSON.stringify(model)));
	}
};

export const commitToModule = (
	store: Store<any>,
	name: string,
	model: FilterModel
) => {
	store.commit(`${name}/setFilters`, JSON.parse(JSON.stringify(model)));
};

export const commitToModuleFilterForm = (
	store: Store<any>,
	name: string,
	model: { [p: string]: string | null | undefined }
) => {
	store.commit(`${name}/setFilters`, JSON.parse(JSON.stringify(model)));
};
