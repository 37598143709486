
























































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import ButtonBehaviour from "@/form/ButtonBehaviour.vue";

@Component({
	components: {
		ButtonBehaviour,
	},
})
export default class GridActionsRenderer<RowType> extends Vue {
	/**
	 * rowId of div for popover target
	 */
	@Prop(String) rowId!: string;

	/**
	 * rowIndex is the index of the record
	 */
	@Prop(Number) rowIndex!: number;

	/**
	 * row is the data of the record
	 */
	@Prop({ type: Object }) row!: RowType;

	/**
	 * Edit action. Enabled by default
	 */
	@Prop({ default: true }) isEdit!: boolean;

	/**
	 * Delete action. Disabled by default
	 */
	@Prop({ default: false }) isDelete!: boolean;

	/**
	 * This will show the Trash icon even when isDelete = true. Trash icon will be shown but disabled
	 */
	@Prop({ default: false }) showDeleteDisabled!: boolean;

	/**
	 * View action. Disabled by default
	 */
	@Prop({ default: false }) isView!: boolean;

	/**
	 * Terminate action. Disabled by default
	 */
	@Prop({ default: false }) isTerminate!: boolean;

	private onClickEdit(event: MouseEvent): void {
		event.stopPropagation();
		this.$emit("clickEdit", { rowIndex: this.rowIndex, row: this.row });
	}
	private onClickDelete(event: MouseEvent): void {
		event.stopPropagation();
		this.$emit("clickDelete", { rowIndex: this.rowIndex, row: this.row });
	}
	private onClickView(event: MouseEvent): void {
		event.stopPropagation();
		this.$emit("clickView", { rowIndex: this.rowIndex, row: this.row });
	}

	private onClickTerminate(event: MouseEvent): void {
		event.stopPropagation();
		this.$emit("clickTerminate", {
			rowIndex: this.rowIndex,
			row: this.row,
		});
	}
}
