








import Vue, { CreateElement, VNode, VNodeChildren, VNodeData } from "vue";
import { Component, Prop } from "vue-property-decorator";

import ElementSchemaManager from "./ElementSchemaManager";

export interface FormSchema {
	elements: ElementSchema[];
	fieldMap: { string: ElementSchema };
}

export interface ElementSchema {
	type: string;
	children?: ElementSchema[];
	[otherProps: string]: any;
}

interface ElementRendererProps {
	value: { [name: string]: any } | null;
	schema: FormSchema | null;
}

@Component({})
export default class ElementRenderer extends Vue
	implements ElementRendererProps {
	@Prop([Object]) value!: { [name: string]: any };

	@Prop([Object]) schema!: FormSchema | null;

	duplicateFieldNameCheck: { [name: string]: boolean } = {};

	private lastRenderedKey: ElementRendererProps = {
		value: null,
		schema: null,
	};

	private lastRenderedCache: VNode | null = null;

	/**
	 * onInput will update the "value" of this object with the field name.
	 */
	private onInput(name: string, newValue: any): void {
		if (this.value[name] === newValue) {
			return;
		}
		// NOTE(Jae): 2020-06-02
		// For now, we be conservative with allocations/perf.
		// and mutate the existing JS obj because each keypress
		// would in-theory generate a new JSObj / Hashmap. Which cant
		// be a good idea.
		//const newData = {...this.value}
		const newData = this.value;
		newData[name] = newValue;
		this.$emit("input", newData);
	}

	private createElementsFromFields(
		createElement: CreateElement,
		components: ElementSchema[]
	): VNodeChildren {
		const renderList: VNodeChildren = [];
		for (const component of components) {
			// We currently assume an element is a field if it has a "name" property.
			const isField = Boolean(component.name);

			// Check for duplicate field names. They must be unique.
			if (isField) {
				if (
					this.duplicateFieldNameCheck[component.name] !== undefined
				) {
					throw new Error(
						"Duplicate name detected in schema. This is not allowed: " +
							component.name
					);
				}
				this.duplicateFieldNameCheck[component.name] = true;
			}

			//
			let children: VNodeChildren | undefined;
			if (component.children && component.children.length > 0) {
				children = this.createElementsFromFields(
					createElement,
					component.children
				);
			}

			// Create props object for Vue.js component
			const props = { ...component };
			delete props.type;
			delete props.children;
			if (isField) {
				if (this.value[component.name] !== undefined) {
					props.value = this.value[component.name];
				}
			}
			const nodeData: VNodeData = {
				props: props,
			};
			if (isField) {
				nodeData.on = {
					input: (newValue: any) =>
						this.onInput(component.name, newValue),
				};
			}

			renderList.push(
				createElement(
					ElementSchemaManager.get(component.type),
					nodeData,
					children
				)
			);
		}
		return renderList;
	}

	render(createElement: CreateElement, context: any): VNode {
		if (!this.schema) {
			return createElement("div", {
				class: "FormRenderer__root",
			});
		}
		if (!this.schema.elements) {
			throw new Error('Missing "elements" on schema.');
		}

		// NOTE(Jae): 2020-07-28
		// Use the VNode data from the last render if nothing changed.
		//
		// We do this so that we don't end up with the following error:
		// "You may have an infinite update loop in a component render function"
		//
		// The problem was that after saving a record on the Validate Contents page, this
		// render() function would get hammered and stall Vue.
		//
		// I looked for a "shouldComponentUpdate" method like React, but Vue doesn't have one
		// and so this will have to do.
		if (
			this.lastRenderedKey.value === this.value &&
			this.lastRenderedKey.schema === this.schema
		) {
			if (this.lastRenderedCache) {
				return this.lastRenderedCache;
			}
		}

		// Render
		this.duplicateFieldNameCheck = {};
		const renderList = this.createElementsFromFields(
			createElement,
			this.schema.elements
		);

		// Store in cache and return
		this.lastRenderedCache = createElement(
			"div",
			{
				class: "FormRenderer__root",
			},
			renderList
		);
		this.lastRenderedKey.value = this.value;
		this.lastRenderedKey.schema = this.schema;
		return this.lastRenderedCache;
	}
}
