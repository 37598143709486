













































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import Button from "@/form/Button.vue";

@Component({
	components: {
		Button,
	},
})
export default class DropdownMenu extends Vue {
	@Prop(String) readonly label!: string;

	@Prop(Boolean) readonly right!: boolean;

	@Prop({ type: Boolean, default: false }) readonly isDisabled!: boolean;

	public $refs!: {
		rootEl: HTMLElement;
		listEl: HTMLElement;
		buttonEl: Vue;
	};

	isOpen = false;

	destroyed(): void {
		// NOTE(Jae): 2020-05-15
		// Ensure we remove listeners if the menu is open and this gets destroyed
		// by swapping routes / etc.
		this.removeEventListeners();
	}

	private removeEventListeners(): void {
		document.body.removeEventListener("click", this.onClickBody);
		document.body.removeEventListener("focusin", this.onFocusInBody, true);
		document.body.removeEventListener("keydown", this.onKeyDown, true);
	}

	@Watch("isOpen")
	onIsOpenChanged(newValue: boolean, oldValue: boolean) {
		switch (newValue) {
			case false: {
				this.removeEventListeners();

				// If close, bring focus back to the button
				// (We only want this behaviour if we were focused on an element within
				// this component, because if a user clicks a random element on the screen, they
				// probably want to focus that)
				if (
					document.activeElement === this.$refs.rootEl ||
					this.$refs.rootEl.contains(document.activeElement)
				) {
					(this.$refs.buttonEl.$el as HTMLElement).focus();
					return;
				}
				break;
			}

			case true: {
				document.body.addEventListener("click", this.onClickBody);
				document.body.addEventListener("focusin", this.onFocusInBody, {
					capture: true,
					passive: false,
				});
				document.body.addEventListener("keydown", this.onKeyDown, {
					capture: true,
					passive: false,
				});
				break;
			}
		}
	}

	onFocusInBody(e: Event): void {
		if (!this.isOpen) {
			// Do nothing if not open
			return;
		}
		const target: HTMLElement = e.target as HTMLElement;
		// If we are focused not within this component, close it.
		if (
			target !== this.$refs.rootEl &&
			!this.$refs.rootEl.contains(target)
		) {
			this.isOpen = false;
			return;
		}
	}

	onKeyDown(e: KeyboardEvent): void {
		if (!this.isOpen) {
			// Do nothing if not open
			return;
		}
		const refListEl = this.$refs.listEl;
		if (!refListEl) {
			return;
		}
		switch (e.key) {
			case "Escape": {
				this.isOpen = false;
				return;
			}
		}
		if (e.key !== "ArrowDown" && e.key !== "ArrowUp") {
			return;
		}
		const items = DropdownMenu.getTabbableNodes(refListEl);
		if (items.length === 0) {
			return;
		}

		// Find position in list
		let currentIndex = -1;
		for (let i = 0; i < items.length; i++) {
			const el = items[i];
			if (el === document.activeElement) {
				currentIndex = i;
				break;
			}
		}

		// Get element to focus
		let nextElToFocus: HTMLElement | null = null;
		switch (e.key) {
			case "ArrowUp":
				if (currentIndex === -1) {
					// If no element selected, selected last item in the list
					nextElToFocus = items[items.length - 1];
				} else if (currentIndex - 1 >= 0) {
					nextElToFocus = items[currentIndex - 1];
				}
				break;
			case "ArrowDown":
				if (currentIndex === -1) {
					// If no element selected, selected first item in the list
					nextElToFocus = items[0];
				} else if (currentIndex + 1 < items.length) {
					nextElToFocus = items[currentIndex + 1];
				}
				break;
		}
		if (nextElToFocus === null) {
			return;
		}
		nextElToFocus.focus();
	}

	onClickBody(e: Event): void {
		const target: HTMLElement = e.target as HTMLElement;
		if (
			this.$refs.rootEl === target ||
			this.$refs.rootEl.contains(target)
		) {
			// If clicked within dropdown
			return;
		}
		this.isOpen = false;
	}

	onClickButton(e: Event) {
		this.isOpen = !this.isOpen;
	}

	/**
	 * getTabbableNodes will get a list elements that can currently be tabbed to.
	 * This will exclude elements that are disabled, input hidden (type="hidden") or visually hidden ("display: none" or "visibility: hidden)
	 */
	private static getTabbableNodes(container: HTMLElement): HTMLElement[] {
		// Based on: github.com/davidtheclark/tabbable
		const tabbableCandidates = container.querySelectorAll<
			HTMLElement & { disabled?: boolean }
		>(
			[
				"input",
				"select",
				"textarea",
				"a[href]",
				"button",
				"[tabindex]",
				"audio[controls]",
				"video[controls]",
				'[contenteditable]:not([contenteditable="false"])',
			].join(",")
		);
		const result: HTMLElement[] = [];
		for (const node of tabbableCandidates) {
			// NOTE(Jae): 2020-05-15
			// node.offsetParent === null is the cheapest way to see if "display: none".
			if (
				node.offsetParent === null ||
				getComputedStyle(node, null).visibility === "hidden" ||
				node.hidden ||
				node.disabled
			) {
				// Ignore detached / hidden elements
				continue;
			}
			result.push(node);
		}
		return result;
	}
}
