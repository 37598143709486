

























































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import EmployerSelector from "@/components/EmployerSelector.vue";
import axios, { axiosStatic } from "@/utils/ApiUtils";
import { employeeListURL, relatedFundsURL } from "@/constants/apiconstants";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import LeftRightFooter from "@/components/LeftRightFooter.vue";
import Container from "@/components/Container.vue";
import Button from "@/form/Button.vue";
import {
	getRcIdFromSelection,
	isOnlyOneChildlessEmployerSelected,
	isOnlyOneReportingCentreSelected,
} from "@/utils/EmployerSelectorUtils";
import { hasPermission } from "@/utils/PermissionUtils";
import { toastErrorMessage } from "@/plugins/toasts";
import { TSelectLevel } from "@/components/employerSelectorTypes";
import Grid from "@/grid/Grid.vue";
import { AccountStatus, FilterModel, PagedResult } from "@/grid/gridTypes";
import {
	ColDef,
	ColGroupDef,
	IServerSideDatasourceTyped,
	IServerSideGetRowsParamsTyped,
	ICellRendererParams,
} from "ag-grid-community";
import {
	columnDateFormatter,
	accountStatusFormatter,
} from "@/utils/CommonUtils";
import GridActionsRenderer from "@/grid/GridActionsRenderer.vue";
import { EmployeeGrid } from "@/models/EmployeeRow";
import { EmployerHierarchy } from "@/store/modules/persistent/persistentTypes";
import { createNamespacedHelpers } from "vuex";
import TextField from "@/form/TextField.vue";
import { FundModel, FundSelectModel } from "@/models/ContributionRow";
import MultiSelect from "@/form/MultiSelect.vue";
import { NO_RC_ERROR_MESSAGE } from "@/constants/constants";
import GridFilter from "@/components/GridFilter.vue";
import SelectField from "@/form/SelectField.vue";
import { accountStatus } from "@/constants/pageConstants";
import { downloadFile } from "@/utils/DownloadUtils";
import moment from "moment";
import { commitToModule, registerModule } from "@/store/modules/filters";

const { mapState } = createNamespacedHelpers("persistent");

@Component({
	components: {
		SelectField,
		GridFilter,
		TextField,
		EmployerSelector,
		Layout,
		LeftRightFooter,
		Container,
		Button,
		Grid,
		MultiSelect,
	},
	computed: mapState([
		"selectedEntities",
		"employerHierarchy",
		"definedBenefitEntities",
	]),
})
export default class EmployeeListPage
	extends Vue
	implements IServerSideDatasourceTyped
{
	private vuexStore = "employeesPage";

	/**
	 * Type the mapped persistent.selectedEntities getter.
	 * It is a computed property. Do not mutate it.
	 */
	selectedEntities!: string[];
	definedBenefitEntities!: string[];
	private fundOptions: FundSelectModel[] = [];

	readonly accountStatusOptions = accountStatus;

	/**
	 * Type the mapped persistent.employerHierarchy getter.
	 * It is a computed property. Do not mutate it.
	 */
	employerHierarchy!: EmployerHierarchy[];

	private pageContext: TSelectLevel = "ALL";
	private errorMessage: string | null = null;
	private gridReady = false;

	public $refs!: {
		gridEl: Grid;
	};

	private columnDefs: (ColGroupDef | ColDef)[] = [
		{
			headerName: "Employer",
			field: "employerName",
			minWidth: 200,
			resizable: true,
		},
		{
			headerName: "Reporting centre",
			field: "reportingCentreName",
			minWidth: 220,
			resizable: true,
		},
		{
			headerName: "Payroll ID",
			field: "payrollNo",
			minWidth: 30,
			resizable: true,
		},
		{
			headerName: "Title",
			field: "title",
			minWidth: 5,
			resizable: true,
		},
		{
			headerName: "Given names",
			field: "firstName",
			minWidth: 120,
			resizable: true,
		},
		{
			headerName: "Family name",
			field: "lastName",
			minWidth: 120,
			resizable: true,
		},
		{
			headerName: "Date of birth",
			field: "dateOfBirth",
			minWidth: 150,
			resizable: true,
			valueFormatter: columnDateFormatter,
			cellClass: "no-upscope",
		},
		{
			headerName: "Gender",
			field: "gender",
			minWidth: 10,
			resizable: true,
		},
		{
			headerName: "Status",
			field: "inactive",
			valueFormatter: accountStatusFormatter,
		},
	];

	private gridVMList: Vue[] = [];
	private onGridReady() {
		this.gridReady = true;
	}

	private filterModel: FilterModel = {
		inactive: {
			value: AccountStatus.ACTIVE,
			column: "inactive",
		},
		payroll: {
			value: "",
			column: "payrollId",
		},
		name: {
			value: "",
			column: "name",
		},
		usi: {
			value: "",
			column: "usi",
		},
		abn: {
			value: "",
			column: "abn",
		},
	};
	private filterSelectedFund: FundModel | null = null;

	beforeMount() {
		if (this.hasPermission("EDIT_EMPLOYEES")) {
			this.columnDefs.push({
				headerName: "Actions",
				cellRenderer: this.actionsRender,
				minWidth: 50,
				resizable: true,
				pinned: "right",
			});
		} else if (this.hasPermission("VIEW_EMPLOYEES")) {
			this.columnDefs.push({
				headerName: "VIEW",
				cellRenderer: this.actionsRender,
				minWidth: 50,
				resizable: true,
				pinned: "right",
			});
		}
		this.retrieveFundOptions();
	}

	created() {
		registerModule(this.$store, this.vuexStore, this.filterModel);
		this.filterModel = this.$store.getters[`${this.vuexStore}/filters`];
	}

	actionsRender(params: ICellRendererParams): HTMLElement {
		const vm = new Vue({
			el: document.createElement("div"),
			render: (createElement) => {
				return createElement(GridActionsRenderer, {
					props: {
						rowIndex: params.rowIndex,
						row: params.data,
						isEdit: this.hasPermission("EDIT_EMPLOYEES"),
						isView:
							this.hasPermission("VIEW_EMPLOYEES") &&
							!this.hasPermission("EDIT_EMPLOYEES"),
						isTerminate:
							params.data.dbBypassEnabled &&
							this.hasPermission("EDIT_EMPLOYEES"),
					},
					on: {
						clickEdit: this.onClickEdit,
						clickView: this.onClickView,
						clickTerminate: this.onClickEdit, // TODO (CHSN-2338): change to terminate
					},
				});
			},
		});
		this.gridVMList.push(vm);
		return vm.$el as HTMLElement;
	}

	updateFilterSelectedFund(value: FundModel) {
		this.filterSelectedFund = value;
		this.filterModel.usi.value = value !== null ? value.usi : "";
		this.filterModel.abn.value = value !== null ? value.abn : "";

		this.onApplyFilter();
	}

	onResetFilter() {
		this.filterSelectedFund = null;
		this.filterModel.usi.value = "";
		this.filterModel.abn.value = "";
		this.filterModel.name.value = "";
		this.filterModel.payroll.value = "";
		this.filterModel.inactive.value = AccountStatus.ACTIVE;
		this.onApplyFilter();
	}

	onClickEdit({ row }: { row: EmployeeGrid }) {
		this.$router.push({
			name: "Edit Employee",
			params: {
				mode: "edit",
				id: String(row.id),
				reportingCentreId: String(row.reportingCentreId),
			},
		});
	}

	onClickView({ row }: { row: EmployeeGrid }) {
		this.$router.push({
			name: "View Employee",
			params: {
				mode: "view",
				id: String(row.id),
				reportingCentreId: String(row.reportingCentreId),
			},
		});
	}

	private reloadGrid(): void {
		this.gridReady = false;
		if (!this.$refs.gridEl) {
			return;
		}
		this.$refs.gridEl.reload();
		this.gridReady = true;
	}

	get selectedReportingCenter() {
		return this.$store.state.persistent.selectedEntities;
	}
	@Watch("selectedReportingCenter")
	onSelectedReportingCenterChanged() {
		this.retrieveEmployees();
		this.retrieveFundOptions();
	}

	@Watch("definedBenefitEntities")
	onDefinedBenefitEntitiesChanged() {
		this.$forceUpdate();
	}

	private retrieveEmployees(): void {
		this.reloadGrid();
	}

	getRows(params: IServerSideGetRowsParamsTyped<EmployeeGrid>): void {
		if (
			isOnlyOneChildlessEmployerSelected(
				this.selectedEntities,
				this.employerHierarchy
			)
		) {
			this.errorMessage = NO_RC_ERROR_MESSAGE;
			params.successCallback([]);
			return;
		}
		const value =
			this.$store.state.persistent.selectedEntities === null
				? []
				: this.$store.state.persistent.selectedEntities;

		params.request.filterModel = Object.keys(this.filterModel).map(
			(key) => {
				return this.filterModel[key];
			}
		);

		axios
			.post<PagedResult<EmployeeGrid>>(
				employeeListURL(),
				{
					// Pass in <Grid> parameters
					grid: params.request,
					// Then override or add our custom parameters
					entities: value,
				},
				{
					headers: {
						"Content-Type": "application/json",
					},
					cancelToken: params.cancelToken,
				}
			)
			.then((response) => {
				const pagedResultData = response.data;
				params.successCallback(pagedResultData);
			})
			.catch((error) => {
				if (axiosStatic.isCancel(error)) {
					return;
				}
				toastErrorMessage(parseErrorMessage(error));
			});
	}

	hasPermission(permission: string) {
		return hasPermission(permission);
	}
	private gridRequest = {
		filters: Object.keys(this.filterModel).map((key) => {
			return this.filterModel[key];
		}),
		orders: null,
	};
	private onClickAdd(isDbEmployee = false) {
		if (this.selectedEntities.length <= 0) {
			this.errorMessage = "Please select a reporting centre";
			return;
		}

		if (
			!isOnlyOneReportingCentreSelected(
				this.selectedEntities,
				this.employerHierarchy
			)
		) {
			this.errorMessage = "Please select only one reporting centre";
			return;
		}

		if (!this.hasPermission("ADD_EMPLOYEES")) {
			toastErrorMessage(
				"You do not have permissions to add employee for the selected Reporting Centre"
			);
			return;
		}

		if (!isDbEmployee) {
			this.$router.push({
				name: "Add Employee",
				params: {
					mode: "add",
					reportingCentreId: String(
						getRcIdFromSelection(this.$store)
					),
				},
			});
		} else {
			this.$router.push({
				name: "Add DB Employee",
				params: {
					mode: "add",
					reportingCentreId: String(
						getRcIdFromSelection(this.$store)
					),
				},
			});
		}
	}

	private onApplyFilter() {
		commitToModule(this.$store, this.vuexStore, this.filterModel);
		this.$refs.gridEl.reload();
	}

	clearErrorMessage() {
		this.errorMessage = null;
	}

	private filterFormFundLabel(option: FundModel): string {
		if (option.usi !== null && option.usi.trim() !== "") {
			return option.name + " - " + option.usi + " - " + option.abn;
		} else {
			return option.name + " - " + option.abn;
		}
	}

	private retrieveFundOptions() {
		if (
			isOnlyOneChildlessEmployerSelected(
				this.selectedEntities,
				this.employerHierarchy
			)
		) {
			return;
		}
		const value =
			this.$store.state.persistent.selectedEntities === null
				? []
				: this.$store.state.persistent.selectedEntities;
		axios
			.post(
				relatedFundsURL(),
				{
					entities: value,
				},
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			)
			.then((response) => {
				this.fundOptions = response.data;
				const fundModel = this.fundOptions.find(
					(fund) =>
						fund.abn === this.filterModel.abn.value.toString() &&
						fund.usi === this.filterModel.usi.value.toString()
				);
				if (fundModel) {
					this.updateFilterSelectedFund(fundModel);
					this.reloadGrid();
				}
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
	}

	get canAddDbEmployee() {
		return (
			this.selectedEntities.length === 1 &&
			this.definedBenefitEntities.includes(this.selectedEntities[0])
		);
	}

	exportCsv() {
		const entities = [...this.$store.state.persistent.selectedEntities];
		downloadFile(
			"/api/employees/export",
			`Employees_${moment().format("YYYY-MM-DD")}.csv`,
			{ entities }
		);
	}
}
