

































import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({
	components: {},
})
export default class PortalNotifications extends Vue {
	handleClose(customFunc: undefined | (() => void), closeFn: () => void) {
		if (customFunc) {
			customFunc();
		}
		closeFn();
	}
}
