


















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import SubHeading from "@/components/SubHeading.vue";

@Component({
	components: { SubHeading },
})
export default class FieldGroup extends Vue {
	@Prop([String]) readonly label!: string;
	@Prop(Array) readonly icon!: string[];
}
