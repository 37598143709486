





























import { Component } from "vue-property-decorator";
import axios from "@/utils/ApiUtils";
import { getRegistrationWizardSubmitApi } from "@/constants/apiconstants";
import { EmployerRegistration } from "@/models/EmployerRegistration";
import FieldHolder from "@/form/FieldHolder.vue";
import EmployerRegistrationDetails from "@/components/EmployerRegistrationDetails.vue";
import {
	ContributionSubmissionMethod,
	DateFormat,
} from "@/store/modules/registrationofinterest/registrationOfInterestTypes";
import BaseRegistrationOfInterestStep from "./BaseRegistrationOfInterestStep.vue";
import { createNamespacedHelpers } from "vuex";
import { toastErrorMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";

const { mapState } = createNamespacedHelpers("registrationOfInterest");

const displayedFieldsNames = [
	"authorizedContactFirstName",
	"authorizedContactLastName",
	"authorizedContactPosition",
	"authorizedContactEmail",
	"authorizedContactMobile",
	"abn",
	"registeredName",
	"tradingName",
	"addressLine1",
	"addressLine2",
	"suburb",
	"postcode",
	"state",
	"refundAccountBsb",
	"refundAccountNumber",
	"refundAccountName",
	"contributionSubmissionMethod",
	"dateFormat",
	"defaultFund",
	"defaultFundOther",
	"numberOfEmployees",
];

const formFieldNames = [...displayedFieldsNames, "secretCode"];

@Component({
	components: {
		FieldHolder,
		EmployerRegistrationDetails,
	},
	computed: {
		...mapState(formFieldNames),
	},
})
export default class FinaliseStep extends BaseRegistrationOfInterestStep {
	authorizedContactFirstName!: string;
	authorizedContactLastName!: string;
	authorizedContactPosition!: string;
	authorizedContactEmail!: string;
	authorizedContactMobile!: string;
	abn!: string;
	registeredName!: string;
	tradingName!: string;
	addressLine1!: string;
	addressLine2!: string;
	suburb!: string;
	postcode!: string;
	state!: string;
	refundAccountBsb!: string;
	refundAccountNumber!: string;
	refundAccountName!: string;
	contributionSubmissionMethod!: ContributionSubmissionMethod;
	dateFormat!: DateFormat;
	defaultFund!: string;
	defaultFundOther!: string;
	numberOfEmployees!: number;
	secretCode!: string;

	finalConfirmation = false;

	formSubmissionErrors: string[] = [];

	get registrationDetails(): EmployerRegistration {
		return displayedFieldsNames.reduce((formData, fieldName) => {
			(formData as any)[fieldName] = (this as any)[fieldName];
			return formData;
		}, {}) as EmployerRegistration;
	}

	submitForm() {
		const formData = formFieldNames.reduce((formData, fieldName) => {
			(formData as any)[fieldName] = (this as any)[fieldName];
			return formData;
		}, {});

		return axios
			.post(getRegistrationWizardSubmitApi(this.secretCode), formData)
			.catch((error) => {
				this.formSubmissionErrors = [error.response.data.message];
				throw error;
			});
	}

	private onLoadPdsFsg() {
		const filename =
			"Combined FSG PDS - Iress SuperConnector 3 April 2024.pdf";
		axios
			.get(`files/` + filename, {
				responseType: "blob",
			})
			.then((response) => {
				const blob = new Blob([response.data], {
					type: "application/pdf",
				});
				if (window.navigator && window.navigator.msSaveOrOpenBlob) {
					window.navigator.msSaveOrOpenBlob(blob, filename);
				} else {
					const url = window.URL.createObjectURL(blob);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", filename);
					link.click();
				}
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
	}

	async validate(): Promise<boolean> {
		if (!(await (this.$refs.validationObserver as any).validate())) {
			return false;
		}

		return this.submitForm()
			.then(() => true)
			.catch(() => false);
	}
}
