



import { Component } from "vue-property-decorator";
import Vue from "vue";
import {
	displayToast,
	toastErrorMessage,
	ToastMessageType,
	TToastMessage,
} from "@/plugins/toasts";
import { RoutePath } from "@/router/routePath";
import axios from "@/utils/ApiUtils";
import {
	userNewReleaseNotesSinceLastLogin,
	userPasswordExpiring,
} from "@/constants/apiconstants";
import { parseErrorMessage } from "@/utils/ErrorUtils";

@Component({
	components: {},
})
export default class UserLoginNotification extends Vue {
	private readonly warningToast: TToastMessage = {
		message: "Your password is about to expire, click here to change it.",
		title: "Password expiring",
		type: ToastMessageType.WARNING,
		onClick: () => {
			this.$router.push(RoutePath.Profile);
		},
	};
	private readonly releaseNotesToast: TToastMessage = {
		message: "New release notes are available, click here to view them",
		type: ToastMessageType.INFO,
		onClick: () => {
			this.$router.push(RoutePath.ReleaseNotes);
		},
	};

	mounted() {
		axios
			.get<boolean>(userPasswordExpiring())
			.then((resp) => {
				if (resp.data) {
					displayToast(this.warningToast);
				}
			})
			.catch((error) => toastErrorMessage(parseErrorMessage(error)));
		// Verify this check hasn't been performed in this session. Refreshing causes this component to remount
		if (this.$store.getters["persistent/checkForNewReleaseForUser"]) {
			axios
				.get<boolean>(userNewReleaseNotesSinceLastLogin())
				.then((res) => {
					if (res.data) {
						displayToast(this.releaseNotesToast);
					}
				});
			// We don't want to perform a re-check until the user has logged out and back in.
			this.$store.commit(
				"persistent/setCheckForNewReleaseForUser",
				false
			);
		}
	}
}
