



import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({})
export default class StyledCellRenderer extends Vue {
	private params: any;

	get styleFactory() {
		if (this.params.styleFactory) {
			return this.params.styleFactory;
		}
		return "";
	}

	get textFactory() {
		if (this.params.textFactory) {
			return this.params.textFactory;
		}
		return "";
	}
	refresh(params: any) {
		if (params.value !== this.params.value) {
			this.params = params;
		}
		return true;
	}
}
