
















































































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import ButtonBehaviour from "@/form/ButtonBehaviour.vue";
import { sanitizeUrl } from "@braintree/sanitize-url";

@Component({
	components: {
		ButtonBehaviour,
	},
})
export default class Button extends Vue {
	@Prop({ type: String, default: "" }) tag!: string;

	@Prop({ type: String, default: "button" }) type!: string;

	/**
	 * variant is the styling the button has.
	 *
	 * Defaults to secondary style (ie. a simple grey button) as most buttons will most likely
	 * want to default to that. The primary button styling is used for submission.
	 */
	@Prop({ type: String, default: "" }) variant!: string;

	@Prop({ type: String, default: "" }) href!: string;
	@Prop({ type: String, default: "" }) dataCy!: string;

	/**
	 * block will make the element span the full width of the parent, just like Bootstrap Vue.
	 */
	@Prop({ type: Boolean, default: false }) block!: boolean;

	/**
	 * icon expects a FontAwesome array
	 * ie. ['fal', 'plus']
	 */
	@Prop({ type: Array }) icon!: string[];

	/**
	 * size has two possible types 'md' and 'lg'.
	 */
	@Prop({ type: String, default: "md" }) size!: string;

	@Prop([Boolean]) disabled!: boolean;

	/**
	 * for is the "for" attribute for linking a <label> button to an <input> element.
	 */
	@Prop({ type: String, default: "" }) for!: string;

	/**
	 * clickEvent helps us only bind the @click event on the element if there is
	 * a listener.
	 *
	 * Source:
	 * - https://github.com/vuejs/vue/issues/7349#issuecomment-621699548
	 * - https://jsfiddle.net/pbastowski/v0wt5qpo/63/
	 */
	get clickEvent(): "click" | null {
		return this.$listeners && this.$listeners.click ? "click" : null;
	}

	onClick(e: Event) {
		this.$emit("click", e);
	}

	get currentVariant(): string {
		if (this.variant !== "") {
			return this.variant;
		}
		if (this.type === "submit") {
			return "primary";
		}
		return "";
	}

	/**
	 * Using a dynamic href attribute in any Vue component will cause SonarQube to complain about unsanitised urls.
	 */
	get sanitisedHref(): string {
		if (this.href === "") {
			return "";
		}
		return sanitizeUrl(this.href);
	}
}
