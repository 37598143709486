import { ActionTree } from "vuex";
import { RootState } from "@/store/root";
import { EmployerHierarchy, PersistentState } from "./persistentTypes";
import axios from "axios";
import {
	currentUserURL,
	refreshTokenURL,
	fetchEmployerHierarchyURL,
	canUserSeeInsightsMenu,
	definedBenefitFeaturesEnabled,
} from "@/constants/apiconstants";
import { StatePhase } from "@/store/modules/login/loginTypes";
import instance from "@/utils/ApiUtils";
import { toastErrorMessage } from "@/plugins/toasts";
import { Logger } from "@/utils/logger";
import { parseErrorMessage } from "@/utils/ErrorUtils";

export const actions: ActionTree<PersistentState, RootState> = {
	setCaptchaSiteKey({ commit }, payload: string) {
		commit("captchaSiteKeySet", payload);
	},
	setAuthToken({ commit }, payload) {
		commit("authTokenSet", payload);
	},
	async refreshTokenFetch({ commit, getters }, payload): Promise<any> {
		if (getters.refreshTokenPhase === StatePhase.IN_PROGRESS) {
			return getters.refreshingCall;
		}
		commit("refreshTokenInProgress");
		const formData = new FormData();
		formData.append("refresh_token", getters.refreshToken);

		const refreshingCall = axios({
			url: refreshTokenURL(),
			method: "POST",
			data: formData,
		}).then((response) => {
			this.dispatch("persistent/setAuthToken", response);
			commit("refreshTokenSuccess");
			commit("refreshTokenCall", null);
			return Promise.resolve(true);
		});
		commit("refreshTokenCall", refreshingCall);

		return refreshingCall;
	},
	fetchCurrentUser({ commit }, payload): any {
		return instance({
			url: currentUserURL(),
			method: "GET",
		}).then(
			(response) => {
				Logger.log(response);
				commit("setCurrentUser", response);
			},
			(error) => {
				toastErrorMessage("Unable to load user details");
			}
		);
	},
	async fetchCanUserSeeInsightsMenuContent(): Promise<any> {
		return instance
			.get(canUserSeeInsightsMenu())
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				if (error.response.status === 403) {
					toastErrorMessage(
						"Your current permissions do not allow you to view this data."
					);
				} else {
					toastErrorMessage(parseErrorMessage(error));
				}
			});
	},
	fetchEmployerHierarchy({ commit }, payload): any {
		return instance
			.get<{ employerTreeHierarchyList: EmployerHierarchy[] }>(
				fetchEmployerHierarchyURL()
			)
			.then(
				(response) => {
					commit(
						"setEmployerHierarchy",
						response.data.employerTreeHierarchyList
					);
					// TODO: Clear state.selectedEntities or update it (remove those are not in state.employerHierarchy)
				},
				(error) => {
					toastErrorMessage("Unable to load employer hierarchy");
				}
			);
	},
	fetchDefinedBenefitFeaturesEnabled({ commit }): Promise<any> {
		return instance({
			url: definedBenefitFeaturesEnabled(),
			method: "GET",
		}).then(
			(response) => {
				Logger.log(response);
				commit("setDefinedBenefitFeaturesEnabled", response.data);
			},
			(error) => {
				toastErrorMessage(parseErrorMessage(error));
			}
		);
	},
};
