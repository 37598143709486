export interface TranslationMapping {
	value: string | boolean | null;
	translatedValue: string;
	style?: string;
}
// Text lookup
export interface TableFilter {
	column: string;
	value: string | string[] | boolean;
}
// Column sort
export interface TableFilterSortColumns {
	column: string; // Column name
	dir: string; // Direction: 'asc' or 'desc'
}

export interface FilterModel {
	[key: string]: TableFilter;
}

export interface PagedResult<Type> {
	elements: Type[];
	limit: number;
	offset: number;
	totalElements: number;
}

export function isPagedResult<Type>(
	maybePagedResult: any
): maybePagedResult is PagedResult<Type> {
	return (
		typeof maybePagedResult === "object" &&
		maybePagedResult.offset !== undefined &&
		maybePagedResult.limit !== undefined &&
		maybePagedResult.totalElements !== undefined
	);
}
/**
 * This should correspond to the Java class au.com.iress.clearinghouse.portal.rds.AccountStatus
 * used by employee and portal user
 */
export enum AccountStatus {
	ACTIVE = "ACTIVE",
	INACTIVE = "INACTIVE",
	ALL = "ALL",
}

export enum MessageType {
	MROR = "MROR",
	CTER = "CTER",
	ALL = "ALL",
}

export enum YesNoAllType {
	YES = "YES",
	NO = "NO",
	ALL = "ALL",
}
