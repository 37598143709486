















































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { FilterForm, FundModel } from "@/models/ContributionRow";
import { FilterOptions } from "@/pages/contribution/ContributionPageTypes";
import MultiSelect from "@/form/MultiSelect.vue";
import TextField from "@/form/TextField.vue";
import SelectField from "@/form/SelectField.vue";
import Button from "@/form/Button.vue";
import GridFilter from "@/components/GridFilter.vue";
import ElementRenderer, {
	ElementSchema,
} from "@/elementschema/ElementRenderer.vue";

@Component({
	components: {
		ElementRenderer,
		GridFilter,
		SelectField,
		TextField,
		MultiSelect,
		Button,
	},
})
export default class ContributionEmployeeFilterForm extends Vue {
	@Prop({ default: null }) readonly filterForm!: FilterForm | null;
	@Prop({ type: Boolean, default: true }) private gridReady!: boolean;
	@Prop({ type: Boolean, default: true }) private filterByErrors!: boolean;

	/**
	 * filterFormLiveData are the current filter options set in the form.
	 * *WARNING:* These are not applied to the request unless the user hit the "Filter" button.
	 */
	filterFormLiveData: FilterOptions =
		ContributionEmployeeFilterForm.getDefaultFilterOptions();

	private static getDefaultFilterOptions(): FilterOptions {
		return {
			fileRegRef: 0,
			cfsSeverity: "",
			abn: "",
			usi: "",
			familyName: "",
		};
	}
	updateFilterSelectedFund(value: FundModel) {
		this.filterFormLiveData.selectedFund = value;
		this.onApplyFilter();
	}

	private filterFormFundLabel(option: FundModel): string {
		if (option.usi !== null && option.usi.trim() !== "") {
			return option.name + " - " + option.usi + " - " + option.abn;
		} else {
			return option.name + " - " + option.abn;
		}
	}

	private onApplyFilter(): void {
		const filterFormData = {
			abn: this.filterFormLiveData.selectedFund?.abn,
			usi: this.filterFormLiveData.selectedFund?.usi,
			fileRegRef: this.filterFormLiveData.fileRegRef,
			cfsSeverity: this.filterFormLiveData.cfsSeverity,
			familyName: this.filterFormLiveData.familyName,
		};
		this.$emit("update-filter-form", filterFormData);
	}

	get fieldMap(): { string: ElementSchema } | undefined {
		if (!this.filterForm?.formSchema) {
			return undefined;
		}
		return this.filterForm.formSchema.fieldMap;
	}

	get fundOptions() {
		return this.filterForm?.fundOptions;
	}

	private resetFilters() {
		this.filterFormLiveData =
			ContributionEmployeeFilterForm.getDefaultFilterOptions();
		this.$emit("update-filter-form", this.filterFormLiveData);
	}
}
