






























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import ButtonBehaviour from "@/form/ButtonBehaviour.vue";

@Component({
	components: {
		ButtonBehaviour,
	},
})
export default class FundFormatActionsRenderer<RowType> extends Vue {
	/**
	 * rowIndex is the index of the record
	 */
	@Prop(Number) rowIndex!: number;

	/**
	 * row id is the employer fund format item id
	 */
	@Prop(Number) fundFormatId!: number;

	@Prop(String) rowFundFormatName!: string;
	@Prop(Boolean) deletable!: boolean;

	private onClickDelete(event: MouseEvent): void {
		event.stopPropagation();

		this.$emit("clickDelete", {
			rowIndex: this.rowIndex,
			fundFormatId: this.fundFormatId,
		});
	}
}
