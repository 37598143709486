var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"validationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('h2',{staticClass:"h3"},[_vm._v("Authorised Contact")]),_c('p',[_vm._v(" Please confirm the details of the Authorised Contact who is responsible for payment of superannuation contributions and authorised to create the new Clearing House account. ")]),_c('TextField',{attrs:{"value":_vm.authorizedContactFirstName,"label":"First Name","rules":"required|max:99"},on:{"input":function($event){return _vm.updateField({ authorizedContactFirstName: $event })}}}),_c('TextField',{attrs:{"value":_vm.authorizedContactLastName,"label":"Surname","rules":"required|max:100"},on:{"input":function($event){return _vm.updateField({ authorizedContactLastName: $event })}}}),_c('TextField',{attrs:{"value":_vm.authorizedContactPosition,"label":"Position in Company","rules":"max:80"},on:{"input":function($event){return _vm.updateField({ authorizedContactPosition: $event })}}}),_c('TextField',{staticClass:"mb-0",attrs:{"value":_vm.authorizedContactEmail,"disabled":_vm.emailValidationStatus ===
				_vm.ValidationStatus.REQUESTED_TO_SEND_CODE ||
			_vm.emailValidationStatus === _vm.ValidationStatus.VERIFYING_CODE,"name":"email","rules":"required|email|max:254","errors":_vm.authorizedContactEmailErrors},on:{"input":_vm.handleAuthorizedContactEmailInput}}),_c('small',{staticClass:"form-text mb-3"},[_vm._v(" Please confirm your email address. Your email address will be used as your log-in username. ")]),(
			_vm.emailValidationStatus !== _vm.ValidationStatus.COMPLETED &&
			_vm.authorizedContactEmail !== '' &&
			errors.email.length === 0
		)?_c('Button',{staticClass:"mb-3",attrs:{"disabled":_vm.emailValidationStatus ===
				_vm.ValidationStatus.REQUESTED_TO_SEND_CODE ||
			_vm.emailValidationStatus === _vm.ValidationStatus.VERIFYING_CODE,"variant":"primary"},on:{"click":_vm.requestToSendEmailValidationCode}},[_vm._v("Send verification code")]):_vm._e(),(
			_vm.emailValidationStatus === _vm.ValidationStatus.CODE_SENT ||
			_vm.emailValidationStatus === _vm.ValidationStatus.VERIFYING_CODE
		)?[_c('TextField',{attrs:{"disabled":_vm.emailValidationStatus === _vm.ValidationStatus.VERIFYING_CODE,"label":"Email Validation Code","rules":"required|digits:6","errors":_vm.authorizedContactEmailValidationErrors},model:{value:(_vm.emailValidationCode),callback:function ($$v) {_vm.emailValidationCode=$$v},expression:"emailValidationCode"}}),_c('small',{staticClass:"form-text mb-3"},[_vm._v(" Please check your junk mail if you do not receive this code within 5 minutes. ")])]:_vm._e(),_c('TextField',{attrs:{"value":_vm.authorizedContactMobile,"disabled":_vm.mobileValidationStatus ===
				_vm.ValidationStatus.REQUESTED_TO_SEND_CODE ||
			_vm.mobileValidationStatus === _vm.ValidationStatus.VERIFYING_CODE,"name":"mobile","rules":"required|digits:10","errors":_vm.authorizedContactMobileErrors},on:{"input":_vm.handleAuthorizedContactMobileInput}}),(
			_vm.mobileValidationStatus !== _vm.ValidationStatus.COMPLETED &&
			_vm.authorizedContactMobile !== '' &&
			errors.mobile.length === 0
		)?_c('Button',{staticClass:"mb-3",attrs:{"disabled":_vm.mobileValidationStatus ===
				_vm.ValidationStatus.REQUESTED_TO_SEND_CODE ||
			_vm.mobileValidationStatus === _vm.ValidationStatus.VERIFYING_CODE,"variant":"primary"},on:{"click":_vm.requestToSendMobileValidationCode}},[_vm._v("Send verification code")]):_vm._e(),(
			_vm.mobileValidationStatus === _vm.ValidationStatus.CODE_SENT ||
			_vm.mobileValidationStatus === _vm.ValidationStatus.VERIFYING_CODE
		)?_c('TextField',{attrs:{"disabled":_vm.mobileValidationStatus === _vm.ValidationStatus.VERIFYING_CODE,"label":"Phone Validation Code","errors":_vm.authorizedContactMobileValidationErrors,"rules":"required|digits:6"},model:{value:(_vm.mobileValidationCode),callback:function ($$v) {_vm.mobileValidationCode=$$v},expression:"mobileValidationCode"}}):_vm._e(),_c('FieldHolder',{attrs:{"rules":"required|checked","name":"authorisedContactConfirmation"}},[_c('b-form-checkbox',{model:{value:(_vm.authorizedContactConfirmation),callback:function ($$v) {_vm.authorizedContactConfirmation=$$v},expression:"authorizedContactConfirmation"}},[_vm._v(" I confirm that I am the delegated authority for payroll and superannuation administration and have the authority to set up a Clearing House account. I acknowledge that you collect and store my personal information in accordance with the "),_c('a',{attrs:{"href":"https://www.iress.com/resources/legal/data-protection/","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("Iress Data Protection Policy")]),_vm._v(" in order to provide the Clearing House and any other products and services that I have requested from you. ")])],1),_c('FieldHolder',{attrs:{"rules":"validated"}},[_c('b-form-checkbox',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"checked":Boolean(_vm.emailValidationCode && _vm.mobileValidationCode)}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }