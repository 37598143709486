































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";

import { RoutePath } from "@/router/routePath";
import Layout from "@/components/Layout.vue";
import SAFFUploaderForm from "@/components/SAFFUploaderForm.vue";
import Card from "@/components/Card.vue";
import Button from "@/form/Button.vue";
import {
	checkPermissionForRoute,
	getIconForRoute,
	getTitleForRoute,
} from "@/utils/RouteUtils";
import AccountBalanceTotal from "@/components/AccountBalanceTotal.vue";
import AlertsOverview from "@/components/AlertsOverview.vue";
import NewsWidget from "@/components/NewsWidget.vue";
import InsightCarousel from "@/components/InsightCarousel.vue";
import { hasPermission } from "@/utils/PermissionUtils";
import axios from "@/utils/ApiUtils";
import { canUserSeeInsightsMenu } from "@/constants/apiconstants";
import { createNamespacedHelpers } from "vuex";

const { mapGetters } = createNamespacedHelpers("persistent");

@Component({
	components: {
		Layout,
		SAFFUploaderForm,
		Button,
		Card,
		AccountBalanceTotal,
		AlertsOverview,
		NewsWidget,
		InsightCarousel,
	},
	computed: {
		...mapGetters({
			employerPermissions: "employerPermissions",
			parentOrganisationPermissions: "parentOrganisationPermissions",
			definedBenefitEntities: "definedBenefitEntities",
		}),
	},
})
export default class DashboardPage extends Vue {
	private readonly alertsLink = RoutePath.Alerts;

	private readonly manualGrid = RoutePath.CreateBatch;
	private readonly verticallyCentered = false;
	private readonly accountBalanceLink = RoutePath.AccountBalance;
	private readonly insightLink = RoutePath.Insight;
	private hasPermission = hasPermission;
	private insightsEnabled = false;

	private employerPermissions!: () => [];
	private parentOrganisationPermissions!: () => [];
	private definedBenefitEntities!: () => [];

	private checkPermission(href: string): boolean {
		return checkPermissionForRoute(href, this.$route);
	}
	/**
	 * getRouteTitle will retrieve the "name" property from a route
	 * based on the given URL segment.
	 */

	private getRouteTitle(href: string): string {
		return getTitleForRoute(href, this.$route);
	}

	private getRouteIcon(href: string): string[] {
		return getIconForRoute(href, this.$route);
	}

	mounted() {
		axios.get(canUserSeeInsightsMenu()).then((res) => {
			this.insightsEnabled = res.data;
		});
	}

	get hasParentOrEmployerPermissions() {
		return (
			this.employerPermissions.length > 0 ||
			this.parentOrganisationPermissions.length > 0
		);
	}

	get alertsAndMessagesButtonLabel() {
		// definedBenefitEntities will return an empty array if db features are off.
		if (
			this.definedBenefitEntities.length > 0 &&
			hasPermission("VIEW_MESSAGE")
		) {
			return "Manage alerts & messages";
		}
		return "Manage alerts";
	}
}
