import { MutationTree } from "vuex";

import { EmployerHierarchy, PersistentState } from "./persistentTypes";
import { StatePhase } from "@/store/modules/login/loginTypes";
import router from "@/router";

interface AuthResponse {
	access_token: string; // ie. "a657f3b7-fa7b-4876-ab5a-5433e5e1d37b"
	expires_in: number; // 299
	refresh_token: string; // "ad303344-8ac5-48b6-b879-a67c7f0ee262"
	scope: string; // "read write trust"
	token_type: string; // "bearer"
}

export const mutations: MutationTree<PersistentState> = {
	authTokenSet(state, payload) {
		const data: AuthResponse = payload.data;
		if (!data) {
			throw new Error("No data from response");
		}
		if (!data.access_token) {
			throw new Error("Missing access_token from response.");
		}
		if (!data.refresh_token) {
			throw new Error("Missing refresh_token from response.");
		}
		if (!data.refresh_token) {
			throw new Error("Missing expires_in from response.");
		}
		const now = new Date();
		state.accessToken = data.access_token;
		state.refreshToken = data.refresh_token;
		now.setSeconds(now.getSeconds() + data.expires_in);
		state.tokenExpiry = now.toString();

		window.sessionStorage.setItem("persistent", JSON.stringify(state));
	},
	refreshTokenSuccess(state) {
		state.refreshTokenPhase = StatePhase.DEFAULT;
	},
	refreshTokenInProgress(state) {
		state.refreshTokenPhase = StatePhase.IN_PROGRESS;
	},
	refreshTokenCall(state, payload) {
		state.refreshingCall = payload;
	},
	setCurrentUser(state, payload) {
		const data = payload.data;
		state.name = data.name;
		state.brandId = data.brandId;
		state.username = data.username;
		state.iressOperations = data.iressOperations;
		state.sponsorPermissions = data.sponsorPermissions;
		state.parentOrganisationPermissions =
			data.parentOrganisationPermissions;
		state.employerPermissions = data.employerPermissions;
		state.reportingCentrePermissions = data.reportingCentrePermissions;
		state.domainPermissions = data.domainPermissions;
		state.employerHierarchy = data.employerTreeHierarchy;
		state.definedBenefitEntities = data.definedBenefitEntities;
		window.sessionStorage.setItem("persistent", JSON.stringify(state));
		router.push("/dashboard");
	},
	setEmployerHierarchy(state, payload: EmployerHierarchy[]) {
		state.employerHierarchy = payload;
		window.sessionStorage.setItem("persistent", JSON.stringify(state));
	},
	setSelectedEntities(state, payload: string[]) {
		state.selectedEntities = payload;
		window.sessionStorage.setItem("persistent", JSON.stringify(state));
	},
	setSelectedEntitiesInsight(state, payload: string[]) {
		state.selectedEntitiesInsight = payload;
		window.sessionStorage.setItem("persistent", JSON.stringify(state));
	},
	setDefinedBenefitFeaturesEnabled(state, payload: boolean) {
		state.definedBenefitFeaturesEnabled = payload;
		window.sessionStorage.setItem("persistent", JSON.stringify(state));
	},
	setCheckForNewReleaseForUser(state, payload: boolean) {
		state.checkForNewReleaseForUser = payload;
		window.sessionStorage.setItem("persistent", JSON.stringify(state));
	},
};
