


































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import FieldHolder from "@/form/FieldHolder.vue";
import ErrorList from "@/components/ErrorList.vue";
import { CommonField } from "@/form/CommonField";
import { RadioOption } from "@/form/FieldOptions";

@Component({
	components: {
		FieldHolder,
		ErrorList,
	},
})
export default class RadioGroup extends Vue implements CommonField {
	private static uniqueId = 0;

	/**
	 * id must be globally unique in a HTML document, we guarantee this by making each render
	 * of this component have unique ID number appended to the end to avoid clashes.
	 *
	 * We need ids mostly to connect <label> and <input> elements together for accessibility.
	 */
	readonly id = "RadioGroup_" + ++RadioGroup.uniqueId;

	@Prop([String]) readonly name!: string;

	@Prop([String]) readonly label!: string;

	@Prop([String]) readonly placeholder!: string;

	@Prop({ type: [String], default: "" }) readonly value!: string;

	@Prop([String]) readonly rules!: string;

	@Prop(Boolean) readonly readonly!: boolean;

	@Prop(Boolean) readonly disabled!: boolean;
	@Prop(Boolean) readonly stacked!: boolean;

	/**
	 * Add additional custom error messages to this field.
	 */
	@Prop([Array]) readonly errors!: string[];

	/**
	 * Whether errors are shown externally.
	 */
	@Prop(Boolean) readonly errorsShownExternally!: boolean;

	@Prop() options!: RadioOption[];

	onChecked(e: any) {
		this.$emit("input", e);
	}

	get isChecked() {
		return this.value;
	}

	/**
	 * computedReadonly will get if the field cannot be modified either due to
	 * having the readonly flag set or if there are no options to choose from.
	 */
	get computedReadonly(): boolean {
		return (
			this.readonly === true || !this.options || this.options.length === 0
		);
	}

	/**
	 * readonlyValue will get the label of the current selected option if available
	 * rather than give the raw value.
	 */
	get readonlyValue(): string | undefined {
		let r = null;
		const value = String(this.value);
		if (!this.options || this.options.length === 0) {
			return value;
		}
		for (const option of this.options) {
			if (option.value !== value) {
				continue;
			}
			r = option.text;
			break;
		}
		if (r === null) {
			// If cannot find value in options list, just use value
			// as is.
			r = this.value !== null ? String(this.value) : "";
		}
		return r;
	}
}
