


















































































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Grid from "../grid/Grid.vue";
import { BatchDetailResponse } from "../pages/batchType";
import BatchSummary from "@/components/BatchSummary.vue";
import BatchUpdates from "@/components/BatchUpdates.vue";
import BatchFieldsRemoved from "@/components/BatchFieldsRemoved.vue";
import BatchDetailsPage from "@/pages/BatchDetailsPage.vue";
import ContributionEmployeeList from "@/components/ContributionEmployeeList.vue";
import PaymentDetail from "@/components/PaymentDetail.vue";
import SuperStreamResponses from "@/components/SuperStreamResponses.vue";
import { AxiosResponse } from "axios";
import axios from "@/utils/ApiUtils";
import {
	canViewBatchURL,
	getAllFundFieldMappingUrl,
} from "@/constants/apiconstants";
import { toastErrorMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import { getBatchUpdates } from "@/rest/BatchResource";
import { FinalBatchReviewUpdateRecord } from "@/models/FinalBatchReviewRecord";
import Button from "@/form/Button.vue";
import {
	downloadAllBatchUpdates,
	downloadAllBatchRemovedFields,
	downloadAllRows,
} from "@/utils/DownloadUtils";
import ContributionEmployeeGridMaintenance from "@/components/ContributionEmployeeGridMaintenance.vue";
import { FundFieldMapping } from "@/models/ContributionRow";

@Component({
	components: {
		PaymentDetail,
		BatchSummary,
		BatchUpdates,
		ContributionEmployeeList,
		ContributionEmployeeGridMaintenance,
		Grid,
		SuperStreamResponses,
		Button,
		BatchFieldsRemoved,
	},
})
export default class BatchDetailsTab extends Vue {
	@Prop() batchDetailResponse: BatchDetailResponse | null = null;

	private canViewBatch = false;
	private activeTab = "";

	private updateData: FinalBatchReviewUpdateRecord[] | null = null;
	private removedData: FinalBatchReviewUpdateRecord[] | null = null;
	private fundFieldMappings: FundFieldMapping[] = [];
	private dynamicFieldNames = [];
	private isDownloadingRows = false;

	async beforeMount() {
		if (this.$route.query.t) {
			this.activeTab = this.$route.query.t as string;
		}

		await axios
			.get(canViewBatchURL(Number(this.batchId)))
			.then((response: AxiosResponse<boolean>) => {
				this.canViewBatch = response.data;
			})
			.catch((e) => {
				toastErrorMessage(parseErrorMessage(e));
			});

		await axios
			.get(getAllFundFieldMappingUrl(this.batchId))
			.then((resp) => {
				this.fundFieldMappings = resp.data.fundFieldMappings;
				this.dynamicFieldNames = resp.data.dynamicFieldNames;
			})
			.catch((e) => toastErrorMessage(parseErrorMessage(e)));
		await getBatchUpdates("api/batch/removed", this.batchId).then(
			(data) => (this.removedData = data)
		);
	}

	updateBatchDetails() {
		this.$emit(BatchDetailsPage.UPDATE_BATCH_DETAILS_EVENT); // pass to parent
	}

	get batchId(): number {
		return Number(this.$route.params.id);
	}

	get DBBypassFlag(): boolean {
		return (
			this.batchDetailResponse?.batchSummaryDetail
				?.contributionFileType === "DBBYP"
		);
	}

	get batchDetails() {
		return this.batchDetailResponse?.batchDetail;
	}

	get batchSummaryDetails() {
		return this.batchDetailResponse?.batchSummaryDetail;
	}

	get basicView(): boolean {
		return this.batchDetailResponse?.batchDetail?.basicView === true;
	}

	get showPaymentInsTab(): boolean {
		return (
			this.batchDetailResponse?.batchDetail?.showPaymentInsTab === true
		);
	}

	get showSuperStreamTab(): boolean {
		return (
			this.batchDetailResponse?.batchDetail?.showSuperStreamTab === true
		);
	}

	onBatchUpdateGridReady() {
		if (this.updateData !== null) {
			return;
		}
		getBatchUpdates("api/batch/updates", this.batchId).then(
			(data) => (this.updateData = data)
		);
	}

	private downloadAllRows() {
		this.isDownloadingRows = true;
		downloadAllRows(this.batchId, () => {
			this.isDownloadingRows = false;
		});
	}

	private downloadAllUpdates() {
		downloadAllBatchUpdates(this.batchId);
	}

	private downloadAllRemovedFields() {
		downloadAllBatchRemovedFields(this.batchId);
	}
}
