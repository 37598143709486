import { Module } from "vuex";

import { RootState } from "@/store/root";
import { mutations } from "./registrationOfInterestMutations";
import {
	AuthorizedContactState,
	BusinessDetailsState,
	ContributionSubmissionMethod,
	DefaultFund,
	DateFormat,
	RefundAccountState,
	RegistrationOfInterestState,
	SecretCodeState,
	OtherState,
} from "./registrationOfInterestTypes";

const secretCodeDefaultState: SecretCodeState = {
	secretCode: "",
};
const authorizedContactDefaultState: AuthorizedContactState = {
	authorizedContactFirstName: "",
	authorizedContactLastName: "",
	authorizedContactPosition: "",
	authorizedContactEmail: "",
	authorizedContactMobile: "",
};
const businessDetailsDefaultState: BusinessDetailsState = {
	abn: "",
	registeredName: "",
	tradingName: "",
	addressLine1: "",
	addressLine2: "",
	suburb: "",
	postcode: "",
	state: "",
};
const refundAccountDefaultState: RefundAccountState = {
	refundAccountBsb: "",
	refundAccountNumber: "",
	refundAccountName: "",
};
const otherDefaultState: OtherState = {
	contributionSubmissionMethod: ContributionSubmissionMethod.FILE_UPLOAD,
	dateFormat: DateFormat["YYYY-MM-DD"],
	defaultFund: DefaultFund.GUILD_SUPER,
	defaultFundOther: "",
	numberOfEmployees: "",
};

export const registrationOfInterest: Module<
	RegistrationOfInterestState,
	RootState
> = {
	namespaced: true,
	state: {
		...secretCodeDefaultState,
		...authorizedContactDefaultState,
		...businessDetailsDefaultState,
		...refundAccountDefaultState,
		...otherDefaultState,
	},
	mutations,
};
