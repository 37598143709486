import { PortalUser } from "@/models/PortalUser";

export function isSponsorOrDomainUser(user: PortalUser): boolean {
	return user.sponsorId !== null || user.domainId !== null;
}

export function isSponsorUser(user: PortalUser): boolean {
	return user.sponsorId !== null;
}

export function isDomainUser(user: PortalUser): boolean {
	return user.domainId !== null;
}
