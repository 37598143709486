import ElementSchemaManager from "@/elementschema/ElementSchemaManager";

import TextField from "@/form/TextField.vue";
import NumberField from "@/form/NumberField.vue";
import CurrencyField from "@/form/CurrencyField.vue";
import SelectField from "@/form/SelectField.vue";
import DatepickerField from "@/form/DatepickerField.vue";
import TFNField from "@/form/TFNField.vue";
import FieldGroup from "@/form/FieldGroup.vue";
import TextareaField from "@/form/TextareaField.vue";

/**
 * Register fields that can be rendered using a model data provided by
 * the backend.
 */
ElementSchemaManager.register("TextField", TextField);
ElementSchemaManager.register("NumberField", NumberField);
ElementSchemaManager.register("CurrencyField", CurrencyField);
ElementSchemaManager.register("SelectField", SelectField);
ElementSchemaManager.register("DatepickerField", DatepickerField);
ElementSchemaManager.register("TFNField", TFNField);
ElementSchemaManager.register("TextareaField", TextareaField);
ElementSchemaManager.register("FieldGroup", FieldGroup);
