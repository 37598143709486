


































import Vue from "vue";
import Component from "vue-class-component";
import TextField from "@/form/TextField.vue";
import CheckBox from "@/form/CheckBox.vue";
import DatepickerField from "@/form/DatepickerField.vue";
import { Prop } from "vue-property-decorator";
import CurrencyField from "@/form/CurrencyField.vue";
import { commitToModule } from "@/store/modules/wizardform";
import SelectField from "@/form/SelectField.vue";
import AutoField from "@/form/AutoField.vue";

interface Field {
	name: string;
	type: "DateField" | "TextField" | "CheckboxField" | "CurrencyField";
	value: any;
	label?: string;
	options?: any;
	rules?: string;
}
@Component({
	components: {
		AutoField,
		SelectField,
		CurrencyField,
		DatepickerField,
		CheckBox,
		TextField,
	},
})
export default class WizardFormStep extends Vue {
	@Prop() fields!: any;
	@Prop() stepName!: string;
	@Prop() vuexStore!: string;

	get fieldMap(): Field[] {
		return Object.values(this.fields);
	}

	validate() {
		return (this.$refs.validationObserver as any).validate();
	}
	updateField = (fieldName: string, value: any) => {
		commitToModule(this.$store, this.vuexStore, this.stepName, {
			fieldName,
			value,
		});
		// trigger get fieldMap refresh otherwise select field won't update
		this.$forceUpdate();
	};
}
