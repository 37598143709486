var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Overlay__root"},[_c('div',{class:{
			Overlay__overlay: true,
			Overlay__overlay__dark: _vm.isVariantDark,
			Overlay__overlay__light: !_vm.isVariantDark,
		}}),_c('div',{class:{
			Overlay__content: true,
			Overlay__content__dark: _vm.isVariantDark,
			Overlay__content__light: !_vm.isVariantDark,
		}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'spinner'],"spin":"","size":"3x"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }