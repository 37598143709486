import { Module, Store } from "vuex";
import { RootState } from "@/store/root";
import { getters } from "@/store/modules/wizardform/wizardFormGetters";
import { mutations } from "@/store/modules/wizardform/wizardFromMutations";

export const getDefaultWizardFormState: () => Readonly<any> = () => ({
	steps: {},
});

export const wizardForm: Module<any, RootState> = {
	namespaced: true,
	state: getDefaultWizardFormState(),
	getters,
	mutations,
};

export const registerModule = (store: Store<any>, name: string) => {
	if (!store.hasModule(name)) {
		store.registerModule(name, wizardForm);
	}
};

export const commitToModule = (
	store: Store<any>,
	name: string,
	stepName: string,
	model: any
) => {
	store.commit(`${name}/updateField`, { stepName, ...model });
};
