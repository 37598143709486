









import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class Badge extends Vue {
	@Prop() readonly errors!: number;
	@Prop() readonly warnings!: number;
	@Prop() readonly discardedErrors!: number;
	@Prop() readonly discardedWarnings!: number;

	get discardedErrorsAndWarnings() {
		return this.discardedErrors + this.discardedWarnings;
	}
}
