import { Module } from "vuex";

import { RootState } from "@/store/root";
import { getters } from "./persistentGetters";
import { actions } from "./persistentActions";
import { mutations } from "./persistentMutations";
import { PersistentState } from "./persistentTypes";
import { StatePhase } from "@/store/modules/login/loginTypes";

const stateVersion = "0.1.1";
//This module should have items that we expect to live beyond page refresh
export const getDefaultPersistentState: () => Readonly<PersistentState> =
	() => ({
		version: stateVersion,
		accessToken: "",
		refreshToken: "",
		tokenExpiry: "",
		refreshTokenPhase: StatePhase.DEFAULT,
		refreshingCall: null,
		name: "",
		username: "",
		sponsorPermissions: null,
		parentOrganisationPermissions: null,
		employerPermissions: null,
		reportingCentrePermissions: null,
		domainPermissions: null,
		selectedEntities: [],
		selectedEntitiesInsight: [],
		employerHierarchy: [],
		definedBenefitEntities: [],
		brandId: null,
		iressOperations: false,
		definedBenefitFeaturesEnabled: null,
		checkForNewReleaseForUser: true,
	});

export function getInitialPersistentState(): PersistentState {
	const persistentBlob = window.sessionStorage.getItem("persistent");
	if (typeof persistentBlob !== "string") {
		return getDefaultPersistentState();
	}
	let persistentState: PersistentState;
	try {
		persistentState = JSON.parse(persistentBlob);
	} catch (e) {
		return getDefaultPersistentState();
	}
	if (persistentState.version !== stateVersion) {
		// Throw away persistent state if version has changed.
		// We could extend this in the future to "upgrade" the localStorage
		// data, but hopefully we can just get away with clearing it completely.
		return getDefaultPersistentState();
	}
	return persistentState;
}

export const persistent: Module<PersistentState, RootState> = {
	namespaced: true,
	state: getInitialPersistentState(),
	getters,
	actions,
	mutations,
};
