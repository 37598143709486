import axios from "@/utils/ApiUtils";
import {
	downloadAllContribution,
	downloadAllRemovedFields,
	downloadAllUpdates,
	superStreamContributionResponseDownloadURL,
} from "@/constants/apiconstants";
import { toastErrorMessage, toastInfoMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";

/**
 * Ajax way to download a file. Be aware that it loses the ability to see a download progress.
 * File is saved to disk only until request is finished and all file data are loaded into memory.
 * Do not recommend to use it to download a large file.
 */
export function downloadFile(
	endPointUrl: string,
	fileName: string,
	params?: any,
	onFinishDownload?: () => void
) {
	axios
		.get(endPointUrl, {
			responseType: "blob",
			params,
		})
		.then((response) => {
			const blob = new Blob([response.data]);
			if (window.navigator && window.navigator.msSaveBlob) {
				window.navigator.msSaveBlob(blob, fileName);
			} else {
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", fileName);
				document.body.appendChild(link);
				link.click();
			}
		})
		.catch((error) => {
			if (error.response.status === 406) {
				toastInfoMessage("Download is in progress. Please wait.");
			} else {
				toastErrorMessage(parseErrorMessage(error));
			}
		})
		.finally(onFinishDownload);
}

export function downloadAllRows(
	batchId: number,
	onFinishDownload: () => void
): void {
	return downloadFile(
		downloadAllContribution(batchId),
		"Contribution-" + batchId + ".csv",
		null,
		onFinishDownload
	);
}

export function downloadAllBatchUpdates(batchId: number): void {
	return downloadFile(
		downloadAllUpdates(batchId),
		"Updates-" + batchId + ".csv"
	);
}

export function downloadAllBatchResponses(batchId: number): void {
	return downloadFile(
		superStreamContributionResponseDownloadURL(batchId),
		"SuperStream-" + batchId + ".csv"
	);
}

export function downloadAllBatchRemovedFields(batchId: number): void {
	return downloadFile(
		downloadAllRemovedFields(batchId),
		"Removed-fields-" + batchId + ".csv"
	);
}
