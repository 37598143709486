var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm"}},[_vm._l((_vm.subSections),function(subSection,key){return _c('FieldGroup',{key:key,staticClass:"add-employee-stages",attrs:{"icon":subSection.icon,"label":_vm.capitalise(subSection.label)}},[(subSection.type === 'readonlyFields')?_c('ReadOnlyFields',{attrs:{"data":Object.entries(
					_vm.subSectionData.find(
						function (section) { return section.name === subSection.name; }
					).data[0]
				)}}):_vm._e(),(subSection.type === 'singleRowGrid')?_c('GridSubsection',{attrs:{"column-defs":subSection.columnDefs,"grid-props":subSection.gridProps,"row-data":_vm.subSectionData.find(
					function (section) { return section.name === subSection.name; }
				).data}}):_vm._e(),(subSection.type === 'grid')?_c('GridSubsection',{attrs:{"column-defs":subSection.columnDefs,"grid-props":subSection.gridProps,"row-data":_vm.subSectionData.find(
					function (section) { return section.name === subSection.name; }
				).data}}):_vm._e()],1)}),(_vm.multiTab.canRefresh && _vm.dataUrl && _vm.mode)?_c('Container',{staticClass:"button-add-form"},[_c('LeftRightFooter',{staticClass:"mt-1"},[_c('Button',{on:{"click":_vm.onVarianceRefresh}},[_vm._v(" Refresh ")])],1)],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }