












import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import { Component } from "vue-property-decorator";
import Login from "../components/Login.vue";
import { LoginPhase } from "@/store/modules/login/loginTypes.ts";
import TermsAndConditions from "@/components/TermsAndConditions.vue";

const { mapState } = createNamespacedHelpers("login");

@Component({
	components: { TermsAndConditions, Login },
	computed: { ...mapState(["phase"]) },
})
export default class LoginPage extends Vue {
	private termsAndConditions: string[] = [];

	private phase!: LoginPhase;

	async mounted() {
		const constants = await import(
			"@/themes/" + process.env.VUE_APP_BRAND + "/constants.json"
		);
		this.termsAndConditions = constants.termsAndConditions;
	}
}
