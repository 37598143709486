


















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class SubHeading extends Vue {
	@Prop({ type: String, default: "" }) readonly title!: string;
	@Prop(Array) readonly icon!: string[];
}
