






































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import ButtonBehaviour from "@/form/ButtonBehaviour.vue";
import WarningIcon from "@/components/WarningIcon.vue";
import ErrorIcon from "@/components/ErrorIcon.vue";
import SuccessIcon from "@/components/SuccessIcon.vue";

@Component({
	components: {
		SuccessIcon,
		ErrorIcon,
		WarningIcon,
		ButtonBehaviour,
	},
})
export default class GridActionsRenderer<RowType> extends Vue {
	/**
	 * rowIndex is the index of the record
	 */
	@Prop(Number) rowIndex!: number;

	/**
	 * row is the data of the record
	 */
	@Prop({ type: Object }) row!: RowType;

	/**
	 * Warning indicator - custom fa icon
	 */
	@Prop({ type: String, default: "exclamation-triangle" })
	customWarningIcon!: string;

	@Prop({ type: String, default: "times-octagon" })
	customErrorIcon!: string;

	/**
	 * Warning Indicator
	 */
	@Prop({ default: false }) withWarning!: boolean;

	/**
	 * Error Indicator
	 */
	@Prop({ default: false }) withError!: boolean;

	/**
	 * Info Indicator
	 */
	@Prop({ default: false }) withInfo!: boolean;

	/**
	 * Error (disabled) Indicator
	 */
	@Prop({ default: false }) withRevalidateError!: boolean;

	/**
	 * Warning (disabled) Indicator
	 */
	@Prop({ default: false }) withRevalidateWarning!: boolean;

	@Prop({ type: String, default: "Warning" })
	warningTitle!: string;

	@Prop({ type: String, default: "Error" })
	errorTitle!: string;

	private emitIconClick(event: MouseEvent) {
		event.stopPropagation();
		this.$emit("icon-click", { rowIndex: this.rowIndex, row: this.row });
	}
}
