

























































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import FieldHolder from "@/form/FieldHolder.vue";
import type { CommonField } from "@/form/CommonField";

@Component({
	components: {
		FieldHolder,
	},
})
export default class TFNField extends Vue implements CommonField {
	private static uniqueId = 0;

	/**
	 * id must be globally unique in a HTML document, we guarantee this by making each render
	 * of this component have unique ID number appended to the end to avoid clashes.
	 *
	 * We need ids mostly to connect <label> and <input> elements together for accessibility.
	 */
	readonly id = "TFNField_" + ++TFNField.uniqueId;

	@Prop([String]) readonly name!: string;

	@Prop([String]) readonly label!: string;

	@Prop(String) readonly value!: string;

	@Prop([String, Object]) readonly rules!: string | object;

	@Prop(Boolean) readonly readonly!: boolean;

	/**
	 * Never used. Replaced by inputPlaceholder. Keep here just in case it is set programmatically, i.e in Form.vue.
	 */
	@Prop(String) readonly placeholder!: string;

	/**
	 * Add additional custom error messages to this field.
	 */
	@Prop([Array]) readonly errors!: string[];

	@Prop(Boolean) readonly disabled!: boolean;

	/**
	 * Whether errors are shown externally.
	 */
	@Prop(Boolean) readonly errorsShownExternally!: boolean;

	@Prop(Boolean) readonly taxFileNoSupplied!: boolean;

	@Prop({ type: Boolean, default: true }) readonly taxFileNoValid!: boolean;

	get inputPlaceholder() {
		if (
			this.value === null ||
			this.value === undefined ||
			!this.taxFileNoSupplied
		) {
			// Not supplied is displayed by checkbox, don't need to duplicate in input box
			return "";
		}
		if (this.taxFileNoValid) {
			return "VALID";
		} else {
			return "BAD";
		}
	}

	onInputChange(evt: { target: HTMLInputElement }) {
		this.$emit("input", evt.target.value);
	}

	onSuppliedChange(checked: boolean) {
		if (!checked) {
			this.$emit("input", null);
		}
		this.$emit("supplied-change", checked);
	}
}
