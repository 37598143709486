





























































































import { Component, Prop } from "vue-property-decorator";
import axios from "../utils/ApiUtils";
import { countryListURL } from "@/constants/apiconstants";
import AutoField from "@/form/AutoField.vue";
import Subform from "@/form/Subform.vue";
import { AddressDetails } from "@/models/ContributionRow";
import { SelectOption } from "@/form/FieldOptions";
import Button from "@/form/Button.vue";
import { Logger } from "@/utils/logger";

const NUM_DEFAULT_ADDRESS_LINES = 2;
const NUM_MAX_ADDITIONAL_ADDRESS_LINES = 2;

@Component({
	components: {
		AutoField,
		Button,
	},
})
export default class AddressForm extends Subform {
	@Prop(Object)
	readonly values!: AddressDetails;

	@Prop({ type: Boolean, default: false }) readOnly!: boolean;

	@Prop({ type: Array, default: () => [] })
	readonly countries!: SelectOption[];

	private countryList: SelectOption[] = [];

	private numberOfAdditionalAddressLines = 0;

	private setDefaultCountryCode(): void {
		if (!this.values.countryCode) {
			this.updateAddressDetails({
				...this.values,
				countryCode: "AU",
			});
		}
	}

	get isAustralia() {
		return this.values.countryCode === "AU";
	}

	get canAddAddressLine() {
		return (
			this.numberOfAdditionalAddressLines <
			NUM_MAX_ADDITIONAL_ADDRESS_LINES
		);
	}

	get canRemoveAddressLine() {
		return this.numberOfAdditionalAddressLines > 0;
	}

	private addAddressLine() {
		if (this.canAddAddressLine) {
			this.numberOfAdditionalAddressLines++;
		}
	}

	private removeAddressLine() {
		if (this.canRemoveAddressLine) {
			this.updateAddressDetails({
				...this.values,
				[`addressLine${
					this.numberOfAdditionalAddressLines +
					NUM_DEFAULT_ADDRESS_LINES
				}`]: null,
			});
			this.numberOfAdditionalAddressLines--;
		}
	}

	private onCountryChange(countryCode: string): void {
		if (countryCode !== "AU") {
			this.updateAddressDetails({
				...this.values,
				postcode: null,
				state: null,
				locality: null,
			});
		}
	}

	private updateAddressDetails(data: AddressDetails): void {
		this.$emit("update", data);
	}

	mounted() {
		if (this.countries && this.countries.length > 0) {
			this.countryList = this.countries;
			this.setDefaultCountryCode();
		} else {
			axios
				.get<SelectOption[]>(countryListURL())
				.then((response) => {
					this.countryList = response.data;
					this.setDefaultCountryCode();
				})
				.catch((error) => {
					Logger.error(error);
				});
		}

		for (let i = NUM_MAX_ADDITIONAL_ADDRESS_LINES; i >= 1; i--) {
			if (
				this.values[
					`addressLine${
						i + NUM_DEFAULT_ADDRESS_LINES
					}` as keyof AddressDetails
				]
			) {
				this.numberOfAdditionalAddressLines = i;
				break;
			}
		}
	}
}
