var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"rootEl",staticClass:"DropdownMenu__root"},[_c('Button',{ref:"buttonEl",attrs:{"variant":"primary","disabled":_vm.isDisabled},on:{"click":_vm.onClickButton}},[_vm._t("button-content",function(){return [_c('span',[_vm._v(_vm._s(_vm.label))])]}),_c('span',{staticClass:"DropdownMenu__arrow",attrs:{"aria-hidden":"true"}})],2),_c('nav',{class:{
			DropdownMenu__nav: true,
			'DropdownMenu__nav--is-open': _vm.isOpen,
			'DropdownMenu__nav--right': _vm.right,
		}},[_c('ul',{ref:"listEl",class:{
				DropdownMenu__list: true,
				'DropdownMenu__list--is-open': _vm.isOpen,
			}},[_vm._t("default")],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }