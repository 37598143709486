




import { AgChartOptions, AgPolarChartOptions } from "ag-charts-community";
import Vue from "vue";
import Component from "vue-class-component";
import { AgChartsVue } from "ag-charts-vue";
import { chartTheme } from "@/components/charts/chartTheme";
import { Prop } from "vue-property-decorator";
import { formatCurrencyValue } from "@/components/charts/ChartUtils";

export interface SmsfVsApraType {
	value: number;
	label: string;
	percentage?: number;
}

const smsfVsApraChartOptions: AgPolarChartOptions = {
	type: "pie",
	title: {
		text: `Where do our contributions go?`,
	},
	// Padding required to not be overlapped by carousel
	padding: {
		bottom: 25,
	},
	series: [
		{
			angleKey: "value",
			calloutLabelKey: "label",
			highlightStyle: {
				item: {
					fillOpacity: 0,
				},
			},
			tooltip: {
				renderer: ({ datum, angleValue }) => {
					return {
						title: `${datum.label}`,
						content: `${formatCurrencyValue(angleValue)} ${
							datum.percentage
								? ` (${datum.percentage.toFixed(2)}%)`
								: ""
						}`,
					};
				},
			},
		},
	],
};

@Component({
	components: {
		agCharts: AgChartsVue,
	},
})
export default class PieChart extends Vue {
	@Prop(Array)
	chartData!: SmsfVsApraType[];

	chartOptions: AgChartOptions = {
		data: this.chartData,
		...smsfVsApraChartOptions,
	};

	async mounted() {
		const theme = await chartTheme();
		this.chartOptions = {
			theme,
			data: this.chartData,
			...smsfVsApraChartOptions,
		};
	}
}
