











































































































































































































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { CellClickedEvent, ColDef, ColGroupDef } from "ag-grid-community";
import { BTab } from "bootstrap-vue";
import { debounce, difference } from "lodash-es";

import axios from "@/utils/ApiUtils";
import { AppRoute, AppRouteNextFunction } from "@/router";
import { RoutePath } from "@/router/routePath";
import Button from "@/form/Button.vue";
import Container from "@/components/Container.vue";
import Grid from "@/grid/Grid.vue";
import Layout from "@/components/Layout.vue";
import ErrorList from "@/components/ErrorList.vue";
import LeftRightFooter from "@/components/LeftRightFooter.vue";
import ModalWithSaveAndCancel from "@/components/ModalWithSaveAndCancel.vue";
import BatchUpdates from "@/components/BatchUpdates.vue";
import {
	Batch,
	FinalBatchReviewRecord,
	FinalBatchReviewUpdateRecord,
	PaymentInformation,
	FinalBatchReviewWithColumnOverrides,
	ProjectedPaymentInformation,
	UpdateType,
} from "@/models/FinalBatchReviewRecord";
import ContributionResource, {
	ContributionSummary,
	fetchBatchDataByFund,
	fetchReviewAuthorisedUnfundedBatches,
} from "@/rest/ContributionResource";
import { getBatchUpdates } from "@/rest/BatchResource";
import ContributionSummaryComponent from "@/pages/contribution/ContributionSummaryComponent.vue";
import { parseErrorMessage, parseErrorMessageList } from "@/utils/ErrorUtils";
import {
	toastErrorMessage,
	toastInfoMessage,
	toastSuccessMessage,
} from "@/plugins/toasts";
import ProgressArrow from "@/components/ProgressArrow.vue";
import {
	authoriseBatchURL,
	authoriserCheckURL,
	batchCancelURL,
	batchReadyForDeclineURL,
	dbPaymentInfoUrl,
	declineBatchURL,
	paymentInformationCheckURL,
	projectedPaymentInfoUrl,
	submitForApprovalURL,
	userHasAuthoriseBatchPermissionURL,
} from "@/constants/apiconstants";
import { columnCurrencyFormatter } from "@/utils/CommonUtils";
import CheckBox from "@/form/CheckBox.vue";
import PageHeader from "@/components/PageHeader.vue";
import {
	enableFileAndWarningsStep,
	getNextStep,
	getPreviousStep,
	getSteps,
} from "@/constants/pageConstants";
import { hasPermission } from "@/utils/PermissionUtils";
import {
	downloadAllBatchRemovedFields,
	downloadAllBatchUpdates,
} from "@/utils/DownloadUtils";
import PaymentDetail from "@/components/PaymentDetail.vue";
import ReviewAuthorisedUnfundedBatches from "@/components/ReviewAuthorisedUnfundedBatches.vue";
import BatchFieldsRemoved from "@/components/BatchFieldsRemoved.vue";
import { ContributionFileType } from "@/pages/fundTypes";

interface FinalBatchReviewPageResponsesBundle {
	summary: ContributionSummary | undefined;
	batchData: FinalBatchReviewRecord[] | undefined;
	columnDefs: (ColGroupDef | ColDef)[] | undefined;
	updateData: FinalBatchReviewUpdateRecord[];
	removeData: FinalBatchReviewUpdateRecord[];
	projectedPaymentData: ProjectedPaymentInformation[];
	canDecline: boolean;
	userCanAuthorise: boolean;
	errors: string[];
	authoriseError: string[];
	userCanSubmit: boolean;
	authorisedUnfundedBatches: Batch[];
	allowCancelAuthorisedUnfundedBatches: boolean;
	dbPaymentInfo: PaymentInformation[];
}

interface FundBatchReviewRowData {
	employee: string;
	payrollNo: string;
	employerContributionsSuperannuationGuarantee: number;
	personalContributions: number;
	employerContributionsSalarySacrificed: number;
	spouseContributions: number;
	employerContributionsVoluntary: number;
	employerContributionsAwardOrProductivity: number;
	childContributions: number;
	otherThirdPartyContributions: number;
	totalAmount: number;
}

// data and total are optional so that it can be undefined and grid will consider it as data is loading.
interface FundBatchReviewTableData {
	data?: FundBatchReviewRowData[];
	total?: FundBatchReviewRowData;
}

interface Fund {
	id: string; // abn or usi which uniquely identifies a fund
	name: string;
}

interface FundData {
	fund: Fund;
	tableData: FundBatchReviewTableData;
}

const fundColumnName = "fund";

const defaultContributionColumnDefs: ColDef[] = [
	{
		headerName: "Super Guarantee",
		field: "employerContributionsSuperannuationGuarantee",
		valueFormatter: columnCurrencyFormatter,
	},
	{
		headerName: "Salary Sacrifice",
		field: "employerContributionsSalarySacrificed",
		valueFormatter: columnCurrencyFormatter,
	},
	{
		headerName: "Award",
		field: "employerContributionsAwardOrProductivity",
		valueFormatter: columnCurrencyFormatter,
	},
	{
		headerName: "Personal",
		field: "personalContributions",
		valueFormatter: columnCurrencyFormatter,
	},
	{
		headerName: "Employer Voluntary",
		field: "employerContributionsVoluntary",
		valueFormatter: columnCurrencyFormatter,
	},
	{
		headerName: "Spouse",
		field: "spouseContributions",
		valueFormatter: columnCurrencyFormatter,
	},
	{
		headerName: "Child",
		field: "childContributions",
		valueFormatter: columnCurrencyFormatter,
	},
	{
		headerName: "Other 3rd Party",
		field: "otherThirdPartyContributions",
		valueFormatter: columnCurrencyFormatter,
	},
];

const projectedPaymentColumnDefs: (ColGroupDef | ColDef)[] = [
	{ headerName: "Reporting centre", field: "reportingCentreName", flex: 2 },
	{ headerName: "Payment method", field: "paymentMethod", flex: 1 },
	{
		headerName: "Payment amount",
		field: "totalAmount",
		valueFormatter: columnCurrencyFormatter,
	},
];

export const dbPaymentColumnDefs: (ColGroupDef | ColDef)[] = [
	{ headerName: "DB fund", field: "fundUsi", flex: 1 },
	{ headerName: "BSB", field: "bsb", flex: 1 },
	{ headerName: "Account number", field: "accountNo", flex: 1 },
	{ headerName: "Account name", field: "accountName", flex: 1 },
	{ headerName: "PRN", field: "prn", flex: 1 },
	{
		headerName: "Payment amount",
		field: "amount",
		valueFormatter: columnCurrencyFormatter,
	},
];

const employeesOfFundColumnDefs: (ColGroupDef | ColDef)[] = [
	{
		headerName: "Employee",
		children: [
			{ headerName: "Employee", field: "employee" },
			{
				headerName: "Payroll ID",
				field: "payrollNo",
				columnGroupShow: "open",
				width: 80,
			},
		],
	},
	{
		headerName: "Contributions",
		children: [...defaultContributionColumnDefs],
	},
];

@Component({
	components: {
		CheckBox,
		BatchUpdates,
		Button,
		Container,
		ContributionSummaryComponent,
		Grid,
		Layout,
		LeftRightFooter,
		ModalWithSaveAndCancel,
		PaymentDetail,
		ProgressArrow,
		ErrorList,
		PageHeader,
		ReviewAuthorisedUnfundedBatches,
		BatchFieldsRemoved,
	},
})
export default class FinalBatchReviewPage extends Vue {
	readonly updatesToPortalTabTitle = "Updates";

	readonly authStatuses = [
		"Authorised",
		"Awaiting Second Authorisation",
		"Awaiting Third Authorisation",
		"Awaiting Fourth Authorisation",
	];

	hasPermission(permission: string) {
		return hasPermission(permission);
	}

	get fundOverviewColumnDefs() {
		return this.fundOverviewColumnDefsWithOverrides();
	}

	get projectedPaymentColumnDefs() {
		return projectedPaymentColumnDefs;
	}

	get employeesOfFundColumnDefs() {
		return employeesOfFundColumnDefs;
	}

	get dbPaymentColumnDefs() {
		return dbPaymentColumnDefs;
	}

	private readonly title = "Final review";

	private steps = getSteps(this.title);

	// seconds before refresh
	private static readonly DATA_REFRESH_INTERVAL: number = 15;

	/**
	 * errors is a collection of error messages that hides the entire screen if not empty.
	 * Used for responses.
	 */
	errors: string[] = [];

	/**
	 * authoriseError - displayed near Authorise / submit button
	 */
	private authoriseError: string[] = [];

	private contributionSummary: ContributionSummary | null = null;

	private batchId: number | null = null; // null for reactive purpose

	private gridReady = false;

	private rowData: FinalBatchReviewRecord[] = [];

	private tabIndex = 0;

	readonly noSelectedFund: FundData = {
		fund: { id: "", name: "" },
		tableData: {},
	};
	/**
	 * Selected fund from By Fund tab and its table data displayed in separate fund tab
	 */
	private selectedFund: FundData = this.noSelectedFund;

	private isConfirmModalShown = false;
	private changesConfirmed = false;
	private changesAcknowledged = false;
	private showCancelBatchModal = false;
	private showDeclineBatchModal = false;
	private showReviewBatches = false;

	/**
	 * Batch status
	 */
	private canCancel = true;
	private canDecline = false;
	/**
	 * User is allowed to Authorise batch (includes permission check, batch has no errors and is not yet approved)
	 */
	private userCanAuthorise = false;
	/**
	 * User is allowed to submit a batch for approval (includes permission check, batch has no errors and is not yet approved or submitted)
	 */
	private userCanSubmit = false;
	private batchData: FinalBatchReviewRecord[] = [];
	private updateData: FinalBatchReviewUpdateRecord[] | null = null;
	private removeData: FinalBatchReviewUpdateRecord[] | null = null;
	private projectedPaymentData: ProjectedPaymentInformation[] | null = null;
	private dbPaymentInfo: PaymentInformation[] | null = null;
	private authorisedUnfundedBatches: Batch[] = [];
	private allowCancelAuthorisedUnfundedBatches = false;

	private userShownFundValidationAlert = false;
	private columDefs: ColDef[] = [];

	async beforeRouteEnter(
		to: AppRoute,
		from: AppRoute,
		next: AppRouteNextFunction<FinalBatchReviewPage>
	) {
		const r = await FinalBatchReviewPage.beforeRouteEnterOrUpdate(
			to,
			from,
			next
		);
		if (r === undefined) {
			return;
		}
		next((vm) => {
			vm.setResponse(r);
		});
	}

	private fundOverviewColumnDefsWithOverrides(): (ColGroupDef | ColDef)[] {
		return [
			{ headerName: "Fund code", field: "fundCode", hide: true },
			{ headerName: "Fund name", field: fundColumnName, minWidth: 120 },
			{
				headerName: "Employee count",
				field: "numOfEmployee",
				minWidth: 125,
			},
			...this.applyColumnDefOverrides(),
			{
				headerName: "Total",
				field: "totalAmount",
				valueFormatter: columnCurrencyFormatter,
				pinned: "right",
				minWidth: 125,
			},
		];
	}
	private applyColumnDefOverrides(): ColDef[] {
		if (this.columDefs.length > 0) {
			this.columDefs.forEach((amountCol) => {
				amountCol.valueFormatter = columnCurrencyFormatter;
				amountCol.minWidth = 125;
			});
			return this.columDefs;
		}
		return defaultContributionColumnDefs;
	}

	async updateContributionSummary(batchId: number) {
		const errors: string[] = [];
		let summaryData: ContributionSummary | undefined;
		await ContributionResource.getContributionSummary(batchId)
			.then((resp) => {
				summaryData = resp.data;
			})
			.catch((e: any) => {
				const respErrors = parseErrorMessageList(e);
				errors.push(...respErrors);
			});
		return {
			summary: summaryData,
			errors: errors,
		};
	}

	private intervalData: {
		id?: string;
		remaining?: number;
		intervalRef?: number;
		isRefreshing: boolean;
	} = {
		id: undefined,
		remaining: undefined,
		intervalRef: undefined,
		isRefreshing: false,
	};

	/**
	 * Summary data is refreshed periodically while the page is open
	 */
	private setIntervalForNewId(id: string) {
		if (this.intervalData.intervalRef !== undefined) {
			this.intervalData.isRefreshing = false;
			clearInterval(this.intervalData.intervalRef);
			this.intervalData.intervalRef = undefined;
		}

		this.intervalData.id = id;
		this.intervalData.remaining =
			FinalBatchReviewPage.DATA_REFRESH_INTERVAL;
		// Runs every second and triggers refresh every DATA_REFRESH_INTERVAL
		this.intervalData.intervalRef = window.setInterval(() => {
			if (this.intervalData.isRefreshing) {
				return;
			}
			if (
				this.intervalData.remaining === undefined ||
				this.intervalData.remaining < 0
			) {
				throw new Error("Invalid state for refresh interval");
			}

			if (this.intervalData.remaining === 0 && this.canRefresh) {
				this.intervalData.remaining =
					FinalBatchReviewPage.DATA_REFRESH_INTERVAL;
				this.triggerRefresh();
			}
			this.intervalData.remaining = Math.abs(
				this.intervalData.remaining - 1
			);
		}, 1000);
	}

	stopRefreshTimer(): void {
		if (this.intervalData.intervalRef !== undefined) {
			clearInterval(this.intervalData.intervalRef);
			this.intervalData.intervalRef = undefined;
		}
		this.intervalData.remaining = undefined;
	}

	get refreshCount(): string {
		if (this.intervalData.remaining === undefined) {
			return "";
		}
		return this.intervalData.remaining === 0
			? ""
			: "" + this.intervalData.remaining;
	}

	private onClickRefresh = debounce(
		() => {
			if (this.intervalData.remaining !== undefined) {
				this.intervalData.remaining = 0;
			} else {
				this.triggerRefresh();
			}
		},
		500,
		{ leading: true, trailing: false }
	);

	get canRefresh(): boolean {
		return (
			this.contributionSummary?.batchStatus === "Authorising Funds" ||
			this.contributionSummary?.batchStatus ===
				"Authorising All Funds Valid" ||
			this.contributionSummary?.batchStatus === "Authorisation Received"
		);
	}

	get errorsRemaining(): boolean {
		if (this.contributionSummary?.errors) {
			return this.contributionSummary.errors > 0;
		}
		return false;
	}

	get backButtonVariant(): string {
		if (this.errorsRemaining) return "primary";
		return "";
	}

	static async beforeRouteEnterOrUpdate(
		to: AppRoute,
		from: AppRoute,
		next: AppRouteNextFunction<FinalBatchReviewPage>
	): Promise<FinalBatchReviewPageResponsesBundle | undefined> {
		const id: string = to.params.id;
		if (!id || id === "0") {
			// Redirect to 404 if ID is blank or 0.
			next({
				name: "Not Found",
			});
			return;
		}
		const batchId = Number(id);
		const errors: string[] = [];
		const authoriseError: string[] = [];

		// Fire requests simultaneously
		let summaryData: ContributionSummary | undefined;
		let finalBatchReviewData: FinalBatchReviewRecord[] | undefined;
		let columDefOverrides: (ColGroupDef | ColDef)[] = [];
		let finalBatchReviewUpdateData: FinalBatchReviewUpdateRecord[] = [];
		let removeData: FinalBatchReviewUpdateRecord[] = [];
		let projectedPaymentData: ProjectedPaymentInformation[] = [];
		let canDecline = false;
		let userCanAuthorise = false;
		let userCanSubmit = false;
		let authorisedUnfundedBatches: Batch[] = [];
		let allowCancelAuthorisedUnfundedBatches = false;
		let dbPaymentInfo: PaymentInformation[] = [];
		await Promise.all([
			ContributionResource.getContributionSummary(batchId)
				.then((resp) => {
					summaryData = resp.data;
				})
				.catch((e: any) => {
					const respErrors = parseErrorMessageList(e);
					errors.push(...respErrors);
				}),
			axios
				.get<FinalBatchReviewWithColumnOverrides>(
					`api/contribution/final-batch-review/${batchId}`
				)
				.then(async (resp) => {
					finalBatchReviewData = resp.data.batchReviewLines;
					columDefOverrides = resp.data.columnOverrides;
				})
				.catch((e) => {
					const respErrors = parseErrorMessageList(e);
					errors.push(...respErrors);
				}),
			axios
				.head(userHasAuthoriseBatchPermissionURL(batchId))
				.then(() => (userCanAuthorise = true))
				.catch((e: any) => {
					if (e.response.status !== 403) {
						const respErrors = parseErrorMessageList(e);
						errors.push(...respErrors);
					}
				}),
			axios
				.get(batchReadyForDeclineURL(batchId))
				.then((resp) => (canDecline = resp.data))
				.catch((e: any) => {
					if (e.response.status !== 403) {
						const respErrors = parseErrorMessageList(e);
						errors.push(...respErrors);
					}
				}),
			axios.get(paymentInformationCheckURL(batchId)).catch((e: any) => {
				if (e.response.status !== 403) {
					const respErrors = parseErrorMessageList(e);
					authoriseError.push(...respErrors);
				}
			}),
			axios.get(authoriserCheckURL(batchId)).catch((e: any) => {
				if (e.response.status !== 403) {
					const respErrors = parseErrorMessageList(e);
					authoriseError.push(...respErrors);
				}
			}),
			axios
				.head(submitForApprovalURL(batchId))
				.then(() => (userCanSubmit = true))
				.catch((e: any) => {
					if (e.response.status !== 403) {
						const respErrors = parseErrorMessageList(e);
						errors.push(...respErrors);
					}
				}),
			getBatchUpdates(
				"api/contribution/final-batch-review/updates",
				batchId
			).then((data) => (finalBatchReviewUpdateData = data)),
			getBatchUpdates(
				"api/contribution/final-batch-review/remove",
				batchId
			).then((data) => (removeData = data)),
			axios
				.get(projectedPaymentInfoUrl(batchId))
				.then((resp) => (projectedPaymentData = resp.data)),
			axios
				.get(dbPaymentInfoUrl(batchId))
				.then((resp) => (dbPaymentInfo = resp.data)),
			fetchReviewAuthorisedUnfundedBatches(batchId)
				.then((resp) => {
					authorisedUnfundedBatches = resp.batches;
					allowCancelAuthorisedUnfundedBatches = resp.allowCancel;
				})
				.catch((e) => toastErrorMessage(parseErrorMessage(e))),
		]);

		return {
			summary: summaryData,
			batchData: finalBatchReviewData,
			columnDefs: columDefOverrides,
			updateData: finalBatchReviewUpdateData,
			projectedPaymentData: projectedPaymentData,
			canDecline,
			userCanAuthorise:
				userCanAuthorise &&
				!summaryData?.batchAuthorised &&
				authoriseError.length === 0,
			errors: errors,
			authoriseError: authoriseError,
			userCanSubmit:
				userCanSubmit &&
				!summaryData?.batchSubmitted &&
				authoriseError.length === 0,
			authorisedUnfundedBatches: authorisedUnfundedBatches,
			allowCancelAuthorisedUnfundedBatches:
				allowCancelAuthorisedUnfundedBatches,
			removeData: removeData,
			dbPaymentInfo: dbPaymentInfo,
		};
	}

	setResponse(resp: FinalBatchReviewPageResponsesBundle) {
		const {
			summary,
			batchData,
			columnDefs,
			updateData,
			projectedPaymentData,
			canDecline,
			userCanAuthorise,
			errors,
			authoriseError,
			userCanSubmit,
			authorisedUnfundedBatches,
			allowCancelAuthorisedUnfundedBatches,
			removeData,
			dbPaymentInfo,
		} = resp;
		if (summary !== undefined) {
			this.contributionSummary = summary;
			enableFileAndWarningsStep(
				this.steps,
				summary.withFileLevelErrorWarning
			);
			this.canCancel = summary.canCancelBatch;
			this.batchId = summary.parentBatchId;
		}
		if (batchData !== undefined) {
			this.batchData = batchData;
		}
		if (columnDefs !== undefined) {
			this.columDefs = columnDefs;
		}
		this.updateData = updateData;
		this.removeData = removeData;
		this.projectedPaymentData = projectedPaymentData;
		if (errors && errors.length > 0) {
			this.errors = errors;
		}
		this.canDecline = canDecline;
		this.userCanAuthorise = userCanAuthorise;
		this.userCanSubmit = userCanSubmit;
		this.authoriseError = authoriseError;
		this.authorisedUnfundedBatches = authorisedUnfundedBatches;
		this.allowCancelAuthorisedUnfundedBatches =
			allowCancelAuthorisedUnfundedBatches;
		this.dbPaymentInfo = dbPaymentInfo;
	}

	private onGridReady() {
		this.gridReady = true;
		this.rowData = this.batchData;
	}

	private get canClickBack(): boolean {
		const parentBatchId = this.getBatchId();
		if (
			parentBatchId === 0 ||
			parentBatchId === null ||
			parentBatchId === undefined
		) {
			return false;
		}
		return true;
	}

	private getBatchId(): number {
		if (typeof this.batchId === "number") {
			return this.batchId;
		}
		return 0;
	}

	private get canClickNext(): boolean {
		if (
			!(this.userCanAuthorise || this.userCanSubmit) ||
			this.errorsRemaining
		) {
			return false;
		}
		// Delay confirmation on a modal if it is not on updates tab
		if (!this.isUpdatesToPortalTabActivated && !this.isConfirmModalShown) {
			return true;
		}

		const changesConfirmed = this.hasWarningsUpdates
			? this.changesConfirmed
			: true;
		const changesAcknowledged = this.hasNonEmployerUpdates
			? this.changesAcknowledged
			: true;
		return changesConfirmed && changesAcknowledged;
	}

	private get isBatchAuthorisedBeforeSubmitting(): boolean {
		return (
			this.errors.includes("Contribution is already authorised.") ||
			this.errors.includes(
				"Contribution is already submitted for authorisation."
			)
		);
	}

	private get isBatchAlreadyAuthorised(): boolean {
		if (!this.contributionSummary) {
			return false;
		}
		if (this.isBatchAuthorisedBeforeSubmitting) {
			return true;
		}
		return this.contributionSummary.batchAuthorised;
	}

	private onClickBack(): void {
		if (!this.canClickBack) {
			return;
		}
		const routePath = getPreviousStep(this.steps, this.title).routePath;
		this.$router.push(routePath.replace("/:id", "/" + this.getBatchId()));
	}

	private onClickViewPayment() {
		this.next();
	}

	private onClickNext(): void {
		if (
			(this.isUpdatesToPortalTabActivated ||
				this.isBatchAlreadyAuthorised) &&
			this.canClickNext
		) {
			return this.approveOrSubmit();
		}
		if (!this.isBatchAlreadyAuthorised && this.hasUpdatesToPortalTab) {
			// not on updates tab, show a modal
			this.isConfirmModalShown = true;
		}
		//This is when there are no updates to portal
		if (
			!this.hasUpdatesToPortalTab &&
			!this.isBatchAlreadyAuthorised &&
			this.canClickNext
		) {
			return this.approveOrSubmit();
		}
	}

	private onContinueReview(): void {
		this.isConfirmModalShown = false;
		this.changesAcknowledged = false;
		this.changesConfirmed = false;
	}

	private onCloseConfirmModalAndApprove() {
		this.isConfirmModalShown = false;
		this.approveOrSubmit();
	}

	private approveOrSubmit() {
		if (this.userCanAuthorise) {
			if (
				!this.showReviewBatches &&
				this.authorisedUnfundedBatches.length > 0
			) {
				this.showReviewBatches = true;
			} else {
				this.approve(false);
			}
		} else {
			this.submitForApproval();
		}
	}

	/**
	 * Batch is already authorised
	 */
	private next(): void {
		const routePath = getNextStep(this.steps, this.title).routePath;
		this.$router.push(routePath.replace("/:id", "/" + this.batchId));
	}
	private triggerRefresh() {
		this.intervalData.isRefreshing = true;
		if (this.batchId !== null) {
			this.updateContributionSummary(this.batchId)
				.then((details) => {
					if (details.summary) {
						this.contributionSummary = details.summary;
						// Redirect to Payment details page
						if (
							this.authStatuses.includes(
								this.contributionSummary.batchStatus
							)
						) {
							const routePath = getNextStep(
								this.steps,
								this.title
							).routePath;
							this.$router.push(
								routePath.replace("/:id", "/" + this.batchId)
							);
						} else if (
							this.contributionSummary.batchStatus ===
							"In Progress"
						) {
							this.stopRefreshTimer();
							toastErrorMessage(
								"New validation error(s) were found.  Please return to the Validate contents screen to correct any new errors."
							);
						} else if (!this.userShownFundValidationAlert) {
							toastInfoMessage(
								"Please wait while we perform final validation checks on your data."
							);
							this.userShownFundValidationAlert = true;
						}
					}
				})
				.finally(() => (this.intervalData.isRefreshing = false));
		}
	}

	private approve(cancelBatches: boolean): void {
		const batchId = this.getBatchId();
		axios
			.put(authoriseBatchURL(batchId), cancelBatches, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(() => {
				if (
					this.contributionSummary?.contributionFileType ===
					ContributionFileType.DB_BYPASS
				) {
					this.next();
					return;
				}
				this.showReviewBatches = false;
				this.setIntervalForNewId(batchId.toString());
				this.triggerRefresh();
				this.userCanAuthorise = false;
				this.userCanSubmit = false;
			})
			.catch((e) => {
				if (e.response.status === 406) {
					toastErrorMessage(
						parseErrorMessage(e) +
							" Please review the list of authorised batches again."
					);
					this.showReviewBatches = false;
					fetchReviewAuthorisedUnfundedBatches(batchId).then(
						(resp) => {
							this.authorisedUnfundedBatches = resp.batches;
							this.allowCancelAuthorisedUnfundedBatches =
								resp.allowCancel;
						}
					);
				} else if (e.response.status === 400) {
					toastErrorMessage(parseErrorMessage(e));
					this.showReviewBatches = false;
				} else {
					const respErrors = parseErrorMessageList(e);
					this.errors.push(...respErrors);
				}
			});
	}

	private submitForApproval(): void {
		const batchId = this.getBatchId();
		this.userCanSubmit = false;
		this.showReviewBatches = false;
		axios
			.put(submitForApprovalURL(batchId))
			.then(() =>
				toastSuccessMessage(
					"Contribution batch is submitted for approval."
				)
			)
			.catch((e) => {
				const respErrors = parseErrorMessageList(e);
				this.errors.push(...respErrors);
			});
	}

	// Cancel batch

	private toggleCancelBatchModal(show: boolean): void {
		this.showCancelBatchModal = show;
	}

	private onCancelBatch(): void {
		const batchId = this.getBatchId();
		axios
			.delete(batchCancelURL(batchId))
			.then((response) => {
				toastSuccessMessage("Contribution batch marked for deletion");
				// Batch no longer exists, so return to the batch list
				this.$router.push(RoutePath.BatchList);
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
		this.showCancelBatchModal = false;
	}

	// Decline batch

	private toggleDeclineBatchModal(show: boolean): void {
		this.showDeclineBatchModal = show;
	}

	private onDeclineBatch(): void {
		const batchId = this.getBatchId();
		axios
			.put(declineBatchURL(batchId))
			.then((response) => {
				toastSuccessMessage("Contribution batch declined");
				const routePath = getPreviousStep(
					this.steps,
					this.title
				).routePath;
				this.$router.push(
					routePath.replace("/:id", "/" + this.batchId)
				);
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
		this.showCancelBatchModal = false;
	}

	// Fund tab methods begin

	private onCellClicked(e: CellClickedEvent): void {
		if (e.colDef.field !== fundColumnName) {
			return;
		}

		const rowData: FinalBatchReviewRecord = e.data;
		const fundId = rowData.fundId;
		if (!rowData || !fundId) {
			throw new Error(
				"Missing row data and fund usi/fund abn for clicked fund"
			);
		}

		if (fundId === this.selectedFund.fund.id) {
			return;
		}

		this.initSelectedFund(fundId, rowData.fund);
		this.fetchFundTableData(fundId);
	}

	private initSelectedFund(id: string, name: string) {
		this.selectedFund = {
			fund: { id, name },
			tableData: {},
		};
	}

	private clearSelectedFund() {
		this.selectedFund = this.noSelectedFund;
	}

	private onTabsChanged(currentTabs: BTab[], previousTabs: BTab[]) {
		// navigate to ByFund tab if fund tab is closed
		if (difference(previousTabs, currentTabs).length > 0) {
			this.tabIndex = 0;
		}
	}

	private onFundGridReady(gridKey: string) {
		// navigate to selected fund
		this.tabIndex = this.pinnedTabsNumber;
	}

	private async fetchFundTableData(fundId: string): Promise<void> {
		if (!this.batchId) {
			throw new Error("Missing contribution batch id");
		}
		if (this.selectedFund === this.noSelectedFund) {
			throw new Error("Should select a fund first");
		}

		return fetchBatchDataByFund(this.batchId, fundId)
			.then((rowData) => {
				const data: FundBatchReviewRowData[] = rowData.map(
					({
						givenName,
						surname,
						payrollNo,
						employerContributionsSuperannuationGuarantee,
						personalContributions,
						employerContributionsSalarySacrificed,
						spouseContributions,
						employerContributionsVoluntary,
						employerContributionsAwardOrProductivity,
						childContributions,
						otherThirdPartyContributions,
						totalAmount,
					}) => ({
						employee: `${givenName} ${surname}`,
						payrollNo,
						employerContributionsSuperannuationGuarantee,
						personalContributions,
						employerContributionsSalarySacrificed,
						spouseContributions,
						employerContributionsVoluntary,
						employerContributionsAwardOrProductivity,
						childContributions,
						otherThirdPartyContributions,
						totalAmount,
					})
				);
				// last element is total line.
				const total = data.splice(-1, 1)[0];
				if (total) {
					total.employee = "Total";
				}
				this.selectedFund.tableData = { data, total };
			})
			.catch((e) => {
				const respErrors = parseErrorMessageList(e);
				this.errors.push(...respErrors);
			});
	}

	// Fund tab methods end

	private navigateToBatchList() {
		this.$router.push(RoutePath.BatchList);
	}

	get pinnedTabsNumber(): number {
		return this.hasUpdatesToPortalTab ? 2 : 1;
	}

	get hasUpdatesToPortalTab(): boolean {
		return this.updateData?.length !== 0;
	}

	get hasPaymentsTab(): boolean {
		return this.projectedPaymentData?.length !== 0;
	}

	get hasRemovedFields(): boolean {
		return this.removeData?.length !== 0;
	}

	get isUpdatesToPortalTabActivated(): boolean {
		// Updates To Portal tab is always the second tab if exists
		return this.hasUpdatesToPortalTab && this.tabIndex === 1;
	}

	get hasWarningsUpdates(): boolean {
		return (
			this.updateData !== null &&
			this.updateData.some((data) => data.updateType === UpdateType.W)
		);
	}

	get hasNonEmployerUpdates(): boolean {
		return (
			this.updateData !== null &&
			this.updateData.some((data) => !data.updatedByEmployer) &&
			!this.isBatchAlreadyAuthorised
		);
	}
	private downloadAllUpdates() {
		if (this.batchId) {
			downloadAllBatchUpdates(this.batchId);
		}
	}

	private downloadAllRemovedFields() {
		if (this.batchId) {
			downloadAllBatchRemovedFields(this.batchId);
		}
	}

	async beforeRouteLeave(
		to: AppRoute,
		from: AppRoute,
		next: AppRouteNextFunction<FinalBatchReviewPage>
	) {
		if (this.intervalData.intervalRef !== undefined) {
			clearInterval(this.intervalData.intervalRef);
			this.intervalData.intervalRef = undefined;
		}
		next();
	}

	@Watch("isBatchAuthorisedBeforeSubmitting")
	updateCanCancel(value: boolean) {
		if (value) {
			this.canCancel = false;
		}
	}
}
