import { MutationTree } from "vuex";

import {
	PartialRegistrationOfInterestState,
	RegistrationOfInterestState,
} from "./registrationOfInterestTypes";

type RegistrationOfInterestStateKeys = keyof RegistrationOfInterestState;

export const mutations: MutationTree<RegistrationOfInterestState> = {
	updateField(state, payload: PartialRegistrationOfInterestState) {
		const keys = Object.keys(payload) as RegistrationOfInterestStateKeys[];

		keys.forEach((key) => {
			(state as any)[key] = payload[key];
		});
	},
};
