













































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import {
	isEmpty,
	isEqual,
	keys,
	mapValues,
	mergeWith,
	pick,
	pickBy,
} from "lodash-es";
import { Component, Prop, Watch } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import { CellClickedEvent } from "ag-grid-community";
import { VForm } from "@/@typings/type-vee-validate";

import Grid from "@/grid/Grid.vue";

import Container from "@/components/Container.vue";
import Layout from "@/components/Layout.vue";
import Modal from "@/components/Modal.vue";
import ModalOrPopup from "@/components/ModalOrPopup.vue";
import AutoField from "@/form/AutoField.vue";
import TextField from "@/form/TextField.vue";
import TFNField from "@/form/TFNField.vue";
import SelectField from "@/form/SelectField.vue";
import { SelectOption } from "@/form/FieldOptions";
import Overlay from "@/components/Overlay.vue";
import ErrorList from "@/components/ErrorList.vue";
import Form from "@/form/Form.vue";
import DatepickerField from "@/form/DatepickerField.vue";
import FieldGroup from "@/form/FieldGroup.vue";
import Accordion from "@/components/Accordion.vue";
import LeftRightFooter from "@/components/LeftRightFooter.vue";
import { CommonField } from "@/form/CommonField";
import { parseErrorMessage, parseErrorMessageList } from "@/utils/ErrorUtils";
import Button from "@/form/Button.vue";
import { computeLabel } from "@/form/FormUtils";
import axios from "@/utils/ApiUtils";
import {
	AddressDetails,
	ContributionRow,
	DbDetails,
	Employee,
	FundFieldMapping,
} from "@/models/ContributionRow";
import ProgressArrow from "@/components/ProgressArrow.vue";
import {
	getPermissionCheckForEntity,
	reportingCentreDetailsURL,
} from "@/constants/apiconstants";
import { toastErrorMessage, toastSuccessMessage } from "@/plugins/toasts";
import EditFieldErrorWarningList from "../pages/contribution/EditFieldErrorWarningList.vue";
import EmployeeForm from "../pages/contribution/EmployeeForm.vue";
import FundDetailsFields from "../pages/contribution/FundDetailsFields.vue";
import ModalWithSaveAndCancel from "@/components/ModalWithSaveAndCancel.vue";
import { AxiosResponse } from "axios";
import SearchEmployee from "@/components/SearchEmployee.vue";
import ValidationObserverNotifier from "@/components/ValidationObserverNotifier.vue";
import MultiSelect from "@/form/MultiSelect.vue";
import PageHeader from "@/components/PageHeader.vue";
import ContributionEmployeeList from "@/components/ContributionEmployeeList.vue";
import {
	ContributionFileType,
	FundDetails,
	SearchFundDetails,
} from "@/pages/fundTypes";
import AddressForm from "@/components/AddressForm.vue";
import { Logger } from "@/utils/logger";
import ContributionEmployeeFilterForm from "@/pages/contribution/ContributionEmployeeFilterForm.vue";

import { hasPermission } from "@/utils/PermissionUtils";
import { ReportingCentreABN } from "@/models/EmployeeRow";

import { createNamespacedHelpers } from "vuex";
import {
	AllGroupedPermissions,
	EmployerHierarchy,
} from "@/store/modules/persistent/persistentTypes";
import CurrencyField from "@/form/CurrencyField.vue";
import FundFieldMappingField from "@/components/FundFieldMappingField.vue";
import Badge from "@/components/Badge.vue";

const { mapGetters } = createNamespacedHelpers("persistent");

const enum PopupViewMode {
	All = 0,
	// SuperFundMemberCommon contains name, address and termination date/reason
	// data in our SAFF csv files.
	SuperFundMemberCommon = 1,
	// SuperFundMemberContributions contains pay period start/end date and dollar
	// amounts contributed.
	SuperFundMemberContributions = 2,
}

/**
 * This should correspond to the Java class au.com.iress.clearinghouse.portal.rest.ContributionRowResponse
 */
interface ContributionRowResponse {
	data: ContributionRow;
	fieldMap: { [fieldName: string]: CommonField };
}

/**
 * This should correspond to the Java class au.com.iress.clearinghouse.portal.rest.ContributionRowErrorResponse
 */
interface ContributionRowErrorResponse {
	errors: ErrorMap;
	warnings: ErrorMap;
	discardedErrors: ErrorMap;
	discardedWarnings: ErrorMap;
}

type ErrorArray = string[];

type HighlightType = "" | "error" | "warning" | "disablederror";

interface FieldMap<T extends ErrorField | WarningField> {
	[fieldName: string]: T;
}

interface ErrorMap {
	[fieldName: string]: ErrorArray;
}

interface ErrorField {
	label: string;
	errors: ErrorArray;
}

type ErrorFieldMap = FieldMap<ErrorField>;

interface WarningField {
	label: string;
	warnings: ErrorArray;
}

type WarningFieldMap = FieldMap<WarningField>;

const fieldNamesGroupedByTab: string[][] = [
	[
		"title",
		"suffix",
		"givenNames",
		"surname",
		"gender",
		"dateOfBirth",
		"taxFileNo",
		"email",
		"landline",
		"mobile",
		"form", // form level problems are currently shown on the first tab,
	],
	[
		"addressType",
		"addressLine1",
		"addressLine2",
		"addressLine3",
		"addressLine4",
		"locality",
		"postcode",
		"state",
		"countryCode",
	],
	[
		"reportingCentre",
		"abn",
		"employerPayrollName",
		"employerPayrollLocation",
		"payrollNo",
		"superannuationFundGeneratedEmployerIdentifier",
	],
	[
		"fundName",
		"fundAbn",
		"fundUsi",
		"memberClientIdentifier",
		"fundElectronicServiceAddress",
		"fundAccountName",
		"fundBsb",
		"fundAccountNumber",
		"fundRegistrationDate",
		"memberBenefitCategory",
		"employerContributionsSuperannuationGuarantee",
		"employerContributionsSalarySacrificed",
		"personalContributions",
		"employerContributionsAwardOrProductivity",
		"employerContributionsVoluntary",
		"spouseContributions",
		"childContributions",
		"otherThirdPartyContributions",
		"periodStart",
		"periodEnd",
	],
	[
		"employmentStartDate",
		"occupationDescription",
		"employmentStatus",
		"atWorkIndicator",
		"weeklyHoursWorked",
		"terminationDate",
		"terminationReason",
		"annualSalaryBenefits",
		"annualSalaryContributions",
		"annualSalaryContributionsStartDate",
		"annualSalaryContributionsEndDate",
		"insuranceOptOut",
		"annualSalaryInsurance",
		"superContributionCommencementDate",
		"superContributionCeaseDate",
		"registrationAmendmentReason",
		"mrr",
	],
	// index 5 array is dynamic and based on FundFieldMapping (DB Registration tab)
	[],
	// index 6 array is dynamic and based on FundFieldMapping (Additional tab)
	[],
];

enum ColumnGroups {
	EMPLOYEE = "Employee",
	CONTRIBUTION = "Contribution",
}

const dbFieldsKeys: (keyof DbDetails)[] = [
	"annualSalaryInsuranceEffectiveDate",
	"annualSalaryBenefitsEffectiveDate",
	"employeeStatusEffectiveDate",
	"employeeBenefitCategoryEffectiveDate",
	"serviceFraction",
	"serviceFractionStartDate",
	"serviceFractionEndDate",
	"definedBenefitEmployerRate",
	"definedBenefitEmployerRateStartDate",
	"definedBenefitEmployerRateEndDate",
	"definedBenefitMemberRate",
	"definedBenefitMemberRateStartDate",
	"definedBenefitMemberRateEndDate",
	"definedBenefitAnnualSalary1",
	"definedBenefitAnnualSalary1StartDate",
	"definedBenefitAnnualSalary1EndDate",
	"definedBenefitAnnualSalary2",
	"definedBenefitAnnualSalary2StartDate",
	"definedBenefitAnnualSalary2EndDate",
	"definedBenefitAnnualSalary3",
	"definedBenefitAnnualSalary3StartDate",
	"definedBenefitAnnualSalary3EndDate",
	"definedBenefitAnnualSalary4",
	"definedBenefitAnnualSalary4StartDate",
	"definedBenefitAnnualSalary4EndDate",
	"definedBenefitAnnualSalary5",
	"definedBenefitAnnualSalary5StartDate",
	"definedBenefitAnnualSalary5EndDate",
	"leaveWithoutPayCode",
	"leaveWithoutPayCodeStartDate",
	"leaveWithoutPayCodeEndDate",
	"employeeLocationIdentifier",
	"employeeLocationIdentifierStartDate",
	"employeeLocationIdentifierEndDate",
	"ordinaryTimeEarnings",
	"actualPeriodicSalaryWagesEarned",
	"superannuableAllowancesPaid",
	"notionalSuperannuableAllowances",
	"serviceFractionDbCont",
	"serviceFractionEffectiveDate",
	"fullTimeHours",
	"contractedHours",
	"actualHoursPaid",
	"employeeLocationIdentifierDbCont",
	"definedBenefitMemberPreTaxContribution",
	"definedBenefitMemberPostTaxContribution",
	"definedBenefitEmployerContribution",
	"definedBenefitNotionalMemberPreTaxContribution",
	"definedBenefitNotionalMemberPostTaxContribution",
	"definedBenefitNotionalEmployerContribution",
	"fullTimeHoursAdd",
	"partTimeHours",
	"partTimeHoursEffectiveDate",
	"memberGroup",
	"memberRegistrationpayPeriodStartDate",
	"memberRegistrationPayPeriodEndDate",
	"choiceFlag",
	"effectiveDateofChoice",
	"superannuationFundDetailsOccupationCodeforInsuranceDescription",
	"contractlengthgreaterthan12months",
	"memberPayGroup",
	"baseRemuneration",
	"notionalGSSRemuneration",
	"packagedRemuneration",
	"insuranceLevy",
	"groupIPPremium",
	"adminFeeforothercategories",
	"numberofweekspurchased",
	"adicPaymentAmount",
	"contributionDueDays",
	"definedBenefitMemberPreReserveUnitsAmount",
	"definedBenefitMemberPostReserveUnitsAmount",
];

@Component({
	components: {
		Badge,
		FundFieldMappingField,
		ContributionEmployeeFilterForm,
		SearchEmployee,
		Layout,
		AgGridVue,
		DatepickerField,
		Accordion,
		Button,
		Modal,
		ModalOrPopup,
		ModalWithSaveAndCancel,
		FieldGroup,
		TextField,
		TFNField,
		SelectField,
		AutoField,
		MultiSelect,
		Form,
		Grid,
		ErrorList,
		LeftRightFooter,
		Overlay,
		ProgressArrow,
		Container,
		EditFieldErrorWarningList,
		EmployeeForm,
		ValidationObserverNotifier,
		PageHeader,
		ContributionEmployeeList,
		FundDetailsFields,
		AddressForm,
		CurrencyField,
	},
	computed: {
		...mapGetters({
			employerHierarchyList: "employerHierarchy",
			allGroupedPermissions: "allGroupedPermissions",
		}),
	},
})
export default class ContributionEmployeeGridMaintenance extends Vue {
	employerHierarchyList!: EmployerHierarchy[];
	allGroupedPermissions!: AllGroupedPermissions;

	private showModalOrPopup: "modal" | "popup" | null = null;

	private popupViewMode: PopupViewMode = PopupViewMode.All;

	private isAreYouSurePopupShown = false;

	private isFetchingRecord = false;

	private isSubmittingSaveRecord = false;

	private viewMode = false;
	private searchMode = false;
	private addMode = false;

	private isDeleteModalShown = false;
	private hasPermission = hasPermission;
	/**
	 * editFieldMap maps field names to their properties. We receive this value from
	 * the backend and apply it to our <Form> component, which applies it down to each
	 * field.
	 */
	private editFieldMap: { [fieldName: string]: CommonField } | null = null;

	/**
	 * editFieldErrorMap maps field names to a list of errors received from the server.
	 */
	private editFieldErrorMap: ErrorMap = {};

	/**
	 * editFieldWarningMap maps field names to a list of warnings received from the server.
	 */
	private editFieldWarningMap: ErrorMap = {};

	/**
	 * editFieldDiscardedErrorMap maps field names to a list of errors received from the server.
	 */
	private editFieldDiscardedErrorMap: ErrorMap = {};

	/**
	 * editFieldDiscardedWarningMap maps field names to a list of warnings received from the server.
	 */
	private editFieldDiscardedWarningMap: ErrorMap = {};

	/**
	 * editFieldValidationErrorMap maps field names to a list of validation errors from vee-validate in the frontend.
	 */
	private editFieldValidationErrorMap: ErrorMap = {};

	/**
	 * editFormError is an error that applies to the edit form as a whole.
	 * ie. Bad Request
	 */
	private editFormError = "";

	/**
	 * errors is a collection of error messages that hides the entire screen if not empty.
	 * Used for responses.
	 */
	errors: ErrorArray = [];

	/**
	 * editGetErrors are displayed in the edit modal if an error occurs trying to retrieve the contribution
	 * record.
	 */
	editGetErrors: ErrorArray = [];

	/**
	 * recordFormData is the record currently being edited but kept in-tact so
	 * it can be compared against recordFormData for unsaved changes.
	 */
	recordFormDataUnedited: ContributionRow | null = null;

	/**
	 * recordFormData is the record currently being edited.
	 */
	recordFormData: ContributionRow | null = null;

	@Prop({ type: Number, default: 0 }) batchId!: number;
	@Prop({ type: Boolean, default: false }) isFormReadonly!: boolean;

	@Prop(Function) gridReadyChanged!: (value: boolean) => void;
	@Prop(Function) refreshSummary!: () => void;
	@Prop() fundFieldMappings: FundFieldMapping[] | undefined;
	@Prop({ type: String, default: ContributionFileType.SUPERSTREAM })
	readonly contributionFileType!:
		| ContributionFileType.DB_BYPASS
		| ContributionFileType.SUPERSTREAM;

	private gridReady = false;

	private warnings: null | number = 0;

	public $refs!: {
		contributionEmployeeListRef: ContributionEmployeeList;
		validationObserver: VForm;
	};

	private allowedFileRegRef: SelectOption[] | undefined;

	private activeEditTabIndex = 0;

	private highlightedFieldName = "";

	private highlightType: HighlightType = "";
	// Generated by getFullErrorFieldMapGroupedByTab.
	// It is not a computed component because part of its source comes from
	// the ValidationObserver.
	private selectedEmployee: Employee | null = null;

	private selectedFundPayeeCode = "";

	/**
	 * TODO: Ticket ID is not created but requirement is mentioned in CHSN-537
	 * A button can toggle the state of mask/unmask sensitive data.
	 * The usage of this data field is just an example to prove how sensitive
	 * data can be unmasked. In MVP, TFN is never able to be fetched in the frontend
	 * even unmasked paramemter is requested.
	 */
	private unmaskSensitiveData = false;
	private emptyGrid = false;
	private emptyRowData = false;

	private get shouldShowSuperFundMemberCommonField(): boolean {
		return (
			this.showModalOrPopup === "modal" ||
			this.popupViewMode === PopupViewMode.SuperFundMemberCommon
		);
	}

	private get computedEmployeeModalTitle(): string {
		if (this.addMode) {
			return "Add employee";
		}
		if (this.recordFormData) {
			if (this.viewMode) {
				return `View employee - ${this.recordFormData.givenNames} ${this.recordFormData.surname} - ${this.recordFormData.payrollNo}`;
			} else {
				return `Edit employee - ${this.recordFormData.givenNames} ${this.recordFormData.surname} - ${this.recordFormData.payrollNo}`;
			}
		}
		return "";
	}

	private get shouldShowAddressField(): boolean {
		return this.showModalOrPopup === "modal";
	}

	private get shouldShowAmountField(): boolean {
		return (
			this.showModalOrPopup === "modal" ||
			//this.popupViewMode === PopupViewMode.All ||
			this.popupViewMode === PopupViewMode.SuperFundMemberContributions
		);
	}

	private onClickSearchEmployee(): void {
		if (this.isFetchingRecord || this.isFormReadonly) {
			return;
		}
		this.viewMode = false;
		this.searchMode = true;
		this.addMode = false;
	}

	private onClickAdd(selectedEmployee: Employee | null): void {
		if (this.isFetchingRecord || this.isFormReadonly) {
			return;
		}
		this.viewMode = false;
		this.searchMode = false;
		this.addMode = true;
		this.selectedEmployee = selectedEmployee;
		this.maybeOpenAddOrEditForm(0, "");
	}

	private onClickView(rowIndex: number, rowData: ContributionRow): void {
		if (this.isFetchingRecord) {
			return;
		}
		this.viewMode = true;
		this.maybeOpenAddOrEditForm(rowData.fileRegRef, rowData.saffLineId);
	}

	private recordPendingDeletion: ContributionRow | null = null;

	private onClickDelete(rowIndex: number, record: ContributionRow): void {
		if (this.isFormReadonly) {
			return;
		}
		if (!record) {
			return;
		}
		this.recordPendingDeletion = record;
		this.isDeleteModalShown = true;
	}

	private closeDeleteRow(): void {
		this.recordPendingDeletion = null;
		this.isDeleteModalShown = false;
	}

	private triggerDeleteRow(): void {
		if (this.recordPendingDeletion) {
			const fileRegRef = this.recordPendingDeletion.fileRegRef;
			const lineId = this.recordPendingDeletion.saffLineId;
			this.recordPendingDeletion = null;

			if (
				fileRegRef <= 0 ||
				lineId === null ||
				lineId === undefined ||
				lineId.length === 0
			) {
				Logger.error(
					"Unable to delete record",
					this.recordPendingDeletion
				);
				toastErrorMessage("Unable to delete record. Invalid Id.");
				this.isDeleteModalShown = false;
				return;
			}

			axios
				.delete(
					"api/contribution/contributionByKey/" +
						fileRegRef +
						"/" +
						lineId
				)
				.then((response) => {
					toastSuccessMessage("Record deleted successfully");
					// Reload grid and summary data
					// The deletion is performed in a transaction so if an error occurred
					// the records were not deleted
					this.refreshGrid();
					this.refreshResponseBundle();
				})
				.catch((error) => {
					toastErrorMessage(parseErrorMessage(error));
				})
				.finally(() => {
					this.isDeleteModalShown = false;
				});
		}
	}

	// refresh add or edit form
	private async refreshAddOrEditForm(fileRegRef: number, saffLineId: string) {
		if (this.isFetchingRecord) {
			return;
		}
		this.isFetchingRecord = true;
		return await this.refreshEditEmployee(fileRegRef, saffLineId);
	}

	private async maybeOpenAddOrEditForm(
		fileRegRef: number,
		saffLineId: string
	) {
		if (this.isFetchingRecord) {
			return;
		}
		this.recordFormDataUnedited = null;
		this.recordFormData = null;
		// Get form metadata and *maybe* get record (record == null if the id == 0)
		this.isFetchingRecord = true;
		this.editGetErrors = [];
		this.editFormError = "";
		if (!saffLineId) {
			// Adding a new employee.
			let url = "api/contribution/getNewContribution/" + this.batchId;

			// Prefill fund details if the employee was included in a previous contribution.
			if (this.selectedEmployee) {
				url += `/${this.selectedEmployee.payrollNo}/${this.selectedEmployee.reportingCentre}`;
			}

			await axios
				.get<ContributionRowResponse>(url, {
					headers: {
						"Content-Type": "application/json",
					},
				})
				.then((response) => {
					this.setFormResponse(response);
				})
				.catch((e) => {
					this.editGetErrors = parseErrorMessageList(e);
				})
				.finally(() => {
					this.isFetchingRecord = false;
				});
		} else {
			// Editing an existing employee.
			await this.refreshEditEmployee(fileRegRef, saffLineId);
		}
		this.showModalOrPopup = "modal";
	}

	private async refreshEditEmployee(fileRegRef: number, saffLineId: string) {
		// Editing an existing employee.
		return axios
			.get<ContributionRowResponse>(
				"api/contribution/contributionByKey/" +
					fileRegRef +
					"/" +
					saffLineId,
				{
					params: {
						...(this.unmaskSensitiveData && { unmasked: "" }),
					},
					headers: {
						"Content-Type": "application/json",
					},
				}
			)
			.then((response) => {
				this.setFormResponse(response);
				return axios
					.get<ContributionRowErrorResponse>(
						`/api/contribution/contributionErrorsByKey/${fileRegRef}/${saffLineId}`,
						{
							headers: {
								"Content-Type": "application/json",
							},
						}
					)
					.then((resp) => {
						this.editFieldErrorMap = resp.data.errors;
						this.editFieldWarningMap = resp.data.warnings;
						this.editFieldDiscardedErrorMap =
							resp.data.discardedErrors;
						this.editFieldDiscardedWarningMap =
							resp.data.discardedWarnings;
					})
					.finally(() => {
						this.isFetchingRecord = false;
					});
			})
			.catch((e) => {
				this.editGetErrors = parseErrorMessageList(e);
			})
			.finally(() => {
				this.isFetchingRecord = false;
			});
	}

	private setFormResponse(response: AxiosResponse<ContributionRowResponse>) {
		const respData = response.data;
		const contributionRow = respData.data;

		//Add existing employee, default rc to the employee's rc
		if (this.addMode && this.selectedEmployee) {
			respData.data.reportingCentre = Number(
				this.selectedEmployee.reportingCentre
			);
			respData.fieldMap.reportingCentre.readonly = true;
		}
		// In add mode, set default reporting centre if only one RC is exists in the options
		if (
			this.addMode &&
			respData &&
			respData.fieldMap.reportingCentre &&
			respData.fieldMap.reportingCentre.options &&
			respData.fieldMap.reportingCentre.options.length === 1
		) {
			const optionValue =
				respData.fieldMap.reportingCentre.options[0].value;
			respData.data.reportingCentre = Number(optionValue);
		}

		if (this.selectedEmployee) {
			if (
				Object.prototype.hasOwnProperty.call(
					this.selectedEmployee,
					"inactive"
				)
			) {
				delete this.selectedEmployee.inactive;
			}

			// Since ContributionRow is sub type of Employee, can merge Employee into it.
			this.recordFormDataUnedited = {
				...contributionRow,
				...this.selectedEmployee,
			};
		} else {
			this.recordFormDataUnedited = contributionRow;
		}

		// make a deep copy
		this.recordFormData = JSON.parse(
			JSON.stringify(this.recordFormDataUnedited)
		);
		if (respData.fieldMap.fileRegRef) {
			this.allowedFileRegRef = respData.fieldMap.fileRegRef
				.options as SelectOption[];
		}
		if (this.recordFormData?.reportingCentre) {
			this.onReportingCentreChange(this.recordFormData.reportingCentre);
		}
		this.editFieldMap = respData.fieldMap;
		this.isFetchingRecord = false;
	}

	private refreshResponseBundle() {
		if (this.refreshSummary) {
			this.refreshSummary();
		}
	}

	private onEditContinueEditing(): void {
		this.isAreYouSurePopupShown = false;
	}

	private onEditCancel(): void {
		if (
			!this.viewMode &&
			JSON.stringify(this.recordFormDataUnedited) !==
				JSON.stringify(this.recordFormData)
		) {
			this.isAreYouSurePopupShown = true;
			return;
		}

		// Close modal
		this.triggerCloseEditRecordModal();
	}

	private onSearchClose(): void {
		this.searchMode = false;
	}

	get hasEditRecordErrorsInModalMode(): boolean {
		return (
			this.showModalOrPopup === "modal" &&
			this.editGetErrors &&
			this.editGetErrors.length > 0
		);
	}

	get canSubmitEditRecord(): boolean {
		return (
			(!this.editGetErrors || this.editGetErrors.length === 0) &&
			this.recordFormData !== null &&
			!this.isFetchingRecord &&
			!this.isSubmittingSaveRecord &&
			!this.isFormReadonly &&
			this.eachTabHasFormErrors.every((v) => !v)
		);
	}

	private handleCellClicked(params: CellClickedEvent): void {
		const groupHeaderName = params.column
			.getOriginalParent()
			?.getColGroupDef()?.headerName;
		if (groupHeaderName === undefined) {
			return;
		}
		const rowIndex = params.rowIndex;
		if (rowIndex === null) {
			return;
		}

		if (
			groupHeaderName === ColumnGroups.EMPLOYEE ||
			params.colDef.headerName === "Total"
		) {
			this.onClickEdit(rowIndex, params.data, 0);
		} else if (groupHeaderName === ColumnGroups.CONTRIBUTION) {
			this.onClickEdit(rowIndex, params.data, 4);
		} else {
			this.onClickEdit(rowIndex, params.data, 0);
		}
	}

	private onClickEdit(
		rowIndex: number,
		rowData: ContributionRow,
		initialTabIndex = 0
	): void {
		if (this.isFetchingRecord || this.isFormReadonly) {
			return;
		}
		this.activeEditTabIndex = initialTabIndex;
		this.viewMode = false;
		this.addMode = false;

		this.maybeOpenAddOrEditForm(rowData.fileRegRef, rowData.saffLineId);
	}

	private hasEditEmployerAbnPermission = false;

	private handleFormDataBeforePost(data: ContributionRow): ContributionRow {
		const result = data;
		delete result.fundFieldMapping;
		delete result.dbBypass;
		if (this.hasEditEmployerAbnPermission) {
			return result;
		}
		return { ...result, abn: null };
	}

	private async onEditSubmit(): Promise<void> {
		if (!this.canSubmitEditRecord) {
			return;
		}

		if (this.recordFormData === null) {
			return;
		}

		if (!(await this.$refs.validationObserver.validate())) {
			return;
		}

		const record = this.recordFormData;

		this.isSubmittingSaveRecord = true;
		let promise;
		if (!record.saffLineId) {
			delete record.dbBypass;
			promise = axios.post<void>(
				"api/contribution/addNewContributionByBatchId/" + this.batchId,
				record,
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
		} else {
			const data = this.handleFormDataBeforePost(record);
			promise = axios.post<void>(
				"api/contribution/updateContributionByKey/" +
					record.fileRegRef +
					"/" +
					record.saffLineId,
				data,
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
		}
		promise
			.then(async (resp) => {
				// Close modal
				this.triggerCloseEditRecordModal();

				// Reload grid and summary data
				this.refreshGrid();
				this.refreshResponseBundle();
			})
			.catch((e) => {
				// refresh line
				const fileRegRef = e.response?.data?.fileRegRef;
				const saffLineId = e.response?.data?.saffLineId;
				if (fileRegRef && saffLineId) {
					this.refreshAddOrEditForm(fileRegRef, saffLineId);
					// Reload grid and summary data
					this.refreshGrid();
					this.refreshResponseBundle();
				}
				const respErrors = parseErrorMessage(e);
				this.editFormError = respErrors;
				if (
					e &&
					e.response &&
					e.response.data &&
					e.response.data.fieldErrorMap
				) {
					this.editFieldErrorMap = e.response.data.fieldErrorMap;
				}
			})
			.finally(() => {
				this.isSubmittingSaveRecord = false;
			});
	}

	private async onContributionRowValuesChanged({
		newRowIndex,
		newData,
		oldRowIndex,
		oldData,
	}: {
		newRowIndex: number;
		newData: ContributionRow;
		oldRowIndex: number;
		oldData: ContributionRow;
	}): Promise<void> {
		if (newRowIndex !== oldRowIndex) {
			return;
		}

		if (isEqual(newData, oldData)) {
			return;
		}

		const record: ContributionRow = this.handleFormDataBeforePost(newData);
		if (record.saffLineId) {
			await axios
				.post<void>(
					"api/contribution/updateContributionByKey/" +
						record.fileRegRef +
						"/" +
						record.saffLineId,
					record,
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				)
				.then((resp) => {
					// Reload summary and grid data
					this.refreshResponseBundle();
					this.refreshGrid();
				})
				.catch((e) => {
					// Reload grid data to fetch old values
					this.refreshGrid();
					const respErrors = parseErrorMessage(e);
					toastErrorMessage(respErrors);
				});
		}
	}

	private async onReportingCentreChange(value: number) {
		if (this.recordFormData) {
			this.recordFormData.reportingCentre = value;
			if (this.allowedFileRegRef) {
				this.recordFormData.fileRegRef = 0; // backend needs to create new file register for this RC
				for (const option of this.allowedFileRegRef) {
					if (option.label === value.toString()) {
						this.recordFormData.fileRegRef = Number(option.value);
					}
				}
			}
			//check if abn is editable by current user
			await axios
				.get(getPermissionCheckForEntity, {
					params: {
						permission: "EDIT_EMPLOYER_ABN",
						entity: "R_" + this.recordFormData?.reportingCentre,
					},
				})
				.then((response) => {
					this.hasEditEmployerAbnPermission = response.data;
				})
				.catch((error) => {
					toastErrorMessage(parseErrorMessage(error));
					this.hasEditEmployerAbnPermission = false;
				});
			// get reporting centre details
			axios
				.get<ReportingCentreABN>(
					reportingCentreDetailsURL(Number(value)),
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				)
				.then((resp) => {
					if (!this.recordFormData) {
						return;
					}
					const data = resp.data;
					this.recordFormData.abn = data.abn;
					// CHSN-1241: `employerPayrollName` is from Employer table and is varchar(200),
					// but it is put into `FWz_Payroll` in WorkbenchLines which is char(100).
					this.recordFormData.employerPayrollName =
						data.employerPayrollName.substr(0, 100);
					this.recordFormData.employerPayrollLocation =
						data.employerPayrollLocation;
					this.recordFormData.employerId = data.employerId;
				})
				.catch((e) => {
					toastErrorMessage(parseErrorMessage(e));
				});
		}
	}

	private reloadGrid(): void {
		this.gridReady = false;
		this.$refs.contributionEmployeeListRef.reloadEmployeeList();
		this.gridReady = true;
	}

	private refreshGrid(): void {
		this.gridReady = false;
		this.$refs.contributionEmployeeListRef.refreshEmployeeList();
		this.gridReady = true;
	}

	@Watch("gridReady")
	private gridReadyValueChanged() {
		if (this.gridReadyChanged) {
			this.gridReadyChanged(this.gridReady);
		}
	}

	private triggerCloseEditRecordModal(): void {
		this.isAreYouSurePopupShown = false;
		this.showModalOrPopup = null;

		// Add Employee Form set this variable. It is also used in `setFormResponse` to pull data into recordFormData (Bad design)
		// Reset it when form close in case it interferes Edit Employee.
		this.selectedEmployee = null;
		// Add and Edit Employee Form share this variable. Reset when form close in case interference
		this.recordFormData = null;
		this.recordFormDataUnedited = null;
	}

	private onGridReady() {
		this.gridReady = true;
	}

	buildFieldMapFromErrorMap(
		errorMap: ErrorMap,
		fieldMapKeyName:
			| "errors"
			| "warnings"
			| "discardedErrors"
			| "discardedWarnings"
	): FieldMap<any> {
		if (this.editFieldMap === null) {
			return {};
		}
		const editFieldMap = this.editFieldMap;
		const trimmedErrorMap = pickBy(errorMap, (errors) => !isEmpty(errors));

		const valueMap = mapValues(
			trimmedErrorMap,
			(errors: ErrorArray, fieldName: string) => {
				if (fieldName === "form") {
					// Form level errors.
					return { label: "Form", [fieldMapKeyName]: errors };
				}
				if (fieldName === "mrr") {
					return {
						label: "Member Registration",
						[fieldMapKeyName]: errors,
					};
				}
				if (!editFieldMap[fieldName]) {
					return { label: fieldName, [fieldMapKeyName]: [] };
				}

				return {
					label: computeLabel(
						editFieldMap[fieldName].label,
						fieldName
					),
					[fieldMapKeyName]: errors,
				};
			}
		);
		return valueMap;
	}

	/**
	 * Merge ErrorFieldMap objects together. ErrorFieldMap objects are
	 * - transformed from validation errors from vee-validate (frontend), from fieldsWithFormErrorsGroupedByTab property.
	 * - transformed this.editFieldErrorMap from server, from fieldsWithErrorsGroupedByTab property.
	 */
	get mergedFieldsWithErrorsGroupedByTab(): ErrorFieldMap[] {
		const fieldMapKeyName = "errors";
		return mergeWith(
			[],
			this.fieldsWithErrorsGroupedByTab,
			this.fieldsWithFormErrorsGroupedByTab,
			(acc, src, key) => {
				if (key === fieldMapKeyName) {
					return (acc ?? []).concat(src);
				}
			}
		);
	}

	get fieldNamesGroupedByTab(): string[][] {
		if (this.fundFieldMappings) {
			const fieldNamesGroupedByTabWithDynamicGrouping = [
				...fieldNamesGroupedByTab,
			];
			// create a list of all errors and warnings in the row
			const allFieldMap: ErrorMap = {
				...this.editFieldErrorMap,
				...this.editFieldWarningMap,
				...this.editFieldDiscardedErrorMap,
				...this.editFieldDiscardedWarningMap,
			};
			const acurityErrors: string[] = [];

			keys(allFieldMap).forEach((key) => {
				if (
					this.editFieldMap != null &&
					!this.editFieldMap[key] &&
					!this.dynamicFields.find((f) => f.fieldName === key)
				) {
					acurityErrors.push(key);
				}
			});

			fieldNamesGroupedByTabWithDynamicGrouping[0] =
				fieldNamesGroupedByTab[0]
					.concat(this.personalDynamicFields.map((f) => f.fieldName))
					.concat(acurityErrors);

			fieldNamesGroupedByTabWithDynamicGrouping[1] =
				fieldNamesGroupedByTab[1].concat(
					this.addressDynamicFields.map((f) => f.fieldName)
				);

			fieldNamesGroupedByTabWithDynamicGrouping[2] =
				fieldNamesGroupedByTab[2].concat(
					this.employerDynamicFields.map((f) => f.fieldName)
				);

			fieldNamesGroupedByTabWithDynamicGrouping[3] =
				fieldNamesGroupedByTab[3].concat(
					this.employmentDynamicFields.map((f) => f.fieldName)
				);

			fieldNamesGroupedByTabWithDynamicGrouping[4] =
				fieldNamesGroupedByTab[4].concat(
					this.fundDynamicFields.map((f) => f.fieldName)
				);

			fieldNamesGroupedByTabWithDynamicGrouping[5] =
				this.dbRegistrationDynamicFields.map((f) => f.fieldName);

			fieldNamesGroupedByTabWithDynamicGrouping[6] =
				this.additionalDynamicFields.map((f) => f.fieldName);

			return fieldNamesGroupedByTabWithDynamicGrouping;
		}
		return fieldNamesGroupedByTab;
	}

	get fieldsWithErrorsGroupedByTab(): ErrorFieldMap[] {
		const errorFieldMap = this.buildFieldMapFromErrorMap(
			this.editFieldErrorMap,
			"errors"
		);

		return this.fieldNamesGroupedByTab.map((fieldNames) =>
			pick(errorFieldMap, fieldNames)
		);
	}

	get fieldsWithWarningsGroupedByTab(): WarningFieldMap[] {
		//const warningmapTmp = JSON.parse(JSON.stringify(this.editFieldWarningMap));
		const warningFieldMap = this.buildFieldMapFromErrorMap(
			this.editFieldWarningMap,
			"warnings"
		);

		return this.fieldNamesGroupedByTab.map((fieldNames) =>
			pick(warningFieldMap, fieldNames)
		);
	}

	get fieldsWithDiscardedErrorsGroupedByTab(): ErrorFieldMap[] {
		const errorFieldMap = this.buildFieldMapFromErrorMap(
			this.editFieldDiscardedErrorMap,
			"discardedErrors"
		);

		return this.fieldNamesGroupedByTab.map((fieldNames) =>
			pick(errorFieldMap, fieldNames)
		);
	}

	get fieldsWithDiscardedWarningsGroupedByTab(): ErrorFieldMap[] {
		const errorFieldMap = this.buildFieldMapFromErrorMap(
			this.editFieldDiscardedWarningMap,
			"discardedWarnings"
		);

		return this.fieldNamesGroupedByTab.map((fieldNames) =>
			pick(errorFieldMap, fieldNames)
		);
	}

	/**
	 * Errors from vee-validate in the frontend!
	 */
	get fieldsWithFormErrorsGroupedByTab(): ErrorFieldMap[] {
		const errorFieldMap = this.buildFieldMapFromErrorMap(
			this.editFieldValidationErrorMap,
			"errors"
		);

		return this.fieldNamesGroupedByTab.map((fieldNames) =>
			pick(errorFieldMap, fieldNames)
		);
	}

	get eachTabHasErrors(): boolean[] {
		return this.fieldsWithErrorsGroupedByTab.map(
			(fieldsWithErrors) => !isEmpty(fieldsWithErrors)
		);
	}

	get eachTabHasWarnings(): boolean[] {
		return this.fieldsWithWarningsGroupedByTab.map(
			(fieldsWithWarnings) => !isEmpty(fieldsWithWarnings)
		);
	}

	get eachTabHasDiscardedErrors(): boolean[] {
		return this.fieldsWithDiscardedErrorsGroupedByTab.map(
			(fieldsWithErrors) => !isEmpty(fieldsWithErrors)
		);
	}

	get eachTabHasDiscardedWarnings(): boolean[] {
		return this.fieldsWithDiscardedWarningsGroupedByTab.map(
			(fieldsWithErrors) => !isEmpty(fieldsWithErrors)
		);
	}

	/**
	 * Tab errors states for errors from vee-validate in the frontend
	 */
	get eachTabHasFormErrors(): boolean[] {
		return this.fieldsWithFormErrorsGroupedByTab.map(
			(fieldsWithErrors) => !isEmpty(fieldsWithErrors)
		);
	}

	get invalidityClassesForEachTab() {
		// The array to be mapped over does not matter, as long as it
		// is as long as the tabs list.
		return this.eachTabHasErrors.map((_, index) => {
			if (
				this.eachTabHasErrors[index] ||
				this.eachTabHasFormErrors[index]
			) {
				return "ContributionPage__edit__tab--error";
			} else if (this.eachTabHasWarnings[index]) {
				return "ContributionPage__edit__tab--warning";
			} else if (
				this.eachTabHasDiscardedErrors[index] ||
				this.eachTabHasDiscardedWarnings[index]
			) {
				//Return value is not CSS. Just need to be a non empty string to enable list of errors/warnings
				return "ContributionPage__edit__tab";
			}
			return "";
		});
	}

	get activeTabHasInvalidFields() {
		return Boolean(
			this.invalidityClassesForEachTab[this.activeEditTabIndex]
		);
	}

	private handleFieldErrorWarningListChange(
		highlightedFieldName: string,
		highlightType: HighlightType
	) {
		this.highlightedFieldName = highlightedFieldName;
		this.highlightType = highlightType;
	}

	private clearErrorsWarnings(fieldName: string) {
		if (this.editFieldErrorMap) {
			this.$delete(this.editFieldErrorMap, fieldName);
		}
		if (this.editFieldWarningMap) {
			this.$delete(this.editFieldWarningMap, fieldName);
		}
		if (this.editFieldDiscardedErrorMap) {
			this.$delete(this.editFieldDiscardedErrorMap, fieldName);
		}
		if (this.editFieldDiscardedWarningMap) {
			this.$delete(this.editFieldDiscardedWarningMap, fieldName);
		}
	}

	@Watch("showModalOrPopup")
	private clearFormStatesWhenModalOrPopupIsClosed(
		value: "modal" | "popup" | null
	) {
		if (value === null) {
			this.editFormError = "";
			this.editFieldErrorMap = {};
			this.editFieldWarningMap = {};
			this.activeEditTabIndex = 0;
			this.selectedFundPayeeCode = "";
		}
	}

	onValidationObserverErrorsChanged(errors: ErrorMap) {
		this.editFieldValidationErrorMap = errors;
	}

	onTaxFileNoSuppliedChange(checked: boolean) {
		if (!this.recordFormData) {
			return;
		}
		this.recordFormData.taxFileNoSupplied = checked;
	}

	get fundDetailsOfFocusedEmployee(): FundDetails | {} {
		if (this.recordFormData === null) {
			return {};
		}

		const formData = this.recordFormData;
		const fundDetailsKeys: (keyof FundDetails)[] = [
			"fundName",
			"fundAbn",
			"fundElectronicServiceAddress",
			"fundBsb",
			"fundAccountNumber",
			"fundAccountName",
			"fundUsi",
		];
		const fundDetails: Partial<FundDetails> = {};
		fundDetailsKeys.forEach((key) => {
			fundDetails[key] = formData[key] ? (formData[key] as string) : "";
		});
		return fundDetails;
	}

	get addressDetailsOfEmployee(): AddressDetails | {} {
		if (this.recordFormData === null) {
			return {};
		}

		const formData = this.recordFormData;
		const addressDetailsKeys: (keyof AddressDetails)[] = [
			"addressType",
			"countryCode",
			"addressLine1",
			"addressLine2",
			"addressLine3",
			"addressLine4",
			"locality",
			"postcode",
			"state",
		];
		const addressDetails: Partial<AddressDetails> = {};
		addressDetailsKeys.forEach((key) => {
			addressDetails[key] = formData[key];
		});
		return addressDetails;
	}

	updateFundDetailsOfFocusedEmployee(value: SearchFundDetails) {
		if (!this.recordFormData) {
			return;
		}

		const form = this.recordFormData;
		const fundDetailsKeys: (keyof FundDetails)[] = [
			"fundName",
			"fundAbn",
			"fundElectronicServiceAddress",
			"fundBsb",
			"fundAccountNumber",
			"fundAccountName",
			"fundUsi",
			"superannuationFundGeneratedEmployerIdentifier",
		];

		fundDetailsKeys.forEach((key) => {
			form[key] = value[key];
		});

		this.selectedFundPayeeCode = value.payeeCode;
	}

	updateAddressDetailsOfEmployee(value: AddressDetails) {
		if (!this.recordFormData) {
			return;
		}

		const form = this.recordFormData;
		const addressDetailsKeys: (keyof AddressDetails)[] = [
			"addressType",
			"countryCode",
			"addressLine1",
			"addressLine2",
			"addressLine3",
			"addressLine4",
			"locality",
			"postcode",
			"state",
		];

		addressDetailsKeys.forEach((key) => {
			form[key] = value[key];
		});
	}

	get fundTypeOfFocusedEmployee(): "APRA" | "SMSF" | "" {
		if (isEmpty(this.fundDetailsOfFocusedEmployee)) {
			return "";
		}

		const fundDetails = this.fundDetailsOfFocusedEmployee as FundDetails;

		if (fundDetails.fundUsi) {
			return "APRA";
		}
		if (fundDetails.fundAbn) {
			return "SMSF";
		}

		return "";
	}

	private setNoRows() {
		this.emptyRowData = true;
	}

	private numOfErrorsForEachTab(tabIndex: number) {
		return Object.keys(this.mergedFieldsWithErrorsGroupedByTab[tabIndex])
			.length;
	}

	private numOfWarningsForEachTab(tabIndex: number) {
		return Object.keys(this.fieldsWithWarningsGroupedByTab[tabIndex])
			.length;
	}

	private numOfDiscardedErrorsForEachTab(tabIndex: number) {
		return Object.keys(this.fieldsWithDiscardedErrorsGroupedByTab[tabIndex])
			.length;
	}

	private numOfDiscardedWarningsForEachTab(tabIndex: number) {
		return Object.keys(
			this.fieldsWithDiscardedWarningsGroupedByTab[tabIndex]
		).length;
	}

	get dynamicFields(): FundFieldMapping[] {
		if (this.fundFieldMappings) {
			return this.fundFieldMappings?.filter(
				(f) =>
					f.usi === this.recordFormData?.fundUsi &&
					f.employerId === this.recordFormData.employerId
			);
		}
		return [];
	}

	get personalDynamicFields(): FundFieldMapping[] {
		return this.dynamicFields.filter((f) => f.tab === "PRSNL");
	}

	get addressDynamicFields(): FundFieldMapping[] {
		return this.dynamicFields.filter((f) => f.tab === "ADDR");
	}

	get employerDynamicFields(): FundFieldMapping[] {
		return this.dynamicFields.filter((f) => f.tab === "EMPLR");
	}

	get employmentDynamicFields(): FundFieldMapping[] {
		return this.dynamicFields.filter((f) => f.tab === "EMPLYM");
	}

	get fundDynamicFields(): FundFieldMapping[] {
		return this.dynamicFields.filter((f) => f.tab === "FUND");
	}

	get dbRegistrationDynamicFields(): FundFieldMapping[] {
		return this.dynamicFields.filter((f) => f.tab === "DBREGO");
	}

	get additionalDynamicFields(): FundFieldMapping[] {
		return this.dynamicFields.filter((f) => f.tab === "ADDL");
	}

	onDbInputChange(e: { data: any; fieldName: string }) {
		// Add to the custom field form. Probs don't need to. Can add to the recordFormData directly.
		if (!this.recordFormData) {
			return;
		}
		// custom fields will show here
		if (Object.keys(this.recordFormData).includes(e.fieldName)) {
			this.recordFormData[e.fieldName] = e.data;
			// db non-custom fields will show here
		} else {
			this.recordFormData[
				dbFieldsKeys.filter((k) => k === e.fieldName)[0]
			] = e.data;
		}
	}
}
