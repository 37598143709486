import Vue from "vue";
import VueRouter, { RawLocation, Route, RouteConfig } from "vue-router";
import {
	MiddlewareContext,
	MiddlewareFunction,
} from "@/router/middleware/middleware";
import permission from "@/router/middleware/permission";

import store from "@/store";
import routes from "@/router/routes";
Vue.use(VueRouter);

export interface AppRouteMeta {
	permissions?: string[];
	title?: string;
	icon?: string[];
}

export interface AppRoute extends Route {
	meta?: AppRouteMeta;
}

export interface AppRouteConfig extends RouteConfig {
	meta?: AppRouteMeta;
}

export type AppRouteNextFunction<T extends Vue = Vue> = (
	to?: RawLocation | false | ((vm: T) => any) | void
) => any;

let defaultBase = "/";
if (process.env.VUE_APP_BASE_URL) {
	defaultBase = process.env.VUE_APP_BASE_URL;
}

const router = new VueRouter({
	base: defaultBase,
	routes,
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(
	context: MiddlewareContext,
	middleware: MiddlewareFunction[],
	index: number
): AppRouteNextFunction {
	const subsequentMiddleware = middleware[index];
	// If no subsequent Middleware exists,
	// the default `next()` callback is returned.
	if (!subsequentMiddleware) {
		return context.next;
	}

	return (...parameters: any[]) => {
		// Run the default Vue Router `next()` callback first.
		context.next(...parameters);
		// Then run the subsequent Middleware with a new
		// `nextMiddleware()` callback.
		const nextMiddleware = nextFactory(context, middleware, index + 1);
		subsequentMiddleware({ ...context, next: nextMiddleware });
	};
}

router.beforeEach(
	(to: AppRoute, from: AppRoute, next: AppRouteNextFunction) => {
		const middlewareList: MiddlewareFunction[] = [permission];
		const isLoggedIn = Boolean(store.getters["persistent/isLoggedIn"]);
		// If the user is not logged in, check if its a route which doesn't require auth
		if (
			to.name !== "Login" &&
			to.name !== "Registration of Interest" &&
			!isLoggedIn
		) {
			next({ name: "Login" });
		} else {
			const context: MiddlewareContext = {
				from,
				next,
				router,
				store,
				to,
			};
			const nextMiddleware = nextFactory(context, middlewareList, 1);

			return middlewareList[0]({ ...context, next: nextMiddleware });
		}
	}
);

export default router;
