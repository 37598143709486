import { RoutePath } from "@/router/routePath";
import { SelectOption } from "@/form/FieldOptions";
import { MessageType, AccountStatus, YesNoAllType } from "@/grid/gridTypes";
import { FundType } from "@/pages/fundTypes";

type ProgressArrowStep =
	| "Data input"
	| "File errors and warnings"
	| "Validate contents"
	| "Final review"
	| "Payment details";

export interface Item {
	label: ProgressArrowStep;
	done: boolean;
	active: boolean;
	disabled: boolean;
	routePath: RoutePath;
	disabledTooltip?: string;
}

const progressArrowSteps: Item[] = [
	{
		label: "Data input",
		done: false,
		active: false,
		disabled: false,
		routePath: RoutePath.DataInput,
	},
	{
		label: "File errors and warnings",
		done: false,
		active: false,
		disabled: false,
		routePath: RoutePath.FileErrorPage,
		disabledTooltip: "No file level errors or warnings",
	},
	{
		label: "Validate contents",
		done: false,
		active: false,
		disabled: false,
		routePath: RoutePath.ContributionValidateContents,
	},
	{
		label: "Final review",
		done: false,
		active: false,
		disabled: false,
		routePath: RoutePath.FinalBatchReview,
	},
	{
		label: "Payment details",
		done: false,
		active: false,
		disabled: false,
		routePath: RoutePath.PaymentDetails,
	},
];

const getStepItem = (item: Item, step: number, activeStep: number) => {
	if (step < activeStep) {
		return { ...item, done: true };
	}
	if (step === activeStep) {
		return { ...item, active: true };
	}
	return { ...item };
};

/**
 * generate steps array from step number or name.
 * @param step starts from 1 if it is a number
 */
export function getSteps(step: number | ProgressArrowStep): Item[] {
	if (typeof step === "number") {
		if (step <= 0 || step > progressArrowSteps.length) {
			throw Error("Invalid step number or step name");
		}
		return progressArrowSteps.map((item, index) =>
			getStepItem(item, index + 1, step)
		);
	} else {
		return getSteps(
			progressArrowSteps.findIndex((item) => item.label === step) + 1
		);
	}
}

function getStepNumber(step: ProgressArrowStep) {
	return progressArrowSteps.findIndex((item) => item.label === step) + 1;
}

export function getStep(steps: Item[], step: number | ProgressArrowStep) {
	if (typeof step === "string") {
		step = getStepNumber(step);
	}

	if (!step || step > steps.length + 1) {
		throw Error("Invalid step [" + step + "]");
	}

	return steps[step - 1];
}

export function getNextStep(
	steps: Item[],
	currentStep: number | ProgressArrowStep
): Item {
	if (typeof currentStep === "string") {
		currentStep = getStepNumber(currentStep);
	}
	const nextStep = getStep(steps, currentStep + 1);

	if (nextStep.disabled) {
		return getNextStep(steps, currentStep + 1);
	}

	return nextStep;
}

export function getPreviousStep(
	steps: Item[],
	currentStep: number | ProgressArrowStep
): Item {
	if (typeof currentStep === "string") {
		currentStep = getStepNumber(currentStep);
	}
	const prevStep = getStep(steps, currentStep - 1);
	if (prevStep.disabled) {
		return getPreviousStep(steps, currentStep - 1);
	}

	return prevStep;
}

export function disableStep(
	steps: Item[],
	step: number | ProgressArrowStep,
	disable: boolean
) {
	getStep(steps, step).disabled = disable;
}

export function enableFileAndWarningsStep(
	steps: Item[],
	isWithFileLevelErrors: boolean
) {
	disableStep(steps, "File errors and warnings", !isWithFileLevelErrors);
}

export const accountStatus: SelectOption[] = [
	{
		label: "Active only",
		value: AccountStatus.ACTIVE,
	},
	{
		label: "Inactive only",
		value: AccountStatus.INACTIVE,
	},
	{
		label: "All",
		value: AccountStatus.ALL,
	},
];

export const messageType: SelectOption[] = [
	{
		label: "MROR",
		value: MessageType.MROR,
	},
	{
		label: "CTER",
		value: MessageType.CTER,
	},
	{
		label: "All",
		value: MessageType.ALL,
	},
];

// This corresponds to au.com.iress.clearinghouse.portal.grid.FilterOptions.YesNoAll
export const yesNoAllType: SelectOption[] = [
	{
		label: "Yes",
		value: YesNoAllType.YES,
	},
	{
		label: "No",
		value: YesNoAllType.NO,
	},
	{
		label: "All",
		value: YesNoAllType.ALL,
	},
];
export const fundType: SelectOption[] = [
	{
		label: "ALL",
		value: "",
	},
	{
		label: "APRA",
		value: FundType.APRA,
	},
	{
		label: "SMSF",
		value: FundType.SMSF,
	},
];

//value must match enum au.com.iress.clearinghouse.portal.contribution.ContributionFileType's code
export const batchType: SelectOption[] = [
	{
		label: "SuperStream",
		value: "SPSTRM",
	},
	{
		label: "DB direct",
		value: "DBBYP",
	},
];
