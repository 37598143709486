import { AgChartTheme } from "ag-charts-community";
import iressVariables from "../../themes/iress/styles/variables.scss";

export async function chartTheme(): Promise<AgChartTheme> {
	let variables;
	if (process.env.VUE_APP_BRAND === "iress") {
		variables = iressVariables;
	} else {
		variables = await import(
			`../../themes/${process.env.VUE_APP_BRAND}/styles/variables.scss`
		);
	}
	if (variables == null) {
		// Default to iress brand
		variables = iressVariables;
	}

	return {
		palette: {
			fills: [variables.graphPrimary, variables.graphSecondary],
			strokes: [variables.graphPrimary, variables.graphSecondary],
		},
		overrides: {
			pie: {
				legend: {
					enabled: false,
				},
				series: {
					strokeOpacity: 0,
					calloutLine: {
						colors: [
							variables.graphPrimary,
							variables.graphSecondary,
						],
					},
				},
			},
			column: {
				legend: {
					enabled: false,
				},
			},
		},
	};
}
