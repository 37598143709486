






























































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { vueVimeoPlayer } from "vue-vimeo-player";
import Layout from "@/components/Layout.vue";
import axios from "@/utils/ApiUtils";
import { vidoesListURL } from "@/constants/apiconstants";

interface VideoDetail {
	title: string;
	url: string;
}

@Component({
	components: {
		Layout,
		vueVimeoPlayer,
	},
})
export default class VideosPage extends Vue {
	private helpVideoList: VideoDetail[] = [];
	private showErrorMessage = false;

	mounted() {
		this.fetchVideoList();
	}

	private async fetchVideoList(): Promise<void> {
		await axios
			.get<VideoDetail[]>(vidoesListURL(), {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then((response) => {
				this.helpVideoList = response.data;
			})
			.catch((error) => {
				this.showErrorMessage = true;
			});
	}
}
