import { at, find } from "lodash";

/**
 * parseErrorMessageList will take a response from the backend and transform
 * it into an array of error messages.
 *
 * This method was designed to be used so users can naively pass the list of errors
 * to components that expect an array without things breaking.
 *
 * Keep spaces flag will ensure error messages don't lose additional spaces when being converted to html.
 *
 */
export function parseErrorMessageList(
	error: any,
	keepSpaces = false
): string[] {
	const res: string | string[] = find(
		at(error, [
			// error_description is returned for oauth messages returned
			// from server
			"response.data.error_description",
			"data.error_description",
			"error_description",
			// Handle object with an AxiosResponse property "response"
			"response.data.message",
			"response.data.error",
			// Handle response data wrapped in AxiosResponse
			"data.message",
			"data.error",
			// Handle response data directly
			"message",
			"error",
		])
	);
	if (!res) {
		return ["Unknown Error"];
	}
	if (typeof res === "string") {
		if (keepSpaces) {
			return [res.replace(/ /g, "\u00A0")];
		}
		return [res];
	}
	// Detect if the structure of the error message we returned is incorrect. If it is, just
	// show "Unknown Error", otherwise weird characters / etc might print on screen.
	if (!Array.isArray(res)) {
		if (process.env.NODE_ENV === "development") {
			return [
				"parseErrorMessage is unable to parse given error structure: " +
					JSON.stringify(res),
			];
		} else {
			return ["Unknown Error"];
		}
	}
	if (res.length > 0 && typeof res[0] !== "string") {
		if (process.env.NODE_ENV === "development") {
			return [
				"parseErrorMessage is unable to parse given error structure: " +
					JSON.stringify(res),
			];
		} else {
			return ["Unknown Error"];
		}
	}
	if (keepSpaces) {
		return res.map((r) => r.replace(/ /g, "\u00A0"));
	}
	return res;
}

/**
 * parseErrorMessage will take a response from the backend and transform
 * it into a single string for error message handling.
 *
 * If you need to handle multiple error messages and render them nicely, use "parseErrorMessageList".
 */
export function parseErrorMessage(error: any): string {
	const res = parseErrorMessageList(error);
	return res.join("\n");
}

// NOTE(Jae): 2020-06-12
// These are currently unused and have been commented out so that we can
// bring it back rapidly and to communicate that they are unused.
// These should be deleted in 3 months if we decide we don't need any of these.

//export const isSuccessfulResponse = (response: Response) => {
//    return !!(response.status >= 200 && response.status < 300);
//};
//export const isSuccessfulAjaxResponse = (response: AxiosResponse) => {
//    return !!(response.status >= 200 && response.status < 300);
//};
//export interface PortalException {
//    error: string;
//    message: string;
//}

// tslint:disable-next-line:no-empty-interface
/*export class PortalError extends Error {
    public remediationAction: (() => void)|undefined;

    constructor(message: string, public title: string, remediationAction?: () => void) {
        super(message);
        this.remediationAction = remediationAction;

        Object.setPrototypeOf(this, PortalError.prototype);
    }
}

export interface PortalErrorResponse {
    exceptionName: string;
    message: string;
    detailedMessage: string;
    error: string;
}

export class PortalErrorResponse implements PortalErrorResponse {
    public detailedMessage: string;
    public exceptionName: string;
    public message: string;
    public error: string;

    constructor(portalErrorResponse: PortalErrorResponse) {
        this.detailedMessage = portalErrorResponse.detailedMessage;
        this.exceptionName = portalErrorResponse.exceptionName;
        this.message = portalErrorResponse.message;
        this.error = portalErrorResponse.error;
        this.message = portalErrorResponse.message;
    }

    public isPortalErrorResponse(): boolean {
        return (
            (!_.isNil(this.detailedMessage) && !_.isNil(this.exceptionName) && !_.isNil(this.message)) ||
            (!_.isNil(this.message) && !_.isNil(this.error))
        );
    }
}
*/
