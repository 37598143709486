





































































































































































import { Component, Watch } from "vue-property-decorator";
import TextField from "@/form/TextField.vue";
import NumberField from "@/form/NumberField.vue";
import FieldHolder from "@/form/FieldHolder.vue";
import SearchFund from "@/components/SearchFund.vue";
import {
	ContributionSubmissionMethod,
	DateFormat,
	DefaultFund,
} from "@/store/modules/registrationofinterest/registrationOfInterestTypes";
import BaseRegistrationOfInterestStep from "./BaseRegistrationOfInterestStep.vue";
import { FundType, SearchFundDetails } from "@/pages/fundTypes";
import { createNamespacedHelpers } from "vuex";
import Multiselect from "vue-multiselect";
import {
	searchForApraFundApi,
	defaultFundsApi,
} from "@/constants/apiconstants";
import axios from "axios";
import { toastErrorMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";

interface DateFormatOption {
	format: DateFormat;
	label: keyof typeof DateFormat;
	example: string;
}

interface Fund {
	name: string;
	usi: string;
	abn: string;
}

// The date format here are the same as CompanyConfigDetails.vue - configDateFormats
const dateFormatOptions: DateFormatOption[] = [
	{
		format: DateFormat["YYYY-MM-DD"],
		label: "YYYY-MM-DD",
		example: "2020-05-07",
	},
	{
		format: DateFormat["YY-MMM-DD"],
		label: "YY-MMM-DD",
		example: "20-May-07",
	},
	{
		format: DateFormat["YY-MM-DD"],
		label: "YY-MM-DD",
		example: "20-05-07",
	},
	{
		format: DateFormat["YYYY-MMM-DD"],
		label: "YYYY-MMM-DD",
		example: "2020-May-07",
	},
	{
		format: DateFormat["YYYY MMM DD"],
		label: "YYYY MMM DD",
		example: "2020 May 07",
	},
	{
		format: DateFormat["YYYY/MM/DD"],
		label: "YYYY/MM/DD",
		example: "2020/05/07",
	},
	{
		format: DateFormat["YY/MM/DD"],
		label: "YY/MM/DD",
		example: "20/05/07",
	},
	{
		format: DateFormat["MMM-DD-YY"],
		label: "MMM-DD-YY",
		example: "May-07-20",
	},
	{
		format: DateFormat["MM-DD-YYYY"],
		label: "MM-DD-YYYY",
		example: "05-07-2020",
	},
	{
		format: DateFormat["MM-DD-YY"],
		label: "MM-DD-YY",
		example: "05-07-20",
	},
	{
		format: DateFormat["MMM-DD-YYYY"],
		label: "MMM-DD-YYYY",
		example: "May-07-2020",
	},
	{
		format: DateFormat["MMM DD YYYY"],
		label: "MMM DD YYYY",
		example: "May 07 2020",
	},
	{
		format: DateFormat["MM/DD/YYYY"],
		label: "MM/DD/YYYY",
		example: "05/07/2020",
	},
	{
		format: DateFormat["MM/DD/YY"],
		label: "MM/DD/YY",
		example: "05/07/20",
	},
	{
		format: DateFormat["DD-MMM-YY"],
		label: "DD-MMM-YY",
		example: "07-May-20",
	},
	{
		format: DateFormat["DD-MM-YYYY"],
		label: "DD-MM-YYYY",
		example: "07-05-2020",
	},
	{
		format: DateFormat["DD-MM-YY"],
		label: "DD-MM-YY",
		example: "07-05-20",
	},
	{
		format: DateFormat["DD-MMM-YYYY"],
		label: "DD-MMM-YYYY",
		example: "07-May-2020",
	},
	{
		format: DateFormat["DD MMM YYYY"],
		label: "DD MMM YYYY",
		example: "07 May 2020",
	},
	{
		format: DateFormat["DD/MM/YYYY"],
		label: "DD/MM/YYYY",
		example: "07/05/2020",
	},
	{
		format: DateFormat["DD/MM/YY"],
		label: "DD/MM/YY",
		example: "07/05/20",
	},
];

const { mapState } = createNamespacedHelpers("registrationOfInterest");

@Component({
	components: {
		Multiselect,
		NumberField,
		TextField,
		FieldHolder,
		SearchFund,
	},
	computed: mapState([
		"contributionSubmissionMethod",
		"dateFormat",
		"defaultFund",
		"defaultFundOther",
		"numberOfEmployees",
		"secretCode",
	]),
})
export default class OtherStep extends BaseRegistrationOfInterestStep {
	contributionSubmissionMethod!: ContributionSubmissionMethod;
	dateFormat!: DateFormat;
	defaultFund!: string;
	defaultFundOther!: string;
	numberOfEmployees!: number | "";
	secretCode!: string;
	funds: Fund[] = [];

	selectedDateFormatOption: DateFormatOption = dateFormatOptions[0];
	selectedDefaultFundOtherOption: SearchFundDetails | null = null;

	get dateFormatOptions(): DateFormatOption[] {
		return dateFormatOptions;
	}

	get ContributionSubmissionMethod(): typeof ContributionSubmissionMethod {
		return ContributionSubmissionMethod;
	}

	get DefaultFund(): typeof DefaultFund {
		return DefaultFund;
	}

	get FundType(): typeof FundType {
		return FundType;
	}

	get searchFundProps(): { [propName: string]: any } {
		return {
			fetchMethod: "get",
			apiUrl: searchForApraFundApi,
			customRequestHeaders: {
				"X-Registration-Wizard-Unique-Access-Code": this.secretCode,
			},
		};
	}

	@Watch("secretCode")
	loadDefaultFunds() {
		axios
			.get<Fund[]>(defaultFundsApi, {
				headers: {
					"X-Registration-Wizard-Unique-Access-Code": this.secretCode,
				},
			})
			.then((response) => {
				//This will return at least one fund (otherwise error if nothing is configured)
				this.funds = response.data;
				this.updateField({ defaultFund: response.data[0].usi });
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
	}

	getLabelForDateFormatOption(dateFormatOption: DateFormatOption) {
		return `${dateFormatOption.label} (${dateFormatOption.example})`;
	}

	getLabelForFundOption(fund: SearchFundDetails) {
		return `${fund.fundName} - ABN ${fund.fundAbn}, USI ${fund.fundUsi}`;
	}

	@Watch("selectedDateFormatOption")
	handleSelectedDateFormatOptionChange(
		dateFormatOption: DateFormatOption,
		previousOption: DateFormatOption
	) {
		this.updateField({ dateFormat: dateFormatOption.format });
	}

	@Watch("defaultFund")
	maybeClearDefaultFundOther(
		_: DefaultFund,
		previousDefaultFund: DefaultFund
	) {
		if (previousDefaultFund === DefaultFund.OTHER) {
			this.selectedDefaultFundOtherOption = null;
		}
	}

	@Watch("selectedDefaultFundOtherOption")
	updateDefaultFundOther(fund: SearchFundDetails | null) {
		if (fund) {
			this.updateField({ defaultFundOther: String(fund.fundUsi) });
		} else {
			this.updateField({ defaultFundOther: "" });
		}
	}
}
