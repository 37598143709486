



















































import Component from "vue-class-component";
import Container from "@/components/Container.vue";
import LeftRightFooter from "@/components/LeftRightFooter.vue";
import Grid from "@/grid/Grid.vue";
import Button from "@/form/Button.vue";
import GridLoadingOverlay from "@/grid/GridLoadingOverlay.vue";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import TextField from "@/form/TextField.vue";
import AutoField from "@/form/AutoField.vue";
import FieldGroup from "@/form/FieldGroup.vue";
import { capitalise } from "@/utils/CommonUtils";
import TableForm from "@/components/TableForm.vue";
import { ColDef, ColGroupDef } from "ag-grid-community";
import axios from "@/utils/ApiUtils";
import { toastErrorMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import { DynamicGridTabProps } from "@/components/CustomGridTab.vue";
import ReadOnlyFields from "@/components/customTabSubsections/ReadOnlyFields.vue";
import GridSubsection, {
	GridSubsectionProps,
} from "@/components/customTabSubsections/GridSubsection.vue";

interface SubSection {
	name: string;
	label: string;
	icon: string[];
	type: "readonlyFields" | "singleRowGrid" | "grid";
	columnDefs?: (ColDef | ColGroupDef)[];
	gridProps?: GridSubsectionProps;
}
export interface MultiTabProps {
	mode: string;
	tabLabel: string;
	canRefresh: boolean;
	subSections: SubSection[];
	subSectionData: { name: string; data: any[] }[];
	// required for CustomGridTab, would eventually like to refactor this to use the GridSubsection comp instead.
	grid?: DynamicGridTabProps;
}
@Component({
	methods: { capitalise },
	components: {
		GridSubsection,
		ReadOnlyFields,
		TableForm,
		FieldGroup,
		AutoField,
		TextField,
		Container,
		LeftRightFooter,
		Grid,
		Button,
		GridLoadingOverlay,
	},
})
export default class CustomTabContainer extends Vue {
	@Prop() multiTab!: MultiTabProps;
	@Prop() dataUrl!: string;
	subSections!: { [k: string]: any };
	mode!: string;
	subSectionData!: { [k: string]: any };
	loading = false;

	public $refs!: {
		gridEl: Grid;
	};
	created() {
		this.subSections = this.multiTab.subSections;
		this.subSectionData = this.multiTab.subSectionData;
		this.mode = this.multiTab.mode;
	}

	private refreshTab() {
		if (!this.multiTab.canRefresh || !this.dataUrl) {
			return;
		}
		axios
			.get(`${this.dataUrl}/${this.mode}`)
			.then((res) => {
				this.subSections = res.data[this.multiTab.tabLabel].subSections;
				this.subSectionData =
					res.data[this.multiTab.tabLabel].subSectionData;
				this.mode = res.data[this.multiTab.tabLabel].mode;
			})
			.catch((e) => {
				toastErrorMessage(parseErrorMessage(e));
			})
			.finally(() => (this.loading = false));
	}

	onVarianceRefresh() {
		if (
			!this.multiTab.canRefresh ||
			!this.dataUrl ||
			this.mode !== "varianceManagement"
		) {
			return;
		} else {
			this.loading = true;
			axios
				.post(this.dataUrl + "/refresh-variance-data")
				.then(() => {
					this.refreshTab();
				})
				.catch((e) => {
					this.loading = false;
					toastErrorMessage(parseErrorMessage(e));
				});
		}
	}
}
