export type LoginState = {
	errorMessage: any;
	phase: LoginPhase;
};
export const enum LoginPhase {
	CRED_DEFAULT = "LOGIN_CRED_DEFAULT",
	CRED_ERROR = "LOGIN_CRED_ERROR",
	CRED_IN_PROGRESS = "LOGIN_CRED_IN_PROGRESS",
	FORGOT_PASSWORD_DEFAULT = "LOGIN_FORGOT_PASSWORD_DEFAULT",
	FORGOT_PASSWORD_ERROR = "LOGIN_FORGOT_PASSWORD_ERROR",
	FORGOT_PASSWORD_IN_PROGRESS = "LOGIN_FORGOT_PASSWORD_IN_PROGRESS",
	RESET_PASSWORD_DEFAULT = "LOGIN_RESET_PASSWORD_DEFAULT",
	RESET_PASSWORD_ERROR = "LOGIN_RESET_PASSWORD_ERROR",
	RESET_PASSWORD_IN_PROGRESS = "LOGIN_RESET_PASSWORD_IN_PROGRESS",
	RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS",
	RESET_PASSWORD_INVALID_BRAND = "RESET_PASSWORD_INVALID_BRAND",
	SUCCESS = "LOGIN_SUCCESS",
	TWOFA_DEFAULT = "LOGIN_TWOFA_DEFAULT",
	TWOFA_ERROR = "LOGIN_TWOFA_ERROR",
	TWOFA_IN_PROGRESS = "LOGIN_TWOFA_IN_PROGRESS",
}
export const enum LoginMessages {
	ERROR_UNKNOWN = "Unknown Error",
	ERROR_EMPTY = "Username and password cannot be empty.",
	ERROR_EMPTY_USERNAME = "Username cannot be empty.",
	ERROR_EMPTY_PASSWORD = "Password cannot be empty.",
}

// Removing for CHSN-559 as it appears to be unused. Can be removed completely if no side effects.
// export const enum LocalStorageKeys {
// 	ACCESS_TOKEN = "accessToken",
// 	REFRESH_TOKEN = "refreshToken",
// 	STATE = "PortalState",
// 	TOKEN_EXPIRY = "tokenExpiry",
// }

export const enum StatePhase {
	DEFAULT,
	IN_PROGRESS,
	ERROR,
}

export type PreLoginResponse = {
	isBrandCorrect: boolean;
	brand: string;
	financialDocsRead: boolean;
};
