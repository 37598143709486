





















































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import SubHeading from "@/components/SubHeading.vue";
import SelectField from "@/form/SelectField.vue";
import ModalWithSaveAndCancel from "@/components/ModalWithSaveAndCancel.vue";
import Button from "@/form/Button.vue";
import { PaymentProfile } from "@/pages/maintenanceTypes";
import TextField from "@/form/TextField.vue";
import Grid from "@/grid/Grid.vue";
import { ColDef, ColGroupDef } from "ag-grid-community";
import DirectDebitInfoText from "@/components/DirectDebitInfoText.vue";
import RadioGroup from "@/form/RadioGroup.vue";
import { RadioOption } from "@/form/FieldOptions";
import BpayHelpText from "@/components/BpayHelpText.vue";
import { hasPermission } from "@/utils/PermissionUtils";

@Component({
	components: {
		SubHeading,
		SelectField,
		ModalWithSaveAndCancel,
		Button,
		TextField,
		Grid,
		DirectDebitInfoText,
		RadioGroup,
		BpayHelpText,
	},
})
export default class PaymentProfileDetails extends Vue {
	@Prop()
	private readonly paymentProfile!: PaymentProfile;

	@Prop()
	private readonly reportingCentres!: { name: string };

	// Need to separate - needs to be displayed in different areas of the screen
	// Readonly
	private otherPaymentType: RadioOption[] = [
		{
			value: "O",
			text: "EFT or BPAY",
		},
	];

	private debitPaymentType: RadioOption[] = [
		{
			value: "D",
			text: "Direct debit",
		},
	];

	readonly columnDefs: (ColGroupDef | ColDef)[] = [
		{
			headerName: "Reporting centre",
			field: "name",
			width: 100,
			resizable: false,
		},
	];

	private hasPermission(permission: string): boolean {
		return hasPermission(permission);
	}

	edit() {
		this.$emit("edit", { memberId: this.paymentProfile.memberId });
	}
}
