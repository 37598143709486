

































































































































import ContributionResource, {
	ContributionSummary,
} from "@/rest/ContributionResource";
import { Component } from "vue-property-decorator";
import Vue from "vue";
import {
	enableFileAndWarningsStep,
	getNextStep,
	getPreviousStep,
	getSteps,
} from "@/constants/pageConstants";
import Layout from "@/components/Layout.vue";
import ProgressArrow from "@/components/ProgressArrow.vue";
import ErrorList from "@/components/ErrorList.vue";
import PageHeader from "@/components/PageHeader.vue";
import ModalWithSaveAndCancel from "@/components/ModalWithSaveAndCancel.vue";
import Button from "@/form/Button.vue";
import LeftRightFooter from "@/components/LeftRightFooter.vue";
import ContributionEmployeeGridMaintenance from "@/components/ContributionEmployeeGridMaintenance.vue";
import Container from "@/components/Container.vue";
import { downloadAllRows } from "@/utils/DownloadUtils";
import ContributionSummaryComponent from "@/pages/contribution/ContributionSummaryComponent.vue";
import { parseErrorMessage, parseErrorMessageList } from "@/utils/ErrorUtils";
import { AppRoute, AppRouteNextFunction } from "@/router";
import axios from "@/utils/ApiUtils";
import {
	batchCancelURL,
	getAllFundFieldMappingUrl,
	splitBatch,
	submitForFundValidation,
} from "@/constants/apiconstants";
import { toastErrorMessage, toastSuccessMessage } from "@/plugins/toasts";
import { RoutePath } from "@/router/routePath";
import { FundFieldMapping } from "@/models/ContributionRow";
import Overlay from "@/components/Overlay.vue";
import Modal from "@/components/ModalOrPopup.vue";
import { hasPermission } from "@/utils/PermissionUtils";
import RefreshButton from "@/components/RefreshButton.vue";
import { ContributionFileType } from "@/pages/fundTypes";

interface ValidateContentsResponsesBundle {
	batchId: number;
	summary: ContributionSummary | undefined;
	errors: string[];
	fundFieldMappings: [];
}

type ErrorArray = string[];
@Component({
	components: {
		RefreshButton,
		ContributionEmployeeGridMaintenance,
		Layout,
		ProgressArrow,
		ErrorList,
		PageHeader,
		LeftRightFooter,
		ModalWithSaveAndCancel,
		Button,
		Container,
		ContributionSummaryComponent,
		Overlay,
		Modal,
	},
})
export default class ValidateContentPage extends Vue {
	/**
	 * errors is a collection of error messages that hides the entire screen if not empty.
	 * Used for responses.
	 */
	errors: ErrorArray = [];
	private errorCount: null | number = 0;

	private warnings: null | number = 0;
	private readonly title = "Validate contents";
	private canClickCancelBatch = false;
	private parentBatchId = 0;
	private steps = getSteps(this.title);
	private isCancelModalShown = false;
	private contributionSummary: ContributionSummary | null = null;
	private emptyRowData = false;
	private fundFieldMappings: FundFieldMapping[] = [];
	private isDownloadingRows = false;
	private splitBatchWarning = false;
	private isSplittingBatch = false;
	private gridRefreshKey = 1;
	private splitRefreshCtr = 15;

	private gridReady = false;
	private onClickSubmitOrNext(): void {
		if (
			this.contributionSummary?.contributionFileType ===
			ContributionFileType.DB_BYPASS
		) {
			if (!this.contributionSummary.dbBatchValidatedNoErrors) {
				this.onSendToFund();
				return;
			}
		}
		this.onClickNext();
	}

	private onClickNext(): void {
		if (!this.canClickNext) {
			return;
		}
		const routePath = getNextStep(this.steps, this.title).routePath;
		this.$router.push(routePath.replace("/:id", "/" + this.parentBatchId));
	}

	private get canClickBack(): boolean {
		if (
			this.parentBatchId === 0 ||
			this.parentBatchId === null ||
			this.parentBatchId === undefined
		) {
			return false;
		}
		return true;
	}

	private onClickBack(): void {
		if (!this.canClickBack) {
			return;
		}
		const routePath = getPreviousStep(this.steps, this.title).routePath;
		this.$router.push(routePath.replace("/:id", "/" + this.parentBatchId));
	}
	get canClickNext(): boolean {
		return (
			this.parentBatchId !== 0 &&
			this.contributionSummary !== null &&
			this.contributionSummary.employees !== 0 &&
			this.contributionSummary.errors !== null &&
			this.contributionSummary.errors === 0
		);
	}

	get definedBenefitFeaturesEnabled() {
		return this.$store.getters["persistent/definedBenefitFeaturesEnabled"];
	}

	private onClickCancelBatch(): void {
		if (!this.canClickCancelBatch) {
			return;
		}
		this.isCancelModalShown = true;
	}

	get canSendToFund(): boolean {
		return (
			this.contributionSummary?.contributionFileType === "DBBYP" &&
			hasPermission("EDIT_CONTRIBUTIONS")
		);
	}

	private onSendToFund(): void {
		axios
			.post(submitForFundValidation(this.parentBatchId))
			.then((resp) => {
				if (resp.status === 200) {
					toastSuccessMessage(
						"Contribution batch submitted to fund for validation"
					);
					this.refreshButtonClicked(); //Retrieve new contributionSummary - will also trigger auto refresh
				} else if (resp.status === 204) {
					// No changes - proceed to next page
					this.onClickNext();
				} else if (resp.status === 208) {
					toastSuccessMessage(
						"Contribution batch is undergoing fund validation"
					);
					this.refreshButtonClicked();
				}
			})
			.catch((e) => toastErrorMessage(parseErrorMessage(e)));
	}

	private closeCancelBatch(): void {
		this.isCancelModalShown = false;
	}

	private downloadAllRows(): void {
		this.isDownloadingRows = true;
		downloadAllRows(this.parentBatchId, () => {
			this.isDownloadingRows = false;
		});
	}

	private splitBatch() {
		this.splitBatchWarning = false;
		this.isSplittingBatch = true;

		axios
			.post(splitBatch(this.parentBatchId))
			.then((resp) => {
				this.splitBatchRefreshPage();
			})
			.catch((err) => {
				this.splitBatchWarning = true;
				this.isSplittingBatch = false;
				toastErrorMessage(parseErrorMessage(err));
			});
	}

	private splitBatchRefreshPage() {
		ValidateContentPage.getResponseBundle(this.parentBatchId).then((r) => {
			this.setResponse(r);
			this.gridRefreshKey++;
			if (r.summary !== undefined && r.summary.errors !== null) {
				this.isSplittingBatch = false;
				toastSuccessMessage("Batch has been updated.");
			}
			this.splitRefreshCtr = 15;
			const timer = setInterval(() => {
				this.splitRefreshCtr--;
				if (this.splitRefreshCtr <= 0) {
					clearInterval(timer);
				}
			}, 1000);
		});
	}

	async beforeRouteEnter(
		to: AppRoute,
		from: AppRoute,
		next: AppRouteNextFunction<ValidateContentPage>
	) {
		const r = await ValidateContentPage.beforeRouteEnterOrUpdate(
			to,
			from,
			next
		);
		if (r === undefined) {
			return;
		}
		next((vm) => {
			vm.setResponse(r);
		});
	}

	async beforeRouteUpdate(
		to: AppRoute,
		from: AppRoute,
		next: AppRouteNextFunction<ValidateContentPage>
	) {
		const r = await ValidateContentPage.beforeRouteEnterOrUpdate(
			to,
			from,
			next
		);
		if (r === undefined) {
			return;
		}
		// NOTE(Jae): 2020-05-19
		// beforeRouteUpdate modifies the current instance, so we can't just simply
		// reuse the (vm) callback pattern.
		this.setResponse(r);
		next();
	}

	static async beforeRouteEnterOrUpdate(
		to: AppRoute,
		from: AppRoute,
		next: AppRouteNextFunction<ValidateContentPage>
	): Promise<ValidateContentsResponsesBundle | undefined> {
		const id = Number(to.params.id);
		if (!id || isNaN(id)) {
			// Redirect to 404 if ID is zero or NaN
			next({
				name: "Not Found",
			});
			return;
		}
		return this.getResponseBundle(id);
	}

	static async getResponseBundle(batchId: number) {
		const errors: string[] = [];

		// Fire requests simultaneously
		let summaryData: ContributionSummary | undefined;
		let fundFieldMappings: [] = [];

		await Promise.all([
			ContributionResource.getContributionSummary(batchId)
				.then((resp: any) => {
					summaryData = resp.data;
				})
				.catch((e: any) => {
					const respErrors = parseErrorMessageList(e);
					errors.push(...respErrors);
				}),

			axios
				.get(getAllFundFieldMappingUrl(batchId))
				.then((resp) => {
					fundFieldMappings = resp.data.fundFieldMappings;
				})
				.catch((e) => toastErrorMessage(parseErrorMessage(e))),
		]);

		return {
			batchId: batchId,
			summary: summaryData,
			errors: errors,
			fundFieldMappings: fundFieldMappings,
		};
	}

	setResponse(resp: ValidateContentsResponsesBundle) {
		const { batchId, summary, errors } = resp;
		this.parentBatchId = batchId;
		if (summary !== undefined) {
			this.warnings = summary.warnings;
			this.errorCount = summary.errors;
			this.canClickCancelBatch = summary.canCancelBatch;
			this.contributionSummary = summary;
			enableFileAndWarningsStep(
				this.steps,
				summary.withFileLevelErrorWarning
			);
		}

		if (errors && errors.length > 0) {
			this.errors = errors;
		} else {
			// clear errors, fixes navigation problems if you visit with an error then get out of an error state.
			this.errors = [];
		}

		this.fundFieldMappings = resp.fundFieldMappings;
	}

	onGridReady(value: boolean) {
		this.gridReady = value;
	}
	triggerBatchCancel() {
		axios
			.delete(batchCancelURL(this.parentBatchId))
			.then((response) => {
				toastSuccessMessage("Contribution batch marked for deletion");
				// Batch no longer exists, so return to the batch list
				this.$router.push(RoutePath.BatchList);
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
		this.isCancelModalShown = false;
	}

	private refreshSummary() {
		ValidateContentPage.getResponseBundle(this.parentBatchId).then(
			(bundle) => this.setResponse(bundle)
		);
	}

	private refreshButtonClicked() {
		this.refreshSummary();
		this.gridRefreshKey++;
	}
}
