









import Vue from "vue";
import { Component } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import Grid from "@/grid/Grid.vue";
import EmployerSelector from "@/components/EmployerSelector.vue";
import DatepickerField from "@/form/DatepickerField.vue";
import Accordion from "@/components/Accordion.vue";
import { columnDateFormatter } from "@/utils/CommonUtils";
import ReportTable from "@/components/ReportTable.vue";
import { GridAggFunction } from "@/grid/gridConstants";
import PageHeader from "@/components/PageHeader.vue";
import Button from "@/form/Button.vue";

@Component({
	components: {
		PageHeader,
		DatepickerField,
		EmployerSelector,
		Grid,
		Layout,
		Accordion,
		ReportTable,
		Button,
	},
})
export default class ContributionsReportPage extends Vue {
	// see chp.d_ContributionType for cont1 - cont8 description
	private columnDefs = [
		{
			field: "employerName",
			rowGroup: true,
			hide: true,
			resizable: true,
		},
		{
			field: "reportingCentre",
			resizable: true,
			rowGroup: true,
			hide: true,
		},
		{
			headerName: "Employee",
			field: "employeeName",
			resizable: true,
			hide: true,
		},
		{
			headerName: "Fund",
			field: "fund",
			resizable: true,
			minWidth: 160,
		},
		{
			headerName: "Paid",
			headerTooltip: "Paid effective date",
			field: "effectiveDate",
			resizable: true,
			valueFormatter: columnDateFormatter,
			minWidth: 115,
		},
		{
			headerName: "From",
			headerTooltip: "Period start date",
			field: "periodStart",
			valueFormatter: columnDateFormatter,
			resizable: true,
			minWidth: 115,
		},
		{
			headerName: "To",
			headerTooltip: "Period end date",
			field: "periodEnd",
			valueFormatter: columnDateFormatter,
			resizable: true,
			minWidth: 115,
		},
		{
			headerName: "Batch ID",
			headerTooltip: "Batch ID",
			field: "batchId",
			enableRowGroup: true,
			resizable: true,
			minWidth: 115,
		},
		{
			headerName: "Super Guarantee",
			field: "superGuarantee",
			resizable: true,
			aggFunc: GridAggFunction.SUM_OF_CURRENCY,
			minWidth: 115,
		},
		{
			headerName: "Salary Sacrifice",
			field: "salarySacrifice",
			resizable: true,
			aggFunc: GridAggFunction.SUM_OF_CURRENCY,
			minWidth: 115,
		},
		{
			headerName: "Award",
			field: "award",
			resizable: true,
			aggFunc: GridAggFunction.SUM_OF_CURRENCY,
			minWidth: 115,
		},
		{
			headerName: "Personal",
			field: "personal",
			resizable: true,
			aggFunc: GridAggFunction.SUM_OF_CURRENCY,
			minWidth: 115,
		},
		{
			headerName: "Employer Voluntary",
			field: "employerVoluntary",
			resizable: true,
			aggFunc: GridAggFunction.SUM_OF_CURRENCY,
			minWidth: 115,
		},
		{
			headerName: "Spouse",
			field: "spouse",
			resizable: true,
			aggFunc: GridAggFunction.SUM_OF_CURRENCY,
			minWidth: 115,
		},
		{
			headerName: "Child",
			field: "child",
			resizable: true,
			aggFunc: GridAggFunction.SUM_OF_CURRENCY,
			minWidth: 115,
		},
		{
			headerName: "Other 3rd Party",
			field: "otherThirdParty",
			resizable: true,
			aggFunc: GridAggFunction.SUM_OF_CURRENCY,
			minWidth: 115,
		},
		{
			headerName: "Refund Amount",
			field: "refundAmount",
			resizable: true,
			aggFunc: GridAggFunction.SUM_OF_CURRENCY,
			minWidth: 115,
		},
		{
			headerName: "Amount Paid",
			field: "amountPaid",
			resizable: true,
			aggFunc: GridAggFunction.SUM_OF_CURRENCY,
			minWidth: 115,
		},
	];
}
