var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
		_vm.withInfo ||
		_vm.withWarning ||
		_vm.withError ||
		_vm.withRevalidateError ||
		_vm.withRevalidateWarning
	)?_c('div',{staticClass:"GridErrorWarningRenderer__root"},[_c('span',{staticClass:"GridErrorWarningRenderer__icon",on:{"click":_vm.emitIconClick}},[(_vm.withError)?_c('ErrorIcon',{staticClass:"GridErrorWarningRenderer__icon",attrs:{"custom-icon":_vm.customErrorIcon,"title":_vm.errorTitle}}):_vm._e(),(_vm.withWarning && !_vm.withError)?_c('WarningIcon',{staticClass:"GridErrorWarningRenderer__icon",attrs:{"custom-icon":_vm.customWarningIcon,"title":_vm.warningTitle}}):_vm._e(),(_vm.withRevalidateError && !_vm.withError && !_vm.withWarning)?_c('ErrorIcon',{staticClass:"GridErrorWarningRenderer__icon",attrs:{"custom-icon":_vm.customErrorIcon,"title":"Pending fund revalidation","disabled":""}}):_vm._e(),(
				_vm.withRevalidateWarning &&
				!_vm.withError &&
				!_vm.withWarning &&
				!_vm.withRevalidateError
			)?_c('WarningIcon',{staticClass:"GridErrorWarningRenderer__icon",attrs:{"custom-icon":_vm.customWarningIcon,"title":"Pending fund revalidation","disabled":""}}):_vm._e(),(
				!_vm.withWarning &&
				!_vm.withError &&
				!_vm.withRevalidateError &&
				!_vm.withRevalidateWarning &&
				_vm.withInfo
			)?_c('SuccessIcon',{staticClass:"GridErrorWarningRenderer__icon"}):_vm._e()],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }