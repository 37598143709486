












import Vue from "vue";
import { Component } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import MaintenanceGrid from "@/components/MaintenanceGrid.vue";
import { RoutePath } from "@/router/routePath";

@Component({
	components: { Layout, MaintenanceGrid },
})
export default class MaintenancePage extends Vue {
	readonly maintenanceGridItems = [
		{
			name: "Business details",
			description:
				"Maintain Parent, Employer and Reporting Centre details",
			link: RoutePath.CompanyMaintenance,
			buttonName: "Manage business details",
			dataCy: "businessDetailsBtn",
			cardDataCy: "businessDetails",
		},
		{
			name: "Users and Roles",
			description: "Maintain Users and Roles",
			link: RoutePath.UserMaintenance,
			buttonName: "Manage users",
			dataCy: "manageUsersBtn",
			cardDataCy: "manageUsers",
		},
		{
			name: "News items",
			description: "Maintain News items content",
			link: RoutePath.NewsMaintenance,
			buttonName: "Manage news item",
			dataCy: "newsItemBtn",
			cardDataCy: "newsItem",
		},
		{
			name: "Employer registrations",
			description: "Maintain employer registrations",
			link: RoutePath.EmployerRegistrationMaintenance,
			buttonName: "Manage employer registration",
			dataCy: "employerRegistrationsBtn",
			cardDataCy: "employerRegistrations",
		},
	];

	private maintenanceActive = false;

	updated() {
		this.maintenanceActive = typeof this.$refs.rv === "undefined";
	}
	mounted() {
		this.maintenanceActive = typeof this.$refs.rv === "undefined";
	}
}
