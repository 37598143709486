







import Vue from "vue";
import {Component} from 'vue-property-decorator';
import Layout from "@/components/Layout.vue";

@Component({
    components: {Layout}
})
export default class NotFoundPage extends Vue {
}
