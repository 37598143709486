import { EmployerHierarchy } from "@/store/modules/persistent/persistentTypes";
import { EMPLOYER, PARENT, RC } from "@/utils/PermissionUtils";
import { Store } from "vuex";

function isEmployer(entity: string) {
	return entity.startsWith(EMPLOYER);
}

function isParentOrganisation(entity: string) {
	return entity.startsWith(PARENT);
}

function isReportingCentre(entity: string) {
	return entity.startsWith(RC);
}

/**
 * If selected entity (parent organisation, employer or reporting centre)
 * is/contains only one reporting centre, return true, otherwise return false.
 *
 * @param selectedEntities selected entities from persistent state
 * @param hierarchyTreeList a list of hierarchy tree, from persistent state
 */
export function isOnlyOneReportingCentreSelected(
	selectedEntities: string[],
	hierarchyTreeList: EmployerHierarchy[]
): boolean {
	if (selectedEntities.length !== 1 || hierarchyTreeList.length === 0) {
		return false;
	}

	const entity = selectedEntities[0];

	if (isReportingCentre(entity)) {
		return true;
	}

	if (isEmployer(entity)) {
		const employerHierarchyListOfParentOrg = hierarchyTreeList
			.filter((h) => isParentOrganisation(h.id))
			.flatMap((h) => h.children);
		const employerHierarchyListOnTopLevel = hierarchyTreeList.filter((h) =>
			isEmployer(h.id)
		);

		const employerHierarchy = employerHierarchyListOfParentOrg
			.concat(employerHierarchyListOnTopLevel)
			.find((h) => h.id === entity);

		if (!employerHierarchy) {
			throw Error(
				`Selected employer (${entity}) is not in hierarchy tree list.`
			);
		}
		const reportingCentreHierarchies = employerHierarchy.children;
		// NOTE (York): Thanks to typescript duck type, Children type can be assigned to EmployerHierarchy type.
		return isOnlyOneReportingCentreSelected(
			reportingCentreHierarchies.map((e) => e.id),
			reportingCentreHierarchies
		);
	}

	if (isParentOrganisation(entity)) {
		const parentOrgHierarchy = hierarchyTreeList.find(
			(h) => h.id === entity
		);
		if (!parentOrgHierarchy) {
			throw Error(
				`Selected parent organisation (${entity}) is not in hierarchy tree list.`
			);
		}
		const employerHierarchies = parentOrgHierarchy.children;
		return isOnlyOneReportingCentreSelected(
			employerHierarchies.map((e) => e.id),
			employerHierarchies
		);
	}

	throw Error("Unknown selected entity type.");
}
/**
 * If selected entity (parent organisation, employer or reporting centre)
 * is an employer and the employer has no children, return true, otherwise
 * return false.
 *
 * @param selectedEntities selected entities from persistent state
 * @param hierarchyTreeList a list of hierarchy tree, from persistent state
 */
export function isOnlyOneChildlessEmployerSelected(
	selectedEntities: string[],
	hierarchyTreeList: EmployerHierarchy[]
) {
	if (selectedEntities.length !== 1 || hierarchyTreeList.length === 0) {
		return false;
	}
	const entity = selectedEntities[0];

	if (!isEmployer(entity)) {
		return false;
	}
	const employerHierarchyListOnTopLevel = hierarchyTreeList.filter((h) =>
		isEmployer(h.id)
	);
	const employerHierarchyListOfParentOrg = hierarchyTreeList
		.filter((h) => isParentOrganisation(h.id))
		.flatMap((h) => h.children);
	const employerHierarchy = employerHierarchyListOfParentOrg
		.concat(employerHierarchyListOnTopLevel)
		.find((h) => h.id === entity);

	if (!employerHierarchy) {
		throw Error(
			`Selected employer (${entity}) is not in hierarchy tree list.`
		);
	}
	return employerHierarchy.children?.length === 0;
}

function getRcIdFromHierarchyRcIdString(rcIdString: string): number {
	// TODO (York): I don't think it is good design to prefix RC integer id and turn it into string.
	// Should maintain the hierarchy type into separate field in EmployerHierarchy
	const id: number = parseInt(rcIdString.replace(RC, ""));
	if (Number.isNaN(id)) {
		throw new Error("RC id is not an integer number.");
	}
	return id;
}

// NOTE: (York) Should not use the global store otherwise it can't be set to another store in test
export function getRcIdFromSelection(store: Store<any>): number | undefined {
	// Note: (York) I have refactored this method a bit on 30/09/2020.
	// - Add type from persistentType
	// - Return number id and throw error if found RC id string can't be parsed
	// But it still need more refactor
	// TODO: refactor this method? Don't return undefined? Reduce complexity?

	const selected: Array<string> =
		store.getters["persistent/selectedEntities"];
	const options: Array<EmployerHierarchy> =
		store.getters["persistent/employerHierarchy"];

	for (const entity of selected) {
		if (entity.startsWith(RC)) {
			return getRcIdFromHierarchyRcIdString(entity);
		} else if (entity.startsWith(EMPLOYER)) {
			for (const root of options) {
				//employer with no parent
				if (
					root.id.startsWith(EMPLOYER) &&
					root.id === entity &&
					root.children !== null &&
					root.children.length === 1
				) {
					const rc = root.children[0];
					return getRcIdFromHierarchyRcIdString(rc.id);
				}
				//employer with parent org and only one rc
				else if (root.children != null) {
					for (const employer of root.children) {
						if (
							employer.id === entity &&
							employer.children !== null &&
							employer.children.length === 1
						) {
							const rc = employer.children[0];
							return getRcIdFromHierarchyRcIdString(rc.id);
						}
					}
				}
			}
		} else if (entity.startsWith(PARENT)) {
			for (const root of options) {
				if (root.children != null) {
					if (root.id === entity && root.children.length === 1) {
						for (const employer of root.children) {
							if (employer.children === null) {
								continue;
							}

							for (const rc of employer.children) {
								return getRcIdFromHierarchyRcIdString(rc.id);
							}
						}
					}
				}
			}
		}
	}

	return undefined;
}

export function onlyParentOrEmployerSelected(
	selectedEntities: string[],
	isEmployerOnly = false
) {
	if (selectedEntities.length !== 1) {
		return false;
	}
	const entity = selectedEntities[0];

	if (isEmployerOnly) {
		return isEmployer(entity);
	}
	return isEmployer(entity) || isParentOrganisation(entity);
}
