import { GetterTree } from "vuex";

import { RootState } from "@/store/root";

import {
	AllGroupedPermissions,
	EmployerHierarchy,
	PersistentState,
	UserDetails,
} from "./persistentTypes";
import { concat } from "lodash-es";

export const getters: GetterTree<PersistentState, RootState> = {
	accessToken(state): string {
		return state.accessToken;
	},
	isLoggedIn(state): boolean {
		if (!state) {
			return false;
		}
		if (!state.accessToken) {
			return false;
		}
		if (!state.tokenExpiry) {
			return false;
		}
		// NOTE(Jae): 2020-05-12
		// We used to "return false" if we were over the expiry date
		// but we no longer do that so that we'll make a request to the server
		// still, which should trigger a 401 response that makes us refresh the token.
		return true;
	},
	refreshToken(state): string {
		return state.refreshToken;
	},
	currentUserDetails(state): UserDetails {
		return {
			username: state.username,
			name: state.name,
			sponsorPermissions: state.sponsorPermissions,
			parentOrganisationPermissions: state.parentOrganisationPermissions,
			employerPermissions: state.employerPermissions,
			reportingCentrePermissions: state.reportingCentrePermissions,
		};
	},
	//To be used to check if this permission exist(doesn't care about entity)
	allPermission(state): any {
		if (
			state.sponsorPermissions &&
			state.parentOrganisationPermissions &&
			state.reportingCentrePermissions &&
			state.employerPermissions &&
			state.domainPermissions
		) {
			return concat(state.sponsorPermissions)
				.concat(state.parentOrganisationPermissions)
				.concat(state.reportingCentrePermissions)
				.concat(state.employerPermissions)
				.concat(state.domainPermissions);
		}
		return null;
	},
	allGroupedPermissions(state): AllGroupedPermissions {
		const {
			sponsorPermissions,
			parentOrganisationPermissions,
			employerPermissions,
			reportingCentrePermissions,
			domainPermissions,
		} = state;
		return {
			sponsorPermissions,
			parentOrganisationPermissions,
			employerPermissions,
			reportingCentrePermissions,
			domainPermissions,
		};
	},
	sponsorPermissions(state): any {
		return state.sponsorPermissions;
	},
	reportingCentrePermissions(state): any {
		return state.reportingCentrePermissions;
	},
	employerPermissions(state): any {
		return state.employerPermissions;
	},
	parentOrganisationPermissions(state): any {
		return state.parentOrganisationPermissions;
	},
	employerHierarchy(state): Array<EmployerHierarchy> {
		return state.employerHierarchy;
	},
	selectedEntities(state): Array<string> {
		return state.selectedEntities;
	},
	definedBenefitEntities(state): Array<string> {
		return state.definedBenefitEntities;
	},
	selectedEntitiesInsight(state): Array<string> {
		return state.selectedEntitiesInsight;
	},
	brandId(state): number | null {
		return state.brandId;
	},
	iressOperations(state): boolean {
		return state.iressOperations;
	},
	domainPermissions(state): any {
		return state.domainPermissions;
	},
	definedBenefitFeaturesEnabled(state): boolean | null {
		return state.definedBenefitFeaturesEnabled;
	},
	checkForNewReleaseForUser(state): boolean {
		return state.checkForNewReleaseForUser;
	},
};
