















import Vue from "vue";
import { Component } from "vue-property-decorator";
import axios from "@/utils/ApiUtils";
import { getAlertsOverviewURL } from "@/constants/apiconstants";
import { toastErrorMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import Overlay from "@/components/Overlay.vue";
import { AlertCount } from "@/models/AlertRow";

@Component({
	components: {
		Overlay,
	},
})
export default class AlertsOverview extends Vue {
	private loading = false;
	private alertCountRows: AlertCount[] = [];

	orderByValueDesc(a: AlertCount, b: AlertCount) {
		return b.count - a.count;
	}

	mounted() {
		this.loading = true;
		axios
			.get<AlertCount[]>(getAlertsOverviewURL())
			.then((response) => {
				this.alertCountRows = response.data.sort(this.orderByValueDesc);
				this.loading = false;
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
				this.loading = false;
			});
	}
}
