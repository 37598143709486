
































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import flatPickr, { flatPickrOptions } from "vue-flatpickr-component";
import FieldHolder from "@/form/FieldHolder.vue";
import ButtonBehaviour from "@/form/ButtonBehaviour.vue";
import { CommonField } from "@/form/CommonField";
import { validate } from "vee-validate";
export type DatepickerConfig = {
	altInput: boolean;
	// Pretty display format
	altFormat: string;
	// Internal value format
	dateFormat: string;
	allowInput: boolean;
	mode?: string;
};
@Component({
	components: {
		flatPickr,
		FieldHolder,
		ButtonBehaviour,
	},
})
export default class DatepickerField extends Vue implements CommonField {
	private static uniqueId = 0;

	private static acurityDateNoValueSet = "0001-01-01";

	/**
	 * id must be globally unique in a HTML document, we guarantee this by making each render
	 * of this component have unique ID number appended to the end to avoid clashes.
	 *
	 * We need ids mostly to connect <label> and <input> elements together for accessibility.
	 */
	readonly id = "DatepickerField_" + ++DatepickerField.uniqueId;

	private readonly screenReaderOnlyText = "Open Calendar";

	/**
	 * isAcurityDateValue will be true if the "value" of this component was
	 * ever set to {@link acurityDateNoValueSet}
	 *
	 * If we can guarantee that all dates will follow "2010-12-30" format, then
	 * this should probably just be "true" for all cases.
	 */
	isAcurityDateValue = false;

	@Prop([String]) readonly name!: string;

	@Prop([String]) readonly label!: string;

	@Prop([String]) readonly value!: string;

	@Prop([String, Object]) readonly rules!: string | object;

	@Prop(Boolean) readonly readonly!: boolean;

	@Prop(Boolean) readonly disabled!: boolean;

	@Prop({ type: Boolean, default: false }) readonly range!: boolean;

	/**
	 * Add additional custom error messages to this field.
	 */
	@Prop([Array]) readonly errors!: string[];

	/**
	 * Whether errors are shown externally.
	 */
	@Prop(Boolean) readonly errorsShownExternally!: boolean;

	$refs!: {
		datePicker: flatPickr;
	};
	private updatedRules: string | object | null = null;

	private readonly config: flatPickrOptions = {
		altInput: true,
		// Pretty display format
		altFormat: "d/m/Y",
		// Internal value format
		dateFormat: "Y-m-d",
		allowInput: true,
		mode: this.range ? "range" : "single",
	};

	mounted() {
		if (this.$refs.datePicker) {
			if (this.range) {
				this.$refs.datePicker.fp._input.addEventListener(
					"input",
					async (event) => {
						const value = this.$refs.datePicker.fp._input.value;
						// if invalid add dateFormat to rules for vee-validator error notification
						const validationResult = await validate(
							value,
							"dateRangeFormat"
						);
						if (validationResult.valid) {
							this.$refs.datePicker.fp.setDate(
								value,
								true,
								this.config.altFormat
							);
						} else {
							this.updatedRules = "dateRangeFormat";
						}
					},
					true
				);
			} else {
				this.$refs.datePicker.fp._input.addEventListener(
					"input",
					async (event) => {
						const value = this.$refs.datePicker.fp._input.value;
						const parsedDate = this.$refs.datePicker.fp.parseDate(
							value,
							this.config.altFormat
						);

						// Note (Raghu) - validate date format to see if manually entered date is in DD/MM/YYYY format.
						// If date is valid, transpile date to YYYY/MM/DD format for backend
						// if invalid add dateFormat to rules for vee-validator error notification
						const validationResult = await validate(
							value,
							"dateFormat"
						);
						if (validationResult.valid) {
							this.updatedRules =
								this.replaceRequiredWithDateRequired;
							if (parsedDate === undefined) {
								this.$refs.datePicker.fp.clear();
							} else {
								const formattedDate =
									this.$refs.datePicker.fp.formatDate(
										parsedDate,
										this.config.altFormat ?? "d/m/Y"
									);
								if (value === formattedDate) {
									this.$refs.datePicker.fp.setDate(
										value,
										true,
										this.config.altFormat
									);
								}
							}
						} else {
							this.updatedRules =
								this.updatedFormatWithDateFormatRule;
						}
					},
					true
				);
			}
		}
	}

	// Note (Raghu) - update local rules if rules change dynamically &
	// Initialize updatedRoles to rules passed into the date component
	// inject dateFormat validation as need be to display validation errors on the UI
	@Watch("rules", { immediate: true })
	onRulesChange() {
		this.updatedRules = this.replaceRequiredWithDateRequired;
	}

	get replaceRequiredWithDateRequired() {
		if (typeof this.rules === "object") {
			return this.rules;
		}
		return this.rules
			? this.rules.replace(/required/gi, "dateRequired")
			: this.rules;
	}

	get updatedFormatWithDateFormatRule() {
		if (typeof this.rules === "object") {
			(this.rules as any)["dateFormat"] = true;
		}
		return this.replaceRequiredWithDateRequired
			? this.replaceRequiredWithDateRequired + "|dateFormat"
			: "dateFormat";
	}

	onClickOpen() {
		this.$refs.datePicker.fp.open();
	}

	onDateChange(newValue: any): void {
		if (newValue === null) {
			if (this.isAcurityDateValue) {
				newValue = DatepickerField.acurityDateNoValueSet;
			}
		}
		if (this.range) {
			if (newValue === "") {
				this.updatedRules = this.rules;
			}
			if (newValue.search("to") === -1) {
				return;
			}
		}

		// Remove dateFormat rule if date is chosen from the date picker
		this.updatedRules = this.rules;
		this.$emit("input", newValue);
	}

	@Watch("value")
	onValueChanged(newValue: string, oldValue: string) {
		if (newValue === DatepickerField.acurityDateNoValueSet) {
			this.isAcurityDateValue = true;
		}
	}

	get computedValue(): string | null {
		if (this.value === DatepickerField.acurityDateNoValueSet) {
			// Handle non-set date
			return null;
		}
		return this.value;
	}
}
