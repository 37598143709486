



























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class WarningIcon extends Vue {
	@Prop({ type: String, default: "exclamation-triangle" })
	customIcon!: string;

	@Prop({ type: String, default: "Warning" })
	title!: string;

	@Prop({ type: Boolean, default: false })
	disabled!: boolean;
}
