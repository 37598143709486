import Vue from "vue";
import {
	extend,
	localize,
	ValidationObserver,
	ValidationProvider,
} from "vee-validate";
import {
	digits,
	email,
	max,
	min,
	numeric,
	regex,
	required,
	min_value,
	max_value,
	length,
	required_if,
} from "vee-validate/dist/rules";
import en from "vee-validate/dist/locale/en.json";
import {
	isValidBsb,
	isValidTfn,
	safeText,
	url,
	isValidAbn,
	isValidUserEmail,
	isValidUserMobileNo,
	isValidCharacter,
	isValidStandardText,
} from "./validators/rule/rules";
import { isEmpty, mapValues, trim } from "lodash-es";
import moment from "moment";

// Install rules
extend("required", required);
extend("min", min);
extend("max", max);
extend("email", email);
extend("digits", digits);
extend("numeric", numeric);
extend("regex", regex);
extend("min_value", min_value);
extend("max_value", max_value);
extend("length", length);
extend("required_if", required_if);

// Reassure the params is a record when names are provided via params.
type VeeValidateExtendNamedParams = Record<string, any>;

//Custom rules
extend("customUrl", {
	computesRequired: false,
	validate: (value) => url(value),
});

extend("customSafeText", {
	computesRequired: false,
	validate: (value) => safeText(value),
});

extend("bsb", {
	validate: isValidBsb,
});

extend("abn", {
	validate: isValidAbn,
});

extend("checked", {
	validate: (value) => value === true,
});

extend("validateAllowedCharacters", {
	validate: isValidCharacter,
});

// Dont allow YYYY-MM-DD while keying in date data manually
extend("dateFormat", {
	computesRequired: true,
	validate: function (value) {
		return (
			isEmpty(value) ||
			moment(value, ["DD/MM/YYYY", "DD-MM-YYYY"], true).isValid()
		);
	},
});

extend("dateRangeFormat", {
	computesRequired: true,
	validate: function (value) {
		if (value === "") {
			return true;
		}
		if (value.search("to") === -1) {
			return false;
		}
		const dates = value.split("to");
		return (
			moment(dates[0].trim(), ["DD/MM/YYYY"], true).isValid() &&
			moment(dates[1].trim(), ["DD/MM/YYYY"], true).isValid() &&
			moment(dates[1].trim(), ["DD/MM/YYYY"], true).isAfter(
				moment(dates[0].trim(), ["DD/MM/YYYY"], true)
			)
		);
	},
});

// Note (Raghu) Update wording of required date fields
// this is a custom required validation for dates to get around null dates or when the date format is not valid but not null
// this gets around cases when the date field is defined but there is junk data in it as we are validating for expected formats by default
// validating to YYYY-MM-DD in required date validation because the backed works with YYYY-MM-DD format and date values are in YYYY-MM-DD
// while editing existing date data.
extend("dateRequired", {
	computesRequired: true,
	validate: (value) => {
		return (
			!isEmpty(value) &&
			moment(
				value,
				["DD/MM/YYYY", "DD-MM-YYYY", "YYYY-MM-DD"],
				true
			).isValid()
		);
	},
});

function validateDates(
	value: string,
	params: { targetDate: string; method: any }
) {
	if (typeof params.targetDate !== "string") {
		// We have nothing to compare with.
		return true;
	}

	const validMethods = [
		"before",
		"after",
		"same",
		"sameOrBefore",
		"sameOrAfter",
	] as const;
	if (!validMethods.includes(params.method)) {
		throw new Error(
			`Invalid method provided for the datesCompare rule. Available methods are: ${validMethods.join(
				", "
			)}`
		);
	}
	const method: typeof validMethods[number] = params.method;
	const dateFormat = "YYYY-MM-DD";
	const originDate = moment(value, dateFormat);
	const targetDate =
		params.targetDate === "currentDate"
			? moment(moment().format(dateFormat), dateFormat)
			: moment(params.targetDate, dateFormat);

	if (!originDate.isValid() || !targetDate.isValid()) {
		// We have nothing to compare with.
		return true;
	}

	switch (method) {
		case "before":
			return originDate.isBefore(targetDate);
		case "after":
			return originDate.isAfter(targetDate);
		case "same":
			return originDate.isSame(targetDate);
		case "sameOrBefore":
			return originDate.isSameOrBefore(targetDate);
		case "sameOrAfter":
			return originDate.isSameOrAfter(targetDate);
	}
}

function generateDatesCompareMessage(method: string, targetFieldName: string) {
	let compareMessage = method;
	switch (method) {
		case "sameOrBefore":
			compareMessage = "same as or before";
			break;
		case "sameOrAfter":
			compareMessage = "same as or after";
			break;
		case "same":
			compareMessage = "same as";
	}

	return `{_field_} must be ${compareMessage} ${targetFieldName}.`;
}

/**
 * Can't use `params?: RuleParamSchema[]` property because it doesn't support infinite rule arguments. If
 * specified params are less than passed in params, then all rest params are stored in the last params as
 * an array. In such case, cross field parameter, i.e, `@targetField`, is not supported.
 *
 * Can't put error message into separate method because there could be multiple targets. There is no way
 * separative message function can find out which target fails. So validation and error message generation
 * are in validate method together.
 */
extend("datesCompare", {
	validate(value, params: VeeValidateExtendNamedParams) {
		if (!Array.isArray(params) || params.length % 3 !== 0) {
			throw Error(
				`Invalid datesCompare rule usage. An example is 
				rule="dateCompare:@targetField1,after,fieldLabel1,@targetField2,before,fieldLabel2"`
			);
		}

		for (let i = 0; i < params.length; i = i + 3) {
			const targetDate = params[i];
			const method = params[i + 1];
			if (!validateDates(value, { targetDate, method })) {
				let targetFieldName = params[i + 2];
				if (targetFieldName !== "current date") {
					targetFieldName = `"${targetFieldName}"`;
				}
				return generateDatesCompareMessage(method, targetFieldName);
			}
		}
		return true;
	},
});

extend("tfn", {
	validate: isValidTfn,
});

extend("sameString", {
	params: ["target", "label"],
	validate(value, { target, label }: VeeValidateExtendNamedParams) {
		if (value != target) {
			return `{_field_} must match ${label}.`;
		}
		return true;
	},
});

extend("userMobile", {
	validate: isValidUserMobileNo,
});

extend("userEmail", {
	validate: isValidUserEmail,
});

extend("bothOrNothing", {
	params: ["target"],
	validate(value, { target }: VeeValidateExtendNamedParams) {
		return Boolean((value && target) || (!value && !target));
	},
});

extend("standardText", {
	validate: isValidStandardText,
});

extend("alphaNumericSpaces", {
	validate: (value) => /^([0-9a-zA-Z\s])*$/.test(value),
});
extend("mobileLeadingZero", {
	validate: (value) => {
		if (value && trim(value).length == 10 && !value.startsWith("0")) {
			return false;
		}
		return true;
	},
});
extend("containsInvalidText", {
	params: ["text", "ignoreCase"],
	validate(value, params: VeeValidateExtendNamedParams) {
		if (!value) {
			return true;
		}
		const text = params.text;
		const ignoreCase = params.ignoreCase === "true";
		return ignoreCase
			? value.toLowerCase().indexOf(text.toLowerCase()) === -1
			: value.indexOf(text) === -1;
	},
});
extend("byFunction", {
	computesRequired: true,
	validate: (value, params: VeeValidateExtendNamedParams) =>
		(params as any)[0](value),
});

extend("boolean", {
	validate: (value) => {
		return value === "true" || value === "false";
	},
});

extend("decimal", {
	params: ["numberOfDecimal"],
	validate(value, params: VeeValidateExtendNamedParams) {
		const decIndex = value.indexOf(".");
		const decimalNumbers =
			decIndex === -1 ? 0 : value.length - decIndex - 1;
		return decimalNumbers <= params.numberOfDecimal;
	},
});

// Should separte all custom rules message into locale files if support more than en locale
const customRulesMessagesEn = {
	...mapValues(en.messages, (message) => `${message}.`), // add full stop to all en validation messages
	required(fieldName: string) {
		if (fieldName === "{field}") {
			return "This field is required.";
		}
		return `The ${fieldName} field is required.`;
	},
	customUrl: "Invalid URL.",
	customSafeText:
		"Field contains invalid character. The following special characters are allowed: , . ! ? $ % & * ' \"() -",
	bsb: "The {_field_} field contains invalid BSB. Valid BSB should be either XXXXXX or XXX-XXX and contains number only.",
	tfn: "The {_field_} field contains invalid tax file number.",
	dateFormat: "{_field_} failed date format validation, DD/MM/YYYY expected.",
	dateRangeFormat:
		"{_field_} failed date format validation, DD/MM/YYYY to DD/MM/YYYY expected, start date should be before end date",
	dateRequired:
		"{_field_} failed date format validation, DD/MM/YYYY expected and is required.",
	abn: "{_field_} must contain 11 digits; spaces and hyphens are accepted.",
	userEmail: "{_field_} contains invalid email address",
	userMobile:
		"{_field_} contains invalid mobile number. Only Australian mobile number is accepted.",
	bothOrNothing(
		fieldName: string,
		placeholders: VeeValidateExtendNamedParams | undefined
	) {
		if (placeholders === undefined) {
			// The whole rule is meaningless if no additional params are provided.
			return "";
		}
		return `${fieldName} should only be entered if ${placeholders.target} is entered.`;
	},
	standardText:
		"{_field_} contains invalid character. Special characters accepted: . , ? ! ( ) { } : ; ' | - _ = \\ / @ # $ % * &",
	alphaNumericSpaces:
		"{_field_} must only contain alphabetic characters, numbers as well as spaces.",
	mobileLeadingZero:
		"If {_field_} has a length of 10 digits, it must commence with a zero. Please enter a valid mobile number.",
	containsInvalidText(
		field: string,
		params: VeeValidateExtendNamedParams | undefined
	) {
		if (params) {
			return `${field} field contains invalid text "${params.text}"`;
		}
		return `${field} field contains invalid text`;
	},
	boolean: "{_field_} must be true or false.",
	max(field: string, params: VeeValidateExtendNamedParams | undefined) {
		if (params) {
			return `The ${field} field must not be greater than ${params.length} characters.`;
		}
		return `The ${field} field must not be greater than NaN characters.`;
	},
	validateAllowedCharacters: "{_field_} contains invalid character.",
	decimal(field: string, params: VeeValidateExtendNamedParams | undefined) {
		if (params) {
			return `The ${field} field cannot have more than ${params.numberOfDecimal} decimal places.`;
		}
		return `The ${field} field must be a whole number.`;
	},
};

// Install messages
localize({
	en: {
		...en,
		messages: customRulesMessagesEn,
	},
});
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
