
































































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import Button from "@/form/Button.vue";
import Container from "@/components/Container.vue";

export interface ModalProps {
	title: string;
	size: "md" | "sm" | "fill" | "lg";
}

@Component({
	components: {
		Button,
		Container,
	},
})
export default class Modal extends Vue implements ModalProps {
	private static bodyClass = "Modal--is-body-open";

	private static emitClose = "close";

	/**
	 * modalsMounted tracks the modals we have open so that we can
	 * nest modals.
	 */
	private static modalsMounted = 0;

	@Prop(String) title!: string;

	@Prop({ type: String, default: "sm" }) size!: "md" | "sm" | "fill" | "lg";

	@Prop({ type: Boolean, default: false }) fullHeight!: boolean;

	@Prop({ type: String, default: "Close" }) closeButtonName!: string;

	@Prop({ type: Boolean, default: false }) closeButtonDisabled!: boolean;

	@Prop({ type: Boolean, default: false }) allowBodyOverflow!: boolean;

	mounted(): void {
		if (Modal.modalsMounted === 0) {
			document.body.classList.add(Modal.bodyClass);
		}
		Modal.modalsMounted++;
		document.body.addEventListener("keydown", this.onKeyDown, {
			capture: true,
			passive: false,
		});
	}

	beforeDestroy(): void {
		Modal.modalsMounted--;
		if (Modal.modalsMounted === 0) {
			document.body.classList.remove(Modal.bodyClass);
		}
		document.body.removeEventListener("keydown", this.onKeyDown);
	}

	/**
	 * errors will return developer mistakes caught at runtime.
	 * These will only appear in development mode.
	 */
	get errors(): string {
		if (process.env.NODE_ENV !== "development") {
			return "";
		}
		if (!this.$listeners || !this.$listeners[Modal.emitClose]) {
			return "Developer Error: You should bind a @close event to your Modal so that keyboard accessibility cases, like pressing ESCAPE works.";
		}
		return "";
	}

	private onKeyDown(e: KeyboardEvent): void {
		switch (e.key) {
			case "Escape": {
				this.triggerClose();
				return;
			}
		}
	}

	private triggerClose(): void {
		if (!this.closeButtonDisabled) {
			this.$emit(Modal.emitClose);
		}
	}
}
