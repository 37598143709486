/**
 * setInputFilter allows us to provide a filter to only allow certain characters to be typed.
 * If we decide we want to use this on other components, we should pull it out into a utility function.
 *
 * Source: https://stackoverflow.com/questions/469357/html-text-input-allow-only-numeric-input
 */
export function setInputFilter(
	textbox: Element,
	inputFilter: (value: string) => boolean
): void {
	[
		"input",
		"keydown",
		"keyup",
		"mousedown",
		"mouseup",
		"select",
		"contextmenu",
		"drop",
	].forEach(function (event) {
		textbox.addEventListener(event, function (
			this: (HTMLInputElement | HTMLTextAreaElement) & {
				oldValue: string;
				oldSelectionStart: number | null;
				oldSelectionEnd: number | null;
			}
		) {
			if (inputFilter(this.value)) {
				this.oldValue = this.value;
				this.oldSelectionStart = this.selectionStart;
				this.oldSelectionEnd = this.selectionEnd;
			} else if (Object.prototype.hasOwnProperty.call(this, "oldValue")) {
				this.value = this.oldValue;
				if (
					this.oldSelectionStart !== null &&
					this.oldSelectionEnd !== null
				) {
					this.setSelectionRange(
						this.oldSelectionStart,
						this.oldSelectionEnd
					);
				}
			} else {
				this.value = "";
			}
		});
	});
}
