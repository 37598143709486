

















import { Component, Prop, Vue } from "vue-property-decorator";
import { columnDateTimeFormatter } from "../utils/CommonUtils";
import Grid from "@/grid/Grid.vue";
import { BatchAuditResponse } from "@/pages/batchType";

@Component({
	components: { Grid: Grid },
})
export default class BatchAuditTable extends Vue {
	@Prop() private batchAuditResponse!: BatchAuditResponse;
	@Prop({ type: String, default: "500px" }) private gridHeight!: string;
	private rowData: BatchAuditResponse[] = [];
	public $refs!: {
		gridEl: Grid;
	};
	private onGridReady() {
		this.rowData = JSON.parse(
			JSON.stringify(this.batchAuditResponse.batchAudit)
		);
		if (this.rowData && this.rowData.length === 0) {
			this.$refs.gridEl.hideOverlay();
			this.$refs.gridEl.showNoRowsOverlay();
		}
	}

	private columnDefs = [
		{
			headerName: "Action performed by",
			field: "actionPerformedBy",
			resizable: true,
			width: 150,
			flex: 1,
		},
		{
			headerName: "Action",
			field: "actionDescription",
			resizable: true,
			width: 300,
			flex: 2,
		},
		{
			headerName: "Action date",
			field: "dateOfAction",
			valueFormatter: columnDateTimeFormatter,
			sortingOrder: ["desc"],
			resizable: true,
			width: 150,
			flex: 1,
		},
	];
}
