var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.style,attrs:{"data-cy":("employer-selector" + (_vm.insight ? 'insight' : ''))}},[_c('treeselect',{attrs:{"value":!_vm.insight
				? _vm.multiple
					? _vm.selectedEntities
					: _vm.selectedEntity
				: _vm.multiple
				? _vm.selectedEntitiesInsight
				: _vm.selectedEntityInsight,"options":_vm.options,"multiple":_vm.multiple,"flat":_vm.flat,"value-consists-of":_vm.valueConsistOf,"disabled":_vm.disable,"clearable":_vm.clearable,"normalizer":_vm.normaliser,"placeholder":"Select Reporting Centres"},on:{"input":_vm.updateValue},scopedSlots:_vm._u([{key:"value-label",fn:function(ref){
				var node = ref.node;
return _c('div',{},[_c('span',{staticClass:"mr-2",attrs:{"title":_vm.getHelpText(node)}},[(_vm.getIcon(node) === 'employer-icon')?_c('font-awesome-icon',{attrs:{"icon":['fal', 'landmark'],"data-cy":"employerNode"}}):_vm._e(),(_vm.getIcon(node) === 'parent-icon')?_c('font-awesome-icon',{attrs:{"icon":['fal', 'building']}}):_vm._e(),(_vm.getIcon(node) === 'reporting-icon')?_c('font-awesome-icon',{attrs:{"icon":['fal', 'users']}}):_vm._e()],1),_c('span',[_vm._v(_vm._s(node.label))])])}},{key:"option-label",fn:function(ref){
				var node = ref.node;
return _c('div',{attrs:{"data-cy":"employerNodeDiv"}},[_c('span',{staticClass:"option-label",attrs:{"data-cy":"employerNodeSpan","title":_vm.getHelpText(node)}},[(_vm.getIcon(node) === 'employer-icon')?_c('font-awesome-icon',{attrs:{"icon":['fal', 'landmark']}}):_vm._e(),(_vm.getIcon(node) === 'parent-icon')?_c('font-awesome-icon',{attrs:{"icon":['fal', 'building']}}):_vm._e(),(_vm.getIcon(node) === 'reporting-icon')?_c('font-awesome-icon',{attrs:{"icon":['fal', 'users']}}):_vm._e(),_vm._v(" "+_vm._s(node.label)+" ")],1)])}}])}),_c('span',{staticClass:"error-message",attrs:{"data-cy":("employer-selector" + (_vm.insight ? 'insight' : '') + "-error")}},[_vm._v(_vm._s(_vm.error))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }