






































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Modal from "@/components/Modal.vue";
import {
	columnCurrencyFormatter,
	columnDateFormatter,
	columnDateTimeFormatter,
} from "@/utils/CommonUtils";
import Grid from "@/grid/Grid.vue";
import { RadioOption } from "@/form/FieldOptions";
import RadioGroup from "@/form/RadioGroup.vue";
import Button from "@/form/Button.vue";
import LeftRightFooter from "@/components/LeftRightFooter.vue";
import { Batch } from "@/models/FinalBatchReviewRecord";

@Component({
	components: {
		Modal,
		Grid,
		RadioGroup,
		Button,
		LeftRightFooter,
	},
})
export default class ReviewAuthorisedUnfundedBatches extends Vue {
	@Prop({ default: [] })
	private authorisedUnfundedBatches: Batch[] | undefined;

	@Prop({ default: false })
	private allowCancelAuthorisedUnfundedBatches: boolean | undefined;

	private readonly SAVE_ONLY = "S";
	private readonly CANCEL_BATCHES = "C";
	private readonly KEEP_BATCHES = "K";

	private formOption = this.SAVE_ONLY;

	private columnDefs = [
		{
			headerName: "Batch ID",
			field: "batchId",
			resizable: true,
		},
		{
			headerName: "Pay period start date",
			field: "payPeriodStartDate",
			resizable: true,
			valueFormatter: columnDateFormatter,
		},
		{
			headerName: "Pay period end date",
			field: "payPeriodEndDate",
			resizable: true,
			valueFormatter: columnDateFormatter,
		},
		{
			headerName: "Total amount",
			field: "totalAmount",
			resizable: true,
			valueFormatter: columnCurrencyFormatter,
		},
		{
			headerName: "Date created",
			field: "dateCreated",
			resizable: true,
			valueFormatter: columnDateFormatter,
		},
		{
			headerName: "Date authorised",
			field: "dateAuthorised",
			resizable: true,
			valueFormatter: columnDateTimeFormatter,
			width: 240,
		},
		{
			headerName: "Order of payment",
			field: "orderOfPayment",
			resizable: true,
		},
	];

	private allOptions: RadioOption[] = [
		{
			value: this.SAVE_ONLY,
			text: "Please save this new contribution batch as ‘In progress’. I wish to review each unpaid contribution batch to decide whether to cancel or keep it, before authorising additional batches.",
		},
		{
			value: this.CANCEL_BATCHES,
			text: "Please cancel all the authorised batch(es) listed above as they are no longer required and authorise this new batch.",
		},
		{
			value: this.KEEP_BATCHES,
			text: "Keep all authorised batch(es) listed above and continue to authorise this new batch. I understand employer payment(s) will be allocated to the older authorised batch(es), in the payment order listed above.",
		},
	];

	onClickCancel() {
		this.$emit("close");
	}

	onClickAction() {
		if (this.formOption === this.SAVE_ONLY) {
			this.$emit("save");
			return;
		}
		this.$emit("approve", {
			cancelBatches: this.formOption === this.CANCEL_BATCHES,
		});
	}

	get options() {
		if (this.allowCancelAuthorisedUnfundedBatches) {
			return this.allOptions;
		}
		return this.allOptions.filter((o) => o.value !== this.CANCEL_BATCHES);
	}

	get buttonActionName() {
		if (this.formOption === this.SAVE_ONLY) {
			return "Save this batch and review the above batches before authorising";
		} else if (this.formOption === this.CANCEL_BATCHES) {
			return "Cancel the above batches and authorise this batch";
		} else {
			return "Keep the above batches and authorise this batch";
		}
	}
}
