var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":(_vm.modeText + " Employee"),"icon":['fal', 'user-plus']}}),_c('b-breadcrumb',{staticClass:"portal-breadcrumb"},[_c('b-breadcrumb-item',{on:{"click":function($event){return _vm.cancel()}}},[_vm._v("Employee")]),_c('b-breadcrumb-item',{attrs:{"active":_vm.mode === 'add'}},[_vm._v(_vm._s(_vm.modeText)+" Employee")]),(_vm.mode === 'edit')?_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v(_vm._s(_vm.employeeNameAndPayroll))]):_vm._e()],1),_c('EmployerSelector',{attrs:{"select-level":_vm.pageContext,"disable":true}}),_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('ValidationObserver',{ref:"employeeForm",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ValidationObserverNotifier',{attrs:{"errors":errors},on:{"validation-observer-errors-changed":_vm.onValidationObserverErrorsChanged}}),(!_vm.loadingEmployeeDetails)?_c('EmployeeForm',{attrs:{"fieldMap":_vm.editFieldMap,"errors-shown-externally":false,"disabled":_vm.mode === 'view'},model:{value:(_vm.recordFormData.employee),callback:function ($$v) {_vm.$set(_vm.recordFormData, "employee", $$v)},expression:"recordFormData.employee"}},[_c('div',{staticClass:"mt-1"},[_c('div',{staticClass:"py-2"},[_c('b-tabs',{staticClass:"portal-tab-stacked",attrs:{"pills":"","card":"","vertical":"","no-body":""},model:{value:(_vm.activeTabIndex),callback:function ($$v) {_vm.activeTabIndex=$$v},expression:"activeTabIndex"}},_vm._l((_vm.formSchema),function(stage,idx){return _c('b-tab',{key:idx,attrs:{"exact-active-class":"active","title-item-class":_vm.showCustomTab(
											idx,
											_vm.dbRegoEmployeeDefinedBenefitFields.length >
												0,
											_vm.additionalEmployeeDefinedBenefitFields.length >
												0
										)},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.capitaliseInDom(stage.header))+" "),(
												_vm.numberOfErrorsForEachTab[
													idx
												]
											)?_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v(_vm._s(_vm.numberOfErrorsForEachTab[ idx ])+" ")]):_vm._e()]},proxy:true}],null,true)},[_c('ValidationObserver',{ref:idx,refInFor:true},[_c('div',{staticClass:"portal-tab-stacked__content"},[(!_vm.loadingRcDetails)?_c('div',[_vm._l((stage.subSections),function(subSection,key){return _c('FieldGroup',{key:key,staticClass:"add-employee-stages",attrs:{"icon":subSection.icon,"label":_vm.capitaliseInDom(
															subSection.name
														)}},[(
															stage.header ===
															'Address'
														)?_c('address-form',{attrs:{"values":_vm.addressDetailsOfEmployee},on:{"update":_vm.updateAddressDetailsOfEmployee}},[_c('SelectField',{attrs:{"name":"addressType"}}),_c('SelectField',{attrs:{"name":"countryCode","searchable":true}}),_c('TextField',{attrs:{"name":"addressLine1"}}),_c('TextField',{attrs:{"name":"addressLine2"}}),_c('TextField',{attrs:{"name":"addressLine3"}}),_c('TextField',{attrs:{"name":"addressLine4"}}),_c('TextField',{attrs:{"name":"locality"}}),_c('TextField',{attrs:{"name":"postcode"}}),_c('SelectField',{attrs:{"name":"state"}})],1):_vm._e(),(
															_vm.customGridTabs.filter(
																function (tab) { return tab.tabName ===
																	stage.header; }
															).length > 0
														)?_c('div',[_c('CustomGridTab',{attrs:{"grid-details":_vm.customGridTabs.find(
																	function (tab) { return tab.tabName ===
																		stage.header; }
																),"save-changes-url":_vm.definedBenefitTabDataForEmployee(
																	_vm.reportingCentreId,
																	_vm.employeeId
																)}})],1):_vm._e(),(
															stage.header ===
															'Fund'
														)?_c('div',[_c('LeftRightFooter',{staticClass:"mb-1"},[(
																	_vm.mode !==
																		'view' &&
																	_vm.editSensitivePermission
																)?_c('Button',{attrs:{"data-cy":"add-fund","variant":"primary"},on:{"click":_vm.handleAddFundClick}},[_vm._v(" Add Fund ")]):_vm._e()],1),(
																_vm.doFundsExist
															)?_c('Grid',{ref:"gridEl",refInFor:true,attrs:{"column-defs":_vm.columnDefs,"row-data":_vm.recordFormData.funds},on:{"grid-ready":_vm.onGridReady}}):(
																!_vm.doFundsExist
															)?_c('AlertInfo',{attrs:{"alert-message":"Please add the APRA Fund or SMSF that this employee wants to contribute to."}}):_vm._e(),_c('ValidationProvider',{attrs:{"vid":"funds","name":"Funds","rules":"required","slim":""}},[_c('input',{attrs:{"type":"hidden"},domProps:{"value":_vm.recordFormData
																		.funds[0]}})]),_c('ValidationProvider',{attrs:{"vid":"smsfTermination","name":"SmsfValidation","rules":{
																required: {
																	allowFalse: false,
																},
															},"slim":""}},[_c('input',{attrs:{"type":"hidden"},domProps:{"value":!_vm.hasFundError}})])],1):_vm._e(),(
															stage.header ===
															'Employer'
														)?_c('div',[_c('b-row',[_c('b-col',[_c('AutoField',{attrs:{"label":"Reporting centre name","value":_vm.employerDetails.reportingCentreName,"readonly":true}})],1)],1),_c('b-row',[_c('b-col',[_c('AutoField',{attrs:{"label":"ABN","value":_vm.employerDetails.abn,"readonly":true}})],1)],1),_c('b-row',[_c('b-col',[_c('AutoField',{attrs:{"label":"Employer payroll name","value":_vm.employerDetails.employerPayrollName,"readonly":true}})],1),_c('b-col',[_c('AutoField',{attrs:{"label":"Employer payroll location","value":_vm.employerDetails.employerPayrollLocation,"readonly":true}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('TextField',{attrs:{"name":"payrollNo"}})],1)],1)],1):_vm._e(),_vm._l((subSection.value),function(row,rowIndex){return _c('b-row',{key:rowIndex,class:{
															'employment-field__container':
																stage.header ===
																'Employment',
														}},_vm._l((row),function(name,index){return _c('b-col',{directives:[{name:"show",rawName:"v-show",value:(
																_vm.displayField(
																	stage,
																	name,
																	_vm.recordFormData
																)
															),expression:"\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tdisplayField(\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tstage,\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tname,\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\trecordFormData\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t)\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"}],key:index,class:{
																'employment-field__input':
																	stage.header ===
																		'Employment' &&
																	_vm.editFieldMap[
																		name
																	]
																		.type !==
																		'TextareaField',
																'employment-field__textarea':
																	_vm.editFieldMap[
																		name
																	]
																		.type ===
																	'TextareaField',
															}},[(
																	_vm.editFieldMap[
																		name
																	]
																		.type !==
																	'DateField'
																)?_c('AutoField',{class:{
																	'no-upscope':
																		_vm.editFieldMap[
																			name
																		]
																			.type ===
																		'TFNField',
																},attrs:{"name":name,"readonly":_vm.editFieldMap[
																		name
																	]
																		.readonly},on:{"supplied-change":_vm.onTaxFileNoSuppliedChange}}):_vm._e(),(
																	_vm.editFieldMap[
																		name
																	]
																		.type ===
																		'DateField' &&
																	name !==
																		'superContributionCommencementDate'
																)?_c('DatepickerField',{class:{
																	'no-upscope':
																		_vm.editFieldMap[
																			name
																		]
																			.name ===
																		'dateOfBirth',
																},attrs:{"name":name,"data-cy":"\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tdateOfBirth\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"}}):_vm._e(),(
																	name ===
																	'superContributionCommencementDate'
																)?_c('DatepickerField',{attrs:{"name":name,"errors":_vm.commencementDateFieldErrors},on:{"input":_vm.onCommencementDateFieldInput}}):_vm._e()],1)}),1)}),(idx === 5)?_c('b-row',{attrs:{"align-v":"end"}},_vm._l((_vm.dbRegoEmployeeDefinedBenefitFields),function(f){return _c('b-col',{key:'dbregofundfieldmap_' +
																f.fundFieldMappingId,staticClass:"mb-2",attrs:{"cols":"3"}},[_c('fund-field-mapping-field',{attrs:{"field-type":f.fieldType,"name":f.fieldName,"label":f.fieldLabel,"field-value":f.fieldValue,"required":f.mandatory,"data-cy":f.fieldName},on:{"input":_vm.onEmployeeDbInputChange}})],1)}),1):_vm._e(),(idx === 6)?_c('b-row',{attrs:{"align-v":"end"}},_vm._l((_vm.additionalEmployeeDefinedBenefitFields),function(f){return _c('b-col',{key:'additionalfundfieldmap_' +
																f.fundFieldMappingId,staticClass:"mb-2",attrs:{"cols":"3"}},[_c('fund-field-mapping-field',{attrs:{"field-type":f.fieldType,"name":f.fieldName,"label":f.fieldLabel,"field-value":f.fieldValue,"required":f.mandatory,"data-cy":f.fieldName},on:{"input":_vm.onEmployeeDbInputChange}})],1)}),1):_vm._e()],2)}),(
														_vm.customMultiTabs.filter(
															function (tab) { return tab.tabName ===
																stage.header; }
														).length > 0
													)?_c('div',[_c('CustomMultiTab',{attrs:{"multi-tab":_vm.customMultiTabs.find(
																function (tab) { return tab.tabName ===
																	stage.header; }
															),"data-url":_vm.definedBenefitTabDataForEmployee(
																_vm.reportingCentreId,
																_vm.employeeId
															)}})],1):_vm._e()],2):_vm._e(),(_vm.loadingRcDetails)?_c('div',{staticClass:"loading-overlay"},[_c('GridLoadingOverlay')],1):_vm._e(),(_vm.activeTabIndex <= 6)?_c('Container',{staticClass:"button-add-form"},[_c('LeftRightFooter',{staticClass:"mt-1"},[[_c('Button',{on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Cancel ")]),(
																_vm.mode !==
																'view'
															)?_c('Button',{staticClass:"action__right save",attrs:{"variant":"primary","data-cy":"Save"},on:{"click":_vm.onClickSave}},[_vm._v(" Save ")]):_vm._e()]],2)],1):_vm._e()],1)])],1)}),1)],1)])]):_vm._e(),(_vm.loadingEmployeeDetails)?_c('div',{staticClass:"loading-overlay"},[_c('GridLoadingOverlay')],1):_vm._e()]}}])})],1)],1),(_vm.fundModalMode)?_c('Modal',{attrs:{"title":_vm.fundModalModeText + ' Fund',"full-height":""},on:{"close":_vm.closeFundModal},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('LeftRightFooter',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('ErrorList',{attrs:{"errors":_vm.fundValidationErrors,"horizontal":true}})]},proxy:true}],null,false,1444972649)},[[_c('Button',{on:{"click":_vm.closeFundModal}},[_vm._v(" Cancel")]),(_vm.fundModalMode !== 'view')?_c('Button',{attrs:{"variant":"primary","data-cy":_vm.fundModalMode === 'add' ? 'Add' : 'Update'},on:{"click":_vm.onClickSubmitFund}},[_vm._v(" "+_vm._s(_vm.fundModalMode === "add" ? "Add" : "Update")+" ")]):_vm._e()]],2)]},proxy:true}],null,false,2948232834)},[(
				!(_vm.fundModalMode === 'view' || _vm.employeeFund.employeeFundId)
			)?_c('SearchFund',{class:{ 'search-fund': !_vm.employeeFund },attrs:{"value":_vm.employeeFund,"reporting-centre-id":_vm.reportingCentreId,"fund-type":_vm.employeeFund && _vm.employeeFund.fundType
					? _vm.employeeFund.fundType
					: undefined},on:{"update":_vm.setSelectedFund}}):_vm._e(),_c('ValidationObserver',{ref:"addFundFormValidation",attrs:{"tag":"div"}},[_c('AddFundForm',{ref:"addFundForm",attrs:{"hide-default-fund":true,"selected-fund":_vm.employeeFund,"smsf-alias-map":_vm.recordFormData.smsfAliasOptions,"readonly":_vm.fundModalMode === 'view'},on:{"update":_vm.updateFund}}),(_vm.employeeFund)?_c('Form',{attrs:{"field-map":_vm.editFieldMap,"readonly":_vm.fundModalMode === 'view'},model:{value:(_vm.employeeFund),callback:function ($$v) {_vm.employeeFund=$$v},expression:"employeeFund"}},_vm._l((_vm.formSchema[3].subSections),function(subSection,key){return _c('div',{key:key,attrs:{"label":subSection.name}},_vm._l((subSection.value),function(row,rowIndex){return _c('b-row',{key:rowIndex},_vm._l((row),function(name,index){return _c('b-col',{directives:[{name:"show",rawName:"v-show",value:(
								_vm.displayField(
									_vm.formSchema[4],
									name,
									_vm.employeeFund
								)
							),expression:"\n\t\t\t\t\t\t\t\tdisplayField(\n\t\t\t\t\t\t\t\t\tformSchema[4],\n\t\t\t\t\t\t\t\t\tname,\n\t\t\t\t\t\t\t\t\temployeeFund\n\t\t\t\t\t\t\t\t)\n\t\t\t\t\t\t\t"}],key:index},[(
									_vm.editFieldMap[name].type !== 'DateField'
								)?_c('AutoField',{attrs:{"name":name,"value":_vm.employeeFund[name],"readonly":_vm.editFieldMap[name].readonly ||
									_vm.fundModalMode === 'view'}}):_vm._e(),(name === 'fundRegistrationDate')?_c('DatepickerField',{attrs:{"name":name,"errors":_vm.fundRegistrationDateFieldErrors,"readonly":_vm.editFieldMap[name].readonly ||
									_vm.fundModalMode === 'view'},on:{"input":_vm.onFundRegistrationDateFieldInput}}):_vm._e(),(name === 'fundTerminationDate')?_c('DatepickerField',{attrs:{"name":name,"readonly":_vm.editFieldMap[name].readonly ||
									_vm.fundModalMode === 'view'}}):_vm._e()],1)}),1)}),1)}),0):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }