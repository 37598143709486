



















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Card from "@/components/Card.vue";
import Button from "@/form/Button.vue";
import { RoutePath } from "@/router/routePath";
import { getIconForRoute } from "@/utils/RouteUtils";
@Component({ components: { Card, Button } })
export default class MaintenanceGridItem extends Vue {
	@Prop({ type: String }) readonly title!: string;

	@Prop({ type: String }) readonly description!: string;
	@Prop({ type: String }) readonly buttonName!: string;
	@Prop({}) readonly link!: RoutePath;
	@Prop({ type: String }) readonly dataCy!: string;
	@Prop({ type: String }) readonly cardDataCy!: string;

	get routeIcon(): string[] {
		return getIconForRoute(this.link, this.$route);
	}
}
