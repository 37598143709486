




import {
	AgCartesianChartOptions,
	AgChartOptions,
	AgCartesianSeriesTooltipRendererParams,
} from "ag-charts-community";
import Vue from "vue";
import Component from "vue-class-component";
import { AgChartsVue } from "ag-charts-vue";
import { chartTheme } from "@/components/charts/chartTheme";
import { Prop } from "vue-property-decorator";
import { formatCurrencyValue } from "@/components/charts/ChartUtils";

export interface BatchTotalType {
	quarterAndYear: string;
	batchTotal: number;
	unfundedTotal: number;
}

const batchTotalChartOptions: AgCartesianChartOptions = {
	type: "column",
	title: {
		text: "Your contribution batch totals",
	},
	legend: {
		enabled: true,
		position: "bottom",
	},
	axes: [
		{
			type: "number",
			position: "left",
			label: {
				format: "$~s",
				formatter: (params) => {
					if (params.formatter) {
						return params
							.formatter(params.value)
							.replace("k", "K")
							.replace("G", "B");
					}
				},
			},
		},
		{
			type: "category",
			position: "bottom",
		},
	],
	series: [
		{
			xKey: "quarterAndYear",
			yKey: "batchTotal",
			yName: "Funded",
			highlightStyle: {
				item: {
					fillOpacity: 0,
				},
			},
			tooltip: {
				renderer: (params: AgCartesianSeriesTooltipRendererParams) => {
					return {
						title: `${params.xValue}`,
						content: `${formatCurrencyValue(params.yValue)}`,
					};
				},
			},
		},
		{
			xKey: "quarterAndYear",
			yKey: "unfundedTotal",
			yName: "Not yet funded",
			highlightStyle: {
				item: {
					fillOpacity: 0,
				},
			},
			tooltip: {
				renderer: (params: AgCartesianSeriesTooltipRendererParams) => {
					return {
						title: `${params.yName}`,
						content: `${formatCurrencyValue(params.yValue)}`,
					};
				},
			},
		},
	],
};

@Component({
	components: {
		agCharts: AgChartsVue,
	},
})
export default class ColumnChart extends Vue {
	@Prop(Array)
	chartData!: BatchTotalType[];

	chartOptions: AgChartOptions = {
		data: this.chartData,
		...batchTotalChartOptions,
	};

	async mounted() {
		const theme = await chartTheme();
		this.chartOptions = {
			theme,
			data: this.chartData,
			...batchTotalChartOptions,
		};
	}
}
