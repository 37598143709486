


























































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { EmployerHierarchy } from "@/store/modules/persistent/persistentTypes";
import { EMPLOYER, PARENT } from "@/utils/PermissionUtils";
import { createNamespacedHelpers } from "vuex";
import { cloneDeep } from "lodash-es";

/**
 * Correspond to @/store/modules/persistent/persistentTypes EmployerHierarchy interface and modifiy
 * - remove Children type and make children recursive
 * - add isDisabled property
 * - children can be null, it mean leaf node in vue-treeselect
 */
interface EmployerSelectorOption {
	id: string;
	label: string;
	children: EmployerSelectorOption[] | null;
	isDisabled: boolean;
	dbEnabled: boolean;
}

const { mapState, mapMutations } = createNamespacedHelpers("persistent");

@Component({
	components: { Treeselect },
	computed: mapState({
		selectedEntitiesFromStore: "selectedEntities",
		hierarchyTreeList: "employerHierarchy",
		selectedEntitiesFromStoreInsight: "selectedEntitiesInsight",
	}),
	methods: {
		...mapMutations(["setSelectedEntities", "setSelectedEntitiesInsight"]),
	},
})
export default class EmployerSelector extends Vue {
	/**
	 * Use for multiple selections (multiple = true)
	 */
	private selectedEntities: string[] = [];
	private selectedEntitiesInsight: string[] = [];

	/**
	 * Used for single selection (multiple = false)
	 */
	private selectedEntity: string | null = null;
	private selectedEntityInsight: string | null = null;

	private options: Array<EmployerSelectorOption> = [];
	@Prop({ type: Boolean, default: true }) multiple!: boolean;
	//This 'selectLevel' enables only that particular level for selection
	@Prop({ default: "ALL" }) selectLevel!: TSelectLevel;
	@Prop({ default: false }) disable!: boolean;
	@Prop({ default: null }) errorMessage!: string | null;
	@Prop({ default: false }) isSelectFirstEntry!: boolean;
	@Prop({ default: true }) clearable!: boolean;
	/**
	 * Should only be used in Insights page
	 */
	@Prop({ default: false }) insight!: boolean;
	private flat = false;
	private valueConsistOf = "BRANCH_PRIORITY";

	/**
	 * Type the mapped persistent.selectedEntities getter.
	 * It is a computed property. Do not mutate it.
	 */
	selectedEntitiesFromStore!: string[];
	selectedEntitiesFromInsightStore!: string[];

	/**
	 * Type the mapped persistent.employerHierarchy getter.
	 * It is a computed property. Do not mutate it.
	 */
	hierarchyTreeList!: EmployerHierarchy[];

	/**
	 * Type the mapped persistent.setSelectedEntities mutation.
	 */
	setSelectedEntities!: (selectedEntities: string[]) => void;
	setSelectedEntitiesInsight!: (selectedEntities: string[]) => void;

	@Watch("selectedEntitiesFromStore", { immediate: true })
	onSelectedEntitiesChanged() {
		if (this.insight) {
			return;
		}
		if (this.multiple) {
			this.selectedEntities = this.selectedEntitiesFromStore;
		} else {
			if (this.selectedEntitiesFromStore.length === 1) {
				// If EmployerSelector happens to select only one item in store, get it.
				this.selectedEntity = this.selectedEntitiesFromStore[0];
			} else {
				// Either empty or more than one items are selected from the store, clear selector.
				this.selectedEntity = null;
			}
		}
	}

	@Watch("selectedEntitiesFromInsightStore", { immediate: true })
	onSelectedEntitiesFromInsightStore() {
		if (!this.selectedEntitiesFromInsightStore) {
			return;
		}

		if (this.multiple) {
			this.selectedEntitiesInsight =
				this.selectedEntitiesFromInsightStore;
		} else {
			if (this.selectedEntitiesFromInsightStore.length === 1) {
				// If EmployerSelector happens to select only one item in store, get it.
				this.selectedEntityInsight =
					this.selectedEntitiesFromInsightStore[0];
			} else {
				// Either empty or more than one items are selected from the store, clear selector.
				this.selectedEntityInsight = null;
			}
		}
	}

	@Watch("hierarchyTreeList", { immediate: true })
	refreshEmployerHierarchy() {
		// NOTE (York): Due to incorrect type of EmployerHierarchy, do explicit type cast
		// to EmployerSelectorOption array.
		const options = cloneDeep(
			this.hierarchyTreeList
		) as EmployerSelectorOption[];
		if (this.selectLevel !== "ALL") {
			this.flat = this.multiple; // only enable flat mode on multiple select mode
			if (this.selectLevel === "RC") {
				this.options = this.enableOnlyRc(options);
				this.valueConsistOf = "LEAF_PRIORITY";
			} else if (this.selectLevel === "Parent") {
				this.options = this.enableOnlyParent(options);
			} else if (this.selectLevel === "Employer") {
				this.options = this.enableOnlyEmployer(options);
				this.valueConsistOf = "LEAF_PRIORITY";
			} else if (this.selectLevel === "Batch") {
				this.options = this.enableOnlyEmployerParent(options);
			}
		} else {
			this.options = this.enableAllLevels(options);
		}
		this.options.forEach((option) => this.setLeafNull(option));
	}

	/**
	 * EmployerHierarchy children property is non-null to free null check.
	 * vue-treeselect needs null for leaf node.
	 */
	private setLeafNull(option: EmployerSelectorOption) {
		if (option.children === null || option.children.length === 0) {
			option.children = null;
			return;
		}
		for (const child of option.children) {
			this.setLeafNull(child);
		}
	}

	// NOTE (York): Remove this method after mounted is fixed
	get optionsFromStore() {
		return this.$store.getters["persistent/employerHierarchy"];
	}

	get style() {
		if (this.errorMessage) {
			return "employer-selector error";
		} else {
			return "employer-selector";
		}
	}

	private getIcon(node: EmployerSelectorOption) {
		let icon = null;
		if (node.id.includes("R_")) {
			icon = "reporting-icon";
		}
		if (node.id.includes("P_")) {
			icon = "parent-icon";
		}
		if (node.id.includes("E_")) {
			icon = "employer-icon";
		}
		return icon;
	}

	private getHelpText(node: EmployerSelectorOption) {
		if (node.id.includes("R_")) {
			return "Reporting Centre";
		}
		if (node.id.includes("P_")) {
			return "Parent Organisation";
		}
		if (node.id.includes("E_")) {
			return "Employer";
		}
	}
	get error() {
		return JSON.parse(JSON.stringify(this.errorMessage));
	}

	updateValue(value: string | string[] | undefined) {
		this.$emit("clear-error-message");
		const selectedItem = value;
		if (this.multiple) {
			if (
				selectedItem === undefined ||
				typeof selectedItem === "string"
			) {
				throw Error(
					"Value of treeselect is either empty array or a string array when multiple is true."
				);
			}

			if (this.insight) {
				this.selectedEntitiesInsight = selectedItem;
				this.setSelectedEntitiesInsight(selectedItem);
			} else {
				this.selectedEntities = selectedItem;
				this.setSelectedEntities(selectedItem);
			}
		} else {
			if (
				selectedItem !== undefined &&
				typeof selectedItem !== "string"
			) {
				throw Error(
					"Value of treeselect is either undefined or a string when multiple is false."
				);
			}
			// NOTE (York): Class component limitation: undefined data is not reactive, change it to null
			if (this.insight) {
				this.selectedEntityInsight = selectedItem ?? null;
				this.setSelectedEntitiesInsight(
					selectedItem ? [selectedItem] : []
				);
			} else {
				this.selectedEntity = selectedItem ?? null;
				this.setSelectedEntities(selectedItem ? [selectedItem] : []);
			}
		}
	}

	private mounted() {
		// NOTE (York): Questions:
		// - `!this.selectedEntities` is always false
		// - it does not guarantee that optionsFromStore[0] has children or grandchildren
		if (this.isSelectFirstEntry && !this.selectedEntities) {
			switch (this.selectLevel) {
				case "ALL":
				case "Parent":
					this.updateValue(this.optionsFromStore[0].id);
					break;
				case "Employer":
					this.updateValue(this.optionsFromStore[0].children[0].id);
					break;
				case "RC":
					this.updateValue(
						this.optionsFromStore[0].children[0].children[0].id
					);
			}
		}
	}

	private enableOnlyParent(
		data: Array<EmployerSelectorOption>
	): Array<EmployerSelectorOption> {
		for (const root of data) {
			root.isDisabled = false;
			if (root.children != null) {
				for (const intermediate of root.children) {
					intermediate.isDisabled = true;
					if (intermediate.children !== null) {
						for (const leaf of intermediate.children) {
							leaf.isDisabled = true;
						}
					}
				}
			}
		}
		return data;
	}

	private enableOnlyEmployer(
		data: Array<EmployerSelectorOption>
	): Array<EmployerSelectorOption> {
		for (const root of data) {
			root.isDisabled = true;
			if (root.children != null) {
				for (const intermediate of root.children) {
					intermediate.isDisabled = false;
					if (intermediate.children !== null) {
						for (const leaf of intermediate.children) {
							leaf.isDisabled = true;
						}
					}
				}
			}
		}
		return data;
	}

	private enableOnlyEmployerParent(
		data: Array<EmployerSelectorOption>
	): Array<EmployerSelectorOption> {
		for (const root of data) {
			root.isDisabled = false;
			if (!root.children || root.children.length === 0) {
				if (root.id.startsWith(PARENT)) {
					// Do not allow selecting parent orgs with no employers at 'batch' level as they are not ready to be
					// used to create a batch. At the time of this comment, a batch could be created but we get
					// get an error when trying to add an employee.
					root.isDisabled = true;
				}
				return data;
			}

			//root is a employer with no parent
			if (root.id.startsWith(EMPLOYER)) {
				for (const leaf of root.children) {
					leaf.isDisabled = true;
				}
			} else {
				// root is a parent
				for (const intermediate of root.children) {
					intermediate.isDisabled = false;
					if (intermediate.children !== null) {
						for (const leaf of intermediate.children) {
							leaf.isDisabled = true;
						}
					}
				}
			}
		}
		return data;
	}

	private enableOnlyRc(data: Array<EmployerSelectorOption>) {
		for (const root of data) {
			root.isDisabled = true;
			if (root.children != null) {
				for (const intermediate of root.children) {
					intermediate.isDisabled = true;
					if (intermediate.children !== null) {
						for (const leaf of intermediate.children) {
							leaf.isDisabled = false;
						}
					}
				}
			}
		}
		return data;
	}

	private enableAllLevels(options: EmployerSelectorOption[]) {
		for (const root of options) {
			root.isDisabled = false;
			if (root.children != null) {
				for (const intermediate of root.children) {
					intermediate.isDisabled = false;
					if (intermediate.children !== null) {
						for (const leaf of intermediate.children) {
							leaf.isDisabled = false;
						}
					}
				}
			}
		}
		return options;
	}
	private normaliser(node: any) {
		if (node.children === null || node.children === "null") {
			delete node.children;
		}
	}
}

export type TSelectLevel = "Parent" | "Employer" | "RC" | "Batch" | "ALL";
