var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.name.match('contractlengthgreaterthan12months'))?_c('TextField',{class:_vm.classValue,attrs:{"name":_vm.name,"label":_vm.label,"value":_vm.fieldValue,"rules":"boolean","disabled":_vm.disabled},on:{"input":_vm.onInputChange}}):_vm._e(),(_vm.name.toUpperCase().includes('ABN'))?_c('TextField',{class:_vm.classValue,attrs:{"name":_vm.name,"label":_vm.label,"value":_vm.fieldValue,"rules":"abn","disabled":_vm.disabled},on:{"input":_vm.onInputChange}}):_vm._e(),(_vm.name.toUpperCase().includes('TFN'))?_c('TextField',{class:_vm.classValue,attrs:{"name":_vm.name,"label":_vm.label,"value":_vm.fieldValue,"rules":"tfn","disabled":_vm.disabled},on:{"input":_vm.onInputChange}}):_vm._e(),(_vm.name.toUpperCase().includes('BSB'))?_c('TextField',{class:_vm.classValue,attrs:{"name":_vm.name,"label":_vm.label,"value":_vm.fieldValue,"rules":"bsb","disabled":_vm.disabled},on:{"input":_vm.onInputChange}}):_vm._e(),(_vm.fieldType === 'DATE')?_c('DatepickerField',{class:_vm.classValue,attrs:{"name":_vm.name,"label":_vm.label,"value":_vm.fieldValue,"rules":this.fieldRule,"disabled":_vm.disabled},on:{"input":_vm.onInputChange}}):_vm._e(),(_vm.fieldType === 'RATE')?_c('CurrencyField',{class:_vm.classValue,attrs:{"name":_vm.name,"label":_vm.label,"value":_vm.fieldValue,"currency-scale":5,"rules":this.fieldRule + '|max:1899',"disabled":_vm.disabled},on:{"input":_vm.onInputChange}}):_vm._e(),(_vm.fieldType === 'PERCNT')?_c('CurrencyField',{class:_vm.classValue,attrs:{"name":_vm.name,"label":_vm.label,"value":_vm.fieldValue,"currency-scale":5,"rules":this.fieldRule + '|max:1899',"disabled":_vm.disabled},on:{"input":_vm.onInputChange}}):_vm._e(),(
			(_vm.fieldType === 'TEXT' || _vm.fieldType === 'OCOINS') &&
			!_vm.doesFieldContain(_vm.name, _vm.containsFields) &&
			!_vm.doesFieldEqual(_vm.name, _vm.customFields)
		)?_c('TextField',{class:_vm.classValue,attrs:{"name":_vm.name,"label":_vm.label,"value":_vm.fieldValue,"rules":this.fieldRule + '|max:1899',"disabled":_vm.disabled},on:{"input":_vm.onInputChange}}):_vm._e(),(
			_vm.fieldType === 'AMOUNT' ||
			_vm.fieldType === 'N_AMNT' ||
			_vm.fieldType === 'AMNT_2'
		)?_c('CurrencyField',{class:_vm.classValue,attrs:{"name":_vm.name,"label":_vm.label,"value":_vm.fieldValue,"rules":this.fieldRule + '|max:11',"disabled":_vm.disabled},on:{"input":_vm.onInputChange}}):_vm._e(),(_vm.fieldType === 'LWP')?_c('AutoField',{class:_vm.classValue,attrs:{"name":_vm.name,"label":_vm.label,"value":_vm.fieldValue,"rules":this.fieldRule + '|max:1899',"disabled":_vm.disabled},on:{"input":_vm.onInputChange}}):_vm._e(),(_vm.fieldType === 'HRS')?_c('CurrencyField',{class:_vm.classValue,attrs:{"name":_vm.name,"label":_vm.label,"value":_vm.fieldValue,"rules":this.fieldRule + '|max:1899',"disabled":_vm.disabled},on:{"input":_vm.onInputChange}}):_vm._e(),(_vm.fieldType === 'UNI_SF')?_c('CurrencyField',{class:_vm.classValue,attrs:{"name":_vm.name,"label":_vm.label,"currency-scale":-1,"value":_vm.fieldValue,"rules":this.fieldRule + '|max:11',"disabled":_vm.disabled},on:{"input":_vm.onInputChange}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }