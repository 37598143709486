




























































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { debounce } from "lodash";
import ButtonBehaviour from "@/form/ButtonBehaviour.vue";

@Component({
	components: {
		ButtonBehaviour,
	},
})
export default class Accordion extends Vue {
	private static uniqueId = 0;

	/**
	 * id must be globally unique in a HTML document, we guarantee this by making each render
	 * of this component have unique ID number appended to the end to avoid clashes.
	 *
	 * We need ids mostly to connect <label> and <input> elements together for accessibility.
	 */
	private readonly id = "Accordion_" + ++Accordion.uniqueId;

	@Prop(String) readonly label!: string;

	@Prop(Boolean) readonly open!: boolean;

	@Prop(Boolean) readonly disabled!: boolean;

	public $refs!: {
		rootEl: HTMLElement;
		bodyEl: HTMLElement;
		bodyInnerEl: HTMLElement;
		buttonEl: ButtonBehaviour;
	};

	isOpen = false;

	/**
	 * We update this with "this.$refs.bodyInnerEl.scrollHeight" when the height value changes
	 * so that the accordion grows.
	 */
	//bodyInnerHeight: number | 'auto' = 'auto';

	/**
	 * onResize callback is wrapped in a debounce function to avoid firing resize too
	 * often when resizing the window.
	 */
	onResize: (e: Event) => void = debounce((e) => this.onResize(e), 200);

	mounted(): void {
		this.isOpen = this.open;
		if (this.isOpen) {
			// NOTE(Jae): 2020-05-22
			// If we start off with our component being open,
			// set the height to "auto".
			// This ensures that an accordion won't appear "half way" open
			// due to computing a fixed height too early. (ie. before fonts/CSS is loaded)
			this.$refs.bodyEl.style.height = "auto";
		}
	}

	destroyed(): void {
		// NOTE(Jae): 2020-05-15
		// Ensure we remove listeners if the menu is open and this gets destroyed
		// by swapping routes / etc.
		this.removeEventListeners();
	}

	private removeEventListeners(): void {
		document.body.removeEventListener("resize", this.onResize);
	}

	_onResize(e: Event): void {
		this.setHeight(this.$refs.bodyInnerEl.offsetHeight);
	}

	private setHeight(height: number | string): void {
		const bodyEl = this.$refs.bodyEl;
		//TODO: remove this when implementing CHSN-214
		if (typeof height === "number") {
			height = height + 10 + "px";
		}

		if (bodyEl.style.height === height) {
			// Ignore if the same
			return;
		}
		// NOTE(Jae) 2020-05-22
		// Set height to current height, then on the next few frames set
		// to the height we want to transition to. We do this because
		// CSS won't magically lerp between "auto" and a new height number
		// for us.
		const currentHeight = this.$refs.bodyInnerEl.offsetHeight + "px";
		bodyEl.style.height = currentHeight;

		// NOTE(Jae) 2020-05-22
		// For unknown reasons, I need to wait 2 frames before modifying the height.
		// Normally, I only need to wait 1, so I suspect this is a quirk related to Vue.js
		// and its VDOM implementation.
		// This logic is mostly need for an Accordion that starts off "open" to ensure there's
		// a smooth CSS transition between an "auto" height and 0.
		window.requestAnimationFrame(() => {
			window.requestAnimationFrame(() => {
				// NOTE(Jae) 2020-05-22
				// Only set to height if the height is unchanged from what
				// we set it to on the last frame as we only want to make this change
				// if it's the value we expect it to be.
				if (bodyEl && bodyEl.style.height === currentHeight) {
					bodyEl.style.height = String(height);
				}
			});
		});
	}

	@Watch("open")
	onOpenPropChanged(newValue: boolean, oldValue: boolean) {
		this.isOpen = newValue;
	}

	@Watch("isOpen")
	onIsOpenChanged(newValue: boolean, oldValue: boolean) {
		switch (newValue) {
			case false: {
				this.removeEventListeners();

				this.setHeight(0);
				break;
			}

			case true: {
				if (this.$refs.bodyEl.style.height === "auto") {
					// NOTE(Jae): 2020-05-22
					// We don't want to set an explicit height if we are already
					// using the default layout height. This handles the case where
					// an Accordion starts off opened on a page by default.
					break;
				}

				document.body.addEventListener("resize", this.onResize);

				// NOTE(Jae): 2020-05-20
				// We calculate the height of the body by seeing what the natural height
				// of the inner element is. Since the "inner" element height is never modified, we
				// can always retrieve the proper height calculated by the browser.
				const height = this.$refs.bodyInnerEl.offsetHeight;
				this.setHeight(height);
				break;
			}
		}
	}

	onClickButton(e: Event) {
		this.isOpen = !this.isOpen;
	}
}
