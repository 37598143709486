






























































































































import Vue from "vue";
import { Component } from "vue-property-decorator";

import ContributionResource, {
	ContributionSummary,
} from "@/rest/ContributionResource";
import Layout from "@/components/Layout.vue";
import ErrorList from "@/components/ErrorList.vue";
import { AppRoute, AppRouteNextFunction } from "@/router";
import { RoutePath } from "@/router/routePath";
import { parseErrorMessage, parseErrorMessageList } from "@/utils/ErrorUtils";
import LeftRightFooter from "@/components/LeftRightFooter.vue";
import SummaryList from "@/components/SummaryList.vue";
import SummaryListItem from "@/components/SummaryListItem.vue";
import Button from "@/form/Button.vue";
import axios from "@/utils/ApiUtils";
import {
	batchCancelURL,
	batchDownloadErrorReport,
} from "@/constants/apiconstants";
import ModalWithSaveAndCancel from "@/components/ModalWithSaveAndCancel.vue";
import ProgressArrow from "@/components/ProgressArrow.vue";
import { toastErrorMessage, toastSuccessMessage } from "@/plugins/toasts";
import {
	getNextStep,
	getSteps,
	enableFileAndWarningsStep,
} from "@/constants/pageConstants";
import { debounce } from "lodash-es";
import PageHeader from "@/components/PageHeader.vue";
import { ContributionFileType } from "@/pages/fundTypes";

interface DataInputResponsesCombined {
	id: string;
	summary: ContributionSummary | undefined;
	errors: string[];
}

@Component({
	components: {
		PageHeader,
		Layout,
		Button,
		ErrorList,
		SummaryList,
		SummaryListItem,
		LeftRightFooter,
		ModalWithSaveAndCancel,
		ProgressArrow,
	},
})
export default class DataInputPage extends Vue {
	// seconds before refresh
	private static readonly DATA_REFRESH_INTERVAL: number = 15;

	private summary: ContributionSummary | null = null;
	private isCancelModalShown = false;

	private readonly title = "Data input";

	private steps = getSteps(this.title);

	/**
	 * errors is a collection of error messages that hides the entire screen if not empty.
	 * Used for responses.
	 */
	private errors: string[] = [];

	async beforeRouteEnter(
		to: AppRoute,
		from: AppRoute,
		next: AppRouteNextFunction<DataInputPage>
	) {
		const r = await DataInputPage.beforeRouteEnterOrUpdate(to, from, next);
		if (r === undefined) {
			return;
		}
		next((vm) => {
			vm.beforeRouteCommon(r);
		});
	}

	async beforeRouteUpdate(
		to: AppRoute,
		from: AppRoute,
		next: AppRouteNextFunction<DataInputPage>
	) {
		const r = await DataInputPage.beforeRouteEnterOrUpdate(to, from, next);
		if (r === undefined) {
			return;
		}
		// NOTE(Jae): 2020-05-19
		// beforeRouteUpdate modifies the current instance, so we can't just simply
		// reuse the (vm) callback pattern.
		this.beforeRouteCommon(r);
		next();
	}

	beforeRouteCommon(resp: DataInputResponsesCombined) {
		const { summary, errors } = resp;
		if (summary !== undefined) {
			this.summary = summary;
			enableFileAndWarningsStep(
				this.steps,
				summary.withFileLevelErrorWarning
			);
		}
		this.errors = errors;

		this.setIntervalForNewId(resp.id);
	}

	static async beforeRouteEnterOrUpdate(
		to: AppRoute,
		from: AppRoute,
		next: AppRouteNextFunction<DataInputPage>
	): Promise<DataInputResponsesCombined | undefined> {
		const id: string = to.params.id;
		if (!id || id === "0") {
			// Redirect to 404 if ID is blank or 0.
			next({
				name: "Not Found",
			});
			return;
		}

		return DataInputPage.fetchDataInputResponses(id);
	}

	static async fetchDataInputResponses(
		id: string
	): Promise<DataInputResponsesCombined> {
		const errors: string[] = [];

		// Fire requests simultaneously
		let summaryData: ContributionSummary | undefined;
		await Promise.all([
			ContributionResource.getContributionSummary(Number(id))
				.then((resp) => {
					summaryData = resp.data;
				})
				.catch((e: any) => {
					const respErrors = parseErrorMessageList(e);
					errors.push(...respErrors);
				}),
		]);

		return {
			id: id,
			summary: summaryData,
			errors: errors,
		};
	}

	private intervalData: {
		id?: string;
		remaining?: number;
		intervalRef?: number;
		isRefreshing: boolean;
	} = {
		id: undefined,
		remaining: undefined,
		intervalRef: undefined,
		isRefreshing: false,
	};

	stopRefreshTimer(): void {
		if (this.intervalData.intervalRef !== undefined) {
			clearInterval(this.intervalData.intervalRef);
			this.intervalData.intervalRef = undefined;
		}
		this.intervalData.remaining = undefined;
	}

	/**
	 * Summary data is refreshed periodically while the page is open
	 */
	private setIntervalForNewId(id: string) {
		if (this.intervalData.intervalRef !== undefined) {
			this.intervalData.isRefreshing = false;
			clearInterval(this.intervalData.intervalRef);
			this.intervalData.intervalRef = undefined;
		}

		this.intervalData.id = id;
		this.intervalData.remaining = DataInputPage.DATA_REFRESH_INTERVAL;
		// Runs every second and triggers refresh every DATA_REFRESH_INTERVAL
		this.intervalData.intervalRef = window.setInterval(() => {
			if (this.intervalData.isRefreshing) {
				return;
			}
			if (
				this.intervalData.remaining === undefined ||
				this.intervalData.remaining < 0
			) {
				throw new Error("Invalid state for refresh interval");
			}

			if (this.intervalData.remaining === 0) {
				this.intervalData.remaining =
					DataInputPage.DATA_REFRESH_INTERVAL;
				this.triggerRefresh();
			}
			this.intervalData.remaining = Math.abs(
				this.intervalData.remaining - 1
			);
		}, 1000);
	}

	private triggerRefresh() {
		if (!this.canRefresh || this.intervalData.id === undefined) {
			// this is done in canRefresh but compiler doesn't know
			return;
		}
		this.intervalData.isRefreshing = true;

		DataInputPage.fetchDataInputResponses(this.intervalData.id)
			.then(this.beforeRouteCommon)
			.finally(() => (this.intervalData.isRefreshing = false));
	}

	get canRefresh(): boolean {
		return (
			this.intervalData.id !== undefined &&
			!this.intervalData.isRefreshing
		);
	}

	get refreshCount(): string {
		if (this.intervalData.remaining === undefined) {
			return "";
		}
		return this.intervalData.remaining === 0
			? ""
			: "" + this.intervalData.remaining;
	}

	get canClickNext(): boolean {
		if (this.summary === null) {
			return false;
		}
		if (
			this.parentBatchId === 0 ||
			this.parentBatchId === undefined ||
			this.parentBatchId === null
		) {
			// If not loaded or invalid ID of 0 or somehow invalid data
			return false;
		}
		if (
			this.summary.employees === null ||
			this.summary.funds === null ||
			this.summary.totalDollarValue === null ||
			(this.summary.contributionFileType ===
				ContributionFileType.SUPERSTREAM &&
				(this.summary.errors === null ||
					this.summary.warnings === null))
		) {
			// If any of these values are null, then things are still being processed.
			return false;
		}

		const canClickNext =
			this.summary.batchStatus === "In Progress" ||
			this.summary.batchStatus === "Awaiting Authorisation" ||
			this.summary.batchStatus === "Manual creation" ||
			this.summary.batchStatus === "Awaiting Second Authorisation" ||
			this.summary.batchStatus === "Awaiting Third Authorisation" ||
			this.summary.batchStatus === "Awaiting Fourth Authorisation" ||
			!this.summary.canCancelBatch; // batch was already approved and is now view only

		if (canClickNext) {
			// clear the refresh timer when we can click next
			this.stopRefreshTimer();
		}

		return canClickNext;
	}

	private onClickRefresh = debounce(
		() => {
			if (this.intervalData.remaining !== undefined) {
				this.intervalData.remaining = 0;
			} else {
				this.triggerRefresh();
			}
		},
		500,
		{ leading: true, trailing: false }
	);

	private onClickNext(): void {
		if (!this.canClickNext) {
			return;
		}
		const routePath = getNextStep(this.steps, this.title).routePath;
		this.$router.push(routePath.replace("/:id", "/" + this.parentBatchId));
	}

	get canClickCancelBatch(): boolean {
		return this.summary?.canCancelBatch === true;
	}

	private onClickCancelBatch(): void {
		if (!this.canClickCancelBatch) {
			return;
		}
		this.isCancelModalShown = true;
	}

	private closeCancelBatch(): void {
		this.isCancelModalShown = false;
	}

	private onClickDownloadErrorReport(): void {
		axios
			.get(batchDownloadErrorReport(this.parentBatchId), {
				responseType: "blob",
			})
			.then((response) => {
				const filename = "errors.csv";
				const blob = new Blob([response.data]);
				if (window.navigator && window.navigator.msSaveBlob) {
					window.navigator.msSaveBlob(blob, filename);
				} else {
					const url = window.URL.createObjectURL(blob);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", filename);
					document.body.appendChild(link);
					link.click();
				}
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
	}

	get filenameOrBatchname(): string {
		if (this.summary === null) {
			return "";
		}
		return this.summary.filename;
	}

	get reportingCentreCount(): string {
		if (this.summary === null) {
			return "";
		}
		return String(this.summary.reportingCentres);
	}

	get employeeCount(): string {
		if (this.summary === null) {
			return "";
		}
		if (this.summary.employees === null) {
			// If null, then we haven't processed this information in the backend yet.
			return "(Processing)";
		}
		return String(this.summary.employees);
	}

	get fundCount(): string {
		if (this.summary === null) {
			return "";
		}
		if (this.summary.funds === null) {
			// If null, then we haven't processed this information in the backend yet.
			return "(Processing)";
		}
		return String(this.summary.funds);
	}

	get totalDollarValue(): string {
		if (this.summary === null) {
			return "";
		}
		if (this.summary.totalDollarValue === null) {
			// If null, then we haven't processed this information in the backend yet.
			return "(Processing)";
		}
		return String(this.summary.totalDollarValue);
	}

	get parentBatchId(): number {
		if (this.summary === null) {
			return 0;
		}
		return Number(this.summary.parentBatchId);
	}

	get batchStatus(): string {
		if (this.summary == null) {
			return "";
		}
		return this.summary.batchStatus;
	}

	get errorCount(): string {
		if (this.summary === null) {
			return "";
		}
		if (this.summary.errors === null) {
			// If null, then we haven't processed this information in the backend yet.
			return "(Processing)";
		}
		return String(this.summary.errors);
	}

	get warningCount(): string {
		if (this.summary === null) {
			return "";
		}
		if (this.summary.warnings == null) {
			// If null, then we haven't processed this information in the backend yet.
			return "(Processing)";
		}
		return String(this.summary.warnings);
	}

	get fileFormatName(): string {
		if (this.summary === null) {
			return "";
		}
		return this.summary.fileFormatName;
	}

	triggerBatchCancel() {
		axios
			.delete(batchCancelURL(this.parentBatchId))
			.then((response) => {
				toastSuccessMessage("Contribution batch marked for deletion");
				// Batch no longer exists, so return to the batch list
				this.$router.push(RoutePath.BatchList);
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
		this.isCancelModalShown = false;
	}

	async beforeRouteLeave(
		to: AppRoute,
		from: AppRoute,
		next: AppRouteNextFunction<DataInputPage>
	) {
		if (this.intervalData.intervalRef !== undefined) {
			clearInterval(this.intervalData.intervalRef);
			this.intervalData.intervalRef = undefined;
		}
		next();
	}
}
