var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('notifications',{attrs:{"data-cy":"toasts","group":"portal","position":"bottom center","width":"100%"},scopedSlots:_vm._u([{key:"body",fn:function(props){return [_c('div',{class:{
				'vue-notification-template': true,
				'vue-notification': true,
				info: props.item.type === 'info',
				warn: props.item.type === 'warn',
				success: props.item.type === 'success',
				error: props.item.type === 'error',
			},on:{"click":function($event){return _vm.handleClose(props.item.data, props.close)}}},[_c('div',{staticClass:"custom-template-content"},[_c('div',{staticClass:"notification-title"},[_vm._v(" "+_vm._s(props.item.title)+" ")]),_c('div',{staticClass:"notification-content",domProps:{"innerHTML":_vm._s(props.item.text)}})])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }