
































































































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import TextField from "@/form/TextField.vue";
import FieldGroup from "@/form/FieldGroup.vue";
import Button from "@/form/Button.vue";
import axios from "@/utils/ApiUtils";
import {
	editFundUrl,
	editSmsfUrl,
	searchFundsForPayeeCodeAndRC,
} from "@/constants/apiconstants";
import {
	toastErrorMessage,
	toastSuccessMessage,
	toastWarningMessage,
} from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import {
	EditFundResponse,
	EditSmsfResponse,
	FundType,
} from "@/pages/fundTypes";
import SelectField from "@/form/SelectField.vue";
import { AppRoute, AppRouteNextFunction } from "@/router";
import { VForm } from "@/@typings/type-vee-validate";
import { formatDateTime } from "@/utils/CommonUtils";
import CheckBox from "@/form/CheckBox.vue";
import RadioGroup from "@/form/RadioGroup.vue";
import { hasPermission } from "@/utils/PermissionUtils";

type FundResponse = EditFundResponse | EditSmsfResponse;

class Option {
	value: string | undefined;
	label: string | undefined;
}

@Component({
	components: {
		RadioGroup,
		CheckBox,
		FieldGroup,
		Layout,
		TextField,
		SelectField,
		Button,
	},
})
export default class EditFundPage extends Vue {
	private responseData: FundResponse | null = null;
	private reportingCentreId = "";
	private fundName = "";
	private fundAbn = "";
	private fundUsi = "";
	private abnStatus = "";
	private complyingStatus = "";
	private payeeCode = "";
	private lastSuperFundLookup = "";
	private fen = "";
	private fundType = "";
	private defaultFundFlag = false;
	private accountName = "";
	private bsb = "";
	private accountNumber = "";
	private esa = "";
	private fundSelected: FundResponse | null = null;
	private esaOptions: Array<Option> = [];
	private disableSubmitBtn = true;

	/**
	 * @see form-options.js mixin from bootstrap-vue
	 */
	private fundTypeOptions: { value: boolean; text?: string }[] = [
		{ value: false, text: "No" },
		{ value: true, text: "Yes" },
	];
	private id: number | null = null;

	private enableSave() {
		this.disableSubmitBtn = false;
	}

	private cancel() {
		this.$router.push({ name: "Funds" });
	}

	private async editFund() {
		if (
			this.fundType == FundType.APRA ||
			this.fundType == FundType.SCHEME
		) {
			if (await (this.$refs.fundForm as VForm).validate()) {
				const fundDetails = {
					reportingCentreId: this.reportingCentreId,
					payeeCode: this.payeeCode,
					fen: this.fen,
					id: this.id, // For audit only.
					defaultFundFlag: this.defaultFundFlag,
				};
				axios
					.post<void>(editFundUrl(), fundDetails)
					.then((response) => {
						toastSuccessMessage(
							"Fund " + this.fundName + " modified successfully."
						);
						this.$router.push({ name: "Funds" });
					})
					.catch((error) => {
						toastErrorMessage(parseErrorMessage(error));
					});
			}
		} else {
			if (await (this.$refs.smsfForm as VForm).validate()) {
				const fundDetails = {
					reportingCentreId: this.reportingCentreId,
					payeeCode: this.payeeCode,
					accountName: this.accountName,
					bsb: this.bsb,
					accountNumber: this.accountNumber,
					esa: this.esa,
				};
				axios
					.post<void>(editSmsfUrl(), fundDetails)
					.then((response) => {
						toastSuccessMessage(
							"Fund " + this.fundName + " modified successfully."
						);
						this.$router.push({ name: "Funds" });
					})
					.catch((error) => {
						toastErrorMessage(parseErrorMessage(error));
					});
			}
		}
	}

	get showSaveButton(): boolean {
		if (this.fundType === "SMSF" && !this.hasFundSensitivePermission) {
			return false;
		}
		return true;
	}

	get isDisabled(): boolean {
		if (this.responseData !== null) {
			if (this.fundType === "APRA") {
				this.responseData = this.responseData as EditFundResponse;
				if (this.fen !== "" && this.fen !== this.responseData.fen) {
					return false;
				}
				if (this.defaultFundFlag != this.responseData.defaultFundFlag) {
					return false;
				}
			} else if (this.fundType === "SMSF") {
				this.responseData = this.responseData as EditSmsfResponse;
				if (
					this.accountName !== "" &&
					this.accountName !== this.responseData.accountName
				) {
					return false;
				}
				if (
					this.accountNumber !== "" &&
					this.accountNumber !== this.responseData.accountNumber
				) {
					return false;
				}
				if (this.bsb !== "" && this.bsb !== this.responseData.bsb) {
					return false;
				}
				if (this.esa !== "" && this.esa !== this.responseData.esa) {
					return false;
				}
				if (!this.hasFundSensitivePermission) {
					return false;
				}
			}
			return true;
		}
		return true;
	}

	get shouldShowAPRAForm() {
		return this.fundType === "APRA";
	}

	get shouldShowSMSFForm() {
		return this.fundType === "SMSF";
	}

	async beforeRouteEnter(
		to: AppRoute,
		from: AppRoute,
		next: AppRouteNextFunction<EditFundPage>
	) {
		const fundType = to.params.fundType;
		const reportingCentreId = to.params.reportingCentreId;
		const response = await EditFundPage.beforeRouteEnterOrUpdate(
			to,
			from,
			next
		);
		if (response === undefined) {
			return;
		}
		next((vm) => {
			vm.setResponse(response, fundType, reportingCentreId);
		});
	}

	static async beforeRouteEnterOrUpdate(
		to: AppRoute,
		from: AppRoute,
		next: AppRouteNextFunction<EditFundPage>
	): Promise<FundResponse | undefined> {
		const payeeCode: string = to.params.payeeCode;
		const reportingCentreId: string = to.params.reportingCentreId;
		const type: string = to.params.fundType;
		if (!payeeCode || payeeCode === "0") {
			// Redirect to 404 if ID is blank or 0.
			next({
				name: "Not Found",
			});
			return;
		}
		if (!reportingCentreId || reportingCentreId === "0") {
			// Redirect to 404 if ID is blank or 0.
			next({
				name: "Not Found",
			});
			return;
		}
		if (!type || type === "") {
			next({
				name: "Not Found",
			});
			return;
		}
		return await EditFundPage.fetchFundDetails(
			payeeCode,
			type,
			reportingCentreId
		);
	}

	private static async fetchFundDetails(
		payeeCode: string,
		type: string,
		reportingCentreId: string
	): Promise<FundResponse | undefined> {
		let fundDetailResponse;
		await axios
			.get(
				searchFundsForPayeeCodeAndRC(
					payeeCode,
					type,
					parseInt(reportingCentreId)
				)
			)
			.then((response) => {
				fundDetailResponse = response.data;
			})
			.catch((error) => {
				const errorMessage = parseErrorMessage(error);
				toastErrorMessage(errorMessage);
			});
		return fundDetailResponse;
	}

	setResponse(
		response: FundResponse,
		fundType: string,
		reportingCentreId: string
	) {
		if (response) {
			this.reportingCentreId = reportingCentreId;
			this.responseData = response;
			if (fundType == "APRA") {
				response = response as EditFundResponse;
				this.fundName = response.fundName;
				this.fundAbn = response.fundAbn;
				this.fundUsi = response.fundUsi;
				this.payeeCode = response.payeeCode;
				this.defaultFundFlag = response.defaultFundFlag;
				this.abnStatus = response.status;
				this.fen = response.fen;
				this.fundType = "APRA";
				this.id = response.id;
			} else if (fundType == "SMSF") {
				response = response as EditSmsfResponse;
				this.fundName = response.name;
				this.fundAbn = response.abn;
				this.payeeCode = response.payeeCode;
				this.accountName = response.accountName;
				this.esa = response.esa;
				this.bsb = response.bsb;
				this.accountNumber = response.accountNumber;
				this.lastSuperFundLookup = formatDateTime(
					response.lastLookupDate
				);
				this.abnStatus = response.status;
				this.complyingStatus = response.complyingStatus;
				this.fundType = "SMSF";
				this.esaOptions = Object.keys(response.smsfAliasMap).map(
					(alias) => ({
						value: alias,
						label: alias,
					})
				);
				if (
					response.status ===
						"Service unavailable. Please try again later." ||
					response.complyingStatus ===
						"Service unavailable. Please try again later."
				) {
					toastWarningMessage(
						"Unable to verify details with the government's Super Fund lookup service. Please try later."
					);
				}
			}
		}
	}
	get hasFundSensitivePermission(): boolean {
		if (hasPermission("EDIT_FUND_SENSITIVE")) {
			return true;
		}
		return false;
	}
}
