import { ColDef, ColGroupDef } from "ag-grid-community";

/**
 * This should correspond to the Java class au.com.iress.clearinghouse.portal.contribution.review.BatchReviewDao.BatchReviewLine
 */
export interface FinalBatchReviewWithColumnOverrides {
	batchReviewLines: FinalBatchReviewRecord[];
	columnOverrides: (ColGroupDef | ColDef)[];
}

export interface FinalBatchReviewRecord {
	fund: string;
	fundCode: string;
	fundId: string;
	numOfEmployee: number;
	employerContributionsSuperannuationGuarantee: number;
	personalContributions: number;
	employerContributionsSalarySacrificed: number;
	spouseContributions: number;
	employerContributionsVoluntary: number;
	employerContributionsAwardOrProductivity: number;
	childContributions: number;
	otherThirdPartyContributions: number;
	totalAmount: number;
}

export interface FinalBatchReviewFundRecord {
	surname: string;
	givenName: string;
	payrollNo: string;
	employerContributionsSuperannuationGuarantee: number;
	personalContributions: number;
	employerContributionsSalarySacrificed: number;
	spouseContributions: number;
	employerContributionsVoluntary: number;
	employerContributionsAwardOrProductivity: number;
	childContributions: number;
	otherThirdPartyContributions: number;
	totalAmount: number;
}

/**
 * This should correspond to the java class au.com.iress.clearinghouse.portal.contribution.ContributionFileStagingDao.BatchUpdateDto
 */
export interface FinalBatchReviewUpdateRecord {
	severity: string;
	entity: string;
	oldValue: string;
	newValue: string;
	lastUpdatedByUserName: string;
	lastUpdatedByUserRole: string;

	updateType: UpdateType;
	entityId: string;
	entityName: string;
	entityType: string;
	description: string;
	lastUpdatedBy: string;
	updatedByEmployer: boolean;
	status: string;
	memberContextId: string;
}

/**
 * This should correspond to the java class au.com.iress.clearinghouse.portal.contribution.PaymentInformationDao.ProjectedPaymentInformation
 */
export interface ProjectedPaymentInformation {
	reportingCentreId: number;
	reportingCentreName: string;
	paymentMethod: string;
	totalAmount: string;
}

/**
 * This should correspond to the java enum au.com.iress.clearinghouse.portal.contribution.ContributionFileStagingDao.UpdateType
 */
export const enum UpdateType {
	I = "INFORMATION",
	W = "WARNING",
}

export interface Batch {
	batchId: number;
	payPeriodStartDate: string;
	payPeriodEndDate: string;
	totalAmount: string;
	dateCreated: string;
	dateAuthorised: string;
	orderOfPayment: number;
}

export interface ReviewBatch {
	batches: Batch[];
	allowCancel: boolean;
}

export interface PaymentInformation {
	bsb: string;
	accountNo: string;
	accountName: string;
	prn: string;
	amount: number;
	fundAbn: string;
	fundUsi: string;
}
