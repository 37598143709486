


































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import FieldHolder from "@/form/FieldHolder.vue";
import Button from "@/form/Button.vue";
import type { CommonField } from "@/form/CommonField";

@Component({
	components: {
		Button,
		FieldHolder,
	},
})
export default class FileUpload extends Vue implements CommonField {
	private static uniqueId = 0;

	private readonly fileLimit: number = 1;

	/**
	 * id must be globally unique in a HTML document, we guarantee this by making each render
	 * of this component have unique ID number appended to the end to avoid clashes.
	 *
	 * We need ids mostly to connect <label> and <input> elements together for accessibility.
	 */
	readonly id = "FileUpload_" + ++FileUpload.uniqueId;

	@Prop([String]) readonly name!: string;

	@Prop([String]) readonly label!: string;

	@Prop([String]) readonly rules!: string;
	@Prop([String]) readonly buttonName!: string;

	@Prop(Boolean) readonly readonly!: boolean;

	/**
	 * Comma-delimited list of file extensions that are acceptable.
	 *
	 * ie. ".csv, .txt"
	 */
	@Prop([String]) readonly accept!: string;

	@Prop([Boolean]) readonly disabled!: boolean;

	@Prop([String, Array]) readonly value!: string | string[];

	/**
	 * Add additional custom error messages to this field.
	 */
	@Prop([Array]) readonly errors!: string[];

	/**
	 * Whether errors are shown externally.
	 */
	@Prop(Boolean) readonly errorsShownExternally!: boolean;

	@Prop({ type: Number, default: 5 }) maxError!: number;

	@Prop({ type: Boolean, default: false })
	readonly showDownloadError!: boolean;

	internalError = "";

	mainError = "";

	files: File[] = [];

	readonly $refs!: {
		fileEl: HTMLInputElement;
	};

	onDrop(e: any) {
		// Prevent default behavior (Prevent file from being opened)
		e.preventDefault();

		if (!e.dataTransfer) {
			return;
		}

		if (!this.validate(e.dataTransfer.files)) {
			this.setFiles([]);
			return;
		}

		// NOTE(Jae): 2020-05-11
		// We are using the DataTransfer API instead of DataTransferItemList API
		// as its good enough for our needs AND is IE11+ compatible
		// out-of-the-box.
		// ie. "dataTransfer.files" instead of "dataTransfer.items"
		this.setFiles([...e.dataTransfer.files]);
	}

	clear() {
		this.setFiles([]);
	}

	onClickRemove(e: MouseEvent, file: File) {
		if (this.disabled) {
			return;
		}
		const index = this.files.indexOf(file);
		if (index === -1) {
			return;
		}
		this.files.splice(index, 1);
	}

	get hasError(): boolean {
		return this.allErrors && this.allErrors.length > 0;
	}

	get allErrors(): string[] {
		const r: string[] = [];
		if (this.errors && this.errors.length > 0) {
			r.push(...this.errors);
			this.mainError = "";
		}
		if (this.internalError) {
			r.push(this.internalError);
		}
		return r;
	}

	validate(files: File[] | FileList): boolean {
		if (!FileUpload.isFileListValid(this.accept, files)) {
			this.mainError = "Invalid File Type";
			this.internalError = "File type must be: " + this.accept;
			return false;
		}
		this.mainError = "";
		this.internalError = "";
		return true;
	}

	public static isFileListValid(
		accept: string,
		files: File[] | FileList
	): boolean {
		if (accept === "") {
			// If no validation rules, the file list is always valid
			return true;
		}
		const acceptRuleList = accept.split(",");
		for (let i = 0; i < acceptRuleList.length; i++) {
			acceptRuleList[i] = acceptRuleList[i].trim();
		}

		let validFileCount = 0;
		FileLoop: for (const file of files) {
			// Check if file matches one of the rules
			for (const acceptRule of acceptRuleList) {
				if (acceptRule.length === 0) {
					continue;
				}
				if (acceptRule.charAt(0) === ".") {
					if (
						!file.name
							.toUpperCase()
							.endsWith(acceptRule.toUpperCase())
					) {
						// No match
						continue;
					}
					validFileCount++;
					continue FileLoop;
				}
				// NOTE(Jae): 2020-05-12
				// The other type of accept rule I'm aware of is is a content mimetype.
				// For now, we only support file extensions.
				throw new Error(
					"Unimplemented support for accept type: " + acceptRule
				);
			}
		}
		return validFileCount === files.length;
	}

	onDragOver(e: DragEvent) {
		// Prevent default behavior (Prevent file from being opened)
		e.preventDefault();

		if (!e.dataTransfer) {
			return;
		}

		if (!FileUpload.isFileListValid(this.accept, e.dataTransfer.files)) {
			e.dataTransfer.dropEffect = "none";
			return;
		}

		// This makes the cursor say "Move" when over the drag area rather than
		// "Copy". While I think "Copy" makes more sense, the fact that the browser
		// changes from "Copy" to another state when dragging over the elemnet feels nicer
		// in my opinion. ie. you get feedback that you *can* drag in that area.
		// Also this site seemed to settle on "Move" so I opted to just copy it: https://easyupload.io/
		e.dataTransfer.dropEffect = "move";
	}

	onFileUpload(e: { target: HTMLInputElement }) {
		if (!e.target) {
			return;
		}
		if (!e.target.files || e.target.files.length === 0) {
			return;
		}
		if (!this.validate(e.target.files)) {
			this.setFiles([]);
			return;
		}
		this.setFiles([...e.target.files]);

		// NOTE(Jae): 2020-05-12
		// Clear the value on the actual <input> element so
		// that re-selecting the same file after clicking "Remove"
		// works. This is because the element itself is remembering the file
		// selected state, but we don't want that.
		this.$refs.fileEl.value = "";
	}

	private setFiles(files: File[]) {
		if (!files || files.length === 0) {
			this.files = [];
			return;
		}

		// Truncate to file limit
		files = files.slice(0, this.fileLimit);

		this.files = files;
		this.$emit("input", this.files);
	}

	private downloadErrors() {
		const data = this.errors.join("\n");
		const blob = new Blob([data], { type: "text/plain" });
		const filename = "errors.txt";
		if (window.navigator && window.navigator.msSaveBlob) {
			window.navigator.msSaveBlob(blob, filename);
		} else {
			const link = document.createElement("a");
			link.href = URL.createObjectURL(blob);
			link.download = filename;
			link.target = "_blank";
			link.click();
			URL.revokeObjectURL(link.href);
		}
	}
}
