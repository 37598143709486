import router from "@/router";
import { Route } from "vue-router";
import { hasPermissionForRoute } from "@/utils/PermissionUtils";

export function getNameForRoute(path: string, route: Route) {
	const normalizedRoute = router.resolve(path, route);
	return normalizedRoute?.route?.name ?? "";
}

export function getTitleForRoute(path: string, route: Route) {
	const normalizedRoute = router.resolve(path, route);
	return normalizedRoute?.route?.meta?.title ?? "";
}

export function getIconForRoute(path: string, route: Route): string[] {
	const normalizedRoute = router.resolve(path, route);
	return normalizedRoute?.route?.meta?.icon ?? [];
}

export function checkPermissionForRoute(path: string, route: Route): boolean {
	const normalizedRoute = router.resolve(path, route);
	if (
		!normalizedRoute ||
		!normalizedRoute.route ||
		!normalizedRoute.route.meta ||
		!normalizedRoute.route.meta.permissions
	) {
		return false;
	}
	return hasPermissionForRoute(normalizedRoute.route.meta.permissions);
}
