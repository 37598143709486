var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"vid":_vm.name,"name":_vm.quotedComputedLabel,"rules":_vm.rules,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.errors;
return [_c('div',{class:[
			'FieldHolder__root',
			{
				'FieldHolder__root--label-left': _vm.isLabelLeft,
				'FieldHolder__root--error': _vm.hasErrors(validationErrors),
			} ],attrs:{"data-field-name":_vm.name}},[_c('div',{staticClass:"FieldHolder__labelholder"},[(_vm.computedLabel)?_c('label',{staticClass:"FieldHolder__label",attrs:{"for":_vm.id}},[(_vm.isRequired)?_c('span',{staticClass:"text-danger"},[_vm._v(" *")]):_vm._e(),_vm._v(" "+_vm._s(_vm.computedLabel))]):_vm._e(),_c('span',{staticClass:"FieldHolder__placeholder"},[_vm._v(" "+_vm._s(_vm.placeholder))])]),(!_vm.errorsShownExternally && _vm.hasErrors(validationErrors))?_c('div',{staticClass:"FieldHolder__error-holder"},_vm._l((_vm.getAllErrors(_vm.errors, validationErrors)),function(error){return _c('div',{key:error,staticClass:"FieldHolder__error-message"},[_vm._v(" "+_vm._s(_vm.validateAndDisplayError(error))+" ")])}),0):_vm._e(),_c('div',{class:{
				FieldHolder__holder: true,
				'FieldHolder__holder--no-label': !_vm.computedLabel,
			}},[(!_vm.readonly)?_c('div',[_vm._t("default")],2):(_vm.readonly)?_c('div',{staticClass:"FieldHolder__readonly",attrs:{"id":_vm.id}},[_vm._v(" "+_vm._s(_vm.isNotEmpty(_vm.value) ? _vm.value : "(empty)")+" "),_c('input',{attrs:{"type":"hidden"},domProps:{"value":_vm.value}})]):_vm._e()])])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }