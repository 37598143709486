var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"HeaderNav__root"},[_c('div',[_c('ul',{staticClass:"HeaderNav__list"},[_vm._m(0),_vm._l((this.leftMenuRoutesForUser),function(route){return _c('li',{key:route.name,staticClass:"HeaderNav__list-item"},[_c('router-link',{attrs:{"to":route},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{class:{
							HeaderNav__link: true,
							'HeaderNav__link-active': isActive,
						},attrs:{"href":href,"title":route.name,"data-cy":'NavBarLink-' +
							route.name +
							"" + (_vm.isInsightPage ? '-insight' : '')},on:{"click":navigate}},[_c('span',{staticClass:"HeaderNav__link-text"},[_c('font-awesome-icon',{attrs:{"icon":route.meta.icon,"size":"lg"}}),_vm._v(" "+_vm._s(route.name)+" ")],1)])]}}],null,true)})],1)})],2)]),_c('div',{staticClass:"HeaderNav__right-container"},[_c('ul',{staticClass:"HeaderNav__list"},[_vm._l((this.rightMenuRoutesForUser),function(route){return _c('li',{key:route.name,staticClass:"HeaderNav__list-item",attrs:{"data-cy":"TopMenuItem"}},[_c('router-link',{attrs:{"to":route},scopedSlots:_vm._u([{key:"default",fn:function(ref){
							var href = ref.href;
							var route = ref.route;
							var navigate = ref.navigate;
							var isActive = ref.isActive;
return [_c('a',{class:{
							HeaderNav__link: true,
							'HeaderNav__link-active': isActive,
						},attrs:{"href":href,"title":route.name,"data-cy":'NavBarLink-' +
							route.name +
							"" + (_vm.isInsightPage ? '-insight' : '')},on:{"click":navigate}},[_c('span',{staticClass:"HeaderNav__link-text"},[_c('font-awesome-icon',{attrs:{"icon":route.meta.icon,"size":"lg"}}),_vm._v(" "+_vm._s(route.name)+" ")],1)])]}}],null,true)})],1)}),_c('li',{staticClass:"HeaderNav__list-item"},[_c('DropdownMenu',{attrs:{"right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('font-awesome-icon',{attrs:{"data-cy":'user-icon' +
								"" + (_vm.isInsightPage ? '-insight' : ''),"icon":['fal', 'user'],"size":"lg"}})]},proxy:true}])},[(_vm.currentUserName)?_c('DropdownMenuHeader',{attrs:{"data-cy":'userName' +
							"" + (_vm.isInsightPage ? '-insight' : '')}},[_vm._v(" "+_vm._s(_vm.currentUserName)+" ")]):_vm._e(),_c('DropdownMenuItem',{attrs:{"data-cy":'user-profile' +
							"" + (_vm.isInsightPage ? '-insight' : '')},on:{"click":_vm.navigateToProfile}},[_vm._v(" Profile ")]),_c('DropdownMenuItem',{on:{"click":_vm.logout}},[_vm._v(" Sign Out ")])],1)],1)],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"HeaderNav__list-item"},[_c('span',{staticClass:"HeaderNav__Icon"},[_c('div',{staticClass:"HeaderNav__logo",attrs:{"alt":"logo"}})])])}]

export { render, staticRenderFns }