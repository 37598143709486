import { Module, Store } from "vuex";
import { RootState } from "@/store/root";
import { getters } from "@/store/modules/insight/getters";
import { mutations } from "@/store/modules/insight/mutations";
import { InsightState } from "@/store/modules/insight/insightStoreTypes";

export const INSIGHTS_STORE = "insight";

export const getDefaultInsightState: () => Readonly<InsightState> = () => ({
	oneLiners: [],
	batchTotalData: [],
	fundTotalData: [],
});

export const insight: Module<InsightState, RootState> = {
	namespaced: true,
	state: getDefaultInsightState(),
	getters,
	mutations,
};

export const registerModule = (store: Store<any>) => {
	if (!store.hasModule(INSIGHTS_STORE)) {
		store.registerModule(INSIGHTS_STORE, insight);
	}
};
export const commitToModule = (store: Store<any>, insight: any) => {
	store.commit(`${INSIGHTS_STORE}/setInsight`, insight);
};
