





































import Vue from "vue";
import { Component } from "vue-property-decorator";
import Grid from "@/grid/Grid.vue";
import LeftRightFooter from "@/components/LeftRightFooter.vue";
import ProgressArrow from "@/components/ProgressArrow.vue";
import { RoutePath } from "@/router/routePath";
import { AppRoute, AppRouteNextFunction } from "@/router";
import Button from "@/form/Button.vue";
import Container from "@/components/Container.vue";
import Form from "@/form/Form.vue";
import TextField from "@/form/TextField.vue";
import FieldGroup from "@/form/FieldGroup.vue";
import DatepickerField from "@/form/DatepickerField.vue";
import Layout from "@/components/Layout.vue";
import ErrorList from "@/components/ErrorList.vue";
import { parseErrorMessageList } from "@/utils/ErrorUtils";
import ContributionSummaryComponent from "@/pages/contribution/ContributionSummaryComponent.vue";
import ContributionResource, {
	ContributionSummary,
	fetchRelativeParentBatchId,
} from "@/rest/ContributionResource";
import PageHeader from "@/components/PageHeader.vue";
import { getSteps, enableFileAndWarningsStep } from "@/constants/pageConstants";
import PaymentDetail from "@/components/PaymentDetail.vue";

interface PaymentDetailsBundle {
	contributionSummary?: ContributionSummary;
	relativeParentBatchId?: number;
	errors?: string[];
}

@Component({
	components: {
		PaymentDetail,
		PageHeader,
		Button,
		Container,
		ContributionSummaryComponent,
		DatepickerField,
		ErrorList,
		FieldGroup,
		Form,
		Grid,
		Layout,
		LeftRightFooter,
		ProgressArrow,
		TextField,
	},
})
export default class PaymentDetailsPage extends Vue {
	private batchId = 0;

	private readonly title = "Payment details";

	private steps = getSteps(this.title);

	private DBBypassFlag = false;

	private errors: string[] | null = null;
	private contributionSummary: ContributionSummary | null = null;
	private relativeParentBatchId: number | null = null;

	async beforeRouteEnter(
		to: AppRoute,
		from: AppRoute,
		next: AppRouteNextFunction<PaymentDetailsPage>
	) {
		const id = Number(to.params.id);
		if (!id || isNaN(id)) {
			// Redirect to 404 if ID is zero or NaN
			next({
				name: "Not Found",
			});
			return;
		}
		const r = await PaymentDetailsPage.beforeRouteEnterOrUpdate(id);

		next((vm) => {
			vm.batchId = id;
			vm.DBBypassFlag =
				r.contributionSummary?.contributionFileType === "DBBYP";
			vm.setResponse(r);
		});
	}

	static async beforeRouteEnterOrUpdate(
		batchId: number
	): Promise<PaymentDetailsBundle> {
		let contributionSummary: ContributionSummary | undefined;
		let relativeParentBatchId: number | undefined;
		const errors: string[] = [];
		await Promise.all([
			ContributionResource.getContributionSummary(batchId)
				.then((resp) => {
					contributionSummary = resp.data;
				})
				.catch((e: any) => {
					errors.push(...parseErrorMessageList(e));
				}),
			fetchRelativeParentBatchId(batchId)
				.then((resp) => {
					relativeParentBatchId = resp;
				})
				.catch((e: any) => {
					errors.push(...parseErrorMessageList(e));
				}),
		]);

		return {
			contributionSummary,
			relativeParentBatchId,
			errors,
		};
	}

	private setResponse(data: PaymentDetailsBundle) {
		this.contributionSummary = data.contributionSummary
			? data.contributionSummary
			: null;
		this.relativeParentBatchId = data.relativeParentBatchId
			? data.relativeParentBatchId
			: null;
		if (data.contributionSummary) {
			enableFileAndWarningsStep(
				this.steps,
				data.contributionSummary.withFileLevelErrorWarning
			);
		}
		if (data.errors && data.errors.length > 0) {
			this.errors = data.errors;
		}
	}

	private onClickClose(): void {
		this.$router.push(RoutePath.Dashboard);
	}

	private goToRelativeBatch(): void {
		this.$router.push(
			RoutePath.FileErrorPage.replace(
				"/:id",
				"/" + this.relativeParentBatchId
			)
		);
	}
}
