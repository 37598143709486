





















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import Modal, { ModalProps } from "@/components/Modal.vue";
import Popup, { PopupProps } from "@/components/Popup.vue";

@Component({
	components: {
		Modal,
		Popup,
	},
})
export default class ModalOrPopup
	extends Vue
	implements ModalProps, PopupProps
{
	@Prop({ type: Boolean, default: false }) popup!: boolean;

	@Prop(String) title!: string;

	@Prop({ type: String, default: "sm" }) size!: "md" | "sm" | "fill" | "lg";

	@Prop({ type: Boolean, default: false }) fullHeight!: boolean;

	@Prop({ type: String, default: "Close" }) closeButtonName!: string;

	@Prop({ type: Boolean, default: false }) closeButtonDisabled!: boolean;

	private onClose(): void {
		this.$emit("close");
	}

	/**
	 * closeEvent helps us only bind the @click event on the element if there is
	 * a listener.
	 *
	 * Source:
	 * - https://github.com/vuejs/vue/issues/7349#issuecomment-621699548
	 * - https://jsfiddle.net/pbastowski/v0wt5qpo/63/
	 */
	get closeEvent(): "close" | null {
		return this.$listeners && this.$listeners.close ? "close" : null;
	}
}
