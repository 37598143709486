





























import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import EmployerSelector from "@/components/EmployerSelector.vue";
import axios from "@/utils/ApiUtils";
import {
	canUserSeeInsightsMenu,
	insightOneLiners,
	insightOneLinersForEntity,
} from "@/constants/apiconstants";
import Card from "@/components/Card.vue";
import InsightOneLiner from "@/components/InsightOneLiner.vue";
import { mapMutations, mapState } from "vuex";
import QuicksightDashboard from "@/components/QuicksightDashboard.vue";
import { toastErrorMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";

interface InsightType {
	message: string;
	priority: number;
}

@Component({
	components: {
		Layout,
		EmployerSelector,
		Card,
		InsightOneLiner,
		QuicksightDashboard,
	},
	computed: mapState(["selectedEntitiesInsight", "employerHierarchy"]),
	methods: {
		...mapMutations(["setSelectedEntitiesInsight"]),
	},
})
export default class InsightPage extends Vue {
	private oneLiners: InsightType[] = [];
	private errorMessage: string | null = null;
	setSelectedEntitiesInsight!: (selectedEntities: string[]) => void;
	private entity: string | null = null;
	private quicksightDashboardKey = 0;

	clearErrorMessage() {
		this.errorMessage = null;
	}

	get componentActive() {
		return this.$route.path === "/insight";
	}

	mounted() {
		axios.get(canUserSeeInsightsMenu()).then((res) => {
			if (res.data) {
				axios
					.get(insightOneLiners())
					.then((resp) => (this.oneLiners = resp.data));
			}
		});
	}

	get selectedReportingCenter() {
		return this.$store.state.persistent.selectedEntitiesInsight;
	}

	@Watch("selectedReportingCenter")
	onSelectedReportingCenterChanged() {
		if (!this.componentActive) {
			return;
		}
		if (
			this.selectedReportingCenter.length !== 0 &&
			this.entity !== this.selectedReportingCenter[0]
		) {
			//changing key will force component to re-render
			this.quicksightDashboardKey++;
			this.entity = this.selectedReportingCenter[0];
			axios
				.get(insightOneLinersForEntity(), {
					params: {
						entities: this.selectedReportingCenter,
					},
				})
				.then((resp) => {
					this.oneLiners = resp.data;
				})
				.catch((error) => {
					toastErrorMessage(
						parseErrorMessage(error) +
							" or no reporting centers under entity"
					);
				});
		}
	}
}
