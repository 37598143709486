import { padEnd } from "lodash-es";

type LogType =
	| "group"
	| "groupCollapsed"
	| "groupEnd"
	| "log"
	| "debug"
	| "warn"
	| "error"
	| "trace";

/**
 * 🦄 Clearing House Portal Logger 🦄
 * Only logs errors on PRODUCTION mode
 *
 * This is just a simple logger with limited functionalities:
 * - Delegates log level to console log
 * - Only logs errors on PRODUCTION mode
 *
 * It is unlike a more used logger for vue
 * https://github.com/justinkames/vuejs-logger
 *
 * It is a modified copy from Acurity WebUI logger. It is credited to
 * - Antonn Esquivel
 * - Jae Bentvelzen
 * - York Chan
 */
// tslint:disable no-console
/* eslint-disable no-console */
export class Logger {
	/**
	 * Identifier for the logger
	 */
	private static readonly MARKER = "🦄";

	/**
	 * Length of log prefix, usually the caller's class name
	 */
	private static readonly logPrefixWidth: number = 20;

	public static addPrefix(prefix: string, ...extras: any[]) {
		return (
			this.MARKER +
			" " +
			padEnd(prefix, this.logPrefixWidth) +
			extras.join(" ")
		);
	}

	/**
	 * Returns a bound console method
	 */
	public static logToConsole: (
		logType: LogType,
		prefix?: string
	) => (message?: any, ...optionalParams: any[]) => void = (
		logType: LogType,
		prefix: string = Logger.MARKER
	) => {
		if (
			window.console &&
			(process.env.NODE_ENV === "development" ||
				process.env.NODE_ENV === "test" ||
				logType === "error")
		) {
			return prefix.trim()
				? Function.prototype.bind.call(
						console[logType],
						console,
						prefix
				  ) // eslint-disable-line no-mixed-spaces-and-tabs
				: Function.prototype.bind.call(console[logType], console);
		}
		// NOTE: Jake: 2019-03-13 - WEBUI-1659
		// Do not change this return without great care.
		// Fixing this to return "void" rather than an empty JS Object took
		// hours to fix.

		// eslint-disable-next-line
		return (): void => {};
	};

	/**
	 * `console.log`
	 */
	public static log = Logger.logToConsole("log");

	/**
	 * `console.debug`
	 */
	public static debug = Logger.logToConsole("debug");

	/**
	 * `console.warn`
	 */
	public static warn = Logger.logToConsole("warn");

	/**
	 * `console.warn`
	 */
	public static warning = Logger.logToConsole("warn");

	/**
	 * `console.error`
	 */
	public static error = Logger.logToConsole("error");

	/**
	 * `console.trace`
	 */
	public static trace = Logger.logToConsole("trace");

	/**
	 * `console.group`
	 */
	public static group(groupName: string, stuff: any[], collapsed = false) {
		if (
			process.env.NODE_ENV === "development" &&
			window.console &&
			stuff.length
		) {
			collapsed
				? Logger.logToConsole("groupCollapsed", "")(groupName)
				: Logger.logToConsole("group", "")(groupName);
			const indent = " ".repeat(this.logPrefixWidth + 2);
			stuff.forEach((s) => {
				Array.isArray(s)
					? Logger.logToConsole("log", "")(indent, ...s)
					: Logger.logToConsole("log", "")(indent, s);
			});
			Logger.logToConsole("groupEnd", "")();
		}
	}
}
