



































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import Button from "@/form/Button.vue";
import axios from "@/utils/ApiUtils";
import {
	saveReportingCentre,
	searchReportingCentre,
	retrieveReportingCentrePaymentProfileOptions,
	retrieveReportingCentrePaymentProfile,
	retrievePaymentProfileByMemberId,
} from "@/constants/apiconstants";
import {
	ReportingCentreDetail,
	PaymentProfile,
	PaymentProfileRC,
} from "@/pages/maintenanceTypes";
import {
	toastErrorMessage,
	toastInfoMessage,
	toastSuccessMessage,
} from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import { VForm } from "@/@typings/type-vee-validate";
import ReportingCentreDetails from "@/components/ReportingCentreDetails.vue";
import { isEqual } from "lodash-es";
import ModalWithSaveAndCancel from "@/components/ModalWithSaveAndCancel.vue";
import PaymentProfileSection from "@/components/PaymentProfileSection.vue";
import { SelectOption } from "@/form/FieldOptions";
import PaymentProfileDetails from "@/components/PaymentProfileDetails.vue";
import PaymentProfileMaintenance from "@/components/PaymentProfileMaintenance.vue";
import { hasPermission } from "@/utils/PermissionUtils";
import { Logger } from "@/utils/logger";

@Component({
	components: {
		PaymentProfileDetails,
		PaymentProfileSection,
		ReportingCentreDetails,
		Button,
		ModalWithSaveAndCancel,
		PaymentProfileMaintenance,
	},
})
export default class EditReportingCentre extends Vue {
	private storedRCDetail: ReportingCentreDetail | null = null;
	private rcDetail: ReportingCentreDetail | null = null;
	private showCancelModal = false;
	private paymentProfileOptions: SelectOption[] | null = null;
	private memberId: string | null = null;
	private paymentProfileRC: PaymentProfileRC | null = null;
	private reportingCentreNames: { name: string }[] = [];
	private memberIdForPaymentProfile: string | undefined = undefined;
	private showPaymentProfileModal = false;

	/**
	 * Indiate whether form is editing and contains changes
	 */
	private isFormDirty = false;

	@Prop({ type: Number, required: true, default: null })
	readonly reportingCentreId!: number | null;

	async saveReportingCentre() {
		if (
			this.storedRCDetail &&
			isEqual(this.storedRCDetail, this.rcDetail)
		) {
			toastInfoMessage(
				"No changes have been made to this record, save not required"
			);
			return;
		}

		if (await (this.$refs.reportingCentreForm as VForm).validate()) {
			axios
				.put(saveReportingCentre(), this.rcDetail)
				.then((response) => {
					if (this.rcDetail) {
						this.storedRCDetail = { ...this.rcDetail };
					}
					this.isFormDirty = false;
					this.$emit("save");
					toastSuccessMessage("Reporting centre saved successfully");
				})
				.catch((error) => {
					toastErrorMessage(parseErrorMessage(error));
				});
		}
	}

	private discardChanges() {
		this.isFormDirty = false;
		this.$emit("cancel");
	}

	private closeModal() {
		this.showCancelModal = false;
	}

	get isRCDetailsChanged() {
		return (
			this.storedRCDetail !== null &&
			!isEqual(this.rcDetail, this.storedRCDetail)
		);
	}

	private cancel() {
		if (this.isRCDetailsChanged) {
			this.showCancelModal = true;
		} else {
			this.discardChanges();
		}
	}

	@Watch("reportingCentreId", { immediate: true })
	fetchReportingCentreDetailsOnChange(value: number | null) {
		if (value !== null) {
			this.fetchReportingCentreDetail(value);
			this.fetchPaymentProfileOptions(value);
			this.fetchPaymentProfile(value);
		} else {
			this.rcDetail = null;
		}
	}

	updateReportingCentreDetail(rcDetail: ReportingCentreDetail) {
		if (rcDetail) {
			this.rcDetail = rcDetail;

			if (!this.isFormDirty && this.isRCDetailsChanged) {
				this.isFormDirty = true;
				this.$emit("form-changed");
			}
		}
	}

	updatePaymentProfile(memberId: string) {
		if (!this.rcDetail || !this.rcDetail.employerId || !memberId) {
			Logger.warn(
				"updatePaymentProfile",
				"Missing employer id or member id."
			);
			return;
		}
		this.memberId = memberId;
		this.rcDetail.paymentProfileMemberId = memberId;
		this.fetchPaymentProfileByMemberId(
			this.rcDetail.employerId,
			this.memberId,
			this.reportingCentreId
		);
	}

	fetchPaymentProfileByMemberId(
		employerId: number,
		memberId: string,
		reportingCentreId: number | null
	) {
		axios
			.get<PaymentProfileRC>(
				retrievePaymentProfileByMemberId(
					employerId,
					memberId,
					reportingCentreId ? reportingCentreId : 0
				)
			)
			.then((response) => {
				this.paymentProfileRC = response.data;
				if (this.paymentProfileRC.paymentProfile) {
					this.memberId = this.paymentProfileRC.paymentProfile.memberId;
					this.reportingCentreNames = this.paymentProfileRC?.reportingCentres.map(
						(name) => {
							return { name };
						}
					);
				}
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
	}

	private async fetchReportingCentreDetail(
		reportingCentreId: number
	): Promise<void> {
		await axios
			.get<ReportingCentreDetail>(
				searchReportingCentre(reportingCentreId)
			)
			.then((response) => {
				this.rcDetail = response.data;
				this.storedRCDetail = { ...this.rcDetail };
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
	}

	async fetchPaymentProfileOptions(reportingCentreId: number): Promise<void> {
		await axios
			.get<PaymentProfile[]>(
				retrieveReportingCentrePaymentProfileOptions(reportingCentreId)
			)
			.then((response) => {
				this.paymentProfileOptions = response.data.map(
					(paymentProfile: PaymentProfile) => ({
						value: paymentProfile.memberId,
						label: paymentProfile.nickname,
					})
				);
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
	}

	async fetchPaymentProfile(reportingCentreId: number): Promise<void> {
		await axios
			.get<PaymentProfileRC>(
				retrieveReportingCentrePaymentProfile(reportingCentreId)
			)
			.then((response) => {
				this.paymentProfileRC = response.data;
				if (this.paymentProfileRC.paymentProfile) {
					this.memberId = this.paymentProfileRC.paymentProfile.memberId;
					this.reportingCentreNames = this.paymentProfileRC.reportingCentres.map(
						(name) => ({ name })
					);
				}
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
	}

	addPaymentProfile() {
		if (this.rcDetail && this.rcDetail.employerId) {
			this.memberIdForPaymentProfile = undefined;
			this.showPaymentProfileModal = true;
		}
	}

	editPaymentProfile(param: { memberId: string }) {
		if (this.rcDetail && this.rcDetail.employerId) {
			this.memberIdForPaymentProfile = param.memberId;
			this.showPaymentProfileModal = true;
		}
	}

	onClosePaymentProfileModal() {
		this.memberIdForPaymentProfile = undefined;
		this.showPaymentProfileModal = false;
	}

	onSavePaymentProfile() {
		if (this.reportingCentreId !== null) {
			this.fetchPaymentProfileOptions(this.reportingCentreId);
			this.fetchPaymentProfile(this.reportingCentreId);
		}
	}

	get readonly() {
		return !hasPermission("EDIT_EMPLOYER");
	}
}
