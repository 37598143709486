















































































































































































































































































































































import { cloneDeep } from "lodash-es";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import EmployerSelector from "@/components/EmployerSelector.vue";
import { TSelectLevel } from "@/components/employerSelectorTypes";
import Form from "@/form/Form.vue";
import TextField from "@/form/TextField.vue";
import FieldGroup from "@/form/FieldGroup.vue";
import axios from "@/utils/ApiUtils";
import { addFundURL, addSmsfURL } from "@/constants/apiconstants";
import Button from "@/form/Button.vue";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import { VForm } from "@/@typings/type-vee-validate";
import { toastErrorMessage, toastSuccessMessage } from "@/plugins/toasts";
import { getRcIdFromSelection } from "@/utils/EmployerSelectorUtils";
import {
	ContributionFileType,
	FundType,
	SearchFundDetails,
} from "@/pages/fundTypes";
import ModalWithSaveAndCancel from "@/components/ModalWithSaveAndCancel.vue";
import SelectField from "@/form/SelectField.vue";
import RadioGroup from "@/form/RadioGroup.vue";
import MultiSelect from "@/form/MultiSelect.vue";
import SearchFund from "@/components/SearchFund.vue";
import { SelectOption } from "@/form/FieldOptions";
import { hasPermission } from "@/utils/PermissionUtils";
import { createNamespacedHelpers } from "vuex";

const { mapState } = createNamespacedHelpers("persistent");

@Component({
	components: {
		MultiSelect,
		RadioGroup,
		ModalWithSaveAndCancel,
		FieldGroup,
		TextField,
		Form,
		Layout,
		EmployerSelector,
		SearchFund,
		Button,
		SelectField,
	},
	computed: mapState(["definedBenefitEntities"]),
})
export default class AddFundPage extends Vue {
	private pageContext: TSelectLevel = "RC";
	private multiSelect = false;
	private defaultFundOptions = ["Yes", "No"];
	private selectedDefaultFund = "No";
	/**
	 * fund type value
	 */
	private fundType: FundType = FundType.APRA;
	private selectedFund: SearchFundDetails | null = null;
	private fen = "";
	private canAddDefaultFund = true;
	private showCancelModal = false;
	definedBenefitEntities!: string[];

	beforeMount() {
		const rcId = getRcIdFromSelection(this.$store);
		if (!rcId) {
			throw new Error("Can't get selected reporting centre");
		}
	}

	private getRcIdFromSelection(): number | undefined {
		return getRcIdFromSelection(this.$store);
	}

	get shouldShowApraForm(): boolean {
		return Boolean(
			this.selectedFund &&
				this.selectedFund.fundAbn &&
				this.fundType === FundType.APRA
		);
	}
	get shouldShowSmsfForm(): boolean {
		return Boolean(
			this.selectedFund &&
				this.selectedFund.fundAbn &&
				this.fundType === FundType.SMSF
		);
	}
	get shouldShowSchemeForm(): boolean {
		return Boolean(
			this.selectedFund &&
				this.selectedFund.fundAbn &&
				this.fundType === FundType.SCHEME
		);
	}
	get shouldShowSubmitButton(): boolean {
		if (this.selectedFund === null) {
			return false;
		}

		return (
			(this.shouldShowApraForm && this.selectedFund.active) ||
			(this.shouldShowSchemeForm && this.selectedFund.active) ||
			// Submit button is not clickable, but still visible, if fund is not complying.
			// CHSN-528.
			this.shouldShowSmsfForm
		);
	}
	get isFundActive(): string {
		return this.selectedFund && this.selectedFund.active
			? "Active"
			: "Inactive";
	}
	get esaOptions(): SelectOption[] {
		if (!this.selectedFund || this.fundType !== FundType.SMSF) {
			return [{ value: "", label: "" }];
		}
		return Object.keys(this.selectedFund.smsfAliasMap).map((alias) => ({
			value: alias,
			label: alias,
		}));
	}
	get smsfStatusErrors(): string[] {
		if (
			this.shouldShowSmsfForm &&
			this.selectedFund?.complyingStatus !== "Complying"
		) {
			return [
				"The status of this SMSF is not complying, you cannot add this SMSF. Please confirm details with your employee.",
			];
		}

		return [];
	}
	private async addFund() {
		if (!this.selectedFund) {
			return;
		}
		const selectedFund: SearchFundDetails = this
			.selectedFund as SearchFundDetails;

		if (
			this.shouldShowApraForm &&
			(await (this.$refs.fundForm as VForm).validate())
		) {
			const fundDetails = {
				reportingCentreId: String(getRcIdFromSelection(this.$store)),
				abn: selectedFund.fundAbn,
				usi: selectedFund.fundUsi,
				fen: this.fen,
				payeeCode: selectedFund.payeeCode,
				defaultFund: this.selectedDefaultFund,
			};

			axios
				.post(addFundURL(), fundDetails)
				.then((response) => {
					toastSuccessMessage(
						`Added Fund ${selectedFund.fundName} - ${selectedFund.fundUsi}`
					);
					this.$router.push({ name: "Funds" });
				})
				.catch((error) => {
					toastErrorMessage(parseErrorMessage(error));
				});
		}
		if (
			this.shouldShowSmsfForm &&
			(await (this.$refs.smsfForm as VForm).validate())
		) {
			const fundDetails = {
				reportingCentreId: String(getRcIdFromSelection(this.$store)),
				abn: selectedFund.fundAbn,
				payeeCode: selectedFund.payeeCode,
				bsb: selectedFund.fundBsb,
				accountName: selectedFund.fundAccountName,
				accountNumber: selectedFund.fundAccountNumber,
				esa: selectedFund.fundElectronicServiceAddress,
			};
			axios
				.post(addSmsfURL(), fundDetails)
				.then((response) => {
					toastSuccessMessage(
						`Added SMSF ${selectedFund.fundName} - ${selectedFund.fundAbn}`
					);
					this.$router.push({ name: "Funds" });
				})
				.catch((error) => {
					toastErrorMessage(parseErrorMessage(error));
				});
		}
		if (
			this.shouldShowSchemeForm &&
			(await (this.$refs.schemeForm as VForm).validate())
		) {
			const fundDetails = {
				reportingCentreId: String(getRcIdFromSelection(this.$store)),
				abn: selectedFund.fundAbn,
				usi: selectedFund.fundUsi,
				fen: this.fen,
				payeeCode: selectedFund.payeeCode,
				defaultFund: this.selectedDefaultFund,
			};

			axios
				.post(addFundURL(), fundDetails)
				.then(() => {
					toastSuccessMessage(
						`Added Scheme ${selectedFund.fundName} - ${selectedFund.fundUsi}`
					);
					this.$router.push({ name: "Funds" });
				})
				.catch((error) => {
					toastErrorMessage(parseErrorMessage(error));
				});
		}
	}
	private cancel() {
		if (this.shouldShowApraForm) {
			if (this.fen === "" && this.selectedDefaultFund === "No") {
				this.$router.push({ name: "Funds" });
			} else {
				this.showCancelModal = true;
			}
		} else if (this.shouldShowSmsfForm) {
			if (
				!this.selectedFund ||
				(this.selectedFund &&
					this.selectedFund.fundElectronicServiceAddress === "" &&
					this.selectedFund.fundAccountNumber === "" &&
					this.selectedFund.fundAccountName === "" &&
					this.selectedFund.fundBsb === "")
			) {
				this.$router.push({ name: "Funds" });
			} else {
				this.showCancelModal = true;
			}
		} else {
			this.$router.push({ name: "Funds" });
		}
	}
	private discardChanges() {
		this.$router.push({ name: "Funds" });
	}
	private closeModal() {
		this.showCancelModal = false;
	}

	private update(value: SearchFundDetails) {
		this.selectedFund = cloneDeep(value);
	}

	get hasFundSensitivePermission(): boolean {
		if (hasPermission("EDIT_FUND_SENSITIVE")) {
			return true;
		}
		return false;
	}

	get contributionFileType() {
		const rcId = this.getRcIdFromSelection();
		if (rcId && this.definedBenefitEntities.includes("R_" + rcId)) {
			return ContributionFileType.ALL;
		}
		return ContributionFileType.SUPERSTREAM;
	}
}
