
import { CreateElement } from "vue";

type ObserverErrors = Record<string, string[]>;

/**
 * vee-validate ValidationObserver provides does not emit any events. Parent can only receive scoped slot props (i.e errors)
 * and use them within ValidationObserver default slot content. This is a helper functional component to emit event whenever
 * validation errors from slot props is changed.
 * ```
 * <ValidationObserver tag="div" v-slot="{ errors }">
 *   <ValidationObserverNotifier :errors="errors" @validation-observer-errors-changed="onValidationObserverErrorsChanged" />
 * </ValidationObserver>
 * ```
 */
export default {
	name: "ValidationObserverNotifier",
	functional: true,
	props: {
		errors: {
			type: Object,
		},
	},
	/**
	 * props changes result in re-render. Call the binded listener `validation-observer-errors-changed` (similar to emit
	 * event in non-functional component)
	 */
	render(createElement: CreateElement, context: any) {
		const { listeners, props } = context;
		const onValidationObserverErrorsChanged: Function =
			listeners["validation-observer-errors-changed"];
		if (onValidationObserverErrorsChanged) {
			onValidationObserverErrorsChanged(props.errors);
		}
		// unused vnode
		return createElement("div", context.children);
	},
};
