

















































import Vue from "vue";
import { debounce } from "lodash-es";
import { Component, Prop, Watch } from "vue-property-decorator";
import { AxiosRequestConfig } from "axios";

import axios from "@/utils/ApiUtils";
import {
	getDefaultApraFundsForRCURL,
	getFundsForEmployee,
	getFundsForRCURL,
	searchFundURL,
} from "@/constants/apiconstants";
import TextField from "@/form/TextField.vue";
import Button from "@/form/Button.vue";
import RadioGroup from "@/form/RadioGroup.vue";
import { RadioOption } from "@/form/FieldOptions";
import MultiSelect from "@/form/MultiSelect.vue";
import CheckBox from "@/form/CheckBox.vue";
import {
	ContributionFileType,
	FundDetails,
	FundResponse,
	FundResponses,
	FundType,
	SearchFundDetails,
	SearchFundRequest,
	TFundResponse,
	TSmsfResponse,
} from "@/pages/fundTypes";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import { toastErrorMessage, toastWarningMessage } from "@/plugins/toasts";

@Component({
	components: {
		Button,
		CheckBox,
		MultiSelect,
		RadioGroup,
		TextField,
	},
	model: {
		event: "update",
	},
})
export default class SearchFund extends Vue {
	@Prop({ type: Number, default: null })
	readonly reportingCentreId!: number | null;

	@Prop(String)
	readonly fundType!: FundType | undefined;

	@Prop(Number)
	readonly employeeId!: number | undefined;

	@Prop({ type: Object })
	readonly value!: FundDetails;

	@Prop({ type: Boolean })
	readonly hideFundTypeSelect!: boolean;

	@Prop(Boolean)
	readonly hideDefaultFilter!: boolean;

	// True - Fund Drop down will show Funds not associated with the RC
	@Prop(Boolean)
	readonly showOtherRcFunds!: boolean;
	@Prop(Boolean)
	readonly readonly!: boolean;
	@Prop({ type: Boolean, default: false })
	readonly fundTypeReadonly!: boolean;

	@Prop({ type: String, default: "post" })
	readonly fetchMethod!: "get" | "post";

	@Prop({ type: String, default: searchFundURL() })
	readonly apiUrl!: string;

	@Prop(Object)
	readonly customRequestHeaders!: object | undefined;

	@Prop({ type: String, default: ContributionFileType.SUPERSTREAM })
	readonly contributionFileType!:
		| ContributionFileType.SUPERSTREAM
		| ContributionFileType.DB_BYPASS
		| ContributionFileType.ALL;

	// With this state, fundType is not a required prop. If fundType is provided, this
	// state is synced with fundType.
	private internalFundType = FundType.APRA;
	private defaultFundsMode = false;

	private isLoadingFromRemote = false;
	private fundOptionsForQuery: FundResponse[] = [];
	private defaultFundOptionsRelatedToRc: FundResponse[] = [];
	private fundOptionsRelatedToRc: FundResponses | null = null;
	private fundOptionsRelatedToEmployee: FundResponses | null = null;

	private selectedFund: FundResponse | null = null;
	// DO NOT alter this state other than in the search event handler.
	// It's here to determine if the user is searching for a fund.
	private searchFundQuery = "";
	private shouldEmitUpdate = true;

	private get isAPRAFund(): boolean {
		return this.internalFundType === FundType.APRA;
	}

	private get isSchemeFund(): boolean {
		return this.internalFundType === FundType.SCHEME;
	}

	private get isSMSFFund(): boolean {
		return this.internalFundType === FundType.SMSF;
	}

	private get propertyToTrackFundOptionsBy(): "fundUsi" | "abn" {
		return this.isAPRAFund || this.isSchemeFund ? "fundUsi" : "abn";
	}

	private get fundTypeOptions(): RadioOption[] {
		if (this.contributionFileType === ContributionFileType.DB_BYPASS) {
			return [{ text: FundType.SCHEME, value: FundType.SCHEME }];
		} else if (
			this.contributionFileType === ContributionFileType.SUPERSTREAM
		) {
			return [
				{ text: "APRA", value: FundType.APRA },
				{ text: "SMSF", value: FundType.SMSF },
			];
		}
		return [
			{ text: "APRA", value: FundType.APRA },
			{ text: "SMSF", value: FundType.SMSF },
			{ text: FundType.SCHEME, value: FundType.SCHEME },
		];
	}

	private get placeholder(): string {
		return `Select a Fund from the list / ${this.noOptionsHint}`;
	}

	private get noOptionsHint(): string {
		const searchQueryDescription =
			this.isAPRAFund || this.isSchemeFund ? "name, USI or ABN" : "ABN";

		return `Type ${searchQueryDescription} to search`;
	}

	private get fundOptions(): FundResponse[] {
		if (this.defaultFundsMode) {
			return this.defaultFundOptionsRelatedToRc;
		} else if (
			this.fundOptionsForQuery.length > 0 ||
			this.searchFundQuery
		) {
			return this.fundOptionsForQuery;
		} else if (this.fundOptionsRelatedToEmployee !== null) {
			return this.isAPRAFund
				? this.fundOptionsRelatedToEmployee.apraResponses
				: this.isSchemeFund
				? this.fundOptionsRelatedToEmployee.schemeResponses
				: this.fundOptionsRelatedToEmployee.smsfResponses;
		} else if (this.fundOptionsRelatedToRc !== null) {
			return this.isAPRAFund
				? this.fundOptionsRelatedToRc.apraResponses
				: this.isSchemeFund
				? this.fundOptionsRelatedToRc.schemeResponses
				: this.fundOptionsRelatedToRc.smsfResponses;
		}
		return [];
	}

	private handleSearch(query: string) {
		// DO NOT alter this state other than in here.
		// It's here to determine if the user is searching for a fund.
		this.searchFundQuery = query;
		this.debouncedSearchFund(query);
	}

	private debouncedSearchFund: (query: string) => void = debounce(
		this.searchFund,
		200
	);

	private getDefaultFundsForRC() {
		if (!this.reportingCentreId) {
			return;
		}

		this.defaultFundOptionsRelatedToRc = [];

		axios
			.get<FundResponse[]>(
				getDefaultApraFundsForRCURL(this.reportingCentreId)
			)
			.then((response) => {
				this.defaultFundOptionsRelatedToRc = response.data;
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
	}

	private getFundsRelatedToRC() {
		if (!this.reportingCentreId) {
			return;
		}

		this.fundOptionsRelatedToRc = null;

		axios
			.get<FundResponses>(getFundsForRCURL(this.reportingCentreId))
			.then((response) => {
				this.fundOptionsRelatedToRc = response.data;
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
	}

	private getFundsRelatedToEmployee() {
		if (!this.reportingCentreId || this.employeeId === undefined) {
			return;
		}

		this.fundOptionsRelatedToEmployee = null;

		axios
			.get<FundResponses>(
				getFundsForEmployee(this.reportingCentreId, this.employeeId)
			)
			.then((response) => {
				this.fundOptionsRelatedToEmployee = response.data;
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
	}

	private getFundLabelFromFund(fundResponse: FundResponse): string | null {
		if (this.isAPRAFund || this.isSchemeFund) {
			const apraResponse: TFundResponse = fundResponse as TFundResponse;
			return `${apraResponse.fundName} - ${apraResponse.fundUsi} - ${apraResponse.fundAbn}`;
		}
		if (this.isSMSFFund) {
			const smsfResponse: TSmsfResponse = fundResponse as TSmsfResponse;
			const name = !smsfResponse.name ? "UNAVAILABLE" : smsfResponse.name;
			const abn = !smsfResponse.abn ? "UNAVAILABLE" : smsfResponse.abn;
			return `${name} - ${abn}`;
		}
		return null;
	}

	private searchFund(query: string): Promise<void> | undefined {
		if (this.defaultFundsMode) {
			// Default funds are fetched once.
			return;
		}

		this.fundOptionsForQuery = [];

		query = query.replace(/[-]/g, "");
		if (!query) {
			return;
		}

		this.isLoadingFromRemote = true;
		this.selectedFund = null;

		let requestData: SearchFundRequest = {
			abn: query,
			fundType: this.internalFundType,
			reportingCentreId: this.reportingCentreId,
			partialMatch: true,
			otherFunds: this.showOtherRcFunds,
		};
		if (this.isAPRAFund || this.isSchemeFund) {
			requestData = {
				...requestData,
				usi: query,
				fundName: query,
			};
		}

		const requestConfig: AxiosRequestConfig = {
			method: this.fetchMethod,
			url: this.apiUrl,
		};

		if (this.customRequestHeaders) {
			requestConfig.headers = this.customRequestHeaders;
		}

		if (requestConfig.method === "get") {
			requestConfig.params = requestData;
		} else {
			requestConfig.data = requestData;
		}

		return axios
			.request<FundResponse[]>(requestConfig)
			.then((response) => {
				this.fundOptionsForQuery = response.data;
				if (this.isSMSFFund && this.fundOptionsForQuery.length === 1) {
					const smsfFundResponse = this
						.fundOptionsForQuery[0] as TSmsfResponse;
					if (
						smsfFundResponse.status ===
							"Service unavailable. Please try again later." ||
						smsfFundResponse.complyingStatus ===
							"Service unavailable. Please try again later."
					) {
						toastWarningMessage(
							"Unable to verify details with the government's Super Fund lookup service. Please try later."
						);
					}
				}
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			})
			.finally(() => {
				this.isLoadingFromRemote = false;
			});
	}

	// WARNING (Ray): this watcher must be declared before the others.
	// Otherwise other watchers/computed properties will receive the wrong fund type.
	@Watch("fundType", { immediate: true })
	syncPropFundTypeToInternal(value: FundType | undefined) {
		if (value) {
			this.internalFundType = value;
		}
	}

	@Watch("isAPRAFund")
	maybeClearDefaultFundsMode(value: boolean) {
		if (!value) {
			this.defaultFundsMode = false;
		}
	}

	@Watch("defaultFundsMode")
	refreshFundOptionsWhenDefaultFundsModeChange(value: boolean) {
		this.selectedFund = null;

		if (!value) {
			this.fundOptionsForQuery = [];
		}
	}

	@Watch("reportingCentreId", { immediate: true })
	fetchRCFundsIfRCChanged(value: number) {
		// Only show fund related to RC
		if (!this.showOtherRcFunds && this.reportingCentreId) {
			this.getFundsRelatedToRC();
			this.getDefaultFundsForRC();
		}

		if (this.employeeId !== undefined) {
			this.getFundsRelatedToEmployee();
		}
	}

	@Watch("employeeId", { immediate: true })
	fetchEmployeeFundsIfEmployeeChanged(value: number | undefined) {
		if (value !== undefined) {
			this.getFundsRelatedToEmployee();
		}
	}

	@Watch("internalFundType")
	clearFundStatesAndEmitFundTypeUpdate(value: FundType) {
		this.fundOptionsForQuery = [];
		this.selectedFund = null;
		this.$emit("fund-type-update", value);
	}

	// Ask the main form to copy over the details of the newly selected fund.
	@Watch("selectedFund")
	updateFundDetails(fund: FundResponse | null) {
		if (!this.shouldEmitUpdate) {
			this.shouldEmitUpdate = true;
			return;
		}

		let eventPayload: SearchFundDetails = {
			fundName: "",
			fundAbn: "",
			fundUsi: "",
			superannuationFundGeneratedEmployerIdentifier: "",
			fundElectronicServiceAddress: "",
			fundBsb: "",
			fundAccountNumber: "",
			fundAccountName: "",
			payeeCode: "",
			status: "",
			complyingStatus: "",
			active: null,
			smsfAliasMap: {},
		};

		if (fund !== null) {
			if (this.isAPRAFund || this.isSchemeFund) {
				// Clear any SMSF fund related fields and provide the latest APRA fund details.
				const apraFundResponse = fund as TFundResponse;
				eventPayload = {
					fundName: apraFundResponse.fundName,
					fundAbn: apraFundResponse.fundAbn,
					fundUsi: apraFundResponse.fundUsi.trim(),
					superannuationFundGeneratedEmployerIdentifier:
						apraFundResponse.fen,
					payeeCode: apraFundResponse.payeeCode,
					active: apraFundResponse.active,
					fundElectronicServiceAddress: "",
					fundBsb: "",
					fundAccountNumber: null,
					fundAccountName: "",
					status: apraFundResponse.active ? "Active" : "Inactive",
					complyingStatus: "",
					smsfAliasMap: {},
				};
			}

			if (this.isSMSFFund) {
				// Clear any APRA fund related fields and provide the latest SMSF fund details.
				const smsfFundResponse = fund as TSmsfResponse;
				eventPayload = {
					fundName: smsfFundResponse.name,
					fundAbn: smsfFundResponse.abn,
					fundElectronicServiceAddress: smsfFundResponse.esa,
					fundBsb: smsfFundResponse.bsb,
					fundAccountNumber: smsfFundResponse.accountNumber,
					fundAccountName: smsfFundResponse.accountName,
					payeeCode: smsfFundResponse.payeeCode,
					status: smsfFundResponse.status,
					complyingStatus: smsfFundResponse.complyingStatus,
					smsfAliasMap: smsfFundResponse.smsfAliasMap,
					active: null,
					fundUsi: "",
					superannuationFundGeneratedEmployerIdentifier: "",
				};
			}
		}

		this.$emit("update", eventPayload);
	}

	@Watch("value", { immediate: true })
	fetchDetailsAboutSelectedFund(value: FundDetails) {
		if (!value) {
			return;
		}

		let internalSelectedFundAbn = "";
		if (this.selectedFund) {
			if (this.isAPRAFund || this.isSchemeFund) {
				internalSelectedFundAbn = (this.selectedFund as TFundResponse)
					.fundUsi;
			} else if (this.isSMSFFund) {
				internalSelectedFundAbn = (this.selectedFund as TSmsfResponse)
					.abn;
			}
		}
		let givenAbnOrUsi = "";
		if (value.fundUsi) {
			givenAbnOrUsi = value.fundUsi;
		} else {
			givenAbnOrUsi = value.fundAbn;
			givenAbnOrUsi = givenAbnOrUsi.replace(/[ -]/g, "");
		}

		if (givenAbnOrUsi !== internalSelectedFundAbn.replace(/[ -]/g, "")) {
			const promise = this.searchFund(givenAbnOrUsi);
			if (promise !== undefined) {
				promise.then(() => {
					const matchedFund = this.fundOptionsForQuery.find(
						(option) => {
							if (this.isAPRAFund || this.isSchemeFund) {
								return (
									givenAbnOrUsi ===
									(option as TFundResponse).fundUsi
								);
							} else if (this.isSMSFFund) {
								const smsfAbn = (option as TSmsfResponse).abn;
								if (smsfAbn != undefined && smsfAbn != null) {
									return (
										givenAbnOrUsi ===
										smsfAbn.replace(/[ -]/g, "")
									);
								} else {
									return false;
								}
							}
						}
					);
					if (matchedFund !== undefined) {
						this.shouldEmitUpdate = false;
						this.selectedFund = matchedFund;
						if (this.isSMSFFund) {
							this.$emit(
								"smsf-alias-map-update",
								(this.selectedFund as TSmsfResponse)
									.smsfAliasMap
							);
						}
					}
				});
			}
		}
	}
}
