








































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import CurrencyField from "@/form/CurrencyField.vue";
import DatepickerField from "@/form/DatepickerField.vue";
import AutoField from "@/form/AutoField.vue";
import TextField from "@/form/TextField.vue";

@Component({
	components: { CurrencyField, DatepickerField, AutoField, TextField },
})
export default class FundFieldMappingField extends Vue {
	// See Form.vue #maybeApplyValueAndInputCallbackToEachField for grid errors/warnings to work
	@Prop(String) fieldType!: string;
	@Prop(String) name!: string;
	@Prop(String) label!: string;
	@Prop({ type: String, default: "" }) errors!: string;
	// bug prop named value does not work here
	@Prop(String) fieldValue: string | undefined;
	@Prop({ type: String, default: "" }) rules!: string;
	customFields: string[] = ["contractlengthgreaterthan12months"];
	containsFields: string[] = ["ABN", "TFN", "BSB"];
	@Prop({ type: Boolean, default: false }) required!: boolean;
	@Prop(String) highlightedFieldName!: string;
	@Prop(String) highlightType!: "" | "error" | "warning";
	@Prop({ type: Boolean, default: false }) disabled!: boolean;
	classValue = "";
	onInputChange(e: any) {
		this.$emit("input", {
			data: e,
			fieldName: this.name,
		});
	}

	doesFieldContain(field: string, containsList: string[]) {
		return containsList.includes(field.toUpperCase());
	}

	doesFieldEqual(field: string, equalsList: string[]) {
		return equalsList.includes(field);
	}

	@Watch("highlightedFieldName")
	protected applyExtraVNodeDataToField() {
		if (this.name === this.highlightedFieldName && this.highlightType) {
			this.classValue = `EmployeeForm__field--${this.highlightType}`;
		} else if (this.classValue !== "") {
			this.classValue = "";
		}
	}

	get fieldRule() {
		const requireRule = this.required ? "required" : "";
		if (requireRule !== "") {
			if (this.rules !== "") {
				return requireRule + "|" + this.rules;
			} else {
				return requireRule;
			}
		}
		if (this.rules !== "") {
			return this.rules;
		}
		return "";
	}
}
