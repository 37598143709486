















import {Component, Prop, Watch} from 'vue-property-decorator'
import Vue from 'vue';
import zxcvbn from "zxcvbn";

@Component({})
export default class PasswordStrengthBar extends Vue {
    @Prop({default: ''}) password!: string;
    private isValid = false;
    private passwordStrengthMessage = '';
    private hasStrengthMessage = false;
    private barWidth = '0%';
    private passwordStrengthClass = 'password-strength-bar progress-bar';

    @Watch('password', {immediate: true}) onMatchChanged() {
        this.isValid = this.validateNewPasswordStrength(this.password);
    }

    private validateNewPasswordStrength(password: string): boolean {
        if (password.length === 0) {
            this.hasStrengthMessage = false;
            this.barWidth = '0%';
            this.passwordStrengthClass = 'password-strength-bar progress-bar';
            return true;
        }

        const result = zxcvbn(password);
        let message = result.feedback.warning || '';
        if (message !== '' && message.charAt(message.length - 1) !== '.') {
            message = message + '.';
        }

        switch (result.score) {
            case 0:
                this.passwordStrengthClass = 'password-strength-bar progress-bar progress-bar-danger';
                this.barWidth = '1%';
                break;
            case 1:
                this.passwordStrengthClass = 'password-strength-bar progress-bar progress-bar-danger';
                this.barWidth = '25%';
                break;
            case 2:
                this.passwordStrengthClass = 'password-strength-bar progress-bar progress-bar-danger';
                this.barWidth = '50%';
                break;
            case 3:
                this.passwordStrengthClass = 'password-strength-bar progress-bar progress-bar-danger-warning';
                this.barWidth = '75%';
                break;
            case 4:
                this.passwordStrengthClass = 'password-strength-bar progress-bar progress-bar-danger-success';
                this.barWidth = '100%';
                break;
        }

        this.passwordStrengthMessage = message;
        if (result.score < 3) {
            this.isValid = false;
            this.hasStrengthMessage = true;
            return false;
        } else {
            this.isValid = true;
            this.hasStrengthMessage = false;
            return true;
        }
    }

    public isPasswordStrongEnough() {
        return this.isValid;
    }

}
