
























import Vue from "vue";
import Component from "vue-class-component";
import { toastInfoMessage } from "@/plugins/toasts";
import { createNamespacedHelpers } from "vuex";
import Vidle from "v-idle";
import ModalWithSaveAndCancel from "@/components/ModalWithSaveAndCancel.vue";

const { mapGetters } = createNamespacedHelpers("persistent");

/**
 * Uses v-idle to show a 59 second modal timer on inactivity
 */
@Component({
	components: {
		ModalWithSaveAndCancel,
	},
	methods: {
		...mapGetters(["isLoggedIn"]),
	},
})
export default class IdleTimer extends Vue {
	// Compare to Java's OAuthConfig
	public static readonly DEFAULT_SESSION_TIMEOUT = 15 * 60;
	private static readonly USER_ACTIVITY_EVENTS = ["mousemove", "keypress"];

	public readonly SESSION_IDLE_INTERRUPT = "sessionIdleInterrupt";

	private isLoggedIn!: () => boolean;

	public $refs!: {
		vIdle: Vidle;
	};

	private showRemindModal = false;

	private emitNotIdleEvent: EventListener | undefined;

	mounted() {
		const emitNotIdleEvent = () => {
			if (!this.showRemindModal) {
				// control whether the interrupt reaches the idle timer
				if (
					navigator.userAgent.indexOf("MSIE") !== -1 ||
					navigator.appVersion.indexOf("Trident/") > 0
				) {
					// IE 11 does not support Event.
					const event: any = document.createEvent("UIEvents");
					event.initUIEvent(
						this.SESSION_IDLE_INTERRUPT,
						true,
						false,
						window,
						0
					);
					window.dispatchEvent(event);
				} else {
					window.dispatchEvent(
						new Event(this.SESSION_IDLE_INTERRUPT)
					);
				}
			}
		};
		this.emitNotIdleEvent = emitNotIdleEvent;

		for (
			let i = IdleTimer.USER_ACTIVITY_EVENTS.length - 1;
			i >= 0;
			i -= 1
		) {
			window.addEventListener(
				IdleTimer.USER_ACTIVITY_EVENTS[i],
				emitNotIdleEvent
			);
		}
	}

	beforeUnmount() {
		if (this.emitNotIdleEvent === undefined) {
			return;
		}
		for (
			let i = IdleTimer.USER_ACTIVITY_EVENTS.length - 1;
			i >= 0;
			i -= 1
		) {
			window.removeEventListener(
				IdleTimer.USER_ACTIVITY_EVENTS[i],
				this.emitNotIdleEvent
			);
		}
	}

	get duration() {
		return IdleTimer.DEFAULT_SESSION_TIMEOUT;
	}

	private onRemind() {
		if (!this.isLoggedIn()) {
			return;
		}

		this.showRemindModal = true;
	}

	private cancelTimeout() {
		this.showRemindModal = false;
	}

	private onInactivityTimeout() {
		if (!this.isLoggedIn()) {
			return;
		}
		this.doLogoutWithToast();
	}

	private async doLogoutWithToast() {
		await this.doLogout();
		toastInfoMessage("Logged out due to inactivity.");
	}

	private async doLogout() {
		this.showRemindModal = false;
		await this.$store.dispatch("login/logout");
	}

	private get remainingTimeout() {
		if (!this.$refs.vIdle) {
			return "";
		}
		return "" + this.$refs.vIdle.seconds;
	}
}
