

























































import { Component, Prop, Vue } from "vue-property-decorator";
import { BatchSummaryDetail, TBatchDetail } from "@/pages/batchType";
import Grid from "@/grid/Grid.vue";
import Button from "@/form/Button.vue";
import Modal from "@/components/Modal.vue";
import ModalWithSaveAndCancel from "@/components/ModalWithSaveAndCancel.vue";
import { batchCancelURL } from "@/constants/apiconstants";
import axios from "@/utils/ApiUtils";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import BatchAuditTable from "@/components/BatchAuditTable.vue";
import { toastErrorMessage, toastSuccessMessage } from "@/plugins/toasts";
import { hasPermission } from "@/utils/PermissionUtils";
import { RoutePath } from "@/router/routePath";

@Component({
	components: {
		ModalWithSaveAndCancel,
		Modal,
		Grid,
		Button,
		BatchAuditTable,
	},
})
export default class BatchSummary extends Vue {
	@Prop() private batchSummaryDetail: BatchSummaryDetail | undefined;
	@Prop() private batchDetail: TBatchDetail | undefined;
	@Prop({ type: String, default: "500px" }) private auditGridHeight!: string;
	private showModalOrPopup = false;
	hasPermission(permission: string) {
		return hasPermission(permission);
	}

	openEditBatch() {
		if (this.batchDetail) {
			const routePath = this.batchSummaryDetail?.withFileLevelErrorWarning
				? RoutePath.FileErrorPage
				: RoutePath.ContributionValidateContents;
			this.$router.push(routePath.replace("/:id", "/" + this.batchId));
		}
	}

	navigateToAuthorization() {
		if (
			this.batchDetail?.status === "IN_PROGRESS" ||
			this.batchDetail?.status === "AWAIT_AUTHORISATION"
		) {
			this.$router.push({
				name: "Final Review",
				params: { id: this.batchId },
			});
			return;
		}

		this.openEditBatch();
	}

	openCancelBatchPopup() {
		this.showModalOrPopup = true;
	}

	get downloadReport() {
		return this.batchSummaryDetail?.downloadReport;
	}

	get editBatch() {
		return this.batchSummaryDetail?.editBatch;
	}

	get authoriseBatch() {
		return this.batchSummaryDetail?.authoriseBatch;
	}

	get cancelBatch() {
		return this.batchSummaryDetail?.cancelBatch;
	}

	get batchAudit() {
		return {
			batchAudit: this.batchSummaryDetail?.batchAudit,
		};
	}

	get batchId() {
		if (this.batchDetail) {
			return this.batchDetail?.id;
		}
		return "-1";
	}

	get employeeCount() {
		return this.batchSummaryDetail?.employeeCount;
	}

	get rcCount() {
		return this.batchSummaryDetail?.rcCount;
	}

	closeModal() {
		this.showModalOrPopup = false;
	}

	isBatchAuthorised() {
		return this.batchDetail?.status === "AUTHORISED";
	}

	triggerBatchCancel() {
		axios
			.delete(batchCancelURL(Number(this.batchId)))
			.then((response) => {
				toastSuccessMessage("Contribution batch marked for deletion");
				this.$router.push(RoutePath.BatchList);
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
		this.showModalOrPopup = false;
	}
}
