




























































































































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import DropdownMenu from "@/components/DropdownMenu.vue";
import DropdownMenuItem from "@/components/DropdownMenuItem.vue";
import DropdownMenuHeader from "@/components/DropdownMenuHeader.vue";
import { menuRoutes, rightMenuRoutes } from "@/router/routes";
import { hasPermissionForRoute } from "@/utils/PermissionUtils";
import { AppRouteConfig } from "@/router";
import { RoutePath } from "@/router/routePath";
import store from "@/store";
// import store from "@/store";

@Component({
	components: {
		DropdownMenu,
		DropdownMenuHeader,
		DropdownMenuItem,
	},
})
export default class HeaderNav extends Vue {
	@Prop()
	private isInsightPage!: boolean;
	//TODO: While implementing CHSN-162, we need to make the routes as computed property
	private leftMenuRoutesForUser: AppRouteConfig[] = [];

	private rightMenuRoutesForUser: AppRouteConfig[] = [];

	private parentOrgId: any;
	private parentOrg: any;

	private async logout(): Promise<void> {
		localStorage.removeItem("showInsightsMenu");

		await this.$store.dispatch("login/logout");
	}

	async mounted() {
		const showInsightsMenu = localStorage.getItem("showInsightsMenu");
		let userCanSeeInsightsMenuContent = false;
		if (!showInsightsMenu) {
			userCanSeeInsightsMenuContent = await store.dispatch(
				"persistent/fetchCanUserSeeInsightsMenuContent",
				{}
			);
			localStorage.setItem(
				"showInsightsMenu",
				userCanSeeInsightsMenuContent + ""
			);
		} else {
			userCanSeeInsightsMenuContent =
				showInsightsMenu == "true" ? true : false;
		}
		const definedBenefitFeaturesEnabled =
			this.$store.getters["persistent/definedBenefitFeaturesEnabled"];

		let temp: AppRouteConfig[] = menuRoutes;
		temp.forEach((route) => {
			if (route.meta && route.meta.permissions) {
				if (hasPermissionForRoute(route.meta.permissions)) {
					if (route.name === "Insights") {
						if (userCanSeeInsightsMenuContent) {
							this.leftMenuRoutesForUser.push(route);
						}
					} else if (route.name === "Bulk file loads") {
						if (definedBenefitFeaturesEnabled) {
							this.leftMenuRoutesForUser.push(route);
						}
					} else {
						this.leftMenuRoutesForUser.push(route);
					}
				}
			}
		});
		temp = rightMenuRoutes;
		temp.forEach((route) => {
			if (route.meta && route.meta.permissions) {
				const permissions: string[] = route.meta.permissions;
				if (hasPermissionForRoute(permissions)) {
					this.rightMenuRoutesForUser.push(route);
				}
			}
		});
	}

	get currentUserName() {
		return this.$store.state.persistent.name;
	}
	private navigateToProfile() {
		this.$router.push(RoutePath.Profile);
	}
}
