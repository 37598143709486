
























































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Item } from "@/constants/pageConstants";

@Component({})
export default class ProgressArrow extends Vue {
	/**
	 * value between 0 and 1.
	 * Opted to make the API similar to the semi-supported <progress-bar> HTML5 element.
	 */
	@Prop(Array) readonly steps!: Item[];
}
