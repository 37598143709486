












import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { ColDef, ColGroupDef } from "ag-grid-community";

import Grid from "@/grid/Grid.vue";
import { columnDescriptionDateFormatter } from "@/utils/CommonUtils";
import { FinalBatchReviewUpdateRecord } from "@/models/FinalBatchReviewRecord";
import { stagingStatusMapping } from "@/utils/BatchUtils";

@Component({
	components: {
		Grid,
	},
})
export default class BatchFieldsRemoved extends Vue {
	/**
	 * null is loading data while [] is no data.
	 */
	@Prop({
		type: Array,
		default: null,
	})
	private rowData!: FinalBatchReviewUpdateRecord[] | null;

	readonly columnDefs: (ColGroupDef | ColDef)[] = [
		{ headerName: "Line ID", field: "memberContextId", width: 120 },
		{ headerName: "Entity", field: "entityType" },
		{ headerName: "USI", field: "entityId" },
		{ headerName: "Fund name", field: "entityName" },
		{
			headerName: "Description",
			field: "description",
			valueFormatter: columnDescriptionDateFormatter,
			width: 530,
		},
		{
			headerName: "Status",
			field: "status",
			cellRendererParams: {
				translationMappings: stagingStatusMapping(),
			},
			cellRenderer: "valueTranslatedCellRenderer",
			width: 130,
		},
	];
}
