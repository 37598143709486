






















































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import axios from "axios";
import { Component } from "vue-property-decorator";
import PasswordStrengthBar from "@/components/PasswordStrengthBar.vue";
import Button from "@/form/Button.vue";
import CheckBox from "@/form/CheckBox.vue";
import { LoginPhase } from "@/store/modules/login/loginTypes";
import ErrorIcon from "@/components/ErrorIcon.vue";
import VueRecaptcha from "vue-recaptcha";
import { Logger } from "@/utils/logger";
import { createNamespacedHelpers } from "vuex";
import { toastErrorMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import WarningIcon from "@/components/WarningIcon.vue";

const { mapMutations } = createNamespacedHelpers("login");

@Component({
	components: {
		WarningIcon,
		ErrorIcon,
		PasswordStrengthBar,
		Button,
		VueRecaptcha,
		CheckBox,
	},
	methods: {
		...mapMutations(["setErrorMessage"]),
	},
})
export default class Login extends Vue {
	private setErrorMessage!: (errorMessage: string) => void;

	private username = "";
	private password = "";
	private financialDocsRead = false;
	private code = "";
	private email = "";
	private phone = "";
	private resetpassword2FA = "";
	private newPassword = "";
	private confirmNewPassword = "";
	private accountLocked = false;
	private forgotPassword = false;
	private showLoginForm = true;
	private shouldShowFinancialDocsReadCheckbox = true;

	private isRequestingPassword = false;
	private recaptchaSiteKey: string | null =
		sessionStorage.getItem("recaptchaSiteKey");

	$refs!: {
		refPasswordResetInitiateCaptcha: VueRecaptcha;
	};

	get canRequestPassword() {
		return !this.isRequestingPassword;
	}

	private recaptchaError() {
		const error =
			"reCAPTCHA has encountered an error (usually network connectivity). Please retry.";
		Logger.error(error);
		this.setErrorMessage(error);
		this.isRequestingPassword = false;
	}

	private executeRecaptcha() {
		this.isRequestingPassword = true;
		this.$refs.refPasswordResetInitiateCaptcha.execute();
	}

	private async setRecaptcha(newKey: string) {
		await this.initiateForgotPassword(newKey);
		// we used the token, so need to get a new one if it failed
		this.$refs.refPasswordResetInitiateCaptcha.reset();
		this.isRequestingPassword = false;
	}

	private expireRecaptcha() {
		// Note (York): This is unlikely to happen because reCaptcha resets after backend validation
		// which should be finished within a second. The user reponse token is valid for two minutes.
		const expirationMesage =
			"reCAPTCHA response has expired and you need to re-verify.";
		Logger.warn(expirationMesage);
		this.setErrorMessage(expirationMesage);
		this.isRequestingPassword = false;
	}

	private login() {
		if (!this.username || !this.password) {
			this.$store.commit(
				"login/setErrorMessage",
				"Username and Password cannot be empty."
			);
			return;
		}

		const payload = {
			username: this.username,
			password: this.password,
			brand: process.env.VUE_APP_BRAND,
		};

		this.$store
			.dispatch("login/loginCredRequest", payload)
			.then((loginInfo) => {
				this.shouldShowFinancialDocsReadCheckbox =
					!loginInfo.financialDocsRead;
				this.financialDocsRead = loginInfo.financialDocsRead;
			});
	}

	private loginTwoFA() {
		if (!this.financialDocsRead) {
			this.$store.commit(
				"login/setErrorMessage",
				"Please confirm that you have read the Iress SuperConnector Combined Financial Service Guide and Product Disclosure Statement, and that you understand and agree to the terms and conditions set out in that document."
			);
			return;
		}
		if (!this.username || !this.password || !this.code) {
			this.$store.commit(
				"login/setErrorMessage",
				"Please enter all the mandatory fields."
			);
			return;
		}

		const payload = {
			code: this.code,
			username: this.username,
			password: this.password,
			financialDocsRead: this.financialDocsRead,
		};
		this.$store.dispatch("login/twoFARequest", payload);
	}

	private showForgotPassword() {
		this.forgotPassword = true;
		this.showLoginForm = false;
		this.email = "";
		this.phone = "";
		this.$store.commit("login/clearErrorMessage");
		this.$store.commit(
			"login/setPhase",
			LoginPhase.FORGOT_PASSWORD_DEFAULT
		);
	}

	private async initiateForgotPassword(recaptchaToken: string) {
		if (this.email && this.phone) {
			const payload = {
				email: this.email,
				phone: this.phone,
				captchaResponse: recaptchaToken,
			};
			await this.$store.dispatch("login/forgotPasswordRequest", payload);
		} else {
			this.$store.commit(
				"login/setErrorMessage",
				"Email Address and Mobile Phone cannot be empty."
			);
		}
	}

	private backToLogin() {
		this.showLoginForm = true;
		this.forgotPassword = false;
		this.username = "";
		this.password = "";
		this.$store.commit("login/clearErrorMessage");
		this.$store.commit("login/setPhase", LoginPhase.CRED_DEFAULT);
	}

	private resetPassword() {
		if (this.newPassword != this.confirmNewPassword) {
			this.$store.commit(
				"login/setErrorMessage",
				"Passwords must match."
			);
			return;
		}
		const payload = {
			email: this.email,
			newPassword: this.newPassword,
			twoFactorCode: this.resetpassword2FA,
			brand: process.env.VUE_APP_BRAND,
		};
		this.$store.dispatch("login/loginResetPassword", payload);
	}

	get errorMessage() {
		return this.$store.state.login.errorMessage;
	}

	get phase() {
		return this.$store.state.login.phase;
	}

	private errorClass(errors: any) {
		return errors && errors.length > 0
			? "form-group FieldHolder__error-holder"
			: "form-group";
	}

	private downloadProductDisclosureStatementFinancialServicesGuide() {
		const filename =
			"Combined FSG PDS - Iress SuperConnector 3 April 2024.pdf";
		axios
			.get(`files/` + filename, {
				responseType: "blob",
			})
			.then((response) => {
				const blob = new Blob([response.data], {
					type: "application/pdf",
				});
				if (window.navigator && window.navigator.msSaveOrOpenBlob) {
					window.navigator.msSaveOrOpenBlob(blob, filename);
				} else {
					const url = window.URL.createObjectURL(blob);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", filename);
					link.click();
				}
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
	}
}
