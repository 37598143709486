






























































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import TextField from "@/form/TextField.vue";
import SelectField from "@/form/SelectField.vue";
import FieldGroup from "@/form/FieldGroup.vue";
import Button from "@/form/Button.vue";
import { CompanyDetail } from "@/pages/maintenanceTypes";
import SubHeading from "@/components/SubHeading.vue";
import { stateList } from "@/constants/constants";
import { SelectOption } from "@/form/FieldOptions";

@Component({
	components: {
		Layout,
		TextField,
		FieldGroup,
		Button,
		SelectField,
		SubHeading,
	},
})
export default class CompanyDetails extends Vue {
	@Prop() readonly companyDetail!: CompanyDetail | null;
	@Prop(Boolean) readonly readonly!: boolean;
	@Prop(Boolean) readonly readonlyAbn!: boolean;
	private readonly states: SelectOption[] = stateList;

	emitUpdate(field: string, value: string) {
		this.$emit("change", { field: field, value: value });
	}
}
