var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.canRefresh)?_c('Button',{attrs:{"disabled":!_vm.canRefresh ||
		_vm.intervalData.remaining !== 0 ||
		_vm.intervalData.isRefreshing},on:{"click":_vm.onClickRefresh}},[_vm._v(" "+_vm._s(_vm.buttonName)+" "+_vm._s(_vm.refreshCount)+" "),(
			!_vm.refreshCount &&
			(!_vm.canRefresh ||
				_vm.intervalData.remaining !== 0 ||
				_vm.intervalData.isRefreshing)
		)?_c('font-awesome-icon',{attrs:{"icon":['fal', 'spinner'],"spin":""}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }