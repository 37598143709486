





















































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import axios, { axiosStatic } from "@/utils/ApiUtils";
import {
	accountBalanceTransactionDetailURL,
	accountBalanceTransactionHeaderURL,
} from "@/constants/apiconstants";
import { toastErrorMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import { FilterModel, PagedResult } from "@/grid/gridTypes";
import {
	ColDef,
	ColGroupDef,
	IServerSideDatasourceTyped,
	IServerSideGetRowsParamsTyped,
} from "ag-grid-community";
import Grid from "@/grid/Grid.vue";
import GridFilter from "@/components/GridFilter.vue";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import AccountBalanceDetail from "@/pages/accountbalance/AccountBalancePage.vue";
import {
	acurityColumnDateFormatter,
	getDateForPastNMonths,
} from "@/utils/CommonUtils";
import Button from "@/form/Button.vue";
import DatepickerField from "@/form/DatepickerField.vue";
import AutoField from "@/form/AutoField.vue";
import { commitToModule, registerModule } from "@/store/modules/filters";

// Corresponds to AccountBalanceResource.Transaction
interface Transaction {
	transactionDate: string;
	description: string;
	deposit: string;
	withdrawal: string;
	batchId: string;
	balance: string;
	contributionShare: string;
}

@Component({
	components: {
		AutoField,
		Layout,
		Grid,
		GridFilter,
		Button,
		DatepickerField,
	},
})
export default class TransactionsPage
	extends Vue
	implements IServerSideDatasourceTyped
{
	@Prop(String) member!: string;

	private vuexStore = `accountBalance/${this.member}`;

	private gridReady = false;
	private accountBalanceDetail: AccountBalanceDetail | null = null;

	public $refs!: {
		gridEl: Grid;
	};

	private readonly columnDefs: (ColGroupDef | ColDef)[] = [
		{
			headerName: "Date",
			field: "transactionDate",
			width: 60,
			resizable: true,
			valueFormatter: acurityColumnDateFormatter,
		},
		{
			headerName: "Batch ID",
			field: "batchId",
			width: 60,
			resizable: true,
		},
		{
			headerName: "Action",
			field: "description",
			width: 140,
			resizable: true,
		},
		{
			headerName: "Deposit",
			field: "deposit",
			width: 60,
			resizable: true,
		},
		{
			headerName: "Withdrawal",
			field: "withdrawal",
			width: 60,
			resizable: true,
		},
		{
			headerName: "Available balance",
			field: "balance",
			width: 60,
			resizable: true,
		},
		/*{
			headerName: "Amount owing",
			field: "owing",
			width: 60,
			resizable: true,
		},*/
		{
			headerName: "Sub batch amount",
			field: "contributionShare",
			width: 60,
			resizable: true,
		},
	];
	private filterModel: FilterModel = {
		startDate: {
			value: this.getDefaultStartDate(),
			column: "startDate",
		},
		endDate: {
			value: "",
			column: "endDate",
		},
	};
	private modules = [RowGroupingModule];

	private onGridReady() {
		this.gridReady = true;
	}

	private onApplyFilter() {
		commitToModule(this.$store, this.vuexStore, this.filterModel);
		this.$refs.gridEl.reload();
	}

	onBack() {
		this.$router.push({ name: "Account balance" });
	}

	onResetFilter() {
		this.filterModel.startDate.value = this.getDefaultStartDate();
		this.filterModel.endDate.value = "";
		this.onApplyFilter();
	}

	private getDefaultStartDate(): string {
		return getDateForPastNMonths(6);
	}

	created() {
		this.retrieveHeader();
		registerModule(this.$store, this.vuexStore, this.filterModel);
		this.filterModel = this.$store.getters[`${this.vuexStore}/filters`];
	}

	private retrieveHeader() {
		axios
			.get<AccountBalanceDetail>(
				accountBalanceTransactionHeaderURL(this.member)
			)
			.then((response) => {
				this.accountBalanceDetail = response.data;
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
	}

	getRows(params: IServerSideGetRowsParamsTyped<Transaction>): void {
		params.request.filterModel = Object.keys(this.filterModel).map(
			(key) => {
				return this.filterModel[key];
			}
		);

		axios
			.get<PagedResult<Transaction>>(
				accountBalanceTransactionDetailURL(this.member),
				{
					params: {
						grid: params.request,
					},
					cancelToken: params.cancelToken,
				}
			)
			.then((response) => {
				params.successCallback(response.data);
			})
			.catch((error) => {
				if (axiosStatic.isCancel(error)) {
					return;
				}
				toastErrorMessage(parseErrorMessage(error));
			});
	}
}
