








































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import {
	CellClickedEvent,
	ColDef,
	ColGroupDef,
	IServerSideDatasourceTyped,
	IServerSideGetRowsParamsTyped,
	ValueSetterParams,
	ICellRendererParams,
} from "ag-grid-community";

import axios, { axiosStatic } from "@/utils/ApiUtils";
import Grid from "@/grid/Grid.vue";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import { toastErrorMessage } from "@/plugins/toasts";
import {
	columnCurrencyFormatter,
	columnCurrencyParser,
	columnDateFormatter,
} from "@/utils/CommonUtils";
import {
	ColDefBase,
	ContributionRow,
	FilterForm,
	FundFieldMapping,
	ValidateContentsPageResponse,
} from "@/models/ContributionRow";
import { getFullname, getTotalAmount } from "@/utils/ContributionUtils";
import GridErrorWarningRenderer from "@/grid/GridErrorWarningRenderer.vue";
import GridActionsRenderer from "@/grid/GridActionsRenderer.vue";
import { FilterOptions } from "@/pages/contribution/ContributionPageTypes";
import ContributionEmployeeFilterForm from "@/pages/contribution/ContributionEmployeeFilterForm.vue";
import moment from "moment";

@Component({
	components: {
		Grid,
		ContributionEmployeeFilterForm,
	},
})
export default class ContributionEmployeeList
	extends Vue
	implements IServerSideDatasourceTyped
{
	@Prop({ type: Number }) batchId!: number;

	@Prop({ type: Boolean, default: false }) loading!: boolean;

	@Prop({ type: Boolean, default: false }) hasActionColumn!: boolean;

	@Prop({ type: Boolean, default: true }) showWarningErrorIcon!: boolean;

	@Prop({ type: Boolean, default: true }) isActionFormReadonly!: boolean;

	@Prop({ type: Boolean, default: true }) filterByErrors!: boolean;
	@Prop(Function) onCellClicked!: (params: CellClickedEvent) => void;
	@Prop(Function) onClickEdit!: (
		rowIndex: number,
		record: ContributionRow
	) => void;
	@Prop(Function) onClickDelete!: (
		rowIndex: number,
		record: ContributionRow
	) => void;
	@Prop(Function) onClickView!: (
		rowIndex: number,
		record: ContributionRow
	) => void;
	@Prop() fundFieldMappings!: FundFieldMapping[];

	private overrideContributionColumnDefs: ColDefBase[] = [];

	/**
	 * filterFormLiveData are the current filter options set in the form.
	 * *WARNING:* These are not applied to the request unless the user hit the "Filter" button.
	 */
	filterFormLiveData: FilterOptions = {
		fileRegRef: 0,
		cfsSeverity: "",
		abn: "",
		usi: "",
		familyName: "",
	};

	/**
	 * filterFormData is a copy of filterFormLiveData that is created once the "Filter" button
	 * is pressed.
	 */
	filterFormData: {
		[fieldName: string]: string | undefined | null;
	} = JSON.parse(JSON.stringify(this.filterFormLiveData));

	private gridReady = false;

	readonly actionColumn: ColDef = {
		headerName: this.isActionFormReadonly ? "View" : "Edit",
		field: "__Actions",
		cellRenderer: this.actionsRender,
		resizable: true,
		pinned: "right",
		minWidth: 80,
		width: 80,
	};

	getColumnDefs(): (ColGroupDef | ColDef)[] {
		return [
			{
				children: [
					{
						resizable: true,
						pinned: "left",
						minWidth: 45,
						maxWidth: 45,
						sortable: true,
						hide: !this.showWarningErrorIcon,
						comparator: () => 0, // disable client side sorting
						...(!this.showWarningErrorIcon && {
							onCellClicked: this.onCellClicked,
						}),
						...(this.showWarningErrorIcon && {
							cellRenderer: this.warningErrorRender,
						}),
					},
					{
						headerName: "Line ID",
						field: "saffLineId",
						resizable: true,
						pinned: "left",
						minWidth: 85,
						sortable: true,
						comparator: () => 0, // disable client side sorting
					},
				],
			},
			{
				headerName: "Employee",
				children: [
					{
						headerName: "Employee",
						field: "ComputedFullName",
						valueGetter: getFullname,
						resizable: true,
						pinned: "left",
						minWidth: 115,
						sortable: true,
						comparator: () => 0, // disable client side sorting
					},
					{
						headerName: "Payroll ID",
						field: "payrollNo",
						resizable: true,
						pinned: "left",
						minWidth: 90,
						sortable: true,
						comparator: () => 0, // disable client side sorting
					},
					{
						headerName: "Employer ABN",
						field: "abn",
						resizable: true,
						pinned: "left",
						width: 100,
						columnGroupShow: "open",
					},
					{
						headerName: "Employer name",
						field: "employerPayrollName",
						resizable: true,
						pinned: "left",
						width: 100,
						columnGroupShow: "open",
					},
					{
						headerName: "Employer payroll location",
						field: "employerPayrollLocation",
						resizable: true,
						pinned: "left",
						width: 100,
						columnGroupShow: "open",
					},
					{
						headerName: "Reporting centre",
						field: "reportingCentreName",
						resizable: true,
						pinned: "left",
						minWidth: 140,
						columnGroupShow: "open",
					},
				],
			},
			{
				// overridden columns may not be contribution only columns
				headerName:
					this.overrideContributionColumnDefs.length === 0
						? "Contribution"
						: "Data",
				children: this.createContributionAndDataColumnDefs(),
			},
			{
				children: [
					{
						headerName: "Total",
						field: "TotalAmount",
						valueGetter: getTotalAmount,
						valueFormatter: columnCurrencyFormatter,
						resizable: true,
						pinned: "right",
						minWidth: 110,
					},
					...(this.hasActionColumn ? [this.actionColumn] : []),
				],
			},
		];
	}
	private readonly buildDynamicColumnDef = (
		label: string,
		field: string,
		colType: string
	): ColDef => {
		return {
			headerName: label,
			field: field,
			flex: 1,
			resizable: true,
			...(!this.isActionFormReadonly && {
				editable: true,
				cellClass: "contribution-value-editable",
			}),
			...(colType === "DATE" && {
				valueFormatter: columnDateFormatter,
				valueSetter: this.dateValueSetter,
			}),
			...(colType === "AMOUNT" && {
				valueFormatter: columnCurrencyFormatter,
				valueParser: columnCurrencyParser,
			}),
		};
	};

	private createContributionAndDataColumnDefs(): ColDef[] {
		if (this.overrideContributionColumnDefs.length !== 0) {
			return this.overrideContributionColumnDefs.map((colDef) =>
				this.buildDynamicColumnDef(
					colDef.label,
					colDef.field,
					colDef.fieldType
				)
			);
		}

		return [
			{
				headerName: "Super Guarantee",
				field: "employerContributionsSuperannuationGuarantee",
				valueFormatter: columnCurrencyFormatter,
				resizable: true,
				minWidth: 100,
				...(!this.isActionFormReadonly && {
					editable: true,
					cellClass: "contribution-value-editable",
					valueParser: columnCurrencyParser,
				}),
			},
			{
				headerName: "Salary Sacrifice",
				field: "employerContributionsSalarySacrificed",
				valueFormatter: columnCurrencyFormatter,
				resizable: true,
				minWidth: 90,
				...(!this.isActionFormReadonly && {
					editable: true,
					cellClass: "contribution-value-editable",
					valueParser: columnCurrencyParser,
				}),
			},
			{
				headerName: "Award",
				field: "employerContributionsAwardOrProductivity",
				valueFormatter: columnCurrencyFormatter,
				resizable: true,
				minWidth: 90,
				...(!this.isActionFormReadonly && {
					editable: true,
					cellClass: "contribution-value-editable",
					valueParser: columnCurrencyParser,
				}),
			},
			{
				headerName: "Personal",
				field: "personalContributions",
				valueFormatter: columnCurrencyFormatter,
				resizable: true,
				minWidth: 90,
				...(!this.isActionFormReadonly && {
					editable: true,
					cellClass: "contribution-value-editable",
					valueParser: columnCurrencyParser,
				}),
			},
			{
				headerName: "Employer Voluntary",
				field: "employerContributionsVoluntary",
				valueFormatter: columnCurrencyFormatter,
				resizable: true,
				minWidth: 95,
				...(!this.isActionFormReadonly && {
					editable: true,
					cellClass: "contribution-value-editable",
					valueParser: columnCurrencyParser,
				}),
			},
			{
				headerName: "Spouse",
				field: "spouseContributions",
				valueFormatter: columnCurrencyFormatter,
				resizable: true,
				minWidth: 90,
				...(!this.isActionFormReadonly && {
					editable: true,
					cellClass: "contribution-value-editable",
					valueParser: columnCurrencyParser,
				}),
			},
			{
				headerName: "Child",
				field: "childContributions",
				valueFormatter: columnCurrencyFormatter,
				resizable: true,
				minWidth: 90,
				...(!this.isActionFormReadonly && {
					editable: true,
					cellClass: "contribution-value-editable",
					valueParser: columnCurrencyParser,
				}),
			},
			{
				headerName: "Other 3rd Party",
				field: "otherThirdPartyContributions",
				valueFormatter: columnCurrencyFormatter,
				resizable: true,
				minWidth: 95,
				...(!this.isActionFormReadonly && {
					editable: true,
					cellClass: "contribution-value-editable",
					valueParser: columnCurrencyParser,
				}),
			},
		];
	}

	private dateValueSetter(params: ValueSetterParams) {
		if (
			params.newValue &&
			params.colDef.field &&
			// Provide formats to prevent from parsing as MM-DD-YYYY which moment defaults to
			moment(params.newValue, ["YYYY-MM-DD", "DD-MM-YYYY"]).isValid()
		) {
			params.data[params.colDef.field] = moment(params.newValue, [
				"YYYY-MM-DD",
				"DD-MM-YYYY",
			]).format("YYYY-MM-DD");
			return true;
		} else if (params.newValue === "" && params.colDef.field) {
			params.data[params.colDef.field] = params.newValue;
			return true;
		}
		toastErrorMessage("Invalid date ");
		return false;
	}

	private selectedContributionRowIndex: number | null = null;
	private selectedContributionRowData: ContributionRow | null = null;

	/**
	 * gridVMList tracks the Vue components we render within the Grid component so we
	 * can free them when the component is destroyed.
	 */
	private gridVMList: Vue[] = [];

	public $refs!: {
		gridEl: Grid;
	};
	private filterForm: FilterForm | null = null;

	private updateFilterForm(filterForm: FilterOptions) {
		this.filterFormData = JSON.parse(JSON.stringify(filterForm));
		if (this.$refs.gridEl) {
			this.$refs.gridEl.reload();
		}
	}

	destroyed(): void {
		// Free
		for (const vm of this.gridVMList) {
			vm.$destroy();
		}
		this.gridVMList = [];
	}

	getRows(params: IServerSideGetRowsParamsTyped<ContributionRow>): void {
		this.$refs.gridEl.showLoadingOverlay();
		if (!this.batchId) {
			// should log an error here.
			return;
		}
		axios
			.get<ValidateContentsPageResponse>(
				`api/contribution/validate-contents/${this.batchId}`,
				{
					headers: {
						"Content-Type": "application/json",
					},
					params: {
						grid: params.request,
						// Then override or add our custom parameters
						...(this.filterFormData && {
							filters:
								Object.keys(this.filterFormData).length > 0
									? this.filterFormData
									: undefined,
						}),
					},
				}
			)
			.then((resp) => {
				const pagedResultData = resp.data.data;
				if (pagedResultData.elements.length === 0) {
					this.$emit("empty-row-data");
				}
				params.successCallback(pagedResultData);
				resp.data.data.elements.forEach((cr) => {
					cr.fundFieldMapping = this.fundFieldMappings.filter(
						(f) => f.usi === cr.fundUsi
					);
				});
				this.filterForm = resp.data.filterForm;
				this.overrideContributionColumnDefs =
					resp.data.contColDefOverride;
			})
			.catch((error) => {
				if (axiosStatic.isCancel(error)) {
					return;
				}
				toastErrorMessage(parseErrorMessage(error));
			})
			.finally(() => {
				this.$refs.gridEl.hideOverlay();
			});
	}

	warningErrorRender(params: ICellRendererParams): HTMLElement {
		const vm = new Vue({
			el: document.createElement("div"),
			render: (createElement) => {
				return createElement(GridErrorWarningRenderer, {
					props: {
						rowIndex: params.rowIndex,
						row: params.data,
						withError: params.data.withError,
						withWarning: params.data.withWarning,
						withRevalidateError: params.data.withRevalidateError,
						withRevalidateWarning:
							params.data.withRevalidateWarning,
						withInfo: true,
					},
					on: {
						"icon-click": this.onErrorWarningIconClick,
					},
				});
			},
		});
		this.gridVMList.push(vm);
		return vm.$el as HTMLElement;
	}

	actionsRender(params: ICellRendererParams): HTMLElement {
		const vm = new Vue({
			el: document.createElement("div"),
			render: (createElement) => {
				return createElement(GridActionsRenderer, {
					props: {
						rowIndex: params.rowIndex,
						row: params.data,
						isEdit: !this.isActionFormReadonly,
						isDelete: !this.isActionFormReadonly,
						isView: this.isActionFormReadonly,
					},
					on: {
						clickEdit: this.onRowClickEdit,
						clickDelete: this.onRowClickDelete,
						clickView: this.onRowClickView,
					},
				});
			},
		});
		this.gridVMList.push(vm);
		return vm.$el as HTMLElement;
	}

	private onErrorWarningIconClick({
		rowIndex,
		row,
	}: {
		rowIndex: number;
		row: ContributionRow;
	}): void {
		if (this.isActionFormReadonly) {
			this.onRowClickView({ rowIndex, row });
		} else {
			this.onRowClickEdit({ rowIndex, row });
		}
	}

	private onRowClickView({
		rowIndex,
		row,
	}: {
		rowIndex: number;
		row: ContributionRow;
	}): void {
		if (this.onClickView) {
			this.onClickView(rowIndex, row);
		}
	}

	private onRowClickDelete({
		rowIndex,
		row,
	}: {
		rowIndex: number;
		row: ContributionRow;
	}): void {
		if (this.onClickDelete) {
			this.onClickDelete(rowIndex, row);
		}
	}

	private onRowClickEdit({
		rowIndex,
		row,
	}: {
		rowIndex: number;
		row: ContributionRow;
	}): void {
		if (this.onClickEdit) {
			this.onClickEdit(rowIndex, row);
		}
	}

	private onRowValueChanged({
		rowIndex,
		data,
	}: {
		rowIndex: number;
		data: ContributionRow;
	}): void {
		this.$emit("row-value-changed", {
			newRowIndex: rowIndex,
			newData: data,
			oldRowIndex: this.selectedContributionRowIndex,
			oldData: this.selectedContributionRowData,
		});
	}

	// This detects when contribution row editing in grid starts
	// We store the value of contribution before start to allow
	// detect any change.
	private onRowEditingStarted({
		rowIndex,
		data,
	}: {
		rowIndex: number;
		data: ContributionRow;
	}): void {
		this.selectedContributionRowIndex = rowIndex;
		this.selectedContributionRowData = { ...data };
	}

	// This detects when contribution row editing ends. We can
	// then clear off the internal state of selected data and index
	private onRowEditingStopped(): void {
		this.selectedContributionRowIndex = null;
		this.selectedContributionRowData = null;
	}

	private onFilterFormChanged(filterForm: FilterForm) {
		this.$emit("filter-form-changed", filterForm);
	}

	@Watch("loading")
	private isLoading(): void {
		this.loading
			? this.$refs.gridEl.showLoadingOverlay()
			: this.$refs.gridEl.hideOverlay();
	}

	/**
	 * reloadEmployeeList can be called by other components to grid reload.
	 * This allows us to reload the data after updating filters / etc.
	 */
	public reloadEmployeeList(): void {
		if (!this.$refs.gridEl) {
			return;
		}
		this.$refs.gridEl.reload();
	}

	/**
	 * reload can be called by other components to trigger calling "getRows".
	 * This allows us to reload the data after updating a row data and retain
	 * the page selection
	 */
	public refreshEmployeeList(): void {
		if (!this.$refs.gridEl) {
			return;
		}
		this.$refs.gridEl.refresh();
	}

	private onGridReady() {
		this.gridReady = true;
		this.$emit("grid-ready");
	}
}
