
































































































































































































import Form from "@/form/Form.vue";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import SubHeading from "@/components/SubHeading.vue";
import TextField from "@/form/TextField.vue";
import { AppRoute, AppRouteNextFunction } from "@/router";
import axios from "@/utils/ApiUtils";
import { profile, userPreference } from "@/constants/apiconstants";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import { toastErrorMessage, toastSuccessMessage } from "@/plugins/toasts";
import PasswordField from "@/form/PasswordField.vue";
import PasswordStrengthBar from "@/components/PasswordStrengthBar.vue";
import Button from "@/form/Button.vue";
import { ValidationObserver, VForm } from "@/@typings/type-vee-validate";
import { createNamespacedHelpers } from "vuex";
import Modal from "@/components/Modal.vue";
import { RoutePath } from "@/router/routePath";
import {
	NotificationCategory,
	NotificationCategoryMapper,
} from "@/models/AlertRow";

export type Profile = {
	title: string;
	name: string;
	email: string;
	position: string;
	mobile: string;
	currentPassword: string;
	newPassword: string;
	confirmNewPassword: string;
	invalidateSession: boolean;
};

export type UserPreference = {
	channel: string;
	category: string;
};

const { mapState } = createNamespacedHelpers("persistent");
@Component({
	components: {
		Modal,
		PasswordField,
		Form,
		TextField,
		SubHeading,
		Layout,
		PasswordStrengthBar,
		Button,
	},
	computed: mapState({
		userName: "username",
	}),
})
export default class ProfilePage extends Vue {
	private formReadOnly = false;
	public $refs!: {
		updateProfileForm: ValidationObserver;
	};
	private profile: Profile = {
		title: "",
		name: "",
		email: "",
		position: "",
		mobile: "",
		currentPassword: "",
		newPassword: "",
		confirmNewPassword: "",
		invalidateSession: false,
	};

	private channelOptions: { value: string; text?: string }[] = [
		{ value: "email", text: "Email" },
		{ value: "sms", text: "SMS" },
		{ value: "none", text: "Alerts dashboard only" },
	];

	private userPreferenceChannelList: UserPreference[] = [];
	private savePreferenceChannelList: UserPreference[] = [];
	/**
	 * Type the mapped persistent.username getter.
	 * It is a computed property. Do not mutate it.
	 */
	userName!: string;
	private showRedirectionModal = false;
	private profileResponse: Profile = {
		title: "",
		name: "",
		email: "",
		position: "",
		mobile: "",
		currentPassword: "",
		newPassword: "",
		confirmNewPassword: "",
		invalidateSession: false,
	};

	async beforeRouteEnter(
		to: AppRoute,
		from: AppRoute,
		next: AppRouteNextFunction<ProfilePage>
	) {
		const response = await ProfilePage.beforeRouteEnterOrUpdate(
			to,
			from,
			next
		);
		if (response === undefined) {
			return;
		}
		next((vm) => {
			vm.setResponse(response);
		});
	}

	private static async getProfile(): Promise<Profile | undefined> {
		let profileResponse;
		await axios
			.get(profile())
			.then((response) => {
				profileResponse = response.data;
			})
			.catch((error) => {
				const errorMessage = parseErrorMessage(error);
				toastErrorMessage(errorMessage);
			});
		return profileResponse;
	}

	static async beforeRouteEnterOrUpdate(
		to: AppRoute,
		from: AppRoute,
		next: AppRouteNextFunction<ProfilePage>
	): Promise<Profile | undefined> {
		return await ProfilePage.getProfile();
	}

	setResponse(response: Profile) {
		this.profileResponse = JSON.parse(JSON.stringify(response));
		this.profile = JSON.parse(JSON.stringify(response));
	}

	beforeMount() {
		this.getUserPreference();
	}

	updateUserPreference(channel: string, category: string) {
		const replaceIndex = this.savePreferenceChannelList.findIndex(
			(item) => item.category === category
		);

		if (replaceIndex !== -1) {
			this.savePreferenceChannelList[replaceIndex] = {
				category: category,
				channel: channel,
			};
		} else {
			this.savePreferenceChannelList.push({
				category: category,
				channel: channel,
			});
		}
	}

	private getUserPreference() {
		axios
			.get(userPreference())
			.then((response) => {
				this.userPreferenceChannelList = response.data;
			})
			.catch((error) => {
				const errorMessage = parseErrorMessage(error);
				toastErrorMessage(errorMessage);
			});
	}

	categoryFullName(category: NotificationCategory) {
		return NotificationCategoryMapper(category);
	}

	userPreferenceChannelName(category: NotificationCategory): string {
		return `${this.categoryFullName(category).toLocaleLowerCase()}Options`;
	}

	get formChanged(): boolean {
		if (
			this.profile.email.toLowerCase() !==
				this.profileResponse.email.toLowerCase() ||
			this.profile.title !== this.profileResponse.title ||
			this.profile.position !== this.profileResponse.position ||
			this.profile.name !== this.profileResponse.name ||
			this.profile.mobile !== this.profileResponse.mobile ||
			this.savePreferenceChannelList.length !== 0 ||
			(this.profile.newPassword != null &&
				this.profile.confirmNewPassword != null)
		) {
			return true;
		}
		return false;
	}
	private async updateProfile() {
		this.formReadOnly = true;
		if (await (this.$refs.updateProfileForm as VForm).validate()) {
			axios
				.put(userPreference(), this.savePreferenceChannelList)
				.catch((error) => {
					const errorMessage = parseErrorMessage(error);
					toastErrorMessage(errorMessage);
				});

			axios
				.put(profile(), this.profile)
				.then((response) => {
					if (response.data.invalidateSession) {
						this.showRedirectionModal = true;
						setTimeout(
							() =>
								this.$store.dispatch("resetState", {
									root: true,
								}),
							6000
						);
					} else {
						this.profile = response.data;
						this.profileResponse = response.data;
						toastSuccessMessage("Profile updated.");
						this.$refs.updateProfileForm.reset();
					}
				})
				.catch((error) => {
					const errorMessage = parseErrorMessage(error);
					if (errorMessage.includes("Account locked")) {
						setTimeout(
							() =>
								this.$store.dispatch("resetState", {
									root: true,
								}),
							6000
						);
					}
					toastErrorMessage(errorMessage);
				});
		}
		this.formReadOnly = false;
	}

	private closeModal() {
		return;
	}
	private cancel() {
		this.$router.push(RoutePath.Dashboard);
	}
}
