import Vue from "vue";
import Notifications from "vue-notification";
import { merge } from "lodash";
import { Logger } from "@/utils/logger";
Vue.use(Notifications);

const options = {
	group: "portal",
	duration: 10000,
	speed: 1000,
};

function toastCustomMessage(toastMessage: TToastMessage) {
	Vue.notify(
		merge({
			...options,
			...{
				title: toastMessage.title,
				text: toastMessage.message,
				type: toastMessage.type,
			},
			// Only apply when function is supplied
			...(toastMessage.onClick && { data: toastMessage.onClick }),
		})
	);
}

function toastError(toastMessage: TToastMessage) {
	toastCustomMessage(toastMessage);
}

function toastInfo(toastMessage: TToastMessage) {
	toastCustomMessage(toastMessage);
}

function toastSuccess(toastMessage: TToastMessage) {
	toastCustomMessage(toastMessage);
}

function toastWarning(toastMessage: TToastMessage) {
	toastCustomMessage(toastMessage);
}

export enum ToastMessageType {
	ERROR = "error",
	INFO = "info",
	SUCCESS = "success",
	WARNING = "warn",
}

export type TToastMessage = {
	message: string;
	title?: string;
	type: string;
	options?: NotificationOptions;
	onClick?: () => void;
};

export function displayToast(toastMessage: TToastMessage) {
	switch (toastMessage.type) {
		case ToastMessageType.ERROR:
			toastError(toastMessage);
			break;
		case ToastMessageType.INFO:
			toastInfo(toastMessage);
			break;
		case ToastMessageType.SUCCESS:
			toastSuccess(toastMessage);
			break;
		case ToastMessageType.WARNING:
			toastWarning(toastMessage);
			break;
	}
}

export function toastSuccessMessage(message: string) {
	displayToast({
		message: message,
		type: ToastMessageType.SUCCESS,
	});
}

export function toastErrorMessage(message: string) {
	Logger.log(ToastMessageType.ERROR.toString());
	displayToast({
		message: message,
		type: ToastMessageType.ERROR.toString(),
	});
}

export function toastWarningMessage(message: string) {
	displayToast({
		message: message,
		type: ToastMessageType.WARNING,
	});
}

export function toastInfoMessage(message: string) {
	displayToast({
		message: message,
		type: ToastMessageType.INFO,
	});
}
