

















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import axios from "@/utils/ApiUtils";
import { newsURL } from "@/constants/apiconstants";
import { Logger } from "@/utils/logger";
import Card from "@/components/Card.vue";

interface NewsDto {
	title: string;
	body: string;
	readMoreUrl: string;
}

@Component({
	components: { Card },
})
export default class NewsWidget extends Vue {
	@Prop({ type: String }) newsSlot!: string;
	private news: NewsDto | null = null;

	mounted() {
		axios
			.get<NewsDto[]>(newsURL() + "/slot/" + this.newsSlot)
			.then((response) => {
				if (response.data.length != 0) {
					//MVP show only latest news
					this.news = response.data[0];
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					Logger.log(error);
				}
			});
	}
}
