








































import Vue from "vue";
import type { AxiosResponse } from "axios";

import axios from "@/utils/ApiUtils";
import { Component, Watch } from "vue-property-decorator";

import FileUpload from "@/form/FileUpload.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import Button from "@/form/Button.vue";
import { parseErrorMessage, parseErrorMessageList } from "@/utils/ErrorUtils";
import { RoutePath } from "@/router/routePath";
import {
	fileFormatOptions,
	uploadContributionFileFormatId,
} from "@/constants/apiconstants";
import { toastErrorMessage } from "@/plugins/toasts";
import SelectField from "@/form/SelectField.vue";
import { hasPermission } from "@/utils/PermissionUtils";

/**
 * Maps to au.com.iress.clearinghouse.portal.rest.PostContributionResponse
 */
interface PostContributionResponse {
	parentBatchId?: string;
	takeToFileLoadPage?: boolean;
}

interface FileFormatDto {
	value: string;
	label: string;
}

interface FileFormatResp {
	displayFileFormatOption: boolean;
	fileFormats: FileFormatDto[];
}

@Component({
	components: {
		FileUpload,
		ProgressBar,
		Button,
		SelectField,
	},
})
export default class SAFFUploaderForm extends Vue {
	private readonly acceptSaff = ".csv, .txt";

	private files: any[] = [];

	progress: number | null = null;

	isSubmitting = false;

	/**
	 * errors is the current error message being shown. Computed based
	 * on response from backend.
	 */
	errors: string[] = [];

	displayFileFormatOptions = false;
	fileFormatOptions: FileFormatDto[] = [];
	//SAFF default 0
	selectedFileFormat = "0";

	get hasError(): boolean {
		return this.errors && this.errors.length > 0;
	}

	get canSubmit(): boolean {
		return this.files.length > 0 && !this.isSubmitting;
	}

	clickCreateGrid(): void {
		this.$router.push(RoutePath.CreateBatch);
	}

	@Watch("files")
	onFilesChanged() {
		// Reset error if uploaded files has changed.
		// This is for the use-case where a user selects a file and submits
		// but gets an error. We then want the error message to be cleared
		// after a user selects a different file post-submit.
		this.errors = [];
		this.progress = null;

		// NOTE(Jae): 2020-05-14
		// Discussed with Karen and she wants the uploading of a valid file
		// type to immediately kick-off uploading, then the user will be taken
		// to the summary page for the file.
		if (this.files && this.files.length > 0) {
			this.triggerSubmit();
		}
	}

	onUploadProgress = (progressEvent: ProgressEvent): void => {
		this.progress = progressEvent.loaded / progressEvent.total;
	};

	triggerSubmit(): void {
		if (this.isSubmitting) {
			return;
		}

		this.isSubmitting = true;
		this.progress = 0;

		// Submit
		const fd = new FormData();
		fd.append("contributionFile", this.files[0]);
		axios
			.post<PostContributionResponse>(
				this.displayFileFormatOptions
					? uploadContributionFileFormatId(this.selectedFileFormat)
					: "api/contribution",
				fd,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
					onUploadProgress: this.onUploadProgress,
				}
			)
			.then((response) => {
				this.onSubmitSuccess(response);
			})
			.catch((error) => {
				// Keep spaces to help distinguish error message as the front end strips additional internal spaces
				this.errors = parseErrorMessageList(error, true);
			})
			.finally(() => {
				this.progress = 1;
				this.isSubmitting = false;
			});
	}

	onSubmitSuccess(response: AxiosResponse<PostContributionResponse>) {
		if (typeof response !== "object") {
			throw new Error("Expected object from response.");
		}
		if (!response.data.parentBatchId) {
			throw new Error('Missing "identifier" from response.');
		}
		const id = response.data.parentBatchId;
		const takeToFileLoadScreen = response.data.takeToFileLoadPage;

		const path = takeToFileLoadScreen
			? RoutePath.FileLoadSummary
			: RoutePath.DataInput;
		this.$router.push({
			path: path.replace("/:id", "/" + id),
			params: {
				id: id,
			},
		});
	}

	mounted() {
		if (hasPermission("VIEW_FILE_FORMAT")) {
			axios
				.get<FileFormatResp>(fileFormatOptions())
				.then((resp) => {
					this.displayFileFormatOptions =
						resp.data.displayFileFormatOption;
					this.fileFormatOptions = resp.data.fileFormats;
				})
				.catch((error) => {
					toastErrorMessage(parseErrorMessage(error));
				});
		}
	}
}
