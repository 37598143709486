












import { Component } from "vue-property-decorator";
import Grid from "../grid/Grid.vue";
import Layout from "../components/Layout.vue";
import Button from "../form/Button.vue";
import Vue from "vue";
import { AppRoute, AppRouteNextFunction } from "@/router";
import { BatchDetailResponse } from "@/pages/batchType";
import axios from "@/utils/ApiUtils";
import {
	batchDetailSummaryURL,
	batchDetailURL,
} from "@/constants/apiconstants";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import BatchDetailTabs from "@/components/BatchDetailTabs.vue";
import BatchDetail from "@/components/BatchDetail.vue";
import { toastErrorMessage } from "@/plugins/toasts";

@Component({
	components: {
		BatchDetail,
		BatchDetailTabs,
		Grid,
		Layout,
		Button,
	},
})
export default class BatchDetailsPage extends Vue {
	private batchDetailResponse: BatchDetailResponse | null = null;
	private responseReady = false;

	/**
	 * Request reload of batch data
	 */
	static readonly UPDATE_BATCH_DETAILS_EVENT = "update-batch-details";

	get batchDetail() {
		return this.batchDetailResponse?.batchDetail;
	}

	private updateBatchDetails() {
		BatchDetailsPage.fetchBatchDetails(this.$route.params.id).then(
			(batchDetailsResponse) => {
				if (batchDetailsResponse) {
					this.setResponse(batchDetailsResponse);
				}
			}
		);
	}

	async beforeRouteEnter(
		to: AppRoute,
		from: AppRoute,
		next: AppRouteNextFunction<BatchDetailsPage>
	) {
		const response = await BatchDetailsPage.beforeRouteEnterOrUpdate(
			to,
			from,
			next
		);
		if (response === undefined) {
			return;
		}
		next((vm) => {
			vm.setResponse(response);
		});
	}

	async beforeRouteUpdate(
		to: AppRoute,
		from: AppRoute,
		next: AppRouteNextFunction<BatchDetailsPage>
	) {
		const response = await BatchDetailsPage.beforeRouteEnterOrUpdate(
			to,
			from,
			next
		);
		if (response === undefined) {
			return;
		}
		next((vm) => {
			vm.setResponse(response);
		});
	}

	static async beforeRouteEnterOrUpdate(
		to: AppRoute,
		from: AppRoute,
		next: AppRouteNextFunction<BatchDetailsPage>
	): Promise<BatchDetailResponse | undefined> {
		const id: string = to.params.id;
		if (!id || id === "0") {
			// Redirect to 404 if ID is blank or 0.
			next({
				name: "Not Found",
			});
			return;
		}
		return await BatchDetailsPage.fetchBatchDetails(id);
	}

	private static async fetchBatchDetails(
		id: string
	): Promise<BatchDetailResponse | undefined> {
		let batchDetail;
		let batchSummaryDetail;
		await axios
			.get(batchDetailURL(Number(id)))
			.then(async (response) => {
				batchDetail = response.data;
				await axios
					.get(batchDetailSummaryURL(Number(id)))
					.then((response) => {
						batchSummaryDetail = response.data;
					})
					.catch((error) => {
						const errorMessage = parseErrorMessage(error);
						toastErrorMessage(errorMessage);
					});
			})
			.catch((error) => {
				const errorMessage = parseErrorMessage(error);
				toastErrorMessage(errorMessage);
			});
		return {
			batchDetail: batchDetail,
			batchSummaryDetail: batchSummaryDetail,
		};
	}

	setResponse(response: BatchDetailResponse) {
		if (response) {
			this.batchDetailResponse = response;
			this.responseReady = true;
		}
	}
}
