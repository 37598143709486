/**
 * setup will initialize any global variables / plugins / etc.
 */
import "@/setup";

import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import IE11RouterFix from "@/utils/IE11RouterFix";

new Vue({
	router,
	store,
	render: (h) => h(App),
	mixins: [IE11RouterFix],
}).$mount("#app");
