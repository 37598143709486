
import Vue, { CreateElement, VNode } from "vue";
import { Component, Prop } from "vue-property-decorator";

import FieldHolder from "@/form/FieldHolder.vue";
import ElementSchemaManager from "@/elementschema/ElementSchemaManager";
import type { CommonField } from "@/form/CommonField";
import { Logger } from "@/utils/logger";

@Component({
	components: {
		FieldHolder,
	},
})
export default class AutoField extends Vue implements CommonField {
	private static uniqueId = 0;

	/**
	 * id must be globally unique in a HTML document, we guarantee this by making each render
	 * of this component have unique ID number appended to the end to avoid clashes.
	 *
	 * We need ids mostly to connect <label> and <input> elements together for accessibility.
	 */
	readonly id = "AutoField_" + ++AutoField.uniqueId;

	/**
	 * fieldMap is a collection of properties that are forwarded on to the field we automatically
	 * render.
	 */
	@Prop([Object]) readonly fieldMap!:
		| { [propName: string]: any }
		| undefined
		| null;

	@Prop([String]) readonly name!: string;

	@Prop([String]) readonly label!: string;

	@Prop({ type: [String, Number, Boolean] }) readonly value!:
		| string
		| number
		| boolean;

	@Prop([String]) readonly rules!: string;

	@Prop(Boolean) readonly readonly!: boolean;

	@Prop(Boolean) readonly disabled!: boolean;

	/**
	 * Add additional custom error messages to this field.
	 */
	@Prop([Array]) readonly errors!: string[];

	/**
	 * Whether errors are shown externally.
	 */
	@Prop(Boolean) readonly errorsShownExternally!: boolean;

	render(createElement: CreateElement, context: any): VNode | null {
		// Get component to render with
		let componentData = null;
		let fallbackToDefaultField = false;
		if (this.fieldMap && this.fieldMap.type) {
			componentData = ElementSchemaManager.get(this.fieldMap.type);
			if (componentData === null) {
				Logger.error(
					"Field type does not exist or is not registered: ",
					this.fieldMap.type,
					"Falling back to readonly TextField."
				);
			}
		}
		if (componentData == null) {
			// Fallback to displaying a TextField. (Which is forced to be "readonly" in code below)
			// This allows AutoField to still display a value even if type information about the field
			// hasn't been loaded from the backend yet.
			componentData = ElementSchemaManager.get("TextField");
			fallbackToDefaultField = true;
			if (componentData === null || componentData === undefined) {
				throw new Error(
					"Unexpected error. Unable to fallback to TextField component under AutoField component with no 'type' defined"
				);
			}
		}

		// Get all the field properties from the fieldMap (provided by a <Form> component)
		const props: { [name: string]: any } = {};
		if (this.fieldMap !== null) {
			for (const propName in this.fieldMap) {
				if (
					!Object.prototype.hasOwnProperty.call(
						this.fieldMap,
						propName
					) ||
					propName === "name"
				) {
					continue;
				}
				props[propName] = this.fieldMap[propName];
			}
		}
		if (fallbackToDefaultField) {
			// Force field to be readonly if we had to use fallback
			// (this ensures fields in the <Popup> component still render even if we dont have
			// field type information yet)
			props.readonly = true;
		}

		// Override props retrieved from fieldMap with locally set properties.
		// This allows user-code to override backend properties such as adding a label:
		// <AutoField name="fieldName" label="My Field Name">
		const propsMeta: { [name: string]: any } | null | undefined =
			this.$options.props;
		if (propsMeta !== null && propsMeta !== undefined) {
			for (const propName in this) {
				if (
					propsMeta[propName] === undefined ||
					propName === "constructor" ||
					propName === "type" ||
					propName === "fieldMap"
				) {
					continue;
				}
				// override fieldMap with local properties
				const propValue = (this as any)[propName];
				if (propValue === null || propValue === undefined) {
					// skip unset properties
					continue;
				}
				// console.warn('field override', propName, propValue, propsMeta[propName]);
				props[propName] = propValue;
			}
		}

		// console.warn("AutoField", this.name, props);

		return createElement(
			componentData,
			{
				props: props,
				// NOTE(Jae): 2020-08-18
				// Pass all listeners applied to this element directly
				// to the child element.
				on: this.$listeners,
			},
			this.$slots.default
		);
	}
}
