






































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import FieldHolder from "@/form/FieldHolder.vue";
import ErrorList from "@/components/ErrorList.vue";
import { CommonField } from "@/form/CommonField";
import { CheckBoxGroupOption } from "@/form/FieldOptions";

@Component({
	components: {
		FieldHolder,
		ErrorList,
	},
})
export default class CheckBoxGroup extends Vue implements CommonField {
	private static uniqueId = 0;

	/**
	 * id must be globally unique in a HTML document, we guarantee this by making each render
	 * of this component have unique ID number appended to the end to avoid clashes.
	 *
	 * We need ids mostly to connect <label> and <input> elements together for accessibility.
	 */
	// NOTE (York): This copy/paste issue took me one day to figure out what the bug is!.
	// In AddOrEditPortalUsers.vue, after page refreshes, the first checkbox in the Grid cannot
	// be clicked because it shares the same id with "Roles to be assigned" checkbox group.
	// Please be careful when you copy/paste to make a component especially!
	readonly id = "CheckBoxGroup_" + ++CheckBoxGroup.uniqueId;

	@Prop([String]) readonly name!: string;

	@Prop([String]) readonly label!: string;

	@Prop() readonly value!: number[] | string[];

	@Prop([String]) readonly rules!: string;

	@Prop(Boolean) readonly readonly!: boolean;

	@Prop(Boolean) readonly disabled!: boolean;

	@Prop() readonly options!: CheckBoxGroupOption[];

	/**
	 * Add additional custom error messages to this field.
	 */
	@Prop([Array]) readonly errors!: string[];

	/**
	 * Whether errors are shown externally.
	 */
	@Prop(Boolean) readonly errorsShownExternally!: boolean;

	@Prop(String) readonly placeholder!: string;

	onChecked(e: any) {
		this.$emit("input", e);
	}
}
