








































import Vue from "vue";
import { Component } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import Button from "@/form/Button.vue";
import axios from "@/utils/ApiUtils";
import { definedBenefitReport } from "@/constants/apiconstants";
import { toastErrorMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";

interface FileDetails {
	link: string;
	title: string;
	filename: string;
}

@Component({
	components: {
		Layout,
		Button,
	},
})
export default class DefinedBenefitReportDocument extends Vue {
	// TODO: Pass report link + name in as prop so it can be viewed and downloaded.
	private readonly documentsList: FileDetails[] = [];

	private viewDocumentLink = "";

	beforeMount() {
		this.loadDocument(this.$route.params.empId, this.$route.params.repId);
	}

	private get isChrome(): boolean {
		return window.navigator.userAgent.indexOf("Chrome") !== -1;
	}

	private pushDocumentOntoList(fileName: string, url: string) {
		this.documentsList.push({
			title: fileName,
			link: url,
			filename: fileName,
		});
	}

	private loadDocument(empId: string, id: string) {
		axios
			.get(definedBenefitReport() + `/download/${empId}/${id}`, {
				responseType: "blob",
			})
			.then((response) => {
				let filename = "unknown";
				if (response.headers["content-disposition"]) {
					filename = response.headers["content-disposition"];
				}
				this.pushDocumentOntoList(
					filename,
					definedBenefitReport() + `/download/${empId}/${id}`
				);
				const blob = new Blob([response.data], {
					type: "application/pdf",
				});
				if (window.navigator && window.navigator.msSaveOrOpenBlob) {
					window.navigator.msSaveOrOpenBlob(blob, filename);
				} else {
					this.viewDocumentLink = `${window.URL.createObjectURL(
						blob
					)}${
						this.isChrome ? "#toolbar=0&navpanes=1&view=FitH" : ""
					}`;
				}
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
	}

	private onDownloadDocument(fileUrl: string, filename: string) {
		axios
			.get(fileUrl, {
				responseType: "blob",
			})
			.then((response) => {
				const blob = new Blob([response.data]);
				if (window.navigator && window.navigator.msSaveBlob) {
					window.navigator.msSaveBlob(blob, filename);
				} else {
					const url = window.URL.createObjectURL(blob);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", filename);
					link.click();
				}
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
	}
}
