import { TranslationMapping } from "@/grid/gridTypes";
import { orderBy } from "lodash";

export const batchStatusMapping: () => TranslationMapping[] = () => {
	return orderBy(
		[
			{
				value: "INIT",
				translatedValue: "Init",
				style: "font-weight: bold;color:#0a5a9f;", //darkblue
			},
			{
				value: "NEW",
				translatedValue: "New",
				style: "font-weight: bold;color:#4682b4;", //Steelblue
			},
			{
				value: "SPLITTING",
				translatedValue: "Splitting",
				style: "font-weight: bold;",
			},
			{
				value: "SPLIT",
				translatedValue: "Split",
				style: "font-weight: bold;color:#0000cd;", //Medium blue
			},
			{
				value: "SPLIT_FAILED",
				translatedValue: "Split Failed",
				style: "font-weight: bold;color:#8b0000;", //Red
			},
			{
				value: "SYSTEM_ERROR",
				translatedValue: "System Error",
				style: "font-weight: bold;color:#8b0000;", //Red
			},
			{
				value: "NOT_UPLOADED",
				translatedValue: "Not Uploaded",
				style: "font-weight: bold;color:#8b0000;", //Red
			},
			{
				value: "UPLOADED",
				translatedValue: "Uploaded",
				style: "font-weight: bold;color:#228b22;", //Forest green
			},
			{
				value: "IN_PROGRESS",
				translatedValue: "In Progress",
				style: "font-weight: bold;color:#228b22;", //Forest green
			},
			{
				value: "IN_PROGRESS_DELTA",
				translatedValue: "Fund Processing", // Might need to see what Karen says
				style: "font-weight: bold;color:#228b22;", //Forest green
			},
			{
				value: "AWAIT_AUTHORISATION",
				translatedValue: "Awaiting Authorisation",
				style: "font-weight: bold;color:#228b22;", //Forest green
			},
			{
				value: "AUTHORISATION_RECEIVED",
				translatedValue: "Authorisation Received",
				style: "font-weight: bold;color:#3cb371;", //Medium Seagreen
			},
			{
				value: "AWAIT_SECOND_AUTH",
				translatedValue: "Awaiting Second Authorisation",
				style: "font-weight: bold;color:#3cb371;", //Medium Seagreen
			},
			{
				value: "AWAIT_THIRD_AUTH",
				translatedValue: "Awaiting Third Authorisation",
				style: "font-weight: bold;color:#3cb371;", //Medium Seagreen
			},
			{
				value: "AWAIT_FOURTH_AUTH",
				translatedValue: "Awaiting Fourth Authorisation",
				style: "font-weight: bold;color:#3cb371;", //Medium Seagreen
			},
			{
				value: "AUTHORISING_FUNDS",
				translatedValue: "Validating",
				style: "font-weight: bold;color:#3cb371;", //Medium Seagreen
			},
			{
				value: "AUTHORISING_FUNDS_VALID",
				translatedValue: "Validated",
				style: "font-weight: bold;color:#3cb371;", //Medium Seagreen
			},
			{
				value: "AUTHORISED",
				translatedValue: "Authorised",
				style: "font-weight: bold;color:#006400;", //Dark Green
			},
			{
				value: "AUTHORISING",
				translatedValue: "Authorising",
				style: "font-weight: bold;",
			},
			{
				value: "DELETING",
				translatedValue: "Deleting",
				style: "font-weight: bold;",
			},
			{
				value: "DELETED",
				translatedValue: "Deleted",
				style: "font-weight: bold;",
			},
			{
				value: "UPLOADING",
				translatedValue: "Uploading",
				style: "font-weight: bold;",
			},
			{
				value: "POLLING",
				translatedValue: "Polling",
				style: "font-weight: bold;",
			},
			{
				value: "EMPTY_FILE",
				translatedValue: "Empty File",
				style: "font-weight: bold;color:#FF8C33;",
			},
		] as TranslationMapping[],
		["translatedValue"],
		["asc"]
	);
};

export const stagingStatusMapping: () => TranslationMapping[] = () => {
	return orderBy(
		[
			{
				value: "NEW",
				translatedValue: "New",
				style: "font-weight: bold;color:#4682b4;", //Steelblue
			},
			{
				value: "DISCARD",
				translatedValue: "Discard",
				style: "font-weight: bold;",
			},
			{
				value: "UPDATED",
				translatedValue: "Updated",
				style: "font-weight: bold;color:#008000;", //green
			},
			{
				value: "NOTUPDATED",
				translatedValue: "Not Updated",
				style: "font-weight: bold;color: #856404;", //warning color
			},
			{
				value: "SYSTEMERROR",
				translatedValue: "System Error",
				style: "font-weight: bold;color:#8b0000;", //Red
			},
		] as TranslationMapping[],
		["translatedValue"],
		["asc"]
	);
};
