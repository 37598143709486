



























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class ErrorIcon extends Vue {
	@Prop({ type: String, default: "times-octagon" })
	customIcon!: string;

	@Prop({ type: String, default: "Error" })
	title!: string;

	@Prop({ type: Boolean, default: false })
	disabled!: boolean;
}
