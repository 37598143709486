


































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import FieldHolder from "@/form/FieldHolder.vue";
import ErrorList from "@/components/ErrorList.vue";
import { CommonField } from "@/form/CommonField";
import Multiselect from "vue-multiselect";
import { Logger } from "@/utils/logger";
import { SelectOption } from "@/form/FieldOptions";

@Component({
	components: {
		FieldHolder,
		ErrorList,
		Multiselect,
	},
})
export default class SelectField extends Vue implements CommonField {
	private static uniqueId = 0;

	/**
	 * id must be globally unique in a HTML document, we guarantee this by making each render
	 * of this component have unique ID number appended to the end to avoid clashes.
	 *
	 * We need ids mostly to connect <label> and <input> elements together for accessibility.
	 */
	readonly id = "SelectField_" + ++SelectField.uniqueId;

	private readonly defaultEmptyText = "(Select an option)";

	readonly $refs!: {
		multiselectRef: Multiselect;
	};

	@Prop([String]) readonly name!: string;

	@Prop([String]) readonly label!: string;

	@Prop({ type: [String, Number, Boolean], default: null }) value!:
		| string
		| number
		| boolean
		| null;

	@Prop([String]) readonly rules!: string;

	@Prop(Boolean) readonly readonly!: boolean;

	@Prop(Boolean) readonly disabled!: boolean;

	@Prop({ type: Boolean, default: true }) readonly searchable!: boolean;

	/**
	 * Add additional custom error messages to this field.
	 */
	@Prop([Array]) readonly errors!: string[];

	@Prop({ type: Array, default: () => [] }) readonly options!: SelectOption[];

	/**
	 * Whether errors are shown externally.
	 */
	@Prop(Boolean) readonly errorsShownExternally!: boolean;

	@Prop({ type: Boolean, default: false }) readonly isLabelLeft!: boolean;

	@Prop({ type: Boolean, default: true }) readonly allowEmptyValue!: boolean;

	@Prop({ type: String, default: "Select option" })
	readonly placeholder!: string;

	/**
	 * Transform prop value into select option with label from prop options
	 */
	get selectedOption(): SelectOption | null {
		if (
			this.options.length === 0 ||
			this.value === undefined ||
			this.value === null
		) {
			return null;
		}
		// NOTE (York): Due the string type restriction of SelectOption.value, type mismatches
		// if SelectField prop value is not string. Convert to string before comparision
		return this.options.find((o) => o.value === String(this.value)) ?? null;
	}

	onSelectChange(selectedOption: SelectOption) {
		// NOTE (York): prop value allows number, boolean and string type but SelectOption is only string type.
		// Convert selected option value back to the type of prop value.
		const value = selectedOption?.value;
		if (value === undefined || value === null) {
			// NOTE (York): we can't emit with undefined payload. onInput method in Form.vue mutates the prop `value` (recordFormData).
			// Setting a property of it to undefined and it sets SelectField $vnode.componentOptions.propsData.value to undefined but
			// it is not reactive and propogated to prop value. Don't have idea why. There are two way to solve the problem.
			// 1. emit null instead of undefined
			// 2. Do not mutate prop value in onInput of Form.vue. emit event there, i.e `$emit("input", {...this.value, [name]: newValue});`
			this.$emit("input", null);
		} else if (typeof this.value === "number") {
			this.$emit("input", Number(value));
		} else if (typeof this.value === "boolean") {
			this.$emit("input", value === "true");
		} else {
			this.$emit("input", value);
		}
	}

	onSelectClose() {
		// Re-emit blur to trigger validation of ValidateProvider in the FieldHolder
		this.$refs.multiselectRef.$emit("blur");
	}

	get computedFatalErrors(): string[] {
		if (!this.options) {
			return [];
		}
		const errors: string[] = [];
		const duplicateKeyCheck: { [fieldName: string]: boolean } = {};
		for (const option of this.options) {
			if (duplicateKeyCheck[option.value] === true) {
				errors.push(
					'"' +
						option.value +
						'" has duplicate. This is not allowed. (Label: ' +
						option.label +
						")"
				);
				continue;
			}
			duplicateKeyCheck[option.value] = true;
		}
		if (process.env.NODE_ENV === "development" && errors.length > 0) {
			Logger.error(
				"Field",
				this.name,
				"has options with a duplicate key value, this is not allowed. Options:",
				{ ...this.options }
			);
		}
		return errors;
	}

	/**
	 * computedReadonly will get if the field cannot be modified either due to
	 * having the readonly flag set or if there are no options to choose from.
	 */
	get computedReadonly(): boolean {
		return (
			this.readonly === true || !this.options || this.options.length === 0
		);
	}

	/**
	 * readonlyValue will get the label of the current selected option if available
	 * rather than give the raw value.
	 */
	get readonlyValue(): string {
		let r = null;
		const value = String(this.value);
		if (!this.options || this.options.length === 0) {
			return value;
		}
		for (const option of this.options) {
			if (option.value !== value) {
				continue;
			}
			r = option.label;
			break;
		}
		if (r === null) {
			// If cannot find value in options list, just use value
			// as is.
			r = this.value !== null ? String(this.value) : "";
		}
		return r;
	}
}
