















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import CheckBox from "@/form/CheckBox.vue";

@Component({
	components: {
		CheckBox,
	},
})
export default class CheckBoxRenderer<RowType> extends Vue {
	/**
	 * rowIndex is the index of the record
	 */
	@Prop(Number) rowIndex!: number;

	/**
	 * row is the data of the record
	 */
	@Prop({ type: Object }) row!: RowType;

	/**
	 * value is the initial value of checkbox
	 */
	@Prop({ type: Boolean, default: false }) readonly value!: boolean;

	/**
	 * disable checkbox ?
	 */
	@Prop({ type: Boolean, default: false }) readonly disable!: boolean;

	/**
	 * Optional if being used in groupby
	 */
	@Prop(String) readonly groupBy!: string;

	private onInput(checked: boolean): void {
		this.$emit("input", {
			rowIndex: this.rowIndex,
			row: this.row,
			value: checked,
			groupBy: this.groupBy,
		});
	}
}
