























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import MaintenanceGridItem from "@/components/MaintenanceGridItem.vue";
import { RoutePath } from "@/router/routePath";
import { checkPermissionForRoute } from "@/utils/RouteUtils";

export interface GridItem {
	name: string;
	description: string;
	buttonName: string;
	link: RoutePath;
	show: boolean;
	dataCy: string;
	cardDataCy: string;
}

@Component({ components: { MaintenanceGridItem } })
export default class MaintenanceGrid extends Vue {
	@Prop() readonly maintenanceGridItems!: GridItem[];

	private hasPermission(link: RoutePath): boolean {
		return checkPermissionForRoute(link, this.$route);
	}

	get filteredMaintenanceGridItems() {
		this.maintenanceGridItems.filter((i) => {
			i.show = this.hasPermission(i.link);
		});

		const filtered: GridItem[] = [];
		this.maintenanceGridItems.forEach((i) => {
			if (i.show && filtered.findIndex((f) => f.name === i.name) === -1) {
				filtered.push(i);
			}
		});

		return filtered;
	}
}
