





































import { Component, Vue } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import PageHeader from "@/components/PageHeader.vue";
import SummaryListItem from "@/components/SummaryListItem.vue";
import SummaryList from "@/components/SummaryList.vue";
import axios from "@/utils/ApiUtils";
import { batchFileLoadSummary } from "@/constants/apiconstants";
import { toastErrorMessage } from "@/plugins/toasts";
import { RoutePath } from "@/router/routePath";
import { parseErrorMessage } from "@/utils/ErrorUtils";

interface FileLoadSummary {
	fileName: string;
	superStreamId?: number;
	dbDirectId?: number;
}
@Component({
	components: { SummaryList, SummaryListItem, PageHeader, Layout },
})
export default class FileLoadSummaryPage extends Vue {
	private readonly title = "File load summary";
	private file: FileLoadSummary = {
		fileName: "",
	};

	beforeMount() {
		const parentId = Number(this.$route.params.id);
		axios
			.get(batchFileLoadSummary(parentId))
			.then((res) => {
				this.file = res.data;
			})
			.catch((e) => {
				// Take user back to dashboard as the file doesn't exist or has been deleted.
				toastErrorMessage(parseErrorMessage(e));
				this.$router.push(RoutePath.Dashboard);
			});
	}

	getDataInputHref(id: number) {
		return RoutePath.DataInput.replace(":id", id.toString());
	}
}
